import { IWaListMessageItem } from 'components/flowV2/modals/visualEditor/waVisualEditorComponents/waEditorInterfaces';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface IWaListMessageProps {
  content: {
    title: string
    body: string
    footer: string
  }
  globalButton: string
  items: IWaListMessageItem[]
  handleSendMessage: (value: string) => void
}
const WaListMessage = ({ content: { footer, title, body }, globalButton, items, handleSendMessage }: IWaListMessageProps) => {
  const [showList, setShowList] = useState(false)

  const handleClickItem = (value: string) => {
    handleSendMessage(value)
    setShowList(false)
  }
  return (
    <>
      <Cont>
        <TextCont>
          <TailCont>
            <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px" enableBackground="new 0 0 8 13"><title>tail-in</title><path opacity="0.13" fill="#0000000" d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"></path><path fill="currentColor" d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"></path></svg>
          </TailCont>
          <Title >
            {title}
          </Title>
          <Body >
            {body}
          </Body>

          <Footer>
            {footer}
          </Footer>

          <GlobalButton onClick={() => setShowList(prev => !prev)}>
            <div>
              <svg viewBox="0 -4 15 20" height="12" width="15" preserveAspectRatio="xMidYMid meet" version="1.1"><title>list-msg-icon</title><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="format_list_bulleted-black-18dp" transform="translate(-1.000000, -3.000000)"><polygon id="Path" points="0 0 18 0 18 18 0 18"></polygon><path d="M3,7.875 C2.3775,7.875 1.875,8.3775 1.875,9 C1.875,9.6225 2.3775,10.125 3,10.125 C3.6225,10.125 4.125,9.6225 4.125,9 C4.125,8.3775 3.6225,7.875 3,7.875 Z M3,3.375 C2.3775,3.375 1.875,3.8775 1.875,4.5 C1.875,5.1225 2.3775,5.625 3,5.625 C3.6225,5.625 4.125,5.1225 4.125,4.5 C4.125,3.8775 3.6225,3.375 3,3.375 Z M3,12.375 C2.3775,12.375 1.875,12.885 1.875,13.5 C1.875,14.115 2.385,14.625 3,14.625 C3.615,14.625 4.125,14.115 4.125,13.5 C4.125,12.885 3.6225,12.375 3,12.375 Z M5.25,14.25 L15.75,14.25 L15.75,12.75 L5.25,12.75 L5.25,14.25 Z M5.25,9.75 L15.75,9.75 L15.75,8.25 L5.25,8.25 L5.25,9.75 Z M5.25,3.75 L5.25,5.25 L15.75,5.25 L15.75,3.75 L5.25,3.75 Z" id="Shape" fill="currentColor" fillRule="nonzero"></path></g></g></svg>
            </div>
            <div>
              {globalButton}
            </div>
          </GlobalButton>


        </TextCont>
      </Cont>
      {showList &&
        <ListContBackground>
          <ListCont>
            <ListTitle>
              <Exit onClick={() => setShowList(prev => !prev)}>&times;</Exit>
              {globalButton}

            </ListTitle>

            <ListItemsCont>
              {items.map((item, index) => {
                return (
                  <React.Fragment key={index}>

                    {item.title && <ListItemTitle>{item.title}</ListItemTitle>}
                    {item.options.map((opt, i) => {
                      return (
                        <ListOptCont key={i} onClick={() => handleClickItem(opt.title)}>
                          <Title>{opt.title}</Title>
                          <Body>{opt.description}</Body>
                        </ListOptCont>
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </ListItemsCont>
          </ListCont>
        </ListContBackground>
      }
    </>
  );
};
const Cont = styled.div`
color: #e9edef;
font-size: 14px;
margin-bottom: 3px;
display: flex;
  gap: 3px;
flex-direction: column;
`;
const TextCont = styled.div`
width: 336px;
width: 90%;
min-height: 40px;
padding: 6px 7px 8px 9px;
  background-color: #202c33;
  position: relative;

  border-radius: 7.5px;
  border-top-left-radius: 0;
  font-size: 15px;
`;

const GlobalButton = styled.div`
  text-align: center;
  min-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding-top: 7px;
  margin-top: 10px;
  border-top: 1px solid #354955;
  cursor: pointer;

  &> :first-child{
    margin-right: 5px;
  }
  
  >div{
    color: #53bdeb;

  }
`;
const Body = styled.div`
  border: none;
  outline: none;
  flex-grow: 1;
  margin-bottom: 5px;
  font-size: 14px;
  color: #e9edef;
  
`;
const Title = styled.div`
  border: none;
  outline: none;
  flex-grow: 1;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
  color: #e9edef;
`;

const Footer = styled.div`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 13px;
  color: #667781;
`;
const TailCont = styled.span`
  position: absolute;
  top: 0px;
  left: 0;
  display: flex;
  transform: translate(-100%);
  color: #202c33;
`;
const ListContBackground = styled.div`
position: absolute;
z-index: 1000;
top: 0;
left: 0;
width: 100%;
height: 100%;
  background-color: rgba(12, 19, 23, 0.75);
`;
const ListCont = styled(TextCont)`
  border-radius: 0;
  padding: 0;
  background-color: #111b21;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const ListTitle = styled.div`
  height: 50px;
  padding-left: 30px;
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 19px;
  font-weight: 500;
  color: #e9edef;
  background-color: #202c33;
`;
const Exit = styled.span`
  font-size: 2.5rem;
  font-weight: 100;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
`;
const ListItemsCont = styled.div`
  padding: 20px 15px 0;
 
`;
const ListItemTitle = styled.div`
  color: #027663;
  margin: 30px 0 20px;
`;
const ListOptCont = styled.div`
  margin-bottom: 30px;
  cursor: pointer;
`;
export default WaListMessage;