import React, { FC } from 'react';
import styled from 'styled-components';
import VisualEditor from '../visualEditor/visualEditor';
import { Node } from 'reactflow';
import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';

interface IInteractiveMenuVisualEditorProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  handleShowVisualEditor: (show: boolean) => void;
  handleDeleteEdgeOnDeleteSource: (sourceId: string) => void
}
const InteractiveMenuVisualEditor = ({ selectedNode, setSelectedNode, handleShowVisualEditor, handleDeleteEdgeOnDeleteSource }: IInteractiveMenuVisualEditorProps) => {
  const { data } = selectedNode;

  return (
    <Cont>
      <VisualEditor
        textStringify={data.textV2?.text || ''}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        handleShowVisualEditor={handleShowVisualEditor}
        handleDeleteEdgeOnDeleteSource={handleDeleteEdgeOnDeleteSource}
      />
    </Cont>
  );
};
const Cont = styled.div``;
export default InteractiveMenuVisualEditor;