import IStore from 'library/interfaces/storeInterface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTimeCounterAction } from 'store/actions';
import styled from 'styled-components';

const TimeCounter = () => {
  const [counter, setCounter] = useState(1);

  const dispatch = useDispatch()
  const time = useSelector<IStore>(store=>store.phone.timeCounter) as string

  const timer = () => {
    const date = new Date(counter * 1000);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(()=>{
    const t = setTimeout(() => {
        setCounter(counter + 1)
        dispatch(updateTimeCounterAction(timer()))
    }, 1000);
    return () => clearTimeout(t)
  },[counter])

  return <Span>{time}</Span>;
};
const Span = styled.span`
  margin-left: 10px;
  font-size: 0.9rem;
  display: inline-block;
  width: 60px;
`;
export default TimeCounter;
