import React, { FC } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { II18nKeys } from 'library/interfaces/i18nKeys';
import { lightBackgroundBlue } from 'library/colors';

//
// ******* component with i18n translation *******
//
interface ISearchInputProps {
  i18nKeys: II18nKeys;
  onInputChange: (value: string) => void;

}
const SearchInput: FC<ISearchInputProps> = ({ i18nKeys, onInputChange }) => {
  // i18n translation
  const { namespace, key } = i18nKeys;
  const { t } = useTranslation(namespace);
  const text = t(key);

  return <Input type="text" placeholder={text} onChange={e => onInputChange(e.target.value)} />;
};
const Input = styled.input`
  width: 50%;
  border: none;
  padding: 5px 20px;
  border-radius: 50px;
  background-color: ${lightBackgroundBlue};
  
  ::placeholder {
    color: black;
  }
`;
export default SearchInput;
