import { darkBorder, primary } from 'library/colors';
import { IQueue } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SelectableQueue, { ISelectableQueue } from '../atoms/selectableQueue';

interface IQueueListProps {
  queuesToRender: ISelectableQueue[]
  handleExit: Function;
  selectAgentsByQueue: Function;
}
const QueueList: FC<IQueueListProps> = ({
  handleExit,
  queuesToRender,
  selectAgentsByQueue,
}) => {
  

  return (
    <Cont>
      <Exit onClick={e => handleExit(e)}>x</Exit>
      {queuesToRender.map((queue, index) => {
        return (
          <SelectableQueue
            key={index}
            queue={queue}
            index={index}
            handleSelectedQueue={selectAgentsByQueue}
          />
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  width: 50%;
  max-height: 200px;
  right: 0;
  top: 35px;
  padding-left: 6px;
  position: absolute;
  border-radius: 10px;
  background-color: white;
  border: 1px solid white;
  box-shadow: 0 0px 5px ${primary}, 0 0px 10px ${primary};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const Exit = styled.div`
  top: 12px;
  position: sticky;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
export default QueueList;
