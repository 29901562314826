import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import useDownloadReport from 'components/Recording/filters/useDownloadReport';
import { darkBorder, primary } from 'library/colors';
import {
  isDateAfterToday,
  isFirstDateAfterToSecondDate,
} from 'library/services/dateServices';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { reportDownloaded } from 'store/actions';
import {
  REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL,
  REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234,
} from 'store/reports/actionTypes';
import { IReport } from 'store/reports/reducer';
import styled from 'styled-components';
import FormGroup from '../molecules/formGroup';

interface IRecordingReportFormProps {
  formDates: {
    start_date: string;
    end_date: string;
  };
  callsDetailExcel: IReport;
  eventsFromBot1234: IReport;
  setFormDates: Function;
  handleViewData: Function;
  requestCallDetails: Function;
  requestEventsFromBot1234: Function;
}
const RecordingReportForm: FC<IRecordingReportFormProps> = ({
  formDates,
  callsDetailExcel,
  eventsFromBot1234,
  setFormDates,
  handleViewData,
  requestCallDetails,
  requestEventsFromBot1234,
}) => {
  const dispatch = useDispatch();
  const { isLoading, error, reportData } = callsDetailExcel;

  const callsDetailExcel_URI =
    reportData && reportData.content_data && reportData.content_data.uri
      ? reportData.content_data.uri
      : null;

  const eventsFromBot1234_URI =
    eventsFromBot1234.reportData &&
    eventsFromBot1234.reportData.content_data &&
    eventsFromBot1234.reportData.content_data.uri
      ? eventsFromBot1234.reportData.content_data.uri
      : null;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (isDateAfterToday(value)) {
      return;
    } else if (
      name === 'start_date' &&
      isFirstDateAfterToSecondDate(value, formDates.end_date)
    ) {
      return;
    } else if (
      name === 'end_date' &&
      isFirstDateAfterToSecondDate(formDates.start_date, value)
    ) {
      return;
    }

    setFormDates({
      ...formDates,
      [name]: value,
    });
  };

  const handleViewDataButtonClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    handleViewData();
  };

  const handleRequestCallDetails = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    requestCallDetails();
  };

  const handleRefreshButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    requestCallDetails();
  };

  const downloadCallsDetailExcel = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    setTimeout(() => {
      dispatch(reportDownloaded(REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL));
    }, 1000);
  };

  const handleRequestEventsFromBot1234 = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    requestEventsFromBot1234();
  };

  const handleRefreshBot1234ButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    requestEventsFromBot1234();
  };
  const downloadEventFromBot1234 = () => {
    setTimeout(() => {
      dispatch(reportDownloaded(REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234));
    }, 1000);
  };

  return (
    <Form>
      <FormGroup
        type={'date'}
        label={'Start date'}
        name={'start_date'}
        value={formDates.start_date}
        handleInputChange={handleInputChange}
      />
      <FormGroup
        type={'date'}
        label={'End date'}
        name={'end_date'}
        value={formDates.end_date}
        handleInputChange={handleInputChange}
      />
      <Button onClick={e => handleViewDataButtonClick(e)}>View Data</Button>
      <ButtonCont>
        {!isLoading && !error && !callsDetailExcel_URI && (
          <Button gray onClick={e => handleRequestCallDetails(e)}>
            Generate Report <br /> Call Details Excell
          </Button>
        )}
        {isLoading && !error && !callsDetailExcel_URI && (
          <Label>We are generating your report <LoadingPoints /></Label>
        )}
        {error && !callsDetailExcel_URI && (
          <Label>
            {error.message} &nbsp;{' '}
            <Refresh onClick={e => handleRefreshButtonClick(e)}>
              {' '}
              Refresh.
            </Refresh>
          </Label>
        )}
        {callsDetailExcel_URI && (
          <DownLoad
            href={callsDetailExcel_URI}
            target={'_blank'}
            rel="noreferrer"
            onClick={e => downloadCallsDetailExcel(e)}
          >
            <Button /* onClick={e => downloadCallsDetailExcel(e)} */>
              Download Excel Report
            </Button>
          </DownLoad>
        )}
      </ButtonCont>

      <ButtonCont>
        {!eventsFromBot1234.isLoading &&
          !eventsFromBot1234.error &&
          !eventsFromBot1234_URI && (
            <Button gray onClick={e => handleRequestEventsFromBot1234(e)}>
              Generate Report <br /> Events from Bot 1234
            </Button>
          )}
        {eventsFromBot1234.isLoading &&
          !eventsFromBot1234.error &&
          !eventsFromBot1234_URI && (
            <Label>We are generating your report <LoadingPoints /></Label>
          )}
        {eventsFromBot1234.error && !eventsFromBot1234_URI && (
          <Label>
            {eventsFromBot1234.error.message} &nbsp;{' '}
            <Refresh onClick={e => handleRefreshBot1234ButtonClick(e)}>
              {' '}
              Refresh.
            </Refresh>
          </Label>
        )}
        {eventsFromBot1234_URI && (
          <DownLoad
            href={eventsFromBot1234_URI}
            target={'_blank'}
            rel="noreferrer"
            onClick={e => downloadEventFromBot1234()}
          >
            <Button /* onClick={e => downloadCallsDetailExcel(e)} */>
              Download Excel Report
            </Button>
          </DownLoad>
        )}
      </ButtonCont>
    </Form>
  );
};
const Form = styled.form``;
const ButtonCont = styled.div`
  //border: 1px solid;
  height: 65px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Label = styled.label`
  text-align: center;
`;
const DownLoad = styled.a`
  width: 100%;
`;
const Button = styled.div<IButton>`
  cursor: pointer;
  text-align: center;
  padding: 5px 0;
  width: 100%;
  border-radius: 50px;
  color: ${props => (props.gray ? '#6e6e6e' : 'white')};
  font-size: 0.9rem;
  font-weight: 500;
  background-color: ${props => (props.gray ? darkBorder : primary)};
`;
const Refresh = styled.a``;
interface IButton {
  gray?: React.ReactNode;
}
export default RecordingReportForm;
