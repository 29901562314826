import { lightBackgroundBlue } from 'library/colors';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import HorizontalForm from '../molecules/horizontalForm'
import { BotsExt, ownerBotsConfig, ownersWithConfig } from '../templates/reports/byBotExtension';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';

interface IWhatsappUsageFormProps {
  formValues: {
    start_date: string,
    end_date: string
    appId: string
  }
  setFormValues: Function
  handleFormButtonClick: Function
  isDisable?: boolean
}
const WhatsappUsageForm: FC<IWhatsappUsageFormProps> = ({
  formValues,
  setFormValues,
  handleFormButtonClick,
  isDisable
}) => {
  const { t } = useTranslation('reports');

  const appList = useAppSelector(store => store.waCampaigns.appList.list)

  const handleAppChange = (value: string) => {
    setFormValues({ ...formValues, appId: value })
  }

  return (
    <Cont>
      <SelectCont>
        <label htmlFor='app'>{t(`whatsappUsage.selectApp`)}</label>
        <select id="app" value={formValues.appId} onChange={e => handleAppChange(e.target.value)}>
          <option value="">{t(`whatsappUsage.selectApp`)}</option>
          {appList.map((app, index) => {
            return (
              <option key={index} value={app.id}>{t(`whatsappUsage.gupshupApps.${app.name}`)}</option>
            )
          })}
        </select>
      </SelectCont>
      <HorizontalForm isDisable={isDisable} formValues={formValues} setFormValues={setFormValues} onSubmit={handleFormButtonClick} />
    </Cont>
  );
};
const Cont = styled.div`
  border-radius: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: ${lightBackgroundBlue};
`;
const SelectCont = styled.div`
  margin: 0 20px 0 0;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  

  &>label{
    font-size: .7rem;
  }

  & > select {
    margin: 0 0 5px;
    height: 44px;
    font-size: 1rem;
    border: none;
    padding: 10px 10px;
    flex-grow: 1;
    max-width: 230px;
    background-color: white;
  }
`;

export default WhatsappUsageForm;