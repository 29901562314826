import { API, graphqlOperation } from 'aws-amplify';

const config = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_REAL_TIME_URL,
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_REAL_TIME_KEY,
};

API.configure(config);

const liveDataQuery = `
subscription Subscribe($owner: String!) {
  queueListSubscribe(owner: $owner) {
    queues {
      id
      uid
      agentsStatus {
        id
        state
        serviceLevel
        answeredCount
        unansweredCount
        availableDuration
      }
      callsStatus {
        abandoned
        answered
        online
        queued
        abandonedCount
        answeredCount
      }
    }
  }
}
`;

export const subscribeLiveData = (owner, next, error) => {
  return API.graphql(graphqlOperation(liveDataQuery, { owner })).subscribe({
    next: ({ /* provider, */ value }) => {
      next(value.data.queueListSubscribe.queues);
    },
    error: error || console.log,
  });
};

const queuedCallsQuery = `
subscription Subscribe($owner: String!) {
    queueListSubscribe(owner: $owner) {
      queues {
        id
        uid
        agentsStatus {
          id
          state
          serviceLevel
          answeredCount
          unansweredCount
        }
        calls {
          srcCallerid
          uniqueid
          events {
            agent
            endTime
            ringtime
            state
          }
        }
      }
    }
  }
`;
export const subscribeQueuedCallsData = (owner, next, error) => {
  return API.graphql(graphqlOperation(queuedCallsQuery, { owner })).subscribe({
    next: ({ /* provider, */ value }) => {
      next(value.data.queueListSubscribe.queues);
    },
    error: error || console.log,
  });
};

const queuedCallsQueryByUid = `
subscription Subscribe($uid: String!) {
  queueSubscribe(uid: $uid) {
    queue {
      uid
      id
      agentsStatus {
        id
        state
        serviceLevel
        answeredCount
        unansweredCount
        availableDuration
      }
      calls {
        srcCallerid
        uniqueid
        events {
          agent
          endTime
          ringtime
          state
        }
      }
    }
  }
}
`;
export const subscribeQueuedCallsDataById = (uid, next, error) => {
  return API.graphql(
    graphqlOperation(queuedCallsQueryByUid, { uid }),
  ).subscribe({
    next: ({ /* provider, */ value }) => {
      next(value.data.queueSubscribe.queue);
    },
    error: error || console.log,
  });
};

export const subscribeQueueDoc = /* GraphQL */ `
  subscription Subscribe($uid: String!) {
    queueSubscribe(uid: $uid) {
      dateFrom
      dateTo
      owner
      uid
      queue {
        uid
        id
        agentsStatus {
          id
          state
        }
        calls {
          srcCallerid
          uniqueid
          events {
            agent
            endTime
            ringtime
            state
          }
        }
        callsStatus {
          abandoned
          abandonedCount
          answered
          answeredCount
          online
          queued
        }
        botStatus {
          abandoned
          answered
          completed
        }
      }
    }
  }
`;

export const subscribeQueueListDoc = /* GraphQL */ `
  subscription Subscribe($owner: String!) {
    queueListSubscribe(owner: $owner) {
      dateFrom
      dateTo
      owner
      queues {
        agentsStatus {
          answeredCount
          id
          serviceLevel
          state
          unansweredCount
        }
        botStatus {
          abandoned
          answered
          completed
        }
        calls {
          srcCallerid
          uniqueid
          events {
            agent
            endTime
            ringtime
            state
          }
        }
        callsStatus {
          abandoned
          abandonedCount
          answered
          answeredCount
          online
          queued
        }
        id
        uid
      }
    }
  }
`;

export function subscribeQueueList(owner, next, error) {
  return API.graphql(
    graphqlOperation(subscribeQueueListDoc, { owner }),
  ).subscribe({
    next: ({ /* provider, */ value }) => {
      console.log('subscribeQueueList: ', value);
      next(value.data.queueListSubscribe);
    },
    error: error || console.log,
  });
}

export function subscribeQueue(uid, next, error) {
  return API.graphql(
    graphqlOperation(queuedCallsQueryByUid, { uid }),
  ).subscribe({
    next: ({ /* provider, */ value }) => {
      console.log('subscribeQueue: ', value);
      next(value.data.queueSubscribe.queue);
    },
    error: error || console.log,
  });
}
