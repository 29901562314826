import PhoneCall from 'components/WorkSpace/molecules/phoneCall';
import { darkBorder } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IPhoneCall {
    isIncoming: boolean;
    dialedNumber: string;
    onHangUp: Function;
    acceptCall: Function;
    remoteDisplayName: string;
  }
const PhoneCallFloat: FC<IPhoneCall> = ({
  isIncoming,
  dialedNumber,
  onHangUp,
  acceptCall,
  remoteDisplayName
}) => {
  return (
    <Cont>
        <PhoneCall remoteDisplayName={remoteDisplayName} isIncoming={isIncoming} dialedNumber={dialedNumber} onHangUp={onHangUp} acceptCall={acceptCall}/>
    </Cont>
  );
};
const Cont = styled.div`
  position: absolute;
  width: 300px;
  top: 80%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 10px;
  box-shadow: 
    0 3px 5px ${darkBorder},
    0 3px 10px ${darkBorder},
    0 3px 10px ${darkBorder},
    0 3px 15px ${darkBorder};
`;
export default PhoneCallFloat;