import { DelIconModalSC, FlexContSBModalSC, InputColorModalSC, InputModalSC, InputVerticalGroupModalSC, LabelModalSC, SelectModalSC, WhiteContModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { danger, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IRouteProps {
  index: number;
  sourceColor: string;
  responseRoute: {
    id: string;
    port: string;
    responseKey: string;
    possibleResponses: string[];
    intentId: string;
    text: string;
  };
  handleRouteChange: (
    index: number,
    name: 'port' | 'responseKey' | 'text' | 'intentId' | 'possibleResponses',
    value: string,
  ) => void;
  handleDeleteRoute: (routeId: string) => void;
  handleUpdateSourceColor: (sourceId: string, color: string) => void;
}
const Route: FC<IRouteProps> = ({
  responseRoute,
  index,
  sourceColor,
  handleRouteChange,
  handleDeleteRoute,
  handleUpdateSourceColor
}) => {
  const intents = useAppSelector(
    store => store.scenariosV2.intents,
  );
  return (
    <WhiteContModalSC>
      <DelIconModalSC onClick={() => handleDeleteRoute(responseRoute.id)}>
        <i className='bx bxs-trash'></i>
      </DelIconModalSC>
      <FlexContSBModalSC>
        <InputVerticalGroupModalSC >
          <LabelModalSC htmlFor="port">Port name</LabelModalSC>
          <InputModalSC
            type="text"
            id="port"
            name="port"
            value={responseRoute.port}
            onChange={e => handleRouteChange(index, 'port', e.target.value)}
          />
        </InputVerticalGroupModalSC>

        <InputVerticalGroupModalSC center margin=' 0 0 20px 10px'>
          <LabelModalSC>Color</LabelModalSC>
          <InputColorModalSC value={sourceColor} onChange={e => handleUpdateSourceColor(responseRoute.id, e.target.value)} />
        </InputVerticalGroupModalSC>

      </FlexContSBModalSC>

      <FlexContSBModalSC>
        <InputVerticalGroupModalSC maxWidth='47%'>
          <LabelModalSC htmlFor="text">Text</LabelModalSC>
          <InputModalSC
            type="text"
            id="text"
            name="text"
            value={responseRoute.text}
            onChange={e => handleRouteChange(index, 'text', e.target.value)}
          />
        </InputVerticalGroupModalSC>

        <InputVerticalGroupModalSC maxWidth='47%'>
          <LabelModalSC htmlFor="intentId">Intents</LabelModalSC>
          <SelectModalSC
            name="intentId"
            id="intentId"
            value={responseRoute.intentId}
            onChange={e => handleRouteChange(index, 'intentId', e.target.value)}
          >
            <option value="">Selecciona la intención</option>
            {intents.map((intent, index) => {
              return (
                <option key={index} value={intent.id}>
                  {intent.name}
                </option>
              );
            })}
          </SelectModalSC>
        </InputVerticalGroupModalSC>
      </FlexContSBModalSC>

      <InputVerticalGroupModalSC >
        <LabelModalSC htmlFor="responseKey">Response Key</LabelModalSC>
        <InputModalSC
          type="text"
          id="responseKey"
          name="responseKey"
          value={responseRoute.responseKey}
          onChange={e =>
            handleRouteChange(index, 'responseKey', e.target.value)
          }
        />
      </InputVerticalGroupModalSC>

      <InputVerticalGroupModalSC margin='0'>
        <LabelModalSC htmlFor="possibleResponses">Possible Responses</LabelModalSC>
        <InputModalSC type="text" id="possibleResponses" name="possibleResponses" />
      </InputVerticalGroupModalSC>
    </WhiteContModalSC>
  );
};

export default Route;
