import RightBar from 'components/Report/organisms/rightBar';
import React from 'react';
import styled from 'styled-components';

const ConfigRightBarTemplate = () => {
  return (
    <RightBar>
      
    </RightBar>
  );
};
const Cont = styled.div``;
export default ConfigRightBarTemplate;