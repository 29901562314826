import { darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import { IQueue, IUser } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SelectableAgent, { IAgentToSelect } from '../atoms/selectableAgent';
import FormFileConfig from '../atoms/formFileConfig';
import { IFile } from '../molecules/FormLoadingFiles';
import { IPBXCampaignInfo, ITrunks } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import QueueList from '../molecules/queueList';
import { ISelectableQueue } from '../atoms/selectableQueue';
import { useTranslation } from 'react-i18next';
import { ICreateCampaignFileContacts } from 'library/interfaces/campaignInterfaces';

export interface IContact {
  id: string;
  contact: string;
}
interface ICreateCampaignConfigContactSourcesProps {
  files: IFile[];
  setFiles: (files: IFile[]) => void;
  setFileContacts: Function;
  fileContact: ICreateCampaignFileContacts[][];
  agentsToSelect: IAgentToSelect[];
  setAgentsToSelect: Function;
  selectedTrunk: {id: string, name: string};
  setTrunk: Function;
  queuesToSelect: ISelectableQueue[];
  setQueuesToSelect: Function;
  campaignNumbers: { callerId: string; did: string }
  setCampaignNumbers: (campaignNumbers: { callerId: string; did: string }) => void;
}
const CreateCampaignConfigContactSources: FC<
  ICreateCampaignConfigContactSourcesProps
> = ({
  files,
  setFiles,
  setFileContacts,
  fileContact,
  agentsToSelect,
  setAgentsToSelect,
  selectedTrunk,
  setTrunk,
  queuesToSelect,
  setQueuesToSelect,
  campaignNumbers,
  setCampaignNumbers
}) => {
  const {t} = useTranslation('outbound')
  const [showQueues, setShowQueues] = useState(false);
  const [selectAllAgents, setSelectAllAgents] = useState(false);
  const PBXCampaignInfo = useSelector<IStore, IPBXCampaignInfo | null>(
    store => store.voiceCampaigns.pxbCampaignInfo,
  );
  const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    const { name, value} = event.target
    setCampaignNumbers({
      ...campaignNumbers,
      [name]: value
    })
  }

  const handleSelectTrunk = (event: React.ChangeEvent<HTMLSelectElement>) =>{
    const {value} = event.target
    if(!PBXCampaignInfo || !value) return
    const selectedTrunk = PBXCampaignInfo.trunks.find(trunk=>trunk.id === value)
    setTrunk(selectedTrunk)
  }
  const handleShowQueues = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setShowQueues(!showQueues);
  };

  const handleSetFileContacts = (index: number, contacts: ICreateCampaignFileContacts[]): void => {
    const newfileContactState: ICreateCampaignFileContacts[][] = fileContact;
    newfileContactState[index] = contacts;
    setFileContacts([...newfileContactState]);
  };

  const handleSelectAgentsByQueue = (queueId: string, index: number) => {
    if (!PBXCampaignInfo) return;
    // take the first queue filter, the queue id should be unique
    const selectedQueue = PBXCampaignInfo.queues.filter(
      queue => queue.id === queueId,
    )[0];
    
    // POC has not defined agents
    if(!selectedQueue?.agents) return

    // map the agent to select array
    const newAgentsToSelect = agentsToSelect.map(agent => {

      //find the current agent in the agent array from the selected queue
      const agentExistInQueue = selectedQueue.agents.find(
        agentID => agentID.id === agent.exten,
      );

      // if exist the agent and is not selected, toogle to isSelected to true
      if (agentExistInQueue && !agent.isSelected && !queuesToSelect[index].isSelected)
        return { ...agent, isSelected: true };

      // if exist the agent a is selected, toogle to isSelected to false deselecting the agent
      else if (agentExistInQueue && agent.isSelected && queuesToSelect[index].isSelected)
        return { ...agent, isSelected: false };

      // if the current agent doesn't exist in the selected queue, return the agent without change
      return agent;
    });
    const newQueuesToSelect = queuesToSelect;
    newQueuesToSelect[index].isSelected = !queuesToSelect[index].isSelected;
    
    setQueuesToSelect([...newQueuesToSelect]);
    setAgentsToSelect([...newAgentsToSelect]);
    if(selectAllAgents) setSelectAllAgents(false)
  };

  const handleSetAgents = (index: number, isSelected: boolean) => {
    const newAgentsToSelectState = agentsToSelect;
    newAgentsToSelectState[index].isSelected = isSelected;
    setAgentsToSelect([...newAgentsToSelectState]);
    setSelectAllAgents(false)
  };

  const handleSelectAllAgents = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let newAgentsToSelectState: IAgentToSelect[];
    if (event.target.checked) {
      newAgentsToSelectState = agentsToSelect.map(agent => {
        return { ...agent, isSelected: true };
      });
    } else {
      newAgentsToSelectState = agentsToSelect.map(agent => {
        return { ...agent, isSelected: false };
      });
    }
    const newQueuesToSelect: ISelectableQueue[] = queuesToSelect.map(queue=>{
      return{...queue, isSelected: false}
    })
    setQueuesToSelect([...newQueuesToSelect])
    setAgentsToSelect([...newAgentsToSelectState]);
    setSelectAllAgents(!selectAllAgents)
  };

  const handleSelectColumnToSave = (
    fileIndex: number, 
    fileUpdated: IFile
  ) => {
    const newFiles = files.splice(fileIndex, 1, fileUpdated)
    setFiles([...newFiles])
  }
  return (
    <Cont>
      <FilesCont>
        <InputsCont>
          <SmalInputCont>
              <label>{t('campaigns.createCampaignForm.contactSources.callerId')}</label>
              <input
                type="text"
                name="callerId"
                value={campaignNumbers.callerId}
                onChange={e => handleInputTextChange(e)}
              />
          </SmalInputCont>
          <SmalInputCont>
              <label>{t('campaigns.createCampaignForm.contactSources.did')}</label>
              <input
                type="text"
                name="did"
                value={campaignNumbers.did}
                onChange={e => handleInputTextChange(e)}
              />
          </SmalInputCont>
        </InputsCont>
        <Title>{t('campaigns.createCampaignForm.contactSources.selectContactSource')}</Title>
        {files.map((file, FileIndex) => {
          return (
            <FormFileConfig
              key={FileIndex}
              FileIndex={FileIndex}
              file={file}
              handleSelectColumnToSave={handleSelectColumnToSave}
              setFileContacts={handleSetFileContacts}
            />
          );
        })}
      </FilesCont>
      <AgentsCont>
        <SelectCont>
        {t('campaigns.createCampaignForm.contactSources.trunk')}:
          <TrunkSelect onChange={e => handleSelectTrunk(e)} defaultValue={selectedTrunk.id}>
            <option value="">{t('campaigns.createCampaignForm.contactSources.selectTrunk')}</option>
            {PBXCampaignInfo?.trunks.map((trunk, index) => {
              return (
                <option key={index} value={trunk.id}>
                  {trunk.name}
                </option>
              );
            })}
          </TrunkSelect>
        </SelectCont>

        <Title>
          <div>{t('campaigns.createCampaignForm.contactSources.selectAgents')}</div>
          {queuesToSelect.length > 0 && <button onClick={e => handleShowQueues(e)}>
          {t('campaigns.createCampaignForm.contactSources.selectAgentsByQueue')}
          </button>}
          {showQueues && (
            <QueueList
              queuesToRender={queuesToSelect}
              selectAgentsByQueue={handleSelectAgentsByQueue}
              handleExit={handleShowQueues}
            />
          )}
        </Title>
        <AgentList>
          <SelectAllCont>
            <input
              type="checkbox"
              name="selectAll"
              id="selectAll"
              checked={selectAllAgents}
              onChange={e => handleSelectAllAgents(e)}
            />
            <SelectAllLabel>{t('campaigns.createCampaignForm.contactSources.selectAllAgents')}</SelectAllLabel>
          </SelectAllCont>
          {agentsToSelect.map((agent, index) => {
            return (
              <SelectableAgent
                key={index}
                index={index}
                agent={agent}
                handleSetAgents={handleSetAgents}
              />
            );
          })}
        </AgentList>
      </AgentsCont>
    </Cont>
  );
};
const Cont = styled.div`
  height: 420px;
  display: flex;
`;
const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & > button {
    width: 50%;
    font-size: 0.8rem;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 5px 0;
    background-color: ${primary};
  }
  & > div {
    line-height: 0;
  }
`;
const FilesCont = styled.div`
  flex-grow: 1;
  max-width: 50%;
  margin-right: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
const AgentsCont = styled(FilesCont)`
  margin-right: 0;
  margin-left: 10px;
`;
const SelectCont = styled.div`
  font-size: 1.2rem;
  line-height: 0;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TrunkSelect = styled.select`
  width: 50%;
  height: 25px;
  font-size: 0.8rem;
  text-align: center;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const AgentList = styled.div`
  height: 75%;
  padding: 5px 10px;
  padding-right: 0;
  border-radius: 10px;
  background-color: white;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const SelectAllCont = styled.div`
  padding: 5px 0px 5px 10px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${darkBorder};
`;
const SelectAllLabel = styled.label`
  font-weight: 500;
  margin: 0 20px;
  font-size: 0.9rem;
`;

const InputsCont = styled.div`
  margin-bottom: 20px;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`;
const SmalInputCont = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  
  & > input {
    text-align: center;
    max-width: 160px;
    font-size: .7rem;
    padding: 10px 0;
    border: none;
  }
`;
export default CreateCampaignConfigContactSources;
