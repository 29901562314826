import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getEmailSession,
  getEmailsReport,
  getEventFromBotData,
  getReport,
  getReportCalls,
  getReportTypesRequest,
  getWhatsappUsage,
  postReportRequest,
  postReportRequest_v2,
} from 'helpers/fakebackend_helper';
import {
  getEmailSessionFail,
  getEmailSessionSuccess,
  getEmailsByFilterFail,
  getEmailsByFilterSuccess,
  getEventFromBotDataFail,
  getEventFromBotDataSuccess,
  getReportFail,
  getReportSuccess,
  getReportTotalCallsByOriginFail,
  getReportTotalCallsByOriginSuccess,
  getReportTypesFail,
  getReportTypesSuccess,
  getWhatsappUsageFail,
  getWhatsappUsageSuccess,
  requestReportFail,
  requestReportSuccess,
  updateEventsFromBotDataCounter,
} from './actions';
import {
  GET_EMAILS_BY_FILTER,
  GET_EMAIL_SESSION,
  GET_EVENTS_FROM_BOT_DATA,
  GET_REPORT_CALLS_DETAIL_EXCEL,
  GET_REPORT_CALLS_DETAIL_EXCEL_V2,
  GET_REPORT_CALLS_SUMMARIZE,
  GET_REPORT_CALLS_SUMMARIZE_V2,
  GET_REPORT_EVENTS_FROM_BOT_1234,
  GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2,
  GET_REPORT_TOTAL_CALLS_BY_ORIGIN,
  GET_REPORT_TOTAL_CALLS_BY_STATUS,
  GET_REPORT_TYPES,
  GET_WHATSAPP_USAGE,
  IGetEmailByFilters,
  IGetEmailSession,
  IGetEventsFromBotData,
  IGetReport,
  IGetReportTotalCallsByOrigin,
  IGetReportTypes,
  IGetReport_V2,
  IGetWhatsappUsage,
  IRequestReport,
  REQUEST_REPORT_CALLS_DETAIL_EXCEL,
  REQUEST_REPORT_EVENTS_FROM_BOT_1234,
  REQUEST_REPORT_TOTAL_CALLS_BY_STATUS,
} from './actionTypes';
import { IError, IReportData, IReportType } from './reducer';
import {
  IEmail,
  IEventFromBotData,
  ITotalCallsByOrigin,
  IWhatsappUsage,
  IWhatsappUsageCounter,
  IWhatsappUsageCounterKeys,
} from 'library/interfaces/reportsInterfaces';
import { localDateToUTC_formatISO } from 'library/services/dateServices';
import { colorHEX } from 'library/services/functions';

function* onGetReportTypes({ payload: { owner } }: IGetReportTypes) {
  try {
    const response: IReportType[] = yield call(
      getReportTypesRequest,
      owner.toLowerCase(),
    );
    yield put(getReportTypesSuccess(response));
  } catch (e) {
    const error: IError = {
      message: 'Something went wrong getting report types!',
      details: `${e}`,
    };
    yield put(getReportTypesFail(error));
  }
}

function* onGetReportTotalCallsByOrigin({
  payload: { owner, start_date, end_date, exten },
}: IGetReportTotalCallsByOrigin) {
  try {
    let queue = '';
    if (exten && exten.startsWith('62')) {
      queue = '112';
    } else if (exten && exten.startsWith('61')) {
      queue = '102';
    }
    const response: ITotalCallsByOrigin = yield call(
      getReportCalls,
      owner.toLowerCase(),
      start_date,
      end_date,
      queue,
    );
    yield put(getReportTotalCallsByOriginSuccess(response));
  } catch (e) {
    const error: IError = {
      message: 'Something went wrong getting the report!',
      details: `${e}`,
    };
    yield put(getReportTotalCallsByOriginFail(error));
  }
}

function* onRequestReport({
  payload: { owner, start_date, end_date, reportTypeId, action },
}: IRequestReport) {
  try {
    const reportId: string = yield call(
      postReportRequest,
      owner.toLowerCase(),
      start_date,
      end_date,
      reportTypeId,
    );
    yield put(requestReportSuccess(reportId, `${action}_SUCCESS`));
  } catch (e) {
    const error: IError = {
      message: 'Something went wrong requesting the report id!',
      details: `${e}`,
    };
    yield put(requestReportFail(error, `${action}_FAIL`));
  }
}

function* onGetReport({
  payload: { owner, requestedReportId, action },
}: IGetReport) {
  try {
    const response: IReportData = yield call(
      getReport,
      owner.toLowerCase(),
      requestedReportId,
    );
    yield put(getReportSuccess(response, `${action}_SUCCESS`));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong requesting the report!',
      details: `${e}`,
    };
    yield put(getReportFail(error, `${action}_FAIL`));
  }
}

function* onGetReport_V2({
  payload: { owner, start_date, end_date, reportTypeId, action, filters },
}: IGetReport_V2) {
  try {
    const START =
      reportTypeId === '2' || reportTypeId === '3'
        ? start_date
        : localDateToUTC_formatISO(start_date);
    const END =
      reportTypeId === '2' || reportTypeId === '3'
        ? end_date
        : localDateToUTC_formatISO(end_date);
    const response: IReportData = yield call(
      postReportRequest_v2,
      owner.toLowerCase(),
      START,
      END,
      reportTypeId,
      filters,
    );
    yield put(getReportSuccess(response, `${action}_SUCCESS`));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong requesting the report!',
      details: `${e}`,
    };
    yield put(getReportFail(error, `${action}_FAIL`));
  }
}

function* onGetEventsFromBotData({
  payload: { exten, start, end, limit },
}: IGetEventsFromBotData) {
  try {
    let lastKey = '';
    let rows: IEventFromBotData[] = [];

    const EVENTS_TO_IGNORE: { [key: string]: boolean } = {
      WORKFLOW_STEP: true,
      PBX_HANGUP: true,
      CALL_COMPLETED: true,
    };

    const counter: { [key: string]: number } = {};

    const callSecondsTranscript: {
      [uniqueId: string]: number;
    } = {};
    const colors: { [key: string]: string } = {};

    let totalCallsAnsweredByTheBot = 0;
    let totalResolved = 0;

    const incomingCallsUniqueIds: string[] = [];

    do {
      const response: { lastKey: string; rows: IEventFromBotData[] } =
        yield call(getEventFromBotData, exten, start, end, limit, lastKey);

      lastKey = response.lastKey;
      rows.push(...response.rows);

      response.rows.forEach(event => {
        if (event.event === 'CALL_COMPLETED' || event.event === 'PBX_HANGUP') {
          const data: {
            COUNT_PLAYBACK: string;
            COUNT_STT: string;
            COUNT_TTS: string;
          } = JSON.parse(event.data);

          const { COUNT_TTS, COUNT_STT } = data;

          const secondsTranscript =
            (parseInt(COUNT_STT) + parseInt(COUNT_TTS)) / 1000;

          callSecondsTranscript[event.uniqueid] = secondsTranscript;
        }
        if (event.event === 'INCOMING_CALL') {
          incomingCallsUniqueIds.push(event.uniqueid);
        }

        if (EVENTS_TO_IGNORE[event.event]) return;

        if (counter[event.event]) {
          counter[event.event] = counter[event.event] + 1;
        } else {
          counter[event.event] = 1;
          colors[event.event] = colorHEX();
        }
      });

      yield put(updateEventsFromBotDataCounter(counter, colors));
    } while (lastKey !== null);

    let totalSeconds = 0;
    incomingCallsUniqueIds.forEach(uuid => {
      totalSeconds = callSecondsTranscript[uuid]
        ? totalSeconds + callSecondsTranscript[uuid]
        : totalSeconds;
    });

    const keys = Object.keys(counter);

    const EVENTS_TO_EXCLUDE: { [key: string]: boolean } = {
      WORKFLOW_STEP: true,
      INCOMING_CALL: true,
    };

    const EVENTS_TO_INCLUDE: { [key: string]: boolean } = {
      CANCEL_EVENT: true,
      CONFIRMATION_EVENT: true,
      SPECIALTY_NOT_SUPPORTED: true,
      'TRANSFER_EVENT(consulta)': true,
      'TRANSFER_EVENT(estudio_medico)': true,
      'TRANSFER_EVENT(no_existe)': true,
      'TRANSFER_EVENT(sin_agendas)': true,
    };

    keys.forEach(key => {
      if (!EVENTS_TO_EXCLUDE[key] && counter[key]) {
        totalCallsAnsweredByTheBot = totalCallsAnsweredByTheBot + counter[key];
      }
      if (EVENTS_TO_INCLUDE[key] && counter[key]) {
        totalResolved = totalResolved + counter[key];
      }
    });

    const rate = (totalResolved / totalCallsAnsweredByTheBot) * 100;

    const solvedRate =
      exten !== '7500' || isNaN(rate) ? null : parseFloat(rate.toFixed(2));

    const totalMinuteTranscript = totalSeconds / 60;
    yield put(
      getEventFromBotDataSuccess(
        rows,
        solvedRate,
        callSecondsTranscript,
        totalMinuteTranscript,
      ),
    );
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong fetching events from bot data!',
      details: `${e}`,
    };
    yield put(getEventFromBotDataFail(error));
  }
}

function* ongetEmails({
  payload: { lastKey, filter, order, date },
}: IGetEmailByFilters) {
  try {
    const response: { lastKey: string; rows: IEmail[] } = yield call(
      getEmailsReport,
      lastKey,
      order,
      date,
      filter,
    );
    yield put(getEmailsByFilterSuccess(response));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong fetching the emails!',
      details: `${e}`,
    };
    yield put(getEmailsByFilterFail(error));
  }
}

function* onGetEmailSession({ payload: { sessionId } }: IGetEmailSession) {
  try {
    const response: { rows: IEmail[] } = yield call(getEmailSession, sessionId);
    yield put(getEmailSessionSuccess(response.rows));
  } catch (e) {
    const error: IError = {
      message:
        'Something was wrong fetching the emails from the selected session!',
      details: `${e}`,
    };
    yield put(getEmailSessionFail(error));
  }
}

function* onGetWhatsappUsage({
  payload: { appId, from, to },
}: IGetWhatsappUsage) {
  try {
    const response: { partnerAppUsageList: IWhatsappUsage[] } = yield call(
      getWhatsappUsage,
      appId,
      from,
      to,
    );
    if (!response.partnerAppUsageList)
      throw new Error('Something was wrong getting the whatsapp usage report!');

    const counter: IWhatsappUsageCounter = response.partnerAppUsageList.reduce(
      (acc, cur) => {
        return {
          incomingMsg: {
            counter: acc.incomingMsg.counter + cur.incomingMsg,
            color: acc.incomingMsg.color,
          },
          outgoingMediaMsgSKU: {
            counter: acc.outgoingMediaMsgSKU.counter + cur.outgoingMediaMsgSKU,
            color: acc.outgoingMediaMsgSKU.color,
          },
          outgoingMsg: {
            counter: acc.outgoingMsg.counter + cur.outgoingMsg,
            color: acc.outgoingMsg.color,
          },
          marketing: {
            counter: acc.marketing.counter + cur.marketing,
            color: acc.marketing.color,
          },
          service: {
            counter: acc.service.counter + cur.service,
            color: acc.service.color,
          },
          utility: {
            counter: acc.utility.counter + cur.utility,
            color: acc.utility.color,
          },
          authentication: {
            counter: acc.authentication.counter + cur.authentication,
            color: acc.authentication.color,
          },
          bic: {
            counter: acc.bic.counter + cur.bic,
            color: acc.bic.color,
          },
          fep: {
            counter: acc.fep.counter + cur.fep,
            color: acc.fep.color,
          },
          ftc: {
            counter: acc.ftc.counter + cur.ftc,
            color: acc.ftc.color,
          },
          gsFees: {
            counter: acc.gsFees.counter + cur.gsFees,
            color: acc.gsFees.color,
          },
          templateMediaMsgSKU: {
            counter: acc.templateMediaMsgSKU.counter + cur.templateMediaMsgSKU,
            color: acc.templateMediaMsgSKU.color,
          },
          templateMsg: {
            counter: acc.templateMsg.counter + cur.templateMsg,
            color: acc.templateMsg.color,
          },
          totalFees: {
            counter: acc.totalFees.counter + cur.totalFees,
            color: acc.totalFees.color,
          },
          totalMsg: {
            counter: acc.totalMsg.counter + cur.totalMsg,
            color: acc.totalMsg.color,
          },
          uic: {
            counter: acc.uic.counter + cur.uic,
            color: acc.uic.color,
          },
          waFees: {
            counter: acc.waFees.counter + cur.waFees,
            color: acc.waFees.color,
          },
        };
      },
      {
        incomingMsg: {
          counter: 0,
          color: colorHEX(),
        },
        outgoingMediaMsgSKU: {
          counter: 0,
          color: colorHEX(),
        },
        outgoingMsg: {
          counter: 0,
          color: colorHEX(),
        },
        marketing: {
          counter: 0,
          color: colorHEX(),
        },
        service: {
          counter: 0,
          color: colorHEX(),
        },
        utility: {
          counter: 0,
          color: colorHEX(),
        },
        authentication: {
          counter: 0,
          color: colorHEX(),
        },
        bic: {
          counter: 0,
          color: colorHEX(),
        },
        fep: {
          counter: 0,
          color: colorHEX(),
        },
        ftc: {
          counter: 0,
          color: colorHEX(),
        },
        gsFees: {
          counter: 0,
          color: colorHEX(),
        },
        templateMediaMsgSKU: {
          counter: 0,
          color: colorHEX(),
        },
        templateMsg: {
          counter: 0,
          color: colorHEX(),
        },
        totalFees: {
          counter: 0,
          color: colorHEX(),
        },
        totalMsg: {
          counter: 0,
          color: colorHEX(),
        },
        uic: {
          counter: 0,
          color: colorHEX(),
        },
        waFees: {
          counter: 0,
          color: colorHEX(),
        },
      },
    );

    yield put(getWhatsappUsageSuccess(response.partnerAppUsageList, counter));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the whatsapp usage report!',
      details: `${e}`,
    };
    yield put(getWhatsappUsageFail(error));
  }
}

function* ReportsSaga() {
  yield takeEvery(GET_REPORT_TYPES, onGetReportTypes);
  yield takeEvery(
    GET_REPORT_TOTAL_CALLS_BY_ORIGIN,
    onGetReportTotalCallsByOrigin,
  );
  yield takeEvery(REQUEST_REPORT_TOTAL_CALLS_BY_STATUS, onRequestReport);
  yield takeEvery(GET_REPORT_TOTAL_CALLS_BY_STATUS, onGetReport);
  yield takeEvery(REQUEST_REPORT_CALLS_DETAIL_EXCEL, onRequestReport);
  yield takeEvery(GET_REPORT_CALLS_DETAIL_EXCEL, onGetReport);
  yield takeEvery(GET_REPORT_CALLS_SUMMARIZE, onGetReport);
  yield takeEvery(REQUEST_REPORT_EVENTS_FROM_BOT_1234, onRequestReport);
  yield takeEvery(GET_REPORT_EVENTS_FROM_BOT_1234, onGetReport);
  yield takeEvery(GET_REPORT_CALLS_DETAIL_EXCEL_V2, onGetReport_V2);
  yield takeEvery(GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2, onGetReport_V2);
  yield takeEvery(GET_REPORT_CALLS_SUMMARIZE_V2, onGetReport_V2);
  yield takeEvery(GET_EVENTS_FROM_BOT_DATA, onGetEventsFromBotData);
  yield takeEvery(GET_EMAILS_BY_FILTER, ongetEmails);
  yield takeEvery(GET_EMAIL_SESSION, onGetEmailSession);
  yield takeEvery(GET_WHATSAPP_USAGE, onGetWhatsappUsage);
}
export default ReportsSaga;
