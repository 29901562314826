import React, { FC } from 'react';
import styled from 'styled-components';

import { darkText, lightBackground } from 'library/colors';

import { useTranslation } from 'react-i18next';
import { II18nKeys } from 'library/interfaces/i18nKeys';

//
// ******* component with i18n translation *******
//
interface IPageTitleProps {
  i18nKeys: II18nKeys;
}
const PageTitle: FC<IPageTitleProps> = ({ i18nKeys }) => {
  // i18n translation
  const { namespace, key } = i18nKeys;
  const { t } = useTranslation(namespace);
  const title = t(key);

  return (
    <>
      <H1>
        <Span>{title}</Span>
      </H1>
    </>
  );
};
const H1 = styled.h1`
  font-weight: 700;
  font-size: 1rem;
  margin: 0px auto 30px;
  color: ${darkText};
`;
const Span = styled.span`
  padding: 4px 25px;
  border-radius: 30px;
  text-transform: uppercase;
  background-color: ${lightBackground};
`;
export default PageTitle;
