const ASESP_JOSE_I_FAVORITE_TEMPLATES = [
  {
    templateId: '6b3e63f4-b4b8-4705-87ea-8caf50038b4d',
    templateName: 'consultas_pre',
  },
  {
    templateId: 'd578556b-675b-40e1-bfbb-4969a14bc0fd',
    templateName: 'teleconsultas_tec2',
  },
  {
    templateId: '37b4988b-7d0d-4e81-938d-69364b785ac5',
    templateName: 'consultas_telefonicas_tel',
  },
  {
    templateId: 'a93d31ad-09b9-4806-aa9b-3bca6ce15281',
    templateName: 'conoloscopia3',
  },
  {
    templateId: 'f68a6381-191b-486a-b487-846ae9e2d918',
    templateName: 'estudio_notif',
  },
  {
    templateId: 'b7465e34-997e-44e2-aef7-a0d942874224',
    templateName: 'estudio_notif3',
  },
  {
    templateId: 'd0794098-c787-4fc4-93ac-302aee5e99d3',
    templateName: 'medic_notif10',
  },
  {
    templateId: 'a4063a17-29ea-4e75-bcf0-5b22b91ceb63',
    templateName: 'medic_notif9',
  },
  {
    templateId: '05542a9c-a03b-43a3-a06c-f765c4c83cd3',
    templateName: 'medic_noti8',
  },
  {
    templateId: '710beb0a-9c3d-4eef-9c4c-d8c990832caf',
    templateName: 'medic_noti7',
  },
  {
    templateId: '729d7913-f797-4152-a4c9-be997cafdef4',
    templateName: 'medic_notif6',
  },
  {
    templateId: '980f5b5c-9310-4cd6-8f3e-6041dbc3cc51',
    templateName: 'medic_notif5',
  },
  {
    templateId: '6aa17712-7351-4ad9-ac02-ac0389fac4a6',
    templateName: 'medic_notif4',
  },
  {
    templateId: '2d3f3ee9-1361-4035-87e6-d5c9ac3c0c9e',
    templateName: 'medic_notif3',
  },
  {
    templateId: '6e84bcd7-ee90-4d1c-8cd1-2a59d9ef65ed',
    templateName: 'medic_notif2',
  },
  {
    templateId: '8c173451-ad78-473a-9487-06361ee6b5e5',
    templateName: 'medic_notif1',
  },
];
export default ASESP_JOSE_I_FAVORITE_TEMPLATES;
