import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { getAws } from 'helpers/api_aws_helper';
import { post } from 'helpers/api_helper';
import { primary } from 'library/colors';
import { IEventFromBotData } from 'library/interfaces/reportsInterfaces';
import { localDateToUTC_formatISO } from 'library/services/dateServices';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

interface IEventDetailedProps {
  event: IEventFromBotData
  owner: string
  callMinuteTranscript: number | undefined
}
const EventDetailed: FC<IEventDetailedProps> = ({ event, owner, callMinuteTranscript }) => {

  const [presignedUri, setPresignedUri] = useState<{
    isLoading: boolean,
    error: string | null,
    uri: string | null,
  }>({
    isLoading: false,
    error: null,
    uri: null,
  });

  const getPresignedUri = async () => {
    try {
      setPresignedUri(prev => ({
        ...prev,
        isLoading: true,
        error: null,
      }));
      const recording: { audioUrl: string } = await getAws(`/calls/recordings/owner/${owner.toLowerCase()}/call/${event.uniqueid}`)
      if (!recording) {
        setPresignedUri(prev => ({
          ...prev,
          isLoading: false,
          error: 'Algo ha ido mal',
          uri: null,
        }));
        throw new Error('No existe la grabación')
      }
      const audioPath = recording.audioUrl.startsWith('/') ? recording.audioUrl.substr(1) : recording.audioUrl;

      const uri = await post('/bucket/audio/signedurl', {
        key: audioPath,
      });
      setPresignedUri(prev => ({
        ...prev,
        isLoading: false,
        error: null,
        uri: uri,
      }));
    } catch (error) {
      console.log(error)
      setPresignedUri(prev => ({
        ...prev,
        isLoading: false,
        error: 'Algo ha ido mal',
        uri: null,
      }));
    }
  };

  return (
    <Cont >
      <div>{event.uniqueid}</div>
      <div>{event.event}</div>
      <div>{event.callerid}</div>
      <div>{
        !presignedUri.isLoading &&
        !presignedUri.uri &&
        !presignedUri.error && (
          <PlayButton onClick={getPresignedUri}>
            <span>&#9654;</span>
          </PlayButton>
        )}

        {presignedUri.isLoading && (
          <span>
            Cargando audio &nbsp;
            <LoadingPoints />
          </span>
        )}
        {presignedUri.error && (
          <span>
            {presignedUri.error}{' '}
            <Refresh onClick={getPresignedUri}>&nbsp; Refrescar</Refresh>
          </span>
        )}

        {presignedUri.uri && (
          <audio
            controls
            className="audio"
            preload="auto"
            src={presignedUri.uri}
            //@ts-ignore
            type="audio/wav"

          />
        )}</div>
      <div>{callMinuteTranscript && callMinuteTranscript.toFixed(2)}</div>
      <div>{event.data}</div>
    </Cont>
  );
};
const Cont = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 2fr;
`;
const PlayButton = styled.button`
  border: none;
  width: 40px;
  height: 20px;
  padding-left: 10px;
  border-radius: 50px;

  & > span {
    display: block;
    line-height: 0;
    color: ${primary};
  }
`;
const Refresh = styled.span`
  color: ${primary};
  cursor: pointer;
`;
export default EventDetailed;