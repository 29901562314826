import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PageTitle from '../atoms/pageTitle';
import Container from '../atoms/container';
import RightBar from './rightBarTemplate';
import QueueSelectLiveBoard from '../organisms/queueSelectLiveBoard';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { getReportData } from 'store/actions';
import { subscribeLiveData } from 'helpers/appSyncLiveBoard';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';
import { ISummarizeAgent } from 'library/interfaces/summarizeInterface';
import { useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';
import { IReportData } from 'library/interfaces/crdInterfaces';
import AgentResume from '../organisms/agentResume';

interface IAgentCallsDetailedTemplateProps {
  queueId: string;
}

const AgentCallsDetailedTemplate = ({ queueId }: IAgentCallsDetailedTemplateProps) => {

  const dispatch = useAppDispatch();
  const [live, setLive] = useState<ILiveDataQueue[]>([]);

  const owner = useAppSelector(store => store.authUserData.owner);
  const { queues, users } = useSelector<IStore, IReportData>(
    state => state.cdr.reportData
  );

  const data = useMemo<ISummarizeAgent[]>(() => {
    const agentsStatus = live.find(item => item.id === queueId)?.agentsStatus || []

    const agents = agentsStatus.map(item => {
      const agentInfo = users.filter(
        user => user.exten_name === item.id,
      )[0];


      return {
        ...agentInfo,
        agent: item.id,
        state: item.state,
        serviceLevel: {
          answered: item.answeredCount,
          unanswered: item.unansweredCount,
          serviceLevel: item.serviceLevel === -1 ? 0 : item.serviceLevel,
          availableDuration: item.availableDuration
        },
      };
    });
    function compareFunction(a: ISummarizeAgent, b: ISummarizeAgent) {
      if (b.state === 'unavailable' && a.state !== "unavailable") {
        return -1
      }
      return 0
    }
    agents.sort(compareFunction)
    return agents

  }, [queueId, live, users])

  useEffect(() => {
    if (queues.length === 0) dispatch(getReportData());

    if (!owner) return;
    const subscription = subscribeLiveData(
      owner.toLowerCase(),
      (data: ILiveDataQueue[]) => setLive(data),
    );
    return () => subscription.unsubscribe();
  }, [owner]);

  return (
    <>
      <PageTitle>Llamadas de agentes</PageTitle>
      <FlexCont>
        <Container padding="20px">
          <QueueSelectLiveBoard
            mainPath="/live-board/detailed-calls"
            redirectPath="/live-board/detailed-calls/detailed"
            live
            queues={queues}
          />

          <Container padding="30px 30px 10px" mediumBackground>
            {data.map((item, index) => {
              return (
                <AgentResume key={index} agent={item} />
              )
            })}
          </Container>
        </Container>
        <RightBar />
      </FlexCont>
    </>
  );
};
const FlexCont = styled.div`
  display: flex;
  min-height: 75vh;
  `;

export default AgentCallsDetailedTemplate;