import { lightBackgroundBlue, lightGreen, popUpBackground, primary } from 'library/colors';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import UploadFIleDropZone from '../organisms/uploadFileDropZone';
import { post, postNoDestructuring } from 'helpers/api_helper';
import { File } from 'buffer';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { uploadFileAction } from 'store/files/asyncActions';

interface IGalleryUploadFilePopUpProps {
  setShow: (show: boolean) => void
}
const GalleryUploadFilePopUp: FC<IGalleryUploadFilePopUpProps> = ({ setShow }) => {
  const [file, setFile] = useState<File | null>(null)
  const dispatch = useAppDispatch()

  const sendFile = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!file) return

    const form = new FormData()
    //@ts-ignore
    form.append('file', file)

    dispatch(uploadFileAction(form))
    setShow(false)
  }
  return (
    <PopUpContainer>
      <FormContainer>
        <Exit id="exitButton" onClick={() => setShow(false)}>X</Exit>
        <HeaderCont>Cargar Archivos</HeaderCont>

        <BlueCont>
          <UploadFIleDropZone file={file} setFile={setFile} />

          <FilesButtonsCont>
            {file && <FileButton onClick={e => setFile(null)}>
              {file.name}
              <i className="bx bx-trash"></i>
            </FileButton>}
          </FilesButtonsCont>
        </BlueCont>
        <ButtonsCont>
          <BackButton onClick={() => setShow(false)}>Cancelar</BackButton>
          <NextButton onClick={e => sendFile(e)}>Guardar</NextButton>
        </ButtonsCont>
      </FormContainer>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.form`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 700px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const HeaderCont = styled.div`
text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
const BlueCont = styled.div`
  height: 320px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: ${lightBackgroundBlue};

  /* & > :last-child {
    flex-grow: 1;
    border: 1px solid; 
  } */
`;
const ButtonsCont = styled.div`
height: 80px;
  padding: 0px 30px;
  margin: 20px auto;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: ${lightBackgroundBlue};
`;
const NextButton = styled.button`
  color: white;
  font-size: 1rem;
  font-weight: 700;
  width: 130px;
  border-radius: 50px;
  margin-left: 20px;
  border: 2px solid ${primary};
  background-color: ${primary};
`;
const BackButton = styled(NextButton)`
  color: black;
  font-weight: 300;
  background-color: transparent;
`;
const FilesButtonsCont = styled.div`
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const FileButton = styled.button`
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid ${lightGreen};

  &:hover {
    color: red;
    border: 1px solid red;
    text-decoration: line-through;
  }

  & > i {
    line-height: 0px;
    font-size: 1.2rem;
    font-weight: 00;
    display: inline-block;
    margin-left: 5px;
    transform: translateY(3px);
  }
`;
export default GalleryUploadFilePopUp;