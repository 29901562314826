import {
  ISetSysNotifications,
  IUpdateShowSysNotifications,
  SET_SYS_NOTIFICATIONS,
  SHOW_INCOMING_CALL_SYS_NOTIFICATION,
  SHOW_QUEUED_CALLS_NO_ANSWERED_SYS_NOTIFICATION,
  UPDATE_SHOW_SYS_NOTIFICATIONS,
} from './actionTypes';
import { ISysNotificationsStore } from './reducer';

export const setSysNotifications = (sysNotificationsStoraged: ISysNotificationsStore): ISetSysNotifications => ({
  type: SET_SYS_NOTIFICATIONS,
  payload: sysNotificationsStoraged
})

export const updateShowSysNotificationsAction = (
  show: boolean,
): IUpdateShowSysNotifications => ({
  type: UPDATE_SHOW_SYS_NOTIFICATIONS,
  payload: { show },
});

export const showPhoneIncomingCallsSysNotificationAction = (
  show: boolean,
): IUpdateShowSysNotifications => ({
  type: SHOW_INCOMING_CALL_SYS_NOTIFICATION,
  payload: { show },
});

export const showQueuedCallsSysNotificationAction = (
  show: boolean,
): IUpdateShowSysNotifications => ({
  type: SHOW_QUEUED_CALLS_NO_ANSWERED_SYS_NOTIFICATION,
  payload: { show },
});
