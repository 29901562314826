import { all, fork } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import LayoutSaga from './layout/saga';
import scenariosSaga from './scenarios/saga';
import agentsSaga from './agents/saga';
import cdrSaga from './cdr/saga';
import recordingsSaga from './recordings/saga';
import ChatBotSaga from './chatBot/saga';
import voiceCampaignsSaga from './outboundCampaigns/voiceCampaigns/saga';
import smsCampaignSaga from './outboundCampaigns/smsCampaigns/saga';
import waCampaignSaga from './outboundCampaigns/waCampaigns/saga';
import ReportsSaga from './reports/saga';
import ConfigurationSaga from './configuration/saga';
import workspaceSaga from './workspace/saga';
import conversationsSaga from './conversation/saga';
import channelsSaga from './channels/saga';
import liveConversationsSaga from './liveConversations/saga';

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(scenariosSaga),
    fork(agentsSaga),
    fork(cdrSaga),
    fork(recordingsSaga),
    fork(ChatBotSaga),
    fork(voiceCampaignsSaga),
    fork(smsCampaignSaga),
    fork(waCampaignSaga),
    fork(ReportsSaga),
    fork(ConfigurationSaga),
    fork(workspaceSaga),
    fork(conversationsSaga),
    fork(channelsSaga),
    fork(liveConversationsSaga),
  ]);
}
