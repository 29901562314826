import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import * as Fields from '../../../Fields';

const optionsLanguage = [
  {
    label: 'Bengali',
    options: [{ label: 'Bengali(Google)', value: 'Bengali(Google)' }],
  },
  {
    label: 'Russian',
    options: [
      { label: 'Russian(Google)', value: 'Russian(Google)' },
      { label: 'Russian(Yandex)', value: 'Russian(Yandex)' },
      { label: 'Russian(Amazon)', value: 'Russian(Amazon)' },
      { label: 'Russian(Tinkoff)', value: 'Russian(Tinkoff)' },
      { label: 'Russian(Microsoft)', value: 'Russian(Microsoft)' },
    ],
  },
  {
    label: 'English (US)',
    options: [
      { label: 'English(US)(Google)', value: 'English (US)(Google)' },
      { label: 'English(US)(Yandex)', value: 'English (US)(Yandex)' },
      { label: 'English(US)(Amazon)', value: 'English (US)(Amazon)' },
      { label: 'English(US)(IBM)', value: 'English (US)(IBM)' },
      { label: 'English(US)(Microsoft)', value: 'English (US)(Microsoft)' },
    ],
  },
];

Language.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
};

function Language({ name, label, options = optionsLanguage }) {
  return (
    <div className="mb-3 select2-container">
      <Label>{label}</Label>
      <Fields.Select2 name={name} options={options} />
    </div>
  );
}

export default Language;
