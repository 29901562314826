import React, { FC } from "react";
import styled from "styled-components";

import { secondary } from "library/colors";
import { useTranslation } from "react-i18next";

interface IChangePrevProps{
    change: string | number; 
    prev: string | number;
    center?: React.ReactNode;
    big?: React.ReactNode;
}
const ChangePrev: FC<IChangePrevProps> = ({change, prev, center, big}) =>{
    const {t} = useTranslation('summarize')
    return(
        <Container center={center}>
            <Div big={big} /* borderRight */ >
                <div>{change}</div>
                <div>{t('change')}</div>
            </Div>
            {/* <Div big={big}>
                <div>{prev}</div>
                <div>prev. 24h</div>
            </Div> */}

        </Container>
    )
}
const Container = styled.div<IContainer>`
color: black;
display: flex;
justify-content: ${props=>props.center && "center"};
`;
const Div = styled.div<IDiv>`
font-size: ${props=>props.big ? '' : '0.62rem'};
    padding: ${props=>props.big ? '0 15px' : '0 10px 0 0'};
    margin-right: ${props=>props.big ? '' : '10px'};
    border-right: ${props=>props.borderRight && `2px solid ${secondary}`}
`;
interface IContainer{
    center?: React.ReactNode
}
interface IDiv{
    borderRight?: React.ReactNode;
    big?: React.ReactNode;
}
export default ChangePrev