import {
  Invitation,
  InvitationAcceptOptions,
  Inviter,
  InviterInviteOptions,
  InviterOptions,
  Message,
  Messager,
  Referral,
  Registerer,
  RegistererOptions,
  RegistererRegisterOptions,
  RegistererState,
  RegistererUnregisterOptions,
  RequestPendingError,
  Session,
  SessionInviteOptions,
  SessionState,
  UserAgent,
  UserAgentOptions,
  UserAgentState,
} from 'sip.js';
import { Buffer } from 'buffer';
import { Logger } from 'sip.js/lib/core';
import {
  SessionDescriptionHandler,
  SessionDescriptionHandlerOptions,
  Transport,
} from 'sip.js/lib/platform/web';
import { IPhoneDelegate, IPhoneHeaders } from './phoneDelegate';
import { IPhoneOptions } from './phoneOptions';

import ringtone from './media/ringtone.mp3';
import outgoingCallTone from './media/outgoingCallTone.mp3';

export class Phone {
  public delegate: IPhoneDelegate | undefined;

  private attemptingReconnection = false;
  private connectRequested = false;
  private logger: Logger;
  private held = false;
  private muted = false;
  private options: IPhoneOptions;
  private registerer: Registerer | undefined = undefined;
  private registerRequested = false;
  private session: Session | undefined = undefined;
  private transferSession: Session | undefined = undefined;
  private userAgent: UserAgent;

  private remoteDisplayName = '';
  private remoteIdentity = '';
  private transferIdentity = '';

  // TODO: fix this shit
  private header: IPhoneHeaders = {
    uniqueId: '',
    queue: '',
    uuid: '',
    callId: '',
    x_callId: undefined,
    contact: '',
    e64_data: [],
    xUuid4UniqueIdFromFront: '',
    blindTransferExt: '',
    blindTransferExtList: [],
    showSuccess: '',
    showInfo: '',
    showError: '',
    showOutgoingCall: '',
    rightBarAnyJsonData: undefined,
  };

  private _showCallNotification = false;
  private _campaignId: string | undefined = undefined;

  public get showCallNotification(): boolean {
    return this._showCallNotification;
  }
  public set showCallNotification(value: boolean) {
    this._showCallNotification = value;
  }

  public get campaignId(): string | undefined {
    return this._campaignId;
  }
  public set campaignId(value: string | undefined) {
    const id = value !== '' ? value : undefined;
    this._campaignId = id;
  }

  constructor(server: string, options: IPhoneOptions) {
    this.delegate = options.delegate;

    this.options = { ...options };

    const userAgentOptions: UserAgentOptions = {
      ...options.userAgentOptions,
    };

    if (!userAgentOptions.transportConstructor) {
      userAgentOptions.transportConstructor = Transport;
    }

    if (!userAgentOptions.transportOptions) {
      userAgentOptions.transportOptions = {
        server,
      };
    }

    if (!userAgentOptions.uri) {
      if (options.aor) {
        const uri = UserAgent.makeURI(options.aor);
        if (!uri) {
          throw new Error(`Failed to create valid URI from ${options.aor}`);
        }
        userAgentOptions.uri = uri;
      }
    }

    this.userAgent = new UserAgent(userAgentOptions);

    this.userAgent.contact.uri.setParam('transport', 'wss');
    this.userAgent.contact.uri.user = userAgentOptions.authorizationUsername;

    this.userAgent.delegate = {
      onConnect: (): void => {
        this.logger.log(`[${this.id}] Connected`);
        if (this.delegate && this.delegate.onServerConnect) {
          this.delegate.onServerConnect();
        }
        if (this.registerer && this.registerRequested) {
          this.logger.log(`[${this.id}] Registering...`);
          this.registerer.register().catch((e: Error) => {
            this.logger.error(
              `[${this.id}] Error occurred registering after connection with server was obtained.`,
            );
            this.logger.error(e.toString());
          });
        }
      },
      onDisconnect: (error?: Error): void => {
        this.logger.log(`[${this.id}] Disconnected`);
        if (this.delegate && this.delegate.onServerDisconnect) {
          this.delegate.onServerDisconnect(error);
        }
        if (this.session) {
          this.logger.log(`[${this.id}] Hanging up...`);
          this.hangup().catch((e: Error) => {
            this.logger.error(
              `[${this.id}] Error occurred hanging up call after connection with server was lost.`,
            );
            this.logger.error(e.toString());
          });
        }
        if (this.registerer) {
          this.logger.log(`[${this.id}] Unregistering...`);
          this.registerer.unregister().catch((e: Error) => {
            this.logger.error(
              `[${this.id}] Error occurred unregistering after connection with server was lost.`,
            );
            this.logger.error(e.toString());
          });
        }

        if (error) {
          this.attemptReconnection();
        }
      },

      onInvite: (invitation: Invitation): void => {
        this.logger.log(`[${this.id}] Received INVITE`);

        if (this.session) {
          this.logger.warn(
            `[${this.id}] Session already in progress, rejecting INVITE...`,
          );
          invitation
            .reject()
            .then(() => {
              this.logger.log(`[${this.id}] Rejected INVITE`);
            })
            .catch((error: Error) => {
              this.logger.error(`[${this.id}] Failed to reject INVITE`);
              this.logger.error(error.toString());
            });
          return;
        }

        const referralInviterOptions: InviterOptions = {
          sessionDescriptionHandlerOptions: { constraints: this.constraints },
        };

        this.initSession(invitation, referralInviterOptions);

        if (invitation.remoteIdentity.uri.user) {
          this.remoteIdentity = invitation.remoteIdentity.uri.user;
        }
        if (invitation.remoteIdentity.displayName) {
          this.remoteDisplayName = invitation.remoteIdentity.displayName;
        } else {
          this.remoteDisplayName = '';
        }

        this.playRingtone();

        const headers = this.getHeaders(invitation);
        this.header = headers;

        if (this.delegate && this.delegate.onCallReceived) {
          this.delegate.onCallReceived(
            headers,
            document.visibilityState,
            this._showCallNotification,
          );
        } else {
          this.logger.warn(
            `[${this.id}] No handler available, rejecting INVITE...`,
          );
          invitation
            .reject()
            .then(() => {
              this.logger.log(`[${this.id}] Rejected INVITE`);
            })
            .catch((error: Error) => {
              this.logger.error(`[${this.id}] Failed to reject INVITE`);
              this.logger.error(error.toString());
            });
        }
      },

      onMessage: (message: Message): void => {
        message.accept().then(() => {
          if (this.delegate && this.delegate.onMessageReceived) {
            this.delegate.onMessageReceived(message.request.body);
          }
        });
      },
    };

    this.logger = this.userAgent.getLogger('sip.SimpleUser');

    window.addEventListener('online', () => {
      this.logger.log(`[${this.id}] Online`);
      this.attemptReconnection();
    });
  }

  private playRingtone() {
    const audioElement = this.options.media?.remote?.mainSession.audio;
    if (!(audioElement instanceof HTMLAudioElement)) {
      throw new Error(`Element not found or not an audio element.`);
    }
    audioElement.src = ringtone;
    audioElement.loop = true;
    audioElement.play();
  }

  private playToneCalling() {
    const audioElement = this.options.media?.remote?.mainSession.audio;
    if (!(audioElement instanceof HTMLAudioElement)) {
      throw new Error(`Element not found or not an audio element.`);
    }
    audioElement.src = outgoingCallTone;
    audioElement.loop = true;
    audioElement.play();
  }

  private stopPreAnswersAudio() {
    const audioElement = this.options.media?.remote?.mainSession.audio;
    if (!(audioElement instanceof HTMLAudioElement)) {
      throw new Error(`Element not found or not an audio element.`);
    }
    audioElement.pause();
    audioElement.loop = false;
    audioElement.removeAttribute('src');
    audioElement.load();
  }

  get id(): string {
    return (
      (this.options.userAgentOptions &&
        this.options.userAgentOptions.displayName) ||
      'Anonymous'
    );
  }

  get localMediaStream(): MediaStream | undefined {
    const sdh = this.session?.sessionDescriptionHandler;
    if (!sdh) {
      return undefined;
    }
    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error(
        'Session description handler not instance of web SessionDescriptionHandler',
      );
    }
    return sdh.localMediaStream;
  }

  private remoteMediaStream(session: Session): MediaStream | undefined {
    const sdh = session?.sessionDescriptionHandler;
    if (!sdh) {
      return undefined;
    }
    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error(
        'Session description handler not instance of web SessionDescriptionHandler',
      );
    }
    return sdh.remoteMediaStream;
  }

  public localStream() {
    return this.localMediaStream;
  }

  public remoteStream() {
    if (!this.session) return;
    return this.remoteMediaStream(this.session);
  }

  public connect(): Promise<void> {
    this.logger.log(`[${this.id}] Connecting UserAgent...`);
    this.connectRequested = true;
    if (this.userAgent.state !== UserAgentState.Started) {
      return this.userAgent.start();
    }
    return this.userAgent.reconnect();
  }

  public disconnect(): Promise<void> {
    this.logger.log(`[${this.id}] Disconnecting UserAgent...`);
    this.connectRequested = false;
    return this.userAgent.stop();
  }

  public isConnected(): boolean {
    return this.userAgent.isConnected();
  }

  public register(
    registererOptions?: RegistererOptions,
    registererRegisterOptions?: RegistererRegisterOptions,
  ): Promise<void> {
    this.logger.log(`[${this.id}] Registering UserAgent...`);
    this.registerRequested = true;

    if (!this.registerer) {
      this.registerer = new Registerer(this.userAgent, registererOptions);
      this.registerer.stateChange.addListener((state: RegistererState) => {
        switch (state) {
          case RegistererState.Initial:
            break;
          case RegistererState.Registered:
            if (this.delegate && this.delegate.onRegistered) {
              this.delegate.onRegistered();
            }
            break;
          case RegistererState.Unregistered:
            if (this.delegate && this.delegate.onUnregistered) {
              this.delegate.onUnregistered();
            }
            break;
          case RegistererState.Terminated:
            this.registerer = undefined;
            break;
          default:
            throw new Error('Unknown registerer state.');
        }
      });
    }

    return this.registerer.register(registererRegisterOptions).then(() => {
      return;
    });
  }

  public unregister(
    registererUnregisterOptions?: RegistererUnregisterOptions,
  ): Promise<void> {
    this.logger.log(`[${this.id}] Unregistering UserAgent...`);
    this.registerRequested = false;

    if (!this.registerer) {
      return Promise.resolve();
    }

    return this.registerer.unregister(registererUnregisterOptions).then(() => {
      return;
    });
  }

  public call(
    destination: string,
    server: string,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions,
  ): Promise<void> {
    this.logger.log(`[${this.id}] Beginning Session...`);

    if (this.session) {
      return Promise.reject(new Error('Session already exists.'));
    }

    const target = UserAgent.makeURI(`sip:${destination}@${server}`);
    if (!target) {
      return Promise.reject(
        new Error(
          `Failed to create a valid URI from "${`sip:${destination}@${server}`}"`,
        ),
      );
    }

    if (!inviterOptions) {
      inviterOptions = {};
    }
    if (!inviterOptions.sessionDescriptionHandlerOptions) {
      inviterOptions.sessionDescriptionHandlerOptions = {};
    }
    if (!inviterOptions.sessionDescriptionHandlerOptions.constraints) {
      inviterOptions.sessionDescriptionHandlerOptions.constraints =
        this.constraints;
    }
    this.remoteIdentity = destination;

    // This code was made to generate a unique id from the front
    // and send it to the pbx at the moment an outgoing call is made
    // with the campaign id if agent is making calls from campaign
    const UNIQUEID = this.generateUUID4();
    this.header.xUuid4UniqueIdFromFront = UNIQUEID;
    this.header.uniqueId = '';
    inviterOptions.extraHeaders = [
      `X-UUID4-UNIQUEID-FROM-FRONT: ${UNIQUEID}`,
      `X-CAMPAIGN-ID: ${this._campaignId ? this._campaignId : 'none'}`,
    ];

    const inviter = new Inviter(this.userAgent, target, inviterOptions);

    this.playToneCalling();

    return this.sendInvite(inviter, inviterOptions, inviterInviteOptions).then(
      () => {
        return;
      },
    );
  }

  public transferCall(
    destination: string,
    server: string,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions,
  ): Promise<void> {
    this.logger.log(`[${this.id}] Beginning Session...`);

    if (this.transferSession) {
      return Promise.reject(new Error('Session already exists.'));
    }

    const target = UserAgent.makeURI(`sip:${destination}@${server}`);
    if (!target) {
      return Promise.reject(
        new Error(
          `Failed to create a valid URI from "${`sip:${destination}@${server}`}"`,
        ),
      );
    }

    if (!inviterOptions) {
      inviterOptions = {};
    }
    if (!inviterOptions.sessionDescriptionHandlerOptions) {
      inviterOptions.sessionDescriptionHandlerOptions = {};
    }
    if (!inviterOptions.sessionDescriptionHandlerOptions.constraints) {
      inviterOptions.sessionDescriptionHandlerOptions.constraints =
        this.constraints;
    }
    this.transferIdentity = destination;

    const inviter = new Inviter(this.userAgent, target, inviterOptions);

    this.playToneCalling();

    return this.sendTransferInvite(
      inviter,
      inviterOptions,
      inviterInviteOptions,
    ).then(() => {
      return;
    });
  }

  public confirmTransfer(): Promise<never> | undefined {
    if (!this.session || !this.transferSession) {
      return Promise.reject(new Error('Session does not exist.'));
    }
    if (this.delegate && this.delegate.onConfirmTransfer) {
      this.delegate.onConfirmTransfer();
    }
    if (this.delegate && this.delegate.onCallHangup) {
      this.delegate.onCallHangup();
    }

    this.transferSession.refer(this.session);

    this.session = undefined;
    return (this.transferSession = undefined);
  }

  public blindTransfer(
    destination: string,
    server: string,
  ): Promise<never> | undefined {
    if (!this.session) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    const target = UserAgent.makeURI(`sip:${destination}@${server}`);
    if (!target) {
      return Promise.reject(
        new Error(
          `Failed to create a valid URI from "${`sip:${destination}@${server}`}"`,
        ),
      );
    }
    if (this.delegate && this.delegate.onBlindTransfer) {
      this.delegate.onBlindTransfer();
    }
    if (this.delegate && this.delegate.onCallHangup) {
      this.delegate.onCallHangup();
    }

    this.session.refer(target, {
      requestOptions: {
        extraHeaders: [`X-CALLID: ${this.header.x_callId}`],
      },
    });

    this.cleanupMainSessionMedia();

    return (this.session = undefined);
  }

  public hangup(): Promise<void> {
    this.logger.log(`[${this.id}] Hangup...`);
    return this.terminate();
  }

  public hangupTransferCall(): Promise<void> {
    this.logger.log(`[${this.id}] Hangup...`);
    return this.terminateTransferCall();
  }

  public answer(
    invitationAcceptOptions?: InvitationAcceptOptions,
  ): Promise<void> {
    this.logger.log(`[${this.id}] Accepting Invitation...`);

    if (!this.session) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    if (!(this.session instanceof Invitation)) {
      return Promise.reject(new Error('Session not instance of Invitation.'));
    }

    if (!invitationAcceptOptions) {
      invitationAcceptOptions = {};
    }
    if (!invitationAcceptOptions.sessionDescriptionHandlerOptions) {
      invitationAcceptOptions.sessionDescriptionHandlerOptions = {};
    }
    if (!invitationAcceptOptions.sessionDescriptionHandlerOptions.constraints) {
      invitationAcceptOptions.sessionDescriptionHandlerOptions.constraints =
        this.constraints;
    }
    return this.session.accept(invitationAcceptOptions);
  }

  public getRemoteDisplayName(): string {
    return this.remoteDisplayName;
  }

  public getRemoteId(): string {
    return this.remoteIdentity;
  }

  public getTransferId(): string {
    return this.transferIdentity;
  }

  public getHeaders(invitation: Invitation): IPhoneHeaders {
    const callId = invitation.request.callId;

    const uniqueId = invitation.request.getHeader('X-UNIQUEID') || '';
    const queue = invitation.request.getHeader('X-QUEUE') || '';
    const uuid = invitation.request.getHeader('X-UUID') || '';
    const contact = invitation.request.getHeader('X-CONTACT') || '';

    const X_E64_DATA = invitation.request.getHeader('X-E64-DATA') || '';
    const data = Buffer.from(X_E64_DATA, 'base64');
    const e64_data: NonNullable<unknown>[] =
      X_E64_DATA !== '' ? JSON.parse(data.toString()) : [];

    const blindTransferExt =
      invitation.request.getHeader('X-BLIND-TRANSFER') || '';

    const X_BLIND_TRANSFER_EXT_LIST = invitation.request.getHeader(
      'X-BLIND-TRANSFER-EXT-LIST',
    );

    const x_callId = invitation.request.getHeader('X-CALLID');

    const blindTransferExtList: { exten: string; label: string }[] | undefined =
      X_BLIND_TRANSFER_EXT_LIST
        ? JSON.parse(X_BLIND_TRANSFER_EXT_LIST)
        : undefined;

    const X_RIGHT_BAR_ANY_JSON_DATA = invitation.request.getHeader(
      'X-RIGHT-BAR-ANY-JSON-DATA',
    );

    const rightBarAnyJsonData = X_RIGHT_BAR_ANY_JSON_DATA
      ? JSON.parse(X_RIGHT_BAR_ANY_JSON_DATA)
      : undefined;

    const showSuccess = invitation.request.getHeader('X-SHOW-SUCCESS') || '';
    const showInfo = invitation.request.getHeader('X-SHOW-INFO') || '';
    const showError = invitation.request.getHeader('X-SHOW-ERROR') || '';
    const showOutgoingCall =
      invitation.request.getHeader('X-SHOW-OUTGOING-CALL') || '';

    return {
      ...this.header,
      uniqueId,
      queue,
      uuid,
      callId,
      x_callId,
      contact,
      e64_data,
      blindTransferExt,
      blindTransferExtList,
      showSuccess,
      showInfo,
      showError,
      showOutgoingCall,
      rightBarAnyJsonData,
    };
  }

  public decline(): Promise<void> {
    this.logger.log(`[${this.id}] rejecting Invitation...`);

    if (!this.session) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    if (!(this.session instanceof Invitation)) {
      return Promise.reject(new Error('Session not instance of Invitation.'));
    }

    return this.session.reject();
  }

  public hold(): Promise<void> {
    this.logger.log(`[${this.id}] holding session...`);
    return this.setHold(true);
  }

  public unhold(): Promise<void> {
    this.logger.log(`[${this.id}] unholding session...`);
    return this.setHold(false);
  }

  public isHeld(): boolean {
    return this.held;
  }

  public mute(): void {
    this.logger.log(`[${this.id}] disabling media tracks...`);
    this.setMute(true);
  }

  public unmute(): void {
    this.logger.log(`[${this.id}] enabling media tracks...`);
    this.setMute(false);
  }

  public isMuted(): boolean {
    return this.muted;
  }

  public message(destination: string, message: string): Promise<void> {
    this.logger.log(`[${this.id}] sending message...`);

    const target = UserAgent.makeURI(destination);
    if (!target) {
      return Promise.reject(
        new Error(`Failed to create a valid URI from "${destination}"`),
      );
    }
    return new Messager(this.userAgent, target, message).message();
  }

  private get constraints(): { audio: boolean; video: boolean } {
    let constraints = { audio: true, video: false };
    if (this.options.media?.constraints) {
      constraints = { ...this.options.media.constraints };
    }
    return constraints;
  }

  private attemptReconnection(reconnectionAttempt = 1): void {
    const reconnectionAttempts = this.options.reconnectionAttempts || 3;
    const reconnectionDelay = this.options.reconnectionDelay || 4;

    if (!this.connectRequested) {
      this.logger.log(`[${this.id}] Reconnection not currently desired`);
      return;
    }

    if (this.attemptingReconnection) {
      this.logger.log(`[${this.id}] Reconnection attempt already in progress`);
    }

    if (reconnectionAttempt > reconnectionAttempts) {
      this.logger.log(`[${this.id}] Reconnection maximum attempts reached`);
      return;
    }

    if (reconnectionAttempt === 1) {
      this.logger.log(
        `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - trying`,
      );
    } else {
      this.logger.log(
        `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - trying in ${reconnectionDelay} seconds`,
      );
    }

    this.attemptingReconnection = true;

    setTimeout(
      () => {
        if (!this.connectRequested) {
          this.logger.log(
            `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - aborted`,
          );
          this.attemptingReconnection = false;
          return;
        }
        this.userAgent
          .reconnect()
          .then(() => {
            this.logger.log(
              `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - succeeded`,
            );
            this.attemptingReconnection = false;
          })
          .catch((error: Error) => {
            this.logger.log(
              `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - failed`,
            );
            this.logger.error(error.message);
            this.attemptingReconnection = false;
            this.attemptReconnection(++reconnectionAttempt);
          });
      },
      reconnectionAttempt === 1 ? 0 : reconnectionDelay * 1000,
    );
  }

  private cleanupMainSessionMedia(): void {
    if (this.options.media) {
      if (this.options.media.local) {
        if (this.options.media.local.video) {
          this.options.media.local.video.srcObject = null;
          this.options.media.local.video.pause();
        }
      }
      if (this.options.media.remote) {
        if (this.options.media.remote.mainSession.audio) {
          this.options.media.remote.mainSession.audio.srcObject = null;
          this.options.media.remote.mainSession.audio.pause();
        }
        if (this.options.media.remote.mainSession.video) {
          this.options.media.remote.mainSession.video.srcObject = null;
          this.options.media.remote.mainSession.video.pause();
        }
      }
    }
  }

  private cleanupTransferMedia(): void {
    if (this.options.media) {
      if (this.options.media.remote) {
        if (this.options.media.remote.transferSession.audio) {
          this.options.media.remote.transferSession.audio.srcObject = null;
          this.options.media.remote.transferSession.audio.pause();
        }
        if (this.options.media.remote.transferSession.video) {
          this.options.media.remote.transferSession.video.srcObject = null;
          this.options.media.remote.transferSession.video.pause();
        }
      }
    }
  }

  private enableReceiverTracks(enable: boolean): void {
    if (!this.session) {
      throw new Error('Session does not exist.');
    }

    const sessionDescriptionHandler = this.session.sessionDescriptionHandler;
    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error(
        "Session's session description handler not instance of SessionDescriptionHandler.",
      );
    }

    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }

    peerConnection.getReceivers().forEach(receiver => {
      if (receiver.track) {
        receiver.track.enabled = enable;
      }
    });
  }

  private enableSenderTracks(enable: boolean): void {
    if (!this.session) {
      throw new Error('Session does not exist.');
    }

    const sessionDescriptionHandler = this.session.sessionDescriptionHandler;
    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error(
        "Session's session description handler not instance of SessionDescriptionHandler.",
      );
    }

    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }

    peerConnection.getSenders().forEach(sender => {
      if (sender.track) {
        sender.track.enabled = enable;
      }
    });
  }

  private initSession(
    session: Session | Inviter,
    referralInviterOptions?: InviterOptions,
  ): void {
    this.session = session;

    // this code is to pass the header value X-UUID4-UNIQUEID-FROM-FRONT to react
    //
    // start
    let xUuid4UniqueIdFromFront: undefined | string = undefined;

    if (
      referralInviterOptions?.extraHeaders &&
      referralInviterOptions?.extraHeaders[0]
    ) {
      xUuid4UniqueIdFromFront =
        referralInviterOptions?.extraHeaders[0].split(':')[1];
    }
    // end

    if (this.delegate && this.delegate.onCallCreated) {
      this.delegate.onCallCreated(xUuid4UniqueIdFromFront);
    }

    this.session.stateChange.addListener((state: SessionState) => {
      if (this.session !== session) {
        return;
      }
      this.logger.log(`[${this.id}] session state changed to ${state}`);
      switch (state) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          break;
        case SessionState.Established:
          this.stopPreAnswersAudio();
          this.setupLocalMedia();
          this.setupRemoteMedia(this.session);

          if (this.delegate && this.delegate.onCallAnswered) {
            this.delegate.onCallAnswered(this.header);
          }
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          this.session = undefined;
          this.cleanupMainSessionMedia();
          if (this.delegate && this.delegate.onCallHangup) {
            this.delegate.onCallHangup();
          }
          break;
        default:
          throw new Error('Unknown session state.');
      }
    });

    this.session.delegate = {
      onRefer: (referral: Referral): void => {
        referral
          .accept()
          .then(() =>
            this.sendInvite(
              referral.makeInviter(referralInviterOptions),
              referralInviterOptions,
            ),
          )
          .catch((error: Error) => {
            this.logger.error(error.message);
          });
      },
    };
  }

  private initTransferSession(
    session: Session,
    referralInviterOptions?: InviterOptions,
  ): void {
    this.transferSession = session;

    if (this.delegate && this.delegate.onTransferCallCreated) {
      this.delegate.onTransferCallCreated();
    }

    this.transferSession.stateChange.addListener((state: SessionState) => {
      if (this.transferSession !== session) {
        return;
      }
      this.logger.log(`[${this.id}] session state changed to ${state}`);
      switch (state) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          break;
        case SessionState.Established:
          this.stopPreAnswersAudio();
          this.setupLocalMedia();
          this.setupRemoteMedia(this.transferSession);
          if (this.delegate && this.delegate.onTransferCallAnswered) {
            this.delegate.onTransferCallAnswered();
          }
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          this.transferSession = undefined;
          this.cleanupTransferMedia();
          if (this.delegate && this.delegate.onTransferCallHangup) {
            this.delegate.onTransferCallHangup();
          }
          break;
        default:
          throw new Error('Unknown session state.');
      }
    });

    this.transferSession.delegate = {
      onRefer: (referral: Referral): void => {
        referral
          .accept()
          .then(() =>
            this.sendInvite(
              referral.makeInviter(referralInviterOptions),
              referralInviterOptions,
            ),
          )
          .catch((error: Error) => {
            this.logger.error(error.message);
          });
      },
    };
  }

  private sendInvite(
    inviter: Inviter,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions,
  ): Promise<void> {
    this.initSession(inviter, inviterOptions);

    return inviter.invite(inviterInviteOptions).then(() => {
      this.logger.log(`[${this.id}] sent INVITE`);
    });
  }

  private sendTransferInvite(
    inviter: Inviter,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions,
  ): Promise<void> {
    this.initTransferSession(inviter, inviterOptions);

    return inviter.invite(inviterInviteOptions).then(() => {
      this.logger.log(`[${this.id}] sent INVITE`);
    });
  }

  private setHold(hold: boolean): Promise<void> {
    if (!this.session) {
      return Promise.reject(new Error('Session does not exist.'));
    }
    const session = this.session;

    if (this.held === hold) {
      return Promise.resolve();
    }

    const sessionDescriptionHandler = this.session.sessionDescriptionHandler;
    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error(
        "Session's session description handler not instance of SessionDescriptionHandler.",
      );
    }

    const options: SessionInviteOptions = {
      requestDelegate: {
        onAccept: (): void => {
          this.held = hold;
          this.enableReceiverTracks(!this.held);
          this.enableSenderTracks(!this.held && !this.muted);
          if (this.delegate && this.delegate.onCallHold) {
            this.delegate.onCallHold(this.held);
          }
        },
        onReject: (): void => {
          this.logger.warn(`[${this.id}] re-invite request was rejected`);
          this.enableReceiverTracks(!this.held);
          this.enableSenderTracks(!this.held && !this.muted);
          if (this.delegate && this.delegate.onCallHold) {
            this.delegate.onCallHold(this.held);
          }
        },
      },
    };

    const sessionDescriptionHandlerOptions =
      session.sessionDescriptionHandlerOptionsReInvite as SessionDescriptionHandlerOptions;
    sessionDescriptionHandlerOptions.hold = hold;
    session.sessionDescriptionHandlerOptionsReInvite =
      sessionDescriptionHandlerOptions;

    return this.session
      .invite(options)
      .then(() => {
        this.enableReceiverTracks(!hold);
        this.enableSenderTracks(!hold && !this.muted);
      })
      .catch((error: Error) => {
        if (error instanceof RequestPendingError) {
          this.logger.error(
            `[${this.id}] A hold request is already in progress.`,
          );
        }
        throw error;
      });
  }

  private setMute(mute: boolean): void {
    if (!this.session) {
      this.logger.warn(
        `[${this.id}] A session is required to enabled/disable media tracks`,
      );
      return;
    }

    if (this.session.state !== SessionState.Established) {
      this.logger.warn(
        `[${this.id}] An established session is required to enable/disable media tracks`,
      );
      return;
    }

    this.muted = mute;

    this.enableSenderTracks(!this.held && !this.muted);
  }

  private setupLocalMedia(): void {
    if (!this.session) {
      throw new Error('Session does not exist.');
    }

    const mediaElement = this.options.media?.local?.video;
    if (mediaElement) {
      const localStream = this.localMediaStream;
      if (!localStream) {
        throw new Error('Local media stream undefiend.');
      }
      mediaElement.srcObject = localStream;
      mediaElement.volume = 0;
      mediaElement.play().catch((error: Error) => {
        this.logger.error(`[${this.id}] Failed to play local media`);
        this.logger.error(error.message);
      });
    }
  }

  private setupRemoteMedia(session: Session): void {
    if (!session) {
      throw new Error('Session does not exist.');
    }

    const mainSessionMediaElement =
      this.options.media?.remote?.mainSession.video ||
      this.options.media?.remote?.mainSession.audio;

    const transferSessionMediaElement =
      this.options.media?.remote?.transferSession.video ||
      this.options.media?.remote?.transferSession.audio;

    // si existe this.transferSession es una llamada de transferencia
    // utiliza el elemento de transferencia
    // en tro caso utiliza el elemento de la session principal
    const mediaElement = this.transferSession
      ? transferSessionMediaElement
      : mainSessionMediaElement;

    if (mediaElement) {
      const remoteStream = this.remoteMediaStream(session);
      if (!remoteStream) {
        throw new Error('Remote media stream undefiend.');
      }
      mediaElement.autoplay = true;
      mediaElement.srcObject = remoteStream;
      mediaElement.play().catch((error: Error) => {
        this.logger.error(`[${this.id}] Failed to play remote media`);
        this.logger.error(error.message);
      });
      remoteStream.onaddtrack = (): void => {
        this.logger.log(`[${this.id}] Remote media onaddtrack`);
        mediaElement.load();
        mediaElement.play().catch((error: Error) => {
          this.logger.error(`[${this.id}] Failed to play remote media`);
          this.logger.error(error.message);
        });
      };
    }
  }

  private terminate(): Promise<void> {
    this.logger.log(`[${this.id}] Terminating...`);

    if (!this.session) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    switch (this.session.state) {
      case SessionState.Initial:
        if (this.session instanceof Inviter) {
          return this.session.cancel().then(() => {
            this.logger.log(
              `[${this.id}] Inviter never sent INVITE (canceled)`,
            );
          });
        } else if (this.session instanceof Invitation) {
          return this.session.reject().then(() => {
            this.logger.log(`[${this.id}] Invitation rejected (sent 480)`);
          });
        } else {
          throw new Error('Unknown session type.');
        }
      case SessionState.Establishing:
        if (this.session instanceof Inviter) {
          return this.session.cancel().then(() => {
            this.logger.log(`[${this.id}] Inviter canceled (sent CANCEL)`);
          });
        } else if (this.session instanceof Invitation) {
          return this.session.reject().then(() => {
            this.logger.log(`[${this.id}] Invitation rejected (sent 480)`);
          });
        } else {
          throw new Error('Unknown session type.');
        }
      case SessionState.Established:
        return this.session.bye().then(() => {
          this.logger.log(`[${this.id}] Session ended (sent BYE)`);
        });
      case SessionState.Terminating:
        break;
      case SessionState.Terminated:
        break;
      default:
        throw new Error('Unknown state');
    }

    this.logger.log(
      `[${this.id}] Terminating in state ${this.session.state}, no action taken`,
    );
    return Promise.resolve();
  }
  private terminateTransferCall(): Promise<void> {
    this.logger.log(`[${this.id}] Terminating...`);

    if (!this.transferSession) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    switch (this.transferSession.state) {
      case SessionState.Initial:
        if (this.transferSession instanceof Inviter) {
          return this.transferSession.cancel().then(() => {
            this.logger.log(
              `[${this.id}] Inviter never sent INVITE (canceled)`,
            );
          });
        } else if (this.transferSession instanceof Invitation) {
          return this.transferSession.reject().then(() => {
            this.logger.log(`[${this.id}] Invitation rejected (sent 480)`);
          });
        } else {
          throw new Error('Unknown session type.');
        }
      case SessionState.Establishing:
        if (this.transferSession instanceof Inviter) {
          return this.transferSession.cancel().then(() => {
            this.logger.log(`[${this.id}] Inviter canceled (sent CANCEL)`);
          });
        } else if (this.transferSession instanceof Invitation) {
          return this.transferSession.reject().then(() => {
            this.logger.log(`[${this.id}] Invitation rejected (sent 480)`);
          });
        } else {
          throw new Error('Unknown session type.');
        }
      case SessionState.Established:
        return this.transferSession.bye().then(() => {
          this.logger.log(`[${this.id}] Session ended (sent BYE)`);
        });
      case SessionState.Terminating:
        break;
      case SessionState.Terminated:
        break;
      default:
        throw new Error('Unknown state');
    }

    this.logger.log(
      `[${this.id}] Terminating in state ${this.transferSession.state}, no action taken`,
    );
    return Promise.resolve();
  }

  // generates a uuid4 following the standard algorithm
  private generateUUID4(): string {
    let uuid = '';
    const hexDigits = '0123456789abcdef';

    // Generate a random UUID4
    for (let i = 0; i < 32; i++) {
      const randomHex = hexDigits.charAt(Math.floor(Math.random() * 16));
      uuid += randomHex;
    }

    // Set the version to 4 (0100)
    uuid = uuid.substring(0, 12) + '4' + uuid.substring(13);

    // Set the variant to one of the reserved variants (10xx)
    uuid =
      uuid.substring(0, 16) +
      hexDigits.charAt((parseInt(uuid.charAt(16), 16) & 3) | 8) +
      uuid.substring(17);

    // Add dashes to match the UUID4 format
    uuid =
      uuid.substring(0, 8) +
      '-' +
      uuid.substring(8, 12) +
      '-' +
      uuid.substring(12, 16) +
      '-' +
      uuid.substring(16, 20) +
      '-' +
      uuid.substring(20);

    return uuid;
  }
}
