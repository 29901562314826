import React from 'react';
import MetaTags from 'react-meta-tags';

import SummarizeTemplate from 'components/LiveDashboard/templates/summarizeTemplate';
import { useTranslation } from 'react-i18next';

const PagesStarter = ({match}) => {
  const {queueId} = match.params
  const {t} = useTranslation('summarize')
    return (
      <React.Fragment>
        <div className="page-content" style={{position: 'relative'}}>
          <MetaTags>
            <title>{t('pageTitle')} | SmartBot</title>
          </MetaTags>
          
          <SummarizeTemplate queueId={queueId} />
          
        </div>
      </React.Fragment>
    );
}

export default PagesStarter;
