import * as React from "react"

export const DataModification = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M19.36 34.23h-.1c-7.25 0-11.94-3-11.94-7.71V20.2a.92.92 0 1 1 1.84 0v6.32c0 4.33 5.44 5.87 10.1 5.87h.1c4.66 0 10.11-1.54 10.11-5.87V12.7c0-4.33-5.45-5.87-10.11-5.87a.92.92 0 0 1 0-1.84c7.26 0 12 3 12 7.71v13.82c-.05 4.68-4.74 7.71-12 7.71Z"
    />
    <path
      fill="currentColor"
      d="M19.36 27.9h-.1c-7.25 0-11.94-3-11.94-7.7a.92.92 0 1 1 1.84 0c0 4.33 5.44 5.86 10.1 5.86h.1c4.66 0 10.11-1.53 10.11-5.86a.92.92 0 0 1 1.84 0c0 4.68-4.69 7.7-11.95 7.7Z"
    />
    <path
      fill="currentColor"
      d="M19.36 20.4h-.1a.92.92 0 1 1 0-1.84h.1c4.64 0 10.11-1.56 10.11-5.86a.92.92 0 1 1 1.84 0c0 4.67-4.69 7.7-11.95 7.7ZM7.59 15.39l-2.98.53.53-2.98h2.45v2.45z"
    />
    <path
      fill="currentColor"
      transform="rotate(-45 11.12 9.414)"
      d="M4.4 7.68h13.45v3.46H4.4z"
    />
    <path
      fill="currentColor"
      d="M16.2 1.57h1.24a.81.81 0 0 1 .81.81v1.85a.81.81 0 0 1-.81.81H16.2V1.57Z"
      transform="rotate(-45 17.228 3.304)"
    />
  </svg>
)

