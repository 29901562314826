import React, { memo } from 'react';
import LeftHandle from '../common/LeftHandle';
import Modal from './Modal';
import { useNodeModal } from '../common/useNodeModal';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
export const SceneryEnd = memo(({ data, isConnectable }) => {
  const { toggleModal, modalOpen } = useNodeModal();

  return (
    <>
      <Modal data={data} toggle={toggleModal} isOpen={modalOpen} />
      <div className="node-circle-end" onDoubleClick={() => toggleModal()}>
        <LeftHandle
          id="route_end"
          isConnectable={isConnectable}
          type="target"
        />
      </div>
    </>
  );
});

SceneryEnd.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
