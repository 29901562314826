import { ChatBotActionTypes, GET_CHATBOT_BY_CALL_FAIL, GET_CHATBOT_BY_CALL_SUCCESS } from './actionTypes';

export interface IChatBotLogs {
  timestamp: string;
  callStatus: 'start' | 'ongoing' | 'end';
  textResponse: string | undefined;
  textRequest: string | undefined;
  transcriptElapsed: string | undefined;
  botElapsed: string | undefined;
  errorDetails: string | undefined;
  successful: string | undefined;
  completedBy: string | undefined;
}
export interface IChatBotCustomer {
  id: string;
  name: string;
  address: string;
  type: string;
}
export interface IChatBotStore {
  error: string | undefined;
  uniqueid: string;
  queue: string;
  queueForwarding: string;
  callerid: string | undefined;
  timestamp: string;
  exten: string;
  customer: IChatBotCustomer | null;
  logs: IChatBotLogs[] | undefined;
}

const INIT_STATE: IChatBotStore = {
  error: undefined,
  uniqueid: '',
  queue: '',
  queueForwarding: '',
  callerid: undefined,
  timestamp: '',
  exten: '',
  customer: {
    id: '',
    name: '',
    address: '',
    type: '',
  },
  logs: undefined,
};

const ChatBot = (
  state = INIT_STATE,
  action: ChatBotActionTypes,
): IChatBotStore => {
  switch (action.type) {
    case GET_CHATBOT_BY_CALL_SUCCESS: {
      return {
        ...action.payload,
        error: undefined,
      };
    }
    case GET_CHATBOT_BY_CALL_FAIL: {
        return {
            ...state,
            error: action.payload
        }
    }
    default:
      return state;
  }
};
export default ChatBot;
