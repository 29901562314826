import { call, put, takeEvery } from 'redux-saga/effects';

import {
  DELETE_SCENARIO,
  GET_FUNCTIONS,
  GET_INTENTS,
  GET_SCENARIO,
  GET_SCENARIOS,
  POST_ADD_SCENARIO,
  PUT_PUBLISH_SCENARIO,
  PUT_RENAME_SCENARIO,
  PUT_SAVE_AS_DRAFT_SCENARIO,
  PUT_UPDATE_FLOW_SCENARIO,
  PUT_UPDATE_SCENARIO,
} from './actionTypes';

import {
  actionScenariosFail,
  addScenarioSuccess,
  deleteScenarioSuccess,
  getFunctionsSuccess,
  getIntentsSuccess,
  getScenariosSuccess,
  getScenarioSuccess,
  publishScenarioSuccess,
  renameScenarioSuccess,
  saveAsDraftScenarioSuccess,
  updateScenarioSuccess,
} from './actions';
import {
  getScenarios,
  getScenario,
  addScenario,
  renameScenario,
  deleteScenario,
  publishScenario,
  saveAsDraftScenario,
  updateScenario,
  updateFlowScenario,
  getScenariosV2,
  getSceneryByIdV2,
  getSceneryFunctions,
  getSceneryIntents,
  postSceneryV2,
  putUpdateSceneryV2,
} from '../../helpers/fakebackend_helper';
import { updateFlowScenarioSuccess } from './actions';

function* onGetScenarios({ payload }) {
  try {
    const funcToCall = payload === 'v1' ? getScenarios : getScenariosV2;
    const response = yield call(funcToCall);
    yield put(getScenariosSuccess(response));
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onGetScenario({ payload: { id, version } }) {
  try {
    const funcToCall = version === 'v1' ? getScenario : getSceneryByIdV2;
    const response = yield call(funcToCall, id);

    yield put(getScenarioSuccess(response));
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onAddScenario({ payload: { scenario, version } }) {
  try {
    const funcToCall = version === 'v1' ? addScenario : postSceneryV2;
    const response = yield call(funcToCall, scenario);
    yield put(addScenarioSuccess(response));
  } catch (error) {
    // yield put(addMessageFail(error));
  }
}

function* onRenameScenario({ payload: { id, newName } }) {
  try {
    const response = yield call(renameScenario, id, newName);
    yield put(renameScenarioSuccess(response));
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onDeleteScenario({ payload: { id } }) {
  try {
    yield call(deleteScenario, id);
    yield put(deleteScenarioSuccess(id));
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onPublishScenario({ id }) {
  try {
    yield call(publishScenario, id);
    yield put(publishScenarioSuccess(id));
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onSaveAsDraftScenario({ id }) {
  try {
    yield call(saveAsDraftScenario, id);
    yield put(saveAsDraftScenarioSuccess(id));
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onUpdateScenario({ payload: { id, data, version } }) {
  try {
    console.log(id, data, version)
    const funcToCall = version === 'v1' ? updateScenario : putUpdateSceneryV2;
    const response = yield call(funcToCall, id, data);
    yield //put(updateScenarioSuccess(response));
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onUpdateFlowScenario({ payload: { id, flow } }) {
  try {
    console.log('onUpdateFlowScenario: ', { id, flow });
    const response = yield call(updateFlowScenario, id, flow);
    yield put(updateFlowScenarioSuccess(response));
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onGetFunctions() {
  try {
    const response = yield call(getSceneryFunctions)
    yield put(getFunctionsSuccess(response))
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* onGetIntents() {
  try {
    const response = yield call(getSceneryIntents)
    yield put(getIntentsSuccess(response))
  } catch (error) {
    yield put(actionScenariosFail(error));
  }
}

function* scenariosSaga() {
  yield takeEvery(GET_SCENARIOS, onGetScenarios);
  yield takeEvery(GET_SCENARIO, onGetScenario);
  yield takeEvery(POST_ADD_SCENARIO, onAddScenario);
  yield takeEvery(PUT_RENAME_SCENARIO, onRenameScenario);
  yield takeEvery(DELETE_SCENARIO, onDeleteScenario);
  yield takeEvery(PUT_PUBLISH_SCENARIO, onPublishScenario);
  yield takeEvery(PUT_SAVE_AS_DRAFT_SCENARIO, onSaveAsDraftScenario);
  yield takeEvery(PUT_UPDATE_SCENARIO, onUpdateScenario);
  yield takeEvery(PUT_UPDATE_FLOW_SCENARIO, onUpdateFlowScenario);
  yield takeEvery(GET_FUNCTIONS, onGetFunctions)
  yield takeEvery(GET_INTENTS, onGetIntents)
}

export default scenariosSaga;
