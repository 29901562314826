// TODO: this tags should be fetch from server
//
// DELETE this file after saving the tags to the database
//

export const TAGS = [
  { id: 1, tag: 'Telefono Incorrecto', description: 'Telefono incorrecto Black List' },
  { id: 2, tag: 'No le interesa', description: 'No interesa oferta realizada' },
  { id: 3, tag: 'Incobrable', description: 'Incobrable Bajar de Campaña' },
  { id: 4, tag: 'No Contestan', description: 'No contesta verificar validez del telefono' },
  { id: 5, tag: 'Volver a llamar', description: 'Volver a llamar Horario Agendar' },
  { id: 6, tag: 'Aprobado', description: 'Aprobado' },
  { id: 7, tag: 'No volver a llamar', description: 'No volver a llamar Black List' },
  { id: 8, tag: 'Contesta y cuelga', description: 'Contesta y cuelga' },
  { id: 9, tag: 'Contestador', description: 'Contestador' },
  { id: 10, tag: 'Desistido', description: 'Desistido No Retira Credito' },
  { id: 11, tag: 'Fallecido', description: 'Fallecido' },
  { id: 12, tag: 'INGRESO SOLICITUD WEB', description: 'Ingreso Web u otro medio' },
  { id: 13, tag: 'Mensaje a Terceros', description: 'Contactamos a terceras personas' },
  { id: 14, tag: 'Pendiente', description: 'Faltan adjuntar documentos' },
  { id: 15, tag: 'Pendiente Resolucion', description: 'Pendiente autorizaciones' },
  { id: 16, tag: 'Pendiente Retiro', description: 'Pendiente retiro redes o credito en cta' },
  { id: 17, tag: 'PreAprobado', description: 'PreAprovado Pendiente doc y Resolucion' },
  { id: 18, tag: 'Promera de Pago', description: 'Promesa de Pago Agendar' },
  { id: 19, tag: 'Rechazado', description: 'Rechazado por sistema o riesgos' },
  { id: 20, tag: 'Todos Nros llamados', description: 'Todos Nros llamados' },
];
