import { lightBackgroundBlue } from 'library/colors';
import { ICreateCampaignMainInfoState, IExecutionDayButton } from 'library/interfaces/campaignInterfaces';
import { isDateBeforeToday, isDateBetweenTheTwoDates, isFirstDateAfterToSecondDate } from 'library/services/dateServices';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ExcludeDay from '../molecules/excludeDay';
import ExecutionDaysButtons from '../molecules/executionDaysButtons';
import ExecutionHours from '../molecules/executionHours';
import ExecutionHoursForm from '../molecules/executionHoursForm';

interface ICreateCampaignMainInfoProps {
  mainInfo: ICreateCampaignMainInfoState
  setMainInfoValues: ({}: ICreateCampaignMainInfoState) => void;
}
const CreateCampaignMainInfo: FC<ICreateCampaignMainInfoProps> = ({
  mainInfo,
  setMainInfoValues,
}) => {
  const {t} = useTranslation('outbound')
  const [hoursForm, setHoursForm] = useState({isValid: false, start_time: '', end_time: ''})

  const handleExecutionDayButtonClick = (index: number) => {
    const newDayButtonsState = mainInfo.execution_day;
    newDayButtonsState[index].isSelected = !mainInfo.execution_day[index].isSelected;
    
    const hoursFormIsValid = hoursForm.start_time !== '' && 
                              hoursForm.end_time !== '' &&
                              newDayButtonsState.some(button => button.isSelected)
    
    setMainInfoValues({
      ...mainInfo,
      execution_day: newDayButtonsState,
    });
    setHoursForm({...hoursForm, isValid: hoursFormIsValid})
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;

    if ((name === 'channels' || name === 'attempts') && parseInt(value) <= 0) {
      return;
    } else if (name === 'recording') {
      const bool: boolean = value === 'true' 

      setMainInfoValues({
        ...mainInfo,
        [name]: bool,
      });
      return
    }

    setMainInfoValues({
      ...mainInfo,
      [name]: value,
    });
  };

  const handleInputDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    date_start?: string,
  ) => {
    const { name, value } = event.target;

    if (name === 'date_start' && isDateBeforeToday(value)) {
      return;
    } else if (
      name === 'date_end' &&
      (date_start === '' || !isFirstDateAfterToSecondDate(value, mainInfo.date_start))
    ) {
      return;
    } else if (
      name === 'exclude_day' && 
      (mainInfo.date_start === '' || 
        mainInfo.date_end === '' || 
        !isDateBetweenTheTwoDates(
          mainInfo.date_start, 
          mainInfo.date_end, 
          value,
          ) ||
        mainInfo.exclude_day.some(day=> day.day === value)) 
    ) {
      return;
    } else if (name === 'exclude_day') {
      const excludeDay = mainInfo.exclude_day;

      excludeDay.unshift({
        day: value,
      });

      setMainInfoValues({
        ...mainInfo,
        exclude_day: [...excludeDay],
      });
      return;
    }

    setMainInfoValues({
      ...mainInfo,
      [name]: value,
    });
  };

  const handleInputTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    
    if(name === 'end_time' && 
      hoursForm.start_time === ''
    ) return
    else if(name === 'end_time' && 
            isFirstDateAfterToSecondDate(
              `2022-1-1 ${hoursForm.start_time}`, 
              `2022-1-1 ${value}`
    )) return 
    else if(name === 'start_time' && 
            hoursForm.end_time !== '' &&
            isFirstDateAfterToSecondDate(
              `2022-1-1 ${value}`, 
              `2022-1-1 ${hoursForm.end_time}`
      )) return 
    const formIsValid = 
      (name === 'end_time' || 
        (name === 'start_time' && hoursForm.end_time !== '')) && 
      mainInfo.execution_day.some(button => button.isSelected)

    setHoursForm({
      ...hoursForm,
      isValid: formIsValid,
      [name]: value
    })
  };

  const deleteExcludeDay = (index: number, days: { day: string }[]) => {
    days.splice(index, 1);
    setMainInfoValues({
      ...mainInfo,
      exclude_day: days,
    });
  };

  const addWorkingHours = () => {
    if(!hoursForm.isValid) return
    const { start_time, end_time } = hoursForm
    const newExecutionDay: IExecutionDayButton[] =  mainInfo.execution_day.map(day=>{
      if(!day.isSelected || day.execution_time.length === 2) return day 
      day.execution_time.push({ start_time, end_time })
      return day
    })
    setMainInfoValues({
      ...mainInfo,
      execution_day: newExecutionDay
    })
  }
  const deleteWorkingHours = (dayIndex: number, timeIndex: number) => {
    const newExecutionDays = mainInfo.execution_day
    newExecutionDays[dayIndex].execution_time.splice(timeIndex, 1)
    
    setMainInfoValues({
      ...mainInfo,
      execution_day: newExecutionDays
    })
  }

  /* useEffect(()=>{
    if(mainInfo.execution_day === '') return

    const executionDay = mainInfo.execution_day.split('|')
    const newDaysButtonsState = mainInfo.execution_day.map(button=>{
      if(executionDay.indexOf(button.label) !== -1) {
        return {...button, isSelected: true}
      }
      return button
    })
    setDayButtons(newDaysButtonsState)
  },[mainInfo.execution_day]) */
  return (
    <>
      <FormGroup marginBottom>
        <FormGroupTitle>{t('campaigns.createCampaignForm.mainInfo.settings')}</FormGroupTitle>
        <FlexCont>
          <InputGroup>
            <label>{t('campaigns.createCampaignForm.mainInfo.campaignName')}</label>
            <input
              type="text"
              name="name"
              value={mainInfo.name}
              onChange={e => handleInputChange(e)}
            />
          </InputGroup>
          <SmallInputsGroup>
            <SmalInputCont>
              <label>{t('campaigns.createCampaignForm.mainInfo.channels')}</label>
              <input
                type="number"
                name="channels"
                value={mainInfo.channels}
                onChange={e => handleInputChange(e)}
              />
            </SmalInputCont>
            <SmalInputCont>
              <label>{t('campaigns.createCampaignForm.mainInfo.attemps')}</label>
              <input
                type="number"
                name="attempts"
                value={mainInfo.attempts}
                onChange={e => handleInputChange(e)}
              />
            </SmalInputCont>
            <SmalInputCont>
              <label>{t('campaigns.createCampaignForm.mainInfo.recording')}</label>
              <div>
                <input
                  type="radio"
                  name="recording"
                  id="recordingYes"
                  value={'true'}
                  checked={mainInfo.recording}
                  onChange={e => handleInputChange(e)}
                />
                <label htmlFor="recordingYes">{t('campaigns.createCampaignForm.mainInfo.yes')}</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="recording"
                  id="recordingNo"
                  value={'false'}
                  checked={!mainInfo.recording}
                  onChange={e => handleInputChange(e)}
                />
                <label htmlFor="recordingNo">{t('campaigns.createCampaignForm.mainInfo.no')}</label>
              </div>
            </SmalInputCont>
          </SmallInputsGroup>
        </FlexCont>
      </FormGroup>

      <FormGroup>
        <FlexCont>
          <div>
            <FormGroupTitle>{t('campaigns.createCampaignForm.mainInfo.schedule')}</FormGroupTitle>
            <InputDateGroup>
              <label>{t('campaigns.createCampaignForm.mainInfo.startCampaign')}</label>
              <input
                type="date"
                name="date_start"
                value={mainInfo.date_start}
                onChange={e => handleInputDateChange(e)}
              />
            </InputDateGroup>
            <InputDateGroup>
              <label>{t('campaigns.createCampaignForm.mainInfo.endCampaign')}</label>
              <input
                type="date"
                name="date_end"
                value={mainInfo.date_end}
                onChange={e => handleInputDateChange(e, mainInfo.date_start)}
              />
            </InputDateGroup>
            <InputDateGroup margin='10px 0 10px'>
              <label>{t('campaigns.createCampaignForm.mainInfo.excludeDays')}</label>
              <input
                type="date"
                name="exclude_day"
                value={
                  mainInfo.exclude_day[0] ? mainInfo.exclude_day[0].day : ''
                }
                onChange={e => handleInputDateChange(e)}
              />
            </InputDateGroup>
            <InputDateGroup>
            <ExcludeDay
              exclusion_day={mainInfo.exclude_day}
              deleteExcludeDay={deleteExcludeDay}
            />
            </InputDateGroup>
          </div>

          <div>
            <FormGroupTitle>{t('campaigns.createCampaignForm.mainInfo.workingHours')}</FormGroupTitle>
            <Flex>
              <ExecutionDaysButtons
                buttons={mainInfo.execution_day}
                handleSelectButton={handleExecutionDayButtonClick}
              />
              <ExecutionHours 
                executionDays={mainInfo.execution_day}
                onDeleteHours={deleteWorkingHours}
              />
              <ExecutionHoursForm 
                hoursForm={hoursForm}
                handleInputTimeChange={handleInputTimeChange}
                onAddClick={addWorkingHours}
              />
            </Flex>
          </div>
        </FlexCont>
      </FormGroup>
    </>
  );
};
const FormGroup = styled.div<IFormGroup>`
  padding: 20px 30px 0;
  margin-bottom: ${props => props.marginBottom && '20px'};
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
const FormGroupTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;
const InputGroup = styled.div`
  margin-bottom: 20px;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;

  flex-direction: column;

  & > input {
    max-width: 300px;
    font-size: 1rem;
    padding: 10px 0;
    border: none;
  }
`;
const SmallInputsGroup = styled.div`
  //border: 1px solid;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`;
const SmalInputCont = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  & > div {
  display: flex;
  padding-left: 5px;
    & > label {
      margin-left: 3px;
      margin-bottom: 0;
    }
  }
  & > input {
    text-align: center;
    max-width: 100px;
    font-size: 1rem;
    padding: 10px 0;
    border: none;
  }
`;
const InputDateGroup = styled.div<IInputDateGroup>`
  margin: ${props=>props.margin ? props.margin : '10px 0 20px'};
  font-size: 0.7rem;
  font-weight: 500;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label {
    margin: 0;
  }
  & > input {
    margin-left: 10px;
    font-size: 1rem;
    text-align: center;
    border: none;
    padding: 10px 0;
    max-width: 200px;
    flex-grow: 1;
  }
`;
const FlexCont = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex-basis: 50%;
  }
`;
const Flex = styled.div`
  display: flex;
  height: 240px;
  justify-content: space-between;
`;
interface IFormGroup {
  marginBottom?: React.ReactNode;
}
interface IInputDateGroup {
  margin?: string;
}
export default CreateCampaignMainInfo;
