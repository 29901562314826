import React, { FC } from 'react';
import styled from 'styled-components';
import WaTextMessageViewer from './waTextMessageViewer';
import WaTextMessageForm from './waTextMessageForm';

interface IWaTextMessageVisualEditorProps {
  textMessage: string
  handleTextMessageChange: (value: string) => void;
}
const WaTextMessageVisualEditor: FC<IWaTextMessageVisualEditorProps> = ({ textMessage, handleTextMessageChange }) => {
  return (
    <Cont>
      <WaTextMessageViewer text={textMessage} />
      <WaTextMessageForm textMessage={textMessage} handleTextMessageChange={handleTextMessageChange} />
    </Cont>
  );
};
const Cont = styled.div``;
export default WaTextMessageVisualEditor;