import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import IndicatorLinked from '../atoms/indicatorLinked';

interface IIndicatorsLinkedProps {
  indicators: {
    label: string;
    value?: number;
    labelBackColor?: string;
  }[];
  onSelectIndicator?: Function;
}
const IndicatorsLinked: FC<IIndicatorsLinkedProps> = ({
  indicators,
  onSelectIndicator,
}) => {
  const {t} = useTranslation('reports')
  return (
    <Cont>
      {indicators.map((indicator, index) => {
        const {label, value, labelBackColor} = indicator
        const isSelectable = (label !== 'total' && value && value > 0 && onSelectIndicator !== undefined)
        return (
          <IndicatorLinked
            key={index}
            label={t(`callIndicator.${label}`)}
            filter={label}
            value={value}
            labelBackColor={labelBackColor}
            onClick={isSelectable ? onSelectIndicator : undefined}
          />
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export default IndicatorsLinked;
