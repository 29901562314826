import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../FormContainer';
import { Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import * as ModalFields from '../common/ModalFields';
import ModalActions from '../common/ModalActions';

const validationSchema = Yup.object({
  value: Yup.string().nullable(),
});

EditResponses.propTypes = {
  data: PropTypes.object.isRequired,
  toggleInEdit: PropTypes.func.isRequired,
  index: PropTypes.number,
};

function EditResponses({ index, data, toggleInEdit }) {
  const onSubmit = values => {
    data.responseRoutes[index].port = values.port;
    data.responseRoutes[index].responseKey = values.responseKey;
    data.responseRoutes[index].possibleResponses = values.possibleResponses;
    data.responseRoutes[index].text = values.port;

    toggleInEdit();
  };

  return (
    <FormContainer title="Output Settings">
      <Formik
        initialValues={{
          port: data.responseRoutes[index].port || '',
          responseKey: data.responseRoutes[index].responseKey || '',
          possibleResponses: data.responseRoutes[index].possibleResponses || [],
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <Row>
              <ModalFields.Port name={'port'} />
            </Row>
            <Row>
              <ModalFields.ResponseKey name={'responseKey'} />
            </Row>
            <Row>
              <ModalFields.PossibleResponses name={'possibleResponses'} />
            </Row>

            <ModalActions toggleInEdit={toggleInEdit} />
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

export default EditResponses;

// {
//   "id":"route_2",
//   "intentId":"0f0eccbd-976f-47cc-97fb-4d6fb8710274",
//   "expectedInput":"number",
//   "text":"Código Reserva",
//   "bg":"blue"
// }
