import React from 'react';
import MetaTags from 'react-meta-tags';

import QueuedCallsTemplate from 'components/LiveDashboard/templates/queuedCallsTemplate';

const PagesStarter = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Queued Calls | SmartBot</title>
        </MetaTags>

        <QueuedCallsTemplate />
      </div>
    </React.Fragment>
  );
};
export default PagesStarter;
