import * as React from "react"

export const SendingEmail = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M26.42 28.89H8.58a5 5 0 0 1-5-5V11.08a5 5 0 0 1 5-5h17.84a5 5 0 0 1 5 5v12.84a5 5 0 0 1-5 4.97ZM8.58 8a3.13 3.13 0 0 0-3.13 3.13v12.79a3.13 3.13 0 0 0 3.13 3.13h17.84a3.13 3.13 0 0 0 3.13-3.13V11.08A3.13 3.13 0 0 0 26.42 8Z"
    />
    <path
      fill="currentColor"
      d="M17.5 19.58a1 1 0 0 1-.58-.2L5.37 10.07a.92.92 0 1 1 1.15-1.43l11 8.85L28.8 8.63a.92.92 0 0 1 1.13 1.45l-11.86 9.31a.93.93 0 0 1-.57.19Z"
    />
  </svg>
)
