import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import ConversationAside from '../organisms/aside';
import MainCont from '../organisms/mainCont';
import { getChannelsAction, getGupshupAppTemplatesAction, getWACampaignsAction } from 'store/actions';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { whatsappIsEnabled } from 'library/enableSpecificFeatures/conversationWorkspace';
import ConversationsByChannelTemplate from './conversationsByChannelTemplate';
import { owners } from 'store/auth/login/owners';

interface IConversationTemplateProps { }
const ConversationTemplate: FC<IConversationTemplateProps> = ({ }) => {
  const dispatch = useAppDispatch()
  const channels = useAppSelector(store => store.channels.channels)
  const owner = useAppSelector(store => store.authUserData.owner)

  useEffect(() => {
    dispatch(getWACampaignsAction())
    dispatch(getChannelsAction())
  }, [])

  useEffect(() => {
    if (channels.length === 0 || !whatsappIsEnabled[owner]) return
    const channel = channels.find(chan => chan.number === whatsappIsEnabled[owner].value)
    if (!channel) return
    dispatch(getGupshupAppTemplatesAction(channel.appId))
  }, [channels])

  return (
    <Cont>
      {owner !== 'CONSULADOUY' && <MainCont />}
      {owner === 'CONSULADOUY' && <ConversationsByChannelTemplate />}
      <ConversationAside />
    </Cont>
  );
};
const Cont = styled.div`
  height: 75vh;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 250px;

`;


export default ConversationTemplate;