import React, { FC } from 'react';
import styled from 'styled-components';
import InfoCont from '../atoms/infoCont';
import Title from '../atoms/title';
import Text from '../atoms/text';

interface IProblemasConAutoExpresoProps { }
const ProblemasConAutoExpreso: FC<IProblemasConAutoExpresoProps> = ({ }) => {
  return (
    <InfoCont>
      <Title>Problemas con Autoexpreso</Title>
      <Text>En este caso su situación está relacionada con su cuenta de Autoexpreso. Metropistas no gestiona las cuentas de Autoexpreso, solo trabajamos la conservación y mantenimiento de las autopistas. El proceso de cobro y asuntos relacionados a multas, sellos e información general de las cuentas son manejados por Autoexpreso. Con gusto le redirigiré al call center de Autoexpreso para que puedan asistirle.</Text>
      <Text danger>Transfiere la llamada. Provéele el número para ocasiones futuras (1-888-688-1010). </Text>
    </InfoCont>
  );
};
const Cont = styled.div``;
export default ProblemasConAutoExpreso;