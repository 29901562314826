import { lightBackground, lightGreen } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IExcludeDayButtonProps {
  children: string;
  index: number;
  onClick: Function;
  margin?: string;
  padding?: string;
}
const ExcludeDayButton: FC<IExcludeDayButtonProps> = ({
  index,
  children,
  onClick,
  margin,
  padding,
}) => {
  const handleButtonClick = (
    index: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onClick(index);
  };
  return (
    <Button 
      margin={margin}
      padding={padding}
      onClick={e => handleButtonClick(index, e)}
    >
      <div>
      {children}
      <i className="bx bx-trash"></i>
      </div>
    </Button>
  );
};
const Button = styled.div<IButton>`
  margin: ${props=>props.margin ? props.margin : '5px 0'};
  padding: ${props=>props.padding ? props.padding : '3px 2px 3px 4px'};
  border-radius: 5px;
  background-color: ${lightBackground};
  border: 1px solid ${lightGreen};
  font-size: .8rem;
  font-weight: 300;
  display: inline-block;

  &:hover {
    color: red;
    border: 1px solid red;
    text-decoration: line-through;
  }

  & > i {
    line-height: 0px;
    font-size: 1rem;
    font-weight: 00;
    display: inline-block;
    margin-left: 2px;
    transform: translateY(1px);
  }
`;
interface IButton {
  margin?: string;
  padding?: string;
}
export default ExcludeDayButton;
