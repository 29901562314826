import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'reactstrap';
import { ErrorMessage, useField } from 'formik';

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  postfix: PropTypes.string,
};

export function NumberInput({ name, postfix = '', ...props }) {
  const [field] = useField(name);

  return (
    <>
      <InputGroup>
        <input type="number" className="form-control" {...field} {...props} />
        {postfix && (
          <span className="input-group-append">
            <span className="input-group-text">{postfix}</span>
          </span>
        )}
      </InputGroup>
      <ErrorMessage name={name} component="span" />
    </>
  );
}
