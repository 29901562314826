import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, CardHeader, Form, FormGroup, Input, Label } from 'reactstrap';
import {
  filterValueUpdate,
  getRecordingsByAllFiltersActions,
  getRecordingsByFilterActions,
  //getRecordingsFilterByDateActions,
} from 'store/actions';

const FiltersForm = ({ filters, ShowFilterForm, owner, selectedQueue, limit, order }) => {
  const { startDate, endDate, agent, source, destination, startTime,  endTime } = filters;
  const {t} = useTranslation('recordings')
  const dispatch = useDispatch();

  const handleExitClick = event => {
    const { id } = event.target;
    if (id === 'popupBackground' || id === 'exit' || id === 'cancel') {
      ShowFilterForm(false);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    let newFiltersValues = filters

    if(name === 'agent') {
      newFiltersValues.destination = ''
      newFiltersValues.source = ''
    }else if(name === 'destination') {
      newFiltersValues.agent = ''
      newFiltersValues.source = ''
    }else if(name === 'source') {
      newFiltersValues.destination = ''
      newFiltersValues.agent = ''
    }
    newFiltersValues[name] = value

    dispatch(filterValueUpdate(newFiltersValues));
  };


  const handleGetRecordingsSubmit = event =>{
    event.preventDefault();

    let FILTER_NAME = undefined
    let FILTER_VALUE = undefined
    const START_DATE = startDate ? `${startDate}T${startTime}:00.000Z` : undefined
    const END_DATE = endDate ? `${endDate}T${endTime}:59.999Z` : undefined
   
    for (const filter in filters) {
      if(filters[filter] && (filter === 'agent' || filter === 'source' || filter === 'destination')){
        FILTER_NAME = filter
        FILTER_VALUE = filters[filter]
      }
    }
  
    if(!selectedQueue){
      dispatch(getRecordingsByFilterActions(owner, START_DATE, END_DATE, FILTER_NAME, FILTER_VALUE, limit, order))
    }else {
      dispatch(getRecordingsByAllFiltersActions(owner, selectedQueue, START_DATE, END_DATE, FILTER_NAME, FILTER_VALUE, limit, order))
    }

    setTimeout(() => {
      ShowFilterForm(false);
    }, 500);
  }
  return (
    <div
      id="popupBackground"
      className="popup-background"
      onClick={e => handleExitClick(e)}
    >
      <div className="popup-container">
        <CardHeader
          tag="h4"
          style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 30px' }}
        >
          {t('filtersForm.filterSearch')}
          <i
            id="exit"
            className="bx bx-x me-1 mt-1"
            style={{ cursor: 'pointer' }}
            onClick={e => handleExitClick(e)}
          ></i>
        </CardHeader>
        <Form style={{ padding: '20px 30px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <FormGroup>
              <Label htmlFor="startDate">{t('filtersForm.startDate')}</Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                value={startDate}
                onChange={e => handleChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="endDate">{t('filtersForm.endDate')}</Label>
              <Input
                type="date"
                name="endDate"
                id="endDate"
                value={endDate}
                onChange={e => handleChange(e)}
              />
            </FormGroup>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <FormGroup style={{width: '152px'}}>
              <Label htmlFor="startTime">{t('filtersForm.startTime')}</Label>
              <Input
                type="time"
                name="startTime"
                id="startTime"
                value={startTime}
                onChange={e => handleChange(e)}
              />
            </FormGroup>
            <FormGroup style={{width: '152px'}}>
              <Label htmlFor="endTime">{t('filtersForm.endTime')}</Label>
              <Input
                type="time"
                name="endTime"
                id="endTime"
                value={endTime}
                onChange={e => handleChange(e)}
              />
            </FormGroup>
          </div>
          <FormGroup>
            <Label for="agent">{t('filtersForm.agent')}</Label>
            <Input
              type="text"
              name="agent"
              id="agent"
              value={agent}
              onChange={e => handleChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="source">{t('filtersForm.source')}</Label>
            <Input
              type="text"
              name="source"
              id="source"
              value={source}
              onChange={e => handleChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="destination">{t('filtersForm.destination')}</Label>
            <Input
              type="text"
              name="destination"
              id="destination"
              value={destination}
              onChange={e => handleChange(e)}
            />
          </FormGroup>
          <FormGroup className="d-flex justify-content-between">
            <Button id="cancel" onClick={e => handleExitClick(e)}>
            {t('filtersForm.cancel')}
            </Button>
            <Button
              className="bg-primary"
              onClick={e => handleGetRecordingsSubmit(e)}
            >
              <i className="bx bx-filter me-1" />
              {t('filtersForm.filter')}
            </Button>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};
export default FiltersForm;