import ButtonsFiltersAndForm from 'components/Report/organisms/buttonsFiltersAndForm';
import RulesAndButton from 'components/Report/organisms/rulesAndButton';
import VerticalIndicatorsWidthCircularGraph from 'components/Report/organisms/verticalIndicatorsWidthCircularGraph';
import { lightBackgroundBlue } from 'library/colors';
import IStore from 'library/interfaces/storeInterface';
import {
  getFirstDayOfCurrentMonth_yyyymmdd,
  getToday_yyyymmdd,
  inputDateFormat_toRenderFormat,
  localDate_formatISO,
  nDaysBeforeNow_LocalTime_formatISO,
} from 'library/services/dateServices';
import { indicatorsToRender } from 'library/services/reportsServices/totalCallByOriginServices';
import {
  graphDataToRender_callDetailsExcel,
  indicatorsToRender_callDetailsExcel,
} from 'library/services/reportsServices/totalCallsByStatus';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReportAction,
  getReportAction_V2,
  getReportFail,
  getReportTotalCallsByOriginAction,
  reportDownloaded,
  requestReportAction,
} from 'store/actions';
import {
  GET_REPORT_CALLS_DETAIL_EXCEL,
  GET_REPORT_CALLS_DETAIL_EXCEL_V2,
  GET_REPORT_CALLS_DETAIL_EXCEL_V2_FAIL,
  GET_REPORT_TOTAL_CALLS_BY_STATUS,
  GET_REPORT_TOTAL_CALLS_BY_STATUS_FAIL,
  REQUEST_REPORT_TOTAL_CALLS_BY_STATUS,
} from 'store/reports/actionTypes';
import { IError, IReportsStore } from 'store/reports/reducer';
import styled from 'styled-components';

interface ICallDetailsExcelTemplateProps {
  reportTypeId: string;
}
const CallDetailsExcelTemplate: FC<ICallDetailsExcelTemplateProps> = ({
  reportTypeId,
}) => {
  const {t} = useTranslation('reports')
  const dispatch = useDispatch();
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const { totalCallByOrigin, callsDetailExcel } = useSelector<IStore, IReportsStore>(
    store => store.reports,
  );

  const [selectableButtons, setSelectableButton] = useState(INIT_BUTTONS_STATE);
  const [form, setForm] = useState(() => ({
    start_date: getToday_yyyymmdd(),
    end_date: getToday_yyyymmdd(),
  }));
  let START_DATE = form.start_date + 'T00:00:00.000Z';
  let END_DATE = form.end_date + 'T23:59:59.999Z';

  const handleSelectableButtonClick = async (index: number) => {
    if (totalCallByOrigin.isLoading) return;

    const newButtonsState = selectableButtons.map((button, ind) => {
      if (index === ind) return { ...button, isSelected: true };
      return { ...button, isSelected: false };
    });

    END_DATE = localDate_formatISO();
    START_DATE =
      newButtonsState[index].value === 1
        ? getToday_yyyymmdd() + 'T00:00:00.000Z'
        : newButtonsState[index].value === 31
        ? getFirstDayOfCurrentMonth_yyyymmdd() + 'T00:00:00.000Z'
        : nDaysBeforeNow_LocalTime_formatISO(
            newButtonsState[index].value,
          ).split('T')[0] + 'T00:00:00.000Z';

    getTotalCallsByOrigin_Report();
    setSelectableButton(newButtonsState);
    setForm({
      start_date: START_DATE.split('T')[0],
      end_date: END_DATE.split('T')[0],
    });
    dispatch(reportDownloaded(`${GET_REPORT_CALLS_DETAIL_EXCEL_V2}_DOWNLOAD`))
  };

  const handleFormSearchClick = () => {
    if (totalCallByOrigin.isLoading) return;

    if (form.end_date === getToday_yyyymmdd()) {
      END_DATE = localDate_formatISO();
    }

    const newButtonsState = selectableButtons.map(button => {
      return { ...button, isSelected: false };
    });
    setSelectableButton(newButtonsState);
    getTotalCallsByOrigin_Report();
  };

  const getTotalCallsByOrigin_Report = () => {
    if (form.start_date === '') return;

    if (form.end_date === getToday_yyyymmdd()) {
      END_DATE = localDate_formatISO();
    }
    dispatch(getReportTotalCallsByOriginAction(owner, START_DATE, END_DATE));
    dispatch(reportDownloaded(`${GET_REPORT_CALLS_DETAIL_EXCEL_V2}_DOWNLOAD`))
  };

  const handleGetReportRefresh = () => {
    dispatch(
      getReportAction(
        owner,
        callsDetailExcel.reportId,
        GET_REPORT_CALLS_DETAIL_EXCEL,
      ),
    );
  }

  useEffect(() => {
    if (owner === '') return;
    getTotalCallsByOrigin_Report();
  }, [owner]);

  useEffect(() => {
    if (
      callsDetailExcel.reportId === '' ||
      (callsDetailExcel.isLoading === false && callsDetailExcel.count === 0)
    )
      return;

    if (callsDetailExcel.count > 5) {
      const error: IError = {
        message: 'Something was wrong requesting the report!',
        details: ``,
      };
      dispatch(getReportFail(error, `${GET_REPORT_CALLS_DETAIL_EXCEL_V2}_FAIL`));
      return;
    }
    
    const t = setTimeout(() => {
      handleGetReportRefresh()
    }, 2500);
    return () => clearTimeout(t);
  }, [callsDetailExcel.count]);

  return (
    <Cont>
      <ButtonsFiltersAndForm
        formValues={form}
        setFormValues={setForm}
        handleFormButtonClick={handleFormSearchClick}
        SelectableButtonsState={selectableButtons}
        handleSelectableButtonClick={handleSelectableButtonClick}
      />
      <BlueCont>
        <Title>
          {t('callDetailsReport.callDetailsFrom')}{' '}
          <b> {inputDateFormat_toRenderFormat(form.start_date)} </b>
          {t('callDetailsReport.to')}<b>{inputDateFormat_toRenderFormat(form.end_date)}</b>
        </Title>
        <RulesAndButton
          maximunDays={31}
          reportKeys={{ nameEn: 'Call Details', nameEs: 'Detalle de Llamadas', format: 'Excel' }}
          dates={{ start: form.start_date, end: form.end_date }}
          action={GET_REPORT_CALLS_DETAIL_EXCEL_V2}
          reportState={{
            isLoading: callsDetailExcel.isLoading,
            error: callsDetailExcel.error,
            url: callsDetailExcel.reportData?.content_data && callsDetailExcel.reportData?.content_data.uri
          }}
          getReportRefresh={handleGetReportRefresh}
        />
        <VerticalIndicatorsWidthCircularGraph
          isLoading={totalCallByOrigin.isLoading}
          error={totalCallByOrigin.error}
          handleRefresh={getTotalCallsByOrigin_Report}
          indicators={indicatorsToRender_callDetailsExcel(
            totalCallByOrigin.reportData?.calls,
          )}
          graphData={graphDataToRender_callDetailsExcel(
            totalCallByOrigin.reportData?.calls,
          )}
        />
      </BlueCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${lightBackgroundBlue};

  & > :last-child {
    grid-column: 3/-1;
  }
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;
const INIT_BUTTONS_STATE = [
  {
    isSelected: true,
    label: 'Today',
    value: 1, // value represents days
  },
  {
    isSelected: false,
    label: 'Last 7 days',
    value: 7, // value represents days
  },
  {
    isSelected: false,
    label: 'This month',
    value: 31, // value represents days
  },
];
export default CallDetailsExcelTemplate;
