import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { getReportData } from 'store/actions';
import { subscribeLiveData } from 'helpers/appSyncLiveBoard';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';
import { workspaceLiveDataService } from 'library/services/workspaceLiveDataService';
import { IReportData } from 'library/interfaces/crdInterfaces';
import { useAppDispatch } from 'library/hooks/useAppDispatch';

import { darkText, lightBackground } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';

interface IPageTitleProps {
  children: React.ReactNode;
}
const PageTitleWithIndicators: FC<IPageTitleProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { owner, exten_name } = useAppSelector(store => store.authUserData);
  const { queues } = useAppSelector(
    store => store.cdr.reportData,
  ) as IReportData;

  const [counters, setCounter] = useState({
    callsInQueue: 0,
    answered: 0,
    unanswered: 0,
    serviceLevel: 0,
  });

  useEffect(() => {
    if (queues.length > 0) return;
    dispatch(getReportData());
  }, []);

  useEffect(() => {
    if (queues.length === 0) return;
    subscribeLiveData(owner.toLowerCase(), (data: ILiveDataQueue[]) => {
      const rst = workspaceLiveDataService(exten_name, queues, data);
      setCounter(rst);
    });
  }, [queues]);
  return (
    <Div>
      <H1>
        <Span>{children}</Span>
      </H1>
      {queues.length > 0 && owner !== 'METROPISTAS' &&  (
        <Counters>
          <Span>
            Llamadas en cola: <b>{counters.callsInQueue}</b>
          </Span>
          <Span>
            Respondidas: <b>{counters.answered}</b>
          </Span>
          <Span>
            No respondidas: <b>{counters.unanswered}</b>
          </Span>
        </Counters>
      )}
    </Div>
  );
};
const Div = styled.div`
  display: grid;
  align-items: center;

  grid-template-columns: 310px 1fr;
  padding-bottom: 30px;
`;
const H1 = styled.h1`
  font-weight: 700;
  font-size: 1rem;
  margin: 0;
  color: ${darkText};
`;
const Span = styled.span`
  padding: 4px 25px;
  border-radius: 30px;
  text-transform: uppercase;
  background-color: ${lightBackground};
`;

const Counters = styled.div`
  font-size: 0.8rem;
  font-weight: 300;
  display: flex;
  height: 10px;
  padding-right: 60px;
  align-items: center;
  justify-content: space-between;

  > span {
    text-transform: none;
    height: 32px;
    line-height: 24px;
  }
  b {
    font-size: 0.9rem;
    font-weight: 500;
    padding-left: 5px;
  }
`;
export default PageTitleWithIndicators;
