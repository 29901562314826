import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FiltersButtons from './filtersButtons';
import FiltersForm from './filtersForm';

import { postFilters } from 'store/actions';
import { API_URL } from 'helpers/api_helper';
import { GET_REPORT } from 'helpers/url_helper';
import RecordingReportTemplate from '../reports/templates/recordingReportTemplate';

const QueueFilter = ({ owner }) => {
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [showGetReportForm, setShowGetReportForm] = useState(false);

  const dispatch = useDispatch();
  const { filters, selectedQueue, limit, order, reportFilters } = useSelector(
    state => state.recordings,
  );
  const { queues } = useSelector(state => state.cdr.reportData);

  const urlGetReport = `${
    API_URL + GET_REPORT + '/' + JSON.stringify(filters)
  }`;

  const downloadUrl = 'useDownloadReport(urlGetReport, reportFilters)';

  return (
    <div className="d-flex justify-content-between">
      <FiltersButtons
        queues={queues}
        owner={owner}
        limit={limit}
        order={order}
        selectedQueue={selectedQueue}
        downloadUrl={downloadUrl}
        showFiltersForm={setShowFilterForm}
        setShowGetReportForm={setShowGetReportForm}
      />
      {showFilterForm && (
        <FiltersForm
          filters={filters}
          owner={owner}
          limit={limit}
          order={order}
          selectedQueue={selectedQueue}
          ShowFilterForm={setShowFilterForm}
        />
      )}
      {showGetReportForm && (
        <RecordingReportTemplate setShowGetReportForm={setShowGetReportForm} />
      )}
    </div>
  );
};
export default QueueFilter;
