import React from 'react';
import PropTypes from 'prop-types';

Index.propTypes = {
  addText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Index({ addText, onClick }) {
  return (
    <button
      type="button"
      className="btn btn-default text-primary"
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: 14,
        fontWeight: 800,
        marginTop: 16,
      }}
    >
      <i className="bx bx-plus-circle" style={{ fontSize: 20 }} />
      {addText ? addText : 'Add'}
    </button>
  );
}

export default Index;
