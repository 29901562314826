import React, { FC } from 'react';
import styled from 'styled-components';

interface IListMessageProps {
  list: {
    body: string
    globalButtons: { type: string, title: string }[]
    items: { title: string; subtitle: string; options: { text: string, title: string, description: string, postbackText: string }[] }[]
    msgid: string
    title: string
    type: string
  }
}
const ListMessage: FC<IListMessageProps> = ({ list }) => {
  return (
    <Cont>
      <p>{list.body}</p>
      {list.globalButtons.map((item, index) => {
        return <p key={index}>{item.title}</p>
      })}
      {list.items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <p key={index}>{item.title}</p>
            <ul>
              {item.options.map((opt, i) => {
                return <li key={i}>{opt.title}</li>
              })}
            </ul>
          </React.Fragment>
        )
      })}
    </Cont>
  );
};
const Cont = styled.div``;
export default ListMessage;