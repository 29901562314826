import React from 'react';
import PropTypes from 'prop-types';

function PageHeader({ title }) {
  return (
    <h4
      className="mb-2"
      style={{
        display: 'inline-block',
        padding: '8px 24px',
        backgroundColor: '#fff',
        borderRadius: 30,
        fontWeight: 800,
      }}
    >
      {title}
    </h4>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
export default PageHeader;
