import React, { FC } from 'react';
import styled from 'styled-components';
import InfoCont from '../atoms/infoCont';
import Title from '../atoms/title';
import Text from '../atoms/text';

interface IAutoridadDeCarreterasProps { }
const AutoridadDeCarreteras: FC<IAutoridadDeCarreterasProps> = ({ }) => {
  return (
    <InfoCont>
      <Title>Autoridad de carreteras</Title>
      <Text danger>Si está en alguna de las siguientes:</Text>
      <ul>
        <li>PR-18 (Expreso Las Américas),</li>
        <li>PR-26 (Expreso Román Baldorioty de Castro),</li>
        <li>PR-30 o Ave. Kennedy</li>
        <li>Carretera PR-2</li>
      </ul>
      <Text>
        Metropistas no gestiona ni ofrece servicios en la autopista en la que te encuentras. Esa zona es manejada por la Autoridad de Carreteras. El número de teléfono para solicitar asistencia a la Autoridad de Carreteras es el 787-625-3253. Puedo ayudarle en esta llamada y transferirle para que coordine directamente con ellos.
      </Text>
    </InfoCont>
  );
};
export default AutoridadDeCarreteras;