import {
  ISession,
  ISessionAssigned,
} from 'library/interfaces/conversationInterfaces';
import { IError } from 'store/reports/reducer';
import {
  ADD_NEW_MESSAGE,
  ADD_NEW_SESSION,
  ConversationActionTypes,
  DEL_CLOSE_SESSION,
  GET_CONVERSATION_SESSIONS,
  GET_CONVERSATION_SESSIONS_FAIL,
  GET_CONVERSATION_SESSIONS_SUCCESS,
  GET_CLIENT_MESSAGES,
  GET_CLIENT_MESSAGES_FAIL,
  GET_CLIENT_MESSAGES_SUCCESS,
  POST_SEND_HSM,
  POST_SEND_HSM_FAIL,
  POST_SEND_HSM_SUCCESS,
  POST_SEND_MESSAGE_SUCCESS,
  SET_SELECTED_SESSION,
  PUT_ASSING_SESSION_TO_AGENT,
  PUT_ASSING_SESSION_TO_AGENT_SUCCESS,
  PUT_ASSING_SESSION_TO_AGENT_FAIL,
  UPDATE_WA_SESION_CLIENT_DATA,
} from './actionTypes';

export interface IActiveSessionStore {
  isLoading: boolean;
  error: IError | null;
  sessionsStore: {
    waLastKey: string | null;
    sessions: ISession[];
  };
  sessionsAssignedStore: {
    lastKey: string | null;
    sessions: ISessionAssigned[];
  };
}
export interface IHSMStore {
  isLoading: boolean;
  messageId: string;
  error: IError | null;
}
export interface IConversationStore {
  selectedSession: string;
  activeSessions: IActiveSessionStore;
  hsm: IHSMStore;
}

const INIT_STATE: IConversationStore = {
  selectedSession: '',
  activeSessions: {
    isLoading: false,
    error: null,
    sessionsStore: {
      waLastKey: null,
      sessions: [],
    },
    sessionsAssignedStore: {
      lastKey: null,
      sessions: [],
    },
  },
  hsm: {
    isLoading: false,
    messageId: '',
    error: null,
  },
};

const conversations = (
  state = INIT_STATE,
  action: ConversationActionTypes,
): IConversationStore => {
  switch (action.type) {
    case GET_CONVERSATION_SESSIONS: {
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          isLoading: true,
          error: null,
        },
      };
    }
    case GET_CONVERSATION_SESSIONS_SUCCESS: {
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          isLoading: false,
          error: null,
          sessionsStore: action.payload.sessionsStore,
          sessionsAssignedStore: action.payload.sessionsAssignedStore,
        },
      };
    }
    case GET_CONVERSATION_SESSIONS_FAIL: {
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case GET_CLIENT_MESSAGES: {
      const { sessionId } = action.payload;
      const newSessions = structuredClone(
        state.activeSessions.sessionsAssignedStore.sessions,
      );
      const index = newSessions.findIndex(session => session.id === sessionId);
      if (index !== -1) {
        newSessions[index].isLoading = true;
        newSessions[index].error = null;
        newSessions[index].hasNewMessage = false;
      }
      return {
        ...state,
        selectedSession: sessionId,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [...newSessions],
          },
        },
      };
    }
    case GET_CLIENT_MESSAGES_SUCCESS: {
      const { message, sessionId } = action.payload;
      const newSessions = structuredClone(
        state.activeSessions.sessionsAssignedStore.sessions,
      );
      const index = newSessions.findIndex(session => session.id === sessionId);
      if (index !== -1) {
        newSessions[index].isLoading = false;
        newSessions[index].error = null;
        newSessions[index].messages = [...message];
      }
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [...newSessions],
          },
        },
      };
    }
    case GET_CLIENT_MESSAGES_FAIL: {
      const { error, sessionId } = action.payload;
      const newSessions = structuredClone(
        state.activeSessions.sessionsAssignedStore.sessions,
      );
      const index = newSessions.findIndex(session => session.id === sessionId);
      if (index !== -1) {
        newSessions[index].isLoading = false;
        newSessions[index].error = error;
      }
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [...newSessions],
          },
        },
      };
    }
    case UPDATE_WA_SESION_CLIENT_DATA: {
      const { sessionId, clientData } = action.payload;
      const newSessions = structuredClone(
        state.activeSessions.sessionsAssignedStore.sessions,
      );
      const index = newSessions.findIndex(session => session.id === sessionId);
      if (index !== -1) {
        newSessions[index].clientData = clientData;
      }
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [...newSessions],
          },
        },
      };
    }
    // send message
    case POST_SEND_MESSAGE_SUCCESS: {
      const { sessionId, message } = action.payload;
      const newSessions = structuredClone(
        state.activeSessions.sessionsAssignedStore.sessions,
      );
      const index = newSessions.findIndex(session => session.id === sessionId);

      if (index !== -1) {
        newSessions[index].messages.push(message as any);
      }
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [...newSessions],
          },
        },
      };
    }
    // send hsm
    case POST_SEND_HSM: {
      return {
        ...state,
        hsm: {
          ...state.hsm,
          isLoading: true,
          error: null,
        },
      };
    }
    case POST_SEND_HSM_SUCCESS: {
      return {
        ...state,
        hsm: {
          ...state.hsm,
          isLoading: false,
          error: null,
          messageId: action.payload.messageId,
        },
      };
    }
    case POST_SEND_HSM_FAIL: {
      return {
        ...state,
        hsm: {
          ...state.hsm,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }

    case ADD_NEW_MESSAGE: {
      const { newMessage } = action.payload;
      const sessionId = newMessage['session_id'];
      if (!sessionId) return state;

      const newSessions = structuredClone(
        state.activeSessions.sessionsAssignedStore.sessions,
      );
      const index = newSessions.findIndex(session => session.id === sessionId);

      if (index !== -1) {
        newSessions[index].messages.push(newMessage as any);
        newSessions[index].hasNewMessage =
          sessionId === state.selectedSession ? undefined : true;

        if (index !== 0) {
          const sessionWithNewMessage = newSessions.splice(index, 1);
          newSessions.unshift(...sessionWithNewMessage);
        }
      }
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [...newSessions],
          },
        },
      };
    }
    case ADD_NEW_SESSION: {
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsStore: {
            ...state.activeSessions.sessionsStore,
            sessions: [
              action.payload.session,
              ...state.activeSessions.sessionsStore.sessions,
            ],
          },
        },
      };
    }

    case DEL_CLOSE_SESSION: {
      const { sessionId } = action.payload;
      const newSessions =
        state.activeSessions.sessionsAssignedStore.sessions.filter(
          session => session.id !== sessionId,
        );

      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [...newSessions],
          },
        },
      };
    }
    case SET_SELECTED_SESSION: {
      const { sessionId } = action.payload;
      const newSessions = structuredClone(
        state.activeSessions.sessionsAssignedStore.sessions,
      );
      const index =
        state.activeSessions.sessionsAssignedStore.sessions.findIndex(
          session => session.id === sessionId,
        );
      if (index !== -1) {
        newSessions[index].hasNewMessage = false;
      }
      return {
        ...state,
        selectedSession: sessionId,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [...newSessions],
          },
        },
      };
    }
    case PUT_ASSING_SESSION_TO_AGENT: {
      // recibe la session sin asignar
      const { session } = action.payload;
      // la quita de las sessiones
      const firteredSessions =
        state.activeSessions.sessionsStore.sessions.filter(
          ses => ses.id !== session.id,
        );
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsStore: {
            waLastKey: null,
            sessions: firteredSessions,
          },
        },
      };
    }
    case PUT_ASSING_SESSION_TO_AGENT_SUCCESS: {
      // recibe la session ya asignada al agente
      // y la coloca al comienzo de las conversaciones
      const { sessionAssigned } = action.payload;
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          sessionsAssignedStore: {
            ...state.activeSessions.sessionsAssignedStore,
            sessions: [
              sessionAssigned,
              ...state.activeSessions.sessionsAssignedStore.sessions,
            ],
          },
        },
      };
    }
    // entra en este caso si hubo algún error en la petición
    // o si la session ya fue asignada a otro agente
    // en cualquiera de los casos
    case PUT_ASSING_SESSION_TO_AGENT_FAIL: {
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          error: action.payload.error,
        },
      };
    }
    default:
      return state;
  }
};
export default conversations;
