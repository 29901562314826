import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAuthenticated,
  phone,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (
        isAuthProtected &&
        !localStorage.getItem('authUser') &&
        !isAuthenticated
      ) {
        return (
          <Redirect
            to={{ pathname: '/entry', state: { from: props.location } }}
          />
        );
      }
      if (rest.path === '/incoming-v2/:scenarioId') {
        return <Component {...props} phone={phone} />;
      }

      return (
        <Layout phone={phone}>
          <Component {...props} phone={phone} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;

/* import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading"

const Authmiddleware = ({ 
  component,  
  ...args }) => (
  <Route
    component={withAuthenticationRequired(component)}
    {...args}
  />
);

export default Authmiddleware;
 */
