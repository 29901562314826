import { darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FormGroup from '../atoms/formGroup';

interface IHorizontalFormProps {
  formValues: {
    start_date: string;
    end_date: string;
  };
  setFormValues: Function;
  onSubmit: Function;
  isDisable?: boolean
}
const HorizontalForm: FC<IHorizontalFormProps> = ({
  formValues,
  setFormValues,
  onSubmit,
  isDisable,
}) => {
  const { t } = useTranslation('reports')
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleFormSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    onSubmit()
  }
  return (
    <Form>
      <FormGroup
        label={t('startDate')}
        name="start_date"
        value={formValues.start_date}
        type="date"
        handleInputChange={e => handleInputChange(e)}
      />
      <FormGroup
        label={t('endDate')}
        name="end_date"
        value={formValues.end_date}
        type="date"
        handleInputChange={e => handleInputChange(e)}
      />
      <Button disabled={isDisable} onClick={e => handleFormSubmit(e)}>{t('search')}</Button>
    </Form>
  );
};
const Form = styled.form`
  display: flex;
  align-items: center;
  border-radius: 0 10px 10px 0;
  padding: 5px 0px 10px 0;
  background-color: ${lightBackgroundBlue};
`;
const Button = styled.button`
  border-radius: 50px;
  margin: 0;
  display: inline-block;
  min-width: 30px;
  font-size: 13px;
  padding: 8px 16px;
  color: white;
  border: 2px solid ${props => props.disabled ? darkBorder : primary};
  background-color: ${props => props.disabled ? darkBorder : primary};
  transform: translateY(10px);
`;
export default HorizontalForm;
