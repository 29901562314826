import React, { FC } from 'react';
import styled from 'styled-components';
import CircularGraph from '../atoms/circularGraph';
import IndicatorsLinked from '../molecules/indicatorsLinked';

interface IIndicatorsLinkedWithCircularGraphProps {
  title: string;
  filter: string;
  indicators: {
    label: string;
    value?: number;
    labelBackColor?: string;
  }[];
  graphData: {
    value: number;
    color: string;
    label?: string;
  }[]
  isLoading?: boolean;
  onSelectIndicator?: Function
}
const IndicatorsLinkedWithCircularGraph: FC<
  IIndicatorsLinkedWithCircularGraphProps
> = ({title, filter, indicators, graphData, isLoading, onSelectIndicator}) => {

  const handleIndicatorClick = (indicartor: string) => {
    if(!onSelectIndicator) return
    onSelectIndicator(indicartor, filter)
  }
  return (
    <Cont>
        <Title>{title}</Title>
        <IndicatorsLinked indicators={indicators} onSelectIndicator={handleIndicatorClick}/>
        <GraphCont>
          <CircularGraph graphData={graphData} isLoading={isLoading}/>
        </GraphCont>
    </Cont>
  );
};
const Cont = styled.div`
  flex-grow: 1;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;

const Title = styled.h3`
  color: black;
`;
const GraphCont = styled.div`
  padding: 10px 10% 0;
`;
export default IndicatorsLinkedWithCircularGraph;
