import * as React from 'react';

export const MuteCall = props => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 104.15 146.76"
        {...props}
    >
        {/* <title>Recurso 17</title> */}
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_2-2" data-name="Capa 2">
                <rect fill='currentColor' x="-18.07" y="55.92" width="140.28" height="7" transform="translate(-26.76 54.22) rotate(-45)"/>
                <rect fill='currentColor' x="48.08" y="115.47" width="7" height="27.78"/>
                <rect fill='currentColor' x="25.24" y="139.76" width="52.44" height="7"/>
                <path fill='currentColor' d="M73.21,72.34A21,21,0,0,1,31.89,77.7l-5.52,5.52A28,28,0,0,0,80.21,72.34v-43l-7,7Z"/>
                <path fill='currentColor' d="M31.17,28a21,21,0,0,1,42-1.47l6-6a28,28,0,0,0-55,7.51V72.34a27.37,27.37,0,0,0,.17,3l6.83-6.84Z"/>
                <path fill='currentColor' d="M13.21,72.56h-7A51.36,51.36,0,0,0,9.36,90.34l5.54-5.55A44.88,44.88,0,0,1,13.21,72.56Z"/>
                <path fill='currentColor' d="M55.83,112H47.34c-12.72,0-23.83-8.08-29.69-20l-5.18,5.17C19.74,110.23,32.65,119,47.34,119h8.49C78.5,119,97,98.15,97,72.56H90C90,94.29,74.65,112,55.83,112Z"/>
            </g>
        </g>
    </svg>
);