import IStore from 'library/interfaces/storeInterface';
import React, { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IConversationStore } from 'store/conversation/reducer';
import styled from 'styled-components';
import ChatMessage from '../atoms/chatMessage';
import { isJSON } from 'library/services/functions';
import ListMessage from '../atoms/messages/list';
import { IConversationChannelType } from 'library/enableSpecificFeatures/conversationWorkspace';

interface ISelectedConversationProps { }
const SelectedConversation: FC<ISelectedConversationProps> = () => {
  const { activeSessions, selectedSession } = useSelector<
    IStore,
    IConversationStore
  >(store => store.conversations);
  const [chatInnerWidth, setChatInnerWidth] = useState(100);
  const contRef = useRef<HTMLDivElement>(null);
  const conversationToRender = useMemo(() => {
    return activeSessions.sessionsAssignedStore.sessions.filter(item => item.id === selectedSession);
  }, [activeSessions.sessionsAssignedStore.sessions, selectedSession]);

  const handleDivResize = () => {
    if (!contRef.current) return;
    setChatInnerWidth(contRef.current.offsetWidth);
  };

  const resizeObserver = new ResizeObserver(handleDivResize);
  useLayoutEffect(() => {
    if (!contRef.current) return;
    contRef.current.scrollIntoView();

    resizeObserver.observe(contRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [activeSessions, selectedSession]);

  return (
    <Cont>
      {conversationToRender.length > 0 &&
        conversationToRender[0].messages.map((message, index) => {
          if (!message.content) return

          const isAgent =
            message.source_type === 'agent' || message.source_type === 'system';

          let imgUrl = ''
          let stickerUrl = ''
          let audioUrl = ''
          let fileUrl = ''
          let text: React.ReactNode = ''

          try {

            if (message.content_type === 'image') {
              const json = JSON.parse(message.content.toString())
              imgUrl = json.url
            } else if (message.content_type === 'sticker') {
              stickerUrl = JSON.parse(message.content.toString()).url
            } else if (message.content_type === 'audio') {
              audioUrl = JSON.parse(message.content.toString()).url
            } else if (isJSON(message.content.toString())) {
              const json = JSON.parse(message.content.toString())
              if (json.type === 'image') imgUrl = json.originalUrl
              if (json.type === 'sticker') stickerUrl = json.url || json.originalUrl
              if (json.type === 'file') fileUrl = json.url
              if (json.type === 'audio') audioUrl = json.url

              if (json.Text && Array.isArray(json.Text)) {

                json.Text.forEach((item: string) => {
                  if (isJSON(item)) {
                    const obj = JSON.parse(item)
                    if (obj.type && obj.type === 'list') {
                      text = <ListMessage list={obj} />
                    }
                  } else {
                    text = text + item
                  }
                });
              }
              //case message.content_type === text
            } else {
              text = message.content.toString()
            }
          } catch (e) {
            null
          }

          const source: IConversationChannelType | 'call' = message.channel_id === 'facebook' ? 'facebook' : message.channel_id === 'instagram' ? 'instagram' : 'whatsapp'

          const msg = {
            source,
            sender:
              message.source_name?.toString() ||
              message.source_type?.toString() ||
              '',
            message: text,
            imgUrl,
            stickerUrl,
            audioUrl,
            fileUrl,
            timestamp: message.created_at?.toString() || '',
          };
          if (isAgent) {
            return (
              <ChatMessage
                chatWidth={chatInnerWidth}
                message={msg}
                key={index}
              />
            );
          }
          return (
            <ChatMessage
              chatWidth={chatInnerWidth}
              right
              message={msg}
              key={index}
            />
          );
        })}
      <div ref={contRef}></div>
    </Cont>
  );
};
const Cont = styled.div`
  flex-grow: 1;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export default SelectedConversation;
