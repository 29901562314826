import DownloadReportButton from 'components/outbound/atoms/downloadReportButton';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { Completed, Draft, Megaphone, Pause, Phone } from 'icons';
import {
  blueGraph,
  darkBorder,
  lightBackgroundBlue,
  primary,
} from 'library/colors';
import { ISMSCampaign } from 'library/interfaces/smsCampaignInterface';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { SMSContactGroupsAction } from 'store/actions';
import styled from 'styled-components';
import LoadingBarChart from '../atoms/loadingBarChart';
import SMSCounter from '../molecules/smsCounter';
import StatusAndButtons from '../molecules/statusAndButtons';

interface ISMSCampaignProps {
  smsCampaign: ISMSCampaign;
}
const SMSCampaign: FC<ISMSCampaignProps> = ({ smsCampaign }) => {
  return (
    <Cont>
      <FlexCont>
        <LeftCont>
          {smsCampaign.status === 'created' && (
            <IconCont>
              <Draft width={25} color={darkBorder} />
            </IconCont>
          )}
          {/* {smsCampaign.status === 'running' && (
            <IconCont left="-45px">
              <Megaphone width={35} />
            </IconCont>
          )} */}
          {smsCampaign.status === 'stopped' && (
            <IconCont left="-47px">
              <Pause width={40} color={'orange'} />
            </IconCont>
          )}
          {smsCampaign.status === 'running' && (
            <IconCont>
              <Phone width={25} color={primary} />
            </IconCont>
          )}
          {smsCampaign.status === 'completed' && (
            <IconCont left="-45px">
              <Completed width={30} color={blueGraph} />
            </IconCont>
          )}
          <Title>{smsCampaign.name}</Title>
          <TotalContacts>Contactos: {smsCampaign.contacts_count}</TotalContacts>
          <SMSCounter
            ok={smsCampaign.dispatched_ok}
            fail={smsCampaign.dispatched_failed}
            total={smsCampaign.dispatched_count}
          />
        </LeftCont>
        <RightCont>
          <StatusAndButtons smsCampaign={smsCampaign} />
        </RightCont>
      </FlexCont>
    </Cont>
  );
};
const Cont = styled.div`
  border-radius: 10px;
  padding: 20px 50px 20px 90px;
  color: black;
  max-width: 1100px;
  margin: 0 auto 20px;
  border: 1px solid ${darkBorder};
`;
const FlexCont = styled.div`
  display: flex;

  /* border: 1px solid blue; */
`;
const LeftCont = styled.div`
  flex-grow: 1;
  /* border: 1px solid red; */
  max-width: 50%;
  position: relative;
`;
const RightCont = styled.div`
  flex-grow: 1;
  max-width: 50%;
  display: flex;
  /* border: 1px solid; */
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
`;
const TotalContacts = styled.div`
  margin-bottom: 25px;
  font-size: 0.75rem;
`;
const IconCont = styled.div<IIconCont>`
  position: absolute;
  left: ${props => (props.left ? props.left : '-40px')};
  top: 5px;
`;
interface IIconCont {
  left?: string;
}
export default SMSCampaign;
