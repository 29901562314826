import React from 'react';
import styled from 'styled-components';

interface IContextMenuProps {
  position: {
    x: number;
    y: number;
  };
  onDelete: Function;
}
const ContextMenu = ({ position, onDelete }: IContextMenuProps) => {
  return (
    <Cont id="nodeContextMenu" position={position}>
      <div onClick={() => onDelete()}>Eliminar</div>
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  position: absolute;
  left: ${props => props.position.x}px;
  top: ${props => props.position.y}px;
  z-index: 10000;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
`;
interface ICont {
  position: {
    x: number;
    y: number;
  };
}
export default ContextMenu;
