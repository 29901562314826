import React from 'react';
import PropTypes from 'prop-types';
import ViewEditContainer from '../ViewEditContainer';
import { CardTitle, Row } from 'reactstrap';
import AddButton from './AddButton';
import Paper from '../foundation/Paper';

ViewEditArrayContainer.propTypes = {
  title: PropTypes.string,
  View: PropTypes.any.isRequired,
  Edit: PropTypes.any.isRequired,
  newRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  addText: PropTypes.string,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

function ViewEditArrayContainer({
  title,
  View,
  Edit,
  newRow,
  removeRow,
  addText,
  data,
  name,
}) {
  return (
    <>
      <CardTitle style={{ marginLeft: 20 }}>{title}</CardTitle>
      <Row>
        {data[name]?.map((item, idx) => (
          <Row key={`item-${idx}`}>
            <Paper mt={8}>
              <ViewEditContainer
                data={data}
                index={idx}
                View={View}
                Edit={Edit}
                onRemove={() => removeRow(idx)}
              />
            </Paper>
          </Row>
        ))}
      </Row>
      <AddButton addText={addText} onClick={newRow} />
    </>
  );
}

export default ViewEditArrayContainer;
