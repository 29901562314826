import * as React from 'react';

export const Hamburguer = props => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  id="svg8"
  version="1.1"
  viewBox="0 0 91.080244 77.460767"
  {...props}
  >
 <defs
    id="defs2" />
 <g
    transform="translate(-33.378221,-48.892052)"
    id="layer1">
   <path
      id="path70"
      fill='currentColor'
      strokeWidth="0.85121727" 
      d="m 33.378221,118.69187 v -7.66096 h 45.540125 45.540124 v 7.66096 7.66095 H 78.918346 33.378221 Z m 0,-31.49504 v -7.660956 h 45.540125 45.540124 v 7.660956 7.660955 H 78.918346 33.378221 Z m 0,-30.643823 v -7.660955 h 45.540125 45.540124 v 7.660955 7.660956 H 78.918346 33.378221 Z"
      />
 </g>
</svg>
);