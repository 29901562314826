export const areNotificationsAlowed = () => {
  return Notification.permission === 'granted';
};

export const sysNotification = (title: string, body: string, duration = 10) => {
  if (!areNotificationsAlowed()) return;
  const options: NotificationOptions = {
    body,
    actions: []
  }
  
  const notification = new Notification(title, options);
  notification.onclick = () =>{
    notification.close()
  }
  setTimeout(() => {
    notification.close()
  }, duration * 1000);
};
