import { IError } from 'store/reports/reducer';
import {
  POST_CALL_TAG,
  POST_CALL_TAG_FAIL,
  POST_CALL_TAG_SUCCESS,
  UPDATE_FROM_NUMBER,
  UPDATE_SELECTED_CAMPAIGN_ID,
  WorkspaceActionTypes,
} from './actionTypes';

export interface IWorkspaceStore {
  selectedCampaignId: string;
  fromNumber: string;
  createTag: {
    isLoading: boolean;
    error: IError | null;
    tagId: string;
  }
}

const INIT_STATE: IWorkspaceStore = {
  selectedCampaignId: '',
  fromNumber: '',
  createTag: {
    isLoading: false,
    error: null,
    tagId: ''
  }
};

const workspace = (
  state = INIT_STATE,
  action: WorkspaceActionTypes,
): IWorkspaceStore => {
  switch (action.type) {
    case POST_CALL_TAG: {
      return{
        ...state,
        createTag: {
          ...state.createTag,
          isLoading: true,
          error: null,
          tagId: '',
        }
      }
    }
    case POST_CALL_TAG_SUCCESS: {
      return{
        ...state,
        createTag: {
          ...state.createTag,
          isLoading: false,
          error: null,
          tagId: action.payload.tagId
        }
      }
    }
    case POST_CALL_TAG_FAIL: {
      return{
        ...state,
        createTag: {
          ...state.createTag,
          tagId: '',
          isLoading: false,
          error: action.payload.error
        }
      }
    }

    case UPDATE_SELECTED_CAMPAIGN_ID: {
      return {
        ...state,
        selectedCampaignId: action.payload.campaignId,
      };
    }
    case UPDATE_FROM_NUMBER: {
      return{
        ...state,
        fromNumber: action.payload.fromNumber
      }
    }
    default:
      return state;
  }
};

export default workspace;
