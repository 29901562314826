import LoadingImg from 'components/Configuration/atoms/loading';
import { Completed, Draft, Megaphone, Pause, Phone } from 'icons';
import IStore from 'library/interfaces/storeInterface';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCampaignsAction,
  getCampaignByIdAction,
  getPBXCampaignInfoAction,
  getReportData,
} from 'store/actions';
import { ICampaign, IVoiceCampaignsStore } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import styled from 'styled-components';
import LeftMenuOutbound from '../molecules/leftMenu';

import SearchAndButton from '../molecules/searchAndButton';
import Campaign from '../organisms/campaign';

interface ICampaignsTemplateProps {
  setShowCampaignForm: (
    show: boolean,
    campaignToEdit?: null | ICampaign,
    campaignIndexToUpdate?: null | ICampaign,
  ) => void;
}
const CampaignsTemplate: FC<ICampaignsTemplateProps> = ({
  setShowCampaignForm,
}) => {
  const dispatch = useDispatch();
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const {campaigns, newCampaignId, postContactGroups} = useSelector<IStore, IVoiceCampaignsStore>(
    store => store.voiceCampaigns,
  );
  
  

  const [campaignFilter, setCampaignFilter] = useState('');
  
  const campaignsToRender = campaigns.filter(campaign =>
    campaignFilter ? campaign.status === campaignFilter : campaign,
  );

  const handleCreateCamgpaignButtonClick = () => {
    setShowCampaignForm(true);
  };

  const calcCreateContactsPorcent = (): number => {
    if(postContactGroups && postContactGroups.totalGroups >= 0){
      let { totalGroups, sendingGroup} = postContactGroups
      let porcent = ((sendingGroup / totalGroups) * 100)
  
      return isNaN(porcent) ? 0 : parseFloat(porcent.toFixed(2))
    }
    return 0
  }

  useEffect(() => {
    if (newCampaignId === '') return;
    dispatch(getCampaignByIdAction(newCampaignId));
  }, [newCampaignId]);

  useEffect(() => {
    if (!owner) return;
    dispatch(getAllCampaignsAction(owner));
    dispatch(getReportData());
    dispatch(getPBXCampaignInfoAction());
  }, [owner]);
  return (
    <Cont>
      <SearchAndButton onButtonClick={handleCreateCamgpaignButtonClick} />
      <FlexCont>
        <LeftMenuOutbound filterButtons={buttons} setCampaignFilter={setCampaignFilter}/>
        <CampaignsCont>
          {campaignsToRender.map((campaign, index) => {
            return (
              <Campaign
                key={index}
                campaign={campaign}
                campaignIndex={index}
                setShowCampaignForm={setShowCampaignForm}
              />
            );
          })}
        </CampaignsCont>
      </FlexCont>
      {postContactGroups && <LoadingImg blur={2} position='fixed' porcent={calcCreateContactsPorcent()}/>}
    </Cont>
  );
};
const Cont = styled.div`
  margin: 0 20px;
  position: relative;
`;
const FlexCont = styled.div`
  display: flex;
`;
const CampaignsCont = styled.div`
  flex-grow: 1;
`;
//
// filter buttons 
//
const buttons = [
  {
    isSelected: true,
    icon: < Megaphone />,
    value: '',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.all' },
  },
  {
    isSelected: false,
    icon: < Draft width={15}/>,
    value: 'draft',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.draft' },
  },
  {
    isSelected: false,
    icon: < Phone width={17}/>,
    value: 'running',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.ongoing' },
  },
  {
    isSelected: false,
    icon: < Pause width={25} style={{transform: 'translateX(-5px)'}}/>,
    value: 'paused',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.pause' },
  },
  {
    isSelected: false,
    icon: < Completed width={18}/>,
    value: 'completed',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.completed' },
  },
]
export default CampaignsTemplate;
