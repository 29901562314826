import { lightBackgroundBlue, primary } from 'library/colors';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IFirstStageData } from './createWACampaignFirstStage';
import WaMessageTemplateCont from '../atoms/waMessageTemplateCont';

export interface ISecondStageData {
  sheet: string;
  numbersColumn: string;
  sampleData: { [key: string]: string | number };
  variableIndex: number[];
  variablesColumnsName: string[];
  // esta propiedad guarda los datos a ser mostrados en el workspace
  dataColumns: string[];
}
interface ICreateWaCampaignSecondStageProps {
  firstStageData: IFirstStageData;
  secondStageData: ISecondStageData;
  setSecondStageData: (data: React.SetStateAction<ISecondStageData>) => void;
}
const CreateWaCampaignSecondStage: FC<ICreateWaCampaignSecondStageProps> = ({
  firstStageData,
  secondStageData,
  setSecondStageData,
}) => {
  const [mensaje, setMensaje] = useState(firstStageData.template?.data || '');
  // let mensaje = firstStageData.template?.data || ''
  const variables = useMemo(() => mensaje.match(/{{+\w+}}/g) || [], []);

  const regEx = /(\{\{[^}]+\}\})/g;
  const mensajeSeparado = useMemo(() => {
    if (!firstStageData.template) return [];
    return firstStageData.template.data.split(regEx);
  }, []);

  useEffect(() => {
    if (
      !variables ||
      secondStageData.variablesColumnsName.length === 0 ||
      !firstStageData.template
    )
      return setMensaje(mensajeSeparado.join(''));

    const newMessage = mensajeSeparado
      .map((item, index) => {
        if (
          /* regEx.test(item) &&  */ secondStageData.variableIndex.includes(
          index,
        )
        ) {
          const indice = secondStageData.variableIndex.indexOf(index);
          const columnName = secondStageData.variablesColumnsName[indice];
          const value = secondStageData.sampleData[columnName];
          return value || item;
        }
        return item;
      })
      .join('');
    setMensaje(newMessage);
  }, [secondStageData.variablesColumnsName]);

  let sheetData = useMemo(
    () =>
      firstStageData.files.length > 0
        ? firstStageData.files[0].sheets.filter(item => {
          if (item.name === secondStageData.sheet) return item;
        })
        : [],
    [secondStageData.sheet],
  );

  const handleSelectSheetChange = (sheetName: string) => {
    if (sheetName === '') return;
    setSecondStageData(prev => ({
      ...prev,
      sheet: sheetName,
    }));
  };
  const handleSelectColumnChange = (numbersColumn: string) => {
    if (numbersColumn === '') return;

    setSecondStageData(prev => ({
      ...prev,
      numbersColumn: numbersColumn,
      sampleData: sheetData[0].data[0] as { [key: string]: string },
    }));
  };

  const handleSelectMessageVariables = (variableName: string) => {
    const variables = [...secondStageData.variablesColumnsName];
    const index = secondStageData.variablesColumnsName.findIndex(
      name => name === variableName,
    );
    if (index !== -1) {
      variables.splice(index, 1);
    } else {
      variables.push(variableName);
    }
    setSecondStageData(prev => ({
      ...prev,
      variablesColumnsName: variables,
    }));
  };

  const handleSelectColumnsData = (columnName: string) => {
    const columns = structuredClone(secondStageData.dataColumns)
    const index = columns.findIndex(colName => colName === columnName)

    if (index !== -1) columns.splice(index, 1)
    else columns.push(columnName)

    setSecondStageData(prev => ({
      ...prev,
      dataColumns: columns,
    }));
  }

  useEffect(() => {
    if (!variables) return;
    let index: number[] = [];
    variables.forEach(val => {
      index.push(mensajeSeparado.findIndex(item => item === val));
    });
    setSecondStageData(prev => ({
      ...prev,
      variableIndex: index,
    }));
  }, []);
  return (
    <Cont>
      <LeftBlock id="messageId">
        <Info>
          Su Plantilla de mensaje
          {!variables ? (
            ` no contiene ninguna variable.`
          ) : (
            <span>
              {' '}
              contiene <b>{variables.length}</b> variables.
            </span>
          )}
        </Info>

        <Info>Plantilla Seleccionada:</Info>
        <WaMessageTemplateCont message={mensaje} />
      </LeftBlock>
      <RightBlock>
        {/* <Info>Fuente de contactos</Info> */}
        <Info>A continuación debe seleccionar la fuente de contactos.</Info>
        <Flex>
          {firstStageData.files.length > 0 && (
            <SheetSelect
              name="sheetsName"
              value={secondStageData.sheet}
              onChange={e => handleSelectSheetChange(e.target.value)}
            >
              <option value="">Seleccionar hoja</option>
              {firstStageData.files[0].sheetsName.map((sheetName, index) => {
                return (
                  <option key={index} value={sheetName}>
                    {sheetName}
                  </option>
                );
              })}
            </SheetSelect>
          )}
          {secondStageData.sheet !== '' && (
            <ColumnSelect
              name="column"
              value={secondStageData.numbersColumn}
              onChange={e => handleSelectColumnChange(e.target.value)}
            >
              <option value="">Columna</option>
              {sheetData[0] &&
                sheetData[0].columnsToSelect.map((column, index) => {
                  return (
                    <option key={index} value={column.name}>
                      {column.name}
                    </option>
                  );
                })}
            </ColumnSelect>
          )}
        </Flex>
        {variables && secondStageData.numbersColumn && (
          <>
            <Info>
              Seleccione las columnas que contienen los datos en
              el mismo orden de aparicion de las variables.
            </Info>
            <ColumnsToSelectCont>
              {sheetData[0].columnsToSelect.map((column, columnIndex) => {
                if (column.name === secondStageData.numbersColumn) return
                return (
                  <SelectableColumn key={columnIndex}>
                    <input
                      type="checkbox"
                      id={column.name}
                      onChange={e => handleSelectMessageVariables(column.name)}
                    />
                    <label htmlFor={column.name}>{column.name}</label>
                  </SelectableColumn>
                );
              })}
            </ColumnsToSelectCont>
          </>
        )}
        {variables && secondStageData.numbersColumn && (
          <>
            <Info margin='10px 0'>
              Datos para mostrar al agente.
            </Info>
            <ColumnsToSelectCont>
              {sheetData[0].columnsToSelect.map((column, columnIndex) => {
                if (column.name === secondStageData.numbersColumn) return
                return (
                  <SelectableColumn key={columnIndex}>
                    <input
                      type="checkbox"
                      id={column.name}
                      onChange={e => handleSelectColumnsData(column.name)}
                    />
                    <label htmlFor={column.name}>{column.name}</label>
                  </SelectableColumn>
                );
              })}
            </ColumnsToSelectCont>
          </>
        )}
      </RightBlock>
    </Cont>
  );
};
const Cont = styled.div`
  height: 420px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  background-color: ${lightBackgroundBlue};
`;
const LeftBlock = styled.div`
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 50%;
`;
const Info = styled.div<IInfo>`
  margin-bottom: 10px;
  margin: ${props => props.margin};
`;
const RightBlock = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 20px;
  max-width: 50%;
`;
const Label = styled.label<ILabel>`
  font-size: 0.7rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
const Flex = styled.div`
  display: flex;
  margin: 10px 0;
`;
const SheetSelect = styled.select`
  width: 50%;
  margin-right: 20px;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const ColumnSelect = styled.select`
  flex-grow: 1;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const ColumnsToSelectCont = styled.div`
  height: 100px;
  /* flex-grow: 1; */
  padding: 0px 0 0 40px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const SelectableColumn = styled.div`
  display: flex;
  margin: 5px 0 10px;

  & > label {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 10px;
  }
`;
interface ILabel {
  margin?: string;
}
interface IInfo {
  margin?: string
}
export default CreateWaCampaignSecondStage;
