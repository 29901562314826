import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { danger, darkText, primary, lightText, secondary } from 'library/colors';
import { useTranslation } from 'react-i18next';

interface IHorizontalBarGraphProps {
  lostCall: number;
  totalCalls: number;
  padding?: string;
  primaryColor?: string;
  dangerColor?: string;
}

const HorizontalBarGraph: FC<IHorizontalBarGraphProps> = ({
  lostCall,
  totalCalls,
  padding,
  primaryColor = primary,
  dangerColor = danger,
}) => {
  const {t} = useTranslation('liveBoard')
  const [translate, setTranslate] = useState(0);

  const calcPorcentOfMissedCalls = () => {
    const rst = (lostCall * 100) / totalCalls;
    setTranslate(rst);
  };

  useEffect(() => {
    calcPorcentOfMissedCalls();
  }, [lostCall, totalCalls]);

  return (
    <Container padding={padding}>
      <CountContainer>
        <CountGroup>
          <Counter>{lostCall}</Counter>
          <Span backgroundColor={dangerColor}>{t('abandoned')}</Span>
        </CountGroup>
        <CountGroup>
          <Counter>{totalCalls - lostCall}</Counter>
          <Span backgroundColor={primaryColor}>{t('answered')}</Span>
        </CountGroup>
        <CountGroup>
          <Counter>{totalCalls}</Counter>
          <Span>{t('all')}</Span>
        </CountGroup>
      </CountContainer>
      <TotalCalls color={totalCalls === 0 ? secondary : primaryColor}>
        <MissedCalls color={dangerColor} porcent={`${translate}%`} />
      </TotalCalls>
    </Container>
  );
};
const Container = styled.div<IContainer>`
  max-width: 235px;
  color: ${darkText};
  padding: ${props=>props.padding ? props.padding : '33px 0 0'};
`;
const CountContainer = styled.div`
  margin: 0 auto 5px;
  display: flex;
  justify-content: space-between;
`;
const CountGroup = styled.div``;
const Counter = styled.div`
  font-weight: 500;
  font-size: 17px;
`;
const Span = styled.span<ISpanProps>`
  color: ${lightText};
  font-size: 10px;
  font-weight: 700;
  margin: 4px 0;
  padding: 2px 10px;
  border-radius: 30px;
  display: inline-block;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#9ba5a8'};
`;
const TotalCalls = styled.div<ITotalCallsProps>`
  overflow: hidden;
  border-radius: 5px;
  background-color: ${props => props.color};
`;
const MissedCalls = styled.div<IMissedCallsProps>`
  height: 7px;
  border-radius: 5px;
  position: relative;
  right: 100%;
  transition: transform 1s;
  transform: translate(${props => props.porcent});
  background-color: ${props => props.color};
`;
interface IContainer{
  padding?: string;
}
interface ISpanProps {
  backgroundColor?: string;
}
interface IMissedCallsProps {
  color: string;
  porcent?: string;
}
interface ITotalCallsProps {
  color: string;
}
export default HorizontalBarGraph;
