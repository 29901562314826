import { lightBackgroundBlue } from 'library/colors';
import { ICreateCampaignFileContacts } from 'library/interfaces/campaignInterfaces';
import React, { FC } from 'react';
import styled from 'styled-components';
import LoadingFiles, { IFile } from '../molecules/FormLoadingFiles';

interface ICreateCampaignLoadFIlesProps {
  files: IFile[];
  setFiles: Function;
  fileContacts: ICreateCampaignFileContacts[][];
  setFileContacts: Function;
  /* deleteFile: (index: number, files: IFile[]) => void; */
}
const CreateCampaignLoadFIles: FC<ICreateCampaignLoadFIlesProps> = ({
  files,
  setFiles,
  fileContacts,
  setFileContacts
  /* deleteFile */
}) => {
  const deleteFile = (index: number, files: IFile[]) => {
    const newFiles = files;
    newFiles.splice(index, 1);
    setFiles([...newFiles]);
    
    if(!fileContacts[index]) return
    const newfileContacts = fileContacts
    newfileContacts.splice(index, 1);
    setFileContacts([...newfileContacts])
  };
  return (
    <Cont>
      <LoadingFiles files={files} setFiles={setFiles} deleteFile={deleteFile} />
    </Cont>
  );
};
const Cont = styled.div`
  height: 420px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
export default CreateCampaignLoadFIles;
