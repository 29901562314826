import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Fields from '../../foundation/Controls/index';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';
import { ISysNotificationsStore } from 'store/global/reducer';
import { setSysNotifications } from 'store/actions';
import { Phone } from 'library/phone/phone';

interface ISetNotificationsProps {
  isSupervisor: boolean;
  phone?: Phone

}
const SetNotifications: FC<ISetNotificationsProps> = ({ isSupervisor, phone }) => {
  const { t } = useTranslation('header');


  const dispatch = useDispatch();
  const sysNotifications = useSelector<IStore, ISysNotificationsStore>(
    store => store.global.sysNotifications,
  );

  const handleActivateNotification = () => {
    if (Notification.permission === 'denied') {
      return;
    } else if (Notification.permission === 'default') {
      Notification.requestPermission().then(result => {
        if (result !== 'granted') {
          dispatch(setSysNotifications({
            areAllowed: false,
            showPhoneIncomingCalls: false,
            showQueuedCallsNoAnswered: false,
            showNewWaSession: false,
            showNewWaMessage: false
          }))
          return;
        }
        dispatch(setSysNotifications({ ...sysNotifications, areAllowed: true }));
      });
      return
    } else if (
      Notification.permission === 'granted' &&
      !sysNotifications.areAllowed
    ) {
      dispatch(setSysNotifications({ ...sysNotifications, areAllowed: true }));
      return;
    } else if (Notification.permission === 'granted') {
      dispatch(setSysNotifications({
        areAllowed: false,
        showPhoneIncomingCalls: false,
        showQueuedCallsNoAnswered: false,
        showNewWaSession: false,
        showNewWaMessage: false
      }));
      return;
    }
  };

  const handleSetShowPhoneIncomingCalls = (show: boolean) => {
    if (!sysNotifications.areAllowed) return;
    dispatch(setSysNotifications({ ...sysNotifications, showPhoneIncomingCalls: show }));
  };

  const handleSetShowNewSession = (show: boolean) => {
    if (!sysNotifications.areAllowed) return;
    dispatch(setSysNotifications({ ...sysNotifications, showNewWaSession: show }));
  };

  const handleSetShowNewMessage = (show: boolean) => {
    if (!sysNotifications.areAllowed) return;
    dispatch(setSysNotifications({ ...sysNotifications, showNewWaMessage: show }));
  };

  const handleSetShowQueuedCallsNoAnswered = (
    show: boolean,
    isSupervisor: boolean,
  ) => {
    if (!sysNotifications.areAllowed || !isSupervisor) return;
    dispatch(setSysNotifications({
      ...sysNotifications,
      showQueuedCallsNoAnswered: show,
    }));
  };

  useEffect(() => {
    if (!phone) return
    phone.showCallNotification = sysNotifications.showPhoneIncomingCalls
  }, [sysNotifications, phone])

  return (
    <Cont>
      <FlexCont>
        <div>
          <Fields.CheckBox
            label=""
            value={sysNotifications.areAllowed}
            onChange={() => handleActivateNotification()}
            style={undefined}
          />
        </div>
        <Label>
          {t(
            `profileMenu.${sysNotifications.areAllowed ? 'desactivate' : 'activate'
            }`,
          )}
        </Label>
      </FlexCont>
      <Option>
        <Span>
          {sysNotifications.showPhoneIncomingCalls && <span>&#10003;</span>}
        </Span>
        <OptionLabel
          onClick={() =>
            handleSetShowPhoneIncomingCalls(
              !sysNotifications.showPhoneIncomingCalls,
            )
          }
        >
          {t('profileMenu.incomingCalls')}
        </OptionLabel>
      </Option>

      <Option>
        <Span>
          {sysNotifications.showNewWaSession && <span>&#10003;</span>}
        </Span>
        <OptionLabel
          onClick={() =>
            handleSetShowNewSession(
              !sysNotifications.showNewWaSession,
            )
          }
        >
          {t('profileMenu.newWaSession')}
        </OptionLabel>
      </Option>
      <Option>
        <Span>
          {sysNotifications.showNewWaMessage && <span>&#10003;</span>}
        </Span>
        <OptionLabel
          onClick={() =>
            handleSetShowNewMessage(
              !sysNotifications.showNewWaMessage,
            )
          }
        >
          {t('profileMenu.newWaMessage')}
        </OptionLabel>
      </Option>

      <Option>
        <Span>
          {sysNotifications.showQueuedCallsNoAnswered && <span>&#10003;</span>}
        </Span>
        <OptionLabel
          onClick={() =>
            handleSetShowQueuedCallsNoAnswered(
              !sysNotifications.showQueuedCallsNoAnswered,
              isSupervisor,
            )
          }
        >
          {t('profileMenu.queuedCallNoAnswered')}
        </OptionLabel>
      </Option>
    </Cont>
  );
};
const Cont = styled.div`
  margin-top: 10px;
  cursor: default;
  padding-left: 10px;
`;
const FlexCont = styled.div`
  display: flex;
  align-items: center;

  & > div {
    width: 35px;
    cursor: pointer;
  }
`;
const Label = styled.label`
  display: block;
  margin: 0;
`;
const Option = styled.div`
  padding-left: 30px;
  display: flex;
  align-items: center;
`;
const Span = styled.span`
  display: block;
  width: 10px;
  margin-right: 3px;
  cursor: default;
  line-height: 0;
`;
const OptionLabel = styled.label`
  cursor: pointer;
  margin: 5px 0 5px;
  line-height: 12px;
  padding: 0;
  font-weight: 300;
  font-size: 0.7rem;
  width: 150px;
`;
export default SetNotifications;
