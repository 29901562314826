import React, { FC } from 'react';
import styled from 'styled-components';

interface ISubTitleProps {
  children: React.ReactNode;
  center?: React.ReactNode;
  sub?: string;
  circleColor?: string;
}
const SubTitle: FC<ISubTitleProps> = ({
  children,
  center,
  circleColor,
  sub,
}) => {
  return (
    <H3 center={center}>
      {circleColor && <Span circleColor={circleColor}></Span>}
      {children}
      {sub && <Sub>{sub}</Sub>}
    </H3>
  );
};
const H3 = styled.h3<IH3>`
  color: black;
  font-size: 14px;
  font-weight: 600;
  text-align: ${props => props.center && 'center'};
`;
const Span = styled.span<ISpan>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${props => props.circleColor};
`;
const Sub = styled.div`
  font-size: 0.71rem;
  font-weight: 300;
  padding-top: 5px;
`;
interface IH3 {
  center?: React.ReactNode;
  circleColor?: string;
}
interface ISpan {
  circleColor?: string;
}
export default SubTitle;
