import React from 'react';
import PropTypes from 'prop-types';

import styles from './Paper.module.css';

function Paper({ children, style, bg = '#fff', mt, ...props }) {
  return (
    <div
      className={styles['paper-style']}
      style={{ backgroundColor: bg, marginTop: mt, ...style }}
      {...props}
    >
      {children}
    </div>
  );
}

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  bg: PropTypes.string,
  mt: PropTypes.number,
};

export default Paper;
