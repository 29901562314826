import * as React from "react"

export const TextToSpeech = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path fill="currentColor" d="M1.26 13.13h16.51v3.5H1.26z" />
    <path
      fill="currentColor"
      d="M7.76 14.88h3.5v18.97h-3.5zM14.04 20.86h9.78v3.5h-9.78z"
    />
    <path fill="currentColor" d="M17.18 22.61h3.5v11.24h-3.5z" />
    <rect
      fill="currentColor"
      x={24.35}
      y={1.15}
      width={5.23}
      height={11.87}
      rx={2.61}
    />
    <path
      fill="currentColor"
      d="M27 17.33a7.71 7.71 0 0 1-7.7-7.7.92.92 0 1 1 1.84 0 5.86 5.86 0 0 0 11.72 0 .92.92 0 0 1 1.84 0 7.71 7.71 0 0 1-7.7 7.7Z"
    />
    <path
      fill="currentColor"
      d="M27 20.86a.92.92 0 0 1-.92-.92v-3.53a.92.92 0 1 1 1.84 0v3.53a.92.92 0 0 1-.92.92Z"
    />
  </svg>
)

