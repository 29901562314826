import LeftMenuOutbound from 'components/outbound/molecules/leftMenu';
import SearchAndButton from 'components/outbound/molecules/searchAndButton';
import { Completed, Draft, Megaphone } from 'icons';
import { lightBackground } from 'library/colors';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import Scenario from '../organisms/scenario';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { useHistory } from 'react-router-dom';

interface IScenariosTemplateProps { }
const ScenariosTemplate: FC<IScenariosTemplateProps> = ({ }) => {

  const history = useHistory()

  const [filter, setFilter] = useState('')
  const scenarios = useAppSelector(store => store.scenariosV2.scenarios)

  const scenariosToRender = useMemo(() => {
    if (!filter) return scenarios
    const regEx = new RegExp(filter, 'i')
    return scenarios.filter(scenario => regEx.test(scenario.name))
  }, [filter, scenarios])

  const handleCreateNewScenario = () => {
    history.push('/incoming-v2/new')
  }

  const handleInputChange = (value: string) => {
    setFilter(value)
  }

  return (
    <Container>
      <Cont>
        <SearchAndButton
          onButtonClick={() => handleCreateNewScenario()}
          onInputChange={handleInputChange}
          buttonKeys={{ key: 'createScenery', nameSpace: 'scenarios' }}
          inputKeys={{ key: 'searchInput', nameSpace: 'scenarios' }}
        />
        <FlexCont>
          <LeftMenuOutbound filterButtons={filterButtons} setCampaignFilter={() => { }} />
          <ScenariosCont>
            {scenariosToRender.map((scenario, index) => {
              return (<Scenario key={index} scenario={scenario} />)
            })}
          </ScenariosCont>

        </FlexCont>
      </Cont>
    </Container>
  );
};
const Container = styled.div`
flex-grow: 1;
  border-radius: 10px;
  background-color: ${lightBackground};
`;
const Cont = styled.div`
  margin: 0 20px;
  position: relative;
`;
const FlexCont = styled.div`
  display: flex;
`;
const ScenariosCont = styled.div`
  flex-grow: 1;
`;
export default ScenariosTemplate;

const filterButtons = [
  {
    isSelected: true,
    icon: < Megaphone />,
    value: '',
    i18nKeys: { namespace: 'scenarios', key: 'leftMenu.all' },
  },
  {
    isSelected: false,
    icon: < Draft width={15} />,
    value: 'draft',
    i18nKeys: { namespace: 'scenarios', key: 'leftMenu.draft' },
  },
  {
    isSelected: false,
    icon: < Completed width={18} />,
    value: 'completed',
    i18nKeys: { namespace: 'scenarios', key: 'leftMenu.published' },
  },
]