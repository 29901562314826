import FormButtons from 'components/outbound/molecules/formButtons';
import FormHeader from 'components/outbound/molecules/formHeader';
import { popUpBackground, primary } from 'library/colors';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CreateWaCampaignFirstStage, {
  IFirstStageData,
} from '../organisms/createWACampaignFirstStage';
import CreateWaCampaignSecondStage, {
  ISecondStageData,
} from '../organisms/createWaCampaignSecondStage';
import {
  ICreateWACampaign,
  ICreateWACampaignContact,
} from 'library/interfaces/whatsappCampaignInterfaces';
import { useDispatch } from 'react-redux';
import { postWACampaignAction } from 'store/actions';
import CreateCampaignHoras from '../organisms/horarios';
import { getAllFilesAction } from 'store/files/asyncActions';

interface IWACreateCampaignTemplateProps {
  setShowWACampaignForm: (show: boolean) => void;
}
const WACreateCampaignTemplate: FC<IWACreateCampaignTemplateProps> = ({
  setShowWACampaignForm,
}) => {
  const dispatch = useDispatch();

  // handle if the current stage is valid to enable or disable the next button
  const [stageIsValid, setStageIsValid] = useState(false);
  // handle the header state
  const [headerStage, setHeaderStage] = useState(HEADER_STAGE_INIT_STATE());

  const [firstStageData, setFirstStageData] = useState<IFirstStageData>({
    campaignName: '',
    startingResponse: '',
    files: [],
    channel: null,
    scenario: null,
    template: null,
    fileUrl: null,
    fileName: null,
  });
  const [secondStageData, setSecondStageData] = useState<ISecondStageData>({
    sheet: '',
    numbersColumn: '',
    sampleData: {},
    variableIndex: [],
    variablesColumnsName: [],
    dataColumns: []
  });
  const [isManualKey, setIsManualKey] = useState(false)

  const currentHeaderStageIndex = useMemo(() => {
    return headerStage.findIndex(stage => stage.current === true);
  }, [headerStage]);

  const handleHeaderStage = (index: number): void => {
    if (index > headerStage.length - 1) return;
    const newHeaderStage = headerStage.map((stage, i) => {
      if (index === i) return { ...stage, current: true, isSelected: true };
      if (index < i) return { ...stage, current: false, isSelected: false };
      return { ...stage, current: false };
    });
    setStageIsValid(false);
    setHeaderStage(newHeaderStage);
  };

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton'
    ) {
      return setShowWACampaignForm(false);
    }
  };

  const handleCreateCampaign = () => {
    if (
      !secondStageData.sheet ||
      !secondStageData.numbersColumn ||
      !firstStageData.channel
    )
      return;
    const newCampaign: ICreateWACampaign = {
      name: firstStageData.campaignName,
      source: firstStageData.channel.number,
      template_id: firstStageData.template?.id || '',
      scenario_id: firstStageData.scenario?.id || '',
      app_id: firstStageData.channel.appId,
      starting_response: firstStageData.startingResponse,
      contacts: [],
    };

    const sheet =
      firstStageData.files[0]?.sheets.filter(item => {
        if (item.name === secondStageData.sheet) return item;
      }) || [];
    if (sheet.length === 0) return;

    const contacts: ICreateWACampaignContact[] = [];
    const regEx = /\D/

    sheet[0].data.forEach(excelRow => {
      const number: number | string = (excelRow as any)[
        secondStageData.numbersColumn
      ];
      if (regEx.test(number.toString())) return
      const params = secondStageData.variablesColumnsName.map(
        val => (excelRow as any)[val],
      );
      const data: { [key: string]: string | number } = {}
      secondStageData.dataColumns.forEach(column => {
        data[column] = excelRow[column]
      });

      if (firstStageData.template?.templateType === 'IMAGE') {
        contacts.push({
          number: number.toString(),
          params,
          data,
          message: {
            type: 'image',
            image: {
              link: firstStageData.fileUrl || ''
            }
          }
        });

      } else if (firstStageData.template?.templateType === 'DOCUMENT') {
        contacts.push({
          number: number.toString(),
          params,
          data,
          message: {
            type: 'document',
            document: {
              link: firstStageData.fileUrl || '',
              filename: firstStageData.fileName || ''
            }
          }
        });

      } else if (firstStageData.template?.templateType === 'VIDEO') {
        contacts.push({
          number: number.toString(),
          params,
          data,
          message: {
            type: 'video',
            video: {
              link: firstStageData.fileUrl || '',
            }
          }
        });

      } else {
        // case where templateType is TEXT
        contacts.push({ number: number.toString(), params, data });
      }
    });

    newCampaign.contacts = contacts;

    dispatch(postWACampaignAction(newCampaign));
    setShowWACampaignForm(false);
  };

  useEffect(() => {
    dispatch(getAllFilesAction())
  }, [])

  //
  // VALIDATIONS
  //
  useEffect(() => {
    if (!headerStage[0].current) return;
    let isValid = true;
    const { campaignName, files, template } = firstStageData;
    if (!campaignName || !template || files.length === 0) isValid = false;
    setStageIsValid(isValid);
  }, [firstStageData, headerStage]);

  useEffect(() => {
    if (!headerStage[2].current) return;
    const { numbersColumn } = secondStageData;
    let isValid = true;
    if (numbersColumn === '') isValid = false;
    setStageIsValid(isValid);
  }, [secondStageData, headerStage]);

  useEffect(() => {
    if (!headerStage[1].current) return;
    setStageIsValid(true);
  }, [headerStage]);
  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton">X</Exit>
        <FormHeader stages={headerStage} />
        <StageCont>
          {headerStage[0].current && (
            <CreateWaCampaignFirstStage
              isManualKey={isManualKey}
              setIsManualKey={setIsManualKey}
              firstStageData={firstStageData}
              setFirstStageData={setFirstStageData}
            />
          )}
          {headerStage[1].current && <CreateCampaignHoras />}
          {headerStage[2].current && (
            <CreateWaCampaignSecondStage
              firstStageData={firstStageData}
              secondStageData={secondStageData}
              setSecondStageData={setSecondStageData}
            />
          )}
        </StageCont>
        <FormButtons
          stageIsValid={stageIsValid}
          stageIndex={currentHeaderStageIndex}
          stageLength={headerStage.length}
          handleHeaderStage={handleHeaderStage}
          saveCampaign={handleCreateCampaign}
        />
      </FormContainer>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.form`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const StageCont = styled.div`
  min-height: 420px;
`;

//
// constants to initialize react states
//
const HEADER_STAGE_INIT_STATE = () => [
  {
    current: true,
    isSelected: true,
    label: 'Información principal', //t('campaigns.createCampaignForm.formHeader.mainInfo'),
  },
  {
    current: false,
    isSelected: false,
    label: 'Horario',
  },
  {
    current: false,
    isSelected: false,
    label: 'Configuración del mensaje', //t('campaigns.createCampaignForm.formHeader.loadingContactsFiles'),
  },
  // {
  //   current: false,
  //   isSelected: false,
  //   label: t('campaigns.createCampaignForm.formHeader.resume'),
  // },
];
export default WACreateCampaignTemplate;
