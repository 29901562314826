import SelectableAgent, {
  IAgentToSelect,
} from 'components/outbound/atoms/selectableAgent';
import { lightBackground, primary, secondary } from 'library/colors';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ISelectableUserListProps {
  selectableAgents: IAgentToSelect[];
  handleSelectAgent: Function;
  handleSelectAllAgents: Function;
  source?: 'inQueue' | 'free';
  height?: string;
  width?: string;
}
const SelectableUserList: FC<ISelectableUserListProps> = ({
  selectableAgents,
  handleSelectAgent,
  handleSelectAllAgents,
  source,
  height,
  width,
}) => {
  const {t} = useTranslation('summarize')
  const [isChecked, setIsChecked] = useState(false);

  const selectedAgents = selectableAgents.filter(
    agent => agent.isSelected === true,
  ).length;

  const onSelectAgent = (index: number, isSelected: boolean) => {
    handleSelectAgent(index, isSelected, source);
    if (isChecked) setIsChecked(false);
  };
  const selectAllAgents = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSelectAllAgents(event.target.checked, source);
    setIsChecked(!isChecked);
  };
  return (
    <Cont height={height} width={width}>
      <Header>
        <div>
          <Input
            type="checkbox"
            checked={isChecked}
            onChange={e => selectAllAgents(e)}
          />
          <Span>{t('addAgentsForm.selectAll')}</Span>
        </div>
        <div>
          {selectedAgents} / {selectableAgents.length}{' '}
        </div>
      </Header>
      <Agents>
        {selectableAgents.map((agent, index) => {
          return (
            <SelectableAgent
              key={index}
              agent={agent}
              index={index}
              handleSetAgents={onSelectAgent}
            />
          );
        })}
      </Agents>
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: ${props => (props.width ? props.width : '350px')};
  max-height: ${props => (props.height ? props.height : '60vh')};
  border-radius: 8px;
  background-color: ${lightBackground};
`;
const Header = styled.div`
  font-size: 0.7rem;
  display: flex;
  padding: 10px 20px 10px 10px;
  border-bottom: 1px solid ${secondary};
  justify-content: space-between;
`;
const Input = styled.input`
  transform: translateY(3px);
`;
const Span = styled.span`
  margin-left: 10px;
`;
const Agents = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  height: calc(100% - 45px);
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
interface ICont {
  height?: string;
  width?: string;
}
export default SelectableUserList;
