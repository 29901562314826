import { IError, IReportData, IReportType } from './reducer';

import {
  typeGetReportActions,
  typeGetReportFail,
  typeGetReportSuccess,
  GET_REPORT_TYPES,
  GET_REPORT_TYPES_FAIL,
  GET_REPORT_TYPES_SUCCESS,
  IGetReport,
  IGetReportFail,
  IGetReportSuccess,
  IGetReportTypes,
  IGetReportTypesFail,
  IGetReportTypesSuccess,
  IRequestReport,
  IRequestReportFail,
  IRequestReportSuccess,
  typeRequestReport,
  typeRequestReportFail,
  typeRequestReportSuccess,
  IReportDownloaded,
  typeReportDownloaded,
  IGetReportTotalCallsByOrigin,
  GET_REPORT_TOTAL_CALLS_BY_ORIGIN,
  IGetReportTotalCallsByOriginSuccess,
  GET_REPORT_TOTAL_CALLS_BY_ORIGIN_SUCCESS,
  IGetReportTotalCallsByOriginFail,
  GET_REPORT_TOTAL_CALLS_BY_ORIGIN_FAIL,
  IGetReport_V2,
  typeGetReportActions_V2,
  IGetEventsFromBotData,
  GET_EVENTS_FROM_BOT_DATA,
  IGetEventsFromBotDataSuccess,
  GET_EVENTS_FROM_BOT_DATA_SUCCESS,
  IGetEventsFromBotDataFail,
  GET_EVENTS_FROM_BOT_DATA_FAIL,
  IUpdateEventsFromBotDataCounter,
  UPDATE_EVENTS_FROM_BOT_DATA_COUNTER,
  IClearEventsFromBotDataStore,
  CLEAR_EVENTS_FROM_BOT_DATA_STORE,
  IGetEmailByFilters,
  GET_EMAILS_BY_FILTER,
  IGetEmailByFiltersSuccess,
  GET_EMAILS_BY_FILTER_SUCCESS,
  IGetEmailByFiltersFail,
  GET_EMAILS_BY_FILTER_FAIL,
  IClearEmailByFilterStore,
  CLEAR_EMAIL_BY_FILTER_STORE,
  IGetEmailSession,
  GET_EMAIL_SESSION,
  IGetEmailSessionSuccess,
  GET_EMAIL_SESSION_SUCCESS,
  IGetEmailSessionFail,
  GET_EMAIL_SESSION_FAIL,
  IClearEmailSessionStore,
  CLEAR_EMAIL_SESSION_STORE,
  IGetWhatsappUsage,
  GET_WHATSAPP_USAGE,
  IGetWhatsappUsageSuccess,
  GET_WHATSAPP_USAGE_SUCCESS,
  IGetWhatsappUsageFail,
  GET_WHATSAPP_USAGE_FAIL,
  IClearWhatsappUsageStore,
  CLEAR_WHATSAPP_USAGE_STORE,
} from './actionTypes';
import {
  IEmail,
  IEmailFilters,
  IEventFromBotData,
  ITotalCallsByOrigin,
  IWhatsappUsage,
  IWhatsappUsageCounter,
} from 'library/interfaces/reportsInterfaces';

export const getReportTypesAction = (owner: string): IGetReportTypes => ({
  type: GET_REPORT_TYPES,
  payload: { owner },
});

export const getReportTypesSuccess = (
  reportTypes: IReportType[],
): IGetReportTypesSuccess => ({
  type: GET_REPORT_TYPES_SUCCESS,
  payload: { reportTypes },
});

export const getReportTypesFail = (error: IError): IGetReportTypesFail => ({
  type: GET_REPORT_TYPES_FAIL,
  payload: { error },
});

export const getReportTotalCallsByOriginAction = (
  owner: string,
  start_date: string,
  end_date: string,
  exten?: string,
): IGetReportTotalCallsByOrigin => ({
  type: GET_REPORT_TOTAL_CALLS_BY_ORIGIN,
  payload: { owner, start_date, end_date, exten },
});

export const getReportTotalCallsByOriginSuccess = (
  counters: ITotalCallsByOrigin,
): IGetReportTotalCallsByOriginSuccess => ({
  type: GET_REPORT_TOTAL_CALLS_BY_ORIGIN_SUCCESS,
  payload: { counters },
});

export const getReportTotalCallsByOriginFail = (
  error: IError,
): IGetReportTotalCallsByOriginFail => ({
  type: GET_REPORT_TOTAL_CALLS_BY_ORIGIN_FAIL,
  payload: { error },
});

export const requestReportAction = (
  owner: string,
  start_date: string,
  end_date: string,
  reportTypeId: string,
  action: typeRequestReport,
): IRequestReport => ({
  type: action,
  payload: { owner, start_date, end_date, reportTypeId, action },
});

export const requestReportSuccess = (
  requestedReportId: string,
  action: typeRequestReportSuccess,
): IRequestReportSuccess => ({
  type: action,
  payload: { requestedReportId },
});

export const requestReportFail = (
  error: IError,
  action: typeRequestReportFail,
): IRequestReportFail => ({
  type: action,
  payload: { error },
});

export const getReportAction = (
  owner: string,
  requestedReportId: string,
  action: typeGetReportActions,
): IGetReport => ({
  type: action,
  payload: { owner, requestedReportId, action },
});

export const getReportAction_V2 = (
  owner: string,
  start_date: string,
  end_date: string,
  reportTypeId: string,
  action: typeGetReportActions_V2,
  filters?: string,
): IGetReport_V2 => ({
  type: action,
  payload: { owner, start_date, end_date, reportTypeId, action, filters },
});

export const getReportSuccess = (
  data: IReportData,
  action: typeGetReportSuccess,
): IGetReportSuccess => ({
  type: action,
  payload: { data },
});

export const getReportFail = (
  error: IError,
  action: typeGetReportFail,
): IGetReportFail => ({
  type: action,
  payload: { error },
});

export const reportDownloaded = (
  action: typeReportDownloaded,
): IReportDownloaded => ({
  type: action,
});

export const getEventFromBotDataAction = (
  exten: string,
  start: string,
  end: string,
  limit?: number,
): IGetEventsFromBotData => ({
  type: GET_EVENTS_FROM_BOT_DATA,
  payload: { exten, start, end, limit },
});

export const getEventFromBotDataSuccess = (
  events: IEventFromBotData[],
  solvedRate: number | null,
  callMinuteTranscript: { [key: string]: number },
  totalMinuteTranscript: number,
): IGetEventsFromBotDataSuccess => ({
  type: GET_EVENTS_FROM_BOT_DATA_SUCCESS,
  payload: { events, solvedRate, callMinuteTranscript, totalMinuteTranscript },
});

export const getEventFromBotDataFail = (
  error: IError,
): IGetEventsFromBotDataFail => ({
  type: GET_EVENTS_FROM_BOT_DATA_FAIL,
  payload: { error },
});

export const updateEventsFromBotDataCounter = (
  counter: { [key: string]: number },
  colors: { [key: string]: string },
): IUpdateEventsFromBotDataCounter => ({
  type: UPDATE_EVENTS_FROM_BOT_DATA_COUNTER,
  payload: { counter, colors },
});

export const clearEventsFromBotDataStoreAction =
  (): IClearEventsFromBotDataStore => ({
    type: CLEAR_EVENTS_FROM_BOT_DATA_STORE,
  });

export const getEmailsByFilterAction = (
  lastKey: string | null,
  order: string,
  date: { start: string; end: string },
  filter?: IEmailFilters,
): IGetEmailByFilters => ({
  type: GET_EMAILS_BY_FILTER,
  payload: { lastKey, order, date, filter },
});

export const getEmailsByFilterSuccess = (reponse: {
  lastKey: string | null;
  rows: IEmail[];
}): IGetEmailByFiltersSuccess => ({
  type: GET_EMAILS_BY_FILTER_SUCCESS,
  payload: reponse,
});

export const getEmailsByFilterFail = (
  error: IError,
): IGetEmailByFiltersFail => ({
  type: GET_EMAILS_BY_FILTER_FAIL,
  payload: { error },
});

export const clearEmailsByFilterStore = (): IClearEmailByFilterStore => ({
  type: CLEAR_EMAIL_BY_FILTER_STORE,
});

export const getEmailSessionAction = (sessionId: string): IGetEmailSession => ({
  type: GET_EMAIL_SESSION,
  payload: { sessionId },
});

export const getEmailSessionSuccess = (
  rows: IEmail[],
): IGetEmailSessionSuccess => ({
  type: GET_EMAIL_SESSION_SUCCESS,
  payload: { rows },
});

export const getEmailSessionFail = (error: IError): IGetEmailSessionFail => ({
  type: GET_EMAIL_SESSION_FAIL,
  payload: { error },
});

export const clearEmailSessionstore = (): IClearEmailSessionStore => ({
  type: CLEAR_EMAIL_SESSION_STORE,
});

export const getWhatsappUsageAction = (
  appId: string,
  from: string,
  to: string,
): IGetWhatsappUsage => ({
  type: GET_WHATSAPP_USAGE,
  payload: { appId, from, to },
});

export const getWhatsappUsageSuccess = (
  partnerAppUsageList: IWhatsappUsage[],
  counter: IWhatsappUsageCounter,
): IGetWhatsappUsageSuccess => ({
  type: GET_WHATSAPP_USAGE_SUCCESS,
  payload: { partnerAppUsageList, counter },
});

export const getWhatsappUsageFail = (error: IError): IGetWhatsappUsageFail => ({
  type: GET_WHATSAPP_USAGE_FAIL,
  payload: { error },
});

export const clearWhatsappUsageStore = (): IClearWhatsappUsageStore => ({
  type: CLEAR_WHATSAPP_USAGE_STORE,
});
