import * as React from 'react';

export const Numbers = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.292 132.292"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 29.891 0)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 66.178 0)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 102.4 0)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 29.891 35.504)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 66.178 35.504)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 102.4 35.504)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 29.891 70.943)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 66.178 70.943)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 102.4 70.943)"
    />
    <path
      d="M0 0a1.98 1.98 0 1 0 .001-3.959A1.98 1.98 0 0 0 0 0"
      style={{
        fill: '#92d900',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(6.52644 0 0 -6.52644 66.178 106.447)"
    />
  </svg>
);
