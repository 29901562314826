import SelectableUserList from 'components/LiveDashboard/molecules/selectableUserList';
import ArrowsButtons from 'components/LiveDashboard/molecules/userListArrows';
import { IAgentToSelect } from 'components/outbound/atoms/selectableAgent';
import { lightBackground, lightText, primary, secondary } from 'library/colors';
import { ISipperId } from 'library/interfaces/addAgentToQueueInterfaces';
import { IQueue } from 'library/interfaces/crdInterfaces';
import { filterAgents } from 'library/services/addAgentToQueueServices';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateAgentsInQueueAction } from 'store/actions';
import styled from 'styled-components';
import LoadingImg from '../atoms/loading';

interface IAgentListsProps {
  isLoading: boolean;
  selectableAgents: {
    inQueue: IAgentToSelect[];
    free: IAgentToSelect[];
  };
  setSelectableAgents: Function;
  selectedQueue: IQueue;
}
const AgentLists: FC<IAgentListsProps> = ({
  isLoading,
  selectableAgents,
  setSelectableAgents,
  selectedQueue,
}) => {
  const {t} = useTranslation('configuration')
  const dispatch = useDispatch()
  const [filter, setFilter] = useState('');
  const freeAgents = filterAgents(selectableAgents.free, filter)
  const inQueueAgents = filterAgents(selectableAgents.inQueue, filter)

  const handleSelectAgent = (
    index: number,
    isSelected: boolean,
    source: 'inQueue' | 'free',
  ) => {
    const newSelectableAgentsState = selectableAgents[source];
    newSelectableAgentsState[index].isSelected = isSelected;
    setSelectableAgents({
      ...selectableAgents,
      [source]: newSelectableAgentsState,
    });
  };
  const handleSelectAllAgents = (
    selectAll: boolean,
    source: 'inQueue' | 'free',
  ) => {
    const newSelectableAgentsState = selectableAgents[source].map(agent => {
      return { ...agent, isSelected: selectAll };
    });
    setSelectableAgents({
      ...selectableAgents,
      [source]: newSelectableAgentsState,
    });
  };

  const addAgents = () => {
    const selected: IAgentToSelect[] = [];
    const noSelected: IAgentToSelect[] = [];
    selectableAgents.free.forEach(agent => {
      if (agent.isSelected === true)
        return selected.push({ ...agent, isSelected: false });
      noSelected.push(agent);
    });
    setSelectableAgents({
      free: noSelected,
      inQueue: [...selected, ...selectableAgents.inQueue],
    });
  };
  const removeAgents = () => {
    const selected: IAgentToSelect[] = [];
    const noSelected: IAgentToSelect[] = [];
    selectableAgents.inQueue.forEach(agent => {
      if (agent.isSelected === true)
        return selected.push({ ...agent, isSelected: false });
      noSelected.push(agent);
    });
    setSelectableAgents({
      free: [...selected, ...selectableAgents.free],
      inQueue: noSelected,
    });
  };

  const updateAgentsInQueue = () => {
    if(selectedQueue.id === '') return
    const agents: ISipperId[] = [];
    selectableAgents.inQueue.forEach(agent => {
      agents.push({ sippeer_id: agent.exten });
    });
    dispatch(updateAgentsInQueueAction(selectedQueue.id, { agents }));
  };
  return (
    <Cont>
      <Filters>
        <Input
          type="text"
          placeholder={t("addAgentsInQueue.findByUserExten")}
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
        <Button onClick={e=>updateAgentsInQueue()}>{t("addAgentsInQueue.confirm")}</Button>
      </Filters>
      <FlexCont>
        <SelectableUserList
          source="free"
          selectableAgents={freeAgents}
          handleSelectAgent={handleSelectAgent}
          handleSelectAllAgents={handleSelectAllAgents}
        />
        <ArrowsButtons moveToLeft={removeAgents} moveToRight={addAgents} />
        <SelectableUserList
          source="inQueue"
          selectableAgents={inQueueAgents}
          handleSelectAgent={handleSelectAgent}
          handleSelectAllAgents={handleSelectAllAgents}
        />
      </FlexCont>
      {isLoading && <LoadingImg />}
    </Cont>
  );
};
const Cont = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  padding-top: 1px;
  position: relative;
`;
const FlexCont = styled.div`
height: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
`;
const Filters = styled.div`
  font-size: 0.7rem;
  display: flex;
  margin-bottom: 20px;
  
`;
const Input = styled.input`
  
  width: 100%;
  padding: 7px 20px;
  border-radius: 30px;
  border: 1px solid ${secondary};
  background-color: ${lightBackground};
`;
const Button = styled.button`
font-size: 0.8rem;
  border: none;
  border-radius: 30px;
  padding: 5px 20px;
  font-weight: 700;
  margin-left: 30px;
  color: ${lightText};
  background-color: ${primary};

  &:hover {
    background-color: #698203;
  }
`;
export default AgentLists;
