import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import * as Fields from '../../../Fields';
import FileUpload from '../../../foundation/Controls/FileUpload';

AudioLibrary.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  upload: PropTypes.bool,
};

function AudioLibrary({ name, options, upload = true }) {
  return (
    <>
      <Row>
        <Col lg={12}>
          <label>Audio Library</label>
          <Fields.Select2 name={name} options={options} />
        </Col>
      </Row>
      {upload && (
        <Row>
          <Col lg={12}>
            <FileUpload />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col lg={12}>
          <audio controls style={{ width: '100%' }}>
            <source src="sample.mp3" />
          </audio>
        </Col>
      </Row>
    </>
  );
}

export default AudioLibrary;
