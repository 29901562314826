import AgentLists from 'components/Configuration/organisms/agentLists';
import QueueList from 'components/Configuration/organisms/queueList';
import { IAgentToSelect } from 'components/outbound/atoms/selectableAgent';
import { lightBackgroundBlue } from 'library/colors';
import { ICdr, IQueue } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';
import { agentsInQueueToSelectableAgents } from 'library/services/addAgentToQueueServices';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCDRDataUsersAndQueuesAction,
  getPBXAgentsInQueueAction,
} from 'store/actions';
import { IUpdateAgentsInQueueStore } from 'store/configuration/reducer';
import styled from 'styled-components';

interface IAgentsInQueueConfigProps {}
const AgentsInQueueConfig: FC<IAgentsInQueueConfigProps> = ({}) => {
  const { t } = useTranslation('configuration');
  const dispatch = useDispatch();
  const { users, agentsInQueue, isLoading } = useSelector<
    IStore,
    IUpdateAgentsInQueueStore
  >(store => store.configuration.updageAgentsInQueue);

  const [selectedQueue, setSelectedQueue] = useState<IQueue>(
    SELECTED_QUEUE_INIT_STATE,
  );
  const [selectableAgents, setSelectableAgents] = useState<{
    inQueue: IAgentToSelect[];
    free: IAgentToSelect[];
  }>({ inQueue: [], free: [] });

  // GET users and queue from pbx cdr
  useEffect(() => {
    dispatch(getCDRDataUsersAndQueuesAction());
  }, []);

  // after above ===> GET users and queue from pbx cdr
  // transform the user from pbx cdr in free selectable agents
  useEffect(() => {
    const newSelectableAgents: IAgentToSelect[] = users.map(user => {
      return {
        exten: user.exten,
        name: user.name,
        isSelected: false,
      };
    });
    setSelectableAgents(prev => ({ ...prev, free: newSelectableAgents }));
  }, [users]);

  // on select queue, search the agents in that queue
  useEffect(() => {
    if (
      selectedQueue.id === '' ||
      // if queue was selected previously
      agentsInQueue.find(queue => queue.queue_id === selectedQueue.id)
    )
      return;

    dispatch(getPBXAgentsInQueueAction(selectedQueue.id));
  }, [selectedQueue]);

  useEffect(() => {
    if (agentsInQueue.length === 0) return;
    const agentsInSelectedQueue = agentsInQueue.find(
      queue => queue.queue_id === selectedQueue.id,
    )?.agents;
    if (!agentsInSelectedQueue) return;
    const { free, inQueue } = agentsInQueueToSelectableAgents(
      agentsInSelectedQueue,
      users,
    );
    setSelectableAgents({ free, inQueue });
  }, [agentsInQueue, selectedQueue]);

  return (
    <Cont>
      {!selectedQueue.id && <Title>{t('addAgentsInQueue.selectQueue')}</Title>}
      {selectedQueue.id && (
        <Title>
          {t('addAgentsInQueue.editAgentsInQueue', {
            queueName: selectedQueue.name,
          })}
        </Title>
      )}
      <FlexCont>
        <QueueList
          selectedQueue={selectedQueue}
          setSelectedQueue={setSelectedQueue}
        />
        <AgentLists
          isLoading={isLoading}
          selectedQueue={selectedQueue}
          selectableAgents={selectableAgents}
          setSelectableAgents={setSelectableAgents}
        />
      </FlexCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
  height: 100%;
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;
const FlexCont = styled.div`
  display: flex;
  height: calc(100% - 45px);
`;
const SELECTED_QUEUE_INIT_STATE = {
  id: '',
  name: '',
  owner_id: '',
};
export default AgentsInQueueConfig;
