import React, { FC, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import {
  darkBorder,
  lightGreen,
  mediumBackground,
  msgIconsBg,
} from 'library/colors';
import { AnswerCall } from 'icons';
import { dateIsoFormated, } from 'library/services/dateServices';
import { IConversationChannelType } from 'library/enableSpecificFeatures/conversationWorkspace';

interface IChatMessageProps {
  message: {
    source: IConversationChannelType | 'call';
    sender: string;
    message: React.ReactNode;
    imgUrl?: string;
    stickerUrl?: string;
    audioUrl?: string;
    fileUrl?: string;
    timestamp: string;
  };
  chatWidth: number;
  right?: React.ReactNode;
}
const ChatMessage: FC<IChatMessageProps> = ({ right, message, chatWidth }) => {
  const ref = useRef<HTMLDivElement>(null)

  const { message: msg, imgUrl, stickerUrl, audioUrl, fileUrl, sender, source, timestamp } = message

  const date = useMemo(() => {
    return dateIsoFormated(timestamp) || "-"
  }, [timestamp])

  const maxWidth = (): number => {
    if (chatWidth < 400) return 400
    return chatWidth * 0.7
  }
  const handleOpenInNewTab = (url: string) => {
    window.open(url, '_blank')
  }

  useEffect(() => {
    if (!ref.current || !msg?.toString().startsWith('<p>')) return

    ref.current.innerHTML = msg.toString()
  }, [msg])
  return (
    <MessageCont right={right} maxWidth={maxWidth()}>
      <Sender right={right}>{sender}</Sender>

      <Message ref={ref}>
        {msg && msg}
        {imgUrl &&
          <ImgWithClick src={imgUrl} alt="img" onClick={() => handleOpenInNewTab(imgUrl)} />
        }
        {stickerUrl &&
          <Img src={stickerUrl} alt="img" />
        }
        {audioUrl &&
          <audio controls src={audioUrl}>
          </audio>
        }
        {fileUrl &&
          <FileCont  >
            <div onClick={() => handleOpenInNewTab(fileUrl)}></div>
            <embed src={fileUrl} type="application/pdf" />
          </FileCont>
        }
      </Message>
      <Date right={right}>{date}</Date>
      <Icon right={right} source={source}>
        {source === 'call' && <AnswerCall width={'70%'} />}
        {source !== 'call' && <div className={`bx bxl-${source}`}></div>}
      </Icon>
    </MessageCont>
  );
};
const MessageCont = styled.div<IPosition>`
  max-width: ${props => props.maxWidth ? props.maxWidth : 400}px;
  margin: 5px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  align-self: ${props => (props.right ? 'flex-end' : 'flex-start')};
  background-color: ${props =>
    props.right ? mediumBackground : 'rgba(134, 201, 57, 0.1)'};
  box-shadow: 0px 1px 5px ${darkBorder};
  position: relative;
`;
const Sender = styled.div<IPosition>`
  color: ${lightGreen};
  font-weight: 600;
  font-size: 1rem;

  text-align: ${props => props.right && 'right'};
`;
const Message = styled.div`
  margin: 10px 0;
  color: black;
`;
const Date = styled.div<IPosition>`
  font-size: 0.7rem;
  margin-left: ${props => !props.right && '30px'};;
  margin-right: ${props => props.right && '30px'};;
  text-align: ${props => props.right && 'right'};
`;
const Icon = styled.span<IIcon>`
  width: 20px;
  height: 20px;
  bottom: 8px;
  left: ${props => !props.right && '20px'};
  right: ${props => props.right && '20px'};
  border-radius: 50%;
  position: absolute;
  text-align: center;
  color: #fff;
  overflow: hidden;
  background-color: ${props => msgIconsBg[props.source]};

  & > div {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    line-height: 20px;
    font-weight: 600;
  }
`;
const Img = styled.img`
  max-width: 200px;
`;
const ImgWithClick = styled(Img)`
  cursor: pointer;
`;
const FileCont = styled.div`
  position: relative;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;
interface IPosition {
  right?: React.ReactNode;
  maxWidth?: number;
}
interface IIcon {
  right?: React.ReactNode;
  source: IConversationChannelType | 'call';
}
export default ChatMessage;
