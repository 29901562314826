import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

// Import menuDropdown
import Fields from '../foundation/Controls';

import logo from '../../assets/images/logo.svg';
import logoLightSvg from '../../assets/images/logo-light.svg';

//i18n
import { useTranslation, withTranslation } from 'react-i18next';

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  updateIsToMakeCallAction,
  updateDialedNumberAction,
  updateAuthUserDataAction,
  updateIsHeldAction,
} from '../../store/actions';
import styled from 'styled-components';
import NumericFloatKeyboard from './numericKeyboard';
import PhoneCallFloat from './phoneCall';
import PhoneHorizontalButtons from 'components/WorkSpace/molecules/phoneHorizontalButtons';
import TimeCounter from 'components/WorkSpace/atoms/timeCounter';
import PhoneTransferCallFloat from './phoneTransferCall';
import AudioGraph from 'components/WorkSpace/atoms/audioGraph';
import ProfileMenu from 'components/CommonForBoth/TopbarDropdown/profileMenu';

const Header = props => {
  const { phone } = props;
  const { t } = useTranslation('header');
  const dispatch = useDispatch();
  const phoneStore = useSelector(store => store.phone);
  const authUserData = useSelector(state => state.authUserData);
  const server = authUserData.pbx_url;

  const remoteDisplayName =
    phone && phone.getRemoteDisplayName() !== ''
      ? phone.getRemoteDisplayName()
      : '';
  const numberToShow = phoneStore.isIncommingCall
    ? phone.getRemoteId()
    : phoneStore.dialedNumber;

  const history = useHistory();
  const pathname = history.location.pathname;
  const regExp = /workspace/g;
  const isWorkspace = regExp.test(pathname);

  const handleConnectClick = async () => {
    if (!phone) {
      return;
    }
    if (phoneStore.isConnected && !phoneStore.isRegistered) {
      return phone.register();
    } else if (!phoneStore.isConnected && !phoneStore.isRegistered) {
      return phone.connect().then(() => phone.register());
    }
    return phone.unregister();
  };

  const showNumericKeyboard = () => {
    if (phoneStore.isCallAnswered) return;
    if (phoneStore.isToMakeACall !== undefined) {
      return dispatch(updateIsToMakeCallAction(undefined));
    }
    return dispatch(updateIsToMakeCallAction(true));
  };
  const updateDialedNumber = number => {
    dispatch(updateDialedNumberAction(number));
  };
  const handlePhoneKeyboardCallClick = () => {
    if (!phone) {
      return;
    }
    if (phoneStore.isToMakeACall === true) {
      phone.call(phoneStore.dialedNumber, server);
    } else {
      phone.transferCall(phoneStore.dialedNumber, server);
    }
    dispatch(updateIsToMakeCallAction(undefined));
  };

  const handleAcceptButtonClick = () => {
    phone.answer();
  };

  const handleHangUpButtonClick = () => {
    phone.hangup();
  };

  const handleHangUpTransferCall = () => {
    phone.hangupTransferCall();
  };
  const handleConfirmTransfer = () => {
    phone.confirmTransfer();
  };
  const handleBlindTransfer = () => {
    if (phoneStore.dialedNumber === '') return;
    phone.blindTransfer(phoneStore.dialedNumber, server);
    dispatch(updateIsToMakeCallAction(undefined));
  };
  const handleExitKeyboard = () => {
    if (phone.isHeld()) {
      phone.unhold();
      dispatch(updateIsHeldAction(false));
    }
    dispatch(updateIsToMakeCallAction(undefined));
  };
  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen();
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen();
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT,
  //       );
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen();
  //     }
  //   }
  // }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  useEffect(() => {
    if (authUserData.auth0_id !== '') return;
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    dispatch(updateAuthUserDataAction(authUser));
  }, []);

  return (
    <React.Fragment>
      <HeaderCont id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div
            className="d-flex"
            style={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}
          >
            {/* <button
              type="button"
              onClick={() => {}}
              className="btn btn-sm font-size-24 header-item "
            >
              <EditPencil width={22} height={22} />
            </button>

            <button
              type="button"
              onClick={() => {}}
              className="btn btn-sm font-size-24 header-item "
              style={{ marginRight: 16 }}
            >
              <NotepadEmpty width={22} height={22} />
            </button> */}
            {/*  <Dropdown*/}
            {/*    className="d-none d-lg-inline-block ms-1"*/}
            {/*    isOpen={socialDrp}*/}
            {/*    toggle={() => {*/}
            {/*      setsocialDrp(!socialDrp);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <DropdownToggle*/}
            {/*      className="btn header-item noti-icon "*/}
            {/*      tag="button"*/}
            {/*    >*/}
            {/*      <i className="bx bx-customize" />*/}
            {/*    </DropdownToggle>*/}
            {/*    <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">*/}
            {/*      <div className="px-lg-2">*/}
            {/*        <Row className="no-gutters">*/}
            {/*          <Col>*/}
            {/*            <Link className="dropdown-icon-item" to="#">*/}
            {/*              <img src={github} alt="Github" />*/}
            {/*              <span>GitHub</span>*/}
            {/*            </Link>*/}
            {/*          </Col>*/}
            {/*          <Col>*/}
            {/*            <Link className="dropdown-icon-item" to="#">*/}
            {/*              <img src={bitbucket} alt="bitbucket" />*/}
            {/*              <span>Bitbucket</span>*/}
            {/*            </Link>*/}
            {/*          </Col>*/}
            {/*          <Col>*/}
            {/*            <Link className="dropdown-icon-item" to="#">*/}
            {/*              <img src={dribbble} alt="dribbble" />*/}
            {/*              <span>Dribbble</span>*/}
            {/*            </Link>*/}
            {/*          </Col>*/}
            {/*        </Row>*/}
            {/*        <Row className="no-gutters">*/}
            {/*          <Col>*/}
            {/*            <Link className="dropdown-icon-item" to="#">*/}
            {/*              <img src={dropbox} alt="dropbox" />*/}
            {/*              <span>Dropbox</span>*/}
            {/*            </Link>*/}
            {/*          </Col>*/}
            {/*          <Col>*/}
            {/*            <Link className="dropdown-icon-item" to="#">*/}
            {/*              <img src={mail_chimp} alt="mail_chimp" />*/}
            {/*              <span>Mail Chimp</span>*/}
            {/*            </Link>*/}
            {/*          </Col>*/}
            {/*          <Col>*/}
            {/*            <Link className="dropdown-icon-item" to="#">*/}
            {/*              <img src={slack} alt="slack" />*/}
            {/*              <span>Slack</span>*/}
            {/*            </Link>*/}
            {/*          </Col>*/}
            {/*        </Row>*/}
            {/*      </div>*/}
            {/*    </DropdownMenu>*/}
            {/*  </Dropdown>*/}
            {/*  <div className="dropdown d-none d-lg-inline-block ms-1">*/}
            {/*    <button*/}
            {/*      type="button"*/}
            {/*      onClick={() => {*/}
            {/*        toggleFullscreen();*/}
            {/*      }}*/}
            {/*      className="btn header-item noti-icon "*/}
            {/*      data-toggle="fullscreen"*/}
            {/*    >*/}
            {/*      <i className="bx bx-fullscreen" />*/}
            {/*    </button>*/}
            {/*  </div>*/}

            {!isWorkspace && (
              <>
                {phoneStore.isCallAnswered === true && (
                  <>
                    <PhoneDisplay>
                      <div>{phone.getRemoteId()}</div>
                      <TimeCounterCont>
                        <TimeCounter />
                      </TimeCounterCont>
                      <div>
                        <AudioGraph
                          phone={phone}
                          width={78}
                          height={50}
                          bars={28}
                        />
                      </div>
                    </PhoneDisplay>
                    <ButtonsCont>
                      <div>
                        <PhoneHorizontalButtons
                          phone={phone}
                        ></PhoneHorizontalButtons>
                      </div>
                    </ButtonsCont>
                  </>
                )}

                <Icon onClick={() => showNumericKeyboard()}>
                  <i className="bx bx-phone-call text-primary" />
                </Icon>

                {phoneStore.isToMakeACall !== undefined && (
                  <NumericFloatKeyboard
                    isCall={
                      phoneStore.isToMakeACall
                        ? phoneStore.isToMakeACall
                        : false
                    }
                    dialedNumber={phoneStore.dialedNumber}
                    onExitClick={handleExitKeyboard}
                    setDialedNumber={updateDialedNumber}
                    onCallButtonClick={handlePhoneKeyboardCallClick}
                    onBlindTranferButtonClick={handleBlindTransfer}
                  />
                )}
                {phoneStore.isIncommingCall !== undefined &&
                  !phoneStore.isCallAnswered && (
                    <PhoneCallFloat
                      isIncoming={phoneStore.isIncommingCall}
                      dialedNumber={numberToShow}
                      onHangUp={handleHangUpButtonClick}
                      acceptCall={handleAcceptButtonClick}
                      remoteDisplayName={remoteDisplayName}
                    />
                  )}

                {phoneStore.isTransferCall !== undefined && (
                  <PhoneTransferCallFloat
                    isTransferCall={phoneStore.isTransferCall}
                    dialedNumber={
                      phone && phone.getTransferId()
                        ? phone.getTransferId()
                        : ''
                    }
                    onHangUp={handleHangUpTransferCall}
                    transfer={handleConfirmTransfer}
                  />
                )}
              </>
            )}

            <Icon>
              <i className="bx bx-bell text-primary" />
            </Icon>

            <Fields.CheckBox
              value={phoneStore.isRegistered}
              onClick={() => handleConnectClick()}
              onChange={() => {}}
              label={``}
            />

            <State id="chargerClick">
              {t('state')}:{' '}
              {phoneStore.isRegistered ? t('available') : t('disconnected')}
            </State>

            {/*<NotificationDropdown />*/}
            <ProfileMenu phone={phone} />
          </div>
        </div>
      </HeaderCont>
    </React.Fragment>
  );
};
const HeaderCont = styled.header`
  position: relative;
`;
const ButtonsCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 230px;
  margin: 0 20px 0 10px;
`;
const PhoneDisplay = styled.div`
  color: black;
  font-size: 1.5rem;

  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 0px;
`;
const TimeCounterCont = styled.div`
  margin: 0 10px;
`;
const Icon = styled.div`
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
`;
const State = styled.div`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
