import { IUpdateAsespCallStatus } from 'library/interfaces/asespCallStatus';
import { IChatBotStore } from './reducer';

export const GET_CHATBOT_BY_CALL = 'GET_CHATBOT_BY_CALL';
export const GET_CHATBOT_BY_CALL_SUCCESS = 'GET_CHATBOT_BY_CALL_SUCCESS';
export const GET_CHATBOT_BY_CALL_FAIL = 'GET_CHATBOT_BY_CALL_FAIL';

export const UPDATE_ASESP_CALL_STATUS = 'UPDATE_ASESP_CALL_STATUS'
export const UPDATE_ASESP_CALL_STATUS_SUCCESS = 'UPDATE_ASESP_CALL_STATUS_SUCCESS'
export const UPDATE_ASESP_CALL_STATUS_FAIL = 'UPDATE_ASESP_CALL_STATUS_FAIL'

export type ChatBotActionTypes = IGetChatBotByCall | IGetChatBotByCallSuccess | IGetChatBotByCallFail | IUpdateCallStatus | IUpdateCallStatusSuccess | IUpdateCallStatusFail;

export interface IGetChatBotByCall {
  type: typeof GET_CHATBOT_BY_CALL;
  payload: {
    owner: string;
    uniqueId: string;
  };
}

export interface IGetChatBotByCallSuccess {
  type: typeof GET_CHATBOT_BY_CALL_SUCCESS;
  payload: IChatBotStore;
}

export interface IGetChatBotByCallFail {
  type: typeof GET_CHATBOT_BY_CALL_FAIL;
  payload: string;
}

export interface IUpdateCallStatus {
  type: typeof UPDATE_ASESP_CALL_STATUS,
  payload: {callStatus: IUpdateAsespCallStatus}
}

export interface IUpdateCallStatusSuccess {
  type: typeof UPDATE_ASESP_CALL_STATUS_SUCCESS,
}

export interface IUpdateCallStatusFail {
  type: typeof UPDATE_ASESP_CALL_STATUS_FAIL,
}