import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

ViewAudioLibrary.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
};

function ViewAudioLibrary({ name, src = '' }) {
  return (
    <>
      <Row>
        <Col lg={12}>
          <div className="data-info-label">Audio Library</div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg={12}>
          <audio controls style={{ width: '100%' }}>
            <source src={src} />
          </audio>
        </Col>
      </Row>
    </>
  );
}

export default ViewAudioLibrary;
