import { danger, lightBackgroundBlue, primary } from 'library/colors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IInputsAndButtonsProps {
  setFilter: (value: string) => void;
  setShowUploadDataForm: () => void
  setShowModifyColumnsForm: () => void
  handleDeleteRowsClick: () => void
  handleSearchClick: () => void
}
const InputsAndButtons: FC<IInputsAndButtonsProps> = ({
  setFilter,
  setShowUploadDataForm,
  setShowModifyColumnsForm,
  handleDeleteRowsClick,
  handleSearchClick
}) => {
  const { t } = useTranslation('databases')
  return (
    <Cont>
      <InputCont>
        <Input placeholder={t('databaseData.find')} onChange={e => setFilter(e.target.value)} />
        <InputIconCont>
          <i className='bx bx-search' onClick={() => handleSearchClick()} />
        </InputIconCont>
      </InputCont>
      <div>
        <Delete onClick={() => handleDeleteRowsClick()}>+ {t('databaseData.deleteRow')}</Delete>
        <Button onClick={() => setShowModifyColumnsForm()}>+ {t('databaseData.columns')}</Button>
        <Button onClick={() => setShowUploadDataForm()}>+ {t('databaseData.addRows')}</Button>
      </div>
    </Cont>
  );
};
const Cont = styled.div`
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
`;
const InputCont = styled.div`
  flex-grow: 1;
  position: relative;
`;
const Input = styled.input`
  width: 100%;
  border: none;
  padding: 5px 20px;
  border-radius: 50px;
  background-color: ${lightBackgroundBlue};
  padding-right: 30px;
  
  ::placeholder {
    color: black;
  }
`;
const InputIconCont = styled.div`
  color: black;
  font-size: 1.2rem;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;

  & > i {
    position: absolute;
  }
`;
const Button = styled.button`
    border: none;
    border-radius: 50px;
    color: white;
    padding: 5px 20px;
    margin-left: 20px;
    background-color: ${primary};
`;
const Delete = styled(Button)`
  background-color: ${danger};
`;
export default InputsAndButtons;