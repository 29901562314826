import React, { FC } from 'react';
import styled from 'styled-components';

import { darkBorder } from 'library/colors';

interface IScreen {
  dialedNumber: string;
  deleteNumber: Function;
}
const Screen: FC<IScreen> = ({ dialedNumber, deleteNumber }) => {
  return (
    <ScreenCont>
      <Number>{dialedNumber}</Number>
      <Delete onClick={() => deleteNumber()}>X</Delete>
    </ScreenCont>
  );
};
const ScreenCont = styled.div`
  border: 1px solid ${darkBorder};
  display: flex;
  max-width: 250px;
  margin: 0px auto;
  line-height: 30px;
`;
const Number = styled.div`
  flex-grow: 1;
  font-size: 1.5rem;
  text-align: right;
`;
const Delete = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 5px;
  padding: 0 10px 0 20px;
  background-color: ${darkBorder};
  clip-path: polygon(0 50%, 30% 0, 100% 0, 100% 100%, 30% 100%);
`;
export default Screen;
