import { lightBackgroundBlue, primary } from 'library/colors';
import { Recording } from 'library/interfaces/recordingsInterface';
import IStore from 'library/interfaces/storeInterface';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PopUpContainer from '../atoms/popupContainer';

interface ICallListPopUpProps {
  filter: string | null;
  handleExitClick: Function;
  dates: { start_date: string; end_date: string };
  handleMoreDetailsClick: Function;
}
const CallListPopUp: FC<ICallListPopUpProps> = ({
  filter,
  handleExitClick,
  dates,
  handleMoreDetailsClick,
}) => {
  const { t } = useTranslation('reports');
  const recordings = useSelector<IStore, Recording[]>(
    store => store.recordings.Items,
  );

  const handleExit = (id: string) => {
    if (id !== 'popUpContainer' && id !== 'exitButton') return;
    handleExitClick();
  };

  return (
    <PopUpContainer onExitClick={handleExit}>
      <Cont>
        <Exit id="exitButton">x</Exit>
        <BlueCont>
          <Title>
            {t('totalCallsReport.callListPopUp.calls')}
            <b>{t(`callIndicator.${filter}`)}</b>
            {t('totalCallsReport.callListPopUp.from')}
            <b>{dates.start_date}</b>
            {t('totalCallsReport.callListPopUp.to')} <b>{dates.end_date}</b>{' '}
          </Title>
        </BlueCont>
        <BlueCont>
          <CallsCont>
            <Header>
              <div>Uuid</div>
              <div>{t('totalCallsReport.callListPopUp.source')}</div>
              <div>{t('totalCallsReport.callListPopUp.destination')}</div>
              <div>{t('totalCallsReport.callListPopUp.callState')}</div>
              <div>{t('totalCallsReport.callListPopUp.startDate')}</div>
            </Header>
            {recordings.map((recording, index) => {
              return (
                <GridCont key={index}>
                  <div>
                    {recording.uuid.slice(recording.uuid.lastIndexOf('-') + 1)}
                  </div>
                  <div>{recording.source}</div>
                  <div>{recording.destination}</div>
                  <div
                    className={`
                    ${
                      recording.disposition === 'ANSWERED'
                        ? 'bg-primary'
                        : recording.disposition === 'ABANDON'
                        ? 'bg-danger'
                        : recording.disposition === 'NO ANSWER'
                        ? 'bg-warning'
                        : recording.disposition === 'BUSY'
                        ? 'bg-secondary'
                        : recording.disposition === 'FAILED'
                        ? 'bg-info'
                        : 'bg-dark'
                    }
                  `}
                  >
                    {recording.disposition}
                  </div>
                  <div>
                    {recording.startDate
                      .replace(/([T.])/g, ' / ')
                      .substr(0, 22)}
                  </div>
                </GridCont>
              );
            })}
          </CallsCont>
        </BlueCont>
        <ButtonCont>
          <Button onClick={() => handleMoreDetailsClick()}>
            {t('totalCallsReport.callListPopUp.moreDetails')}
          </Button>
        </ButtonCont>
      </Cont>
    </PopUpContainer>
  );
};
const Cont = styled.div`
  position: fixed;
  top: 120px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;

const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const BlueCont = styled.div`
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.h2`
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    margin: 0 10px;
  }
`;
const CallsCont = styled.div`
  height: 40vh;
  overflow-y: scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const GridCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 100px 120px 1fr;
  grid-template-rows: min-content;
  justify-items: center;

  & > div {
    width: 100%;
    text-align: center;
  }
`;
const Header = styled(GridCont)`
  position: sticky;
  top: 0px;
  padding: 10px 0;
  background-color: ${lightBackgroundBlue};
`;
const ButtonCont = styled(BlueCont)`
  display: flex;
  justify-content: end;
  padding-right: 50px;
`;
const Button = styled.button`
  border-radius: 50px;
  margin: 0;
  display: inline-block;
  min-width: 30px;
  font-size: 13px;
  padding: 8px 16px;
  color: white;
  border: 2px solid ${primary};
  background-color: ${primary};
`;
export default CallListPopUp;
