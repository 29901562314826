import { ISysNotificationsStore } from "./reducer";

export const SET_SYS_NOTIFICATIONS = 'SET_SYS_NOTIFICATIONS'
export const UPDATE_SHOW_SYS_NOTIFICATIONS = 'UPDATE_SHOW_NOTIFICATIONS';
export const SHOW_INCOMING_CALL_SYS_NOTIFICATION =
  'SHOW_INCOMING_CALL_NOTIFICATION';
export const SHOW_QUEUED_CALLS_NO_ANSWERED_SYS_NOTIFICATION =
  'SHOW_QUEUED_CALLS_NO_ANSWER_SYS_NOTIFICATION';

export type ShowSysNotificationsActionTypes = ISetSysNotifications | IUpdateShowSysNotifications

export type UpdateShowSysNotifications =
  | typeof UPDATE_SHOW_SYS_NOTIFICATIONS
  | typeof SHOW_INCOMING_CALL_SYS_NOTIFICATION
  | typeof SHOW_QUEUED_CALLS_NO_ANSWERED_SYS_NOTIFICATION;

export interface ISetSysNotifications {
  type: typeof SET_SYS_NOTIFICATIONS,
  payload: ISysNotificationsStore
}
export interface IUpdateShowSysNotifications {
  type: UpdateShowSysNotifications;
  payload: { show: boolean };
}
