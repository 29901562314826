import {
  IQueue,
  IReportData,
  ISummarize,
} from 'library/interfaces/crdInterfaces';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';
import {
  IModelSummarizeData,
  ISummarizeAgent,
} from 'library/interfaces/summarizeInterface';
import { agentsStatusCounter, occupationRange } from './liveBoardService';

const SummarizeService = (
  reportData: IReportData,
  LiveData: ILiveDataQueue[],
  summarizeData: ISummarize[],
  queueId: string,
): IModelSummarizeData => {
  // queue contains the data from the selected queue
  const queue = queueData(reportData, queueId);

  // agents state and occupation range calculate from the liveData
  const { agents_state, occupation_range } = agentsStateDataAndOcupationRange(
    LiveData,
    queueId,
  );

  // call contains the liveData > calls from the selected queue
  const calls = callsData(LiveData, queueId);

  // summarize data
  const {
    service_level,
    abandoned_calls,
    answered_calls,
    incoming_calls,
    calltime_average,
    calltime_min_time,
    calltime_max_time,
    calltime_change,
    waiting_average,
    waiting_min_time,
    waiting_max_time,
    waiting_change,
  } = summarizeObject(summarizeData, queueId);

  // agents data contains the agents with state diferent to unavailable
  const agents = agentsData(LiveData, reportData, queueId);

  // transform the server data
  const callTimeAverageNumber =
    convertTimeStringToSecondsNumber(calltime_average);
  const waitingAverageNumber =
    convertTimeStringToSecondsNumber(waiting_average);

  // calculate and transform the given data to required data and format
  const service_average = convertSecondsNumberToTimeString(
    callTimeAverageNumber + waitingAverageNumber,
  );
  const service_min_time = convertSecondsNumberToTimeString(
    calltime_min_time + waiting_min_time,
  );
  const service_max_time = convertSecondsNumberToTimeString(
    calltime_max_time + waiting_max_time,
  );
  const service_change = convertSecondsNumberToTimeString(
    calltime_change + waiting_change,
  );

  const response = {
    queue,
    agents_state,
    occupation_range,
    calls,

    service_level,
    abandoned_calls,
    answered_calls,
    incoming_calls,

    calltime_average,
    calltime_min_time: convertSecondsNumberToTimeString(calltime_min_time),
    calltime_max_time: convertSecondsNumberToTimeString(calltime_max_time),
    calltime_change: convertSecondsNumberToTimeString(calltime_change),

    waiting_average,
    waiting_min_time: convertSecondsNumberToTimeString(waiting_min_time),
    waiting_max_time: convertSecondsNumberToTimeString(waiting_max_time),
    waiting_change: convertSecondsNumberToTimeString(waiting_change),

    service_average,
    service_min_time,
    service_max_time,
    service_change,

    agents,
  };

  return response;
};
//
// all the functions contains a default value to return
// and validate if exists required data
//
// if exist the data update the default object validating the new data
// in another case return the default object to avoid errors
//
const queueData = (reportData: IReportData, queueId: string): IQueue => {
  const queue = { id: '', name: '', owner_id: '' };
  const queueReportData = reportData.queues.filter(item => item.id === queueId);
  if (queueReportData.length > 0) {
    queue.id = queueReportData[0].id;
    queue.name = queueReportData[0].name;
    queue.owner_id = queueReportData[0].owner_id;
  }
  return queue;
};

const agentsStateDataAndOcupationRange = (
  LiveData: ILiveDataQueue[],
  queueId: string,
) => {
  let occupation_range = 0;

  let agents_state = {
    total: 0,
    incall: 0,
    ringing: 0,
    onhold: 0,
    available: 0,
    unavailable: 0,
    connected: 0,
  };

  const live = LiveData.find(item => item.id === queueId);
  if (live) {
    const counter = agentsStatusCounter(live.agentsStatus);
    const total = live.agentsStatus.length;
    const connected =
      live.agentsStatus.length - counter.unavailable - counter.onhold;
    const busy = counter.incall + counter.ringing;

    agents_state = {
      ...agents_state,
      ...counter,
      total,
      connected,
    };

    occupation_range = occupationRange(busy, connected);
  }
  return { occupation_range, agents_state };
};

const callsData = (LiveData: ILiveDataQueue[], queueId: string) => {
  const calls = {
    inqueue: 0,
    answered: 0,
    abandon: 0,
    online: 0,
    answeredCount: 0,
    abandonedCount: 0,
  };
  const live = LiveData.find(item => item.id === queueId);
  if (live) {
    calls.inqueue = live.callsStatus.queued;
    calls.answered = live.callsStatus.answered;
    calls.abandon = live.callsStatus.abandoned;
    calls.online = live.callsStatus.online;
    calls.answeredCount = live.callsStatus.answeredCount;
    calls.abandonedCount = live.callsStatus.abandonedCount;
  }
  return calls;
};

const summarizeObject = (summarizeData: ISummarize[], queueId: string) => {
  const summarize = summarizeData.filter(item => item.queue === queueId);
  return {
    service_level: summarize[0]?.sla ? Math.round(summarize[0].sla) : 0,
    abandoned_calls: summarize[0]?.abandoned_calls
      ? summarize[0].abandoned_calls
      : 0,
    answered_calls: summarize[0]?.answered_calls
      ? summarize[0].answered_calls
      : 0,
    incoming_calls: summarize[0]?.incoming_calls
      ? summarize[0].incoming_calls
      : 0,

    calltime_average: summarize[0]?.calltime_average
      ? summarize[0].calltime_average.substring(3)
      : '00:00',
    calltime_min_time: summarize[0]?.calltime_min_time
      ? summarize[0].calltime_min_time
      : 0,
    calltime_max_time: summarize[0]?.calltime_max_time
      ? summarize[0].calltime_max_time
      : 0,
    calltime_change: summarize[0]?.calltime_change
      ? summarize[0].calltime_change
      : 0,

    waiting_average: summarize[0]?.waiting_average
      ? summarize[0].waiting_average.substring(3)
      : '00:00',
    waiting_min_time: summarize[0]?.waiting_min_time
      ? summarize[0].waiting_min_time
      : 0,
    waiting_max_time: summarize[0]?.waiting_max_time
      ? summarize[0].waiting_max_time
      : 0,
    waiting_change: summarize[0]?.waiting_change
      ? summarize[0].waiting_change
      : 0,
  };
};

export const convertSecondsNumberToTimeString = (secondsNumber: number) => {
  const date = new Date(secondsNumber * 1000);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  const timeString =
    hours !== '00' ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;

  return timeString;
};
const convertTimeStringToSecondsNumber = (time: string) => {
  const minutes = time.slice(0, 2);
  const seconds = time.slice(3, 5);

  const minutesToSeconds = parseInt(minutes) * 60;
  const secondsToSeconds = parseInt(seconds);

  const totalSeconds = minutesToSeconds + secondsToSeconds;

  return totalSeconds;
};

export const agentsData = (
  LiveData: ILiveDataQueue[],
  reportData: IReportData,
  queueId: string,
): ISummarizeAgent[] => {
  const live = LiveData.find(item => item.id === queueId);

  const connectedAgents = live
    ? live.agentsStatus.filter(item => item.state !== 'unavailable')
    : [];

  const agents = connectedAgents.map(item => {
    const agentInfo = reportData.users.filter(
      user => user.exten_name === item.id,
    )[0];

    return {
      ...agentInfo,
      agent: item.id,
      state: item.state,
      serviceLevel: {
        answered: item.answeredCount,
        unanswered: item.unansweredCount,
        serviceLevel: item.serviceLevel === -1 ? 0 : item.serviceLevel,
        availableDuration: item.availableDuration,
      },
    };
  });
  return agents;
};
export default SummarizeService;
