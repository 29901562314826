import * as React from "react"

export const ScenarioEnd = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M17.5 28.29A13.89 13.89 0 1 1 31.39 14.4 13.9 13.9 0 0 1 17.5 28.29Zm0-25.94A12 12 0 1 0 29.55 14.4 12.06 12.06 0 0 0 17.5 2.35Z"
    />
    <path
      fill="currentColor"
      d="M12.21 20.17a.92.92 0 0 1-.65-1.57L21.63 8.53a.94.94 0 0 1 1.3 0 .92.92 0 0 1 0 1.3L12.86 19.9a.91.91 0 0 1-.65.27Z"
    />
    <path
      fill="currentColor"
      d="M22.28 20.17a.91.91 0 0 1-.65-.27L11.56 9.83a.92.92 0 0 1 0-1.3.94.94 0 0 1 1.3 0L22.93 18.6a.92.92 0 0 1-.65 1.57ZM14.48 27.37h6v6.09A1.54 1.54 0 0 1 19 35h-3a1.54 1.54 0 0 1-1.54-1.54v-6.09h.02Z"
    />
    <path
      fill="currentColor"
      d="M30 33.44H5a.92.92 0 0 1 0-1.84h25a.92.92 0 1 1 0 1.84Z"
    />
  </svg>
)

