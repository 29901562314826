import * as React from 'react';

export const Completed = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="svg8"
    version="1.1"
    viewBox="0 0 115.18378 115.09755"
    {...props}
  >
    <defs id="defs2" />

    <g transform="translate(-59.813527,-90.306417)" id="layer1">
      <path
        id="path117"
        fill="currentColor"
        strokeWidth="0.66668564"
        d="m 107.10299,204.69836 c -18.452852,-3.62108 -34.306423,-15.93105 -42.021136,-32.62853 -7.016965,-15.1873 -7.024902,-33.43941 -0.02097,-48.22318 9.950217,-21.00276 29.658718,-33.540233 52.724136,-33.540233 6.73646,0 14.59088,1.368658 18.3188,3.192111 2.79214,1.365733 3.38319,3.564001 1.34255,4.993318 -1.24738,0.873708 -1.92394,0.832489 -6.20477,-0.378026 -6.40706,-1.811761 -20.12034,-1.958014 -26.47201,-0.282326 -8.028357,2.118032 -16.022691,6.615856 -22.270636,12.530056 -11.188665,10.591 -16.38194,22.5262 -16.392454,37.6732 -0.01807,26.04743 19.171713,47.6584 45.29371,51.00841 14.70426,1.88575 29.9803,-3.15657 41.11287,-13.57056 11.39751,-10.6618 16.40342,-22.88279 16.21509,-39.58602 -0.10075,-8.93523 0.10309,-9.54733 3.18655,-9.56865 2.47223,-0.0171 3.09619,2.27507 3.08238,11.32345 -0.0147,9.61525 -1.41014,16.21342 -5.13323,24.27156 -7.13824,15.44978 -21.21873,27.37943 -37.44226,31.72285 -6.83949,1.83109 -18.83653,2.33458 -25.31862,1.06257 z m -2.47516,-50.22095 c -5.086861,-5.04043 -9.419525,-9.84447 -9.628131,-10.67563 -0.208606,-0.83116 -0.0091,-1.95732 0.44348,-2.50257 1.771703,-2.13478 3.590028,-1.04155 11.663221,7.01226 l 8.02289,8.00362 25.59896,-26.83749 c 14.07943,-14.76063 26.28099,-27.11412 27.11457,-27.4522 1.92088,-0.77908 3.59534,0.48565 3.59534,2.71557 0,2.17088 -54.10894,58.90087 -56.17951,58.90087 -0.88261,0 -4.72401,-3.31154 -10.63082,-9.16443 z"
      />
    </g>
  </svg>
);
