import React from 'react';
import MetaTags from 'react-meta-tags';

import QueuedCallsDetailedTemplate from 'components/LiveDashboard/templates/queuedCallsDetailedTemplate';

const PagesStarter = ({ match }) => {
  const { uid } = match.params;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Queued Calls Detailed | SmartBot</title>
        </MetaTags>

        <QueuedCallsDetailedTemplate uid={uid} />
      </div>
    </React.Fragment>
  );
};
export default PagesStarter;
