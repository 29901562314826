import React from 'react';
import NodeModal from '../common/NodeModal';
import PropTypes from 'prop-types';

function Modal({ Icon, data, toggle, isOpen }) {
  return (
    <NodeModal Icon={Icon} data={data} isOpen={isOpen} toggle={toggle}>
      After a client ends the conversation with an agent, it may be necessary to
      ask the client questions about the quality of service, provide them with
      additional information, and so on. Attention! This block can proceed only
      from &quot;Success!&quot; ports of the Call Forwarding, Cal to Queue and
      Extension blocks.
    </NodeModal>
  );
}

Modal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  Icon: PropTypes.any,
};

export default Modal;
