import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import * as Fields from '../../Fields';
import SynthLanguage from '../common/SynthLanguage';

const validationSchema = Yup.object({
  synthLanguage: Yup.object().nullable(),
  voice: Yup.object().nullable(),
  text: Yup.string().required('required'),
});

Edit.propTypes = {
  data: PropTypes.object.isRequired,
  toggleInEdit: PropTypes.func.isRequired,
};

function Edit({ data, toggleInEdit }) {
  const onSubmit = values => {
    data.synthLanguage = values.synthLanguage;
    data.voice = values.voice;
    data.text = values.text;
    toggleInEdit();
  };

  return (
    <Formik
      initialValues={{
        synthLanguage: data?.synthLanguage || '',
        voice: data?.voice || '',
        text: data?.text || 'Hello! This is an automated information system',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <SynthLanguage />

          <Row>
            <Col lg={12}>
              <Label>
                Text (
                <a
                  href="https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  SSML tags
                </a>
                ) & (
                <a
                  href="https://cloud.google.com/text-to-speech"
                  target="_blank"
                  rel="noreferrer"
                >
                  SSML voices
                </a>
                )
              </Label>
              <Fields.TextArea name="text" placeholder="Text ..." />
            </Col>
          </Row>

          <div className="mt-3">
            <button type="submit" className="btn btn-primary btn-sm">
              Save
            </button>
            <button className="btn btn-default btn-sm" onClick={toggleInEdit}>
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Edit;
