import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import * as Fields from '../';
import { Col, Row } from 'reactstrap';

SwitchContainer.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

export function SwitchContainer({
  name,
  label,
  children,
  className,
  ...props
}) {
  const [field] = useField(name);

  return (
    <Row className={className}>
      <Col md={12}>
        <Fields.Switch name={name} label={label} {...props} />

        {field.value && <div>{children}</div>}
      </Col>
    </Row>
  );
}
