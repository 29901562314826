import * as React from 'react';

export const Outgoing = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0c-.51.51-1.35.51-1.86 0l-1.39-1.39c-.9.52-1.79 1.2-2.62 2.03S-7.38 2.36-7.9 3.26l1.39 1.39c.51.51.51 1.35 0 1.86L-7.9 7.9c-.51.51-1.35.51-1.86 0l-.73-.73c-1.8-1.8-.76-5.75 2.32-8.83S-1.13-5.78.66-3.98l.73.73c.51.51.51 1.35 0 1.86z"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      transform="matrix(5.2398 0 0 -5.8289 108.51 51.242)"
    />
    <path
      d="M0 0v4.72h-4.72"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      transform="matrix(5.2398 0 0 -5.8289 118.519 31.016)"
    />
    <path
      d="m0 0-4.72-4.72"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      transform="matrix(5.2398 0 0 -5.8289 118.519 3.504)"
    />
    <path
      d="M0 0v-1.4c0-1.98-1.6-3.58-3.58-3.58h-4.13l-4.5-3.75.09 3.74c-1.98 0-3.58 1.6-3.58 3.58v7.14c0 1.98 1.6 3.58 3.58 3.58h3.11"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      transform="matrix(5.2398 0 0 -5.8289 96.04 79.22)"
    />
    <path
      d="M0 0h5.5"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      transform="matrix(5.2398 0 0 -5.8289 27.398 48.56)"
    />
    <path
      d="M0 0h8.42"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      transform="matrix(5.2398 0 0 -5.8289 27.398 67.213)"
    />
    <path
      d="M0 0h10.36"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      transform="matrix(5.2398 0 0 -5.8289 27.398 85.05)"
    />
  </svg>
);
