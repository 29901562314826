import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import PageHeader from '../../components/Common/PageHeader';
import Paper from '../../components/foundation/Paper';

import {
  ScenePublished,
  SceneEraser,
  SceneAll,
  FolderAdd,
  SceneCheck,
  SceneStar,
} from '../../icons';
import InternalSidebar from '../../components/InternalSidebar';
import ScenerySheetGallery from '../../components/ScenarySheetGallery';
import OrderBy from '../../components/incoming/OrderBy';
import SearchScenery from '../../components/incoming/SearchScenery';
import SceneryList from '../../components/incoming/SceneryList';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import {
  getScenarios as onGetScenarios,
  deleteScenario as onDeleteScenario,
  clearScenarioStoreAction,
} from 'store/actions';

const sidebarItems = [
  {
    Icon: SceneAll,
    text: 'Todos',
    onClick: () => {},
    divider: false,
  },
  {
    Icon: ScenePublished,
    text: 'Publicados',
    onClick: () => {},
    divider: false,
  },
  {
    Icon: SceneEraser,
    text: 'Borradores',
    onClick: () => {},
    divider: false,
  },
  {
    Icon: FolderAdd,
    text: 'Crear carpeta',
    onClick: () => {},
    divider: true,
  },
];

const sceneryGalleryItems = [
  {
    id: 1,
    Icon: SceneCheck,
    text: 'Llamada',
  },
  {
    id: 2,
    Icon: SceneCheck,
    text: 'Notificatión',
  },
  {
    id: 3,
    Icon: SceneStar,
    text: 'Assistente de voz',
  },
];

const Index = () => {
  const dispatch = useDispatch();
  const [displayScenarios, setDisplayScenarios] = useState([]);

  const { scenarios } = useSelector(state => ({
    scenarios: state.scenarios.scenarios,
  }));

  const version = location.pathname === '/v2/incoming' ? 'v2' : 'v1';

  useEffect(() => {
    dispatch(onGetScenarios(version));
  }, [onGetScenarios]);

  useEffect(() => {
    return () => {
      dispatch(clearScenarioStoreAction());
    };
  }, []);

  useEffect(() => {
    if (scenarios) {
      setDisplayScenarios(
        scenarios.map(s => {
          (s.key = s.id),
            (s.type = s.published ? 'Publicado' : 'Borrador'),
            (s.text = s.name);
          s.updatedAt = moment(s.updatedAt).format('DD-MM-YY hh:mm:ss');
          s.createAt = moment(s.createAt).format('DD-MM-YY hh:mm:ss');
          return s;
        }),
      );
    }
  }, [scenarios]);

  const onDeleteItem = id => {
    dispatch(onDeleteScenario(id));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Entrantes | SmartBot</title>
        </MetaTags>
        <Container fluid>
          <PageHeader title="ESCENARIOS · ENTRANTES" />
          <Paper>
            <Row className="p-2">
              <Col lg="6">
                <SearchScenery />
              </Col>
              <Col lg="6" className="d-flex justify-content-end">
                <OrderBy />
              </Col>
            </Row>
            <Row className="p-2">
              <Col lg="3">
                <InternalSidebar items={sidebarItems} />
              </Col>
              <Col lg="9">
                <ScenerySheetGallery
                  items={sceneryGalleryItems}
                  version={version}
                />

                <SceneryList
                  version={version}
                  items={displayScenarios}
                  onDeleteItem={onDeleteItem}
                />
              </Col>
            </Row>
          </Paper>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
