import React from 'react';
import {COLORS} from "./constants";
import PropTypes from "prop-types";

import * as Icons from '../../../icons';
import NodeTitle from "./NodeTitle";
import {useNodeModal} from "./useNodeModal";

const ICON_SIZE = 16;

function NodeLayout({icon = undefined, data, color = 'blue', Modal, children}) {
  const Icon = icon ? Icons[icon] : undefined;
  const {toggleModal, modalOpen} = useNodeModal();

  return (
    <>
      {Modal && <Modal Icon={Icon} data={data} toggle={toggleModal} isOpen={modalOpen}/>}
      <div onDoubleClick={() => toggleModal()}>
        {/*Top*/}
        <div
          className="node-top"
          style={{
            border: `3px solid ${COLORS[color]}`,
            borderBottom: 'none'
          }}
        >
          <div className="node-top-content" style={{backgroundColor: COLORS[color], color: '#fff'}}>
            {icon && <Icon width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>}
            <NodeTitle data={data}/>
          </div>
        </div>

        {/*Content*/}
        <div className="node-content">
          {children}
        </div>

        {/*Bottom*/}
        <div
          className="node-bottom"
          style={{
            border: `3px solid ${COLORS[color]}`,
            borderTop: 'none'
          }}
        />
      </div>
    </>
  );
}

NodeLayout.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  toggleModal: PropTypes.func,
  data: PropTypes.object.isRequired,
  Modal: PropTypes.any,
  children: PropTypes.any,
}

export default NodeLayout;