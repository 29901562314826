import React from 'react';
import PropTypes from 'prop-types';

import './TagsInput.css';

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

function Tag({ text, onRemove }) {
  return (
    <div className="input-tag">
      <span>{text}</span>
      {onRemove && (
        <button type="button" className="input-tag-btn" onClick={onRemove}>
          x
        </button>
      )}
    </div>
  );
}

export default Tag;
