import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import * as Fields from '../../../Fields';

const optionsVoice = [
  {
    label: 'Neural',
    options: [
      { label: 'Wavenet_A', value: 'Wavenet_A' },
      { label: 'Wavenet_B', value: 'Wavenet_B' },
    ],
  },
  {
    label: 'Common',
    options: [
      { label: 'Standard_A', value: 'Standard_A' },
      { label: 'Standard_B', value: 'Standard_B' },
    ],
  },
];

Voice.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
};

function Voice({ name, label, options = [...optionsVoice] }) {
  return (
    <>
      <Label>{label}</Label>
      <Fields.Select2 name={name} options={options} />
    </>
  );
}

export default Voice;
