import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  darkBorder,
  darkText,
  lightBackground,
  lightBackgroundBlue,
  popUpBackground,
} from 'library/colors';

import {
  getFirstDayOfCurrentMonth_yyyymmdd,
  getToday_yyyymmdd,
  localDateToUTC_formatISO,
} from 'library/services/dateServices';

import RecordingReportForm from '../organisms/recordingReportForm';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';
import {
  getReportAction,
  getReportFail,
  getReportTypesAction /*  postReportRequestAction */,
  reportDownloaded,
  requestReportAction,
} from 'store/actions';
import {
  IReportsStore,
  IReport,
  IReportType,
  IError,
} from 'store/reports/reducer';
import GraphAndData from '../organisms/graphandData';
import {
  GET_REPORT_CALLS_DETAIL_EXCEL,
  GET_REPORT_CALLS_DETAIL_EXCEL_FAIL,
  GET_REPORT_EVENTS_FROM_BOT_1234,
  GET_REPORT_EVENTS_FROM_BOT_1234_FAIL,
  GET_REPORT_TOTAL_CALLS_BY_STATUS,
  GET_REPORT_TOTAL_CALLS_BY_STATUS_FAIL,
  REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL,
  REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234,
  REQUEST_REPORT_CALLS_DETAIL_EXCEL,
  REQUEST_REPORT_EVENTS_FROM_BOT_1234,
  REQUEST_REPORT_TOTAL_CALLS_BY_STATUS,
} from 'store/reports/actionTypes';

interface IRecordingReportTemplateProps {
  setShowGetReportForm: (bool: boolean) => void;
}
const RecordingReportTemplate: FC<IRecordingReportTemplateProps> = ({
  setShowGetReportForm,
}) => {
  const dispatch = useDispatch();
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);

  const {
    reportTypes,
    totalCallsByStatus,
    callsDetailExcel,
    eventsFromBot1234,
  } = useSelector<IStore, IReportsStore>(store => store.reports);

  const [formDates, setFormDates] = useState(()=>{
    const start_date = getFirstDayOfCurrentMonth_yyyymmdd()
    const end_date = getToday_yyyymmdd()
    return {start_date, end_date}
  });
  const START_DATE = localDateToUTC_formatISO(formDates.start_date + 'T00:00:00.000Z');
  const END_DATE = localDateToUTC_formatISO(formDates.end_date + 'T23:59:59.999Z');
  
  const getTotalCallsBystatus_Report = () => {
    if (reportTypes.types.length === 0 || formDates.start_date === '') return;
    dispatch(
      requestReportAction(
        owner,
        START_DATE,
        END_DATE,
        reportTypes.types[3].id,
        REQUEST_REPORT_TOTAL_CALLS_BY_STATUS,
      ),
    );
  };

  const getRecordingsDetails_Excel_report = () => {
    if (reportTypes.types.length === 0 || formDates.start_date === '') return;
    dispatch(
      requestReportAction(
        owner,
        START_DATE,
        END_DATE,
        reportTypes.types[3].id,
        REQUEST_REPORT_CALLS_DETAIL_EXCEL,
      ),
    );
  };


  const getEventsFromBot1234_report = () => {
    if (reportTypes.types.length === 0 || formDates.start_date === '') return;
    dispatch(
      requestReportAction(
        owner,
        START_DATE,
        END_DATE,
        reportTypes.types[0].id,
        REQUEST_REPORT_EVENTS_FROM_BOT_1234,
      ),
    );
  };

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton'
    ) {
      return setShowGetReportForm(false);
    }
  };

  useEffect(() => {
    if (reportTypes.types.length === 0) dispatch(getReportTypesAction(owner));
    return () => {
      dispatch(reportDownloaded(REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL))
      dispatch(reportDownloaded(REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234))
    }
  }, []);

  //
  // total call status JSON
  //
  // request the and receives the id of the report
  useEffect(() => {
    getTotalCallsBystatus_Report();
  }, [reportTypes.types]);

  //initial request
  useEffect(() => {
    if (totalCallsByStatus.reportId === '') return;
    dispatch(
      getReportAction(
        owner,
        totalCallsByStatus.reportId,
        GET_REPORT_TOTAL_CALLS_BY_STATUS,
      ),
    );
  }, [totalCallsByStatus.reportId]);

  //secondary request
  useEffect(() => {
    if (
      totalCallsByStatus.reportId === '' ||
      (totalCallsByStatus.isLoading === false && totalCallsByStatus.count === 0)
    )
      return;

    if (totalCallsByStatus.count > 5) {
      const error: IError = {
        message: 'Something was wrong requesting the report!',
        details: ``,
      };
      dispatch(getReportFail(error, GET_REPORT_TOTAL_CALLS_BY_STATUS_FAIL));
      return;
    }

    const t = setTimeout(() => {
      dispatch(
        getReportAction(
          owner,
          totalCallsByStatus.reportId,
          GET_REPORT_TOTAL_CALLS_BY_STATUS,
        ),
      );
    }, 2500);
    return () => clearTimeout(t);
  }, [totalCallsByStatus.count]);

  //
  // Details excel
  //
  // initial request
  useEffect(() => {
    if (callsDetailExcel.reportId === '') return;
    dispatch(
      getReportAction(
        owner,
        callsDetailExcel.reportId,
        GET_REPORT_CALLS_DETAIL_EXCEL,
      ),
    );
  }, [callsDetailExcel.reportId]);

  //secondary request
  useEffect(() => {
    if (
      callsDetailExcel.reportId === '' ||
      (callsDetailExcel.isLoading === false && callsDetailExcel.count === 0)
    )
      return;

    if (callsDetailExcel.count > 5) {
      const error: IError = {
        message: 'Something was wrong requesting the report!',
        details: ``,
      };
      dispatch(getReportFail(error, GET_REPORT_CALLS_DETAIL_EXCEL_FAIL));
      return;
    }

    const t = setTimeout(() => {
      dispatch(
        getReportAction(
          owner,
          callsDetailExcel.reportId,
          GET_REPORT_CALLS_DETAIL_EXCEL,
        ),
      );
    }, 2500);
    return () => clearTimeout(t);
  }, [callsDetailExcel.count]);

  //
  // bot 1234
  //
  // initial request
  useEffect(() => {
    if (eventsFromBot1234.reportId === '') return;
    dispatch(
      getReportAction(
        owner,
        eventsFromBot1234.reportId,
        GET_REPORT_EVENTS_FROM_BOT_1234,
      ),
    );
  }, [eventsFromBot1234.reportId]);

  //secondary request
  useEffect(() => {
    if (
      eventsFromBot1234.reportId === '' ||
      (eventsFromBot1234.isLoading === false && eventsFromBot1234.count === 0)
    )
      return;

    if (eventsFromBot1234.count > 5) {
      const error: IError = {
        message: 'Something was wrong requesting the report!',
        details: ``,
      };
      dispatch(getReportFail(error, GET_REPORT_EVENTS_FROM_BOT_1234_FAIL));
      return;
    }

    const t = setTimeout(() => {
      dispatch(
        getReportAction(
          owner,
          eventsFromBot1234.reportId,
          GET_REPORT_EVENTS_FROM_BOT_1234,
        ),
      );
    }, 2500);
    return () => clearTimeout(t);
  }, [eventsFromBot1234.count]);

  return (
    <PopUpContainer id={'popupContainer'} onClick={e => handleExit(e)}>
      <Cont>
        <Exit id="exitButton">x</Exit>
        <H1>
          <Span>Recording &nbsp; Report</Span>
        </H1>
        <SubTitle>
          {' '}
          from &nbsp; <b>{formDates.start_date}</b> &nbsp; to &nbsp;{' '}
          <b>{formDates.end_date}</b>
        </SubTitle>
        <FlexCont>
          <GraphAndData
            error={totalCallsByStatus.error}
            data={totalCallsByStatus.reportData?.content_data}
            isLoading={totalCallsByStatus.isLoading}
            handleRefresh={getTotalCallsBystatus_Report}
          />

          <FormCont>
            <RecordingReportForm
              formDates={formDates}
              setFormDates={setFormDates}
              callsDetailExcel={callsDetailExcel}
              eventsFromBot1234={eventsFromBot1234}
              handleViewData={getTotalCallsBystatus_Report}
              requestCallDetails={getRecordingsDetails_Excel_report}
              requestEventsFromBot1234={getEventsFromBot1234_report}
            />
          </FormCont>
        </FlexCont>
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const Cont = styled.div`
  width: 800px;
  height: 500px;
  margin-top: 150px;
  color: black;
  position: relative;
  padding: 30px 20px;
  border-radius: 20px;
  border: 1px solid white;
  background-color: ${lightBackgroundBlue};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  padding: 10px;
  line-height: 10px;
  font-size: 1.2rem;
  cursor: pointer;
`;
const H1 = styled.h1`
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  margin: 0px auto 10px;
  color: ${darkText};
`;
const Span = styled.span`
  letter-spacing: px;
  padding: 4px 25px;
  border-radius: 30px;
  background-color: ${lightBackground};
`;
const SubTitle = styled.div`
  text-align: center;
  font-size: 0.8rem;
`;
const FormCont = styled.div`
  flex-grow: 1;
  height: 400px;
  max-width: 200px;
  margin-left: 20px;
  padding-left: 10px;
  border-left: 1px solid ${darkBorder};
`;
const FlexCont = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default RecordingReportTemplate;
