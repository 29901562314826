import { darkBorder, lightBackgroundBlue, popUpBackground, primary } from 'library/colors';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import SelectableColumn from '../organisms/selectableColumn';
import { ICreateDatabase } from 'library/interfaces/databasesInterfaces';
import { postCreateDatabase } from 'store/databases/asyncActions';
import { useDispatch } from 'react-redux';

interface ICreateDatabasePops {
  setShowCreateDatabaseForm: (show: boolean) => void
}
const CreateDatabaseTemplate = ({ setShowCreateDatabaseForm }: ICreateDatabasePops) => {

  const dispatch = useDispatch()

  const dbNameInputRef = useRef<HTMLInputElement>(null)
  const columnNameInputRef = useRef<HTMLInputElement>(null)
  const columnTypeSelectRef = useRef<HTMLSelectElement>(null)

  const [columnIndex, setColumnIndex] = useState<{ key: string, type: string, isSelected: boolean }[]>([])
  const [idColumns, setIdColumns] = useState<{ key: string, type: string, isSelected: boolean }[]>([])


  const handleAddColumnButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    if (!columnNameInputRef?.current || !columnTypeSelectRef?.current) return
    const key = columnNameInputRef.current.value
    const type = columnTypeSelectRef.current.value

    if (key === 'id') return columnNameInputRef.current.value = ''

    setColumnIndex([...columnIndex, { key, type, isSelected: false }])
    setIdColumns([...idColumns, { key, type, isSelected: false }])
    columnNameInputRef.current.value = ''
  }

  const handleSelectColumnIndex = (index: number) => {

    //case select multiple
    setColumnIndex(prev => prev.map((column, ind) => {
      if (index === ind) return { ...column, isSelected: !column.isSelected }
      return column
    }))

    // case select only one
    // setColumnIndex(prev => prev.map((column, ind) => {
    //   if (index === ind) return { ...column, isSelected: true }
    //   return { ...column, isSelected: false }
    // }))
  }

  const handleSelectColumnId = (index: number) => {

    //case select multiple
    setIdColumns(prev => prev.map((column, ind) => {
      if (index === ind) return { ...column, isSelected: !column.isSelected }
      return column
    }))

    // case select only one
    // setIdColumns(prev => prev.map((column, ind) => {
    //   if (index === ind) return { ...column, isSelected: true }
    //   return { ...column, isSelected: false }
    // }))
  }

  const handleDeleteColumn = (index: number) => {
    setColumnIndex(prev => prev.filter((column, ind) => ind !== index))
    setIdColumns(prev => prev.filter((column, ind) => ind !== index))
  }

  const handleSaveDatabase = () => {
    if (!dbNameInputRef?.current) return
    const indexList = columnIndex.filter(col => col.isSelected).map(col => {
      return {
        key: col.key,
        name: col.key,
        type: col.type === 'string' ? 'S' : 'N'
      }
    })
    const schema = columnIndex.map((col, index) => {
      return {
        key: col.key,
        name: col.key,
        type: col.type === 'string' ? 'S' : 'N',
        order: index + 1
      }
    })
    const id = idColumns.filter(col => col.isSelected).map(col => {
      return {
        key: col.key,
        name: col.key,
        type: col.type === 'string' ? 'S' : 'N'
      }
    })
    if (indexList.length === 0 || schema.length === 0 || id.length === 0) return

    const newDb: ICreateDatabase = {
      name: dbNameInputRef.current.value,
      indexList,
      schema,
      aux: id
    }
    setShowCreateDatabaseForm(false)
    dispatch(postCreateDatabase(newDb))
  }

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton' ||
      event.target.id === 'cancelButton'
    ) {
      return setShowCreateDatabaseForm(false);
    }
  };
  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton" onClick={e => handleExit(e)}>
          x
        </Exit>
        <HeaderCont>
          Nueva base de datos
        </HeaderCont>
        <BodyCont>
          <FormGroupTitle>Información general</FormGroupTitle>
          <InputGroup>
            <label>Nombre de la base de datos</label>
            <input type="text" name="name" ref={dbNameInputRef} />
          </InputGroup>
          {/* <InputGroup>1-. En este punto solo se crea la base de datos, los datos se cargan posteriormente.</InputGroup>
          <InputGroup>2-. Crear la base de datos toma algunos minutos. Nosotros le indicaremos cuanto tomará el proceso.</InputGroup>
          <InputGroup>3-. Debe agregar las columnas con el mismo nombre que tienen en su archivo XLSX o CSV, de lo contrario no se mostrarán los datos (puede ser editado más adelante).</InputGroup>
          <InputGroup>4-. Es obligatorio seleccionar las columnas indices en este punto (columas de busqueda). Esta selección no podrá ser editada en el futuro.</InputGroup> */}
          <FormGroupTitle>Columnas</FormGroupTitle>
          <InputGroup>
            <label>Nombre de la columna</label>
            <input type="text" name="columnName" ref={columnNameInputRef} />
          </InputGroup>
          <GridCont>
            <InputGroup>
              <label htmlFor='type'>Tipo de dato</label>
              <select name="type" id="type" ref={columnTypeSelectRef}>
                <option value="string">Texto</option>
                <option value="number">Numérico</option>
              </select>
            </InputGroup>
            <InputGroup>
              <label></label>
              <AddColumnButton onClick={e => handleAddColumnButtonClick(e)}>+ Agregar Columna</AddColumnButton>
            </InputGroup>
          </GridCont>
        </BodyCont>

        <BodyCont>
          {/* <FormGroupTitle></FormGroupTitle> */}
          {/* <InputGroup>
            <label>Nombre de la columna</label>
            <input type="text" name="columnName" ref={columnNameInputRef} />
          </InputGroup>
          <GridCont>
            <InputGroup>
              <label htmlFor='type'>Tipo de dato</label>
              <select name="type" id="type" ref={columnTypeSelectRef}>
                <option value="string">Texto</option>
                <option value="number">Numérico</option>
              </select>
            </InputGroup>
            <InputGroup>
              <label></label>
              <AddColumnButton onClick={e => handleAddColumnButtonClick(e)}>+ Agregar Columna</AddColumnButton>
            </InputGroup>
          </GridCont> */}
          <InputGroup>
            <label >Seleccionar id (Si es más de una se concatena)</label>
            <ColumnsCont>
              {idColumns.map((column, index) => {
                return (
                  <SelectableColumn key={index} column={column} index={index} onSelectColumn={handleSelectColumnId} onDeleteColumn={handleDeleteColumn} />
                )
              })}

            </ColumnsCont>
          </InputGroup>
          <InputGroup>
            <label >Seleccionar Índices de busqueda</label>
            <ColumnsCont>
              {columnIndex.map((column, index) => {
                return (
                  <SelectableColumn key={index} column={column} index={index} onSelectColumn={handleSelectColumnIndex} onDeleteColumn={handleDeleteColumn} />
                )
              })}

            </ColumnsCont>
          </InputGroup>
        </BodyCont>
        <ButtonsCont>
          <CancelButton id='cancelButton' onClick={e => handleExit(e)} isDisabled={false}>Cancelar</CancelButton>
          <SaveButton isDisabled={false} onClick={() => handleSaveDatabase()}>Crear</SaveButton>
        </ButtonsCont>

      </FormContainer>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.form`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const HeaderCont = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: center;
  grid-column: 1 / -1;
  background-color: ${lightBackgroundBlue};
`;
const FormGroupTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 10px;
`;
const BodyCont = styled.div`
  border-radius: 10px;
  padding: 10px 20px;
  background-color: ${lightBackgroundBlue};
  margin-bottom: 20px;
`;
const InputGroup = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  display: grid;
  margin-bottom: 10px;

  & > input,
  select {
    width: 100%;
    font-size: 1rem;
    padding: 5px 0;
    border: none;
    background-color: white;
  }
`;
const GridCont = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 10px;
`;
const AddColumnButton = styled.button`
  /* grid-template-rows: ; */
  border: none;
  border-radius: 50px;
  color: white;
  padding: 0;
  height: 25px;
  margin-top: 20px;
  background-color: ${primary};
`;
const ColumnsCont = styled.div`
  height: 110px;
  padding: 5px 10px;
  padding-right: 0;
  border-radius: 10px;
  background-color: white;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const ButtonsCont = styled.div`
height: 60px;
  padding: 0px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  grid-column: 1 / -1;
  background-color: ${lightBackgroundBlue};
`;
const SaveButton = styled.div<ISaveButton>`
  color: white;
  font-size: 1rem;
  font-weight: 700;
  width: 130px;
  border-radius: 50px;
  margin-left: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px solid ${props => props.isDisabled ? darkBorder : primary};
  background-color: ${props => props.isDisabled ? darkBorder : primary};
`;
const CancelButton = styled(SaveButton)`
  color: black;
  font-weight: 300;
  background-color: transparent;
`;
interface ISaveButton {
  isDisabled: boolean;
}
export default CreateDatabaseTemplate;