import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface ILoadingPointsProps {}
const LoadingPoints: FC<ILoadingPointsProps> = ({}) => {
  return (
    <Cont>
      <Point animationDelay={.3}>.</Point>
      <Point animationDelay={.6}>.</Point>
      <Point animationDelay={.9}>.</Point>
    </Cont>
  );
};
const Cont = styled.div`
  font-weight: 500;
  width: 15px;
  display: inline-flex;
  justify-content: space-between;
`;

const PointAnimation = keyframes`
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(-7px);
  }
  50%{
    transform: translateY(0px);
  }
  100%{
    transform: translateY(0px);
  }
`;
const Point = styled.span<IPoint>`
  //animation: name duration timing-function delay iteration-count direction fill-mode;
  animation: ${PointAnimation} 2s
    ${props => props.animationDelay && props.animationDelay + 's'} infinite;
`;
interface IPoint {
  animationDelay: number;
}
export default LoadingPoints;
