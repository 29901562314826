import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import * as Fields from '../../Fields';

const validationSchema = Yup.object({
  from: Yup.string(),
  to: Yup.string(),
  messageText: Yup.string(),
});

Edit.propTypes = {
  data: PropTypes.object.isRequired,
  toggleInEdit: PropTypes.func.isRequired,
};

function Edit({ data, toggleInEdit }) {
  const onSubmit = values => {
    data.from = values.from;
    data.to = values.to || '{{phone}}';
    data.messageText = values.messageText;
    toggleInEdit();
  };

  return (
    <Formik
      initialValues={{
        from: data?.from || '',
        to: data?.to || '{{phone}}',
        messageText: data?.messageText || '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {
        /* formik */ () => (
          <Form>
            <Row>
              <Col lg={12}>
                <label>From</label>
                <Fields.Select2 name="from" options={[]} />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <label>To</label>
                <Fields.TextInput name="to" disabled />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Label>Message Text</Label>
                <Fields.TextArea
                  name="messageText"
                  placeholder="Message Text ..."
                />
              </Col>
            </Row>

            <div className="mt-3">
              <button type="submit" className="btn btn-primary btn-sm">
                Save
              </button>
              <button className="btn btn-default btn-sm" onClick={toggleInEdit}>
                Cancel
              </button>
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

export default Edit;
