import React, { FC } from 'react';
import styled from 'styled-components';
import InfoCont from '../atoms/infoCont';
import Title from '../atoms/title';
import Text from '../atoms/text';

interface IPoliciaAutopistasProps { }
const PoliciaAutopistas: FC<IPoliciaAutopistasProps> = ({ }) => {
  return (
    <InfoCont>
      <Title>Policía Autopistas</Title>
      <Text>Para atender su situación es necesario que se comunique con la división de autopistas de la Policía, ¿en dónde se encuentras? Identificaré el cuartel más cercano para transferir su llamada:</Text>
      <Text danger>Recuerda antes de transferir la llamada, proveerle el número de teléfono del cuartel que corresponda.</Text>
      <ul>
        <li>Arecibo: 787-986-7889</li>
        <li>Buchanan (San Juan/Guaynabo, Bayamón, Cataño): 787-945-5081</li>
        <li>Carolina: 787-276-0047</li>
        <li>Ceiba: 787-855-1212</li>
        <li>Caguas: 787-747-1771</li>
        <li>Salinas: 787-824-3165</li>
      </ul>
    </InfoCont>
  );
};
export default PoliciaAutopistas;