import { primary } from 'library/colors';
import { ISMSCampaignActions } from 'library/interfaces/smsCampaignInterface';
import React, { FC } from 'react';
import { campaignAction } from 'store/outboundCampaigns/voiceCampaigns/actionTypes';
import { ICampaignStatus } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import styled from 'styled-components';

interface IStateMenuOptionProps {
  label: string;
  action: campaignAction | ISMSCampaignActions;
  onActionClick: (action: campaignAction | ISMSCampaignActions) => void;
}
const StateMenuOption: FC<IStateMenuOptionProps> = ({ label, action, onActionClick }) => {
  const handleButtonClick = () => {
    onActionClick(action);
  };
  return <Button onClick={() => handleButtonClick()}>{label}</Button>;
};
const Button = styled.button`
  text-align: left;
  padding: 2px 15px;
  border-color: transparent;
  background-color: transparent;

  &:hover{
    color: ${primary};
    transform: scale(1.01);
  }
`;
export default StateMenuOption;
