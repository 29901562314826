import React, { FC } from 'react';
import styled from 'styled-components';

import SubTitle from '../atoms/subTitle';
import HorizontalBarGraph from '../atoms/horizontalBarGraph';

import { secondary } from 'library/colors';
import { useTranslation } from 'react-i18next';

interface ILostCallsProps {
  lostCalls: number;
  totalCalls: number;
}
const LostCalls: FC<ILostCallsProps> = ({ lostCalls = 0, totalCalls = 0 }) => {
  const {t} = useTranslation('liveBoard')
  return (
    <Div>
      <SubTitle>{t('abandonedCalls')}</SubTitle>
      <HorizontalBarGraph lostCall={lostCalls} totalCalls={totalCalls} />
    </Div>
  );
};
const Div = styled.div`
  flex-grow: 1;
  max-width: 270px;
  padding-bottom: 20px;
  border-right: 2px solid ${secondary};
`;
export default LostCalls;
