import React from 'react';
import { Col, Row } from 'reactstrap';
import Paper from '../foundation/Paper';

import './ScenerySheetGallery.css';
import PropTypes from 'prop-types';
import SceneryItem from './SceneryItem';
import SceneryItemAdd from './SceneryItemAdd';

function ScenerySheetGallery({ items, version }) {
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        padding: 24,
      }}
      bg="#eff5f8ff"
    >
      <Row>
        <Col lg="12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <strong>Galería de Plantillas</strong>
        </Col>
      </Row>
      <Row>
        <div className="scenery-gallery-sheet-items">
          {items.map(({ id, text, Icon }) => (
            <SceneryItem key={id} text={text} Icon={Icon} />
          ))}

          <SceneryItemAdd version={version}/>
        </div>
      </Row>
    </Paper>
  );
}

ScenerySheetGallery.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ScenerySheetGallery;
