import { DataVerification } from 'icons';
import { blueGraph, darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { IDatabase } from 'library/interfaces/databasesInterfaces';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteDatabase } from 'store/databases/asyncActions';
import styled from 'styled-components';
import StateCounter from '../atoms/stateCount';

interface IDatabaseProps {
  database: IDatabase
}
const Database: FC<IDatabaseProps> = ({ database }) => {

  const history = useHistory()
  const dispatch = useAppDispatch()

  const [showMenu, setShowMenu] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)

  const handleDatabaseClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const clickedElement = event.target as HTMLDivElement;
    const closestElememt = clickedElement.closest('[id]');
    const parentId = closestElememt?.id;

    if (parentId !== 'dbContainer' || !isAvailable) return
    history.push(`/database/${database.id}`)
  }

  const handleShowMenu = () => {
    if (!isAvailable) return
    setShowMenu(!showMenu)
  }

  const handleDeleteDatabase = (id: string) => {
    setShowMenu(false)
    dispatch(deleteDatabase(id))
  }
  return (
    <Containter id='dbContainer' onClick={e => handleDatabaseClick(e)}>
      <Cont>
        <IconCont left="-50px">
          <DataVerification width={40} color={primary} />
        </IconCont>
        <Title>{database.name}</Title>
        <Date>Indices:<span> {database.indexList.map((item, index, arr) => {
          const simbol = index === arr.length - 1 ? '.' : ', '
          return item.name + simbol
        })}</span></Date>
        {/* <Date>Fecha de creación: <span>{dateFormat(database.createdAt)}</span></Date>
        <Date>Última actualización: <span>{dateFormat(database.updatedAt)}</span></Date> */}
        <Date>Columnas:<span> {database.schema.map((item, index, arr) => {
          const simbol = index === arr.length - 1 ? '.' : ', '
          return item.name + simbol
        })}</span></Date>
      </Cont>
      <StateAndButtonCont>
        <StateCounter createdAt={database.createdAt} setIsAvailable={setIsAvailable} />
        <MenuButton id='menu' onClick={() => handleShowMenu()}>...</MenuButton>
        {showMenu && <Menu id='menuButton'>
          <Button onClick={() => handleDeleteDatabase(database.id)}>Eliminar</Button>
        </Menu>}
      </StateAndButtonCont>
    </Containter>
  );
};
const Containter = styled.div`
  border-radius: 10px;
  padding: 20px 50px 20px 90px;
  color: black;
  max-width: 1100px;
  margin: 0 auto 20px;
  border: 1px solid ${darkBorder};
  display: flex;
  cursor: pointer;
`;
const Cont = styled.div`
  position: relative;
  flex-grow: 1;
`;
const IconCont = styled.div<IIconCont>`
  position: absolute;
  left: ${props => (props.left ? props.left : '-40px')};
  top: 5px;
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
`;
const Date = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  margin-bottom: 5px;
  color: ${primary};
  line-break: strict;
  display: flex;

  & > span{
    color: black;
    padding-left: 5px;
  }

`;
const StateAndButtonCont = styled.div`
  display: flex;
  position: relative;
`;

const MenuButton = styled.div`
  width: 40px;
  height: 30px;
  margin-left: 5px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 35%;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;
const Menu = styled.div`
  position: absolute;
  right: 0;
  top: 35px;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  border-radius: 5px;
  background-color: ${lightBackgroundBlue};
  border: 1px solid ${darkBorder};
  box-shadow: 0 0px 5px ${primary};
`;
const Button = styled.button`
  text-align: left;
  padding: 2px 15px;
  border-color: transparent;
  background-color: transparent;

  &:hover{
    color: ${primary};
    transform: scale(1.01);
  }
`;
interface IIconCont {
  left?: string;
}
export default Database;