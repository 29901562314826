import { danger } from 'library/colors';
import { ICreateDatabaseSchema, IDatabaseRow } from 'library/interfaces/databasesInterfaces';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IDatabaseColumnProps {
  index: number
  rowObj: IDatabaseRow
  handleUpdateRow: (key: string, value: string | number) => void
  // handleDeleteColumn: (index: number) => void
}
const RowKey: FC<IDatabaseColumnProps> = ({ rowObj, index, handleUpdateRow }) => {
  const key = Object.keys(rowObj)[0]

  const handeColumnChange = (key: 'key' | 'type', value: string) => {
    // const editedColumn = structuredClone(column)
    // editedColumn[key] = value
    // if (key === 'key') {
    //   editedColumn.name = value
    // }
    // handleUpdateColumn(index, editedColumn)
  }
  return (
    <Cont>
      {/* <DelIcon onClick={() => console.log(index)}>
        <i className='bx bxs-trash'></i>
      </DelIcon> */}
      <InputGroup>
        <label htmlFor="">{key}</label>
        <input
          type="text"
          value={rowObj[key] || ''}
          onChange={e => handleUpdateRow(key, e.target.value)}
        />
      </InputGroup>
    </Cont>
  );
};
const Cont = styled.div`
margin-bottom: 10px;
padding: 5px 10px;
border-radius: 10px;
  background-color: white;
  display: flex;
  position: relative;
`;
const InputGroup = styled.div`
width: 100%;
  font-size: 0.7rem;
  font-weight: 500;
  display: grid;
  margin-bottom: 10px;

  & > input,
  select {
    font-size: 1rem;
    padding: 5px 0;
    border: none;
    background-color: white;
  }
  & > input{
    padding-right: 10px;
  }
`;
const DelIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 1rem;
  cursor: pointer;

  &:hover{
    color: ${danger}
  }
`;
export default RowKey;