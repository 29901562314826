import { Node, Position } from 'reactflow';
import endScenary from '../customNodes/endScenary';
import CircleNode from '../customNodes/circleNode';
import InteractiveMenu from '../customNodes/interactiveMenu';

export type CustomNode = Node<INodeData, CustomNodeTypes>;

export type CustomNodeTypes =
  | 'sceneryStart'
  | 'sceneryEnd'
  | 'message'
  | 'interactiveMenu'
  | 'conditional'
  | 'function'
  | 'sessionVariables'
  | 'transfer'
  | 'sendSMS'
  | 'database'
  | 'event'
  | 'GPT';

export type CustomNodeObject<T> = Record<CustomNodeTypes, T>;

const nodeTypes: CustomNodeObject<({ data }: any) => JSX.Element> = {
  sceneryStart: CircleNode,
  sceneryEnd: CircleNode,
  interactiveMenu: InteractiveMenu,
  message: InteractiveMenu,
  conditional: InteractiveMenu,
  function: InteractiveMenu,
  sessionVariables: InteractiveMenu,
  transfer: InteractiveMenu,
  sendSMS: InteractiveMenu,
  database: InteractiveMenu,
  event: InteractiveMenu,
  GPT: InteractiveMenu,
};

export interface INodeData {
  title: string;
  label: string;
  backgroundColor: string;
  target?: IHandleData & { type: 'target' };
  sources?: (IHandleData & { type: 'source' })[];

  //
  // text to speech legacy, now message
  //
  synthLanguage?: string;
  voice?: string;
  textV2?: ITextV2;
  // new property
  contentType?: 'Plain Text' | 'XML' | 'SSML';

  //
  // interactive Menu legacy
  //
  outputType?: 'voiceSyntesis';
  failTexts?: string;
  responseRoutes?: {
    id: string;
    port: string;
    responseKey: string;
    possibleResponses: string[];
    intentId: string;
    text: string;
  }[];
  //
  //text, and contentType propeties come from above, text to speech and they are necessary
  //text?: string
  //contentType?: 'Plain Text' | 'XML' | 'SSML';
  //
  // new properties
  repeatNumber?: '0' | '1' | '2' | '3' | '4';
  context?: string;

  //
  // conditional ===> data verification
  //
  conditionalRoutes?: {
    id: string;
    port: string;
    variable: string;
    condition: string;
    value: string;
  }[];

  //
  // function request
  //
  function?: {
    id: string;
    name: string;
    callback: boolean;
  };
  waitingMessage?: string;

  //
  //
  // session variables
  sessionVariables?: {
    key: string;
    value: string;
  }[];
  //
  // transfer
  //
  transfer?: string;

  // databases
  database?: {
    key: string;
    tableId: string;
  };

  // event
  event?: {
    name: string;
    data: string;
  };

  // gpt
  model?: string;
  systemContext?: string;
  functions?: {
    id: string;
    name: string;
    description: string;
    parameters: string;
  }[];
}

export interface IHandleData {
  id: string;
  type: 'source' | 'target';
  position: Position;
  color: string;
  label?: string;
  value?: string;
}

export interface ITextV2 {
  text: string;
  xml: string;
  ssml: string;
}
export const nodeData: CustomNodeObject<INodeData> = {
  sceneryStart: {
    title: 'Comienzo de Escenario',
    label: 'Scenery Start',
    backgroundColor: '#7c9904',
    sources: [
      {
        id: 'route_start',
        color: '#85ce5f',
        type: 'source',
        position: Position.Right,
      },
    ],
  },
  sceneryEnd: {
    title: 'Fin de Escenario',
    label: 'Scenery End',
    backgroundColor: '#7c909c',
    target: {
      id: 'route_end',
      color: '#7c909c',
      type: 'target',
      position: Position.Left,
    },
  },
  message: {
    title: 'Mensaje',
    label: 'Mensaje',
    synthLanguage: '',
    voice: '',
    textV2: {
      text: '',
      xml: '',
      ssml: '',
    },
    contentType: 'Plain Text',
    backgroundColor: '#4cb3ed',
    target: {
      id: 'route_in',
      color: '#4cb3ed',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: 'route_out',
        color: '#4cb3ed',
        type: 'source',
        position: Position.Right,
        label: 'Out',
      },
    ],
  },
  interactiveMenu: {
    title: 'Menú Interactivo',
    label: '',
    outputType: 'voiceSyntesis',
    textV2: {
      text: '',
      xml: '',
      ssml: '',
    },
    failTexts: '',
    responseRoutes: [],
    // this properties are necessaries
    synthLanguage: '',
    voice: '',
    contentType: 'Plain Text',
    //new properties
    repeatNumber: '0',
    context: '',
    backgroundColor: '#7c9904',
    target: {
      id: 'route_in',
      color: '#85ce5f',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: 'route_fail',
        color: '#d78480',
        type: 'source',
        position: Position.Right,
        label: 'Fallida',
        value: 'route_fail',
      },
    ],
  },
  conditional: {
    title: 'Condiciones',
    label: '',
    conditionalRoutes: [],
    backgroundColor: '#654aed',
    target: {
      id: 'route_in',
      color: '#4cb3ed',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: 'route_fail',
        color: '#d78480',
        type: 'source',
        position: Position.Right,
        label: 'Fallida',
        value: '0',
      },
    ],
  },
  function: {
    title: 'Función',
    label: '',
    backgroundColor: '#d9d330',
    target: {
      id: 'route_in',
      color: '#d9d330',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: 'route_fail',
        color: '#d78480',
        type: 'source',
        position: Position.Right,
        label: 'Fallida',
        value: 'route_fail',
      },
      {
        id: 'route_success',
        color: '#85ce5f',
        type: 'source',
        position: Position.Right,
        label: 'Success',
        value: 'route_success',
      },
    ],
  },
  sessionVariables: {
    title: 'Variables de sesión',
    label: '',
    sessionVariables: [
      {
        key: '',
        value: '',
      },
    ],
    backgroundColor: '#4cb3ed',
    target: {
      id: 'route_in',
      color: '#4cb3ed',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: 'route_out',
        color: '#4cb3ed',
        type: 'source',
        position: Position.Right,
        label: 'Out',
      },
    ],
  },
  transfer: {
    title: 'Transferencia',
    label: '',
    transfer: '',
    backgroundColor: '#9a0495',
    target: {
      id: 'route_in',
      color: '#9a0495',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: 'route_out',
        color: '#9a0495',
        type: 'source',
        position: Position.Right,
        label: 'Out',
      },
    ],
  },
  sendSMS: {
    title: 'Send SMS',
    label: '',
    backgroundColor: '#4cb3ed',
    target: {
      id: '0',
      color: '#4cb3ed',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: '0',
        color: '#d78480',
        type: 'source',
        position: Position.Right,
        label: 'Fallida',
        value: '0',
      },
      {
        id: '1',
        color: '#85ce5f',
        type: 'source',
        position: Position.Right,
        label: 'Enviado',
      },
    ],
  },
  database: {
    title: 'Base de Datos',
    label: '',
    database: {
      key: '',
      tableId: '',
    },
    backgroundColor: '#9a0001',
    target: {
      id: 'route_in',
      color: '#9a0001',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: 'route_out',
        color: '#9a0001',
        type: 'source',
        position: Position.Right,
        label: 'Out',
      },
    ],
  },
  event: {
    title: 'Evento',
    label: '',
    event: {
      name: '',
      data: '',
    },
    backgroundColor: '#ff9901',
    target: {
      id: 'route_in',
      color: '#9a0001',
      type: 'target',
      position: Position.Left,
    },
    sources: [
      {
        id: 'route_out',
        color: '#ff9901',
        type: 'source',
        position: Position.Right,
        label: 'Out',
      },
    ],
  },
  GPT: {
    title: 'GTP',
    label: '',
    // gpt
    model: 'gpt-3.5-turbo-0613',
    systemContext: '',
    functions: [],
    //
    backgroundColor: '#00A67E',
    target: {
      id: 'route_in',
      color: '#00A67E',
      type: 'target',
      position: Position.Left,
    },
    sources: [],
  },
};
export default nodeTypes;
