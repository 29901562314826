import React from 'react';
import FormContainer from '../FormContainer';

import * as Fields from '../Fields';
import { Row, Col, Button } from 'reactstrap';

function FormRequest() {
  return (
    <>
      <FormContainer title="Select from template">
        <label>Select the template</label>
        <Fields.Select2 name="requestTemplate" options={[]} />
      </FormContainer>

      <FormContainer title="General settings">
        <Row>
          <Col md={4}>
            <label>Method</label>
            <Fields.Select2
              name="method"
              options={[
                { label: 'GET', value: 'GET' },
                { label: 'POST', value: 'POST' },
                { label: 'HEAD', value: 'HEAD' },
                { label: 'DELETE', value: 'DELETE' },
                { label: 'PUT', value: 'PUT' },
                { label: 'PATCH', value: 'PATCH' },
              ]}
            />
          </Col>
          <Col md={8}>
            <label>URL:port</label>
            <Fields.TextInput name="url" placeholder="URL:port" />
          </Col>
        </Row>
      </FormContainer>

      <FormContainer title="Request headers">
        <Row>
          <Col md={5}>
            <Fields.Select2
              name={`header`}
              options={[
                { label: 'Accept', value: 'Accept' },
                { label: 'Accept-Charset', value: 'Accept-Charset' },
                { label: 'Accept-Encoding', value: 'Accept-Encoding' },
                { label: 'Accept-Language', value: 'Accept-Language' },
                { label: 'Authorization', value: 'Authorization' },
                { label: 'Content-Encoding', value: 'Content-Encoding' },
                { label: 'Content-Language', value: 'Content-Language' },
                { label: 'Content-Length', value: 'Content-Length' },
              ]}
            />
          </Col>
          <Col md={1} style={{ display: 'flex', alignItems: 'center' }}>
            =
          </Col>
          <Col md={5}>
            <Fields.TextInput name="headerValue" placeholder="value" />
          </Col>
          <Col md={1}>
            <Button>Del</Button>
          </Col>
        </Row>
      </FormContainer>

      <FormContainer title="Query parameters">
        <Row>
          <Col md={5}>
            <Fields.TextInput name="queryParameter" placeholder="Parameter" />
          </Col>
          <Col md={1} style={{ display: 'flex', alignItems: 'center' }}>
            =
          </Col>
          <Col md={5}>
            <Fields.TextInput name="queryParameterValue" placeholder="Value" />
          </Col>
          <Col md={1}>
            <Button>Del</Button>
          </Col>
        </Row>
      </FormContainer>

      <FormContainer title="Request body">
        <Row>
          <Col md={5}>
            <Fields.TextInput name="field" placeholder="Parameter" />
          </Col>
          <Col md={1} style={{ display: 'flex', alignItems: 'center' }}>
            =
          </Col>
          <Col md={5}>
            <Fields.TextInput name="fieldValue" placeholder="Value" />
          </Col>
          <Col md={1}>
            <Button>Del</Button>
          </Col>
        </Row>
      </FormContainer>
    </>
  );
}

export default FormRequest;
