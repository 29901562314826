import React, { FC } from "react";
import styled from "styled-components";

interface ITimeCounterAndMinMaxProps{
    time_average?: string;
    time_min_time?: string;
    time_max_time?: string;
}
const TimeCounterAndMinMax: FC<ITimeCounterAndMinMaxProps> = ({time_average = '00:00', time_min_time, time_max_time}) =>{
    return(
        <Container>
            <TimeCount>{time_average}</TimeCount>
            <MinMax>
                <div>Min {time_min_time}</div>
                <div>Max {time_max_time}</div>
            </MinMax>
        </Container>
    )
}
const Container = styled.div`
display: flex;
align-items: center;
transform: translate(-3px);
`
const TimeCount = styled.span`
    font-size: 2.3rem;
    font-weight: 600;
`
const MinMax = styled.div`
margin-left: 6px;
line-height: 15px; 
font-size: .7rem;
`
export default TimeCounterAndMinMax;