import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import Container from '../atoms/container';
import {
  IQueueCallEventToApp,
  IQueuedCall,
  QueuedCallEventsType,
} from 'library/interfaces/liveQueuedCallsInterfaces';
import { darkBorder, mediumBackground, primary } from 'library/colors';
import { convertSecondsNumberToTimeString } from 'library/services/summarizeService';
import { sysNotification } from 'library/notifications/notifications';

interface IQueuedCallDetailProps {
  call: IQueuedCall<IQueueCallEventToApp>;
}
const QueuedCallDetail: FC<IQueuedCallDetailProps> = ({ call }) => {

  const eventsToRender = [...call.events];

  const state = {
    ENTER_QUEUE: 'En cola',
    noanswer: 'No atendió',
    answer: 'En llamada',
  };
  // useEffect(()=>{
  //   console.log(call.events)
  //   if(call.events.length > 0 && call.events[call.events.length - 1].state !== 'noanswer') return
  //   // sysNotification(call.events[call.events.length - 1].name, `No atendió la llamada de ${call.srcCallerid}`)
  // },[call.events[call.events.length - 1].endTime])
  return (
    <ResumenCont>
      <Container margin="0 auto 20px" padding="10px">
        <CallerId>
          Llamada de: <b>{call.srcCallerid}</b>
        </CallerId>

        <CallsCont>
          {eventsToRender.length > 0 &&
            eventsToRender.reverse().map((event, index) => {
              const dateStart = new Date(
                call.events.slice(0, 1)[0]?.endTime,
              ).getTime();
              const dateEnd = new Date(event.endTime).getTime();

              const wait = convertSecondsNumberToTimeString(
                (dateEnd - dateStart) / 1000,
              );

              return (
                <Cont key={index}>
                  <Wait>
                    En cola: &nbsp;<b>{wait}</b>
                  </Wait>
                  <Wait>
                    Repicando: &nbsp;<b>{event.ringtime}</b>
                  </Wait>
                  <Wait>
                    Agente: &nbsp;<b>{event.agent || '0000'}</b>{' '}
                  </Wait>
                  <Name>
                    <b>{event.name.substring(0, 17) || 'No asignado'}</b>
                  </Name>
                  <State state={event.state}>{state[event.state]}</State>
                </Cont>
              );
            })}
        </CallsCont>
      </Container>
    </ResumenCont>
  );
};
const ResumenCont = styled.div`
  border: 1px;
  /* max-width: 600px; */
  margin: 0 auto;

  /* @media (min-width: 1500px) {
    transform: scale(1.1);
    margin: 20px auto 50px;
  }
  @media (min-width: 1600px) {
    transform: scale(1.17);
    margin: 30px auto 60px;
  }
  @media (min-width: 1600px) {
    transform: scale(1.17);
    margin: 30px auto 60px;
  }
  @media (min-width: 1750px) {
    transform: scale(1.3);
    margin: 50px auto 90px;
  }
  @media (min-width: 1900px) {
    transform: scale(1.45);
    margin: 70px auto 130px;
  } */
`;
const CallerId = styled.div``;
const CallsCont = styled.div`
  /* height: 190px; */
  /* flex-grow: 1; */
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 10px;
  background-color: ${mediumBackground};

  overflow-x: scroll;

  ::-webkit-scrollbar {
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const Cont = styled.div`
  background-color: white;
  padding: 10px;
  margin: 5px;
  width: 145px;
  min-width: 145px;
  height: 140px;
  border-radius: 10px;
`;
const Wait = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Name = styled.div`
  text-align: center;
`;
const STATE_COLORS = {
  ENTER_QUEUE: 'transparent',
  noanswer: 'orange',
  answer: primary,
};
const State = styled.div<IState>`
  text-align: center;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 700;
  letter-spacing: 1px;
  color: ${props => (props.state !== 'ENTER_QUEUE' ? 'white' : darkBorder)};
  border: ${props =>
    props.state === 'ENTER_QUEUE' && `1px solid ${darkBorder}`};
  background-color: ${props => STATE_COLORS[props.state]};
`;
interface IState {
  state: QueuedCallEventsType;
}
export default QueuedCallDetail;
