import React, { FC } from 'react';
import styled from 'styled-components';

import { II18nKeys } from 'library/interfaces/i18nKeys';

import { primary } from 'library/colors';
import { useTranslation } from 'react-i18next';

//
// ******* component with i18n translation *******
//
 interface IMenuButtonProps {
  i18nKeys: II18nKeys;
  isSelected: boolean;
  onButtonClick: Function;
  index: number;
}
const MenuButton: FC<IMenuButtonProps> = ({ i18nKeys, isSelected, onButtonClick, index }) => {
  // i18n translation
  const { namespace, key } = i18nKeys;
  const { t } = useTranslation(namespace);
  const text = t(key);

  return <Button isSelected={isSelected} onClick={()=>onButtonClick(index)}>{text}</Button>;
};
const Button = styled.button<IButton>`
  height: 100%;
  padding: 0 30px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  background-color: transparent;
  line-height: 70px;
  color: ${props=>props.isSelected && primary};
  border-bottom: 5px solid ${props=>props.isSelected ? primary : 'transparent'};
  
`;
interface IButton {
  isSelected: boolean;
}
export default MenuButton;
