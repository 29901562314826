import { BlueContModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { darkBorder, primary } from 'library/colors';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import WaVisualEditor from './waVisualEditorComponents/waVisualEditor';
import { IWaListMessage, IWaQuickReplyMessage } from './waVisualEditorComponents/waEditorInterfaces';
import { Node } from 'reactflow';
import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';

interface IVisualEditorProps {
  textStringify: string
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  handleShowVisualEditor: (show: boolean) => void;
  handleDeleteEdgeOnDeleteSource: (sourceId: string) => void

}
const VisualEditor = ({ textStringify, selectedNode, setSelectedNode, handleShowVisualEditor, handleDeleteEdgeOnDeleteSource }: IVisualEditorProps) => {

  const [dataText, setDataText] = useState<string | null>(null)

  const [quickReply, setQuickReply] = useState<IWaQuickReplyMessage | null>(null)
  const [list, setList] = useState<IWaListMessage | null>(null)

  useEffect(() => {
    try {
      const data = JSON.parse(textStringify)
      if (data.type === 'quick_reply') {
        setQuickReply(data)
      } else if (data.type === 'list') {
        setList(data)
      }
    } catch {
      if (typeof textStringify === 'string')
        setDataText(textStringify)
    }
  }, [textStringify])
  return (
    <Cont>
      <WaVisualEditor
        quickReply={quickReply}
        list={list}
        textMessage={dataText}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        handleShowVisualEditor={handleShowVisualEditor}
        handleDeleteEdgeOnDeleteSource={handleDeleteEdgeOnDeleteSource}
      />
    </Cont>
  );
};
const Cont = styled.div`
`;


export default VisualEditor;