import React, { FC } from 'react';
import { ICallStatus } from 'store/reports/reducer';
import styled from 'styled-components';
import Indicator from '../atoms/indicator';

interface IIndicatorsProps {
  callStatus: ICallStatus;
}
const Indicators: FC<IIndicatorsProps> = ({ callStatus }) => {
  const { answered, notAnswered, abandom, busy, failed, total } = callStatus;
  return (
    <Cont>
      <Indicator label="Answered" total={answered} />
      <Indicator label="No Answered" total={notAnswered} />
      <Indicator label="Abandom" total={abandom} />
      <Indicator label="Busy" total={busy} />
      <Indicator label="Fail" total={failed} />
      <Indicator label="Total" total={total} />
    </Cont>
  );
};
const Cont = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 10px 0;
  border-radius: 10px;
`;
export default Indicators;
