import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// english US translations imports
import notifications_en_US from './locales/en_US/notifications.json';
import menu_en_US from './locales/en_US/menu.json';
import header_en_US from './locales/en_US/header.json';
import workspace_en_US from './locales/en_US/workspace.json';
import liveBoard_en_US from './locales/en_US/liveBoard.json';
import summarize_en_US from './locales/en_US/summarize.json';
import recordings_en_US from './locales/en_US/recordings.json';
import outbound_en_US from './locales/en_US/outbound.json';
import reports_en_US from './locales/en_US/reports.json';
import configuration_en_US from './locales/en_US/configuration.json';
import scenarios_en_US from './locales/en_US/scenarios.json';
import databases_en_US from './locales/en_US/databases.json';
import agentsLive_en_US from './locales/en_US/agentsLive.json';

// spanish LA translations imports
import notifications_es_LA from './locales/es_LA/notifications.json';
import menu_es_LA from './locales/es_LA/menu.json';
import header_es_LA from './locales/es_LA/header.json';
import workspace_es_LA from './locales/es_LA/workspace.json';
import liveBoard_es_LA from './locales/es_LA/liveBoard.json';
import summarize_es_LA from './locales/es_LA/summarize.json';
import recordings_es_LA from './locales/es_LA/recordings.json';
import outbound_es_LA from './locales/es_LA/outbound.json';
import reports_es_LA from './locales/es_LA/reports.json';
import configuration_es_LA from './locales/es_LA/configuration.json';
import scenarios_es_LA from './locales/es_LA/scenarios.json';
import databases_es_LA from './locales/es_LA/databases.json';
import agentsLive_es_LA from './locales/es_LA/agentsLive.json';

// the translations
const resources = {
  en_US: {
    notifications: notifications_en_US,
    menu: menu_en_US,
    header: header_en_US,
    workspace: workspace_en_US,
    liveBoard: liveBoard_en_US,
    summarize: summarize_en_US,
    recordings: recordings_en_US,
    outbound: outbound_en_US,
    reports: reports_en_US,
    configuration: configuration_en_US,
    scenarios: scenarios_en_US,
    databases: databases_en_US,
    agentsLive: agentsLive_en_US,
  },
  es_LA: {
    notifications: notifications_es_LA,
    menu: menu_es_LA,
    header: header_es_LA,
    workspace: workspace_es_LA,
    liveBoard: liveBoard_es_LA,
    summarize: summarize_es_LA,
    recordings: recordings_es_LA,
    outbound: outbound_es_LA,
    reports: reports_es_LA,
    configuration: configuration_es_LA,
    scenarios: scenarios_es_LA,
    databases: databases_es_LA,
    agentsLive: agentsLive_es_LA,
  },
};

// config
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'en_US',
    fallbackLng: 'en_US', // use en if detected lng is not available

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
