import { primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ISelectableButtonProps {
  children: string | number;
  isSelected: boolean;
  onClick: Function;
  index?: number;
}
const SelectableButton: FC<ISelectableButtonProps> = ({
  children,
  isSelected,
  onClick,
  index = 0,
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    return onClick(index);
  };
  return (
    <Button isSelected={isSelected} onClick={e => handleClick(e)}>
      {children}
    </Button>
  );
};
const Button = styled.button<IButton>`
  border-radius: 50px;
  margin: 0 10px;
  display: inline-block;
  min-width: 30px;
  font-size: 13px;
  padding: 8px 16px;
  border: 2px solid ${primary};
  color: ${props => (props.isSelected ? 'white' : 'black')};
  background-color: ${props => (props.isSelected ? primary : 'white')};
`;
interface IButton {
  isSelected: boolean;
}
export default SelectableButton;
