import LeftMenuOutbound from 'components/outbound/molecules/leftMenu';
import { Completed, Draft, Megaphone, Pause, Phone, Whatsapp } from 'icons';
import IStore from 'library/interfaces/storeInterface';
import { IWaCampaign } from 'library/interfaces/whatsappCampaignInterfaces';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWACampaignsAction } from 'store/actions';
import styled from 'styled-components';
import WACampaign from '../organisms/waCampaign';
import WaCampaignsLoadingContactsCont from '../organisms/waCampaignLoadingContactsCont';
import SearchAndButtonsWa from '../organisms/searchAndButtonsWa';
import { useAppSelector } from 'library/hooks/useAppSelector';

interface IWaCampaignsTemplateProps {
  setShowWACampaignForm: (show: boolean) => void;
  setShowGallery: (show: boolean) => void;
}
const WaCampaignsTemplate: FC<IWaCampaignsTemplateProps> = ({
  setShowWACampaignForm,
  setShowGallery
}) => {
  const dispatch = useDispatch();
  const campaigns = useSelector<IStore, IWaCampaign[]>(
    store => store.waCampaigns.campaigns,
  );
  const {exten, auth0_id} = useAppSelector(store=>store.authUserData)

  const [waCampaignFilter, setWACampaignFilter] = useState('');

  const waCampaignsToRender = useMemo(() => {
    return campaigns.filter(campaign =>
      campaign.active !== false
      && (waCampaignFilter ? campaign.status === waCampaignFilter : campaign),
    );
  }, [waCampaignFilter, campaigns])

  const handleCreateWACamgpaignButtonClick = () => {
    if(exten.startsWith('65')) return
    setShowWACampaignForm(true);
  };

  const handleShowGallery = () => {
    if(exten.startsWith('65')) return
    setShowGallery(true)
  }


  useEffect(() => {
    if (campaigns.length > 0 || !auth0_id || exten.startsWith('65')) return;
    dispatch(getWACampaignsAction());
  }, [auth0_id]);

  return (
    <Cont>
      <SearchAndButtonsWa
        onCreateWaCampaignButtonClick={handleCreateWACamgpaignButtonClick}
        onShowGalleryButtonClick={handleShowGallery}
      />
      <FlexCont>
        <LeftMenuOutbound
          filterButtons={buttons}
          setCampaignFilter={setWACampaignFilter}
        />
        <CampaignsCont>
          <WaCampaignsLoadingContactsCont />
          {waCampaignsToRender.map((campaign, index) => {
            return <WACampaign key={index} campaign={campaign} />;
          })}
        </CampaignsCont>
      </FlexCont>
    </Cont>
  );
};
const Cont = styled.div`
  margin: 0 20px;
`;
const FlexCont = styled.div`
  display: flex;
`;
const CampaignsCont = styled.div`
  flex-grow: 1;
  /* border: 1px solid red; */
`;
//
// filter buttons
//
const buttons = [
  {
    isSelected: true,
    icon: <Megaphone />,
    value: '',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.all' },
  },
  {
    isSelected: false,
    icon: <Draft width={15} />,
    value: 'created',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.draft' },
  },
  {
    isSelected: false,
    icon: <Whatsapp width={18} />,
    value: 'running',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.ongoing' },
  },
  {
    isSelected: false,
    icon: <Pause width={25} style={{ transform: 'translateX(-5px)' }} />,
    value: 'stopped',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.pause' },
  },
  {
    isSelected: false,
    icon: <Completed width={18} />,
    value: 'completed',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.completed' },
  },
];
export default WaCampaignsTemplate;
