import { IModelQueuedCallsData } from 'library/interfaces/liveQueuedCallsInterfaces';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../atoms/container';
import Title from '../atoms/title';
import OnLineAgents from '../molecules/onLineAgents';
import QueuedCall from '../molecules/queuedCall';
import { mediumBackground, primary } from 'library/colors';

interface IQueuedCallsResumeProps {
  data: IModelQueuedCallsData;
}
const QueuedCallsResume: FC<IQueuedCallsResumeProps> = ({ data }) => {
  const { queue, agents_state, calls } = data;

  const history = useHistory();

  const handleClick = () => {
    if(!queue.uid) return
    history.push(`/live-board/queued-calls/detailed/${queue.uid}`);
  };
  return (
    <ResumenCont>
      <Container
        margin="0 auto 20px"
        padding="0 0 0 10px"
        handleClick={handleClick}
        cursorPointer
      >
        <Title padding="20px 15px 5px">{queue.name}</Title>
        <Div>
          <CallsCont>
            {calls.map((call, index) => {
              return <QueuedCall key={index} call={call} />;
            })}
          </CallsCont>
          <OnLineAgents data={agents_state} />
        </Div>
      </Container>
    </ResumenCont>
  );
};
const ResumenCont = styled.div`
  border: 1px;
  max-width: 1050px;
  margin: 0 auto;

  @media (min-width: 1500px) {
    transform: scale(1.1);
    margin: 20px auto 50px;
  }
  @media (min-width: 1600px) {
    transform: scale(1.17);
    margin: 30px auto 60px;
  }
  @media (min-width: 1600px) {
    transform: scale(1.17);
    margin: 30px auto 60px;
  }
  @media (min-width: 1750px) {
    transform: scale(1.3);
    margin: 50px auto 90px;
  }
  @media (min-width: 1900px) {
    transform: scale(1.45);
    margin: 70px auto 130px;
  }
`;
const Div = styled.div`
  display: flex;
  padding: 0 15px 15px;

  &:last-child {
  }
`;
const CallsCont = styled.div`
  max-width: 80%;
  height: 190px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 10px;
  background-color: ${mediumBackground};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
export default QueuedCallsResume;
