import { IChannel } from 'library/interfaces/channelsInterfaces';
import { IError } from 'store/reports/reducer';
import {
  getChannelsFail,
  getChannelsSuccess,
  updateChannelSceneryFail,
  updateChannelScenerySuccess,
} from './actions';
import {
  getChannels,
  putUpdateChannelScenery,
} from 'helpers/fakebackend_helper';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_CHANNELS,
  IUpdateChannelScenery,
  UPDATE_CHANNEL_SCENERY,
} from './actionTypes';

function* onGetChannels() {
  try {
    const channels: IChannel[] = yield call(getChannels);
    yield put(getChannelsSuccess(channels));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the channels',
      details: `${e}`,
    };
    yield put(getChannelsFail(error));
  }
}

function* onUpdateChannelScenery({
  payload: { channelId, sceneryId },
}: IUpdateChannelScenery) {
  try {
    yield call(putUpdateChannelScenery, channelId, { scenario_id: sceneryId });
    yield put(updateChannelScenerySuccess(channelId, sceneryId));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong updating the channel scenery',
      details: `${e}`,
    };
    yield put(updateChannelSceneryFail(error));
  }
}

function* channelsSaga() {
  yield takeEvery(GET_CHANNELS, onGetChannels);
  yield takeEvery(UPDATE_CHANNEL_SCENERY, onUpdateChannelScenery);
}

export default channelsSaga;
