import React, { FC } from 'react';
import styled from 'styled-components';

import MenuButton from '../atoms/menuButton';

import { darkBorder } from 'library/colors';
import { II18nKeys } from 'library/interfaces/i18nKeys';

interface IOutboundMenuProps {
  buttons: {
    i18nKeys: II18nKeys;
    isSelected: boolean;
  }[];
  onButtonClick: Function;
}

const OutboundMenu: FC<IOutboundMenuProps> = ({ buttons, onButtonClick }) => {
  return (
    <Menu>
      {buttons.map((item, index) => {
        return (
          <MenuButton
            key={index}
            index={index}
            isSelected={item.isSelected}
            i18nKeys={item.i18nKeys}
            onButtonClick={onButtonClick}
          />
        );
      })}
    </Menu>
  );
};
const Menu = styled.div`
  height: 70px;
  border-bottom: 2px solid ${darkBorder};
`;
export default OutboundMenu;
