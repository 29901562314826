import { DelIconModalSC, FlexContSBModalSC, InputColorModalSC, InputModalSC, InputVerticalGroupModalSC, LabelModalSC, SelectModalSC, WhiteContModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { conditions } from 'components/flowV2/utils';
import { danger, primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IConditionProps {
  index: number;
  sourceColor: string;
  condition: {
    id: string;
    port: string;
    variable: string;
    condition: string;
    value: string;
  };
  handleConditionChange: (
    index: number,
    name: 'port' | 'variable' | 'condition' | 'value',
    value: string,
  ) => void;
  handleDeleteCondition: (conditionId: string) => void;
  handleUpdateSourceColor: (sourceId: string, color: string) => void;
}
const Condition: FC<IConditionProps> = ({
  index,
  condition,
  sourceColor,
  handleConditionChange,
  handleDeleteCondition,
  handleUpdateSourceColor
}) => {
  return (
    <WhiteContModalSC>
      <DelIconModalSC onClick={() => handleDeleteCondition(condition.id)}>
        <i className='bx bxs-trash'></i>
      </DelIconModalSC>
      <FlexContSBModalSC>
        <InputVerticalGroupModalSC >
          <LabelModalSC htmlFor="port">Port name</LabelModalSC>
          <InputModalSC
            type="text"
            id="port"
            name="port"
            value={condition.port}
            onChange={e => handleConditionChange(index, 'port', e.target.value)}
          />
        </InputVerticalGroupModalSC>
        <InputVerticalGroupModalSC center margin=' 0 0 20px 10px'>
          <LabelModalSC>Color</LabelModalSC>
          <InputColorModalSC value={sourceColor} onChange={e => handleUpdateSourceColor(condition.id, e.target.value)} />
        </InputVerticalGroupModalSC>
      </FlexContSBModalSC>

      <FlexContSBModalSC>
        <InputVerticalGroupModalSC maxWidth='35%' margin='0'>
          <LabelModalSC htmlFor="variable">Variable Name</LabelModalSC>
          <InputModalSC
            type="text"
            id="variable"
            name="variable"
            value={condition.variable}
            onChange={e =>
              handleConditionChange(index, 'variable', e.target.value)
            }
          />
        </InputVerticalGroupModalSC>
        <InputVerticalGroupModalSC maxWidth="20%" margin='0'>
          <LabelModalSC htmlFor="condition">Condition</LabelModalSC>
          <SelectModalSC
            name="condition"
            id="condition"
            value={condition.condition}
            onChange={e =>
              handleConditionChange(index, 'condition', e.target.value)
            }
          >
            {conditions.map((condition, index) => {
              return (
                <option key={index} value={condition.value}>
                  {condition.name}
                </option>
              );
            })}
          </SelectModalSC>
        </InputVerticalGroupModalSC>
        <InputVerticalGroupModalSC maxWidth='35%' margin='0'>
          <LabelModalSC htmlFor="value">value</LabelModalSC>
          <InputModalSC
            type="text"
            id="value"
            name="value"
            value={condition.value}
            onChange={e =>
              handleConditionChange(index, 'value', e.target.value)
            }
          />
        </InputVerticalGroupModalSC>
      </FlexContSBModalSC>
    </WhiteContModalSC>
  );
};
export default Condition;
