import React, { FC } from 'react';
import styled from 'styled-components';

import { Hamburguer, LightBulb, Paper, PeopleText, Reports } from 'icons';
import { lightGreenICON } from 'library/colors';
import { useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';

interface IRightBarIconsProps {
  handleExtendedBar: Function;
}
const RightBarIcons: FC<IRightBarIconsProps> = ({ handleExtendedBar }) => {
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const showWa = owner === 'BSE' || owner === 'POC' || owner === 'CASMU';
  const showReport = owner === 'BSE'
  return (
    <IconsCont>
      <div>
        <Icons>
          {/* <Paper width={18}/> */}
          <PeopleText
            width={25}
            cursor={'pointer'}
            color={lightGreenICON}
            onClick={() => handleExtendedBar(1)}
          />
        </Icons>
        <Icons>
          <LightBulb
            width={30}
            cursor={'pointer'}
            color={lightGreenICON}
            onClick={() => handleExtendedBar(2)}
          />
        </Icons>
        {showWa && (
          <Icons>
            <Paper
              width={18}
              cursor={'pointer'}
              color={lightGreenICON}
              onClick={() => handleExtendedBar(3)}
            />
          </Icons>
        )}
        {showReport && <Icons>
          <Reports
            width={20}
            cursor={'pointer'}
            color={lightGreenICON}
            onClick={() => handleExtendedBar(4)}
          />
        </Icons>}
      </div>
      <HambCont>
        <Hamburguer width={20} color={'white'} />
      </HambCont>
    </IconsCont>
  );
};
const IconsCont = styled.div`
  height: 100%;
  min-width: 50px;
  max-width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: #2a3042;
  position: relative;
  z-index: 10;
`;
const Icons = styled.div`
  margin-top: 20px;
  display: grid;
  place-items: center;
`;
const HambCont = styled.div`
  color: black;
  margin-bottom: 20px;
`;
export default RightBarIcons;
