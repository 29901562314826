export const primary = '#7c9904';
export const secondary = '#cad7db';
export const danger = '#d78480';

// TEXT COLORS
export const darkText = '#000';
export const lightText = '#fff';

('#9ba5a8'); // gris del fondo del grafico de barra horizontal

export const blueGraph = '#4cb3ed';

export const lightGreen = '#86c939';
export const lightGreenICON = '#92d900';
export const darkGreen = '#698203';

export const lightBackgroundBlue = '#eff6f9';

export const contactGreen = '#2f9980';

export const inactiveColor = 'rgb(116, 120, 141)';

export const border = 'rgb(239, 242, 247)';

export const darkBorder = 'rgb(194, 207, 213)';

// BACKGROUND COLORS

export const lightBackground = '#fff';
export const mediumBackground = 'rgb(239, 245, 248)';
export const lightGrayBackground = 'rgb(248, 248, 251)';
export const popUpBackground = 'rgba(219, 237, 243, .7)';

export const incomingCallIconBackground = '#5f73aa';

export const msgIconsBg = {
  call: incomingCallIconBackground,
  whatsapp: '#25D366',
  facebook: '#3b5998',
  instagram: '#D62976',
};

export const darkGrayBg = '#2a3042';
