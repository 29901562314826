import { darkBorder, primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IWaTypesOfMEssageButtonsProps {
  buttons: { label: string, isSelected: boolean }[]
  handleSelectButton: (index: number) => void;
}
const WaTypesOfMEssageButtons = ({ buttons, handleSelectButton }: IWaTypesOfMEssageButtonsProps) => {
  return (
    <Cont>
      <MessagesTypes>Tipo de Mensaje</MessagesTypes>
      {buttons.map((button, index) => {
        return (
          <Button
            key={index}
            $isSelected={button.isSelected}
            onClick={() => handleSelectButton(index)}
          >
            {button.label}
          </Button>
        )
      })}
    </Cont>
  );
};
const Cont = styled.div``;
const MessagesTypes = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;
const Button = styled.button<IButton>`
  display: block;
  cursor: pointer;
  border: none;
  text-align: left;
  margin-bottom: 2px;
  padding: 2px 5px;
  width: 100%;
  border-radius: 5px;
  background-color: ${props => props.$isSelected ? primary : 'transparent'};

  &:hover {
    background-color: ${props => !props.$isSelected && darkBorder};
  }
`;
interface IButton {
  $isSelected: boolean
}
export default WaTypesOfMEssageButtons;