import { lightBackgroundBlue } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface IIndicatorProps {
  label: string;
  filter?: string;
  value?: number;
  labelBackColor?: string;
  onClick?: Function
}
const IndicatorLinked: FC<IIndicatorProps> = ({
  label,
  filter = "",
  value = 0,
  labelBackColor = lightBackgroundBlue,
  onClick,
}) => {
  return (
    <Cont isSelectable={onClick && true} onClick={()=>onClick && onClick(filter)}>
      <Total>{value}</Total>
      <Label labelBackColor={labelBackColor}>{label}</Label>
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  text-align: center;
  min-width: 60px;
  margin: 5px auto;
  cursor: ${props=>props.isSelectable ? 'pointer' : 'default'};
  
  &:hover{
    transform: scale(${props=>props.isSelectable && 1.05});
  }
`;
const Total = styled.div`
  text-align: center;
  font-size: 1.1rem;
`;
const Label = styled.div<ILabel>`
  padding: 3px 5px;
  font-size: 0.6rem;
  font-weight: 500;
  border-radius: 50px;
  color: ${props => props.labelBackColor !== lightBackgroundBlue && 'white'};
  background-color: ${props => props.labelBackColor};
`;
interface ICont {
  isSelectable?: boolean 
}
interface ILabel {
  labelBackColor: string;
}
export default IndicatorLinked;
