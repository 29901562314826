import * as React from 'react';

export const Incoming = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M113.28 51.093c-2.973-2.973-7.87-2.973-10.842 0l-8.102 8.102C89.09 56.164 83.902 52.2 79.064 47.362S70.262 37.336 67.23 32.09l8.102-8.102c2.973-2.973 2.973-7.87 0-10.842l-8.102-8.102c-2.973-2.973-7.87-2.973-10.842 0l-4.255 4.255c-10.493 10.492-4.43 33.516 13.523 51.47s41.036 24.015 51.47 13.523l4.255-4.255c2.973-2.973 2.973-7.87 0-10.842zM96.9 3.353v27.513h27.513M96.9 30.866l27.513-27.513"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={5.829}
    />
    <path
      d="M99.407 79.072v8.16c0 11.542-9.327 20.868-20.868 20.868H54.465l-26.23 21.859.524-21.8c-11.541 0-20.867-9.327-20.867-20.868V45.73c0-11.541 9.326-20.868 20.867-20.868h18.129M23.047 48.411h32.06M23.047 67.064h49.08M23.047 84.843h60.388"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={5.829}
    />
  </svg>
);
