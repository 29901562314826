import React, { FC } from 'react';
import styled from 'styled-components';

import { darkBorder, lightGreen } from 'library/colors';

import { AnswerCall, HangUpCall, TransferCall } from 'icons';

interface IPhoneRoundButton {
  red?: React.ReactNode;
  transfer?: React.ReactNode;
  onButtonClick: Function;
}
const PhoneRoundButton: FC<IPhoneRoundButton> = ({
  red,
  onButtonClick,
  transfer,
}) => {
  return (
    <Button red={red} transfer={transfer} onClick={() => onButtonClick()}>
      <Span>
        {/* <img src={red ? hangUp : transfer ? transferSvg : answer} alt="" /> */}
        {red ? (
          <HangUpCall width={'65%'} />
        ) : transfer ? (
          <TransferCall width={'60%'} color='black'/>
        ) : (
          <AnswerCall width={'70%'} />
        )}
      </Span>
    </Button>
  );
};
const Button = styled.button<IButtonProps>`
  width: 40px;
  aspect-ratio: 1;
  color: '#fff';
  cursor: pointer;
  border: none;
  border-radius: 50%;
  position: relative;
  transition: transform 0.25s;
  background-color: ${props =>
    props.red ? 'red' : props.transfer ? darkBorder : lightGreen};

  &:hover {
    transform: scale(1.05);
  }
`;
const Span = styled.span`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
interface IButtonProps {
  red?: React.ReactNode;
  transfer?: React.ReactNode;
}
export default PhoneRoundButton;
