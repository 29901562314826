import * as React from "react"

export const SendSMS = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M26.66 31.71a1 1 0 0 1-.56-.19l-6.29-4.86H8.34a6.61 6.61 0 0 1-6.6-6.6V9.88a6.6 6.6 0 0 1 6.6-6.59h18.32a6.6 6.6 0 0 1 6.6 6.59v10.18a6.61 6.61 0 0 1-5.68 6.53v4.2a.91.91 0 0 1-.51.82.86.86 0 0 1-.41.1ZM8.34 5.13a4.76 4.76 0 0 0-4.76 4.75v10.18a4.77 4.77 0 0 0 4.76 4.76h11.78a.88.88 0 0 1 .56.19l5.06 3.91v-3.18a.92.92 0 0 1 .92-.92 4.77 4.77 0 0 0 4.76-4.76V9.88a4.76 4.76 0 0 0-4.76-4.75Z"
    />
    <path
      fill="currentColor"
      d="M17.32 18.42a.92.92 0 0 1-.92-.92v-3.56c0-.09 0-.12-.06-.15a3 3 0 0 0-1.63-.35v4.06a.92.92 0 1 1-1.84 0v-4.93a.93.93 0 0 1 .81-.91c.44-.05 2.71-.28 3.87.75a2 2 0 0 1 .69 1.53v3.56a.92.92 0 0 1-.92.92Z"
    />
    <path
      fill="currentColor"
      d="M20.85 18.42a.92.92 0 0 1-.92-.92v-3.56c0-.09 0-.12-.07-.15a3 3 0 0 0-1.62-.35v4.06a.92.92 0 0 1-1.84 0v-4.93a.93.93 0 0 1 .81-.91c.44-.05 2.71-.28 3.87.75a2 2 0 0 1 .68 1.53v3.56a.92.92 0 0 1-.91.92ZM10 18.42H8.09a.92.92 0 0 1 0-1.84h1.68l-.08-.09-.13-.11c-.41-.35-1.12-1-1.74-1.45a1.89 1.89 0 0 1-.69-2.1 1.8 1.8 0 0 1 1.8-1.18h1.63a.92.92 0 0 1 0 1.84H9c.65.51 1.38 1.13 1.8 1.49l.13.11a2.41 2.41 0 0 1 .8 2.35 1.58 1.58 0 0 1-1.73.98ZM26.2 18.42h-1.93a.92.92 0 0 1 0-1.84H26a.24.24 0 0 0-.08-.09l-.12-.11c-.41-.35-1.12-1-1.75-1.45a1.91 1.91 0 0 1-.69-2.1 1.81 1.81 0 0 1 1.8-1.18h1.64a.92.92 0 1 1 0 1.84h-1.6c.64.51 1.37 1.13 1.79 1.49l.13.11a2.39 2.39 0 0 1 .81 2.35 1.6 1.6 0 0 1-1.73.98Z"
    />
  </svg>
)

