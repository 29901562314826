import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface ISMSFormButtonsProps {
  saveCampaign: Function;
  isCreatingSMSCampaign: boolean | undefined;
}
const SMSFormButtons: FC<ISMSFormButtonsProps> = ({
  saveCampaign,
  isCreatingSMSCampaign,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const isButtonDisable = isCreatingSMSCampaign
    ? true
    : isChecked
    ? false
    : true;
  //save
  const handleSaveButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (isButtonDisable) return;
    saveCampaign();
  };
  return (
    <Cont>
      <DisclaimerCont>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={e => setIsChecked(!isChecked)}
        />
        <p>
          I confirm the legality of processing the uploaded data (including the
          required consents) and guarantee compliance with the requirements of
          the advertising legislation.
        </p>
      </DisclaimerCont>
      <ButtonsCont>
        <NextButton
          isDisabled={isButtonDisable}
          onClick={e => handleSaveButtonClick(e)}
        >
          {!isCreatingSMSCampaign ? (
            'Crear'
          ) : (
            <>
              Creando <LoadingPoints />
            </>
          )}
        </NextButton>
      </ButtonsCont>
    </Cont>
  );
};
const Cont = styled.div`
  height: 80px;
  padding: 0px 30px;
  margin: 20px auto;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: ${lightBackgroundBlue};
`;
const DisclaimerCont = styled.div`
  display: flex;

  & > input:checked {
    background-color: lime;
  }

  & > p {
    margin: 0;
    max-width: 373px;
    margin-left: 10px;
    font-size: 0.7rem;
    font-weight: 500;
  }
`;
const ButtonsCont = styled.div`
  min-width: 303px;
  display: flex;
  justify-content: end;
`;
const NextButton = styled.button<INextButton>`
  color: white;
  font-size: 1rem;
  font-weight: 700;
  width: 150px;
  border-radius: 50px;
  margin-left: 20px;
  border: 2px solid ${props => (props.isDisabled ? darkBorder : primary)};
  background-color: ${props => (props.isDisabled ? darkBorder : primary)};
`;
interface INextButton {
  isDisabled: boolean;
}
export default SMSFormButtons;
