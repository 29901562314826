import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SidebarLink({ Icon, title, to, badge = undefined }) {
  const { t } = useTranslation('menu');
  return (
    <Link to={to}>
      <Icon width={24} height={24} color={'rgb(146, 217, 0)'} />
      {badge && (
        <span className="badge rounded-pill bg-info float-end">{badge}</span>
      )}
      <span style={{ marginLeft: 8 }}>{t(`${title}`)}</span>
    </Link>
  );
}

SidebarLink.propTypes = {
  Icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  badge: PropTypes.number,
};

export default SidebarLink;
