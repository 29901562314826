import {
  deleteWaCampaign,
  getGupshupAppList,
  getGupshupAppTemplates,
  getWACampaignByID,
  getWACampaigns,
  postWACampaign,
  postWACampaignContacs,
  putChangeWACampaignStatus,
  putWaCampaignColor,
} from 'helpers/fakebackend_helper';
import {
  IGupshupApp,
  IGupshupAppTemplateResponse,
  IWaCampaign,
} from 'library/interfaces/whatsappCampaignInterfaces';
import { groupContactsInArr } from 'library/services/campaignFormServices';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { IError } from 'store/reports/reducer';
import {
  deleteWaCampaignFail,
  deleteWaCampaignSuccess,
  getGupshupAppListFail,
  getGupshupAppListSuccess,
  getGupshupAppTemplatesFail,
  getGupshupAppTemplatesSuccess,
  getWACampaignByIdFail,
  getWACampaignByIdSuccess,
  getWACampaignsFail,
  getWACampaignsSuccess,
  postWACampaignFail,
  postWACampaignSuccess,
  postWAContactsFinished,
  putChangeWACampaignStatusFail,
  putChangeWACampaignStatusSuccess,
  putWaCampaignColorFail,
  putWaCampaignColorSuccess,
  WAContactGroupsAction,
  WAContactGroupsFail,
  WAContactGroupsSuccess,
} from './actions';
import {
  DELETE_WA_CAMPAIGN,
  GET_GUPSHUP_APP_LIST,
  GET_GUPSHUP_APP_TEMPLATES,
  GET_WA_CAMPAIGNS,
  GET_WA_CAMPAIGN_BY_ID,
  IDeleteWaCampaign,
  IGetGupshupAppTemplates,
  IGetWACampaignById,
  IPostWACampaign,
  IPostWAContactGroups,
  IPutChangeWACampaignStatus,
  IPutWaCampaignColor,
  POST_WA_CAMPAIGN,
  POST_WA_CONTACT_GROUP,
  PUT_RUN_WA_CAMPAIGN,
  PUT_STOP_WA_CAMPAIGN,
  PUT_WA_CAMPAIGN_COLOR,
} from './actionTypes';

function* onGetWACampaigns() {
  try {
    const waCampaigns: IWaCampaign[] = yield call(getWACampaigns);
    yield put(getWACampaignsSuccess(waCampaigns.reverse()));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the campaigns',
      details: `${e}`,
    };
    yield put(getWACampaignsFail(error));
  }
}

function* onGetWACampaignById({
  payload: { waCampaignId },
}: IGetWACampaignById) {
  try {
    const waCampaign: IWaCampaign = yield call(getWACampaignByID, waCampaignId);
    yield put(getWACampaignByIdSuccess(waCampaignId, waCampaign));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the campaign',
      details: `${e}`,
    };
    yield put(getWACampaignByIdFail(waCampaignId, error));
  }
}

function* onPostWACampaign({ payload: { waCampaign } }: IPostWACampaign) {
  try {
    const { contacts, ...rest } = waCampaign;
    const waCampaignId: string = yield call(postWACampaign, rest);
    const newWACampaign: IWaCampaign = yield call(
      getWACampaignByID,
      waCampaignId,
    );

    // contactLoad doesn't exist on campaign, is undefined
    // then, below initialize contactLoad to handle the percent of contact load
    newWACampaign.contactLoad = {
      isLoading: false,
      loadPercentage: 0,
      postContactsGroupNumberOfError: [],
    };
    // first contacts_count is 0, then set the value = contacts.length
    newWACampaign.contacts_count = contacts.length;
    // insert the new campaign on store to render it
    yield put(postWACampaignSuccess(newWACampaign));

    // split contacts in array groups of n contacts inside the array
    const contactGroups = groupContactsInArr(contacts, 20);

    yield put(WAContactGroupsAction(waCampaignId, contactGroups));

    //
    // TODO: delete the line below from here and sms saga
    //
    // yield call(onPostContactsToSMSCampaign, SMSCampaignId, contactGroups);
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong creating the campaigns',
      details: `${e}`,
    };
    yield put(postWACampaignFail(error));
  }
}

function* onPostContactsToWACampaign({
  payload: { waCampaignId, contacts },
}: IPostWAContactGroups) {
  let index = 1;
  const total = contacts.length;
  for (const contactArr of contacts) {
    try {
      yield call(postWACampaignContacs, waCampaignId, {
        contacts: contactArr,
      });

      const percent: number = Math.round((index / total) * 100);
      yield put(WAContactGroupsSuccess(waCampaignId, percent));
    } catch (e) {
      const percent: number = Math.round((index / total) * 100);
      yield put(WAContactGroupsFail(waCampaignId, percent, contactArr));
    }
    index++;
  }
  yield delay(1000);
  yield put(postWAContactsFinished(waCampaignId));
}

function* onPutChangeWACampaignStatus({
  payload: { action, waCampaignId, actionType },
}: IPutChangeWACampaignStatus) {
  try {
    yield call(putChangeWACampaignStatus, waCampaignId, action);

    const waCampaignUpdated: IWaCampaign = yield call(
      getWACampaignByID,
      waCampaignId,
    );

    yield put(
      putChangeWACampaignStatusSuccess(
        waCampaignId,
        waCampaignUpdated,
        `${actionType}_SUCCESS`,
      ),
    );
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong updating the campaign status',
      details: `${e}`,
    };
    yield put(
      putChangeWACampaignStatusFail(waCampaignId, error, `${actionType}_FAIL`),
    );
  }
}

function* onDeleteWaCampaign({ payload: { waCampaignId } }: IDeleteWaCampaign) {
  try {
    const res: string = yield call(deleteWaCampaign, waCampaignId);

    if (res !== 'The campaign was set as inactive')
      throw new Error('Something went wrong deleting the campaign ');

    const waCampaign: IWaCampaign = yield call(getWACampaignByID, waCampaignId);

    yield put(deleteWaCampaignSuccess(waCampaignId, waCampaign));
  } catch (e) {
    const error: IError = {
      message: 'Something went wrong deleting the campaign',
      details: `${e}`,
    };
    yield put(deleteWaCampaignFail(waCampaignId, error));
  }
}

function* onPutWaCampaignColor({
  payload: { waCampaignId, color },
}: IPutWaCampaignColor) {
  try {
    const res: string = yield call(putWaCampaignColor, waCampaignId, color);
    if (res !== 'OK') throw new Error('Can not update the campaign color');

    const waCampaign: IWaCampaign = yield call(getWACampaignByID, waCampaignId);

    yield put(putWaCampaignColorSuccess(waCampaignId, waCampaign));
  } catch (e) {
    const error: IError = {
      message: 'Something went wrong updating the campaign color',
      details: `${e}`,
    };
    yield put(putWaCampaignColorFail(waCampaignId, error));
  }
}

function* onGetGupshupAppList() {
  try {
    const appList: IGupshupApp[] = yield call(getGupshupAppList);
    yield put(getGupshupAppListSuccess(appList));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the gupshup app list',
      details: `${e}`,
    };
    yield put(getGupshupAppListFail(error));
  }
}

function* onGetGupshupAppTemplates({
  payload: { appId },
}: IGetGupshupAppTemplates) {
  try {
    const { templates }: IGupshupAppTemplateResponse = yield call(
      getGupshupAppTemplates,
      appId,
    );
    yield put(getGupshupAppTemplatesSuccess(templates));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the gupshup app templates',
      details: `${e}`,
    };
    yield put(getGupshupAppTemplatesFail(error));
  }
}

function* waCampaignSaga() {
  yield takeEvery(GET_WA_CAMPAIGNS, onGetWACampaigns);
  yield takeEvery(GET_WA_CAMPAIGN_BY_ID, onGetWACampaignById);
  yield takeEvery(POST_WA_CAMPAIGN, onPostWACampaign);
  yield takeEvery(POST_WA_CONTACT_GROUP, onPostContactsToWACampaign);
  yield takeEvery(
    [PUT_RUN_WA_CAMPAIGN, PUT_STOP_WA_CAMPAIGN],
    onPutChangeWACampaignStatus,
  );
  yield takeEvery(DELETE_WA_CAMPAIGN, onDeleteWaCampaign);
  yield takeEvery(PUT_WA_CAMPAIGN_COLOR, onPutWaCampaignColor);
  yield takeEvery(GET_GUPSHUP_APP_LIST, onGetGupshupAppList);
  yield takeEvery(GET_GUPSHUP_APP_TEMPLATES, onGetGupshupAppTemplates);
}
export default waCampaignSaga;
