import React, { FC } from 'react';
import styled from 'styled-components';

interface ITitleProps {
  children: string
}
const Title: FC<ITitleProps> = ({ children }) => {
  return (
    <H1>{children}</H1>
  );
};
const H1 = styled.h1`
  font-size: 1.5rem;
  text-align: center;
`;
export default Title;