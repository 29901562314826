import { darkBorder, lightBackgroundBlue, popUpBackground, primary } from 'library/colors';
import { ICreateDatabaseSchema, IDatabase, IDatabaseRow, IUpdateDatabase } from 'library/interfaces/databasesInterfaces';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import DatabaseColumn from '../organisms/column';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { putUpdateDatabase, updateRow } from 'store/databases/asyncActions';
import RowKey from '../organisms/rowKey';

interface IModifyColumnsFormProps {
  row: IDatabaseRow
  rowIndex: number,
  currentDb?: IDatabase
  setSelectedRow: (row: IDatabaseRow | null, index: number) => void
}
const ModifyRowForm: FC<IModifyColumnsFormProps> = ({ row, setSelectedRow, currentDb, rowIndex }) => {
  const dispath = useAppDispatch()
  const [editableRow, setEditableRow] = useState<IDatabaseRow>({})

  const rowToRender = Object.keys(row)

  const handleUpdateRow = (key: string, value: string | number) => {
    setEditableRow(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const handleAddColumn = () => {
    // const newColumn: ICreateDatabaseSchema = {
    //   key: 'Nueva Columna',
    //   name: 'Nueva Columna',
    //   order: editableSchema.length,
    //   type: 'S'
    // }
    // setEditableSchema(prev => [...prev, newColumn].map((item, index) => {
    //   return { ...item, order: index }
    // }))
  }

  const handleDeleteColumn = (index: number) => {
    // setEditableSchema(prev => prev.filter((item, ind) => ind !== index))
  }

  const handleUpdaterow = () => {
    if (!currentDb) return
    dispath(updateRow({ dbId: currentDb.id, index: rowIndex, row: editableRow }))
    setSelectedRow(null, 0)
  }

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton' ||
      event.target.id === 'cancelButton'
    ) {
      return setSelectedRow(null, 0);
    }
  };

  useEffect(() => {
    setEditableRow(row)
  }, [row])
  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton" onClick={e => handleExit(e)}>
          x
        </Exit>
        <HeaderCont>
          Modificar Datos
        </HeaderCont>
        <BodyCont>
          {rowToRender.map((key, index) => {
            if (key === 'id' || key === 'updated_at' || key === 'created_at') return
            return (
              <RowKey
                key={index}
                index={index}
                rowObj={{ [key]: editableRow[key] }}
                handleUpdateRow={handleUpdateRow}
              />
            )
          })}
          {/* <AddResponseButton onClick={() => handleAddColumn()}>
            <i className="bx bxs-plus-circle" /> <span>Añadir columna</span>
          </AddResponseButton> */}
        </BodyCont>
        <ButtonsCont>
          <CancelButton id='cancelButton' onClick={e => handleExit(e)} isDisabled={false}>Cancelar</CancelButton>
          <SaveButton isDisabled={false} onClick={() => handleUpdaterow()}>Guardar</SaveButton>
        </ButtonsCont>
      </FormContainer>
    </PopUpContainer>

  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.form`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 500px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);

  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const HeaderCont = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: center;
  grid-column: 1 / -1;
  background-color: ${lightBackgroundBlue};
`;
const BodyCont = styled.div`
height: 320px;
  border-radius: 10px;
  padding: 20px;
  background-color: ${lightBackgroundBlue};
  margin-bottom: 20px;

  overflow-y: scroll;

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(123, 153, 4, 0.3);
  border-radius: 10px;
}

&:hover {
  ::-webkit-scrollbar-thumb {
    background-color: ${primary};
  }
}
`;
const AddResponseButton = styled.div`
  color: ${primary};
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > span {
    margin-left: 5px;
  }
  & > i {
    font-size: 1rem;
  }
`;
const ButtonsCont = styled.div`
height: 60px;
  padding: 0px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  grid-column: 1 / -1;
  background-color: ${lightBackgroundBlue};
`;
const SaveButton = styled.div<ISaveButton>`
  color: white;
  font-size: 1rem;
  font-weight: 700;
  width: 130px;
  border-radius: 50px;
  margin-left: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px solid ${props => props.isDisabled ? darkBorder : primary};
  background-color: ${props => props.isDisabled ? darkBorder : primary};
`;
const CancelButton = styled(SaveButton)`
  color: black;
  font-weight: 300;
  background-color: transparent;
`;
interface ISaveButton {
  isDisabled: boolean;
}
export default ModifyRowForm;