import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import * as Fields from '../../Fields';
import FormCard from '../../FormCard';
import FormRequest from '../../FormRequest';

const validationSchema = Yup.object({
  synthLanguage: Yup.object().nullable(),
  voice: Yup.object().nullable(),
  text: Yup.string().required('required'),
});

Edit.propTypes = {
  data: PropTypes.object.isRequired,
  toggleInEdit: PropTypes.func.isRequired,
};

function Edit({ data, toggleInEdit }) {
  const onSubmit = values => {
    data.synthLanguage = values.synthLanguage;
    data.voice = values.voice;
    data.text = values.text;
    toggleInEdit();
  };

  return (
    <Formik
      initialValues={{
        synthLanguage: data?.synthLanguage || '',
        voice: data?.voice || '',
        text: data?.text || 'Hello! This is an automated information system',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {
        /* formik */ () => (
          <Form>
            <Row>
              <Col lg={12}>
                <Fields.SwitchContainer
                  name="changeCallerId"
                  label="Change Caller ID"
                >
                  <FormCard>
                    <label>Variable</label>
                    <Fields.Select2
                      name="variable"
                      options={[
                        { label: '{{UTC}}', value: '{{UTC}}' },
                        { label: '{{asr_input}}', value: '{{asr_input}}' },
                        { label: '{{btn_input}}', value: '{{btn_input}}' },
                        { label: '{{phone}}', value: '{{phone}}' },
                      ]}
                    />
                  </FormCard>
                </Fields.SwitchContainer>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Fields.SwitchContainer
                  name="useVoiceMailDetection"
                  label="Use voice mail detection"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Fields.SwitchContainer
                  name="makeHttpRequestAfterCompletion"
                  label="Make an HTTP Request after escenario completion"
                >
                  <FormRequest />
                </Fields.SwitchContainer>
              </Col>
            </Row>

            <FormCard title="Dialing Control">
              <label>Dialing Time in seconds</label>
              <Fields.NumberInput name="dialingTime" postfix="sec" />
            </FormCard>

            <div className="mt-3">
              <button type="submit" className="btn btn-primary btn-sm">
                Save
              </button>
              <button className="btn btn-default btn-sm" onClick={toggleInEdit}>
                Cancel
              </button>
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

export default Edit;
