import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useField, ErrorMessage } from 'formik';

Select2.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function Select2({ name, options, ...props }) {
  const [field] = useField(name);
  const { onChange, ...rest } = field;

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  return (
    <>
      <Select
        classNamePrefix="select2-selection"
        options={options}
        onChange={obj => onChange(convertToDefEventPara(name, obj))}
        {...rest}
        {...props}
      />
      <ErrorMessage name={name} component="span" />
    </>
  );
}
