import React, { FC } from 'react';
import styled from 'styled-components';

import PhoneTransferCall from 'components/WorkSpace/molecules/phoneTransferCall';
import { darkBorder } from 'library/colors';

interface IPhoneTransferCall {
  isTransferCall: boolean;
  dialedNumber: string;
  onHangUp: Function;
  transfer: Function;
}
const PhoneTransferCallFloat: FC<IPhoneTransferCall> = ({
  isTransferCall,
  dialedNumber,
  onHangUp,
  transfer,
}) => {
  return (
    <Cont>
      <PhoneTransferCall
        isTransferCall={isTransferCall}
        dialedNumber={dialedNumber}
        onHangUp={onHangUp}
        transfer={transfer}
      />
    </Cont>
  );
};
const Cont = styled.div`
  position: absolute;
  width: 300px;
  top: 80%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 10px;
  box-shadow: 
    0 3px 5px ${darkBorder},
    0 3px 10px ${darkBorder},
    0 3px 10px ${darkBorder},
    0 3px 15px ${darkBorder};
`;
export default PhoneTransferCallFloat;
