import React from 'react';
import Paper from '../foundation/Paper';
import PropTypes from 'prop-types';

function SceneryItem({ text, Icon }) {
  return (
    <Paper
      className="scenery-gallery-item"
      style={{ padding: '16px', minWidth: '10rem' }}
    >
      <Icon width={32} height={32} />
      {text}
    </Paper>
  );
}

SceneryItem.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.any.isRequired,
};

export default SceneryItem;
