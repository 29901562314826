import React from 'react';
import Divider from '../Divider';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';

function InternalSidebarItem({ Icon, text, onClick, divider = false }) {
  return (
    <>
      {divider && <Divider />}

      <NavItem>
        <NavLink onClick={onClick}>
          <Icon width={20} height={20} /> {text + ' '}
        </NavLink>
      </NavItem>
    </>
  );
}

InternalSidebarItem.propTypes = {
  Icon: PropTypes.any,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  divider: PropTypes.bool,
};

export default InternalSidebarItem;
