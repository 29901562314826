import {
  IEmail,
  IEmailFilters,
  IEventFromBotData,
  ITotalCallsByOrigin,
  IWhatsappUsage,
  IWhatsappUsageCounter,
} from 'library/interfaces/reportsInterfaces';
import { IError, IReportData, IReportType } from './reducer';

export const GET_REPORT_TYPES = 'GET_REPORT_TYPES';
export const GET_REPORT_TYPES_SUCCESS = 'GET_REPORT_TYPES_SUCCESS';
export const GET_REPORT_TYPES_FAIL = 'GET_REPORT_TYPES_FAIL';

export const GET_REPORT_TOTAL_CALLS_BY_ORIGIN =
  'GET_REPORT_TOTAL_CALLS_BY_ORIGIN';
export const GET_REPORT_TOTAL_CALLS_BY_ORIGIN_SUCCESS =
  'GET_REPORT_TOTAL_CALLS_BY_ORIGIN_SUCCESS';
export const GET_REPORT_TOTAL_CALLS_BY_ORIGIN_FAIL =
  'GET_REPORT_TOTAL_CALLS_BY_ORIGIN_FAIL';

export const REQUEST_REPORT_TOTAL_CALLS_BY_STATUS =
  'REQUEST_REPORT_TOTAL_CALLS_BY_STATUS';
export const REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS =
  'REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS';
export const REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_FAIL =
  'REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_FAIL';

export const GET_REPORT_TOTAL_CALLS_BY_STATUS =
  'GET_REPORT_TOTAL_CALLS_BY_STATUS';
export const GET_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS =
  'GET_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS';
export const GET_REPORT_TOTAL_CALLS_BY_STATUS_FAIL =
  'GET_REPORT_TOTAL_CALLS_BY_STATUS_FAIL';

export const REQUEST_REPORT_CALLS_DETAIL_EXCEL =
  'REQUEST_REPORT_CALLS_DETAIL_EXCEL';
export const REQUEST_REPORT_CALLS_DETAIL_EXCEL_SUCCESS =
  'REQUEST_REPORT_CALLS_DETAIL_EXCEL_SUCCESS';
export const REQUEST_REPORT_CALLS_DETAIL_EXCEL_FAIL =
  'REQUEST_REPORT_CALLS_DETAIL_EXCEL_FAIL';

export const GET_REPORT_CALLS_DETAIL_EXCEL = 'GET_REPORT_CALLS_DETAIL_EXCEL';
export const GET_REPORT_CALLS_DETAIL_EXCEL_SUCCESS =
  'GET_REPORT_CALLS_DETAIL_EXCEL_SUCCESS';
export const GET_REPORT_CALLS_DETAIL_EXCEL_FAIL =
  'GET_REPORT_CALLS_DETAIL_EXCEL_FAIL';

export const REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL =
  'REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL';

export const REQUEST_REPORT_EVENTS_FROM_BOT_1234 =
  'REQUEST_REPORT_EVENTS_FROM_BOT_1234';
export const REQUEST_REPORT_EVENTS_FROM_BOT_1234_SUCCESS =
  'REQUEST_REPORT_EVENTS_FROM_BOT_1234_SUCCESS';
export const REQUEST_REPORT_EVENTS_FROM_BOT_1234_FAIL =
  'REQUEST_REPORT_EVENTS_FROM_BOT_1234_FAIL';

export const GET_REPORT_EVENTS_FROM_BOT_1234 =
  'GET_REPORT_EVENTS_FROM_BOT_1234';
export const GET_REPORT_EVENTS_FROM_BOT_1234_SUCCESS =
  'GET_REPORT_EVENTS_FROM_BOT_1234_SUCCESS';
export const GET_REPORT_EVENTS_FROM_BOT_1234_FAIL =
  'GET_REPORT_EVENTS_FROM_BOT_1234_FAIL';

export const REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234 =
  'REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234';

// V2

export const GET_REPORT_CALLS_DETAIL_EXCEL_V2 =
  'GET_REPORT_CALLS_DETAIL_EXCEL_V2';
export const GET_REPORT_CALLS_DETAIL_EXCEL_V2_SUCCESS =
  'GET_REPORT_CALLS_DETAIL_EXCEL_V2_SUCCESS';
export const GET_REPORT_CALLS_DETAIL_EXCEL_V2_FAIL =
  'GET_REPORT_CALLS_DETAIL_EXCEL_V2_FAIL';
export const GET_REPORT_CALLS_DETAIL_EXCEL_V2_DOWNLOAD =
  'GET_REPORT_CALLS_DETAIL_EXCEL_V2_DOWNLOAD';

export const GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2 =
  'GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2';
export const GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_SUCCESS =
  'GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_SUCCESS';
export const GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_FAIL =
  'GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_FAIL';
export const GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_DOWNLOAD =
  'GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_DOWNLOAD';

export const GET_REPORT_CALLS_SUMMARIZE_V2 = 'GET_REPORT_CALLS_SUMMARIZE_V2';
export const GET_REPORT_CALLS_SUMMARIZE_V2_SUCCESS =
  'GET_REPORT_CALLS_SUMMARIZE_V2_SUCCESS';
export const GET_REPORT_CALLS_SUMMARIZE_V2_FAIL =
  'GET_REPORT_CALLS_SUMMARIZE_V2_FAIL';
export const GET_REPORT_CALLS_SUMMARIZE_V2_DOWNLOAD =
  'GET_REPORT_CALLS_SUMMARIZE_V2_DOWNLOAD';

export const GET_REPORT_CALLS_SUMMARIZE = 'GET_REPORT_CALLS_SUMMARIZE';
export const GET_REPORT_CALLS_SUMMARIZE_SUCCESS =
  'GET_REPORT_CALLS_SUMMARIZE_SUCCESS';
export const GET_REPORT_CALLS_SUMMARIZE_FAIL =
  'GET_REPORT_CALLS_SUMMARIZE_FAIL';

//
// this actions is to get events from bot JSON to process data in front
//
export const GET_EVENTS_FROM_BOT_DATA = 'GET_EVENTS_FROM_BOT_DATA';
export const GET_EVENTS_FROM_BOT_DATA_SUCCESS =
  'GET_EVENTS_FROM_BOT_DATA_SUCCESS';
export const GET_EVENTS_FROM_BOT_DATA_FAIL = 'GET_EVENTS_FROM_BOT_DATA_FAIL';
export const UPDATE_EVENTS_FROM_BOT_DATA_COUNTER =
  'UPDATE_EVENTS_FROM_BOT_DATA_COUNTER';
export const CLEAR_EVENTS_FROM_BOT_DATA_STORE =
  'CLEAR_EVENT_FROM_BOT_DATA_STORE';

//
// Email
//
export const GET_EMAILS_BY_FILTER = 'GET_EMAILS_BY_FILTER';
export const GET_EMAILS_BY_FILTER_SUCCESS = 'GET_EMAILS_BY_FILTER_SUCCESS';
export const GET_EMAILS_BY_FILTER_FAIL = 'GET_EMAILS_BY_FILTER_FAIL';
export const CLEAR_EMAIL_BY_FILTER_STORE = 'CLEAR_EMAIL_BY_FILTER_STORE';

export const GET_EMAIL_SESSION = 'GET_EMAIL_SESSION';
export const GET_EMAIL_SESSION_SUCCESS = 'GET_EMAIL_SESSION_SUCCESS';
export const GET_EMAIL_SESSION_FAIL = 'GET_EMAIL_SESSION_FAIL';
export const CLEAR_EMAIL_SESSION_STORE = 'CLEAR_EMAIL_SESSION_STORE';

//
// whatsapp usage
//
export const GET_WHATSAPP_USAGE = 'GET_WHATSAPP_USAGE';
export const GET_WHATSAPP_USAGE_SUCCESS = 'GET_WHATSAPP_USAGE_SUCCESS';
export const GET_WHATSAPP_USAGE_FAIL = 'GET_WHATSAPP_USAGE_FAIL';
export const CLEAR_WHATSAPP_USAGE_STORE = 'CLEAR_WHATSAPP_USAGE_STORE';

export type ReportTypes =
  | IGetReportTypes
  | IGetReportTypesSuccess
  | IGetReportTypesFail
  | IGetReportTotalCallsByOrigin
  | IGetReportTotalCallsByOriginSuccess
  | IGetReportTotalCallsByOriginFail
  | IRequestReport
  | IRequestReportSuccess
  | IRequestReportFail
  | IGetReport_V2
  | IGetReportSuccess
  | IGetReportFail
  | IReportDownloaded
  | IGetEventsFromBotData
  | IGetEventsFromBotDataSuccess
  | IGetEventsFromBotDataFail
  | IUpdateEventsFromBotDataCounter
  | IClearEventsFromBotDataStore
  | IGetEmailByFilters
  | IGetEmailByFiltersSuccess
  | IGetEmailByFiltersFail
  | IClearEmailByFilterStore
  | IGetEmailSession
  | IGetEmailSessionSuccess
  | IGetEmailSessionFail
  | IClearEmailSessionStore
  | IGetWhatsappUsage
  | IGetWhatsappUsageSuccess
  | IGetWhatsappUsageFail
  | IClearWhatsappUsageStore;

export type typeRequestReport =
  | typeof REQUEST_REPORT_TOTAL_CALLS_BY_STATUS
  | typeof REQUEST_REPORT_CALLS_DETAIL_EXCEL
  | typeof REQUEST_REPORT_EVENTS_FROM_BOT_1234;

export type typeRequestReportSuccess =
  | typeof REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS
  | typeof REQUEST_REPORT_CALLS_DETAIL_EXCEL_SUCCESS
  | typeof REQUEST_REPORT_EVENTS_FROM_BOT_1234_SUCCESS;

export type typeRequestReportFail =
  | typeof REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_FAIL
  | typeof REQUEST_REPORT_CALLS_DETAIL_EXCEL_FAIL
  | typeof REQUEST_REPORT_EVENTS_FROM_BOT_1234_FAIL;

export type typeGetReportActions =
  | typeof GET_REPORT_TOTAL_CALLS_BY_STATUS
  | typeof GET_REPORT_CALLS_DETAIL_EXCEL
  | typeof GET_REPORT_EVENTS_FROM_BOT_1234
  | typeof GET_REPORT_CALLS_SUMMARIZE;

// V2
export type typeGetReportActions_V2 =
  | typeof GET_REPORT_CALLS_DETAIL_EXCEL_V2
  | typeof GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2
  | typeof GET_REPORT_CALLS_SUMMARIZE_V2;

export type typeGetReportSuccess =
  | typeof GET_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS
  | typeof GET_REPORT_CALLS_DETAIL_EXCEL_SUCCESS
  | typeof GET_REPORT_CALLS_DETAIL_EXCEL_V2_SUCCESS // V2
  | typeof GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_SUCCESS // v2
  | typeof GET_REPORT_EVENTS_FROM_BOT_1234_SUCCESS
  | typeof GET_REPORT_CALLS_SUMMARIZE_V2_SUCCESS // v2
  | typeof GET_REPORT_CALLS_SUMMARIZE_SUCCESS;

export type typeGetReportFail =
  | typeof GET_REPORT_TOTAL_CALLS_BY_STATUS_FAIL
  | typeof GET_REPORT_CALLS_DETAIL_EXCEL_FAIL
  | typeof GET_REPORT_CALLS_DETAIL_EXCEL_V2_FAIL // V2
  | typeof GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_FAIL // V2
  | typeof GET_REPORT_EVENTS_FROM_BOT_1234_FAIL
  | typeof GET_REPORT_CALLS_SUMMARIZE_V2_FAIL // V2
  | typeof GET_REPORT_CALLS_SUMMARIZE_FAIL;

export type typeReportDownloaded =
  | typeof REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL
  | typeof REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234
  | typeof GET_REPORT_CALLS_DETAIL_EXCEL_V2_DOWNLOAD
  | typeof GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_DOWNLOAD
  | typeof GET_REPORT_CALLS_SUMMARIZE_V2_DOWNLOAD;

export interface IGetReportTypes {
  type: typeof GET_REPORT_TYPES;
  payload: { owner: string };
}

export interface IGetReportTypesSuccess {
  type: typeof GET_REPORT_TYPES_SUCCESS;
  payload: { reportTypes: IReportType[] };
}

export interface IGetReportTypesFail {
  type: typeof GET_REPORT_TYPES_FAIL;
  payload: { error: IError };
}

export interface IGetReportTotalCallsByOrigin {
  type: typeof GET_REPORT_TOTAL_CALLS_BY_ORIGIN;
  payload: {
    owner: string;
    start_date: string;
    end_date: string;
    exten?: string;
  };
}

export interface IGetReportTotalCallsByOriginSuccess {
  type: typeof GET_REPORT_TOTAL_CALLS_BY_ORIGIN_SUCCESS;
  payload: { counters: ITotalCallsByOrigin };
}

export interface IGetReportTotalCallsByOriginFail {
  type: typeof GET_REPORT_TOTAL_CALLS_BY_ORIGIN_FAIL;
  payload: { error: IError };
}

export interface IRequestReport {
  type: typeRequestReport;
  payload: {
    owner: string;
    start_date: string;
    end_date: string;
    reportTypeId: string;
    action: typeRequestReport;
  };
}

export interface IRequestReportSuccess {
  type: typeRequestReportSuccess;
  payload: { requestedReportId: string };
}

export interface IRequestReportFail {
  type: typeRequestReportFail;
  payload: { error: IError };
}

export interface IGetReport {
  type: typeGetReportActions;
  payload: {
    owner: string;
    requestedReportId: string;
    action: typeGetReportActions;
  };
}

export interface IGetReport_V2 {
  type: typeGetReportActions_V2;
  payload: {
    owner: string;
    start_date: string;
    end_date: string;
    reportTypeId: string;
    action: typeGetReportActions_V2;
    filters?: string;
  };
}

export interface IGetReportSuccess {
  type: typeGetReportSuccess;
  payload: { data: IReportData };
}

export interface IGetReportFail {
  type: typeGetReportFail;
  payload: { error: IError };
}

export interface IReportDownloaded {
  type: typeReportDownloaded;
}

export interface IGetEventsFromBotData {
  type: typeof GET_EVENTS_FROM_BOT_DATA;
  payload: {
    exten: string;
    start: string;
    end: string;
    limit?: number;
  };
}

export interface IGetEventsFromBotDataSuccess {
  type: typeof GET_EVENTS_FROM_BOT_DATA_SUCCESS;
  payload: {
    events: IEventFromBotData[];
    solvedRate: number | null;
    callMinuteTranscript: { [key: string]: number };
    totalMinuteTranscript: number;
  };
}

export interface IGetEventsFromBotDataFail {
  type: typeof GET_EVENTS_FROM_BOT_DATA_FAIL;
  payload: { error: IError };
}

export interface IUpdateEventsFromBotDataCounter {
  type: typeof UPDATE_EVENTS_FROM_BOT_DATA_COUNTER;
  payload: {
    counter: { [key: string]: number };
    colors: { [key: string]: string };
  };
}

export interface IClearEventsFromBotDataStore {
  type: typeof CLEAR_EVENTS_FROM_BOT_DATA_STORE;
}

export interface IGetEmailByFilters {
  type: typeof GET_EMAILS_BY_FILTER;
  payload: {
    lastKey: string | null;
    order: string;
    date: { start: string; end: string };
    filter?: IEmailFilters;
  };
}

export interface IGetEmailByFiltersSuccess {
  type: typeof GET_EMAILS_BY_FILTER_SUCCESS;
  payload: { lastKey: string | null; rows: IEmail[] };
}

export interface IGetEmailByFiltersFail {
  type: typeof GET_EMAILS_BY_FILTER_FAIL;
  payload: { error: IError };
}

export interface IClearEmailByFilterStore {
  type: typeof CLEAR_EMAIL_BY_FILTER_STORE;
}

export interface IGetEmailSession {
  type: typeof GET_EMAIL_SESSION;
  payload: { sessionId: string };
}

export interface IGetEmailSessionSuccess {
  type: typeof GET_EMAIL_SESSION_SUCCESS;
  payload: { rows: IEmail[] };
}

export interface IGetEmailSessionFail {
  type: typeof GET_EMAIL_SESSION_FAIL;
  payload: { error: IError };
}

export interface IClearEmailSessionStore {
  type: typeof CLEAR_EMAIL_SESSION_STORE;
}

export interface IGetWhatsappUsage {
  type: typeof GET_WHATSAPP_USAGE;
  payload: { appId: string; from: string; to: string };
}

export interface IGetWhatsappUsageSuccess {
  type: typeof GET_WHATSAPP_USAGE_SUCCESS;
  payload: {
    partnerAppUsageList: IWhatsappUsage[];
    counter: IWhatsappUsageCounter;
  };
}

export interface IGetWhatsappUsageFail {
  type: typeof GET_WHATSAPP_USAGE_FAIL;
  payload: { error: IError };
}

export interface IClearWhatsappUsageStore {
  type: typeof CLEAR_WHATSAPP_USAGE_STORE;
}
