import * as React from 'react';

export const FolderAdd = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 1163.8h2062.5V0H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="matrix(5.358 0 0 -5.358 -2025.1 3422.2)">
      <path
        d="M397.66 618.88h.001c.372 0 .706.235.831.585l3.163 8.815a.869.869 0 0 1-.109.806.872.872 0 0 1-.721.376l-17.863.019h-.001a.886.886 0 0 1-.832-.585l-3.162-8.815a.872.872 0 0 1 .108-.806.871.871 0 0 1 .722-.375zm.001-.949h-.002l-17.863.02a1.837 1.837 0 0 0-1.497.778 1.834 1.834 0 0 0-.225 1.672l3.162 8.815a1.838 1.838 0 0 0 1.725 1.214h.002l17.863-.02a1.833 1.833 0 0 0 1.722-2.45l-3.163-8.815a1.836 1.836 0 0 0-1.724-1.214"
        fill="#333"
      />
      <path
        d="m378.91 619.74-.949.001.015 13.197c.001.489.192.949.538 1.295.346.345.806.535 1.294.535h.002l4.474-.006a1.82 1.82 0 0 0 1.26-.504l.612-.581a.878.878 0 0 1 .608-.243l11.918-.013a1.825 1.825 0 0 0 1.295-.538c.345-.347.535-.807.535-1.296l-.002-1.651-.949.001.002 1.651a.877.877 0 0 1-.258.625.874.874 0 0 1-.624.259l-11.918.013a1.82 1.82 0 0 0-1.26.504l-.613.582a.878.878 0 0 1-.607.242l-4.474.006h-.001a.88.88 0 0 1-.883-.882z"
        fill="#333"
      />
      <path d="M393.29 623.71h-5.952v.949h5.952z" fill="#333" />
      <path d="M390.78 621.21h-.949v5.952h.949z" fill="#333" />
    </g>
  </svg>
);
