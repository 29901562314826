import React from 'react';
import PropTypes from 'prop-types';

import * as Fields from '../../../../Fields';
import { Col } from 'reactstrap';

Port.propTypes = {
  name: PropTypes.string.isRequired,
  lg: PropTypes.number,
};

export function Port({ name, lg = 12 }) {
  return (
    <Col lg={lg}>
      <label>Port</label>
      <Fields.TextInput name={name} />
    </Col>
  );
}
