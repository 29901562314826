import { IChannel } from 'library/interfaces/channelsInterfaces';
import {
  GET_CHANNELS,
  GET_CHANNELS_FAIL,
  GET_CHANNELS_SUCCESS,
  IGetChannels,
  IGetChannelsFail,
  IGetChannelsSuccess,
  IUpdateChannelScenery,
  IUpdateChannelSceneryFail,
  IUpdateChannelScenerySuccess,
  UPDATE_CHANNEL_SCENERY,
  UPDATE_CHANNEL_SCENERY_FAIL,
  UPDATE_CHANNEL_SCENERY_SUCCESS,
} from './actionTypes';
import { IError } from 'store/reports/reducer';

export const getChannelsAction = (): IGetChannels => ({
  type: GET_CHANNELS,
});

export const getChannelsSuccess = (
  channels: IChannel[],
): IGetChannelsSuccess => ({
  type: GET_CHANNELS_SUCCESS,
  payload: { channels },
});

export const getChannelsFail = (error: IError): IGetChannelsFail => ({
  type: GET_CHANNELS_FAIL,
  payload: { error },
});

export const updateChannelSceneryAction = (
  channelId: string,
  sceneryId: string,
): IUpdateChannelScenery => ({
  type: UPDATE_CHANNEL_SCENERY,
  payload: { channelId, sceneryId },
});

export const updateChannelScenerySuccess = (
  channelId: string,
  sceneryId: string,
): IUpdateChannelScenerySuccess => ({
  type: UPDATE_CHANNEL_SCENERY_SUCCESS,
  payload: { channelId, sceneryId },
});

export const updateChannelSceneryFail = (
  error: IError,
): IUpdateChannelSceneryFail => ({
  type: UPDATE_CHANNEL_SCENERY_FAIL,
  payload: { error },
});
