import { IIndicatorProps } from 'components/Report/atoms/indicatorLinked';
import { danger, primary } from 'library/colors';
import { ICallsCounter } from 'library/interfaces/reportsInterfaces';

export const indicatorsToRender_callDetailsExcel = (
  callStatus?: ICallsCounter,
): IIndicatorProps[] => {
  const answered = callStatus?.answered;
  const notAnswered = callStatus?.noAnswered;
  const abandom = callStatus?.abandom;
  const busy = callStatus?.busy;
  const failed = callStatus?.failed;
  const total = callStatus?.count;

  const indicators = [
    {
      label: 'answered',
      value: answered,
      labelBackColor: primary,
    },
    {
      label: 'not answered',
      value: notAnswered,
      labelBackColor: 'purple',
    },
    {
      label: 'failed',
      value: failed,
      labelBackColor: 'orange',
    },
    {
      label: 'busy',
      value: busy,
      labelBackColor: 'lightblue',
    },
    {
      label: 'abandon',
      value: abandom,
      labelBackColor: danger,
    },
    {
      label: 'total',
      value: total,
    },
  ];
  return indicators;
};

export const graphDataToRender_callDetailsExcel = (
  callStatus?: ICallsCounter,
) => {
  const answered = callStatus?.answered || 0;
  const notAnswered = callStatus?.unanswered || 0;
  const abandom = callStatus?.abandom || 0;
  const busy = callStatus?.busy || 0;
  const failed = callStatus?.failed || 0;

  const graphData = [
    {
      label: 'Answered',
      value: answered,
      color: primary,
    },
    {
      label: 'Not answered',
      value: notAnswered,
      color: 'purple',
    },
    {
      label: 'Failed',
      value: failed,
      color: 'orange',
    },
    {
      label: 'Busy',
      value: busy,
      color: 'lightblue',
    },
    {
      label: 'Abandon',
      value: abandom,
      color: danger,
    },
  ];

  return graphData;
};
