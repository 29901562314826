import React, { FC, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import AnimatedArrowToShowMenu from './animatedArrowToShowMenu';
import SetLanguage from './setLanguage';
import SetNotifications from './setNotifications';
import { useDispatch } from 'react-redux';
import { Phone } from 'library/phone/phone';

interface IProfileMenuOptionsProps {
  show: boolean;
  isSupervisor: boolean
  phone?: Phone
}
const ProfileMenuOptions: FC<IProfileMenuOptionsProps> = ({ show, isSupervisor, phone }) => {
  const { t } = useTranslation('header');
  const { logout } = useAuth0();
  const dispatch = useDispatch()

  const [showOptions, setShowOptions] = useState({
    languages: false,
    notifications: false,
  });

  const handleOptionClick = (option: 'languages' | 'notifications') => {
    setShowOptions({
      ...showOptions,
      [option]: !showOptions[option],
    });
  };
  // old function to hotfix
  const onLogout = () => {
    localStorage.removeItem("authUser")
    logout();
    console.log('LOGOUT')
  }

  // new function to develop
  // 
  // const onLogout = () => {
  //   localStorage.removeItem('authUser');
  //   localStorage.removeItem('auth0_token');
  //   logout();
  //   dispatch(deleteAuthUserData())
  // };

  return (
    <Cont show={show}>
      <OptionCont>
        <Option>
          <Icon className="bx bx-user font-size-16 align-middle me-1" />
          <span>{t('profileMenu.profile')}</span>
        </Option>
      </OptionCont>
      <OptionCont showOptions={showOptions.languages} heightOnShow={'70px'}>
        <Option onClick={() => handleOptionClick('languages')}>
          <Icon className="bx bx-world font-size-16 align-middle me-1" />
          <span>{t('profileMenu.language')}</span>
          <AnimatedArrowToShowMenu isUp={showOptions.languages} />
        </Option>
        <SetLanguage />
      </OptionCont>
      <OptionCont showOptions={showOptions.notifications} heightOnShow={isSupervisor ? '160px' : '130px'}>
        <Option onClick={() => handleOptionClick('notifications')}>
          <Icon className="bx bxs-bell-ring font-size-16 align-middle me-1" />
          <span>{t('profileMenu.notifications')}</span>
          <AnimatedArrowToShowMenu isUp={showOptions.notifications} />
        </Option>
        <SetNotifications isSupervisor={isSupervisor} phone={phone} />
      </OptionCont>
      <Hr />
      <OptionCont>
        <Option onClick={() => onLogout()}>
          <Icon className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>{t('profileMenu.logout')}</span>
        </Option>
      </OptionCont>
    </Cont>
  );
};
const showAnimation = keyframes`
  0%{ transform: translateY(0)}
  100%{ transform: translateY(-10px)}
`;
const Cont = styled.div<ICont>`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  z-index: 10000;
  width: 180px;
  right: 0;
  top: calc(100% + 10px);
  border-radius: 5px;
  padding: 10px 0;
  background-color: white;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 30%);
  animation: ${showAnimation} 0.3s forwards;
`;
const OptionCont = styled.div<IOption>`
  padding: 6px 0 6px 10px;
  overflow: hidden;
  cursor: pointer;
  height: ${props => (props.showOptions ? props.heightOnShow : '32px')};
  transition: height 0.3s;
`;
const Option = styled.div`
  display: flex;
  align-items: center;
`;
const Icon = styled.i``;
const Hr = styled.hr`
  margin: 5px 0 7px;
`;
interface ICont {
  show: boolean;
}
interface IOption {
  showOptions?: boolean;
  heightOnShow?: string;
}
export default ProfileMenuOptions;
