import {
  IGupshupApp,
  IGupshupAppTemplate,
  IWaCampaign,
} from 'library/interfaces/whatsappCampaignInterfaces';
import { IError } from 'store/reports/reducer';
import {
  DELETE_WA_CAMPAIGN_FAIL,
  DELETE_WA_CAMPAIGN_SUCCESS,
  GET_GUPSHUP_APP_LIST,
  GET_GUPSHUP_APP_LIST_FAIL,
  GET_GUPSHUP_APP_LIST_SUCCESS,
  GET_GUPSHUP_APP_TEMPLATES,
  GET_GUPSHUP_APP_TEMPLATES_FAIL,
  GET_GUPSHUP_APP_TEMPLATES_SUCCESS,
  GET_WA_CAMPAIGNS,
  GET_WA_CAMPAIGNS_FAIL,
  GET_WA_CAMPAIGNS_SUCCESS,
  GET_WA_CAMPAIGN_BY_ID,
  GET_WA_CAMPAIGN_BY_ID_FAIL,
  GET_WA_CAMPAIGN_BY_ID_SUCCESS,
  POST_WA_CAMPAIGN,
  POST_WA_CAMPAIGN_FAIL,
  POST_WA_CAMPAIGN_SUCCESS,
  POST_WA_CONTACTS_FINISHED,
  POST_WA_CONTACT_GROUP,
  POST_WA_CONTACT_GROUP_FAIL,
  POST_WA_CONTACT_GROUP_SUCCESS,
  PUT_RUN_WA_CAMPAIGN,
  PUT_RUN_WA_CAMPAIGN_FAIL,
  PUT_RUN_WA_CAMPAIGN_SUCCESS,
  PUT_STOP_WA_CAMPAIGN,
  PUT_STOP_WA_CAMPAIGN_FAIL,
  PUT_STOP_WA_CAMPAIGN_SUCCESS,
  PUT_WA_CAMPAIGN_COLOR_FAIL,
  PUT_WA_CAMPAIGN_COLOR_SUCCESS,
  WACampaignsActionTypes,
} from './actionTypes';

export interface IAppList {
  isLoading: boolean;
  error: IError | null;
  list: IGupshupApp[];
}
export interface IGupshupAppTemplatesStore {
  isLoading: boolean;
  error: IError | null;
  templates: IGupshupAppTemplate[];
}
export interface IWACampaignStore {
  isLoading: boolean;
  error: IError | null;
  campaigns: IWaCampaign[];
  appList: IAppList;
  templates: IGupshupAppTemplatesStore;
  postContactsOf: IWaCampaign[] | undefined;
  isCreatingWACampaign: boolean | undefined;
}

const INIT_STATE: IWACampaignStore = {
  isLoading: false,
  error: null,
  campaigns: [],
  appList: {
    isLoading: false,
    error: null,
    list: [],
  },
  templates: {
    isLoading: false,
    error: null,
    templates: [],
  },
  postContactsOf: undefined,
  isCreatingWACampaign: undefined,
};

const waCampaigns = (
  state = INIT_STATE,
  action: WACampaignsActionTypes,
): IWACampaignStore => {
  switch (action.type) {
    case GET_WA_CAMPAIGNS: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_WA_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        campaigns: action.payload.waCampaigns,
      };
    }
    case GET_WA_CAMPAIGN_BY_ID:
    case PUT_RUN_WA_CAMPAIGN:
    case PUT_STOP_WA_CAMPAIGN: {
      const { waCampaignId } = action.payload;
      const campaigns = structuredClone(state.campaigns);

      const index = campaigns.findIndex(
        campaign => campaign.id === waCampaignId,
      );

      if (index !== -1) {
        campaigns[index].isLoading = true;
      }
      return {
        ...state,
        error: null,
        campaigns: [...campaigns],
      };
    }
    case GET_WA_CAMPAIGN_BY_ID_SUCCESS:
    case PUT_RUN_WA_CAMPAIGN_SUCCESS:
    case PUT_STOP_WA_CAMPAIGN_SUCCESS:
    case DELETE_WA_CAMPAIGN_SUCCESS:
    case PUT_WA_CAMPAIGN_COLOR_SUCCESS: {
      const { waCampaignId, waCampaign } = action.payload;
      const campaigns = structuredClone(state.campaigns);

      const index = campaigns.findIndex(
        campaign => campaign.id === waCampaignId,
      );

      if (index !== -1) {
        campaigns[index] = waCampaign;
      }
      return {
        ...state,
        error: null,
        campaigns: [...campaigns],
      };
    }
    case GET_WA_CAMPAIGN_BY_ID_FAIL:
    case PUT_RUN_WA_CAMPAIGN_FAIL:
    case PUT_STOP_WA_CAMPAIGN_FAIL:
    case DELETE_WA_CAMPAIGN_FAIL:
    case PUT_WA_CAMPAIGN_COLOR_FAIL: {
      const { waCampaignId, error } = action.payload;
      const campaigns = structuredClone(state.campaigns);

      const index = campaigns.findIndex(
        campaign => campaign.id === waCampaignId,
      );

      if (index !== -1) {
        campaigns[index].isLoading = undefined;
      }
      return {
        ...state,
        campaigns: [...campaigns],
        error: error,
      };
    }
    case POST_WA_CAMPAIGN: {
      return {
        ...state,
        isCreatingWACampaign: true,
        error: null,
      };
    }
    case POST_WA_CAMPAIGN_SUCCESS: {
      const newWACampaign = action.payload.waCampaign;
      const postContactsOf: IWaCampaign[] = state.postContactsOf
        ? structuredClone(state.postContactsOf)
        : [];
      return {
        ...state,
        isCreatingWACampaign: false,
        error: null,
        postContactsOf: [newWACampaign, ...postContactsOf],
      };
    }
    case POST_WA_CONTACT_GROUP: {
      const { waCampaignId } = action.payload;
      const postContactsOf: IWaCampaign[] = state.postContactsOf
        ? structuredClone(state.postContactsOf)
        : [];

      const index = postContactsOf.findIndex(
        campaign => campaign.id === waCampaignId,
      );

      if (index !== -1) {
        postContactsOf[index].contactLoad = {
          ...postContactsOf[index].contactLoad,
          isLoading: true,
          loadPercentage: 0,
          postContactsGroupNumberOfError: [],
        };
      }
      return {
        ...state,
        isCreatingWACampaign: undefined,
        postContactsOf: [...postContactsOf],
      };
    }
    case POST_WA_CONTACT_GROUP_SUCCESS: {
      const { waCampaignId, loadPercentage } = action.payload;
      const postContactsOf: IWaCampaign[] = state.postContactsOf
        ? structuredClone(state.postContactsOf)
        : [];

      const index = postContactsOf.findIndex(
        campaign => campaign.id === waCampaignId,
      );

      if (index !== -1) {
        const currentGroupsError =
          postContactsOf[index].contactLoad?.postContactsGroupNumberOfError ||
          [];

        postContactsOf[index].contactLoad = {
          ...postContactsOf[index].contactLoad,
          isLoading: true,
          loadPercentage: loadPercentage,
          postContactsGroupNumberOfError: [...currentGroupsError],
        };
      }
      return {
        ...state,
        postContactsOf: [...postContactsOf],
      };
    }
    case POST_WA_CONTACT_GROUP_FAIL: {
      const { waCampaignId, loadPercentage, contactGroupOfError } =
        action.payload;
      const postContactsOf: IWaCampaign[] = state.postContactsOf
        ? structuredClone(state.postContactsOf)
        : [];

      const index = postContactsOf.findIndex(
        campaign => campaign.id === waCampaignId,
      );

      if (index !== -1) {
        const currentGroupsError =
          postContactsOf[index].contactLoad?.postContactsGroupNumberOfError ||
          [];

        postContactsOf[index].contactLoad = {
          ...postContactsOf[index].contactLoad,
          isLoading: true,
          loadPercentage: loadPercentage,
          postContactsGroupNumberOfError: [
            ...currentGroupsError,
            contactGroupOfError,
          ],
        };
      }
      return {
        ...state,
        postContactsOf: [...postContactsOf],
      };
    }
    case POST_WA_CONTACTS_FINISHED: {
      const { waCampaignId } = action.payload;
      let completedWACampaign: IWaCampaign[] = [];
      const postContactsOf: IWaCampaign[] = state.postContactsOf
        ? structuredClone(state.postContactsOf)
        : [];

      const index = postContactsOf.findIndex(
        campaign => campaign.id === waCampaignId,
      );

      if (index !== -1) {
        const currentGroupsError =
          postContactsOf[index].contactLoad?.postContactsGroupNumberOfError ||
          [];

        if (currentGroupsError.length > 0) {
          postContactsOf[index].contactLoad = {
            ...postContactsOf[index].contactLoad,
            isLoading: false,
            loadPercentage: 0,
            postContactsGroupNumberOfError: [...currentGroupsError],
          };
        } else {
          completedWACampaign = postContactsOf.splice(index, 1);
          completedWACampaign[0].contactLoad = {
            ...completedWACampaign[0].contactLoad,
            isLoading: false,
            loadPercentage: 0,
            postContactsGroupNumberOfError: [...currentGroupsError],
          };
        }
      }
      const finishPostContactsOf =
        postContactsOf.length > 0 ? postContactsOf : undefined;
      return {
        ...state,
        campaigns: [...completedWACampaign, ...state.campaigns],
        postContactsOf: finishPostContactsOf,
        isCreatingWACampaign: undefined,
      };
    }

    // errors to show on app
    case GET_WA_CAMPAIGNS_FAIL:
    case POST_WA_CAMPAIGN_FAIL: {
      return {
        ...state,
        isLoading: false,
        isCreatingWACampaign: undefined,
        error: action.payload.error,
      };
    }
    //
    // gupshup app list
    //
    case GET_GUPSHUP_APP_LIST: {
      return {
        ...state,
        appList: {
          ...state.appList,
          isLoading: true,
          error: null,
        },
      };
    }
    case GET_GUPSHUP_APP_LIST_SUCCESS: {
      return {
        ...state,
        appList: {
          ...state.appList,
          isLoading: false,
          error: null,
          list: action.payload.appList,
        },
      };
    }
    case GET_GUPSHUP_APP_LIST_FAIL: {
      return {
        ...state,
        appList: {
          ...state.appList,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    //
    // gupshup templates
    //
    case GET_GUPSHUP_APP_TEMPLATES: {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: true,
          error: null,
          templates: [],
        },
      };
    }
    case GET_GUPSHUP_APP_TEMPLATES_SUCCESS: {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: false,
          error: null,
          templates: [...action.payload.templates],
        },
      };
    }
    case GET_GUPSHUP_APP_TEMPLATES_FAIL: {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: false,
          templates: [],
          error: action.payload.error,
        },
      };
    }
    default:
      return state;
  }
};
export default waCampaigns;
