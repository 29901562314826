import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import IndicatorLinked from '../atoms/indicatorLinked';

interface IVerticalIndicatorsLinkedProps {
  indicators: {
    label: string;
    value?: number;
    labelBackColor?: string;
  }[];
  onSelectIndicator?: Function;
}
const VerticalIndicatorsLinked: FC<IVerticalIndicatorsLinkedProps> = ({
  indicators,
  onSelectIndicator,
}) => {
  const {t} = useTranslation('reports')
  return (
    <Cont>
      {indicators.map((indicator, index) => {
        const {label, value, labelBackColor} = indicator
        const isSelectable = (label !== 'total' && value && value > 0 && onSelectIndicator !== undefined)
        return (
          <IndicatorLinked
            key={index}
            label={t(`callIndicator.${label}`)}
            value={value}
            labelBackColor={labelBackColor}
            onClick={isSelectable ? onSelectIndicator : undefined}
          />
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
min-width: 120px;
  max-width: 200px;
  padding: 0 10px;
  margin-right: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;

  &>div{
    width: 100%;
  }
`;
export default VerticalIndicatorsLinked;