import { ISMSCampaign } from 'library/interfaces/smsCampaignInterface';
import IStore from 'library/interfaces/storeInterface';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SMSCampaign from './smsCampaign';

interface ISMSCampaignsLoadingContactsContProps {}
const SMSCampaignsLoadingContactsCont: FC<
  ISMSCampaignsLoadingContactsContProps
> = ({}) => {
  const postContactsOf = useSelector<IStore, ISMSCampaign[] | undefined>(
    store => store.smsCampaigns.postContactsOf,
  );

  return (
    <Cont
      display={postContactsOf && postContactsOf.length > 0 ? 'block' : 'none'}
    >
      {postContactsOf &&
        postContactsOf.map((campaign, index) => {
          return <SMSCampaign key={index} smsCampaign={campaign} />;
        })}
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  /* border: 1px solid; */
  display: ${props => props.display};
`;
interface ICont {
  display: 'block' | 'none';
}
export default SMSCampaignsLoadingContactsCont;
