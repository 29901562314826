import { ISceneryGlobals } from 'library/interfaces/scenariosInterfaces';

export const INIT_SCENERY_GLOBALS: ISceneryGlobals = {
  name: 'Nuevo Escenario',
  sources: [],
  settings: {
    lang: 'es-ES',
    sttEngine: 'microsoft',
    sttLang: 'es-ES',
    ttsEngine: 'microsoft',
    ttsGender: 'Female',
    ttsLang: 'es-UY',
    ttsVoice: 'es-UY-ValentinaNeural',
  },
};
