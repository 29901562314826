import { Completed, Draft, Megaphone, Pause, Phone } from 'icons';
import { blueGraph, darkBorder, primary } from 'library/colors';
import React, { FC } from 'react';
import { ICampaignStatus } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import styled from 'styled-components';

interface ICampaignInfoProps {
  textI18N: {
    scenario: string;
    from: string;
    to: string;
    callerId: string;
    did: string;
  };
  title: string;
  from: string;
  to: string;
  callerId: string;
  did: string;
  status: ICampaignStatus;
}
const CampaignInfo: FC<ICampaignInfoProps> = ({
  textI18N,
  title,
  from,
  to,
  status,
  callerId,
  did
}) => {
  return (
    <Cont>
      {status === 'draft' && (
        <IconCont>
          <Draft width={25} color={darkBorder} />
        </IconCont>
      )}
      {(status === 'published' ||
        (status.endsWith('ing') && status !== 'running')) && (
        <IconCont left="-45px">
          <Megaphone width={35} />
        </IconCont>
      )}
      {status === 'paused' && (
        <IconCont left="-47px">
          <Pause width={40} color={'orange'} />
        </IconCont>
      )}
      {status === 'running' && (
        <IconCont>
          <Phone width={25} color={primary} />
        </IconCont>
      )}
      {status === 'completed' && (
        <IconCont left="-45px">
          <Completed width={30} color={blueGraph} />
        </IconCont>
      )}
      <Title>{title}</Title>
      {callerId && <Scenario>{textI18N.callerId}: {callerId}</Scenario>}
      {did && <Scenario>{textI18N.did}: {did}</Scenario>}
      <Date>
        <div>
          {textI18N.from} - {from}
        </div>
        <div>
          {textI18N.to} - {to}
        </div>
      </Date>
    </Cont>
  );
};
const Cont = styled.div`
  position: relative;
`;
const IconCont = styled.div<IIconCont>`
  position: absolute;
  left: ${props => (props.left ? props.left : '-40px')};
  top: 5px;
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
`;
const Scenario = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  margin-bottom: 5px;
`;
const Date = styled.div`
  display: flex;

  & > div {
    margin-right: 20px;
    font-size: 0.75rem;
    font-weight: 500;
  }
`;
interface IIconCont {
  left?: string;
}
export default CampaignInfo;
