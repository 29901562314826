import { LiveBoard, SendSMS } from 'icons';
import { AgentGraphIcon } from 'icons/AgentGraphIcon';
import { darkBorder, darkText } from 'library/colors';
import { ISummarizeAgent } from 'library/interfaces/summarizeInterface';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import AgentServiceLevel from './agentServiceLevel';

interface IAgent {
  agent: ISummarizeAgent;
}
const Agent: FC<IAgent> = ({ agent }) => {
  const [showAgentInfo, setShowAngentInfo] = useState(false);
  const name = agent.name ? agent.name.substring(0, 18) : '';

  const STATE_COLOR =
    agent.state === 'incall'
      ? '#85ce5f'
      : agent.state === 'ringing'
      ? '#4cb3ed'
      : agent.state === 'onhold'
      ? '#c09405'
      : '#053855';

  const handleAgentGraphIconHover = (isHover = false) => {
    setShowAngentInfo(isHover);
  };
  return (
    <AgentCont>
      <FlexCont>
        <ImgCont>
          <img src={agent.picture} alt={agent.name} />
        </ImgCont>
        <Name>
          <Span circleColor={STATE_COLOR}></Span> {name}
        </Name>
      </FlexCont>
      <FlexCont>
        <Icon>
          <SendSMS width="100%" height="100%" />
        </Icon>
        <Icon
          onMouseEnter={e => handleAgentGraphIconHover(true)}
          onMouseLeave={e => handleAgentGraphIconHover()}
        >
          <AgentGraphIcon width="100%" height="100%" />
          {showAgentInfo && <AgentServiceLevel data={agent.serviceLevel} />}
        </Icon>
      </FlexCont>
    </AgentCont>
  );
};
const AgentCont = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 14px 10px 20px;
`;
const ImgCont = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;

  & > img {
    width: 100%;
  }
`;
const Name = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: dimgray;
  cursor: default;
`;
const Span = styled.span<ISpan>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${props => props.circleColor};
`;
const FlexCont = styled.div`
  display: flex;
  align-items: center;
`;
const Icon = styled.div`
  color: black;
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
`;
interface ISpan {
  circleColor?: string;
}
export default Agent;
