import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import { Input } from 'reactstrap';

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
};

export function TextArea({ name }) {
  const [field] = useField(name);
  const { onChange, ...rest } = field;

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  return (
    <>
      <Input
        type="textarea"
        {...field}
        {...rest}
        onChange={event =>
          onChange(convertToDefEventPara(name, event.target.value))
        }
      />
      <ErrorMessage name={name} component="span" />
    </>
  );
}
