import { danger, darkBorder, popUpBackground, primary } from 'library/colors';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateIsEdited } from 'store/scenariosV2/reducer';
import styled from 'styled-components';

interface IGoBackAlertProps {
  hideAlert: () => void;
}
const GoBackAlert = ({ hideAlert }: IGoBackAlertProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleExit = () => {
    dispatch(updateIsEdited(false))
    history.push('/incoming-v2')
  }
  return (
    <PopUpContainer>
      <Cont>
        <AlertText>Tienes cambios pendientes sin guardar.</AlertText>
        <ButtonCont>
          <CancelButton onClick={() => hideAlert()}>Volver para guardar</CancelButton>
          <SaveButton onClick={handleExit}>Salir de todos modos</SaveButton>
        </ButtonCont>
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1000;
  background-color: ${popUpBackground};
`;
const Cont = styled.div`
  position: fixed;
  top: 200px;
  z-index: 10;
  width: 500px;
  padding-top: 30px;
  color: black;
  overflow: hidden;
  border-radius: 20px;
  background-color: ${darkBorder};
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(215, 132, 128, 0.5);

`;
const AlertText = styled.div`
font-size: 1.2rem;
  text-align: center;
  margin-bottom: 30px;
`;
const ButtonCont = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 30px;
`;
const SaveButton = styled.button`
  align-self: right;
  border: none;
  color: white;
  font-size: 1rem;
  padding: 5px 20px;
  border-radius: 50px;
  background-color: ${danger};

`;
const CancelButton = styled(SaveButton)`
  margin-right: 20px;
  background-color: ${primary};
`;
export default GoBackAlert;