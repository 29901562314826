import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PageTitle from '../atoms/pageTitle';
import Container from '../atoms/container';
import RightBar from './rightBarTemplate';
import QueueSelectLiveBoard from '../organisms/queueSelectLiveBoard';
import { queuesChangeIdValueToUidValue } from 'library/services/liveQueuedCallsServices';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { getReportData } from 'store/actions';
import { subscribeLiveData } from 'helpers/appSyncLiveBoard';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';
import { liveBoardAppSyncService } from 'library/services/liveBoardService';
import CallsResume from '../organisms/callsResume';

const AgentCallsTemplate = () => {

  const dispatch = useAppDispatch();
  const [data, setData] = useState<ILiveDataQueue[]>([]);

  const owner = useAppSelector(store => store.authUserData.owner);
  const queues = useAppSelector(
    state => state.cdr.reportData.queues,
  );

  const dataToRender = useMemo(() => {
    return liveBoardAppSyncService(queues, data);
  }, [data, queues]);

  useEffect(() => {
    if (queues.length === 0) dispatch(getReportData());

    if (!owner) return;
    const subscription = subscribeLiveData(
      owner.toLowerCase(),
      (data: ILiveDataQueue[]) => setData(data),
    );
    return () => subscription.unsubscribe();
  }, [owner]);

  return (
    <>
      <PageTitle>Llamadas por cola</PageTitle>
      <FlexCont>
        <Container padding="20px">
          <QueueSelectLiveBoard
            mainPath="/live-board/agent-calls"
            redirectPath="/live-board/agent-calls/detailed"
            live
            queues={queues}
          />
          <Container padding="30px 30px 10px" mediumBackground>
            {dataToRender.map((item, index) => {
              return (
                <CallsResume key={index} data={item} />
              )
            })}
          </Container>
        </Container>
        <RightBar />
      </FlexCont>
    </>
  );
};
const FlexCont = styled.div`
  display: flex;
  min-height: 75vh;
  `;
const Cont = styled.div``;
export default AgentCallsTemplate;