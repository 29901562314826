import * as React from 'react';

export const Paper = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 142.43" {...props}>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_4" data-name="Capa 4">
        <path
          fill="currentColor"
          d="M92.94,142.43H15.06A15.07,15.07,0,0,1,0,127.38V26.22L27.9,0h65A15.07,15.07,0,0,1,108,15.06V127.38A15.07,15.07,0,0,1,92.94,142.43ZM6,28.82v98.56a9.06,9.06,0,0,0,9.06,9.05H92.94a9.06,9.06,0,0,0,9.06-9.05V15.06A9.06,9.06,0,0,0,92.94,6H30.28Z"
        />
        <polygon
          fill="currentColor"
          points="40.3 36.78 18.13 36.78 18.13 30.78 34.3 30.78 34.3 3 40.3 3 40.3 36.78"
        />
        <rect x="34.7" y="68.22" width="49.79" height="6" fill="currentColor" />
        <rect x="34.7" y="87.22" width="49.79" height="6" fill="currentColor" />
        <rect
          x="34.7"
          y="106.89"
          width="49.79"
          height="6"
          fill="currentColor"
        />
        <rect x="20.87" y="68.22" width="8.41" height="6" fill="currentColor" />
        <rect x="20.87" y="87.22" width="8.41" height="6" fill="currentColor" />
        <rect
          x="20.87"
          y="106.89"
          width="8.41"
          height="6"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
