import { BlueContModalSC, InputModalSC, InputVerticalGroupModalSC, LabelModalSC, SectionTitleModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';
import React, { FC } from 'react';
import { Node } from 'reactflow';
import styled from 'styled-components';

interface IEventModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
}
const EventModal: FC<IEventModalProps> = ({ selectedNode, setSelectedNode }) => {
  const { data } = selectedNode;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        event: {
          name: '',
          data: '',
          ...selectedNode.data.event,
          [name]: value
        }
      },
    };
    setSelectedNode(editedNode);
  }

  return (
    <BlueContModalSC>
      <SectionTitleModalSC>
        Evento
      </SectionTitleModalSC>
      <InputVerticalGroupModalSC>
        <LabelModalSC htmlFor="Name">Name</LabelModalSC>
        <InputModalSC
          type="text"
          id="Name"
          name="name"
          value={data?.event?.name}
          placeholder='Asigna un nombre al evento'
          onChange={e => handleInputChange(e)}
        />
      </InputVerticalGroupModalSC>
      <InputVerticalGroupModalSC>
        <LabelModalSC htmlFor="Data">Data</LabelModalSC>
        <InputModalSC
          type="text"
          id="Data"
          name="data"
          value={data?.event?.data}
          placeholder='Ingresa la data del evento'
          onChange={e => handleInputChange(e)}
        />
      </InputVerticalGroupModalSC>
    </BlueContModalSC>
  );
};
const Cont = styled.div``;
export default EventModal;