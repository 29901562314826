import React from 'react';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import ReportTemplate from 'components/Report/templates/reportTemplate';
import ReportRightBarTemplate from 'components/Report/templates/reportRightBartemplate';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const PagesStarter = () => {
  const {t} = useTranslation('reports')
  return (
    <React.Fragment>
      <div className="page-content" style={{position: 'relative'}}>
        <MetaTags>
          <title>{t('pageTitle')} | SmartBot</title>
        </MetaTags>
        <Container fluid>
          <PageTitle>{t('pageTitle')}</PageTitle>
          <FlexCont>
            <ReportTemplate />
            <ReportRightBarTemplate />
          </FlexCont>
        </Container>
      </div>
    </React.Fragment>
  );
};
const FlexCont = styled.div`
display: flex;
min-height: 75vh;
`;
export default PagesStarter;
