import * as React from "react"

export const CallToQueue = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M28.68 23.12a2.92 2.92 0 0 1-2.11-.87l-2.18-2.18a3 3 0 0 1 0-4.21l1.68-1.67a19.28 19.28 0 0 0-2.67-3.31 18.75 18.75 0 0 0-3.31-2.66l-1.67 1.67a3.06 3.06 0 0 1-4.21 0L12 7.71a3 3 0 0 1 0-4.21l1.15-1.15c1.6-1.6 4.1-2.05 7-1.28A18.54 18.54 0 0 1 28.29 6a18.45 18.45 0 0 1 4.92 8.08c.78 2.94.32 5.44-1.28 7l-1.15 1.14a2.9 2.9 0 0 1-2.1.9ZM20 6.14a.93.93 0 0 1 .46.12 20.67 20.67 0 0 1 4.24 3.32 20.94 20.94 0 0 1 3.3 4.29.91.91 0 0 1-.13 1.13l-2.18 2.18a1.15 1.15 0 0 0 0 1.61L27.87 21a1.19 1.19 0 0 0 1.61 0l1.15-1.15c1.12-1.12 1.41-3 .81-5.27A16.71 16.71 0 0 0 27 7.29a16.56 16.56 0 0 0-7.24-4.44c-2.29-.6-4.16-.32-5.28.8L13.33 4.8a1.15 1.15 0 0 0 0 1.61l2.18 2.18a1.17 1.17 0 0 0 1.61 0l2.18-2.18a.91.91 0 0 1 .7-.27ZM6.17 34.29a.87.87 0 0 1-.49-.14.91.91 0 0 1-.42-.86l.08-.83a14.07 14.07 0 0 0 .12-2.56 8.49 8.49 0 0 1-4-7.19v-11a8.45 8.45 0 0 1 3.73-7A.92.92 0 0 1 6.43 5a.93.93 0 0 1-.25 1.28 6.6 6.6 0 0 0-2.92 5.47v11a6.61 6.61 0 0 0 3.3 5.7c.82.48.84 1.65.7 3.27l.61-.35c1-.57 2.06-1.17 3.22-1.88a.9.9 0 0 1 .48-.14H22.9a6.59 6.59 0 0 0 5.52-3 .91.91 0 0 1 1.27-.35.93.93 0 0 1 .31 1.32 8.44 8.44 0 0 1-7 3.83H11.83c-1.09.67-2.09 1.23-3.05 1.78-.74.42-1.44.82-2.13 1.23a.91.91 0 0 1-.48.13Z"
    />
    <path
      fill="currentColor"
      d="M16.88 17.11H7.7a.92.92 0 0 1 0-1.84h9.18a.92.92 0 1 1 0 1.84ZM20.3 22.47H7.7a.92.92 0 0 1 0-1.84h12.6a.92.92 0 1 1 0 1.84Z"
    />
  </svg>
)

