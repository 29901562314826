import React, { FC } from 'react';
import styled from 'styled-components';

import SubTitle from '../atoms/subTitle';
import ProgressBarGraph from '../atoms/progressBarGraph';

import { lightBackground, secondary } from 'library/colors';
import { useTranslation } from 'react-i18next';

interface IOccupationRange {
  porcent: number;
  center?: React.ReactNode;
}
const OccupationRange: FC<IOccupationRange> = ({ center, porcent }) => {
  const {t} = useTranslation('liveBoard')
  return (
    <Div center={center} >
      <SubTitle center={center} sub={center ? '' : t('last24hs')}>{t('occupancyRange')}</SubTitle>
      <Graph center={center}>
        <ProgressBarGraph porcent={porcent} center={center} />
      </Graph>
    </Div>
  );
};
const Div = styled.div<IDiv>`
  flex-grow: 1;
  max-width: 250px;
  padding: ${props=>props.center ? '0 40px 20px' : '20px'};
  border-right: ${props=>props.center ? `2px solid ${secondary}` : ''};
  border-radius: ${props=> !props.center && '8px'};
  background-color: ${props=> !props.center && lightBackground};
`;
const Graph = styled.div<IDiv>`
  padding: ${props=>props.center ? '5px 0 0' : '0 20px'};
`;
interface IDiv {
  center?: React.ReactNode;
}
export default OccupationRange;
