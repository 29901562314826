import * as React from "react"

export const ContinueScenario = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >

    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M29 17.56h-3.46a3.22 3.22 0 0 1-3.22-3.21v-2.73a22.39 22.39 0 0 0-9.64 0v2.73a3.22 3.22 0 0 1-3.22 3.21H6a3.21 3.21 0 0 1-3.2-3.21v-1.81c0-5 6.46-9 14.7-9s14.7 3.93 14.7 9v1.81a3.21 3.21 0 0 1-3.2 3.21ZM17.5 9.25a23 23 0 0 1 6 .77.91.91 0 0 1 .68.88v3.45a1.38 1.38 0 0 0 1.38 1.38H29a1.38 1.38 0 0 0 1.38-1.38v-1.81c0-3.86-5.9-7.12-12.87-7.12S4.63 8.68 4.63 12.54v1.81A1.38 1.38 0 0 0 6 15.73h3.46a1.38 1.38 0 0 0 1.38-1.38V10.9a.91.91 0 0 1 .68-.88 23 23 0 0 1 5.98-.77ZM17.5 30.91a.93.93 0 0 1-.91-.93l.07-10.88a.92.92 0 0 1 .92-.91.91.91 0 0 1 .91.92L18.42 30a.92.92 0 0 1-.92.91Z"
    />
    <path
      fill="currentColor"
      d="M17.62 31.42a.91.91 0 0 1-.65-.27L12 26.22a.92.92 0 0 1 1.3-1.3l4.29 4.28 4.26-4.26a.92.92 0 1 1 1.3 1.3l-4.91 4.91a.92.92 0 0 1-.62.27Z"
    />
  </svg>
)

