import { TextAreaModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IWaTextMessageFormProps {
  textMessage: string
  handleTextMessageChange: (value: string) => void;
}
const WaTextMessageForm = ({ textMessage, handleTextMessageChange }: IWaTextMessageFormProps) => {
  return (
    <Form>
      <TextAreaModalSC
        height='150px'
        value={textMessage}
        onChange={e => handleTextMessageChange(e.target.value)}
        placeholder={`Cuerpo del mensaje. Maximo 4096 caracteres.`}
      />

    </Form>
  );
};
const Form = styled.div`
 /* border: 1px solid red; */
 /* height: 490px; */
 padding: 10px 0px;

 > input {
  margin-bottom: 5px;
 }
`;
export default WaTextMessageForm;