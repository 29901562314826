import React, { FC } from 'react';
import styled from 'styled-components';

import SubTitle from '../atoms/subTitle';
import DonutGraph from '../atoms/donutGraph';
import { secondary } from 'library/colors';
import { useTranslation } from 'react-i18next';

interface IOnLineAgents {
  data: {
    total: number;
    incall: number;
    ringing: number;
    onhold: number;
    available: number;
    unavailable: number;
    connected: number;
  }; 
  donutGraph?: React.ReactNode;
}

const OnLineAgents: FC<IOnLineAgents> = ({  donutGraph, data }) => {
  const {total, incall, ringing, onhold, available, unavailable, connected} = data
  const {t} = useTranslation('liveBoard')
  return (
    <Container donutGraph={donutGraph}>
      <SubTitle>{t('totalAgents')} &middot; {total}</SubTitle>
      <FlexCont>
        {donutGraph && <DonutGraph data={data}/>}

        <ListCont donutGraph={donutGraph}>
          <SubTitle circleColor="#85ce5f">{t('onCall')} &middot; {incall}</SubTitle>
          <SubTitle circleColor="#4cb3ed">{t('ringing')} &middot; {ringing}</SubTitle>
          <SubTitle circleColor="#c09405">{t('onHold')} &middot; {onhold}</SubTitle>
          <SubTitle circleColor="#053855">{t('available')} &middot; {available}</SubTitle>
          <SubTitle circleColor="#d78480">{t('connected')} &middot; {connected}</SubTitle>
          <SubTitle circleColor={secondary}>{t('unavailable')} &middot; {unavailable}</SubTitle>
        </ListCont>
      </FlexCont>
    </Container>
  );
};
const Container = styled.div<IListCont>`
  flex-grow: 1;
  max-width: ${props => !props.donutGraph && '195px'};
  min-width: 195px;
  padding: 0 20px;
`;
const FlexCont = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
`;
const ListCont = styled.div<IListCont>`
  margin-left: ${props => props.donutGraph && '15px'};
`;
const Span = styled.span<ISpan>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  border-radius: 50%;
  background-color: ${props => props.circleColor};
`;
interface IListCont {
  donutGraph?: React.ReactNode;
}
interface ISpan {
  circleColor: string;
}
export default OnLineAgents;
