import React, { FC } from 'react';
import styled from 'styled-components';

interface ILoadingImg {
  blur?: number;
  position?: string;
  porcent?: number;
  zIndex?: number
  width?: number
}
const LoadingImg: FC<ILoadingImg> = ({ porcent, blur = 1, position = 'absolute', zIndex = 0, width }) => {
  return (
    <Cont blur={blur} position={position} zIndex={zIndex}>
      <Img
        width={width}
        src="https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg"
        alt="Loading..."
      />
      <Number>{porcent && `${porcent}%`}</Number>
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  top: 0;
  left: 0;
  inset: 0;
  display: grid;
  place-items: center;
  backdrop-filter: blur(${props => props.blur}px);
  position: ${props => props.position};
  margin-left: ${props => props.position !== 'absolute' && '250px'};
  z-index: ${props => props.zIndex};
`;
const Img = styled.img<IImg>`
  width: ${props => props.width + 'px'};
`;
const Number = styled.div`
  position: absolute;
  font-size: 1.7rem;
`;
interface ICont {
  blur: number;
  position: string;
  zIndex: number
}
interface IImg {
  width?: number
}
export default LoadingImg;
