import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  inactiveColor,
  primary,
  lightBackground,
  lightText,
  secondary,
} from 'library/colors';

export interface IButton {
  text: string | number;
  isActive: boolean;
  value?: string;
}
interface IButtonProps {
  button: IButton;
  onClick: Function;
  index?: number;
  margin?: string;
  padding?: string;
}
const Button: FC<IButtonProps> = ({ button, onClick, index = 0, margin, padding }) => {
  const { text, isActive } = button;
  const handleClick = () =>{
    onClick(index)
  }
  return <ButtonStyled active={isActive} margin={margin} padding={padding} onClick={()=>handleClick()} >{text}</ButtonStyled>;
};
interface IButtonStyled {
  active: boolean;
  margin?: string;
  padding?: string;
}
const ButtonStyled = styled.button<IButtonStyled>`
  color: ${props => (props.active ? lightText : 'black')};
  outline: none;
  margin: ${props=>props.margin ? props.margin : '4px'};
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid ${secondary};
  background-color: ${props => (props.active ? primary : lightBackground)};
  /* box-shadow: 0 0 0 0.15rem ${props =>
    props.active ? 'rgba(144, 168, 42, 0.5)' : 'transparent'}; */
  transition: background-color .15s, color .15s, /* box-shadow 1s */;

  &:hover{
    background-color: ${props => props.active && '#698203'};
  }
`;
export default Button;
