import { ISMSCampaign } from 'library/interfaces/smsCampaignInterface';
import { IError } from 'store/reports/reducer';
import {
  GET_SMS_CAMPAIGNS,
  GET_SMS_CAMPAIGNS_FAIL,
  GET_SMS_CAMPAIGNS_SUCCESS,
  GET_SMS_CAMPAIGN_BY_ID,
  GET_SMS_CAMPAIGN_BY_ID_FAIL,
  GET_SMS_CAMPAIGN_BY_ID_SUCCESS,
  POST_SMS_CAMPAIGN,
  POST_SMS_CAMPAIGN_FAIL,
  POST_SMS_CAMPAIGN_SUCCESS,
  POST_SMS_CONTACTS_FINISHED,
  POST_SMS_CONTACT_GROUP,
  POST_SMS_CONTACT_GROUP_FAIL,
  POST_SMS_CONTACT_GROUP_SUCCESS,
  PUT_RUN_SMS_CAMPAIGN,
  PUT_RUN_SMS_CAMPAIGN_FAIL,
  PUT_RUN_SMS_CAMPAIGN_SUCCESS,
  PUT_STOP_SMS_CAMPAIGN,
  PUT_STOP_SMS_CAMPAIGN_FAIL,
  PUT_STOP_SMS_CAMPAIGN_SUCCESS,
  SMSCampaignsActionTypes,
} from './actionTypes';

export interface ISMSCampaignStore {
  isLoading: boolean;
  error: IError | null;
  campaigns: ISMSCampaign[];
  postContactsOf: ISMSCampaign[] | undefined;
  isCreatingSMSCampaign: boolean | undefined;
}

const INIT_STATE: ISMSCampaignStore = {
  isLoading: false,
  error: null,
  campaigns: [],
  postContactsOf: undefined,
  isCreatingSMSCampaign: undefined,
};

const smsCampaigns = (
  state = INIT_STATE,
  action: SMSCampaignsActionTypes,
): ISMSCampaignStore => {
  switch (action.type) {
    case GET_SMS_CAMPAIGNS: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_SMS_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        campaigns: action.payload.SMSCampaigns,
      };
    }
    case GET_SMS_CAMPAIGN_BY_ID:
    case PUT_RUN_SMS_CAMPAIGN:
    case PUT_STOP_SMS_CAMPAIGN: {
      const { SMSCampaignId } = action.payload;
      const campaigns = [...state.campaigns];

      const index = campaigns.findIndex(
        campaign => campaign.id === SMSCampaignId,
      );

      if (index !== -1) {
        campaigns[index].isLoading = true;
      }
      return {
        ...state,
        error: null,
        campaigns: [...campaigns],
      };
    }
    case GET_SMS_CAMPAIGN_BY_ID_SUCCESS:
    case PUT_RUN_SMS_CAMPAIGN_SUCCESS:
    case PUT_STOP_SMS_CAMPAIGN_SUCCESS: {
      const { SMSCampaignId, SMSCampaign } = action.payload;
      const campaigns = [...state.campaigns];

      const index = campaigns.findIndex(
        campaign => campaign.id === SMSCampaignId,
      );

      if (index !== -1) {
        campaigns[index] = SMSCampaign;
      }
      return {
        ...state,
        error: null,
        campaigns: [...campaigns],
      };
    }
    case GET_SMS_CAMPAIGN_BY_ID_FAIL:
    case PUT_RUN_SMS_CAMPAIGN_FAIL:
    case PUT_STOP_SMS_CAMPAIGN_FAIL: {
      const { SMSCampaignId, error } = action.payload;
      const campaigns = [...state.campaigns];

      const index = campaigns.findIndex(
        campaign => campaign.id === SMSCampaignId,
      );

      if (index !== -1) {
        campaigns[index].isLoading = undefined;
      }
      return {
        ...state,
        campaigns: [...campaigns],
        error: error,
      };
    }
    case POST_SMS_CAMPAIGN: {
      return {
        ...state,
        isCreatingSMSCampaign: true,
        error: null,
      };
    }
    case POST_SMS_CAMPAIGN_SUCCESS: {
      const newSMSCampaign = action.payload.SMSCampaign;
      const postContactsOf: ISMSCampaign[] = state.postContactsOf
        ? [...state.postContactsOf]
        : [];
      return {
        ...state,
        isCreatingSMSCampaign: false,
        error: null,
        postContactsOf: [newSMSCampaign, ...postContactsOf],
      };
    }
    case POST_SMS_CONTACT_GROUP: {
      const { SMSCampaignId } = action.payload;
      const postContactsOf: ISMSCampaign[] = state.postContactsOf
        ? [...state.postContactsOf]
        : [];

      const index = postContactsOf.findIndex(
        campaign => campaign.id === SMSCampaignId,
      );

      if (index !== -1) {
        postContactsOf[index].contactLoad = {
          ...postContactsOf[index].contactLoad,
          isLoading: true,
          loadPercentage: 0,
          postContactsGroupNumberOfError: [],
        };
      }
      return {
        ...state,
        isCreatingSMSCampaign: undefined,
        postContactsOf: [...postContactsOf],
      };
    }
    case POST_SMS_CONTACT_GROUP_SUCCESS: {
      const { SMSCampaignId, loadPercentage } = action.payload;
      const postContactsOf: ISMSCampaign[] = state.postContactsOf
        ? [...state.postContactsOf]
        : [];

      const index = postContactsOf.findIndex(
        campaign => campaign.id === SMSCampaignId,
      );

      if (index !== -1) {
        const currentGroupsError =
          postContactsOf[index].contactLoad?.postContactsGroupNumberOfError ||
          [];

        postContactsOf[index].contactLoad = {
          ...postContactsOf[index].contactLoad,
          isLoading: true,
          loadPercentage: loadPercentage,
          postContactsGroupNumberOfError: [...currentGroupsError],
        };
      }
      return {
        ...state,
        postContactsOf: [...postContactsOf],
      };
    }
    case POST_SMS_CONTACT_GROUP_FAIL: {
      const { SMSCampaignId, loadPercentage, contactGroupOfError } =
        action.payload;
      const postContactsOf: ISMSCampaign[] = state.postContactsOf
        ? [...state.postContactsOf]
        : [];

      const index = postContactsOf.findIndex(
        campaign => campaign.id === SMSCampaignId,
      );

      if (index !== -1) {
        const currentGroupsError =
          postContactsOf[index].contactLoad?.postContactsGroupNumberOfError ||
          [];

        postContactsOf[index].contactLoad = {
          ...postContactsOf[index].contactLoad,
          isLoading: true,
          loadPercentage: loadPercentage,
          postContactsGroupNumberOfError: [
            ...currentGroupsError,
            contactGroupOfError,
          ],
        };
      }
      return {
        ...state,
        postContactsOf: [...postContactsOf],
      };
    }
    case POST_SMS_CONTACTS_FINISHED: {
      const { SMSCampaignId } = action.payload;
      let completedSMSCampaign: ISMSCampaign[] = [];
      const postContactsOf: ISMSCampaign[] = state.postContactsOf
        ? [...state.postContactsOf]
        : [];

      const index = postContactsOf.findIndex(
        campaign => campaign.id === SMSCampaignId,
      );

      if (index !== -1) {
        const currentGroupsError =
          postContactsOf[index].contactLoad?.postContactsGroupNumberOfError ||
          [];

        if (currentGroupsError.length > 0) {
          postContactsOf[index].contactLoad = {
            ...postContactsOf[index].contactLoad,
            isLoading: false,
            loadPercentage: 0,
            postContactsGroupNumberOfError: [...currentGroupsError],
          };
        } else {
          completedSMSCampaign = postContactsOf.splice(index, 1);
          completedSMSCampaign[0].contactLoad = {
            ...completedSMSCampaign[0].contactLoad,
            isLoading: false,
            loadPercentage: 0,
            postContactsGroupNumberOfError: [...currentGroupsError],
          };
        }
      }
      const finishPostContactsOf =
        postContactsOf.length > 0 ? postContactsOf : undefined;
      return {
        ...state,
        campaigns: [...completedSMSCampaign, ...state.campaigns],
        postContactsOf: finishPostContactsOf,
        isCreatingSMSCampaign: undefined,
      };
    }

    // errors to show on app
    case GET_SMS_CAMPAIGNS_FAIL:
    case POST_SMS_CAMPAIGN_FAIL: {
      return {
        ...state,
        isLoading: false,
        isCreatingSMSCampaign: undefined,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
};
export default smsCampaigns;
