import { IQueue, IUser } from 'library/interfaces/crdInterfaces';
import { IError } from 'store/reports/reducer';
import {
  ConfigutationTypes,
  GET_CDR_USERS_AND_QUEUES,
  GET_CDR_USERS_AND_QUEUES_FAIL,
  GET_CDR_USERS_AND_QUEUES_SUCCESS,
  GET_PBX_AGENTS_IN_QUEUE,
  GET_PBX_AGENTS_IN_QUEUE_FAIL,
  GET_PBX_AGENTS_IN_QUEUE_SUCCESS,
  UPDATE_AGENTS_IN_QUEUE,
  UPDATE_AGENTS_IN_QUEUE_FAIL,
  UPDATE_AGENTS_IN_QUEUE_SUCCESS,
} from './actionTypes';

export interface ISippeer {
  sippeer_id: string;
}
export interface IAgentsInQueue {
  agents: ISippeer[];
  queue_id: string;
}
export interface IUpdateAgentsInQueueStore {
  isLoading: boolean;
  error: IError | null;
  users: IUser[];
  queues: IQueue[];
  agentsInQueue: IAgentsInQueue[];
}
export interface IConfigurationStore {
  updageAgentsInQueue: IUpdateAgentsInQueueStore;
}
const INIT_UPDATE_AGENTS_IN_QUEUE_STATE: IUpdateAgentsInQueueStore = {
  isLoading: false,
  error: null,
  users: [],
  queues: [],
  agentsInQueue: [],
};
const INIT_STATE: IConfigurationStore = {
  updageAgentsInQueue: INIT_UPDATE_AGENTS_IN_QUEUE_STATE,
};
const configuration = (
  state = INIT_STATE,
  action: ConfigutationTypes,
): IConfigurationStore => {
  switch (action.type) {
    case GET_CDR_USERS_AND_QUEUES:
    case GET_PBX_AGENTS_IN_QUEUE:
    case UPDATE_AGENTS_IN_QUEUE: {
      return {
        ...state,
        updageAgentsInQueue: {
          ...state.updageAgentsInQueue,
          isLoading: true,
          error: null,
        },
      };
    }
    case GET_CDR_USERS_AND_QUEUES_SUCCESS: {
      return {
        ...state,
        updageAgentsInQueue: {
          ...state.updageAgentsInQueue,
          isLoading: false,
          error: null,
          users: action.payload.users,
          queues: action.payload.queues,
        },
      };
    }
    case GET_PBX_AGENTS_IN_QUEUE_SUCCESS: {
      // insert the payload first in the array
      // to find first the result on searh
      const newAgentsInQueue = [
        action.payload.agentsInQueue,
        ...state.updageAgentsInQueue.agentsInQueue,
      ];
      return {
        ...state,
        updageAgentsInQueue: {
          ...state.updageAgentsInQueue,
          isLoading: false,
          error: null,
          agentsInQueue: newAgentsInQueue,
        },
      };
    }
    case UPDATE_AGENTS_IN_QUEUE_SUCCESS: {
      return {
        ...state,
        updageAgentsInQueue: {
          ...state.updageAgentsInQueue,
          isLoading: false,
          error: null,
        },
      };
    }
    case GET_CDR_USERS_AND_QUEUES_FAIL:
    case GET_PBX_AGENTS_IN_QUEUE_FAIL:
    case UPDATE_AGENTS_IN_QUEUE_FAIL: {
      return {
        ...state,
        updageAgentsInQueue: {
          ...state.updageAgentsInQueue,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
};
export default configuration;
