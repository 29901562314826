import { TransferCall } from 'icons';
import { danger, darkBorder, lightBackground, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { Phone } from 'library/phone/phone';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ITransferNumbersProps {
  phone: Phone
  setShow: (show: boolean) => void
  extList: { exten: string; label: string }[]
}
const TransferNumbers: FC<ITransferNumbersProps> = ({ phone, setShow, extList }) => {

  const server = useAppSelector(store => store.authUserData.pbx_url)

  const handleBlindTransfer = (extension: string) => {
    phone.blindTransfer(extension, server)
    setShow(false)
  }

  const handleExit = () => {
    setShow(false)
  }

  return (
    <Cont>
      <Exit onClick={() => handleExit()}>x</Exit>
      <Title>Transferir a:</Title>
      {extList.map((item, index) => {
        return (
          <NumbersCont key={index}>
            <IconCont onClick={() => handleBlindTransfer(item.exten)}>
              <TransferCall width={18} />
            </IconCont>
            <Label>{item.label}</Label>
          </NumbersCont>
        )
      })}
    </Cont>
  );
};
const Cont = styled.div`
width: 300px;
padding: 10px;
  position: absolute;
  top: 0px;
  left: 0;
  /* left: 0; */
  color: black;
  border-radius: 10px;
  cursor: default;
  border: 1px solid ${primary};
  background-color: ${lightBackground};

  max-height: 445px;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;

const Title = styled.div`
  font-size: 1rem;
`;
const NumbersCont = styled.div`
margin: 10px 0;
  display: grid;
  place-items: center;
  grid-template-columns: 50px 1fr;
`;
const IconCont = styled.div`
  /* border: 1px solid; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  background-color: ${darkBorder};
  opacity: .5;
  transition: background-color .3s;

  &:hover {
    opacity: 1;
  background-color: ${primary};

  }
`;
const Label = styled.div`
  /* border: 1px solid; */
  font-size: .9rem;
  line-height: 0px;
  justify-self: self-start;
`;
const Exit = styled.span`
  padding: 0 10px;
  font-weight: 300;
  font-size: 1rem;
  line-height: 25px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

  &:hover{
    color: ${danger};
  }
`;
export default TransferNumbers;