import * as React from 'react';

export const AgentGraphIcon = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M123.54 79.935c-5.44 27.895-29.987 48.955-59.416 48.955-33.474 0-60.532-27.127-60.532-60.532 0-27.964 18.969-51.535 44.771-58.509"
      fill="none"
      stroke= "black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.9737}
    />
    <path
      d="M128.7 62.43c-.906-32.636-27.616-58.857-60.462-58.857-.488 0-.906.07-1.394.07V62.43z"
      fill="none"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.9737}
    />
  </svg>
);