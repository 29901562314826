import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';
import SidebarLink from './SidebarLink';

import {
  Outgoing,
  Incoming,
  Recordings,
  Numbers,
  Integrations,
  LiveBoard,
  LiveChat,
  Workspace,
  Reports,
  History,
  Configuration,
  DataVerification,
} from '../../icons';
import { useAppSelector } from 'library/hooks/useAppSelector';

// import { Outgoing } from '../../icons';

const SidebarContent = props => {
  const ref = useRef();
  const options = useAppSelector(store => store.authUserData.options);

  const optionsToRender = options.filter(
    (obj, index, array) => index === array.findIndex(o => obj.id === o.id),
  );
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const toIcon = optionId => {
    switch (optionId) {
      case 'workspace':
        return Workspace;
      case 'live-board':
        return LiveBoard;
      case 'outgoing':
        return Outgoing;
      case 'incoming':
        return Incoming;
      case 'incoming-v2':
        return Incoming;
      case 'recordings':
        return Recordings;
      case 'numbers':
        return Numbers;
      case 'integrations':
        return Integrations;
      case 'live-chat':
        return LiveChat;
      case 'reports':
        return Reports;
      case 'history':
        return History;
      case 'configuration':
        return Configuration;
      case 'databases':
        return DataVerification;
    }

    return Workspace;
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t('Menu')} </li>

            <li>
              {optionsToRender.map(option => {
                if (option.id === 'subcribeChannel') return;
                return (
                  <SidebarLink
                    key={option.id}
                    Icon={toIcon(option.id)}
                    title={option.id}
                    to={`/${option.id}`}
                  />
                );
              })}
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
