import { AddButtonModalSC, BlueContModalSC, InputVerticalGroupModalSC, LabelModalSC, SectionTitleModalSC, SelectModalSC, TextAreaModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { CustomNodeTypes, IHandleData, INodeData } from 'components/flowV2/utils';
import React, { FC } from 'react';
import { Node, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';
import GptFunction from './gptFunction';
import { getColorFromSource, updateSourceAndEdgeColor } from 'components/flowV2/utils/colorFunctions';

interface IGPTModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  handleDeleteEdgeOnDeleteSource: (sourceId: string) => void
  handleUpdateEdgeColor: (nodeId: string, sourceId: string, color: string) => void;
}
const GPTModal: FC<IGPTModalProps> = ({
  selectedNode,
  setSelectedNode,
  handleDeleteEdgeOnDeleteSource,
  handleUpdateEdgeColor
}) => {

  const { data } = selectedNode;

  const handleSystemContextChange = (value: string) => {
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        systemContext: value
      },
    };
    setSelectedNode(editedNode);
  }

  const handleAddFunction = () => {
    const newFunction = {
      id: uuid(),
      name: `Function ${(data.functions?.length || 0) + 1}`,
      description: '',
      parameters: ''
    };
    const newSource: IHandleData & { type: 'source' } = {
      id: newFunction.id,
      color: '#00A67E',
      type: 'source',
      position: Position.Right,
      label: newFunction.name,
    };
    const functions = data.functions || [];
    const sources = data.sources || [];
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        functions: [...functions, newFunction],
        sources: [...sources, newSource],
      },
    };
    setSelectedNode(editedNode);
  };

  const handleDeleteFunction = (functionId: string) => {
    if (!data.functions || !data.sources) return
    const functionsToEdit = structuredClone(data.functions)
    const sourcesToEdit = structuredClone(data.sources)

    const functionIndex = functionsToEdit.findIndex(func => func.id === functionId)
    const sourceIndex = sourcesToEdit.findIndex(source => source.id === functionId)

    if (functionIndex === -1 || sourceIndex === -1) return
    functionsToEdit.splice(functionIndex, 1)
    sourcesToEdit.splice(sourceIndex, 1)
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        functions: [...functionsToEdit],
        sources: [...sourcesToEdit],
      },
    };
    handleDeleteEdgeOnDeleteSource(functionId)
    setSelectedNode(editedNode);
  }

  const handleFunctionChange = (
    index: number,
    name: 'name' | 'description' | 'parameters',
    value: string
  ) => {
    const functions = structuredClone(data.functions) || []
    const sources = structuredClone(data.sources) || []

    functions[index][name] = value
    if (name === 'name') {
      sources[index].label = value
    }

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        functions: [...functions],
        sources: sources,
      },
    };
    setSelectedNode(editedNode);
  }

  const handleUpdateSourceColor = (sourceId: string, color: string) => {
    updateSourceAndEdgeColor(
      sourceId,
      color,
      selectedNode,
      setSelectedNode,
      handleUpdateEdgeColor
    )
  }

  return (
    <BlueContModalSC>
      <SectionTitleModalSC>Select the model:</SectionTitleModalSC>
      <InputVerticalGroupModalSC>
        <SelectModalSC>
          <option value="gpt-3.5-turbo-0613">GPT 3.5 Turbo 0613</option>
        </SelectModalSC>
      </InputVerticalGroupModalSC>
      <SectionTitleModalSC>Context:</SectionTitleModalSC>
      <InputVerticalGroupModalSC>
        <TextAreaModalSC
          value={data.systemContext}
          onChange={e => handleSystemContextChange(e.target.value)}
        />
      </InputVerticalGroupModalSC>
      <SectionTitleModalSC>Functions:</SectionTitleModalSC>

      {data.functions && data.functions.map((func, index) => {
        return (
          <GptFunction
            key={index}
            index={index}
            functionData={func}
            sourceColor={getColorFromSource(data.sources, func.id)}
            handleUpdateSourceColor={handleUpdateSourceColor}
            handleDeleteFunction={handleDeleteFunction}
            handleFunctionChange={handleFunctionChange}
          />
        )
      })}
      <AddButtonModalSC onClick={() => handleAddFunction()}>
        <i className="bx bxs-plus-circle" /> <span>Add Function</span>
      </AddButtonModalSC>
    </BlueContModalSC>
  );
};
export default GPTModal;