import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useField, useFormikContext } from 'formik';

import './TagsInput.css';

import Tag from './Tag';
import TagList from './TagList';

TagsInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export function TagsInput({ name }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const inputRef = useRef();

  console.log(field);

  const removeTag = indexToRemove => {
    setFieldValue(name, [
      ...field.value.filter((_, index) => index !== indexToRemove),
    ]);
  };

  const addTags = event => {
    const value = event.target.value;

    if (event.key === 'Enter' && value !== '') {
      setFieldValue(name, [...field.value, value]);
      inputRef.current.value = '';
    }
  };

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <>
      <TagList tags={field.value} removeTag={removeTag} />
      <input
        ref={inputRef}
        type="text"
        className="form-control"
        onKeyDown={onKeyDown}
        onKeyUp={addTags}
      />
      <ErrorMessage name={name} component="span" />
    </>
  );
}
