import {
  CustomNodeTypes,
  IHandleData,
  INodeData,
} from 'components/flowV2/utils';
import { lightBackgroundBlue, primary } from 'library/colors';
import React, { FC } from 'react';
import { Node, Position } from 'reactflow';
import styled from 'styled-components';
import Route from './route';
import { v4 as uuid } from 'uuid';
import TextTabs from '../textToSpeech/textTab';
import { AddButtonModalSC, BlueContModalSC, FlexContSBModalSC, RadioGroupModalSC, SectionTitleModalSC, TextAreaModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { getColorFromSource, updateSourceAndEdgeColor } from 'components/flowV2/utils/colorFunctions';

interface IInteractiveMenuModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  handleDeleteEdgeOnDeleteSource: (sourceId: string) => void
  handleUpdateEdgeColor: (nodeId: string, sourceId: string, color: string) => void;
  handleShowVisualEditor: (show: boolean) => void;
}
const InteractiveMenuModal: FC<IInteractiveMenuModalProps> = ({
  selectedNode,
  setSelectedNode,
  handleDeleteEdgeOnDeleteSource,
  handleUpdateEdgeColor,
  handleShowVisualEditor
}) => {
  const { data } = selectedNode;

  const radioToRender = ['0', '1', '2', '3', '4'];

  const handleInputRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    node: Node<INodeData, CustomNodeTypes>,
  ) => {
    const { value, name } = event.target;
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...node,
      data: { ...node.data, [name]: value },
    };
    setSelectedNode(editedNode);
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    node: Node<INodeData, CustomNodeTypes>,
  ) => {
    const { value, name } = event.target;
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...node,
      data: { ...node.data, [name]: value },
    };
    setSelectedNode(editedNode);
  };

  const handleAddRoute = () => {
    const newResponseRoute = {
      id: uuid(),
      port: `Port ${data.responseRoutes?.length}`,
      responseKey: '',
      possibleResponses: [],
      intentId: '',
      text: `Port ${data.responseRoutes?.length}`,
    };
    const newSource: IHandleData & { type: 'source' } = {
      id: newResponseRoute.id,
      color: '#7c9904',
      type: 'source',
      position: Position.Right,
      label: newResponseRoute.port,
    };
    const sources = data.sources || [];
    const responseRoutes = data.responseRoutes || [];
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        responseRoutes: [...responseRoutes, newResponseRoute],
        sources: [...sources, newSource],
      },
    };
    setSelectedNode(editedNode);
  };

  const handleDeleteRoute = (routeId: string) => {
    if (!data.responseRoutes || !data.sources) return
    const responseRoutesToEdit = structuredClone(data.responseRoutes)
    const sourcesToEdit = structuredClone(data.sources)
    const routeIndex = responseRoutesToEdit.findIndex(route => route.id === routeId)
    const sourceIndex = sourcesToEdit.findIndex(source => source.id === routeId)
    if (routeIndex === -1 || sourceIndex === -1) return
    responseRoutesToEdit.splice(routeIndex, 1)
    sourcesToEdit.splice(sourceIndex, 1)
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        responseRoutes: [...responseRoutesToEdit],
        sources: [...sourcesToEdit],
      },
    };
    handleDeleteEdgeOnDeleteSource(routeId)
    setSelectedNode(editedNode);
  }

  const handleRouteChange = (
    index: number,
    name: 'port' | 'responseKey' | 'text' | 'intentId' | 'possibleResponses',
    value: string,
  ) => {
    const responseRoutes = structuredClone(data.responseRoutes) || [];
    const sources = structuredClone(data.sources) || [];

    if (name === 'possibleResponses') {
      responseRoutes[index][name] = [value];
    } else if (name === 'port') {
      responseRoutes[index][name] = value;
      sources.forEach(source => {
        if (source.id === responseRoutes[index].id) {
          source.label = value;
        }
      });
    } else {
      responseRoutes[index][name] = value;
    }

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        responseRoutes: [...responseRoutes],
        sources: sources,
      },
    };
    setSelectedNode(editedNode);
  };

  const handleTabTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!data.textV2) return
    const { name, value } = event.target
    const textData = structuredClone(data.textV2);
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: { ...selectedNode.data, textV2: { ...textData, [name]: value } }
    };
    setSelectedNode(editedNode);
  }

  const handleUpdateSourceColor = (sourceId: string, color: string) => {
    updateSourceAndEdgeColor(
      sourceId,
      color,
      selectedNode,
      setSelectedNode,
      handleUpdateEdgeColor
    )
  }

  return (
    <BlueContModalSC>
      <Flex>
        <SectionTitleModalSC>Message</SectionTitleModalSC>
        <VisualButton onClick={() => handleShowVisualEditor(true)}>Visual</VisualButton>
      </Flex>

      <TextTabs textV2={data?.textV2} handleTextChange={handleTabTextChange} />
      <SectionTitleModalSC>
        <FlexContSBModalSC>
          Repeat Message
          <FlexContSBModalSC>
            {radioToRender.map((item, index) => {
              return (
                <RadioGroupModalSC key={index}>
                  <input
                    type="radio"
                    id={`radio${item}`}
                    name="repeatNumber"
                    value={item}
                    checked={data.repeatNumber === item}
                    onChange={e => handleInputRadioChange(e, selectedNode)}
                  />
                  <label htmlFor={`radio${item}`}>{item}</label>
                </RadioGroupModalSC>
              )
            })}
          </FlexContSBModalSC>
        </FlexContSBModalSC>
      </SectionTitleModalSC>
      <TextAreaModalSC
        name="failTexts"
        id="textArea"
        value={data?.failTexts}
        onChange={e => handleTextChange(e, selectedNode)}
      />
      <SectionTitleModalSC>Context</SectionTitleModalSC>
      <TextAreaModalSC
        name="context"
        id="textArea2"
        value={data?.context}
        onChange={e => handleTextChange(e, selectedNode)}
      />

      <SectionTitleModalSC>Response routes</SectionTitleModalSC>
      {data.responseRoutes?.map((route, index) => {
        return (
          <Route
            key={index}
            responseRoute={route}
            index={index}
            sourceColor={getColorFromSource(data.sources, route.id)}
            handleUpdateSourceColor={handleUpdateSourceColor}
            handleRouteChange={handleRouteChange}
            handleDeleteRoute={handleDeleteRoute}
          />
        );
      })}

      <AddButtonModalSC onClick={() => handleAddRoute()}>
        <i className="bx bxs-plus-circle" /> <span>Add Response Route</span>
      </AddButtonModalSC>
    </BlueContModalSC>
  );
};
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const VisualButton = styled(SectionTitleModalSC)`
  background-color: ${primary};
  padding: 3px 10px ;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
`;
export default InteractiveMenuModal;
