import React, { FC } from 'react';
import styled from 'styled-components';

import { lightBackground, mediumBackground, secondary } from 'library/colors';

interface IContainer {
  children: React.ReactNode;
  padding?: string;
  smallPadding?: string;
  margin?: string; 
  gridColumn?: string;
  gridRow?: string;
  mediumBackground?: React.ReactNode;
  cursorPointer?: React.ReactNode;
  handleClick?: Function;
}

const Container: FC<IContainer> = ({
  children,
  mediumBackground,
  padding,
  smallPadding,
  margin,
  gridColumn,
  gridRow,
  cursorPointer,
  handleClick
}) => {
  return (
    <Div
      mediumBackground={mediumBackground}
      padding={padding}
      gridColumn={gridColumn}
      gridRow={gridRow}
      smallPadding={smallPadding}
      margin={margin}
      cursorPointer={cursorPointer}
      onClick={(e)=>handleClick && handleClick(e)}
    >
      {children}
    </Div>
  );
};
const Div = styled.div<IDiv>`
  width: 100%;
  cursor: ${props=>props.cursorPointer && 'pointer'};
  margin: ${props=>props.margin ? props.margin : '0px auto'};
  border-radius: 8px;
  padding: ${props => props.padding && props.padding};
  background-color: ${props =>
    props.mediumBackground ? mediumBackground : lightBackground};

  @media (min-width: 890px) {
    padding: ${props => props.padding && props.padding};
    grid-column: ${props=>props.gridColumn && props.gridColumn};
    grid-row: ${props=>props.gridRow && props.gridRow};
  }
`;
interface IDiv {
  padding?: string;
  smallPadding?: string;
  margin?: string; 
  gridColumn?: string;
  gridRow?: string;
  mediumBackground?: React.ReactNode;
  cursorPointer?: React.ReactNode;
}
export default Container;
