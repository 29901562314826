import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ISetLanguageProps {}
const SetLanguage: FC<ISetLanguageProps> = ({}) => {
  const [selectedLang, setSelectedLang] = useState('');

  const { t, i18n } = useTranslation();

  const handleSetLang = (lang: string) => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
  };
  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'en_US';
    setSelectedLang(lang);
  }, []);

  const languages = [
    {
      label: 'English',
      value: 'en_US',
    },
    {
      label: 'Español',
      value: 'es_LA',
    },
  ];

  return (
    <Cont>
      {languages.map(lang => {
        return (
          <Lang key={lang.value} >
            <Span>{selectedLang === lang.value && <span>&#10003;</span>}</Span>
            <Label onClick={() => handleSetLang(lang.value)}>{lang.label}</Label>
          </Lang>
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
margin-top: 10px;
cursor: default;
`;
const Lang = styled.div`
  padding-left: 30px;
  display: flex;
  align-items: center;
`;
const Span = styled.span`
  display: block;
  width: 10px;
  margin-right: 3px;
  cursor: default;
  line-height: 0;
`;
const Label = styled.label`
  cursor: pointer;
  margin: 0 0 5px;
  line-height: 10px;
  padding: 0;
`;
export default SetLanguage;
