import { IIndicatorProps } from 'components/Report/atoms/indicatorLinked';
import { danger, primary } from 'library/colors';
import { ICallsCounter } from 'library/interfaces/reportsInterfaces';

export const indicatorsToRender = (
  callsData?: ICallsCounter,
): IIndicatorProps[] => {
  const answered = callsData?.answered;
  const unanswered = callsData?.unanswered;
  const failed = callsData?.failed || 0;
  const count = callsData?.count;
  // const busy = callsData?.busy || 0
  // const abandom = callsData?.abandom || 0
  // const noAnswered = callsData?.noAnswered || 0

  const indicators = [
    {
      label: 'answered',
      value: answered,
      labelBackColor: primary,
    },
    {
      label: 'unanswered',
      value: unanswered,
      labelBackColor: 'orange',
    },
    {
      label: 'failed',
      value: failed,
      labelBackColor: danger,
    },
    {
      label: 'total',
      value: count,
      labelBackColor: 'lightblue',
    },
  ];
  return indicators;
};

export const graphDataToRender = (callsData?: ICallsCounter) => {
  const answered = callsData?.answered || 0;
  const unanswered = callsData?.unanswered || 0;
  const failed = callsData?.failed || 0;

  const graphData = [
    {
      label: 'Answered',
      value: answered,
      color: primary,
    },
    {
      label: 'Unanswered',
      value: unanswered,
      color: 'orange',
    },
    {
      label: 'Failed',
      value: failed,
      color: danger,
    },
  ];

  return graphData;
};
