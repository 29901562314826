import React, { FC } from 'react';
import styled from 'styled-components';

import Container from '../atoms/container';
import OnLineAgents from '../molecules/onLineAgents';

import { primary, secondary } from 'library/colors';
import Agent from '../molecules/agent';
import { ISummarizeAgent } from 'library/interfaces/summarizeInterface';
import { useTranslation } from 'react-i18next';

interface ILiveDashboardAside {
  data: {
    total: number;
    incall: number;
    ringing: number;
    onhold: number;
    available: number;
    unavailable: number;
    connected: number;
  };
  agents: ISummarizeAgent[];
  gridColumn?: string;
  gridRow?: string;
  showAddAgentToQueue: Function;
}

const LiveDashboardAside: FC<ILiveDashboardAside> = ({
  data,
  agents,
  gridColumn,
  gridRow,
  showAddAgentToQueue,
}) => {
  const {t} = useTranslation('summarize')
  return (
    <Container padding="20px 0px" gridColumn={gridColumn} gridRow={gridRow}>
      <OnLineAgents donutGraph data={data} />
      <ButtonsCont>
        <AddAgentButton onClick={() => showAddAgentToQueue(true)}>
          + {t('addAgents')}
        </AddAgentButton>

        <Button>{t('allStates')}</Button>
        <Button>{t('roles')}</Button>
      </ButtonsCont>
      <RelativeCont>
      <AgentsCont>
        {agents &&
          agents.map((item, index) => {
            return <Agent agent={item} key={index} />;
          })}
      </AgentsCont>
      </RelativeCont>
    </Container>
  );
};
const ButtonsCont = styled.div`
  padding: 0 20px;
  border-bottom: 2px solid ${secondary};
  margin-bottom: 20px;
`;
const AddAgentButton = styled.div`
  font-size: 0.72rem;
  color: ${primary};
  margin: 15px auto;
  font-weight: 600;
  text-align: center;
  padding: 7px 0;
  border: 2px solid;
  border-radius: 30px;
  cursor: pointer;
`;
const Button = styled.div`
  font-size: 0.72rem;
  padding: 7px 20px;
  margin: 15px auto;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  border: 1px solid ${secondary};

  &:after {
    content: '';
    top: 45%;
    right: 20px;
    cursor: pointer;
    position: absolute;
    border-top: 7px solid black;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
`;
const RelativeCont = styled.div`
  position: relative;
`;
const AgentsCont = styled.div`
  max-height: 345px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
export default LiveDashboardAside;
