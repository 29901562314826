import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Pencil, Checkmark, Close } from '../../../icons';

import { COLORS } from './constants';

const ICON_SIZE = 16;
const ICON_SIZE_SMALL = 12;

function NodeTitle({ data }) {
  const [editState, setEditState] = useState(false);
  const [newValue, setNewValue] = useState(data.label);
  const editRef = useRef();

  useEffect(() => {
    if (editState) {
      editRef?.current.focus();
    }
  }, [editState]);

  const handleChange = event => {
    setNewValue(event.target.value);
  };

  const handleEdit = useCallback(() => {
    setEditState(true);
  }, []);

  const handleCancel = useCallback(() => {
    setNewValue(data.label);
    setEditState(false);
  }, [data.label]);

  const handleConfirm = useCallback(() => {
    data.label = newValue;
    setEditState(false);
  }, [data.label, newValue]);

  const handleDown = useCallback(
    event => {
      if (event.keyCode === 13) {
        handleConfirm();
      }
    },
    [handleConfirm],
  );

  return editState ? (
    <>
      <input
        ref={editRef}
        className="node-title-input"
        type="text"
        value={newValue}
        onChange={handleChange}
        onKeyDown={handleDown}
      />
      <button
        className="btn btn-light btn-sm"
        onClick={handleConfirm}
        style={{ marginRight: 2, padding: 0 }}
      >
        <Checkmark
          width={ICON_SIZE}
          height={ICON_SIZE}
          style={{ color: COLORS.green2 }}
        />
      </button>
      <button
        className="btn btn-light btn-sm"
        style={{ padding: 0 }}
        onClick={handleCancel}
      >
        <Close
          width={ICON_SIZE}
          height={ICON_SIZE}
          style={{ color: COLORS.red }}
        />
      </button>
    </>
  ) : (
    <>
      <span style={{ marginRight: 4 }}>{newValue}</span>
      <button
        className="btn btn-link btn-sm"
        style={{ padding: 0 }}
        onClick={handleEdit}
      >
        <Pencil
          width={ICON_SIZE_SMALL}
          height={ICON_SIZE_SMALL}
          style={{ color: '#fff' }}
        />
      </button>
    </>
  );
}

NodeTitle.propTypes = {
  data: PropTypes.object,
};

export default NodeTitle;
