import React, { FC } from 'react';
import styled from 'styled-components';

interface IIndicatorProps {
  data: {
    value: number;
    color: string;
    label: string;
  }
}
const Indicator: FC<IIndicatorProps> = ({ data }) => {
  return (
    <Cont>
      <Color bgColor={data.color} />
      <Label>{data.label}:</Label>
      <Value>{data.value}</Value>
    </Cont>
  );
};
const Cont = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const Color = styled.div<IColor>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.bgColor};
`;
const Label = styled.div`
  margin: 0 10px;
`;
const Value = styled.div``;

interface IColor {
  bgColor: string
}
export default Indicator;