import * as React from 'react';

export const Configuration = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M66.146 40.459h0c-14.176 0-25.655 11.542-25.655 25.655 0 14.177 11.541 25.656 25.655 25.656m23.272-66.993c-3.262-1.819-6.838-3.324-10.538-4.328L74.802 3.2H57.364l-4.077 17.25c-3.7 1.004-7.276 2.51-10.538 4.328l-15.055-9.346L15.4 27.725l9.346 15.055c-1.819 3.262-3.324 6.837-4.328 10.538l-17.25 4.077v17.438l17.25 4.078c1.004 3.7 2.51 7.276 4.328 10.538L15.4 104.503l12.294 12.295 15.055-9.347c3.262 1.82 6.837 3.325 10.538 4.329l4.14 17.375h17.438l4.077-17.25c3.701-1.004 7.277-2.509 10.538-4.328M66.146 91.832h0c14.176 0 25.655-11.541 25.655-25.655 0-14.176-11.541-25.655-25.655-25.655m-23.272 66.992c3.262 1.82 6.838 3.325 10.538 4.328l4.078 17.25h17.438l4.077-17.25c3.7-1.003 7.276-2.509 10.538-4.328l15.055 9.346 12.294-12.294-9.346-15.054c1.819-3.262 3.324-6.838 4.328-10.539l17.25-4.077V57.458l-17.25-4.077c-1.004-3.701-2.51-7.277-4.328-10.538l9.346-15.055-12.294-12.294-15.055 9.346c-3.262-1.82-6.837-3.325-10.538-4.328l-4.14-17.376H57.427l-4.077 17.25c-3.701 1.004-7.277 2.51-10.538 4.329"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.2727}
    />
  </svg>
);
