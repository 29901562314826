import { getAws, postAws } from 'helpers/api_aws_helper';
import { primary, darkBorder } from 'library/colors';
import IStore from 'library/interfaces/storeInterface';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IReportData } from 'store/reports/reducer';
import LoadingPoints from './loadingPoints';
import { AxiosError } from 'axios';

interface IDownloadReportButtonProps {
  children: string;
  reportType?: number | string;
  params?: string;
  campaignId?: string
  disable?: boolean
}
const DownloadReportButton: FC<IDownloadReportButtonProps> = ({
  children,
  reportType,
  params,
  campaignId,
  disable
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [url, setUrl] = useState('')

  const owner = useSelector<IStore, string>(
    store => store.authUserData.owner,
  ).toLowerCase();

  const handleRequestReport = async () => {
    if (!reportType || (!campaignId && !params) || disable) return
    setIsLoading(true)

    const urlRequest = campaignId
      ? `/report/v2/type/${reportType}/owner/${owner}?campaign=${campaignId}`
      : `/report/v2/type/${reportType}/owner/${owner}?${params}`

    try {


      const requestReport: IReportData = await postAws(urlRequest,);

      if (requestReport.status === 'completed'
        && requestReport.content_data
        && requestReport.content_data.uri) {
        setUrl(requestReport.content_data.uri)
      }

      while (requestReport.status === 'in-progress') {
        await new Promise(resolve => setTimeout(resolve, 2000)); // Esperar 2 segundos

        const getReport: IReportData = await getAws(
          `/report/${requestReport.id}/owner/${owner}`,
        );

        requestReport.status = getReport.status;

        if (getReport.content_data && getReport.content_data.uri) {
          setUrl(getReport.content_data.uri)
        }
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      const error = e as AxiosError
      console.log(error.message)
    }
  };

  return (
    (!isLoading && url) ?
      <a href={url} target="_blank" rel='noreferrer'><DownloadButton onClick={() => setUrl('')}>Descargar</DownloadButton></a>
      :
      <Button disabled={isLoading} onClick={e => handleRequestReport()}>{isLoading ? <>Generando <LoadingPoints /></> : children}</Button>
  );
};
const Button = styled.button`
  width: 120px;
  height: 30px;
  font-weight: 600;
  color: ${props => props.disabled ? 'white' : primary};
  border: 2px solid ${props => props.disabled ? darkBorder : primary};
  border-radius: 50px;
  background-color:  ${props => props.disabled ? darkBorder : 'transparent'};
`;
const DownloadButton = styled(Button)`
  color: white;
  background-color: ${primary};
`;
export default DownloadReportButton;
