import React, { FC } from 'react';
import styled from 'styled-components';

import { darkText, lightBackground } from 'library/colors';

interface IPageTitleProps{
    children: React.ReactNode
}
const PageTitle: FC<IPageTitleProps> = ({children}) =>{
    return(
        <H1>
            <Span>{children}</Span>
        </H1>
    )
}
const H1 = styled.h1`
    font-weight: 700;
    font-size: 1rem;
    margin: 0px auto 30px;
    color: ${darkText};

`
const Span = styled.span`
    padding: 4px 25px;
    border-radius: 30px;
    text-transform: uppercase;
    background-color: ${lightBackground}
`
export default PageTitle;
