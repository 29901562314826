import React from 'react';
import { useHistory } from 'react-router-dom';

function SceneryItemAdd({version}) {
  const history = useHistory();
  return (
    <button
      className="btn btn-primary scenery-gallery-btn-add"
      onClick={() => history.push(version === 'v1'? '/editor/new': '/v2/incoming/flow/new')}
    >
      <div className="btn-gallery-center">
        <span>+</span> Nuevo escenario
      </div>
    </button>
  );
}

SceneryItemAdd.propTypes = {};

export default SceneryItemAdd;
