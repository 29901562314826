import * as React from "react"

export const HttpRequest = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M12.87 30.78a.87.87 0 0 1-.31-.05.92.92 0 0 1-.56-1.17l8.73-24.73a.92.92 0 1 1 1.73.61l-8.72 24.73a.92.92 0 0 1-.87.61ZM9.26 25a.91.91 0 0 1-.65-.27L1.8 17.87a.92.92 0 0 1 0-1.3l6.81-6.81a.92.92 0 0 1 1.3 0 .91.91 0 0 1 0 1.29l-6.16 6.17 6.16 6.16A.92.92 0 0 1 9.26 25ZM25.74 25a.92.92 0 0 1-.65-1.57l6.16-6.16-6.16-6.17a.91.91 0 0 1 0-1.29.92.92 0 0 1 1.3 0l6.81 6.81a.92.92 0 0 1 0 1.3l-6.81 6.81a.91.91 0 0 1-.65.27Z"
    />
  </svg>
)

