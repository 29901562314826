import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface ISpeedGraph {
  porcent: number;
  maxWidth?: number;
  white?: React.ReactNode
}
const SpeedGraph: FC<ISpeedGraph> = ({ porcent, maxWidth, white }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [graphWidth, setGraphWidth] = useState<number>(0);

  const barWidht = 13;

  const updateWidth = () => {
    const currentWidht = containerRef.current?.clientWidth;
    setGraphWidth(currentWidht ? currentWidht : 0);
  };

  useEffect(() => {
    const initialWidth = containerRef.current?.clientWidth
      ? containerRef.current?.clientWidth
      : 0;
    setGraphWidth(initialWidth);
    window.addEventListener('resize', () => updateWidth());
    return window.removeEventListener('resize', () => updateWidth());
  }, []);
  return (
    <Container ref={containerRef} maxWidth={maxWidth} barWidth={barWidht} graphWidth={graphWidth}>
      <GraphContainer>
        <Graph porcent={50} barWidth={barWidht} graphWidth={graphWidth}>
          <Steeple porcent={porcent} white={white}/>
          <Label white={white}>
            <span>{porcent}</span>%
          </Label>
        </Graph>
      </GraphContainer>
    </Container>
  );
};
const Container = styled.div<IContainer>`
  color: black;
  overflow: hidden;
  margin: 10px auto 15px;
  max-width: ${props=>props.maxWidth && props.maxWidth}px;
  padding-bottom: ${props => props.barWidth / 2}px;
`;
const GraphContainer = styled.div`
  aspect-ratio: 2/1;
  position: relative;
`;
const Graph = styled.div<IGraph>`
  position: absolute;
  width: 100%;
  aspect-ratio: 1;
  display: inline-grid;
  place-content: center;
  margin: 0;

  &:before,
  &:after {
    content: '';
    width: 100%;
    aspect-ratio: 1;
    position: absolute;
    border-radius: 50%;
    transition: background 0.25s, transform 0s;
    transform: rotate(${props => (props.rotate ? -props.rotate : '-90')}deg);
    background: conic-gradient(#d78480 10%, #ff9904 20% 35%, #85ce5f 50%, transparent 0),
      radial-gradient(
          farthest-side,
          ${props =>
              props.backgroundColor ? props.backgroundColor : "#85ce5f"}
            90%,
          #0000
        )
        bottom/ ${props => props.barWidth}px ${props => props.barWidth}px no-repeat,
      radial-gradient(
          farthest-side,
          ${props =>
              props.backgroundColor ? props.backgroundColor : '#d78480'}
            90%,
          #0000
        )
        top/ ${props => props.barWidth}px ${props => props.barWidth}px no-repeat;
    -webkit-mask: radial-gradient(
      farthest-side,
      transparent calc(100% - ${props => props.barWidth}px),
      red calc(100% - 12px)
    );
    mask: radial-gradient(
      farthest-side,
      transparent calc(100% - ${props => props.barWidth}px),
      red calc(100% - 12px)
    );
  }
`;
const Steeple = styled.div<ISteeple>`
  position: absolute;
  z-index: 1;
  width: 3px;
  height: calc(50%);
  left: 50%;
  border-radius: 50%;
  transform: rotateZ(calc(-90deg + calc(${props=>props.porcent} * 1.8deg)));
  transform-origin: 50% 100%;
  transition: transform 1s;
  background-image: linear-gradient( ${props=>props.white ? 'white' : 'black'} 50%, transparent 50% );
`
const Label = styled.div<ILabel>`
  font-size: .8rem;
  transform: translate(5%, -15%);
  color: ${props=>props.white && 'white'};

  & > span {
    font-size: 2rem;
    font-weight: 600;
  }
`;
interface IContainer {
  barWidth: number;
  graphWidth: number;
  maxWidth?: number;
}
interface IGraph {
  porcent: number;
  barWidth: number;
  graphWidth: number;
  backgroundColor?: string;
  rotate?: number;
}
interface ISteeple{
  porcent: number
  white?: React.ReactNode
}
interface ILabel {
  white?: React.ReactNode
}
export default SpeedGraph;