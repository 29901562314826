import { darkBorder, lightBackgroundBlue, popUpBackground, primary } from 'library/colors';
import { ICreateDatabaseSchema, IDatabase, IUpdateDatabase } from 'library/interfaces/databasesInterfaces';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import DatabaseColumn from '../organisms/column';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { putUpdateDatabase } from 'store/databases/asyncActions';

interface IModifyColumnsFormProps {
  currentDb?: IDatabase
  setShowModifyColumnsForm: (show: boolean) => void
}
const ModifyColumnsForm: FC<IModifyColumnsFormProps> = ({ setShowModifyColumnsForm, currentDb }) => {
  const dispath = useAppDispatch()
  const [editableSchema, setEditableSchema] = useState<ICreateDatabaseSchema[]>([])

  const handleUpdateColumn = (index: number, Column: ICreateDatabaseSchema) => {
    setEditableSchema(column => column.map((col, ind) => {
      if (ind === index) {
        return Column
      }
      return col
    }))
  }

  const handleAddColumn = () => {
    const newColumn: ICreateDatabaseSchema = {
      key: 'Nueva Columna',
      name: 'Nueva Columna',
      order: editableSchema.length,
      type: 'S'
    }
    setEditableSchema(prev => [...prev, newColumn].map((item, index) => {
      return { ...item, order: index }
    }))
  }

  const handleDeleteColumn = (index: number) => {
    setEditableSchema(prev => prev.filter((item, ind) => ind !== index))
  }

  const handleUpdateDatabase = () => {
    if (!currentDb) return
    const database: IUpdateDatabase = {
      name: currentDb.name,
      aux: currentDb.aux,
      schema: editableSchema.filter(item => item.key !== '')
    }
    dispath(putUpdateDatabase({ dbId: currentDb.id, database }))
    return setShowModifyColumnsForm(false);
  }

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton' ||
      event.target.id === 'cancelButton'
    ) {
      return setShowModifyColumnsForm(false);
    }
  };

  useEffect(() => {
    if (!currentDb) return
    const schema = structuredClone(currentDb.schema)
    setEditableSchema(schema)
  }, [currentDb])
  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton" onClick={e => handleExit(e)}>
          x
        </Exit>
        <HeaderCont>
          Modificar Columnas
        </HeaderCont>
        <BodyCont>
          {editableSchema.map((column, index) => {
            if (currentDb?.indexList.some((item2) => item2.key === column.key) ||
              currentDb?.aux.some((item3) => item3.key === column.key)) return
            return (
              <DatabaseColumn
                key={index}
                index={index}
                column={column}
                handleDeleteColumn={handleDeleteColumn}
                handleUpdateColumn={handleUpdateColumn}
              />
            )
          })}
          <AddResponseButton onClick={() => handleAddColumn()}>
            <i className="bx bxs-plus-circle" /> <span>Añadir columna</span>
          </AddResponseButton>
        </BodyCont>
        <ButtonsCont>
          <CancelButton id='cancelButton' onClick={e => handleExit(e)} isDisabled={false}>Cancelar</CancelButton>
          <SaveButton isDisabled={false} onClick={() => handleUpdateDatabase()}>Guardar</SaveButton>
        </ButtonsCont>
      </FormContainer>
    </PopUpContainer>

  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.form`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 530px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);

  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const HeaderCont = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: center;
  grid-column: 1 / -1;
  background-color: ${lightBackgroundBlue};
`;
const BodyCont = styled.div`
height: 320px;
  border-radius: 10px;
  padding: 20px;
  background-color: ${lightBackgroundBlue};
  margin-bottom: 20px;

  overflow-y: scroll;

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(123, 153, 4, 0.3);
  border-radius: 10px;
}

&:hover {
  ::-webkit-scrollbar-thumb {
    background-color: ${primary};
  }
}
`;
const AddResponseButton = styled.div`
  color: ${primary};
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > span {
    margin-left: 5px;
  }
  & > i {
    font-size: 1rem;
  }
`;
const ButtonsCont = styled.div`
height: 60px;
  padding: 0px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  grid-column: 1 / -1;
  background-color: ${lightBackgroundBlue};
`;
const SaveButton = styled.div<ISaveButton>`
  color: white;
  font-size: 1rem;
  font-weight: 700;
  width: 130px;
  border-radius: 50px;
  margin-left: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px solid ${props => props.isDisabled ? darkBorder : primary};
  background-color: ${props => props.isDisabled ? darkBorder : primary};
`;
const CancelButton = styled(SaveButton)`
  color: black;
  font-weight: 300;
  background-color: transparent;
`;
interface ISaveButton {
  isDisabled: boolean;
}
export default ModifyColumnsForm;