import { ACTION_SCENARIOS_FAIL, DELETE_SCENARIO, DELETE_SCENARIO_SUCCESS, GET_SCENARIO, GET_SCENARIOS, GET_SCENARIOS_SUCCESS, GET_SCENARIO_SUCCESS, POST_ADD_SCENARIO, POST_ADD_SCENARIO_SUCCESS, PUT_RENAME_SCENARIO, PUT_RENAME_SCENARIO_SUCCESS, PUT_PUBLISH_SCENARIO, PUT_PUBLISH_SCENARIO_SUCCESS, PUT_SAVE_AS_DRAFT_SCENARIO, PUT_SAVE_AS_DRAFT_SCENARIO_SUCCESS, PUT_UPDATE_SCENARIO, PUT_UPDATE_SCENARIO_SUCCESS, PUT_UPDATE_FLOW_SCENARIO_SUCCESS, PUT_UPDATE_FLOW_SCENARIO, CLEAR_SCENARIO_STORE, GET_FUNCTIONS, GET_FUNCTIONS_SUCCESS, GET_INTENTS, GET_INTENTS_SUCCESS } from "./actionTypes";

export const actionScenariosFail = error => ({
  type: ACTION_SCENARIOS_FAIL,
  payload: error,
})

export const getScenarios = (version = 'v1') => ({
  type: GET_SCENARIOS,
  payload: version
})

export const getScenariosSuccess = scenarios => ({
  type: GET_SCENARIOS_SUCCESS,
  payload: scenarios,
})

export const getScenario = (id, version = 'v1') => ({
  type: GET_SCENARIO,
  payload: {id, version},
})

export const getScenarioSuccess = scenario => ({
  type: GET_SCENARIO_SUCCESS,
  payload: scenario,
})

export const addScenario = (scenario, version = 'v1') => ({
  type: POST_ADD_SCENARIO,
  payload: {scenario, version},
})

export const addScenarioSuccess = scenario => ({
  type: POST_ADD_SCENARIO_SUCCESS,
  payload: scenario,
})

export const renameScenario = (id, newName) => ({
  type: PUT_RENAME_SCENARIO,
  payload: {id, newName},
})

export const renameScenarioSuccess = (id, newName) => ({
  type: PUT_RENAME_SCENARIO_SUCCESS,
  payload: {id, newName},
})

export const deleteScenario = (id) => ({
  type: DELETE_SCENARIO,
  payload: {id},
})

export const deleteScenarioSuccess = (id) => ({
  type: DELETE_SCENARIO_SUCCESS,
  payload: {id},
})

export const publishScenario = (id) => ({
  type: PUT_PUBLISH_SCENARIO,
  payload: id,
})

export const publishScenarioSuccess = (id) => ({
  type: PUT_PUBLISH_SCENARIO_SUCCESS,
  payload: id,
})

export const saveAsDraftScenario = (id) => ({
  type: PUT_SAVE_AS_DRAFT_SCENARIO,
  payload: id,
})

export const saveAsDraftScenarioSuccess = (id) => ({
  type: PUT_SAVE_AS_DRAFT_SCENARIO_SUCCESS,
  payload: id,
})

export const updateScenario = (id, data, version = 'v1') => ({
  type: PUT_UPDATE_SCENARIO,
  payload: {id, data, version},
})

export const updateScenarioSuccess = (id, data) => ({
  type: PUT_UPDATE_SCENARIO_SUCCESS,
  payload: {id, data},
})

export const updateFlowScenario = (id, flow) => ({
  type: PUT_UPDATE_FLOW_SCENARIO,
  payload: {id, flow},
})

export const updateFlowScenarioSuccess = (id, flow) => ({
  type: PUT_UPDATE_FLOW_SCENARIO_SUCCESS,
  payload: {id, flow},
})

export const clearScenarioStoreAction = () => ({
  type: CLEAR_SCENARIO_STORE
})

export const getFunctionsAction = () => ({
  type: GET_FUNCTIONS
})

export const getFunctionsSuccess = (functions) => ({
  type: GET_FUNCTIONS_SUCCESS,
  payload: functions
})

export const getIntentsAction = () => ({
  type: GET_INTENTS
})

export const getIntentsSuccess = (intents) => ({
  type: GET_INTENTS_SUCCESS,
  payload: intents
})