import React from 'react';
import NodeModal from '../common/NodeModal';
import PropTypes from 'prop-types';

function Modal({ Icon, data, toggle, isOpen }) {
  return (
    <NodeModal Icon={Icon} data={data} isOpen={isOpen} toggle={toggle}>
      You must use this block to mark the end of your scenario. If there&apos;s
      no such block, the scenario will not work.
    </NodeModal>
  );
}

Modal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  Icon: PropTypes.any,
};

export default Modal;
