import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../FormContainer';
import { Form, Formik } from 'formik';
import { Col, Label, Row } from 'reactstrap';
import * as Fields from '../../Fields';
import * as Yup from 'yup';
import Language from '../common/SynthLanguage/Language';
import NumberInput from '../../Fields/NumberInput';

const validationSchema = Yup.object({
  value: Yup.string().nullable(),
});

EditInputSettings.propTypes = {
  data: PropTypes.object.isRequired,
  toggleInEdit: PropTypes.func.isRequired,
};

function EditInputSettings({ data, toggleInEdit }) {
  const onSubmit = values => {
    data.inputType = values.inputType;
    data.recognitionLanguage = values.recognitionLanguage;
    data.startRecognition = values.startRecognition;
    data.setOffset = values.setOffset;
    data.DTMFType = values.DTMFType;

    toggleInEdit();
  };

  return (
    <FormContainer title="Input Settings">
      <Formik
        initialValues={{
          inputType: data?.inputType || 'both', // both, voice, keyboard
          recognitionLanguage: data?.recognitionLanguage,
          startRecognition: data?.startRecognition, // At the End, Offset from the Start, Offset from the End
          setOffset: data?.setOffset || 1, // in seconds
          DTMFType: data?.DTMFType || 'All', // All (default), Telephone_event, Inband, SIP info
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => {
          return (
            <Form>
              <Row>
                <Col lg={12}>
                  <Fields.Radio
                    inline={true}
                    name="inputType"
                    label="both"
                    value="both"
                  />
                  <Fields.Radio
                    inline={true}
                    name="inputType"
                    label="voice"
                    value="voice"
                  />
                  <Fields.Radio
                    inline={true}
                    name="inputType"
                    label="keyboard"
                    value="keyboard"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Language
                    label="Recognition language"
                    name="recognitionLanguage"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={7}>
                  <label>Start Recognition</label>
                  <Fields.Select2
                    name="startRecognition"
                    options={[
                      { label: 'At the End', value: 'AtTheEnd' },
                      {
                        label: 'Offset from the Start',
                        value: 'OffsetFromTheStart',
                      },
                      {
                        label: 'Offset from the End',
                        value: 'OffsetFromTheEnd',
                      },
                    ]}
                  />
                </Col>

                {formik?.values?.startRecognition?.value !== 'AtTheEnd' && (
                  <Col lg={5}>
                    <label>Set offset</label>
                    <Fields.NumberInput name="setOffset" postfix="sec" />
                  </Col>
                )}
              </Row>

              <Row className="mt-3">
                <Col lg={12}>
                  <label>DTMF type</label>
                  <Fields.Select2
                    name="DTMFType"
                    options={[
                      { label: 'All (default)', value: 'All' },
                      {
                        label: 'Telephone_event',
                        value: 'Telephone_event',
                      },
                      { label: 'Inband', value: 'Inband' },
                      { label: 'SIP info', value: 'SIPInfo' },
                    ]}
                  />
                </Col>
              </Row>

              <div className="mt-3">
                <button type="submit" className="btn btn-primary btn-sm">
                  Save
                </button>
                <button
                  className="btn btn-default btn-sm"
                  onClick={toggleInEdit}
                >
                  Cancel
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
}

export default EditInputSettings;
