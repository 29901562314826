import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PageHeader from '../../components/Common/PageHeader';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

// actions
import {
  editProfile,
  resetProfileFlag,
  getUser as onGetUser,
} from '../../store/actions';

const UserProfile = () => {
  const dispatch = useDispatch();
  let user = JSON.parse(localStorage.getItem('authUser'));

  const { error, success, agent } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
    agent: state.agents.agent,
  }));

  const [email, setemail] = useState(user.email);
  const [name, setname] = useState(user.display_name);
  const [owner, setowner] = useState(user.owner);
  const [roles, setroles] = useState(
    user.roles?.map(r => {
      return r.name;
    }),
  );
  const [picture, setpicture] = useState(user.picture);
  const [exten, setexten] = useState(user.exten);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetProfileFlag());
    }, 3000);
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(onGetUser());
    }
  }, [success]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: name || '',
      picture: picture || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Ingrese su nombre de usuario'),
      picture: Yup.string().required('Ingrese el link de su foto'),
    }),
    onSubmit: values => {
      dispatch(editProfile(user.auth0_id, values));
    },
  });

  useEffect(() => {
    if (agent) {
      setemail(agent.email);
      setname(agent.display_name);
      setowner(agent.owner);
      setroles(
        agent.roles?.map(r => {
          return r.name;
        }),
      );
      setpicture(agent.picture);
      setexten(agent.exten);
      localStorage.setItem('authUser', JSON.stringify(agent));
    }
  }, [agent]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Perfil | Smartbot - Admin</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <PageHeader title="Perfil" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={picture}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-0">{email}</p>
                        <p className="mb-0">Organización: {owner}</p>
                        <p className="mb-0">Roles: {roles?.join(', ')}</p>
                        <p className="mb-0">Interno: {exten}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Actualizar</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Nombre</Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder="Ingrese su nombre"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ''}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                  <Label className="form-label">Imagen de Perfil</Label>
                  <Input
                    name="picture"
                    className="form-control"
                    placeholder="Ingrese el link de su foto"
                    type="url"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.picture || ''}
                    invalid={
                      validation.touched.picture && validation.errors.picture
                        ? true
                        : false
                    }
                  />
                  {validation.touched.picture && validation.errors.picture ? (
                    <FormFeedback type="invalid">
                      {validation.errors.picture}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Actualizar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
