import React, { FC } from 'react';
import styled from 'styled-components';

interface IAnimatedArrowToShowMenuProps {
  isUp?: boolean;
  className?: string;
}
const AnimatedArrowToShowMenu: FC<IAnimatedArrowToShowMenuProps> = ({
  isUp,
  className
}) => {
  return <AnimatedArrow className={className} isUp={isUp} />;
};
const AnimatedArrow = styled.i<IAnimatedArrow>`
  margin-left: 7px;
  display: inline-block;
  border: 4px solid transparent;
  border-bottom: none;
  border-top: 5px solid #495057;
  border-radius: 10px;
  position: relative;

  transform: ${props => props.isUp && 'rotateX(180deg)'};
  transition: transform 0.5s;

  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: -4px;
    border: 4px solid transparent;
    border-bottom: none;
    border-top: 5px solid white;
  }
`;
interface IAnimatedArrow {
  isUp?: boolean;
}
const Cont = styled.div``;
export default AnimatedArrowToShowMenu;
