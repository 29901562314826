import DropZone from 'components/outbound/atoms/dropzone';
import { IFile } from 'components/outbound/molecules/FormLoadingFiles';
import { darkBorder, lightBackgroundBlue, lightGreen, popUpBackground, primary } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { IDatabase, IDatabaseRow } from 'library/interfaces/databasesInterfaces';
import React, { FC, useState } from 'react';
import { postRows } from 'store/databases/asyncActions';
import { ISelectedDatabaseState } from 'store/databases/reducer';
import styled from 'styled-components';

interface IUploadDataTemplateProps {
  currentDb?: IDatabase
  setShowUploadDataForm: (show: boolean) => void
}
const UploadDataTemplate: FC<IUploadDataTemplateProps> = ({ currentDb, setShowUploadDataForm }) => {
  const dispatch = useAppDispatch()
  const [files, setFiles] = useState<IFile[]>([])

  const handleSaveData = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (files.length === 0 || !currentDb) return
    const ids: { [key: string]: string } = {}
    const data: IDatabaseRow[] = []
    files[0].sheets[0]?.data.forEach(item => {
      const id: string = currentDb.aux.map(col => item[col.key]).filter(itm => itm !== undefined).join('')
      if (ids[id]) return
      ids[id] = id
      data.push({ ...item, id })
    })
    dispatch(postRows({
      dbId: currentDb.id,
      allRows: data,
      dbIndex: currentDb.indexList[0]?.key || ''
    }))
    setShowUploadDataForm(false)
  }
  const handleDeleteFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    setFiles([])
  }

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton' ||
      event.target.id === 'cancelButton'
    ) {
      return setShowUploadDataForm(false);
    }
  };
  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton" onClick={e => handleExit(e)}>
          x
        </Exit>
        <HeaderCont>
          Agregar nuevos registros a la base de datos
        </HeaderCont>
        <BodyCont>
          <Title>Cargar base de datos</Title>
          <DropZone files={files} setFiles={setFiles} isCampaign={false} />
          <FilesButtonsCont>
            {files.map((file, index) => {
              return (
                <FileButton
                  key={index}
                  onClick={e => handleDeleteFile(e)}
                >
                  {file.fileName}
                  <i className='bx bx-trash'></i>
                </FileButton>
              );
            })}
          </FilesButtonsCont>
        </BodyCont>
        <ButtonsCont>
          <CancelButton id='cancelButton' onClick={e => handleExit(e)} isDisabled={false}>Cancelar</CancelButton>
          <SaveButton onClick={e => handleSaveData(e)} isDisabled={!(files.length > 0)}>Guardar</SaveButton>
        </ButtonsCont>
      </FormContainer>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.form`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);

  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const HeaderCont = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  padding: 10px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: center;
  grid-column: 1 / -1;
  background-color: ${lightBackgroundBlue};
`;
const BodyCont = styled.div`
height: 375px;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: ${lightBackgroundBlue};
  margin-bottom: 20px;
  display: grid;
  place-items: center;
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 20px;
`;
const FilesButtonsCont = styled.div`
height: 42px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const FileButton = styled.button`
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid ${lightGreen};

  &:hover {
    color: red;
    border: 1px solid red;
    text-decoration: line-through;
  }

  & > i {
    line-height: 0px;
    font-size: 1.2rem;
    font-weight: 00;
    display: inline-block;
    margin-left: 5px;
    transform: translateY(3px);

  }
`;
const ButtonsCont = styled.div`
height: 60px;
  padding: 0px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  grid-column: 1 / -1;
  background-color: ${lightBackgroundBlue};
`;
const SaveButton = styled.div<ISaveButton>`
  color: white;
  font-size: 1rem;
  font-weight: 700;
  width: 130px;
  border-radius: 50px;
  margin-left: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px solid ${props => props.isDisabled ? darkBorder : primary};
  background-color: ${props => props.isDisabled ? darkBorder : primary};
`;
const CancelButton = styled(SaveButton)`
  color: black;
  font-weight: 300;
  background-color: transparent;
`;
interface ISaveButton {
  isDisabled: boolean;
}
export default UploadDataTemplate;