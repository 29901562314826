import LoadingImg from 'components/Configuration/atoms/loading';
import { TransferCall } from 'icons';
import { danger, darkBorder, lightBackground, primary } from 'library/colors';
import { IUser } from 'library/interfaces/crdInterfaces';
import { IWaCampaign } from 'library/interfaces/whatsappCampaignInterfaces';
import { dateIsoFormated } from 'library/services/dateServices';
import React, { FC } from 'react';
import { ILiveConversationSession } from 'store/liveConversations/reducer';
import styled from 'styled-components';

interface ILiveConversationSessionProps {
  session: ILiveConversationSession
  users: IUser[]
  waCampaigns: IWaCampaign[]
  showChat: (channel: string, source: string, sessionId: string) => void
  showLogs: (sessionId: string) => void
  showUsers: (show: boolean) => void
  setCurrentSessionId: (sessionId: string) => void
  deleteSession: (sessionId: string) => void
  restoreSession: (sessionId: string) => void
}
const LiveConversationSession: FC<ILiveConversationSessionProps> = ({
  session,
  users,
  waCampaigns,
  showChat,
  showLogs,
  showUsers,
  setCurrentSessionId,
  deleteSession,
  restoreSession
}) => {

  const agent = users.find(usr => usr.auth0_id === session.assigned_to)?.name || 'Sin asignar'

  const campaign = waCampaigns.find(camp => camp.id === session.campaign_id)

  const handleReasignSessionClick = () => {
    showUsers(true)
    setCurrentSessionId(session.id)
  }


  // console.log(session)
  // console.log(agentName)
  return (
    <Cont>
      <InformationBlock>
        {session.isLoading && <LoadingImg width={60} />}
        <InfoTitle>Agente</InfoTitle>
        <Info>{agent}</Info>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Cliente</InfoTitle>
        <Info>{session.source_name}<br />{session.source_id}</Info>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Campaña</InfoTitle>
        <Info>{campaign?.name}</Info>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Inicio de sesión</InfoTitle>
        <Info>{dateIsoFormated(session.created_at)}</Info>
        <InfoTitle>Última actualización </InfoTitle>
        <Info>{dateIsoFormated(session.updated_at)}</Info>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Ver chat</InfoTitle>
        <ButtonCont>
          <IconCont>
            <i className='bx bxs-show' onClick={() => showChat(session.channel_id, session.source_id, session.id)} />
          </IconCont>
        </ButtonCont>
      </InformationBlock>
      <InformationBlock>
        <InfoTitle>Ver logs</InfoTitle>
        <ButtonCont>
          <IconCont>
            <i className='bx bxs-show' onClick={() => showLogs(session.id)} />
          </IconCont>
        </ButtonCont>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Reasignar</InfoTitle>
        <ButtonCont>
          <TransferButton onClick={() => handleReasignSessionClick()}>
            <TransferCall width={25} />
          </TransferButton>
        </ButtonCont>
      </InformationBlock>

      {session.active &&
        <InformationBlock>
          <InfoTitle>Cerrar</InfoTitle>
          <ButtonCont>
            <CloseButton danger={true} onClick={() => deleteSession(session.id)}>
              <i className='bx bx-trash'></i>
            </CloseButton>
          </ButtonCont>
        </InformationBlock>}

      {!session.active &&
        <InformationBlock>
          <InfoTitle>Restaurar</InfoTitle>
          <ButtonCont>
            <CloseButton danger={false} onClick={() => restoreSession(session.id)}>
              <i className='bx bx-arrow-back'></i>
            </CloseButton>
          </ButtonCont>
        </InformationBlock>}
    </Cont>
  );
};
const Cont = styled.div`
padding: 10px 10px 5px 0;
border-radius: 10px;
margin-bottom: 10px;
display: grid;
grid-gap: 5px;
grid-template-columns: 1fr 1fr .8fr 1fr .4fr .4fr .4fr .4fr;
  background-color: ${lightBackground};
`;
const InformationBlock = styled.div`
  text-align: center;
  font-size: .7rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const InfoTitle = styled.div`
  
`;
const Info = styled.div`
  /* border: 1px solid; */
  flex-grow: 1;
`;
const IconCont = styled.div`
  font-size: 2rem;

  cursor: pointer;
    color: ${darkBorder};

    &:hover {
      color: black;
    }
`;
const ButtonCont = styled.div`
  /* border: 1px solid; */
  flex-grow: 1;
  display: grid;
  place-items: center;
`;
const TransferButton = styled.div`
color: #fff;
flex-grow: 1;
    width: 100%;
    padding: 6px 0 ;
  border-radius: 50px;
  cursor: pointer;
  background-color: ${darkBorder};

  &:hover {
    background-color: ${primary};
    }
`;

const CloseButton = styled(TransferButton) <ICloseButton>`
  width: 40px;
  font-size: 1.2rem;
  line-height: 0;
  background-color: ${darkBorder};

  &:hover {
    background-color: ${props => props.danger ? danger : primary};
    }
`;
interface ICloseButton {
  danger: boolean
}
export default LiveConversationSession;