import { IWhatsappUsageCounterKeys } from "library/interfaces/reportsInterfaces";

export const whatsappUsageCountersToShow: { [key: string]: IWhatsappUsageCounterKeys[] } = {
  BSE: ['authentication', "marketing", 'templateMsg', 'utility', 'service',],
  CASMU: ['authentication', "marketing", 'templateMsg', 'utility', 'service',]
}

export const emailsReportIsAvailableFor: { [key: string]: true } = {
  BSE: true
}

export const queuesReportIsAvailableFor: { [key: string]: true } = {
  ASESP: true,
  METROPISTAS: true,
}