import {
  ICreateSMSCampaign,
  ICreateSMSCampaignContact,
  ISMSCampaign,
  ISMSCampaignActions,
} from 'library/interfaces/smsCampaignInterface';
import { IError } from 'store/reports/reducer';
import {
  changeSMSCampaignStatusActions,
  changeSMSCampaignStatusFail,
  changeSMSCampaignStatusSuccess,
  GET_SMS_CAMPAIGNS,
  GET_SMS_CAMPAIGNS_FAIL,
  GET_SMS_CAMPAIGNS_SUCCESS,
  GET_SMS_CAMPAIGN_BY_ID,
  GET_SMS_CAMPAIGN_BY_ID_FAIL,
  GET_SMS_CAMPAIGN_BY_ID_SUCCESS,
  IGetSMSCampaignById,
  IGetSMSCampaignByIdFail,
  IGetSMSCampaignByIdSuccess,
  IGetSMSCampaigns,
  IGetSMSCampaignsFail,
  IGetSMSCampaignsSuccess,
  IPostSMSCampaign,
  IPostSMSCampaignFail,
  IPostSMSCampaignSuccess,
  IPostSMSContactGroups,
  IPostSMSContactGroupsFail,
  IPostSMSContactGroupsSuccess,
  IPostSMSContactsFinish,
  IPutChangeSMSCampaignStatus,
  IPutChangeSMSCampaignStatusFail,
  IPutChangeSMSCampaignStatusSuccess,
  POST_SMS_CAMPAIGN,
  POST_SMS_CAMPAIGN_FAIL,
  POST_SMS_CAMPAIGN_SUCCESS,
  POST_SMS_CONTACTS_FINISHED,
  POST_SMS_CONTACT_GROUP,
  POST_SMS_CONTACT_GROUP_FAIL,
  POST_SMS_CONTACT_GROUP_SUCCESS,
  PUT_RUN_SMS_CAMPAIGN,
  PUT_STOP_SMS_CAMPAIGN,
} from './actionTypes';

export const getSMSCampaignsAction = (): IGetSMSCampaigns => ({
  type: GET_SMS_CAMPAIGNS,
});

export const getSMSCampaignsSuccess = (
  SMSCampaigns: ISMSCampaign[],
): IGetSMSCampaignsSuccess => ({
  type: GET_SMS_CAMPAIGNS_SUCCESS,
  payload: { SMSCampaigns },
});

export const getSMSCampaignsFail = (error: IError): IGetSMSCampaignsFail => ({
  type: GET_SMS_CAMPAIGNS_FAIL,
  payload: { error },
});

export const getSMSCampaignByIdAction = (
  SMSCampaignId: string,
): IGetSMSCampaignById => ({
  type: GET_SMS_CAMPAIGN_BY_ID,
  payload: { SMSCampaignId },
});

export const getSMSCampaignByIdSuccess = (
  SMSCampaignId: string,
  SMSCampaign: ISMSCampaign,
): IGetSMSCampaignByIdSuccess => ({
  type: GET_SMS_CAMPAIGN_BY_ID_SUCCESS,
  payload: { SMSCampaignId, SMSCampaign },
});

export const getSMSCampaignByIdFail = (
  SMSCampaignId: string,
  error: IError,
): IGetSMSCampaignByIdFail => ({
  type: GET_SMS_CAMPAIGN_BY_ID_FAIL,
  payload: { SMSCampaignId, error },
});

export const postSMSCampaignAction = (
  SMSCampaign: ICreateSMSCampaign,
): IPostSMSCampaign => ({
  type: POST_SMS_CAMPAIGN,
  payload: { SMSCampaign },
});

export const postSMSCampaignSuccess = (
  SMSCampaign: ISMSCampaign,
): IPostSMSCampaignSuccess => ({
  type: POST_SMS_CAMPAIGN_SUCCESS,
  payload: { SMSCampaign },
});

export const postSMSCampaignFail = (error: IError): IPostSMSCampaignFail => ({
  type: POST_SMS_CAMPAIGN_FAIL,
  payload: { error },
});

export const SMSContactGroupsAction = (
  SMSCampaignId: string,
  contacts: ICreateSMSCampaignContact[][],
): IPostSMSContactGroups => ({
  type: POST_SMS_CONTACT_GROUP,
  payload: { SMSCampaignId, contacts },
});

export const SMSContactGroupsSuccess = (
  SMSCampaignId: string,
  loadPercentage: number,
): IPostSMSContactGroupsSuccess => ({
  type: POST_SMS_CONTACT_GROUP_SUCCESS,
  payload: { SMSCampaignId, loadPercentage },
});

export const SMSContactGroupsFail = (
  SMSCampaignId: string,
  loadPercentage: number,
  contactGroupOfError: ICreateSMSCampaignContact[],
): IPostSMSContactGroupsFail => ({
  type: POST_SMS_CONTACT_GROUP_FAIL,
  payload: { SMSCampaignId, loadPercentage, contactGroupOfError },
});

export const postSMSContactsFinished = (
  SMSCampaignId: string,
): IPostSMSContactsFinish => ({
  type: POST_SMS_CONTACTS_FINISHED,
  payload: { SMSCampaignId },
});

export const putChangeSMSCampaignStatusAction = (
  action: ISMSCampaignActions,
  SMSCampaignId: string,
): IPutChangeSMSCampaignStatus => {
  const actionType: changeSMSCampaignStatusActions =
    action === 'run' ? PUT_RUN_SMS_CAMPAIGN : PUT_STOP_SMS_CAMPAIGN;
  return {
    type: actionType,
    payload: { action, SMSCampaignId, actionType },
  };
};

export const putChangeSMSCampaignStatusSuccess = (
  SMSCampaignId: string,
  SMSCampaign: ISMSCampaign,
  actionType: changeSMSCampaignStatusSuccess,
): IPutChangeSMSCampaignStatusSuccess => ({
  type: actionType,
  payload: { SMSCampaignId, SMSCampaign },
});

export const putChangeSMSCampaignStatusFail = (
  SMSCampaignId: string,
  error: IError,
  actionType: changeSMSCampaignStatusFail,
): IPutChangeSMSCampaignStatusFail => ({
  type: actionType,
  payload: { SMSCampaignId, error },
});
