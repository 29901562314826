import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { darkGrayBg, lightGreen } from 'library/colors';

interface ICallCounterProps {
  count?: number | string;
  label?: string;
  color?: string;
  backgroundColor?: string;
  labelWidth?: string;
  toolTip?: string;
}
const CounterWithLabel: FC<ICallCounterProps> = ({
  count = 0,
  label = '',
  color = 'white',
  backgroundColor = lightGreen,
  labelWidth = '60px',
  toolTip
}) => {
  return (
    <Cont>
      <Count>{count}</Count>
      <Label
        color={color}
        backgroundColor={backgroundColor}
        labelWidth={labelWidth}
      >
        {label}
        {toolTip && <ToolTip>{toolTip}</ToolTip>}
      </Label>
    </Cont>
  );
};
const Cont = styled.div`
  max-width: 100px;
  cursor: default;
`;
const Count = styled.div`
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
`;


const Label = styled.div<ILabel>`
  border: 1px solid ${props => props.backgroundColor};
  width: ${props => props.labelWidth};
  height: 18px;
  font-size: 0.65rem;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  border-radius: 50px;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  

  position: relative;

  &:hover > div {
    display: block;
  }
`;
const toolTipAnimation = keyframes`
  0%{ 
    opacity: 0;
  }
  100%{ 
    opacity: 1;
    transform : translate(50%, -3px);
  }
`;
const ToolTip = styled.div`
    width: 500%;
    max-width: 200px;
    /* min-height: 50px; */
    position: absolute;
    bottom: 100%;
    right: 50%;
    color: #fff;
    font-weight: 300;
    border-radius: 5px;
    padding: 10px;
    transform: translate(50%, 0px);
    background-color: ${darkGrayBg};
    display: none;
    animation: ${toolTipAnimation} .3s forwards;
    box-shadow: 0 0 5px  ${darkGrayBg};
`;
interface ILabel {
  color: string;
  backgroundColor: string;
  labelWidth: string;
}
export default CounterWithLabel;
