import React, { FC } from 'react';
import styled from 'styled-components';
import InfoCont from '../atoms/infoCont';
import Title from '../atoms/title';
import Text from '../atoms/text';

interface IReclamacionDeDañosProps { }
const ReclamacionDeDaños: FC<IReclamacionDeDañosProps> = ({ }) => {
  return (
    <InfoCont translateY='40%'>
      <Title>Reclamación de daños</Title>
      <ol>
        <li>
          <Text>
            Veo que llama para hacer una reclamación de daños. Es importante que sepa que para presentar una reclamación y que esta sea evaluada por el Departamento correspondiente, debe tener los siguientes documentos en formato PDF:
          </Text>
          <ol type='a'>
            <li>Informe de querella con sellos – se gestiona en la comandancia de área a la que pertenezca el cuartel donde realizado la querella. Este documento puede tardar de 5 a 10 días a partir de la fecha de la querella/accidente.</li>
            <li>Copia de la licencia de conducir.</li>
            <li>Copia de la licencia del vehículo.</li>
            <li>Estimado de daños y/o factura de pago por daños.</li>
            <li>Foto del daño ocasionado</li>
          </ol>
        </li>
        <li>
          <Text>
            Pedirle su correo electrónico y dejarle saber que le enviará en un email, las instrucciones de cómo someter una reclamación de daños en línea y el link de acceso a la página web de metropistas. Desde ahí, el cliente, una vez tenga todos los documentos, podrá someter su reclamación.
          </Text>
        </li>
        <li>
          <Text>
            Darle la información de contacto de la Analista de Reclamaciones que estará trabajando su reclamación, una vez sometidos todos los documentos          </Text>
        </li>
      </ol>
      <Text>
        Lilliana Rivera
        <br />
        Oficina: 787-474-5999 ext. 2005
        <br />
        Celular corporativo: 787-307-2311
        <br />
        Lilliana.rivera@metropistas.com
      </Text>
    </InfoCont>
  );
};
const Cont = styled.div``;
export default ReclamacionDeDaños;