import * as React from "react"

export const DataVerification = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M16.43 32.12h-.1c-7.25 0-11.94-3-11.94-7.71V10.59c0-4.68 4.69-7.71 11.94-7.71a.92.92 0 0 1 0 1.84c-4.66 0-10.1 1.54-10.1 5.87v13.82c0 4.33 5.44 5.87 10.1 5.87h.1c4.67 0 10.11-1.54 10.11-5.87v-6.32a.92.92 0 0 1 1.84 0v6.32c0 4.68-4.69 7.71-11.95 7.71Z"
    />
    <path
      fill="currentColor"
      d="M16.43 25.79h-.1c-7.25 0-11.94-3-11.94-7.7a.92.92 0 0 1 1.84 0c0 4.33 5.44 5.86 10.1 5.86h.1c4.67 0 10.11-1.53 10.11-5.86a.92.92 0 0 1 1.84 0c0 4.68-4.69 7.7-11.95 7.7Z"
    />
    <path
      fill="currentColor"
      d="M16.43 18.29h-.1c-7.25 0-11.94-3-11.94-7.7a.92.92 0 1 1 1.84 0c0 4.33 5.44 5.86 10.1 5.86h.1a.92.92 0 1 1 0 1.84ZM22 13.13a1 1 0 0 1-.65-.28l-3.89-3.92a.92.92 0 0 1 1.32-1.29L22 10.9l7.09-7a.92.92 0 1 1 1.29 1.31l-7.75 7.64a.91.91 0 0 1-.63.28Z"
    />
  </svg>
)

