import React from 'react';
import PropTypes from 'prop-types';
import SmallButton from '../../../foundation/buttons/SmallButton';

ModalActions.propTypes = {
  toggleInEdit: PropTypes.func.isRequired,
};

function ModalActions({ toggleInEdit }) {
  return (
    <div className="mt-3">
      <SmallButton text="Save" type="submit" variant="primary" />
      <SmallButton text="Cancel" variant="default" onClick={toggleInEdit} />
    </div>
  );
}

export default ModalActions;
