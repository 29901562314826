import { BlueContModalSC, InputVerticalGroupModalSC, SectionTitleModalSC, SelectModalSC, TextAreaModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';
import { lightBackgroundBlue, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { FC } from 'react';
import { Node } from 'reactflow';
import styled from 'styled-components';

interface IFunctionRequestModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
}
const FunctionRequestModal: FC<IFunctionRequestModalProps> = ({
  selectedNode,
  setSelectedNode,
}) => {
  const { data } = selectedNode;
  const functions = useAppSelector(
    store => store.scenariosV2.functions,
  );

  const handleSelectChange = (value: string) => {
    const selectedFunction = functions.find(func => func.id === value);
    if (!selectedFunction) return;
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        label: value,
        function: selectedFunction,
      },
    };
    setSelectedNode(editedNode);
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    node: Node<INodeData, CustomNodeTypes>,
  ) => {
    const value = event.target.value
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        waitingMessage: value
      },
    };
    setSelectedNode(editedNode);
  }
  return (
    <BlueContModalSC>
      <SectionTitleModalSC>
        Functions
      </SectionTitleModalSC>
      <InputVerticalGroupModalSC>
        <SelectModalSC
          name=""
          id=""
          value={data.function?.id}
          onChange={e => handleSelectChange(e.target.value)}
        >
          <option value="">Select a function</option>
          {functions.map((func, index) => {
            return (
              <option key={index} value={func.id}>
                {func.name}
              </option>
            );
          })}
        </SelectModalSC>
      </InputVerticalGroupModalSC>
      <SectionTitleModalSC>Waiting message</SectionTitleModalSC>
      <TextAreaModalSC
        value={data?.waitingMessage}
        onChange={e => handleTextAreaChange(e, selectedNode)} />
    </BlueContModalSC>
  );
};
export default FunctionRequestModal;
