import {
  ISessionAssigned,
  ISendHSM,
  ISendMessage,
  ISession,
} from 'library/interfaces/conversationInterfaces';
import {
  ADD_NEW_MESSAGE,
  ADD_NEW_SESSION,
  DEL_CLOSE_SESSION,
  GET_CONVERSATION_SESSIONS,
  GET_CONVERSATION_SESSIONS_FAIL,
  GET_CONVERSATION_SESSIONS_SUCCESS,
  GET_CLIENT_MESSAGES,
  GET_CLIENT_MESSAGES_FAIL,
  GET_CLIENT_MESSAGES_SUCCESS,
  IAddNewMessage,
  IAddNewSession,
  IDelCloseSession,
  IGetConversationSessions,
  IGetConversationSessionsFail,
  IGetConversationSessionsSuccess,
  IGetClientMessages,
  IGetClientMessagesFail,
  IGetClientMessagesSuccess,
  IPostSendHSM,
  IPostSendHSMFail,
  IPostSendHSMSuccess,
  IPostSendMessage,
  IPostSendMessageFail,
  IPostSendMessageSuccess,
  ISetSelectedSession,
  POST_SEND_HSM,
  POST_SEND_HSM_FAIL,
  POST_SEND_HSM_SUCCESS,
  POST_SEND_MESSAGE,
  POST_SEND_MESSAGE_FAIL,
  POST_SEND_MESSAGE_SUCCESS,
  SET_SELECTED_SESSION,
  IPutAssingSessionToAgent,
  PUT_ASSING_SESSION_TO_AGENT,
  IPutAssingSessionToAgentSuccess,
  PUT_ASSING_SESSION_TO_AGENT_SUCCESS,
  IPutAssingSessionToAgentFail,
  PUT_ASSING_SESSION_TO_AGENT_FAIL,
  IUpdateWaSesionClientData,
  UPDATE_WA_SESION_CLIENT_DATA,
} from './actionTypes';
import { IError } from 'store/reports/reducer';
import { IGupshupAppTemplate } from 'library/interfaces/whatsappCampaignInterfaces';
import { IConversationEnabledChannel } from 'library/enableSpecificFeatures/conversationWorkspace';

export const getConversationSessionsAction = (
  channels: IConversationEnabledChannel[],
): IGetConversationSessions => ({
  type: GET_CONVERSATION_SESSIONS,
  payload: { channels },
});

/**
 *
 * @param sessions son las sesiones sin asignar - unassigned
 * @param sessionAssigned son las sesiones asignadas al agente
 */
export const getConversationSessionsSuccess = (
  sessions: { waLastKey: string | null; sessions: ISession[] },
  sessionAssigned: {
    lastKey: string | null;
    sessions: ISessionAssigned[];
  },
): IGetConversationSessionsSuccess => ({
  type: GET_CONVERSATION_SESSIONS_SUCCESS,
  payload: { sessionsStore: sessions, sessionsAssignedStore: sessionAssigned },
});

export const getConversationSessionsFail = (
  error: IError,
): IGetConversationSessionsFail => ({
  type: GET_CONVERSATION_SESSIONS_FAIL,
  payload: { error },
});

export const getCLientMessagesAction = (
  channel: string,
  source: string,
  sessionId: string,
  templates: IGupshupAppTemplate[],
): IGetClientMessages => ({
  type: GET_CLIENT_MESSAGES,
  payload: { channel, source, sessionId, templates },
});

export const getCLientMessagesSuccess = (
  message: { [key: string]: string | number }[],
  sessionId: string,
): IGetClientMessagesSuccess => ({
  type: GET_CLIENT_MESSAGES_SUCCESS,
  payload: { message, sessionId },
});

export const getCLientMessagesFail = (
  error: IError,
  sessionId: string,
): IGetClientMessagesFail => ({
  type: GET_CLIENT_MESSAGES_FAIL,
  payload: { error, sessionId },
});

export const postSendMessageAction = (
  sessionId: string,
  message: ISendMessage,
): IPostSendMessage => ({
  type: POST_SEND_MESSAGE,
  payload: { sessionId, message },
});

export const postSendMessageSuccess = (
  sessionId: string,
  message: ISendMessage,
): IPostSendMessageSuccess => ({
  type: POST_SEND_MESSAGE_SUCCESS,
  payload: { sessionId, message },
});

export const postSendMessageFail = (
  sessionId: string,
  error: IError,
): IPostSendMessageFail => ({
  type: POST_SEND_MESSAGE_FAIL,
  payload: { sessionId, error },
});

export const postSendHSMAction = (
  campaignId: string,
  hsm: ISendHSM,
): IPostSendHSM => ({
  type: POST_SEND_HSM,
  payload: { campaignId, hsm },
});

export const postSendHSMASuccess = (
  messageId: string,
): IPostSendHSMSuccess => ({
  type: POST_SEND_HSM_SUCCESS,
  payload: { messageId },
});

export const postSendHSMAFail = (error: IError): IPostSendHSMFail => ({
  type: POST_SEND_HSM_FAIL,
  payload: { error },
});

export const addNewMEssageAction = (newMessage: {
  [key: string]: string | number;
}): IAddNewMessage => ({
  type: ADD_NEW_MESSAGE,
  payload: { newMessage },
});

export const addNewSessionAction = (session: ISession): IAddNewSession => ({
  type: ADD_NEW_SESSION,
  payload: { session },
});

export const delCloseSessionAction = (sessionId: string): IDelCloseSession => ({
  type: DEL_CLOSE_SESSION,
  payload: { sessionId },
});

export const setSelectedSessionAction = (
  sessionId: string,
): ISetSelectedSession => ({
  type: SET_SELECTED_SESSION,
  payload: { sessionId },
});

export const putAssingSessionToAgent = (
  session: ISession,
): IPutAssingSessionToAgent => ({
  type: PUT_ASSING_SESSION_TO_AGENT,
  payload: { session },
});

export const putAssingSessionToAgentSuccess = (
  sessionAssigned: ISessionAssigned,
): IPutAssingSessionToAgentSuccess => ({
  type: PUT_ASSING_SESSION_TO_AGENT_SUCCESS,
  payload: { sessionAssigned },
});

export const putAssingSessionToAgentFail = (
  error: IError,
): IPutAssingSessionToAgentFail => ({
  type: PUT_ASSING_SESSION_TO_AGENT_FAIL,
  payload: { error },
});

export const updateWaSessionClientDataAction = (
  sessionId: string,
  clientData: { [key: string]: string | number },
): IUpdateWaSesionClientData => ({
  type: UPDATE_WA_SESION_CLIENT_DATA,
  payload: { sessionId, clientData },
});
