import {
  SET_SYS_NOTIFICATIONS,
  SHOW_INCOMING_CALL_SYS_NOTIFICATION,
  SHOW_QUEUED_CALLS_NO_ANSWERED_SYS_NOTIFICATION,
  ShowSysNotificationsActionTypes,
  UPDATE_SHOW_SYS_NOTIFICATIONS,
} from './actionTypes';

export interface ISysNotificationsStore {
  areAllowed: boolean;
  showPhoneIncomingCalls: boolean;
  showQueuedCallsNoAnswered: boolean;
  showNewWaSession: boolean;
  showNewWaMessage: boolean;
}

export interface IGlobalStore {
  sysNotifications: ISysNotificationsStore;
}

const INIT_STATE: IGlobalStore = {
  sysNotifications: {
    areAllowed: false,
    showPhoneIncomingCalls: false,
    showQueuedCallsNoAnswered: false,
    showNewWaSession: false,
    showNewWaMessage: false,
  },
};

const global = (
  state = INIT_STATE,
  action: ShowSysNotificationsActionTypes,
): IGlobalStore => {
  switch (action.type) {
    case SET_SYS_NOTIFICATIONS: {
      return {
        ...state,
        sysNotifications: {
          ...state.sysNotifications,
          ...action.payload,
        },
      };
    }
    case UPDATE_SHOW_SYS_NOTIFICATIONS: {
      return {
        ...state,
        sysNotifications: {
          ...state.sysNotifications,
          areAllowed: action.payload.show,
        },
      };
    }
    case SHOW_INCOMING_CALL_SYS_NOTIFICATION: {
      return {
        ...state,
        sysNotifications: {
          ...state.sysNotifications,
          showPhoneIncomingCalls: action.payload.show,
        },
      };
    }
    case SHOW_QUEUED_CALLS_NO_ANSWERED_SYS_NOTIFICATION: {
      return {
        ...state,
        sysNotifications: {
          ...state.sysNotifications,
          showQueuedCallsNoAnswered: action.payload.show,
        },
      };
    }
    default:
      return state;
  }
};
export default global;
