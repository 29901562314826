import React, { FC } from 'react';
import styled from 'styled-components';

import { II18nKeys } from 'library/interfaces/i18nKeys';
import { useTranslation } from 'react-i18next';
import { darkBorder, primary } from 'library/colors';

//
// ******* component with i18n translation *******
//
interface ILeftMenuButtonProps {
  i18nKeys: II18nKeys;
  isSelected: boolean;
  onButtonClick: Function;
  index: number;
  icon: React.ReactNode
}
const LeftMenuButton: FC<ILeftMenuButtonProps> = ({
  i18nKeys,
  isSelected,
  onButtonClick,
  index,
  icon
}) => {
  // i18n translation
  const { namespace, key } = i18nKeys;
  const { t } = useTranslation(namespace);
  const text = t(key);

  return (
    <Button index={index} isSelected={isSelected} onClick={() => onButtonClick(index)}>
      <Icon>
        {icon}
      </Icon>
      {text}
    </Button>
  );
};
const Button = styled.button<IButton>`
  text-align: left;
  padding: 10px 0 10px 5px;
  border: none;
  border-bottom:  ${props=>props.index === 0 && `1px solid ${darkBorder}`};
  background-color: transparent;
  color: ${props => props.isSelected && primary};
`;
const Icon = styled.span`
    width: 20px;
    margin-right: 10px;
    display: inline-block;
`;
interface IButton {
  isSelected: boolean;
  index: number;
}
export default LeftMenuButton;
