import CounterWithLabel from 'components/outbound/atoms/counterWithLabel';
import { danger, darkBorder } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ISMSCounterProps {
  ok: number | string
  fail: number | string
  total: number | string
}
const SMSCounter: FC<ISMSCounterProps> = ({ ok, fail, total }) => {
  return(
    <Cont>
      <CounterWithLabel label='Enviados' count={ok} />
      <CounterWithLabel label='Fallidos' count={fail} backgroundColor={danger}/>
      <CounterWithLabel label='Total' count={total} backgroundColor={darkBorder}/>
    </Cont>
  )
}
const Cont = styled.div`
  flex-grow: 1;
  max-width: 280px;
  display: flex;
  justify-content: space-between;
`;
export default SMSCounter;