import styled from "styled-components";

import { danger, lightBackgroundBlue, primary } from "library/colors";
import { ReactNode } from "react";

export const BlueContModalSC = styled.div`
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
  max-height: 460px;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }

  @media (min-width: 1920px) {
    margin: 15px 20px;
    padding: 10px 20px 20px;
    max-height: 600px;
  }
`;

export const WhiteContModalSC = styled.div`
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  position: relative;
`;

export const SectionTitleModalSC = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
  font-size: .8rem;
  
  @media (min-width: 1920px) {
    font-size: 1.1rem;
   
  }
`;

export const InputVerticalGroupModalSC = styled.div<IInputVerticalGroupModalSCProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.center && 'center'};
  max-width: ${props => props.maxWidth};
  margin: ${props => props.margin ? props.margin : '0 0 20px'};
`;

interface IInputVerticalGroupModalSCProps {
  maxWidth?: string
  margin?: string
  center?: ReactNode
}

export const LabelModalSC = styled.label`
  font-size: 0.7rem;
  line-height: 15px;
  margin: 0;
  
  @media (min-width: 1920px) {
    font-size: .8rem;
    margin-bottom: 5px;
  }
`;

export const InputModalSC = styled.input`
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${primary};

  @media (min-width: 1920px) {
    font-size: 1rem;
    
  }
`;

export const SelectModalSC = styled.select`
width: 100%;
padding: 4.25px;
border-radius: 5px;
background-color: white;
border: 1px solid ${primary};

@media (min-width: 1920px) {
padding: 4.5px;
  font-size: 1rem;
}
`;

export const DelIconModalSC = styled.div`
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 1rem;
  cursor: pointer;

  &:hover{
    color: ${danger}
  }
`;

export const FlexContSBModalSC = styled.div`
  display: flex;
  justify-content: space-between;
  `;
export const AddButtonModalSC = styled.div`
  color: ${primary};
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > span {
    margin-left: 5px;
  }
  & > i {
    font-size: 1rem;
  }

  @media (min-width: 1920px) {
    font-size: .8rem;

    & > i {
      font-size: 1.2rem;
    }
}
`;

export const RadioGroupModalSC = styled.div`
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > input {
    margin-right: 5px;
  }
  & > label {
    margin: 0;
    line-height: 0;
    padding: 0;
    font-size: 0.7rem;
  }
`;

export const TextAreaModalSC = styled.textarea<ITextAreaModalSCProps>`
  width: 100%;
  height: ${props => props.height ? props.height : '80px'};
  resize: none;
  border-radius: 5px;
  border: 1px solid ${primary};
`;

interface ITextAreaModalSCProps {
  height?: string
}

export const CheckBoxButtonContModalSC = styled.label<ICheckBoxButtonContModalSCProps>`
  flex-grow: 1 ;
  border: 1px solid ${primary};
  margin: 0;
  height: 31.5px;
  border-radius: 5px;
  max-width: 45%;
  display: grid;
  place-items: center;
  background-color: ${props => props.isChecked && primary};
  color: ${props => props.isChecked && 'white'};

  & > input {
    display: none;
  }

  @media (min-width: 1920px) {
    height: 36px;
  }
`;

export const InputColorModalSC = styled.input.attrs({ type: "color" })`
  height: 31.5px;

  @media (min-width: 1920px) {
    height: 36px;
  }
`;

interface ICheckBoxButtonContModalSCProps {
  isChecked: boolean
}