import { API, graphqlOperation } from 'aws-amplify';

const config = {
  aws_appsync_graphqlEndpoint:
    'https://sqcfyys44zacjdjwo24njgcknq.appsync-api.us-east-1.amazonaws.com/graphql', // La URL de la API de AppSync
  aws_appsync_region: 'us-east-1', // La región de AWS donde has creado tu API de AppSync
  aws_appsync_authenticationType: 'API_KEY', // El tipo de autenticación que estás utilizando en tu API de AppSync
  aws_appsync_apiKey: 'da2-bckmwgmvgbh5tdnax5vjqybay4', // La clave de API si estás utilizando autenticación basada en API key en tu API de AppSync
};

const query = `
subscription MySubscription ($idx: String!) {
  subscribeSessionEvents(idx: $idx) {
    agentId
    channelId
    idx
    timestamp
    events {
      eventType
      payload
    }
  }
}
`;

export function subscribeWebChat(idx, next, error) {
  API.configure(config);

  return API.graphql(graphqlOperation(query, { idx })).subscribe({
    next: ({ provider, value }) => {
      next(value.data.subscribeSessionEvents.events);
    },
    error: error || console.log,
  });
}
