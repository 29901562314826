import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle } from 'reactstrap';

FormCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function FormCard({ title, children }) {
  return (
    <Card>
      <CardBody>
        {title && <CardTitle className="h4">{title}</CardTitle>}
        {children}
      </CardBody>
    </Card>
  );
}

export default FormCard;
