import React from 'react';
import PropTypes from 'prop-types';

import * as Fields from '../../../../Fields';
import { Col } from 'reactstrap';

ResponseKey.propTypes = {
  name: PropTypes.string.isRequired,
  lg: PropTypes.number,
};

export function ResponseKey({ name, lg = 12 }) {
  return (
    <Col lg={lg}>
      <label>Response key</label>
      <div>
        {[
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '*',
          '#',
          'No',
        ].map(item => (
          <Fields.Radio
            key={item}
            inline={true}
            name={name}
            label={item}
            value={item}
          />
        ))}
      </div>
    </Col>
  );
}
