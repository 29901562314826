import * as React from 'react';

export const TransferCall = props => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 151.41 105.02"
        {...props}
    >
        {/* <title>Recurso 18</title> */}
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_3" data-name="Capa 3">
                <path fill='currentColor' d="M150.14,26.9c.06-.07.1-.14.15-.21l.18-.23.15-.25c0-.07.09-.14.13-.22s.08-.17.13-.26.07-.15.1-.23.07-.18.1-.27l.09-.25c0-.09,0-.18.07-.28l.06-.25c0-.11,0-.22,0-.33l0-.21a5.15,5.15,0,0,0,0-1.11l0-.21c0-.11,0-.22,0-.33l-.06-.25a2.86,2.86,0,0,1-.07-.28l-.09-.25a2,2,0,0,0-.1-.26c0-.08-.07-.16-.1-.23s-.09-.18-.13-.27l-.13-.21-.15-.26-.18-.23c0-.07-.09-.14-.15-.21s-.24-.28-.37-.41L132,1.64a5.59,5.59,0,0,0-7.9,7.9l8.23,8.23H65.15a5.59,5.59,0,0,0,0,11.17h67.18l-8.23,8.23a5.59,5.59,0,0,0,7.9,7.91l17.77-17.77C149.9,27.18,150,27,150.14,26.9Z"/>
                <path fill='currentColor' d="M1.27,78.12a1.56,1.56,0,0,0-.16.2l-.17.24-.15.25c0,.07-.09.14-.13.22a2.1,2.1,0,0,0-.13.26c0,.08-.07.15-.1.23s-.07.18-.1.27L.24,80a2.68,2.68,0,0,0-.07.27l-.06.26c0,.11,0,.22,0,.33a1.52,1.52,0,0,0,0,.21,5.06,5.06,0,0,0,0,1.1,1.52,1.52,0,0,0,0,.21c0,.11,0,.22.05.34L.17,83c0,.09,0,.18.07.28l.09.24c0,.09.06.18.1.27s.07.15.1.23.08.18.13.27l.13.21.15.25.17.24a1.56,1.56,0,0,0,.16.2,4.72,4.72,0,0,0,.37.41L19.4,103.38a5.59,5.59,0,1,0,7.91-7.9l-8.23-8.23H86.25a5.59,5.59,0,0,0,0-11.18H19.08l8.23-8.23a5.59,5.59,0,1,0-7.91-7.9L1.64,77.71A4.72,4.72,0,0,0,1.27,78.12Z"/>
            </g>
        </g>
    </svg>
);