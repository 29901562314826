import { primary } from 'library/colors';
import { IQueue } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

interface IQueueListProps {
  selectedQueue: IQueue
  setSelectedQueue: Function;
}
const QueueList: FC<IQueueListProps> = ({ selectedQueue, setSelectedQueue }) => {
  const queues = useSelector<IStore, IQueue[]>(store => store.configuration.updageAgentsInQueue.queues);
  
  return (
    <Cont>
      {queues.map((queue, index) => {
        return (
          <Queue key={index} isSelected={selectedQueue.id === queue.id} onClick={e => setSelectedQueue(queue)}>
            {queue.name}
          </Queue>
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  height: calc(60vh + 54px);
  width: 200px;
  border-radius: 10px;
  padding: 20px;
  background-color: white;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const Queue = styled.div<IQueueProps>`
  cursor: pointer;
  padding-bottom: 20px;
  color: ${props=>props.isSelected && primary};

  &:hover {
    color: ${primary};
  }
`;
interface IQueueProps {
  isSelected: boolean
}
export default QueueList;
