import IStore from 'library/interfaces/storeInterface';
import {
  getToday_yyyymmdd,
  localDate_formatISO,
  nDaysBeforeNow_LocalTime_formatISO,
} from 'library/services/dateServices';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getReportAction_V2, reportDownloaded } from 'store/actions';
import { typeGetReportActions_V2 } from 'store/reports/actionTypes';
import { IError } from 'store/reports/reducer';
import styled from 'styled-components';
import { date } from 'yup';
import GetReportButton from '../atoms/getReportButton';
import { primary } from 'library/colors';

interface IRulesAndButtonProps {
  maximunDays: number;
  reportKeys: { nameEn: string; nameEs: string, format: string };
  dates: { start: string, end: string }
  action: typeGetReportActions_V2;
  reportState: {
    isLoading: boolean;
    error: IError | null;
    url?: string;
  }
  getReportRefresh: Function
  filters?: string
  selectOpt?: { label: string, value: string }[]
  selectValue?: string;
  onSelectChange?: (value: string) => void
}
const RulesAndButton: FC<IRulesAndButtonProps> = ({
  maximunDays,
  reportKeys,
  dates,
  action,
  reportState,
  getReportRefresh,
  filters,
  selectOpt,
  selectValue,
  onSelectChange
}) => {
  const dispatch = useDispatch()
  const owner = useSelector<IStore, string>(store => store.authUserData.owner)
  const { reportTypeId } = useParams() as any

  const { t } = useTranslation('reports');
  const today = getToday_yyyymmdd();
  const startDate =
    nDaysBeforeNow_LocalTime_formatISO(maximunDays).split('T')[0];

  let START_DATE = dates.start + 'T00:00:00.000Z';
  let END_DATE = dates.end + 'T23:59:59.999Z';
  const buttonText = {
    loadingMessage: t('reportButton.loading'),
    errorMessage: t('reportButton.error'),
    Label: t('reportButton.generateReport', { reportKeys }),
    downloadMessage: t('reportButton.download', { reportKeys }),
    refresh: t('reportButton.refresh')
  };

  const handleGetReport_V2 = () => {
    if (selectValue === '----') return
    if (dates.end === getToday_yyyymmdd()) {
      END_DATE = localDate_formatISO();
    }
    dispatch(getReportAction_V2(owner, START_DATE, END_DATE, reportTypeId, action, filters))
  }

  const handleDownloadReport = () => {
    dispatch(reportDownloaded(`${action}_DOWNLOAD`))
  }
  const handleRefresh = () => {
    getReportRefresh()
  }
  return (
    <Cont>
      <RulesCont>
        <Title>{t('rules.rules')}</Title> <b></b>
        <Rule>{t('rules.general')}</Rule>
        <Rule>{t('rules.timeRange', { maximunDays, today, startDate })}</Rule>
      </RulesCont>
      <ButtonCont>
        {selectOpt && onSelectChange &&
          <Select value={selectValue} onChange={e => onSelectChange(e.target.value)}>
            {selectOpt.map((opt, index) => {
              return <option key={index} value={opt.value}>{t(`botExtension1234Report.selectExtOpt.${opt.label}`)}</option>
            })}
          </Select>}
        <GetReportButton
          error={reportState.error}
          isLoading={reportState.isLoading}
          url={reportState.url}
          buttonText={buttonText}
          onGetReport={handleGetReport_V2}
          onDownloadReport={handleDownloadReport}
          onRefreshReport={handleRefresh}
        />
      </ButtonCont>
    </Cont>
  );
};
const Cont = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;
const RulesCont = styled.div``;
const Title = styled.div`
  font-size: 1.2rem;
`;
const Rule = styled.p`
  margin-bottom: 10px;
`;
const ButtonCont = styled.div`
  flex-grow: 1;
  display: grid;
  align-content: center;
`;
const Select = styled.select`
padding: 5px 0px 5px 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  border: 1px solid ${primary};
  background-color: white;
`;
export default RulesAndButton;
