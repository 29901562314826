import { lightBackground, primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IArrowsButtonsProps {
  moveToLeft: Function;
  moveToRight: Function;
}
const ArrowsButtons: FC<IArrowsButtonsProps> = ({
  moveToLeft,
  moveToRight,
}) => {
  return (
    <Arrows>
      <LeftArrow onClick={e => moveToLeft()} />
      <RightArrow onClick={e => moveToRight()} />
    </Arrows>
  );
};
const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 20vh;
  margin: auto;
`;
const LeftArrow = styled.div`
  width: 15px;
  height: 50px;
  margin: 10px;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  background-color: ${primary};
  display: flex;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid ${lightBackground};
  }
  &:after {
    transform: translate(3px);
    border-right: 6px solid ${primary};
  }
  &:hover {
    background-color: #698203;
  }
`;
const RightArrow = styled(LeftArrow)`
  &:before {
    transform: rotateY(180deg) translate(-1px);
  }
  &:after {
    transform: rotateY(180deg) translate(2px);
  }
`;
export default ArrowsButtons;
