import React, { Component, ErrorInfo } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  fallback?: (error?: string) => React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error: error
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
  }

  render() {
    if (this.state.hasError && this.state.error) {
      return this.props.fallback ? this.props.fallback(this.state.error.stack?.split('(')[0] || '')
        : <Cont><h1>Something went wrong.</h1><h2>Refresh the app!</h2></Cont>;
    }

    return this.props.children;
  }
}

const Cont = styled.div`
  height: 100vh;
  display: grid;
  place-content: center;
  text-align: center;
`;
export default ErrorBoundary;