import { subscribeQueuedCallsData } from 'helpers/appSyncLiveBoard';
import { IReportData } from 'library/interfaces/crdInterfaces';
import { ILiveQueuedCalls } from 'library/interfaces/liveQueuedCallsInterfaces';
import IStore from 'library/interfaces/storeInterface';
import { queuedCallsService } from 'library/services/liveQueuedCallsServices';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportData } from 'store/actions';
import { IAuthUserData } from 'store/authUserData/reducer';
import { ISysNotificationsStore } from 'store/global/reducer';
import QueuedCallsNotifications from '../queuedCallsNotifications/queuedCallsNotifications';

interface ISubscriptionsProps {
  authUserData: IAuthUserData;
  sysNotifications: ISysNotificationsStore;
}
// this component renders if user has 'supervisor' role with conditional render (&&) in app.js
// the idea behind this component is handle all general subscriptions in the app
// in future, with authUserData we can evaluate the scope of the user and make the corresponding subscriptions
const Subscriptions: FC<ISubscriptionsProps> = ({
  authUserData,
  sysNotifications,
}) => {
  const [live, setLive] = useState<ILiveQueuedCalls[]>([]);

  const dispatch = useDispatch();
  const reportData = useSelector<IStore, IReportData>(
    state => state.cdr.reportData,
  );

  const dataToNotify = queuedCallsService(reportData, live);

  // get users and queues
  useEffect(() => {
    if (reportData.users.length === 0) dispatch(getReportData());
  }, []);

  // subscribe queues by owner
  useEffect(() => {
    if (
      !authUserData.owner ||
      reportData.users.length === 0 ||
      !sysNotifications.areAllowed ||
      !sysNotifications.showQueuedCallsNoAnswered
    )
      return;
    const subscription = subscribeQueuedCallsData(
      authUserData.owner.toLowerCase(),
      (data: ILiveQueuedCalls[]) => {
        setLive(data);
      },
    );
    return () => subscription.unsubscribe();
  }, [authUserData, reportData, sysNotifications]);

  return (
    <>
      {dataToNotify.map(queue => {
        return queue.calls.map((call, index) => {
          return <QueuedCallsNotifications call={call} key={index} />;
        });
      })}
    </>
  );
};

export default Subscriptions;
