import { IGupshupAppTemplate } from 'library/interfaces/whatsappCampaignInterfaces';
import { IError } from 'store/reports/reducer';
import {
  ILiveConversationAgents,
  ILiveConversationSession,
  ILiveConversationSessionLogs,
  ILiveConversationsCampaign,
} from './reducer';

export const GET_LIVE_CONVERSATION_SESSION_MESSAGES =
  'GET_LIVE_CONVERSATION_SESSION_MESSAGES';
export const GET_LIVE_CONVERSATION_SESSION_MESSAGES_SUCCESS =
  'GET_LIVE_CONVERSATION_SESSION_MESSAGES_SUCCESS';
export const GET_LIVE_CONVERSATION_SESSION_MESSAGES_FAIL =
  'GET_LIVE_CONVERSATION_SESSION_MESSAGES_FAIL';

export const GET_LIVE_CONVERSATION_SESSION_LOGS =
  'GET_LIVE_CONVERSATION_SESSION_LOGS';
export const GET_LIVE_CONVERSATION_SESSION_LOGS_SUCCESS =
  'GET_LIVE_CONVERSATION_SESSION_LOGS_SUCCESS';
export const GET_LIVE_CONVERSATION_SESSION_LOGS_FAIL =
  'GET_LIVE_CONVERSATION_SESSION_LOGS_FAIL';

export const PUT_LIVE_CONVERSATION_TRANSFER_SESSION =
  'PUT_LIVE_CONVERSATION_TRANSFER_SESSION';
export const PUT_LIVE_CONVERSATION_TRANSFER_SESSION_SUCCESS =
  'PUT_LIVE_CONVERSATION_TRANSFER_SESSION_SUCCESS';
export const PUT_LIVE_CONVERSATION_TRANSFER_SESSION_FAIL =
  'PUT_LIVE_CONVERSATION_TRANSFER_SESSION_FAIL';

export const GET_SESSIONS_FROM_MONITOR = 'GET_SESSIONS_FROM_MONITOR';
export const GET_SESSIONS_FROM_MONITOR_SUCCESS =
  'GET_SESSIONS_FROM_MONITOR_SUCCESS';
export const GET_SESSIONS_FROM_MONITOR_FAIL = 'GET_SESSIONS_FROM_MONITOR_FAIL';

export const DELETE_LIVE_CONVERSATION_SESSION =
  'DELETE_LIVE_CONVERSATION_SESSION';
export const DELETE_LIVE_CONVERSATION_SESSION_SUCCESS =
  'DELETE_LIVE_CONVERSATION_SESSION_SUCCESS';
export const DELETE_LIVE_CONVERSATION_SESSION_FAIL =
  'DELETE_LIVE_CONVERSATION_SESSION_FAIL';

export const GET_AGENTS_CONNECTED_TO_WA = 'GET_AGENTS_CONNECTED_TO_WA';
export const GET_AGENTS_CONNECTED_TO_WA_SUCCESS =
  'GET_AGENTS_CONNECTED_TO_WA_SUCCESS';
export const GET_AGENTS_CONNECTED_TO_WA_FAIL =
  'GET_AGENTS_CONNECTED_TO_WA_FAIL';

export const PUT_RESTORE_WA_SESSION = 'PUT_RESTORE_WA_SESSION';
export const PUT_RESTORE_WA_SESSION_SUCCESS = 'PUT_RESTORE_WA_SESSION_SUCCESS';
export const PUT_RESTORE_WA_SESSION_FAIL = 'PUT_RESTORE_WA_SESSION_FAIL';

export type LiveConversationsActionTypes =
  | IGetLiveConversationSessionMessages
  | IGetLiveConversationSessionMessagesSuccess
  | IGetLiveConversationSessionMessagesFail
  | IGetLiveConversationSessionLogs
  | IGetLiveConversationSessionLogsSuccess
  | IGetLiveConversationSessionLogsFail
  | IPutLiveConversationTransferSession
  | IPutLiveConversationTransferSessionSuccess
  | IPutLiveConversationTransferSessionFail
  | IGetSessionsFromMonitor
  | IGetSessionsFromMonitorSuccess
  | IGetSessionsFromMonitorFail
  | IdeleteLiveConversationSession
  | IdeleteLiveConversationSessionSuccess
  | IdeleteLiveConversationSessionFail
  | IGetAgentsConnectedToWa
  | IGetAgentsConnectedToWaSuccess
  | IGetAgentsConnectedToWaFail
  | IPutRestoreWaSession
  | IPutRestoreWaSessionSuccess
  | IPutRestoreWaSessionFail;

export interface IGetLiveConversationSessionMessages {
  type: typeof GET_LIVE_CONVERSATION_SESSION_MESSAGES;
  payload: {
    channel: string;
    source: string;
    sessionId: string;
    templates: IGupshupAppTemplate[];
  };
}
export interface IGetLiveConversationSessionMessagesSuccess {
  type: typeof GET_LIVE_CONVERSATION_SESSION_MESSAGES_SUCCESS;
  payload: { message: { [key: string]: string | number }[] };
}
export interface IGetLiveConversationSessionMessagesFail {
  type: typeof GET_LIVE_CONVERSATION_SESSION_MESSAGES_FAIL;
  paylaod: { error: IError };
}

export interface IGetLiveConversationSessionLogs {
  type: typeof GET_LIVE_CONVERSATION_SESSION_LOGS;
  payload: { sessionId: string };
}
export interface IGetLiveConversationSessionLogsSuccess {
  type: typeof GET_LIVE_CONVERSATION_SESSION_LOGS_SUCCESS;
  payload: { logs: ILiveConversationSessionLogs[] };
}
export interface IGetLiveConversationSessionLogsFail {
  type: typeof GET_LIVE_CONVERSATION_SESSION_LOGS_FAIL;
  paylaod: { error: IError };
}

export interface IPutLiveConversationTransferSession {
  type: typeof PUT_LIVE_CONVERSATION_TRANSFER_SESSION;
  payload: { sessionId: string; agentId: string };
}
export interface IPutLiveConversationTransferSessionSuccess {
  type: typeof PUT_LIVE_CONVERSATION_TRANSFER_SESSION_SUCCESS;
  payload: { session: ILiveConversationSession };
}
export interface IPutLiveConversationTransferSessionFail {
  type: typeof PUT_LIVE_CONVERSATION_TRANSFER_SESSION_FAIL;
  payload: { sessionId: string; error: IError };
}

export interface IGetSessionsFromMonitor {
  type: typeof GET_SESSIONS_FROM_MONITOR;
  payload: {
    lastKey: string | null;
    isActive?: boolean;
    campaign?: string;
    assignedTo?: string;
  };
}
export interface IGetSessionsFromMonitorSuccess {
  type: typeof GET_SESSIONS_FROM_MONITOR_SUCCESS;
  payload: {
    sessions: ILiveConversationSession[];
    lastKey: string | null;
    campaign?: ILiveConversationsCampaign;
  };
}
export interface IGetSessionsFromMonitorFail {
  type: typeof GET_SESSIONS_FROM_MONITOR_FAIL;
  payload: { error: IError };
}

export interface IdeleteLiveConversationSession {
  type: typeof DELETE_LIVE_CONVERSATION_SESSION;
  payload: { sessionId: string };
}
export interface IdeleteLiveConversationSessionSuccess {
  type: typeof DELETE_LIVE_CONVERSATION_SESSION_SUCCESS;
  payload: { sessionId: string };
}
export interface IdeleteLiveConversationSessionFail {
  type: typeof DELETE_LIVE_CONVERSATION_SESSION_FAIL;
  payload: { error: IError };
}

export interface IGetAgentsConnectedToWa {
  type: typeof GET_AGENTS_CONNECTED_TO_WA;
  payload: { campaignId: string };
}
export interface IGetAgentsConnectedToWaSuccess {
  type: typeof GET_AGENTS_CONNECTED_TO_WA_SUCCESS;
  payload: { agents: ILiveConversationAgents };
}
export interface IGetAgentsConnectedToWaFail {
  type: typeof GET_AGENTS_CONNECTED_TO_WA_FAIL;
}

export interface IPutRestoreWaSession {
  type: typeof PUT_RESTORE_WA_SESSION;
  payload: { sessionId: string };
}
export interface IPutRestoreWaSessionSuccess {
  type: typeof PUT_RESTORE_WA_SESSION_SUCCESS;
  payload: { sessionId: string };
}
export interface IPutRestoreWaSessionFail {
  type: typeof PUT_RESTORE_WA_SESSION_FAIL;
  payload: { error: IError };
}
