import React from 'react';
import NodeModal from '../common/NodeModal';
import PropTypes from 'prop-types';

function Modal({ Icon, data, toggle, isOpen }) {
  return (
    <NodeModal Icon={Icon} data={data} isOpen={isOpen} toggle={toggle}>
      When the scenario reaches this block, the call is considered successful
      (for example, the customer received the required information). The
      successful call completion means that SmartBot will no longer try to call
      this customer during the campaign.
    </NodeModal>
  );
}

Modal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  Icon: PropTypes.any,
};

export default Modal;
