import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import QueueButton from './queueButton';
import {
  filterAddQueue,
  filterRemoveQueue,
  getRecordings,
  getRecordingsByQueueActions,
} from 'store/actions';
import { useTranslation } from 'react-i18next';

const FiltersButtons = ({
  queues,
  owner,
  selectedQueue,
  limit,
  order,
  downloadUrl,
  showFiltersForm,
  postFilters,
  setShowGetReportForm,
}) => {
  const {t} = useTranslation('recordings')
  const [queuesButton, setQueuesButton] = useState([]);

  const dispatch = useDispatch();

  const handleSelectQueue = indexSelected => {
    const newButtonsState = queuesButton.map((item, index) => {
      if (index === indexSelected && item.isActive) {
        dispatch(getRecordings(item.owner_id));
        dispatch(filterRemoveQueue(''));
        return { ...item, isActive: false };
      } else if (index === indexSelected) {
        dispatch(getRecordingsByQueueActions(owner, item.id, limit, order));
        dispatch(filterAddQueue(item.id));
        return { ...item, isActive: true };
      }
      return { ...item, isActive: false };
    });
    setQueuesButton(newButtonsState);
  };

  const handleReportsButtonClick = () => {
    setShowGetReportForm(true);
  };

  useEffect(() => {
    const queuesButtonData = queues.map(item => {
      return {
        ...item,
        isActive: false,
      };
    });
    setQueuesButton(queuesButtonData);
  }, [queues]);
  return (
    <>
      <div>
        {queuesButton &&
          queuesButton.map((queue, index) => (
            <QueueButton
              queue={queue}
              key={index}
              index={index}
              handleIsActive={handleSelectQueue}
            />
          ))}
      </div>
      <div>
        <Button
          type="button"
          color="primary"
          className="btn-rounded me-2"
          onClick={() => showFiltersForm(true)}
        >
          <i className="bx bx-filter me-1" />
          {t('filter')}
        </Button>
        {/* <Button
          type="button"
          color="secondary"
          className="btn-rounded"
          onClick={() => handleReportsButtonClick()}
        >
          <i className="bx bx-download me-1" />
          Reports
        </Button> */}
      </div>
    </>
  );
};
export default FiltersButtons;
