import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useField, useFormikContext } from 'formik';

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export function Switch({ name, label, ...props }) {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleClick = useCallback(() => {
    setFieldValue(name, !field.value);
  }, []);

  return (
    <div className="col-md-12">
      <div className="form-check form-switch mb-3" dir="ltr">
        <input
          type="checkbox"
          className="form-check-input"
          id={name}
          {...field}
          {...props}
        />
        <label
          className="form-check-label"
          htmlFor={name}
          onClick={handleClick}
        >
          {label}
        </label>
      </div>
      <ErrorMessage name={name} component="span" />
    </div>
  );
}
