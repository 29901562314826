import { createSlice } from '@reduxjs/toolkit';
import {
  IDatabase,
  IDatabaseRow,
} from 'library/interfaces/databasesInterfaces';
import {
  deleteDatabase,
  deleteRows,
  getDatabases,
  getRowByRowId,
  getRowsByDatabaseId,
  postCreateDatabase,
  postRows,
  putUpdateDatabase,
  updateRow,
} from './asyncActions';

export interface ISelectedDatabaseState {
  isLoading: boolean;
  error: string | null;
  lastKey: string | null;
  rows: IDatabaseRow[];
  percent: number | null;
}
export interface IDatabasesState {
  isLoading: boolean;
  databases: IDatabase[];
  error: string | null;
  selectedDatabase: ISelectedDatabaseState;
}

const initialState: IDatabasesState = {
  isLoading: false,
  databases: [],
  error: null,
  selectedDatabase: {
    isLoading: false,
    error: null,
    lastKey: null,
    rows: [],
    percent: null,
  },
};

const databasesSlice = createSlice({
  name: 'databases',
  initialState,
  reducers: {
    updatePencent: (state, action) => {
      state.selectedDatabase.percent = action.payload;
    },
    clearRows: state => {
      state.selectedDatabase.lastKey = null;
      state.selectedDatabase.rows = [];
    },
  },
  extraReducers: builder => {
    builder
      // get all
      .addCase(getDatabases.pending, state => {
        state.isLoading = true;
        state.error = null;
        state.databases = [];
      })
      .addCase(getDatabases.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.databases = [...action.payload];
      })
      .addCase(getDatabases.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // get rows
      .addCase(getRowsByDatabaseId.pending, state => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: true,
          error: null,
        };
      })
      .addCase(getRowsByDatabaseId.fulfilled, (state, action) => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          error: null,
          rows: [...state.selectedDatabase.rows, ...action.payload.rows],
          lastKey: action.payload.lastKey,
        };
      })
      .addCase(getRowsByDatabaseId.rejected, (state, action) => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          error: action.error.message || null,
        };
      })
      // get row by row id
      .addCase(getRowByRowId.pending, state => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: true,
          error: null,
        };
      })
      .addCase(getRowByRowId.fulfilled, (state, action) => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          error: null,
          rows: [action.payload],
          lastKey: null,
        };
      })
      .addCase(getRowByRowId.rejected, (state, action) => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          error: action.error.message || null,
        };
      })
      // create database
      .addCase(postCreateDatabase.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postCreateDatabase.fulfilled, state => {
        state.error = null;
      })
      .addCase(postCreateDatabase.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      // update database
      .addCase(putUpdateDatabase.pending, state => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: true,
          rows: [],
          lastKey: null,
        };
      })
      .addCase(putUpdateDatabase.fulfilled, state => state)
      .addCase(putUpdateDatabase.rejected, (state, action) => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          percent: null,
          error: action.error.message || null,
        };
      })
      // add rows
      .addCase(postRows.pending, state => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: true,
          error: null,
          percent: 0,
        };
      })
      .addCase(postRows.fulfilled, state => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          error: null,
          percent: null,
          rows: [],
        };
      })
      .addCase(postRows.rejected, (state, action) => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          percent: null,
          error: action.error.message || null,
        };
      })
      //update row
      .addCase(updateRow.pending, state => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: true,
          error: null,
        };
      })
      .addCase(updateRow.fulfilled, (state, action) => {
        const editedRows = [...state.selectedDatabase.rows];
        editedRows[action.payload.index] = action.payload.row;
        return {
          ...state,
          selectedDatabase: {
            ...state.selectedDatabase,
            isLoading: false,
            error: null,
            rows: editedRows,
          },
        };
      })
      .addCase(updateRow.rejected, (state, action) => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          percent: null,
          error: action.error.message || null,
        };
      })
      // delete rows
      .addCase(deleteRows.pending, state => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: true,
          error: null,
        };
      })
      .addCase(deleteRows.fulfilled, (state, action) => {
        const editedRows = state.selectedDatabase.rows.filter(
          item => !action.payload.some(id => item['id'] === id.id),
        );
        return {
          ...state,
          selectedDatabase: {
            ...state.selectedDatabase,
            isLoading: false,
            error: null,
            rows: editedRows,
          },
        };
      })
      .addCase(deleteRows.rejected, (state, action) => {
        state.selectedDatabase = {
          ...state.selectedDatabase,
          isLoading: false,
          percent: null,
          error: action.error.message || null,
        };
      })
      // delete database
      .addCase(deleteDatabase.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteDatabase.fulfilled, (state, action) => {
        const newDbs = state.databases.filter(db => db.id !== action.payload);
        return {
          ...state,
          isLoading: false,
          error: null,
          databases: newDbs,
        };
      })
      .addCase(deleteDatabase.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});
export const { updatePencent, clearRows } = databasesSlice.actions;
export default databasesSlice.reducer;
