import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { blueGraph, secondary, danger } from 'library/colors';

interface IProgressBarGraph {
  porcent: number;
  maxWidth?: number;
  center?: React.ReactNode;
  dangerBg?: React.ReactNode;
}
const ProgressBarGraph: FC<IProgressBarGraph> = ({ porcent, maxWidth, center, dangerBg }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [graphWidth, setGraphWidth] = useState<number>(0);

  //the graph is a circle. Its render the 50%.
  //So, the movement is the porcent / 2
  const movementPorcent = porcent / 2;
  const barWidht = 13;

  const updateWidth = () => {
    const currentWidht = containerRef.current?.clientWidth;
    setGraphWidth(currentWidht ? currentWidht : 0);
  };

  useEffect(() => {
    const initialWidth = containerRef.current?.clientWidth
      ? containerRef.current?.clientWidth
      : 0;
    setGraphWidth(initialWidth);
    window.addEventListener('resize', () => updateWidth());
    return window.removeEventListener('resize', () => updateWidth());
  }, []);
  return (
    <Container ref={containerRef} maxWidth={maxWidth} barWidth={barWidht} graphWidth={graphWidth}>
      <GraphContainer>
        <Graph center={center} porcent={50} barWidth={barWidht} graphWidth={graphWidth}>
          <Label>
            <span>{porcent}</span>%
          </Label>
        </Graph>
        {porcent > 0 && (
          <Graph
            center={center}
            barWidth={barWidht}
            graphWidth={graphWidth}
            porcent={movementPorcent}
            backgroundColor={dangerBg ? danger : blueGraph}
          />
        )}
      </GraphContainer>
    </Container>
  );
};
const Container = styled.div<IContainer>`
  color: black;
  overflow: hidden;
  max-width: ${props=>props.maxWidth && props.maxWidth}px;
  padding-bottom: ${props => props.barWidth / 2}px;
`;
const GraphContainer = styled.div`
  aspect-ratio: 2/1;
  position: relative;
`;
const Graph = styled.div<IGraph>`
  position: absolute;
  width: 100%;
  aspect-ratio: 1;
  display: inline-grid;
  place-content: center;
  margin: 0;

  &:before {
    content: '';
    width: 100%;
    aspect-ratio: 1;
    position: absolute;
    border-radius: 50%;
    transition: background 0.25s, transform 0s;
    transform: rotate(${props => (props.rotate ? -props.rotate : '-90')}deg);
    background: conic-gradient(
        ${props => (props.backgroundColor ? props.backgroundColor : secondary)}
          ${props => props.porcent}%,
        transparent 0
      ),
      radial-gradient(
          farthest-side,
          ${props =>
              props.backgroundColor ? props.backgroundColor : secondary}
            90%,
          #0000
        )
        top/ ${props => props.barWidth}px ${props => props.barWidth}px no-repeat;
    -webkit-mask: radial-gradient(
      farthest-side,
      transparent calc(100% - ${props => props.barWidth}px),
      red ${props=>props.center ? 'calc(100% - 12px)' : 'calc(100% - 12px)'}
    );
    mask: radial-gradient(
      farthest-side,
      transparent calc(100% - ${props => props.barWidth}px),
      red ${props=>props.center ? 'calc(100% - 12px)' : 'calc(100% - 12px)'}
    );
  }
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    transition: background-color 0.25s, transform 0s;
    inset: calc(50% - ${props => props.barWidth}px / 2);
    background-color: ${props =>
      props.backgroundColor ? props.backgroundColor : secondary};
    transform: rotate(
        calc(
          (${props => props.porcent}*3.6deg) -
            ${props => (props.rotate ? props.rotate : '90')}deg
        )
      )
      translateY(calc(50% - ${props => props.graphWidth}px / 2));
  }
`;
const Label = styled.div`
  font-size: 17px;
  transform: translate(8%, -15%);

  & > span {
    font-size: 40px;
    font-weight: 900;
  }
`;
interface IContainer {
  barWidth: number;
  graphWidth: number;
  maxWidth?: number;
}
interface IGraph {
  porcent: number;
  barWidth: number;
  graphWidth: number;
  backgroundColor?: string;
  rotate?: number;
  center?: React.ReactNode;
}
export default ProgressBarGraph;
