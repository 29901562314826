import * as React from 'react';

export const Message = props => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 146.96 173.6"
        {...props}
    >
        {/* <title>Recurso 14</title> */}
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_6" data-name="Capa 6">
                <path 
                fill='currentColor' 
                d="M0,173.6V30.83A30.86,30.86,0,0,1,30.83,0h85.3A30.86,30.86,0,0,1,147,30.83V90.26a30.86,30.86,0,0,1-30.83,30.83H52.49ZM30.83,10A20.85,20.85,0,0,0,10,30.83V149.45l38.34-38.36h67.79A20.86,20.86,0,0,0,137,90.26V30.83A20.85,20.85,0,0,0,116.13,10Z"/>
            </g>
        </g>
    </svg>
);