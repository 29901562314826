import * as React from "react"

export const FunctionRequest = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M6.45 32.21a4 4 0 0 1-3.59-2.32 1.25 1.25 0 0 1 2.22-1.15 1.55 1.55 0 0 0 1.47 1 1.32 1.32 0 0 0 1.15-.84c.39-1.11 2.29-8.58 5.64-22.22l.22-.9a3.61 3.61 0 0 1 3.46-3 4.47 4.47 0 0 1 4.27 2.92 1.25 1.25 0 1 1-2.39.73 2 2 0 0 0-1.82-1.15c-.35 0-.86.13-1.09 1.09l-.23.9c-3.41 13.91-5.28 21.25-5.7 22.45a3.79 3.79 0 0 1-3.33 2.48Z"
    />
    <path
      fill="currentColor"
      d="M31 32.21a1.23 1.23 0 0 1-1-.5L19.93 18.24H6.59a1.25 1.25 0 1 1 0-2.5h14a1.22 1.22 0 0 1 1 .5L32 30.21a1.25 1.25 0 0 1-1 2Z"
    />
    <path
      fill="currentColor"
      d="M20.09 32.21a1.25 1.25 0 0 1-1-2L30 16.22a1.25 1.25 0 1 1 2 1.54l-10.93 14a1.28 1.28 0 0 1-.98.45Z"
    />
  </svg>
)

