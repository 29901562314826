import * as React from "react"

export const AnswerRecognition = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M11.63 31.58H9.35a5.94 5.94 0 0 1-5.93-5.93v-2.29a.92.92 0 0 1 1.84 0v2.29a4.09 4.09 0 0 0 4.09 4.09h2.28a.92.92 0 0 1 0 1.84ZM25.65 31.58h-2.28a.92.92 0 1 1 0-1.84h2.28a4.09 4.09 0 0 0 4.09-4.09v-2.29a.92.92 0 0 1 1.84 0v2.29a5.94 5.94 0 0 1-5.93 5.93ZM4.34 12.55a.92.92 0 0 1-.92-.92V9.35a5.94 5.94 0 0 1 5.93-5.93h2.28a.92.92 0 0 1 0 1.84H9.35a4.09 4.09 0 0 0-4.09 4.09v2.28a.92.92 0 0 1-.92.92ZM30.66 12.55a.92.92 0 0 1-.92-.92V9.35a4.09 4.09 0 0 0-4.09-4.09h-2.28a.92.92 0 1 1 0-1.84h2.28a5.94 5.94 0 0 1 5.93 5.93v2.28a.92.92 0 0 1-.92.92ZM17.5 28.23a.92.92 0 0 1-.92-.92V7.69a.92.92 0 0 1 1.84 0v19.62a.92.92 0 0 1-.92.92ZM11.63 22.35a.92.92 0 0 1-.91-.92v-7.86a.92.92 0 1 1 1.83 0v7.86a.92.92 0 0 1-.92.92ZM23.37 22.35a.92.92 0 0 1-.92-.92v-7.86a.92.92 0 1 1 1.83 0v7.86a.92.92 0 0 1-.91.92Z"
    />
  </svg>
)


