import * as React from 'react';

export const SuccessfulCall = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M95.51 81.814c-4.1-4.107-10.816-4.107-14.922 0L69.395 93.007c-7.197-4.162-14.367-9.618-21.027-16.278S36.252 62.9 32.091 55.698l11.193-11.189c4.101-4.106 4.101-10.82 0-14.922L32.09 18.393c-4.102-4.101-10.817-4.101-14.923 0l-5.884 5.884C-3.138 38.7 5.217 70.432 29.941 95.157s56.457 33.073 70.88 18.651l5.883-5.879c4.101-4.106 4.101-10.82 0-14.922zM74.934 28.489l17.568 18.023 35.573-35.1"
      fill="none"
      stroke="#7c9904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={8.437341}
    />
  </svg>
);
