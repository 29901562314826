import { lightBackgroundBlue } from 'library/colors';
import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import HorizontalForm from '../molecules/horizontalForm';
import SelectableButtonFilter from '../molecules/selectableButtonsFilter';
import { BotsExt, ownerBotsConfig, ownersWithConfig } from '../templates/reports/byBotExtension';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';

interface IEventsByBotDetailedFormProps {
  formValues: {
    start_date: string,
    end_date: string
    exten: string
  }
  setFormValues: Function
  handleFormButtonClick: Function
  isDisable?: boolean
}
const EventsByBotDetailedForm: FC<IEventsByBotDetailedFormProps> = ({
  formValues,
  setFormValues,
  handleFormButtonClick,
  isDisable
}) => {
  const { t } = useTranslation('reports');

  const { owner, exten } = useAppSelector(store => store.authUserData)

  const extensions = useMemo(() => {
    return BotsExt[ownerBotsConfig(ownersWithConfig, owner, exten)] || []
  }, [owner, exten])

  const handleExtenChange = (value: string) => {
    setFormValues({ ...formValues, exten: value })
  }

  return (
    <Cont>
      <SelectCont>
        <label htmlFor='exten'>Selecciona la extensión</label>
        <select id="exten" onChange={e => handleExtenChange(e.target.value)}>
          {extensions.map((ext, index) => {
            return (
              <option key={index} value={ext.value}>{t(`botExtension1234Report.selectExtOpt.${ext.label}`)}</option>
            )
          })}
        </select>
      </SelectCont>
      <HorizontalForm isDisable={isDisable} formValues={formValues} setFormValues={setFormValues} onSubmit={handleFormButtonClick} />
    </Cont>
  );
};
const Cont = styled.div`
  border-radius: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: ${lightBackgroundBlue};
`;
const SelectCont = styled.div`
  margin: 0 20px 0 0;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  

  &>label{
    font-size: .7rem;
  }

  & > select {
    margin: 0 0 5px;
    height: 44px;
    font-size: 1rem;
    border: none;
    padding: 10px 10px;
    flex-grow: 1;
    max-width: 230px;
    background-color: white;
  }
`;
export default EventsByBotDetailedForm;