import {
  AGENT_ACTION_FAIL,
  GET_AGENTS_SUCCESS,
  GET_AGENT_SUCCESS,
  ADD_AGENT_SUCCESS,
  UPDATE_AGENT_SUCCESS,
  DELETE_AGENT_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  agentList: [],
  agent: null,
  isOnline: false,
  error: {},
};

const agents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AGENT_ACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        agentList: action.payload,
      };
    case GET_AGENT_SUCCESS:
      return {
        ...state,
        agent: action.payload,
      };
    case ADD_AGENT_SUCCESS: {
      let nagent = action.payload.agent;
      nagent.id = action.payload.id;
      return {
        ...state,
        agentList: [...state.agentList, nagent],
      };
    }
    case UPDATE_AGENT_SUCCESS: {
      let agent = action.payload.agent;
      agent.id = action.payload.id;
      return {
        ...state,
        agentList: state.agentList.map(f =>
          f.id === agent.id ? { f, ...agent } : f,
        ),
      };
    }
    case DELETE_AGENT_SUCCESS:
      return {
        ...state,
        agentList: state.agentList.filter(agent => agent.id !== action.payload),
      };
    default:
      return state;
  }
};

export default agents;
