import IStore from 'library/interfaces/storeInterface';
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IAuthUserDataStore } from 'store/authUserData/reducer';
import styled from 'styled-components';
import AnimatedArrowToShowMenu from './animatedArrowToShowMenu';
import ProfileMenuOptions from './profileMenuOptions';
import { Phone } from 'library/phone/phone';

interface IProfileMenuProps {
  phone?: Phone
}
const ProfileMenu: FC<IProfileMenuProps> = ({ phone }) => {
  const userData = useSelector<IStore, IAuthUserDataStore>(
    store => store.authUserData,
  );
  const [showMenu, setShowMenu] = useState(false);

  const isSupervisor = useMemo(() => {
    return userData.roles.some(rol => rol.name === 'supervisor')
  }, [userData])

  const handleShowMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (
      !(event.target instanceof HTMLElement) ||
      !event.target.className?.includes('menu-Toggle')
    )
      return;
    setShowMenu(prev => !prev);
  };
  return (
    <Cont className="menu-Toggle" onClick={e => handleShowMenu(e)}>
      <ImgCont className="menu-Toggle">
        <img className="menu-Toggle" src={userData.picture} alt="userPic" />
      </ImgCont>
      <UserName className="menu-Toggle">
        {userData.display_name}
        <AnimatedArrowToShowMenu className="menu-Toggle" isUp={showMenu} />
      </UserName>
      {<ProfileMenuOptions show={showMenu} isSupervisor={isSupervisor} phone={phone} />}
    </Cont>
  );
};
const Cont = styled.div`
  padding: 0 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
`;
const ImgCont = styled.div`
  width: 36px;
  height: 36px;
  padding: 3px;

  & > img {
    max-width: 100%;
    border-radius: 50%;
  }
`;
const UserName = styled.div`
  margin-left: 10px;
  display: none;
  align-items: center;

  @media(min-width: 1200px) {
    display: flex;
  }
`;
export default ProfileMenu;
