import * as React from 'react';

export const History = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.348 66.151c0-30.276 24.55-54.825 54.826-54.825S129 35.876 129 66.15s-24.55 54.826-54.826 54.826c-19.35 0-36.397-10.004-46.138-25.142"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.5817}
    />
    <path
      d="m3.29 46.801 16.058 19.35 20.075-19.35M71.805 27.517v39.358l18.823 18.89"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.5817}
    />
  </svg>
);
