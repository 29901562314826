import CircularGraph from 'components/Report/atoms/circularGraph';
import Indicator from 'components/Report/molecules/indicator';
import WhatsappUsageDetail from 'components/Report/organisms/whatsappUsageDetail';
import WhatsappUsageForm from 'components/Report/organisms/whatsappUsageForm';
import { lightBackgroundBlue, primary } from 'library/colors';
import { whatsappUsageCountersToShow } from 'library/enableSpecificFeatures/reports';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { getToday_yyyymmdd } from 'library/services/dateServices';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGupshupAppListAction, getWhatsappUsageAction } from 'store/actions';
import styled from 'styled-components';

interface IWhatsappUsageProps { }
const WhatsappUsage: FC<IWhatsappUsageProps> = ({ }) => {
  const { t } = useTranslation('reports')

  const dispatch = useAppDispatch()
  const { partnerAppUsageList, counter, isLoading } = useAppSelector(store => store.reports.whatsappUsage)
  const owner = useAppSelector(store => store.authUserData.owner)

  const observer = useRef<IntersectionObserver>()
  const [numberOfElements, setNumberOfElements] = useState(10)

  const [showDetails, setShowDetails] = useState(false)
  const [form, setForm] = useState(() => ({
    start_date: getToday_yyyymmdd(),
    end_date: getToday_yyyymmdd(),
    appId: ''
  }));

  const labelsToRender = useMemo(() => {
    if (!counter || !owner) return []
    const keys = whatsappUsageCountersToShow[owner]
    if (!keys) return []
    const data = keys.map(key => {
      return {
        value: counter[key].counter,
        color: counter[key].color,
        label: t(`whatsappUsage.${key}`),
      }
    })
    return data
  }, [counter, owner, t])

  const usageToRender = useMemo(() => {
    const sliceEnd = numberOfElements
    const withData = partnerAppUsageList.filter(item =>
      whatsappUsageCountersToShow[owner].some(key => item[key] !== 0)
    )
    return withData.slice(0, sliceEnd)
  }, [partnerAppUsageList, numberOfElements])

  const lastElementRef = useCallback((node: any) => {

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setNumberOfElements(prev => prev + 10)
      }
    })
    if (node) observer.current.observe(node)
  }, [])

  const handleFormButtonClick = () => {
    if (form.appId === '') return
    dispatch(getWhatsappUsageAction(form.appId, form.start_date, form.end_date))
  }

  useEffect(() => {
  }, [])
  useEffect(() => {
    dispatch(getGupshupAppListAction())
  }, [])
  return (
    <Cont>
      <WhatsappUsageForm isDisable={!form.appId || isLoading} formValues={form} setFormValues={setForm} handleFormButtonClick={handleFormButtonClick} />
      <BlueCont>
        <Title>
          {t('whatsappUsage.summary')}
        </Title>
        <WhiteCont>
          <GraphCont>

            <CircularGraph graphData={labelsToRender.filter(item => item.label !== t(`whatsappUsage.marketing`))} />

          </GraphCont>

        </WhiteCont>
        <WhiteCont grid='3 / 5'>
          {labelsToRender.map((data, index) => {
            return (
              <Indicator key={index} data={data} />
            )
          })}
        </WhiteCont>
      </BlueCont>
      <BlueCont>
        <EventDetailedHeader >
          <AddResponseButton onClick={() => setShowDetails(!showDetails)}>
            <span>{t(`whatsappUsage.showDetailedUsage`)}</span>
            <i className="bx bxs-plus-circle" />
          </AddResponseButton>
        </EventDetailedHeader>

        {showDetails && whatsappUsageCountersToShow[owner] &&
          <ListHeaders columnsNumber={whatsappUsageCountersToShow[owner].length + 1}>
            <div>{t('whatsappUsage.date')}</div>
            {whatsappUsageCountersToShow[owner].map((item, index) => {
              return (
                <div key={index}>{t(`whatsappUsage.${item}`)}</div>
              )
            })}
          </ListHeaders>}

        {showDetails && usageToRender.map((dailyUsage, index) => {
          if (usageToRender.length === index + 1) {
            return (
              <LastElement key={index} ref={lastElementRef}>
                <WhatsappUsageDetail usage={dailyUsage} columns={whatsappUsageCountersToShow[owner]} />
              </LastElement>
            )
          }
          return (
            <WhatsappUsageDetail usage={dailyUsage} key={index} columns={whatsappUsageCountersToShow[owner]} />
          )
        })}

      </BlueCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${lightBackgroundBlue};

`;
const Title = styled.h2`
  grid-column: 1 / -1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;
const WhiteCont = styled.div<IWhiteCont>`
  height: 100%;
  grid-column: ${props => props.grid ? props.grid : '1 / 3'};
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;

const GraphCont = styled.div`
  padding: 10px 10% 0;
`;
const EventDetailedHeader = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &>div {
    margin-right: 20px;
  }
`;
const AddResponseButton = styled.div`
margin: 11px 0;
  color: ${primary};
  display: flex;
  align-items: center;
  cursor: pointer;

  & > span {
    margin-right: 5px;
  }
  & > i {
    font-size: 1rem;
  }
`;
const ListHeaders = styled.div<IListHeader>`
  grid-column: 1 / -1;
display: grid;
place-items: center;
grid-template-columns: repeat(${props => props.columnsNumber}, 1fr) ;
`;
const LastElement = styled.div`
  grid-column: 1 / -1;
`;
interface IWhiteCont {
  grid?: string
}
interface IListHeader {
  columnsNumber: number
}
export default WhatsappUsage;