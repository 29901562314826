import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getRecordingsFromReportModuleAction,
  getReportTotalCallsByOriginAction,
} from 'store/actions';
import { IRestReport } from 'store/reports/reducer';
import IStore from 'library/interfaces/storeInterface';
import { lightBackgroundBlue } from 'library/colors';
import {
  getFirstDayOfCurrentMonth_yyyymmdd,
  getToday_yyyymmdd,
  inputDateFormat_toRenderFormat,
  localDate_formatISO,
  nDaysBeforeNow_LocalTime_formatISO,
} from 'library/services/dateServices';
import {
  graphDataToRender,
  indicatorsToRender,
} from 'library/services/reportsServices/totalCallByOriginServices';

import ButtonsFiltersAndForm from '../../organisms/buttonsFiltersAndForm';
import IndicatorsLinkedWithCircularGraph from '../../organisms/indicatorsLinkedWithCircularGraph';
import { useHistory } from 'react-router-dom';
import CallListPopUp from 'components/Report/organisms/callListPopUp';
import { useTranslation } from 'react-i18next';
import { IAuthUserData } from 'store/authUserData/reducer';
import { useAppSelector } from 'library/hooks/useAppSelector';

interface ITotalCallByOriginTemplateProps { }
const TotalCallByOriginTemplate: FC<ITotalCallByOriginTemplateProps> = ({ }) => {
  const { t } = useTranslation('reports')
  const history = useHistory();

  const dispatch = useDispatch();
  const { owner, exten } = useSelector<IStore, IAuthUserData>(store => store.authUserData);
  const { reportData, isLoading } = useAppSelector(
    store => store.reports.totalCallByOrigin,
  );

  const [selectableButtons, setSelectableButton] = useState(INIT_BUTTONS_STATE);
  const [form, setForm] = useState(() => ({
    start_date: getToday_yyyymmdd(),
    end_date: getToday_yyyymmdd(),
  }));
  const [showPopUp, setShowPopUp] = useState<{
    show: boolean;
    filter: string | null;
    source: string | null;
  }>({
    show: false,
    filter: null,
    source: null
  });

  let START_DATE = form.start_date + 'T00:00:00.000Z';
  let END_DATE = form.end_date + 'T23:59:59.999Z';

  const handleSelectableButtonClick = (index: number) => {
    if (isLoading) return;
    const newButtonsState = selectableButtons.map((button, ind) => {
      if (index === ind) return { ...button, isSelected: true };
      return { ...button, isSelected: false };
    });

    END_DATE = localDate_formatISO();
    START_DATE =
      newButtonsState[index].value === 1
        ? getToday_yyyymmdd()
        : newButtonsState[index].value === 31
          ? getFirstDayOfCurrentMonth_yyyymmdd()
          : nDaysBeforeNow_LocalTime_formatISO(
            newButtonsState[index].value,
          ).split('T')[0];

    dispatch(
      getReportTotalCallsByOriginAction(
        owner.toLocaleLowerCase(),
        START_DATE + 'T00:00:00.000Z',
        END_DATE,
        exten
      ),
    );
    setSelectableButton(newButtonsState);
    setForm({
      start_date: START_DATE.split('T')[0],
      end_date: END_DATE.split('T')[0],
    });
  };

  const handleFormSearchClick = () => {
    if (isLoading) return;
    let END_DAY = form.end_date + 'T23:59:59:999Z';
    let START_DAY = form.start_date + 'T00:00:00:000Z';

    if (form.end_date === getToday_yyyymmdd()) {
      END_DAY = localDate_formatISO();
    }

    const newButtonsState = selectableButtons.map(button => {
      return { ...button, isSelected: false };
    });
    setSelectableButton(newButtonsState);
    dispatch(
      getReportTotalCallsByOriginAction(
        owner.toLocaleLowerCase(),
        START_DAY,
        END_DAY,
        exten
      ),
    );
  };

  const selectedIndicator = (indicartor: string, source: string) => {
    if (isLoading || source === 'total') return;
    getRecordings(indicartor, source)

    setShowPopUp({
      show: true,
      filter: indicartor,
      source: source
    });
  };

  const handlePopUpExit = () => {
    setShowPopUp({
      show: false,
      filter: null,
      source: null
    });
  };

  const handleMoreDetailsButtonClick = () => {
    const { filter, source } = showPopUp
    if (!filter || !source) return
    getRecordings(filter, source)
    history.push(`/recordings`);
  }
  const getRecordings = (indicartor: string, source: string) => {
    let END_DAY = form.end_date + 'T23:59:59:999Z';
    let START_DAY = form.start_date + 'T00:00:00:000Z';

    const filters = `callType=${source.toUpperCase()}&state=${indicartor.toUpperCase()}`;
    dispatch(
      getRecordingsFromReportModuleAction(
        owner,
        100,
        'DES',
        START_DAY,
        END_DAY,
        filters,
      ),
    );
  }
  useEffect(() => {
    if (!owner) return;
    const OWNER = owner.toLocaleLowerCase();
    dispatch(getReportTotalCallsByOriginAction(OWNER, START_DATE, END_DATE, exten));
  }, [owner]);

  return (
    <>
      <Cont>
        <ButtonsFiltersAndForm
          formValues={form}
          setFormValues={setForm}
          handleFormButtonClick={handleFormSearchClick}
          SelectableButtonsState={selectableButtons}
          handleSelectableButtonClick={handleSelectableButtonClick}
        />
        <BlueCont>
          <Title>
            {t('totalCallsReport.totalCallsFrom')}{' '}
            <b> {inputDateFormat_toRenderFormat(form.start_date)} </b>
            {t('totalCallsReport.to')}<b>{inputDateFormat_toRenderFormat(form.end_date)}</b>
          </Title>
          <IndicatorsLinkedWithCircularGraph
            title={t('totalCallsReport.total')}
            filter={'total'}
            isLoading={isLoading}
            onSelectIndicator={selectedIndicator}
            indicators={indicatorsToRender(reportData?.calls)}
            graphData={graphDataToRender(reportData?.calls)}
          />
          <IndicatorsLinkedWithCircularGraph
            title={t('totalCallsReport.internal')}
            filter={'internal'}
            isLoading={isLoading}
            onSelectIndicator={selectedIndicator}
            indicators={indicatorsToRender(reportData?.internal)}
            graphData={graphDataToRender(reportData?.internal)}
          />
          <IndicatorsLinkedWithCircularGraph
            title={t('totalCallsReport.external')}
            filter={'external'}
            isLoading={isLoading}
            onSelectIndicator={selectedIndicator}
            indicators={indicatorsToRender(reportData?.external)}
            graphData={graphDataToRender(reportData?.external)}
          />
        </BlueCont>
      </Cont>
      {showPopUp.show && <CallListPopUp filter={showPopUp.filter} dates={form} handleExitClick={handlePopUpExit} handleMoreDetailsClick={handleMoreDetailsButtonClick} />}
    </>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;

//
// INITIALIZE selectable buttons state
//
const INIT_BUTTONS_STATE = [
  {
    isSelected: true,
    label: 'Today',
    value: 1, // value represents days
  },
  {
    isSelected: false,
    label: 'Last 7 days',
    value: 7, // value represents days
  },
  {
    isSelected: false,
    label: 'This month',
    value: 31, // value represents days
  },
];
export default TotalCallByOriginTemplate;
