import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
//@ts-ignore
import MetaTags from 'react-meta-tags';
import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import FlowV2 from 'components/flowV2';
import DatabasesTemplate from 'components/databases/template/databasesTemplate';
import { useTranslation } from 'react-i18next';
import CreateDatabaseTemplate from 'components/databases/template/createDatabaseTemplate';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { getDatabases } from 'store/databases/asyncActions';

const DatabasesPage = () => {
  const { t } = useTranslation('databases')
  const dispatch = useAppDispatch()
  const [showCreateDatabaseForm, setShowCreateDatabaseForm] = useState(false)

  useEffect(() => {
    dispatch(getDatabases())
  }, [])
  return (
    <Cont className="page-content">
      <MetaTags>
        <title>{t('metaTag')} | SmartBot</title>
      </MetaTags>
      <div>
        <PageTitle>{t('pageTitle')}</PageTitle>
      </div>
      <DatabasesTemplate setShowCreateDatabaseForm={setShowCreateDatabaseForm} />
      {showCreateDatabaseForm && <CreateDatabaseTemplate setShowCreateDatabaseForm={setShowCreateDatabaseForm} />}
    </Cont>
  );
};
const Cont = styled.div`
  background-color: #eff5f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export default DatabasesPage;