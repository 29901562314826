import AgentCallsDetailedTemplate from 'components/LiveDashboard/templates/agentCallsDetailedTemplate';
import React from 'react';
import MetaTags from 'react-meta-tags';

const PagesStarter = ({ match }) => {
  const { queueId } = match.params;
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Agent Calls Detailed | SmartBot</title>
        </MetaTags>
        <AgentCallsDetailedTemplate queueId={queueId} />
      </div>
    </React.Fragment>
  );
};
export default PagesStarter;
