import { ICreateTag } from 'library/interfaces/workspaceInterface';
import { IError } from 'store/reports/reducer';
import {
  IPostCallTag,
  IPostCallTagFail,
  IPostCallTagSuccess,
  IUpdateFromNumber,
  IUpdateSelectedCampaignId,
  POST_CALL_TAG,
  POST_CALL_TAG_FAIL,
  POST_CALL_TAG_SUCCESS,
  UPDATE_FROM_NUMBER,
  UPDATE_SELECTED_CAMPAIGN_ID,
} from './actionTypes';

export const postCallTagAction = (
  tag: ICreateTag,
  campaignId: string,
): IPostCallTag => ({
  type: POST_CALL_TAG,
  payload: { tag, campaignId },
});

export const postCallTagSuccess = (tagId: string): IPostCallTagSuccess => ({
  type: POST_CALL_TAG_SUCCESS,
  payload: { tagId },
});

export const postCallTagFail = (error: IError): IPostCallTagFail => ({
  type: POST_CALL_TAG_FAIL,
  payload: { error },
});

export const updateSelectedCampaignIdAction = (
  campaignId: string,
): IUpdateSelectedCampaignId => ({
  type: UPDATE_SELECTED_CAMPAIGN_ID,
  payload: { campaignId },
});

export const updateFromNumberAction = (
  fromNumber: string,
): IUpdateFromNumber => ({
  type: UPDATE_FROM_NUMBER,
  payload: { fromNumber },
});
