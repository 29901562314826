import { lightBackgroundBlue } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';
import HorizontalForm from '../molecules/horizontalForm';
import SelectableButtonFilter from '../molecules/selectableButtonsFilter';

interface IButtonsFiltersAndFormProps {
  SelectableButtonsState: {
    isSelected: boolean;
    label: string;
  }[];
  handleSelectableButtonClick: Function;
  formValues: {
    start_date: string,
    end_date: string
  }
  setFormValues: Function
  handleFormButtonClick: Function
}
const ButtonsFiltersAndForm: FC<IButtonsFiltersAndFormProps> = ({
  formValues,
  setFormValues,
  SelectableButtonsState,
  handleSelectableButtonClick,
  handleFormButtonClick
}) => {
  return (
    <Cont>
      <SelectableButtonFilter
        SelectableButtons={SelectableButtonsState}
        handleSelectableButtonClick={handleSelectableButtonClick}
      />
      <HorizontalForm formValues={formValues} setFormValues={setFormValues} onSubmit={handleFormButtonClick}/>
    </Cont>
  );
};
const Cont = styled.div`
  border-radius: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: ${lightBackgroundBlue};
`;
export default ButtonsFiltersAndForm;
