import EmailsForm from 'components/Report/organisms/emailForm';
import EmailPopup from 'components/Report/organisms/emailPopup';
import EmailSessionPopup from 'components/Report/organisms/emailSessionPopup';
import EmailTableRow from 'components/Report/organisms/emailTableRow';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { darkBorder, lightBackgroundBlue } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { EmailFiltersTypes, NotificationTypes as NotificationTypes } from 'library/interfaces/reportsInterfaces';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEmailSessionAction, getEmailsByFilterAction } from 'store/actions';
import styled from 'styled-components';

interface IEmailReportProps { }
const EmailReport: FC<IEmailReportProps> = ({ }) => {
  const { t } = useTranslation('reports')
  const dispatch = useAppDispatch()

  const observer = useRef<IntersectionObserver>()

  const { rows, lastKey, isLoading } = useAppSelector(store => store.reports.emails)

  const [date, setDate] = useState({ start: '', end: '' })
  const [selectedFilter, setSelectedFilter] = useState<EmailFiltersTypes>('')
  const [filterValue, setFilterValue] = useState<{
    cedula: string,
    notification_type: NotificationTypes
  }>
    ({ cedula: '', notification_type: 'Delivery' })
  const [order, setOrder] = useState('des')

  const [selectedEmail, setSelectedEmail] = useState('')
  const [selectedSession, setSelectedSession] = useState('')

  const lastElementRef = useCallback((node: any) => {

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (!lastKey) return
        if (selectedFilter === '') {
          dispatch(getEmailsByFilterAction(lastKey, order, date))
          return
        }
        dispatch(getEmailsByFilterAction(lastKey, order, date,
          { filter: selectedFilter, filterValue: filterValue[selectedFilter] }
        ))
      }
    }, {
      rootMargin: '300px'
    })
    if (node) observer.current.observe(node)
  }, [lastKey, selectedFilter, filterValue, order, date])

  useEffect(() => {

    setTimeout(() => {
      document.body.classList.add('vertical-collpsed')
      document.body.classList.add('sidebar-enable')
    }, 0);

    return () => {
      document.body.classList.remove('vertical-collpsed')
      document.body.classList.remove('sidebar-enable')
    }
  }, [])

  useEffect(() => {
    if (!selectedSession) return
    dispatch(getEmailSessionAction(selectedSession))
  }, [selectedSession])

  return (
    <Cont>
      <Title>{t('emails.title')}</Title>
      <EmailsForm
        order={order}
        setOrder={setOrder}
        date={date}
        setDate={setDate}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      {rows.length > 0 &&
        <MailsCont>
          {COLUMNS_TO_SHOW.map((column, index) => {
            return (
              <MailColumnHeader
                key={index}
                center={index === 2 || index === 6 || index === COLUMNS_TO_SHOW.length - 1}
              >{t(`emails.tableHeader.${column}`)}</MailColumnHeader>
            )
          })}
          {rows.map((row, index) => {
            if (index === rows.length - 1) {
              return (

                <EmailTableRow key={index} reference={lastElementRef} row={row} setSelectedEmail={setSelectedEmail} setSelectedSession={setSelectedSession} />

              )
            }
            return (
              <EmailTableRow key={index} row={row} setSelectedEmail={setSelectedEmail} setSelectedSession={setSelectedSession} />
            )
          })}
          {isLoading && <Loading>Cargando <LoadingPoints /></Loading>}
        </MailsCont>}
      {selectedEmail && <EmailPopup email={selectedEmail} clearSelectedEmail={setSelectedEmail} />}
      {selectedSession && <EmailSessionPopup clearSelectedEmailSession={setSelectedSession} />}
    </Cont >
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;
`;
const MailsCont = styled.div`
  margin-top: 20px;
  padding: 0 20px 20px;
  border-radius: 10px;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: .7fr .4fr .3fr 1fr .5fr .1fr .4fr .3fr .2fr;
  background-color: ${lightBackgroundBlue};
`;
const MailColumnHeader = styled.div<IMailColumnHeader>`
margin-top: 10px;
padding-bottom: 10px;
border-bottom: 1px solid ${darkBorder};
  text-align: ${props => props.center && 'center'};
`;
const Loading = styled.div`
  grid-column: 1 / -1;
  text-align: center;
`;
interface IMailColumnHeader {
  center: boolean
}
//
//columns
//
const COLUMNS_TO_SHOW = ['timestamp', 'phone', 'session_id', 'name', 'cedula', 'destination', 'body', 'notification_type', 'option']
export default EmailReport;