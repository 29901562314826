import { DelIconModalSC, FlexContSBModalSC, InputColorModalSC, InputModalSC, InputVerticalGroupModalSC, LabelModalSC, TextAreaModalSC, WhiteContModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IGptFunctionProps {
  index: number;
  sourceColor: string;
  functionData: {
    id: string;
    name: string;
    description: string;
    parameters: string;
  }
  handleDeleteFunction: (functionId: string) => void;
  handleFunctionChange: (
    index: number,
    name: 'name' | 'description' | 'parameters',
    value: string
  ) => void;
  handleUpdateSourceColor: (sourceId: string, color: string) => void;
}
const GptFunction: FC<IGptFunctionProps> = ({
  index,
  sourceColor,
  functionData,
  handleDeleteFunction,
  handleFunctionChange,
  handleUpdateSourceColor
}) => {
  return (
    <WhiteContModalSC>
      <DelIconModalSC onClick={() => handleDeleteFunction(functionData.id)}>
        <i className='bx bxs-trash'></i>
      </DelIconModalSC>

      <FlexContSBModalSC>
        <InputVerticalGroupModalSC>
          <LabelModalSC>Name</LabelModalSC>
          <InputModalSC
            value={functionData.name}
            onChange={e => handleFunctionChange(index, 'name', e.target.value)}
          />
        </InputVerticalGroupModalSC>

        <InputVerticalGroupModalSC center margin=' 0 0 20px 10px'>
          <LabelModalSC>Color</LabelModalSC>
          <InputColorModalSC value={sourceColor} onChange={e => handleUpdateSourceColor(functionData.id, e.target.value)} />
        </InputVerticalGroupModalSC>

      </FlexContSBModalSC>
      <InputVerticalGroupModalSC>
        <LabelModalSC>Description</LabelModalSC>
        <TextAreaModalSC
          value={functionData.description}
          onChange={e => handleFunctionChange(index, 'description', e.target.value)}
        />
      </InputVerticalGroupModalSC>

      <InputVerticalGroupModalSC>
        <LabelModalSC>Parameters</LabelModalSC>
        <TextAreaModalSC
          value={functionData.parameters}
          onChange={e => handleFunctionChange(index, 'parameters', e.target.value)}
        />
      </InputVerticalGroupModalSC>
    </WhiteContModalSC>
  );
};

export default GptFunction;