import { IConversationChannelType } from 'library/enableSpecificFeatures/conversationWorkspace';
import { selectedChatTypes, UPDATE_SELECTED_CHAT } from './actionTypes';

export interface ISelectedChatCustomer {
  id: string;
  name: string;
  phoneNumber: string;
  address: string;
  type: string;
}

export interface ISelectedChatMessage {
  source: IConversationChannelType | 'call';
  sender: string;
  message: string;
  timestamp: string;
}

export interface ISelectedChatStore {
  customer: ISelectedChatCustomer;
  messages: ISelectedChatMessage[];
}

const INIT_STATE: ISelectedChatStore = {
  customer: {
    id: '',
    name: '',
    phoneNumber: '',
    address: '',
    type: '',
  },
  messages: [],
};

const selectedChat = (
  state = INIT_STATE,
  action: selectedChatTypes,
): ISelectedChatStore => {
  switch (action.type) {
    case UPDATE_SELECTED_CHAT:
      return {
        customer: action.payload.customer,
        messages: action.payload.messages,
      };
    default:
      return state;
  }
};
export default selectedChat;
