import React, { FC } from 'react';
import { ILiveConversationActiveAgents } from 'store/liveConversations/reducer';
import styled from 'styled-components';

interface IAgentLiveConversationProps {
  agent: ILiveConversationActiveAgents
}
const AgentLiveConversation: FC<IAgentLiveConversationProps> = ({ agent }) => {
  return (
    <AgentCont>
      <ImgCont>
        <img src={agent.picture} alt={agent.name} />
      </ImgCont>
      <Name>
        <Span circleColor={'#85ce5f'}></Span> {agent.name}
      </Name>

    </AgentCont>
  );
};
const AgentCont = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;
const ImgCont = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;

  & > img {
    width: 100%;
  }
`;
const Name = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
  color: dimgray;
  cursor: default;
`;
const Span = styled.span<ISpan>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${props => props.circleColor};
`;
interface ISpan {
  circleColor?: string;
}
export default AgentLiveConversation;