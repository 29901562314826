import { darkBorder, primary } from 'library/colors';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import WaTypesOfMEssageButtons from './waTypesOfMessageButtons';
import WaQuickReplyMessageEditor from './waQuickReplyMessageEditor';
import { AddButtonModalSC, BlueContModalSC, InputModalSC, SelectModalSC, TextAreaModalSC, WhiteContModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { IWaListMessage, IWaListMessageItem, IWaQuickReplyMessage, IWaQuickReplyOptions } from './waEditorInterfaces';
import { Node, Position } from 'reactflow';
import { CustomNodeTypes, IHandleData, INodeData } from 'components/flowV2/utils';
import WaListMessageView from './waListMessageView';
import WaTextMessageVisualEditor from './waTextMessageVisualEditor';

interface IWaVisualEditorProps {
  quickReply: IWaQuickReplyMessage | null
  list: IWaListMessage | null
  textMessage: string | null
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  handleShowVisualEditor: (show: boolean) => void;
  handleDeleteEdgeOnDeleteSource: (sourceId: string) => void
}
const WaVisualEditor = ({ quickReply, list, textMessage, setSelectedNode, selectedNode, handleShowVisualEditor, handleDeleteEdgeOnDeleteSource }: IWaVisualEditorProps) => {

  const [buttons, setButtons] = useState([
    { label: 'Texto', isSelected: true },
    { label: 'Quick repy', isSelected: false },
    { label: 'Lista', isSelected: false },
  ])

  const handleSelectButton = (index: number) => {
    setButtons(prev => prev.map((but, i) => index === i ? { ...but, isSelected: !but.isSelected } : { ...but, isSelected: false }))
  }

  type TQuickReplytype = 'text' | 'image' | 'video' | 'file'
  const [type, setType] = useState<TQuickReplytype | ''>('')

  const [header, setHeader] = useState('')
  const [text, setText] = useState('')
  const [caption, setCaption] = useState('')
  const [url, setUrl] = useState('')
  const [filename, setFilename] = useState('')

  const [options, setOptions] = useState<IWaQuickReplyOptions[]>([])
  const [quickReplyResponseRoutes, setQuickReplyResponseRoutes] = useState<{
    id: string;
    port: string;
    responseKey: string;
    possibleResponses: string[];
    intentId: string;
    text: string;
  }[]>([])

  const handleHeaderChange = (value: string) => {
    if (value.length > 20) return
    setHeader(value)
  }
  const handleTextChange = (value: string) => {
    if (value.length > 1024) return
    setText(value)
  }

  const handleCaptionChange = (value: string) => {
    if (value.length > 60) return
    setCaption(value)
  }

  const handleOptionsChange = (index: number, value: string) => {
    if (value.length > 20) return
    const newOpt: IWaQuickReplyOptions = { type: 'text', title: value, postbackText: value }
    const newOpts = options.map((opt, i) => index === i ? newOpt : opt)
    setOptions(newOpts)

    setQuickReplyResponseRoutes(prev => prev.map((resRoute, i) => i === index ? { ...resRoute, port: value, responseKey: value } : resRoute))
  }

  const handleAddButton = () => {
    if (options.length > 2) return
    setOptions(prev => [...prev, { type: 'text', title: '', postbackText: "" }])
    setQuickReplyResponseRoutes(prev => [...prev, {
      id: crypto.randomUUID(),
      port: '',
      responseKey: '',
      possibleResponses: [],
      intentId: '',
      text: '',
    }])
  }

  const handleSaveQuickReply = () => {
    const newQuickReply: IWaQuickReplyMessage = {
      type: 'quick_reply',
      msgid: crypto.randomUUID(),
      content: {
        type: 'text',
        text: text,
        caption: caption,
        header: header,
      },
      options: options.filter(item => item.title)
    }

    const dataSources = selectedNode.data.sources || []
    const newResponseRoutes = quickReplyResponseRoutes.filter(resRoute => resRoute.responseKey)
    const newSources: (IHandleData & { type: 'source' })[] = newResponseRoutes.map(item => {
      return {
        id: item.id,
        color: dataSources.find(sou => sou.id === item.id)?.color || '#7c9904',
        type: 'source',
        position: Position.Right,
        label: item.port,
      }
    })

    const failedSource = dataSources[0]
    const sources = failedSource ? [failedSource, ...newSources] : newSources

    const textData = structuredClone(selectedNode.data.textV2);

    if (!textData) return

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        responseRoutes: [...newResponseRoutes],
        sources: [...sources],
        textV2: { ...textData, text: JSON.stringify(newQuickReply, null, 2) }
      }
    };
    quickReplyResponseRoutes.forEach(resRoute => {
      if (resRoute.responseKey) return
      handleDeleteEdgeOnDeleteSource(resRoute.id)
    })
    setSelectedNode(editedNode)
    handleShowVisualEditor(false)

  }

  useEffect(() => {
    // if (selectedNode.data.responseRoutes?.length === 0) return
    setQuickReplyResponseRoutes(selectedNode.data.responseRoutes || [])
  }, [selectedNode])

  useEffect(() => {
    if (!quickReply) return
    handleSelectButton(1)
    setType(quickReply.content.type)

    setHeader(quickReply.content.header || '')
    setText(quickReply.content.text)
    setCaption(quickReply.content.caption || '')
    setOptions(quickReply.options)
  }, [quickReply])

  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [footer, setFooter] = useState('')

  const [globalButtonTitle, setGlobalButtonTitle] = useState('')

  const [items, setItems] = useState<IWaListMessageItem[]>([{ title: '', options: [] }])

  const [responseRoutes, setResponseRoutes] = useState<{
    id: string;
    port: string;
    responseKey: string;
    possibleResponses: string[];
    intentId: string;
    text: string;
  }[]>([])


  const handleTitleChange = (value: string) => {
    if (value.length > 60) return
    setTitle(value)
  }
  const handleBodyChange = (value: string) => {
    if (value.length > 1024) return
    setBody(value)
  }
  const handleFooterChange = (value: string) => {
    if (value.length > 60) return
    setFooter(value)
  }
  const handleGlobalButtonChange = (value: string) => {
    if (value.length > 20) return
    setGlobalButtonTitle(value)
  }

  const handleItemTitleChange = (index: number, value: string) => {
    if (value.length > 24) return
    setItems(prev => prev.map((item, i) => index === i ? { ...item, title: value } : item))
  }

  const handleItemOptionTitleChange = (itemIndex: number, optionIndex: number, value: string) => {
    if (value.length > 24) return
    setItems(prev => prev.map((item, i) => {
      if (itemIndex !== i) return item
      const newItem = structuredClone(item)
      return { ...newItem, options: newItem.options.map((opt, j) => optionIndex === j ? { ...opt, title: value, postbackText: value } : opt) }
    }))

    let responseRouteIndex = -1

    items.forEach((ite, index) => {
      if (index <= itemIndex) {
        ite.options.forEach((opt, j) => {
          if (itemIndex === index && optionIndex < j) return
          responseRouteIndex++
        })
      }
    })
    setResponseRoutes(prev => prev.map((resRoute, i) => responseRouteIndex === i ? { ...resRoute, port: value, responseKey: value } : resRoute))
  }
  const handleItemOptionDescriptionChange = (itemIndex: number, optionIndex: number, value: string) => {
    if (value.length > 72) return
    setItems(prev => prev.map((item, i) => {
      if (itemIndex !== i) return item
      const newItem = structuredClone(item)
      return { ...newItem, options: newItem.options.map((opt, j) => optionIndex === j ? { ...opt, description: value } : opt) }
    }))
  }

  const handleAddOptionToItem = (itemIndex: number) => {
    setResponseRoutes(prev => [...prev, {
      id: crypto.randomUUID(),
      port: '',
      responseKey: '',
      possibleResponses: [],
      intentId: '',
      text: '',
    }])
    setItems(prev => prev.map((item, i) => itemIndex === i ? { ...item, options: [...item.options, { type: 'text', title: '', description: '', postbackText: '' }] } : item))
  }

  const handleAddSecion = () => {
    setItems(prev => [...prev, { title: '', options: [{ type: 'text', title: '', description: '', postbackText: '' }] }])
    setResponseRoutes(prev => [...prev, {
      id: crypto.randomUUID(),
      port: '',
      responseKey: '',
      possibleResponses: [],
      intentId: '',
      text: '',
    }])
  }



  const handleSaveListMessage = () => {
    const newList: IWaListMessage = {
      type: 'list',
      title,
      body,
      footer,
      msgid: crypto.randomUUID(),
      globalButtons: [{ type: 'text', title: globalButtonTitle }],
      items: items.filter((item, i) => i === 0 || item.title).map(item => {
        return { ...item, options: item.options.filter(opt => opt.title) }
      })
    }

    const dataSources = selectedNode.data.sources || []
    // const options = items.map(item => item.options).flat().filter(opt => opt.title)
    const newResponseRoutes = responseRoutes.filter(resRoute => resRoute.responseKey)
    const newSources: (IHandleData & { type: 'source' })[] = newResponseRoutes.map(item => {
      return {
        id: item.id,
        color: dataSources.find(sou => sou.id === item.id)?.color || '#7c9904',
        type: 'source',
        position: Position.Right,
        label: item.port,
      }
    })

    const failedSource = dataSources[0]
    const sources = failedSource ? [failedSource, ...newSources] : newSources

    const textData = structuredClone(selectedNode.data.textV2);

    if (!textData) return

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        responseRoutes: [...newResponseRoutes],
        sources: [...sources],
        textV2: { ...textData, text: JSON.stringify(newList, null, 2) }
      }
    };
    responseRoutes.forEach(resRoute => {
      if (resRoute.responseKey) return
      handleDeleteEdgeOnDeleteSource(resRoute.id)
    })
    setSelectedNode(editedNode)
    handleShowVisualEditor(false)
  }

  useEffect(() => {
    // if (selectedNode.data.responseRoutes?.length === 0) return
    setResponseRoutes(selectedNode.data.responseRoutes || [])
  }, [selectedNode])

  useEffect(() => {
    if (!list) return
    handleSelectButton(2)
    setTitle(list.title)
    setBody(list.body)
    setFooter(list.footer)
    setGlobalButtonTitle(list.globalButtons[0].title)
    setItems(list.items)
  }, [list])

  const [textBody, setTextBody] = useState('')

  const handleTextBodyChange = (value: string) => {
    if (value.length > 4096) return
    setTextBody(value)
  }

  const handleSaveTextMessage = () => {
    const textData = structuredClone(selectedNode.data.textV2);

    if (!textData) return

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        textV2: { ...textData, text: textBody }
      }
    };
    setSelectedNode(editedNode)
    handleShowVisualEditor(false)
  }


  useEffect(() => {
    if (!textMessage) return
    setTextBody(textMessage)
  }, [textMessage])

  return (
    <>
      <BlueContModalSC>
        <GridCont isGrid={(!quickReply && !list && !textMessage && selectedNode.type === 'interactiveMenu')}>
          {!quickReply && !list && !textMessage && selectedNode.type === 'interactiveMenu' &&
            <WaTypesOfMEssageButtons
              buttons={buttons}
              handleSelectButton={handleSelectButton}
            />}
          {buttons[1].isSelected && <div>
            <WaQuickReplyMessageEditor buttons={options} content={{ caption, filename, header, text, url }} />

            <SelectModalSC value={type} onChange={e => setType(e.target.value as TQuickReplytype)}>
              <option value="">Selectiona un tipo de respuesta rapida</option>
              <option value="text">texto</option>
              {/* <option value="image">imagen</option>
              <option value="video">video</option>
              <option value="document">Documento</option> */}
            </SelectModalSC>
            {type && <Form>
              {type === 'text' && <InputModalSC placeholder='Header del mensaje' value={header} onChange={e => handleHeaderChange(e.target.value)} />}


              {/* placeholder='Texto principal del mensaje'  */}

              <TextAreaModalSC placeholder='Texto principal del mensaje' value={text} onChange={e => handleTextChange(e.target.value)} />

              <InputModalSC placeholder='Footer del mensaje' value={caption} onChange={e => handleCaptionChange(e.target.value)} />
              {type !== 'text' && <InputModalSC placeholder={`URL del ${type}`} value={url} onChange={e => setUrl(e.target.value)} />}
              {type === 'file' && <InputModalSC placeholder='Nombre del archivo' value={filename} onChange={e => setFilename(e.target.value)} />}

              {options.map((opt, index) => {
                return (
                  <WhiteContModalSC key={index}>
                    <InputModalSC value={opt.title} onChange={e => handleOptionsChange(index, e.target.value)} />
                  </WhiteContModalSC>
                )
              })}
              {options.length < 3 &&
                <AddButtonModalSC onClick={() => handleAddButton()}>
                  <i className="bx bxs-plus-circle" /> <span>Añadir botón</span>
                </AddButtonModalSC>}


            </Form>}
          </div>}

          {buttons[2].isSelected &&
            <div>
              <WaListMessageView
                content={{ title, body, footer }}
                globalButton={globalButtonTitle}
                items={items}
              />

              <Form>
                <InputModalSC placeholder='Titulo de la lista. Maximo 60 caracteres (Opcional)' value={title} onChange={e => handleTitleChange(e.target.value)} />
                <TextAreaModalSC placeholder='Cuerpo del mensaje. Maximo 1024 caracteres.' value={body} onChange={e => handleBodyChange(e.target.value)} />
                <InputModalSC placeholder='Titulo del footer de la lista. Maximo 60 caracteres (Opcional)' value={footer} onChange={e => handleFooterChange(e.target.value)} />
                <InputModalSC placeholder='Texto del botón de lista. Maximo 20 caracteres' value={globalButtonTitle} onChange={e => handleGlobalButtonChange(e.target.value)} />

                {items.map((item, index) => {
                  return (
                    <WhiteContModalSC key={index}>
                      <InputModalSC placeholder={items.length > 1 ? 'Obligatorio' : 'Requerido si habrá más de una sección. 24 Caracteres.'} value={item.title} onChange={e => handleItemTitleChange(index, e.target.value)} />
                      <BlueContModalSC>
                        {item.options.map((opt, i) => {
                          return (
                            <WhiteContModalSC key={i}>
                              <InputModalSC placeholder='Titulo del item. Maximo 24 caracteres.' value={opt.title} onChange={e => handleItemOptionTitleChange(index, i, e.target.value)} />
                              <InputModalSC placeholder='Descriptión del item. Maximo 72 caracteres.' value={opt.description} onChange={e => handleItemOptionDescriptionChange(index, i, e.target.value)} />
                            </WhiteContModalSC>
                          )
                        })}

                        {items.map(item => item.options).flat().length < 10 && <AddButtonModalSC onClick={() => handleAddOptionToItem(index)}>
                          <i className="bx bxs-plus-circle" /> <span>Añadir Item a la lista</span>
                        </AddButtonModalSC>}
                      </BlueContModalSC>
                    </WhiteContModalSC>
                  )
                })}
                <AddButtonModalSC onClick={() => handleAddSecion()}>
                  <i className="bx bxs-plus-circle" /> <span>Añadir sección</span>
                </AddButtonModalSC>
              </Form>
            </div>
          }

          {buttons[0].isSelected &&
            <WaTextMessageVisualEditor textMessage={textBody} handleTextMessageChange={handleTextBodyChange} />
          }


        </GridCont>
      </BlueContModalSC>
      <ButtonCont>
        <CancelButton onClick={() => handleShowVisualEditor(false)}>Cancelar</CancelButton>
        {buttons[1].isSelected && <SaveButton onClick={handleSaveQuickReply}>Guardar</SaveButton>}
        {buttons[2].isSelected && <SaveButton onClick={handleSaveListMessage}>Guardar</SaveButton>}
        {buttons[0].isSelected && <SaveButton onClick={handleSaveTextMessage}>Guardar</SaveButton>}
      </ButtonCont>
    </>
  );
};
const GridCont = styled.div<IGridCont>`
  display: ${props => props.isGrid ? 'grid' : 'block'};
  grid-gap: 10px;
  grid-template-columns: 1fr 3fr;
`;
const Form = styled.div`
 /* border: 1px solid red; */
 /* height: 490px; */
 padding: 10px 0px;

 > input {
  margin-bottom: 5px;
 }
`;



const ButtonCont = styled.div`
  display: flex;
  justify-content: end;
  margin: 10px 0 20px;
  padding-right: 50px;
`;
const SaveButton = styled.button`
  align-self: right;
  border: none;
  color: white;
  padding: 5px 20px;
  border-radius: 50px;
  background-color: ${primary};

  @media (min-width: 1920px) {
    font-size: 1.1rem;
  }
`;
const CancelButton = styled(SaveButton)`
  margin-right: 20px;
  background-color: ${darkBorder};
`;
interface IGridCont {
  isGrid: boolean
}
export default WaVisualEditor;

