import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import LargeButton from '../atoms/largeButton';

import { lightBackground, secondary } from 'library/colors';

import { IQueue } from 'library/interfaces/crdInterfaces';
import { useTranslation } from 'react-i18next';

interface IQueueSelectListProps {
  queues: IQueue[];
  mainPath: string;
  redirectPath: string;
}
const QueueSelectList: FC<IQueueSelectListProps> = ({
  queues,
  mainPath,
  redirectPath,
}) => {
  const { t } = useTranslation('liveBoard');
  const history = useHistory();

  const allQueuesTitle = t('allQueues');
  const allQueuesDescription = t('selectQueue');

  const [showMenu, setShowMenu] = useState(false);
  const [button, setbutton] = useState({
    title: allQueuesTitle,
    description: allQueuesDescription,
  });

  const handleSelectQueueClick = (
    title: string,
    description: string,
    queueId?: string,
  ) => {
    setbutton({
      title,
      description,
    });
    setShowMenu(false);

    if (title === allQueuesTitle) return history.push(`${mainPath}`);
    else return history.push(`${redirectPath}/${queueId}`);
  };

  return (
    <Div>
      <LargeButton
        border
        title={button.title}
        description={button.description}
        showMenu={showMenu}
        onClick={setShowMenu}
      />
      {showMenu && (
        <List showMenu={showMenu}>
          <LargeButton
            title={allQueuesTitle}
            description={allQueuesDescription}
            showMenu={showMenu}
            onClick={() =>
              handleSelectQueueClick(allQueuesTitle, allQueuesDescription)
            }
          />
          {queues &&
            queues.map((queue, index) => {
              return (
                <LargeButton
                  key={index}
                  title={queue.name}
                  description={button.description}
                  onClick={() =>
                    handleSelectQueueClick(
                      queue.name,
                      button.description,
                      queue.id,
                    )
                  }
                />
              );
            })}
        </List>
      )}
    </Div>
  );
};
const Div = styled.div`
  flex-grow: 1;
  margin: 10px auto;
  min-width: 315px;
  max-width: 540px;
  position: relative;

  @media (min-width: 890px) {
    min-width: 340px;
    margin: 10px 0;
  }
`;
const showListAnimation = keyframes`
  0%{
    opacity: .5;
    transform: translateY(-5px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
`;
const List = styled.div<IList>`
  border-radius: 8px;
  position: absolute;
  top: 60px;
  z-index: 100;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${lightBackground};
  box-shadow: 0 0 3px ${secondary}, 0 3px 10px ${secondary};
  animation: ${showListAnimation} 0.2s;
`;
interface IList {
  showMenu: boolean;
}
export default QueueSelectList;
