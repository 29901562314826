import { border, primary } from 'library/colors';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

export interface ISelectableQueue {
  id: string;
  name: string;
  isSelected: boolean;
}
interface ISelectableQueueProps {
  queue: ISelectableQueue;
  index: number;
  handleSelectedQueue: Function;
}
const SelectableQueue: FC<ISelectableQueueProps> = ({ queue, index, handleSelectedQueue }) => {

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    handleSelectedQueue(queue.id, index);
  };
  return (
    <Button isSelected={queue.isSelected} onClick={e => handleButtonClick(e)}>
      {queue.name.substring(0, 10)}
    </Button>
  );
};
const Button = styled.button<IButton>`
  width: 100%;
  height: 40px;
  text-align: left;
  padding: 0 10px;
  margin: 1px 0px;
  border-radius: 5px;
  border: none;
  color: ${props => (props.isSelected ? 'white' : 'black')};
  background-color: ${props => (props.isSelected ? primary : 'transparent')};

  &:hover {
    color: ${props => !props.isSelected && primary};
    background-color: ${props => !props.isSelected && border};
  }
`;
interface IButton {
  isSelected: boolean;
}
export default SelectableQueue;
