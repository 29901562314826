import {
  CDR_ACTION_FAIL,
  GET_AGENTS_SERVICE_LEVEL_SUCCESS,
  GET_LIVE_DATA_SUCCESS,
  GET_REPORT_DATA_SUCCESS,
  GET_SUMMARIZE_DATA_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  liveData: [],
  summarizeData: [],
  reportData: {
    users: [],
    queues: [],
  },
  agentsServiceLevel: {},
  error: {},
};

const cdr = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CDR_ACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_LIVE_DATA_SUCCESS:
      return {
        ...state,
        liveData: action.payload,
      };
    case GET_SUMMARIZE_DATA_SUCCESS:
      return {
        ...state,
        summarizeData: action.payload,
      };
    case GET_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reportData: action.payload,
      };
    case GET_AGENTS_SERVICE_LEVEL_SUCCESS:
      return {
        ...state,
        agentsServiceLevel: action.payload,
      };
    default:
      return state;
  }
};

export default cdr;
