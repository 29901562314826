import React, { FC } from 'react';
import styled from 'styled-components';

import SubTitle from '../atoms/subTitle';
import ChangePrev from '../atoms/changePrev';

import { primary, secondary } from 'library/colors';
import { useTranslation } from 'react-i18next';

interface ICallsAnswered {
  answered?: number;
  inqueue?: number;
  big?: React.ReactNode;
  center?: React.ReactNode;
}
const CallsAnswered: FC<ICallsAnswered> = ({  answered = 0 , inqueue, big, center}) => {
  const {t} = useTranslation('summarize')
  return (
    <Div big={big}>
      <SubTitle center={center} sub={big ? '' : t('last24hs')}>
        {t('answeredCalls')}
      </SubTitle>
      <Number big={big}>{answered}</Number>
      
      { center &&
      <> 
      <SubTitle center={center} sub={big ? '' : t('last24hs')}>
        {t('queuedCalls')} &nbsp; &nbsp; &nbsp; 
      </SubTitle>
      <Number big={big}>{inqueue}</Number>
      </>}

      {/* {!big && <ChangePrev center={center} big={big} change={1} prev={6} />} */}
    </Div>
  );
};
const Div = styled.div<IDiv>`
  flex-grow: 1;
  max-width: 250px;
  padding: ${props => (props.big ? '0 0 0px' : '20px')};
  border-right: ${props => (props.big ? `2px solid ${secondary}` : '')};
`;
const Number = styled.div<IDiv>`
  color: ${primary};
  font-size: 2.5rem;
  font-weight: 500;
  padding: ${props => (props.big ? '0 0 0 48px ' : '6px 0')};
`;
interface IDiv {
  big?: React.ReactNode;
}
export default CallsAnswered;
