import React from 'react';
import PropTypes from 'prop-types';

function SidebarItem({ text, Icon, type }) {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div
      className="sidebar-node-item"
      onDragStart={event => onDragStart(event, type)}
      draggable
    >
      <div>
        <Icon width={22} height={22} /> {text}
      </div>
      <i className="bx bx-grid-vertical" style={{ fontSize: 20 }} />
    </div>
  );
}

SidebarItem.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.any.isRequired,
  type: PropTypes.any,
};

export default SidebarItem;
