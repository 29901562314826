import React, { FC } from 'react';
import styled from 'styled-components';
import Title from '../atoms/title';
import Text from '../atoms/text';
import { darkBorder, primary } from 'library/colors';
import InfoCont from '../atoms/infoCont';

interface IAsistenciaEnLaCarreteraProps { }
const AsistenciaEnLaCarretera: FC<IAsistenciaEnLaCarreteraProps> = ({ }) => {
  return (
    <InfoCont translateY='55%'>
      <Title>Asistencia en la Carretera</Title>
      <Text>Entiendo que su llamada es para asistencia en carretera. ¿En qué autopista se encuentra?</Text>
      <Text>Recuerde, por su seguridad, no salga del vehículo si hay otros carros pasando. Si es necesario salir del vehículo, hágalo por el lado más alejado de la autopista. Espere en una zona segura, preferiblemente tras las vallas de metal. </Text>
      <Text danger>Si está en alguna de las siguientes:</Text>
      <ul>
        <li>
          PR-20 (Expreso Rafael Martínez Nadal)
          <ul>
            <li>Total de 9.7km</li>
          </ul>
        </li>
        <li>PR-17 (Puente Teodoro Moscoso)</li>
        <li>
          PR-52 (Expreso Luis A. Ferré)
          <ul>
            <li>Total de 108.3 km</li>
            <li>
              Estaciones de peaje en:
              <ul>
                <li>Caguas Norte</li>
                <li>Caguas Sur</li>
                <li>Salinas</li>
                <li>Salinas Sur</li>
                <li>Juana Díaz Este</li>
                <li>Juana Díaz Oeste</li>
                <li>Ponce</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          PR-53 (Expreso José Celso Barbosa)
          <ul>
            <li>Total de 94 km</li>
            <li>
              Estaciones de peaje en:
              <ul>
                <li>Ceiba</li>
                <li>Humacao Norte</li>
                <li>Humacao Sur</li>
                <li>Guayama</li>
                <li>Hucar</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          PR-66 (Expreso Roberto Sánchez Vilella)
          <ul>
            <li>Total de 19.5 km</li>
            <li>
              Estaciones de peaje en:
              <ul>
                <li>Plaza Carolina</li>
                <li>Carolina Norte</li>
                <li>Carolina Sur</li>
                <li>Rio Grande</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <Text>Le transferiré al centro de control de Caguas Norte para que le envíen una unidad de asistencia de inmediato.</Text>
      <Text danger>Si está en alguna de las siguientes:</Text>
      <ul>
        <li>
          PR-22 (Expreso José de Diego)
          <ul>
            <li>Total de 83 km</li>
            <li>
              Estaciones de peaje en:
              <ul>
                <li>Buchanan</li>
                <li>Toa Baja</li>
                <li>Vega Alta</li>
                <li>Manatí</li>
                <li>Arecibo</li>
                <li>Arecibo – Rampa Factor</li>
                <li>Hatillo</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          PR-5 en la zona de Bayamón
          <ul>
            <li>Total de km</li>
            <li>
              Estaciones de peaje en:
              <ul>
                <li>Bayamón Sur</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <Text>Le transferiré al centro de control de Buchanan para que le envíen una unidad de asistencia de inmediato. </Text>
    </InfoCont>
  );
};

export default AsistenciaEnLaCarretera;