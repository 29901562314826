import React from 'react';
import PropTypes from 'prop-types';

import NodeModal from '../common/NodeModal';
import View from './View';
import Edit from './Edit';
import ViewEditContainer from '../../ViewEditContainer';

function Modal({ Icon, data, toggle, isOpen }) {
  return (
    <NodeModal Icon={Icon} data={data} isOpen={isOpen} toggle={toggle}>
      <ViewEditContainer data={data} View={View} Edit={Edit} />
    </NodeModal>
  );
}

Modal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  Icon: PropTypes.any,
};

export default Modal;
