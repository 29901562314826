import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button, { IButton } from '../atoms/button';

const TimeRangeButtons = () => {
  const {t} = useTranslation('summarize')
  const [buttons, setButtons] = useState<IButton[]>([
    { text: t('day'), value: 'last24h', isActive: true },
    { text: '12hs', value: '', isActive: false },
    { text: '8hs', value: '', isActive: false },
    { text: '4hs', value: '', isActive: false },
    { text: '1hs', value: '', isActive: false },
    { text: '30min', value: '', isActive: false },
  ]);
  const handleButtonClick = (index: number) => {
    return
    const newButtonState = buttons.map((button, i): IButton=>{
      if(i===index) {
        return {text: button.text, isActive: true}
      }
      return {text: button.text, isActive: false}
    })

    setButtons(newButtonState)
  };
  return (
    <Container>
      <Text>{t('period')}:</Text>
      <ButtonsContainer>
        {buttons.map((item, index) => {
          return (
            <Button
              key={index}
              index={index}
              button={item}
              onClick={handleButtonClick}
            />
          );
        })}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  
  @media(min-width: 890px){
    min-width: 500px;
    justify-content: end;
    max-width: 520px;
  }
`;
const Text = styled.div`
font-size: 14px;
font-weight: 500;
padding: 0 5px;
text-align: right;
`;
const ButtonsContainer = styled.div` 
  text-align: center;
`;
export default TimeRangeButtons;
