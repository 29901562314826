import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ISpanProps {
  children: ReactNode
  danger?: ReactNode
}
const Span: FC<ISpanProps> = ({ children, danger }) => {
  return (
    <Cont danger={danger}>{children}</Cont>
  );
};
const Cont = styled.span<ICont>`  
color: ${props => props.danger && 'red'};
`;
interface ICont {
  danger: ReactNode
}
export default Span;