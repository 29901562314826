import LoadingImg from 'components/Configuration/atoms/loading';
import { TransferCall } from 'icons';
import { danger, darkBorder, lightBackground, primary } from 'library/colors';
import { ISession } from 'library/interfaces/conversationInterfaces';
import { dateIsoFormated } from 'library/services/dateServices';
import React, { FC } from 'react';
import { ILiveConversationSession } from 'store/liveConversations/reducer';
import styled from 'styled-components';

interface IConversationByChannelProps {
  session: ILiveConversationSession | ISession
  showChat: (channel: string, source: string, sessionId: string) => void
  showLogs: (sessionId: string) => void
  showUsers: (show: boolean) => void
  setCurrentSessionId: (sessionId: string) => void
  deleteSession: (sessionId: string) => void
}
const ConversationByChannel: FC<IConversationByChannelProps> = ({ session, showChat, showLogs, showUsers, setCurrentSessionId, deleteSession }) => {

  const agent = session as ILiveConversationSession

  const handleReasignSessionClick = () => {
    showUsers(true)
    setCurrentSessionId(session.id)
  }
  return (
    <Cont>

      <InformationBlock>
        {agent?.isLoading && <LoadingImg width={60} />}
        <InfoTitle>Agente</InfoTitle>
        <Info>{agent.active_assigned_to}</Info>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Cliente</InfoTitle>
        <Info>{session.source_name}<br />{session.source_id}</Info>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Inicio de sesión</InfoTitle>
        <Info>{dateIsoFormated(session.created_at)}</Info>
      </InformationBlock>
      <InformationBlock>
        <InfoTitle>Última actualización </InfoTitle>
        <Info>{dateIsoFormated(session.updated_at)}</Info>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Ver chat</InfoTitle>
        <ButtonCont>
          <IconCont>
            <i className='bx bxs-show' onClick={() => showChat(session.channel_id, session.source_id, session.id)} />
          </IconCont>
        </ButtonCont>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Ver logs</InfoTitle>
        <ButtonCont>
          <IconCont>
            <i className='bx bxs-show' onClick={() => showLogs(session.id)} />
          </IconCont>
        </ButtonCont>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Reasignar</InfoTitle>
        <ButtonCont>
          <TransferButton onClick={() => handleReasignSessionClick()}>
            <TransferCall width={25} />
          </TransferButton>
        </ButtonCont>
      </InformationBlock>

      <InformationBlock>
        <InfoTitle>Cerrar</InfoTitle>
        <ButtonCont>
          <CloseButton danger={true} onClick={() => deleteSession(session.id)}>
            <i className='bx bx-trash'></i>
          </CloseButton>
        </ButtonCont>
      </InformationBlock>
    </Cont>
  );
};
const Cont = styled.div`
padding: 10px 10px 5px 0;
border-radius: 10px;
margin-bottom: 10px;
display: grid;
grid-gap: 5px;
grid-template-columns: 1fr 1fr 1fr 1fr .4fr .4fr .4fr .4fr;
  background-color: ${lightBackground};
`;
const InformationBlock = styled.div`
  text-align: center;
  font-size: .7rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const InfoTitle = styled.div`
  
`;
const Info = styled.div`
  /* border: 1px solid; */
  flex-grow: 1;
`;
const ButtonCont = styled.div`
  /* border: 1px solid; */
  flex-grow: 1;
  display: grid;
  place-items: center;
`;
const IconCont = styled.div`
  font-size: 2rem;

  cursor: pointer;
    color: ${darkBorder};

    &:hover {
      color: black;
    }
`;
const TransferButton = styled.div`
color: #fff;
flex-grow: 1;
    width: 100%;
    padding: 6px 0 ;
  border-radius: 50px;
  cursor: pointer;
  background-color: ${darkBorder};

  &:hover {
    background-color: ${primary};
    }
`;
const CloseButton = styled(TransferButton) <ICloseButton>`
  width: 40px;
  font-size: 1.2rem;
  line-height: 0;
  background-color: ${darkBorder};

  &:hover {
    background-color: ${props => props.danger ? danger : primary};
    }
`;
interface ICloseButton {
  danger: boolean
}
export default ConversationByChannel;