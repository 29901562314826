import { ICdr, IQueue, IReportData } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Container from '../atoms/container';
import PageTitle from '../atoms/pageTitle';
import QueueSelectLiveBoard from '../organisms/queueSelectLiveBoard';
import RightBar from './rightBarTemplate';
import { getReportData } from 'store/actions';
import LiveDashboardAside from '../organisms/liveDashboardAside';
import { agentsDataQueuedCallsDetailed, queuedCallsService, queuesChangeIdValueToUidValue } from 'library/services/liveQueuedCallsServices';
import { subscribeQueuedCallsDataById } from 'helpers/appSyncLiveBoard';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';
import { ILiveQueuedCalls } from 'library/interfaces/liveQueuedCallsInterfaces';
import { agentsData } from 'library/services/summarizeService';
import QueuedCallDetail from '../molecules/queuedCallDetail';

interface IQueuedCallsTemplateProps {
  uid: string;
}
const QueuedCallsDetailedTemplate: FC<IQueuedCallsTemplateProps> = ({
  uid,
}) => {
  const dispatch = useDispatch();
  const [live, setLive] = useState<ILiveQueuedCalls[]>([]);
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const reportData = useSelector<IStore, IReportData>(
    state => state.cdr.reportData,
  );

  
  const dataToRender = queuedCallsService(reportData, live)[0];
  const agentsToRender = agentsDataQueuedCallsDetailed(live, reportData, uid)
  
  const queuedCallsQueues = queuesChangeIdValueToUidValue(reportData.queues, live)

  useEffect(() => {
    if (reportData.queues.length === 0) dispatch(getReportData());
  }, []);

  useEffect(() => {

    if (!owner) return;
    const subscription = subscribeQueuedCallsDataById(
      uid,
      (data: ILiveQueuedCalls) => setLive([data]),
    );
    return () => subscription.unsubscribe();
  }, [owner]);
  return (
    <>
      <PageTitle>Queued Calls Detailed</PageTitle>

      <FlexCont>
        <Container padding="20px">
          <QueueSelectLiveBoard
            mainPath="/live-board/queued-calls"
            redirectPath="/live-board/queued-calls/detailed"
            live
            queues={queuedCallsQueues}
          />
          <Container padding="30px 30px 10px" mediumBackground>
            <GridContainer>
                
              <DetailsCont>
                {/* <div> */}
              {dataToRender && dataToRender.calls.map((call, index)=>{
                return (
                  <QueuedCallDetail key={index} call={call}></QueuedCallDetail>
                )
              })}
              {/* </div> */}
              </DetailsCont>
              <AsideCont>
              {dataToRender && (
                  <LiveDashboardAside
                    data={dataToRender.agents_state}
                    agents={agentsToRender}
                    showAddAgentToQueue={() => {}}
                  />
                  )}
                </AsideCont>
            </GridContainer>
          </Container>
        </Container>
        <RightBar />
      </FlexCont>
    </>
  );
};
const FlexCont = styled.div`
  display: flex;
  min-height: 75vh;
`;
const GridContainer = styled.div`
  margin: 0px auto 0;
  color: black;
  display: flex;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 320px;
  /* grid-template-rows: 140px 185px 185px 170px; */
  /* position: relative; */
  /* z-index: 0; */
  /* max-width: 1030px; */

  /* @media (min-width: 1600px) {
    transform: scale(1.1);
    margin: 50px auto 50px;
  }
  @media (min-width: 1700px) {
    transform: scale(1.2);
    margin: 100px auto 100px;
  }
  @media (min-width: 1800px) {
    transform: scale(1.3);
    margin: 130px auto 130px;
  }
  @media (min-width: 1900px) {
    transform: scale(1.45);
    margin: 180px auto 160px;
  } */
`;
const AsideCont = styled.div`
/* transform: scale(.8); */
  grid-column: -2 / -1;
  grid-template-rows: 1 / -1;
  min-width: 400;
`;

const DetailsCont = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  /* border: 1px solid; */
  flex-grow: 1;
  max-width: 100%;
`;
export default QueuedCallsDetailedTemplate;
