import { primary, lightGrayBackground } from 'library/colors';
import IStore from 'library/interfaces/storeInterface';
import { ICreateTag } from 'library/interfaces/workspaceInterface';
import { Phone } from 'library/phone/phone';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePhoneCallIdAction, deletePhoneE64DataAction, postCallTagAction, updateSelectedCampaignIdAction } from 'store/actions';
import { IAuthUserData } from 'store/authUserData/reducer';
import { ICampaign } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import phone, { IPhoneStore } from 'store/phone/reducer';
import { IWorkspaceStore } from 'store/workspace/reducer';
import styled from 'styled-components';
import Approval from '../atoms/approval';
import { TAGS } from '../tags';

interface ITagsPopupProps {
  handleExit: () => void;
  owner: string;
  number: string;
  phone: Phone;
}
const TagsPopup: FC<ITagsPopupProps> = ({ handleExit, owner, number, phone }) => {
  const dispatch = useDispatch();

  // call is now is unique id
  const {uniqueId, isCallAnswered, isIncommingCall, dialedNumber} = useSelector<IStore, IPhoneStore>(state => state.phone);
  const campaigns = useSelector<IStore, ICampaign[]>(
    store => store.voiceCampaigns.campaigns,
  );
  const authUserData =  useSelector<IStore, IAuthUserData>(store=>store.authUserData)
  const {selectedCampaignId, createTag} = useSelector<IStore, IWorkspaceStore>(store=>store.workspace)
  
  const [selectedTag, setSelectedTag] = useState('');
  const [observations, setObservations] = useState('');

  const [numberToTag, setNumberToTag] = useState('')
  
  const [showApprovalOnCreateTag, setShowApprovalOnCreateTag] = useState(false)

  const handleSelectCampaign = (campaignId: string) => {
    dispatch(updateSelectedCampaignIdAction(campaignId))
    if(!phone) return
    phone.campaignId = campaignId
  }

  const handlePostTags = () => {
    if (selectedCampaignId === '' || selectedTag === '' || uniqueId === '')
      return;

    const tag: ICreateTag = {
      owner: owner.toLowerCase(),
      agent: authUserData.exten,
      contact_number: numberToTag,
      call_status: '',
      direction: '',
      callid: uniqueId,
      tag: selectedTag,
      comments: observations
    };
    
    dispatch(postCallTagAction(tag, selectedCampaignId))
    dispatch(deletePhoneCallIdAction())
    dispatch(deletePhoneE64DataAction())
    setObservations('')
  };

  useEffect(()=>{
    if(createTag.tagId === '') return
    setShowApprovalOnCreateTag(true)
    const t = setTimeout(() => {
      setShowApprovalOnCreateTag(false)
    }, 2000);
    return () => clearTimeout(t)
  },[createTag.tagId])

  // number come from phone in realTime
  // if hang up the call, number is empty and tag is sent with number = ''
  // so, the number is set on state with each call
  useEffect(()=>{
    if(number === '') return 
    
    // if call is a incomming call
    // update the number after agent accept the call
    if(isIncommingCall === true){
      if(!isCallAnswered) return
      setNumberToTag(number)
      return 
    }

    // if call is a outgoing call dialed by agent
    // update the number to tag when agent click the call button on phone keyboard and return
    if(isIncommingCall === false && dialedNumber !== ''){
      setNumberToTag(number)
      return 
    }
  
    // call id ( UNIQUE ID ) of corporation change after agent accept the call
  },[number, isIncommingCall, isCallAnswered])

  return (
    <TagsContainer>
      <Exit id="exitButton" onClick={() => handleExit()}>
        x
      </Exit>

      <h4>Etiquetar Llamada</h4>

      <Select onChange={e => handleSelectCampaign(e.target.value)}>
        <option value="">Seleccionar campaña</option>
        {campaigns.map((campaign, index) => {
          return (
            <option key={index} value={campaign.id}>
              {campaign.name}
            </option>
          );
        })}
      </Select>

      <Select onChange={e => setSelectedTag(e.target.value)}>
        <option value="">
          {selectedCampaignId === ''
            ? 'Selecciona primero una campaña'
            : 'Selecciona una etiqueta'}
        </option>
        {selectedCampaignId !== '' &&
          TAGS.map((tag, index) => {
            return (
              <option key={index} value={tag.tag}>
                {tag.tag}
              </option>
            );
          })}
      </Select>
      <Observaciones>Observaciones</Observaciones>
      <TextArea value={observations} onChange={e=>setObservations(e.target.value)}></TextArea>
      <Button isEnable={selectedTag !==''} onClick={() => handlePostTags()}>Guardar</Button>
      {showApprovalOnCreateTag && <Approval />}
    </TagsContainer>
  );
};
const TagsContainer = styled.div`
  position: absolute;
  z-index: 1000;
  height: 360px;
  width: 300px;
  top: 0;
  left: calc(100% + 10px);
  padding: 30px 20px 0px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const Select = styled.select`
  margin: 10px 0 10px;
  width: 100%;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
  max-height: 200px;

  & > option {
  }
`;
const Observaciones = styled.div`
  margin-top: 20px;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
`;
const Button = styled.button<IButton>`
  padding: 5px 20px;
  color: white;
  border: none;
  display: block;
  border-radius: 50px;
  margin: 10px auto 0;
  background-color: ${props=>props.isEnable ? primary : lightGrayBackground };
`;
interface IButton {
  isEnable: boolean
}
export default TagsPopup;
