import React, { FC } from 'react';
import styled from 'styled-components';

import InputDate from 'components/Recording/reports/atoms/InputDate';

interface IFormGroupProps {
  label: string;
  name: string;
  value: string;
  type?: 'date' | 'number';
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const FormGroup: FC<IFormGroupProps> = ({
  label,
  name,
  value,
  type = 'text',
  handleInputChange,
}) => {
  return (
    <Cont>
      <label htmlFor={name}>{label}</label>
      <InputDate
        type={type}
        name={name}
        value={value}
        handleChange={handleInputChange}
      />
    </Cont>
  );
};
const Cont = styled.div`
margin: 0 20px 0 0;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
`;
export default FormGroup;
