import {
  IQueueCallEventToApp,
  IQueuedCall,
  QueuedCallEventsType,
} from 'library/interfaces/liveQueuedCallsInterfaces';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import QueuedCallEvent from '../atoms/queuedCallEvent';
import { convertSecondsNumberToTimeString } from 'library/services/summarizeService';
import { darkBorder, primary } from 'library/colors';
import { sysNotification } from 'library/notifications/notifications';

interface IQueuedCallProps {
  call: IQueuedCall<IQueueCallEventToApp>;
}
const QueuedCall: FC<IQueuedCallProps> = ({ call }) => {
  const reversedEvents = [...call.events].reverse();
  const eventToRender = reversedEvents.slice(0, 1);

  const dateStart = new Date(call.events.slice(0, 1)[0]?.endTime).getTime();
  const dateEnd = new Date(call.events.slice(-1)[0]?.endTime).getTime();

  const wait = convertSecondsNumberToTimeString((dateEnd - dateStart) / 1000);

  const ringtime = convertSecondsNumberToTimeString(
    call.events.reduce((acc, cur) => acc + cur.ringtime, 0),
  );

  const state = {
    ENTER_QUEUE: 'En cola',
    noanswer: 'No atendida',
    answer: 'Atendida',
  };
  // useEffect(()=>{
  //   if(eventToRender[0] && eventToRender[0].state !== 'noanswer') return
  //   // sysNotification(eventToRender[0].name, `No atendió la llamada de ${call.srcCallerid}`)
  // },[dateEnd])

  return (
    <Cont>
      <CallerId>
        <b>{call.srcCallerid}</b>
      </CallerId>
      <Wait>
        En cola: &nbsp;<b>{wait}</b>
      </Wait>
      <Wait>
        Repicando: &nbsp;<b>{ringtime}</b>
      </Wait>
      {eventToRender[0] && (
        <>
          <div>
            Agente: &nbsp;<b>{eventToRender[0].agent || '0000'}</b>{' '}
          </div>
          <Name>
            <b>{eventToRender[0].name.substring(0, 17) || 'No asignado'}</b>
          </Name>
          <State state={eventToRender[0].state}>
            {state[eventToRender[0].state]}
          </State>
        </>
      )}
    </Cont>
  );
};
const Cont = styled.div`
  background-color: white;
  padding: 10px;
  margin: 5px;
  flex-grow: 0;
  width: 145px;
  height: 140px;
  border-radius: 10px;
`;
const CallerId = styled.div`
  text-align: center;
`;
const Wait = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Name = styled.div`
  text-align: center;
`;
const EventsCont = styled.div``;

const STATE_COLORS = {
  ENTER_QUEUE: 'transparent',
  noanswer: 'orange',
  answer: primary,
};
const State = styled.div<IState>`
  text-align: center;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: 700;
  letter-spacing: 1px;
  color: ${props=>props.state !== 'ENTER_QUEUE' ? 'white' : darkBorder};
  border: ${props=>props.state === 'ENTER_QUEUE' && `1px solid ${darkBorder}`};
  background-color: ${props =>STATE_COLORS[props.state]};
`;
interface IState {
  state: QueuedCallEventsType;
}
export default QueuedCall;
