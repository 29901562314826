import { darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface IFormButtonsProps {
  stageIndex: number;
  stageLength: number;
  stageIsValid: boolean;
  handleHeaderStage: (index: number) => void;
  saveCampaign: Function
}
const FormButtons: FC<IFormButtonsProps> = ({
  stageIndex = 1,
  stageLength = 0,
  stageIsValid,
  handleHeaderStage,
  saveCampaign
}) => {
  const [isChecked, setIsChecked] = useState(false)

  // If not the first stage show the back button
  const showBackButton = stageIndex > 0

  // If it is the last stage it's true. 
  // Then, show the disclaimer and the save button.
  // And hide the next button.
  const showDisclaimer = stageIndex === stageLength -1

  //next
  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if(!stageIsValid) return
    handleHeaderStage(stageIndex + 1)
  };

  // back
  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    handleHeaderStage(stageIndex - 1 )
  };

  //save
  const handleSaveButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if(!isChecked) return 
    saveCampaign()
  };
  return (
    <Cont>
      {showDisclaimer && <DisclaimerCont>
        <input type="checkbox" checked={isChecked} onChange={e=>setIsChecked(!isChecked)}/>
        <p>
          I confirm the legality of processing the uploaded data (including the
          required consents) and guarantee compliance with the requirements of
          the advertising legislation.
        </p>
      </DisclaimerCont>}
      <ButtonsCont>
        {showBackButton && <BackButton isDisabled={false} onClick={e => handleBackButtonClick(e)}>Back</BackButton>}
        {!showDisclaimer && <NextButton isDisabled={!stageIsValid} onClick={e => handleNextButtonClick(e)}>Next</NextButton>}
        {showDisclaimer && <NextButton isDisabled={!isChecked} onClick={e => handleSaveButtonClick(e)}>Save</NextButton>}
      </ButtonsCont>
    </Cont>
  );
};
const Cont = styled.div`
height: 80px;
  padding: 0px 30px;
  margin: 20px auto;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: ${lightBackgroundBlue};
`;
const DisclaimerCont = styled.div`
  display: flex;

  & > input:checked {
    background-color: lime;
  }

  & > p {
    margin: 0;
    max-width: 373px;
    margin-left: 10px;
    font-size: 0.7rem;
    font-weight: 500;
  }
`;
const ButtonsCont = styled.div`
  min-width: 303px;
  display: flex;
  justify-content: end;
`;
const NextButton = styled.button<INextButton>`
  color: white;
  font-size: 1rem;
  font-weight: 700;
  width: 130px;
  border-radius: 50px;
  margin-left: 20px;
  border: 2px solid ${props=> props.isDisabled ? darkBorder : primary};
  background-color: ${props=> props.isDisabled ? darkBorder : primary};
`;
const BackButton = styled(NextButton)`
  color: black;
  font-weight: 300;
  background-color: transparent;
`;
interface INextButton {
  isDisabled: boolean;
}
export default FormButtons;
