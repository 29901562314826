import {
  ICreateCampaign,
  ITagSummarized,
} from 'library/interfaces/campaignInterfaces';
import { ICampaign, IPBXCampaignInfo } from './reducer';

export const GET_ALL_CAMPAIGNS = 'GET_ALL_CAMPAIGNS';
export const GET_ALL_CAMPAIGNS_SUCCESS = 'GET_ALL_CAMPAIGNS_SUCCESS';
export const GET_ALL_CAMPAIGNS_FAIL = 'GET_ALL_CAMPAIGNS_FAIL';

export const GET_CAMPAIGN_BY_ID = 'GET_CAMPAIGN_BY_ID';
export const GET_CAMPAIGN_BY_ID_SUCCESS = 'GET_CAMPAIGN_BY_ID_SUCCESS';
export const GET_CAMPAIGN_BY_ID_FAIL = 'GET_CAMPAIGN_BY_ID_FAIL';

export const GET_PBX_CAMPAIGN_INFO = 'GET_PBX_CAMPAIGN_INFO';
export const GET_PBX_CAMPAIGN_INFO_SUCCESS = 'GET_PBX_CAMPAIGN_INFO_SUCCESS';
export const GET_PBX_CAMPAIGN_INFO_fAIL = 'GET_PBX_CAMPAIGN_INFO_fAIL';

export const POST_CREATE_CAMPAIGN = 'POST_CREATE_CAMPAIGN';
export const POST_CREATE_CAMPAIGN_SUCCESS = 'POST_CREATE_CAMPAIGN_SUCCESS';
export const POST_CREATE_CAMPAIGN_FAIL = 'POST_CREATE_CAMPAIGN_FAIL';

export const PUT_UPDATE_CAMPAIGN = 'PUT_UPDATE_CAMPAIGN';
export const PUT_UPDATE_CAMPAIGN_SUCCESS = 'PUT_UPDATE_CAMPAIGN_SUCCESS';
export const PUT_UPDATE_CAMPAIGN_FAIL = 'PUT_UPDATE_CAMPAIGN_FAIL';

export const PUT_PUBLISH_CAMPAIGN = 'PUT_PUBLISH_CAMPAIGN';
export const PUT_PUBLISH_CAMPAIGN_SUCCESS = 'PUT_PUBLISH_CAMPAIGN_SUCCESS';
export const PUT_PUBLISH_CAMPAIGN_FAIL = 'PUT_PUBLISH_CAMPAIGN_FAIL';

export const PUT_PAUSE_CAMPAIGN = 'PUT_PAUSE_CAMPAIGN';
export const PUT_PAUSE_CAMPAIGN_SUCCESS = 'PUT_PAUSE_CAMPAIGN_SUCCESS';
export const PUT_PAUSE_CAMPAIGN_FAIL = 'PUT_PAUSE_CAMPAIGN_FAIL';

export const PUT_RESUME_CAMPAIGN = 'PUT_RESUME_CAMPAIGN';
export const PUT_RESUME_CAMPAIGN_SUCCESS = 'PUT_RESUME_CAMPAIGN_SUCCESS';
export const PUT_RESUME_CAMPAIGN_FAIL = 'PUT_RESUME_CAMPAIGN_FAIL';

export const PUT_TERMINATE_CAMPAIGN = 'PUT_TERMINATE_CAMPAIGN';
export const PUT_TERMINATE_CAMPAIGN_SUCCESS = 'PUT_TERMINATE_CAMPAIGN_SUCCESS';
export const PUT_TERMINATE_CAMPAIGN_FAIL = 'PUT_TERMINATE_CAMPAIGN_FAIL';

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAIL = 'DELETE_CAMPAIGN_FAIL';

export const REFRESH_CAMPAIGN = 'REFRESH_CAMPAIGN';
export const REFRESH_CAMPAIGN_SUCCESS = 'REFRESH_CAMPAIGN_SUCCESS';
export const REFRESH_CAMPAIGN_FAIL = 'REFRESH_CAMPAIGN_FAIL';

export const GET_CAMPAIGN_TAGS_SUMMARIZED = 'GET_CAMPAIGN_TAGS_SUMMARIZED';
export const GET_CAMPAIGN_TAGS_SUMMARIZED_SUCCESS =
  'GET_CAMPAIGN_TAGS_SUMMARIZED_SUCCESS';
export const GET_CAMPAIGN_TAGS_SUMMARIZED_FAIL =
  'GET_CAMPAIGN_TAGS_SUMMARIZED_FAIL';

export const POST_CONTACT_GROUP = "POST_CONTACT_GROUP"
export const POST_CONTACT_GROUP_SUCCESS = "POST_CONTACT_GROUP_SUCCESS"
export const POST_CONTACT_GROUP_FAIL = "POST_CONTACT_GROUP_FAIL"

export type OutboundActionTypes =
  | IGetAllCampaignsSuccess
  | IGetAllCampaignsFail
  | IGetCampaignByIdSuccess
  | IGetCampaignByIdFail
  | IGetPBXCampaignInfoSuccess
  | IGetPBXCampaignInfoFail
  | IPostCreateCampaign
  | IPostCreateCampaignSuccess
  | IPostCreateCampaignFail
  | IPutUpdateCampaignSuccess
  | IPutUpdateCampaignFail
  | IPutUpdateCampaignStatus
  | IPutUpdateCampaignStatusSuccess
  | IPutUpdateCampaignStatusFail
  | IDeleteCampaignSuccess
  | IDeleteCampaignFail
  | IRefreshCampaignSuccess
  | IRefreshCampaignFail
  | IGetCampaignTagsSummarizedSuccess
  | IGetCampaignTagsSummarizedFail
  | IPostContactGroupsAction
  | IPostContactGroupSuccess
  | IPostContactGroupFail;

export type campaignAction =
  | 'publish'
  | 'pause'
  | 'resume'
  | 'terminate'
  | 'delete';

export type CampaignStatusActions =
  | typeof PUT_PUBLISH_CAMPAIGN
  | typeof PUT_PAUSE_CAMPAIGN
  | typeof PUT_RESUME_CAMPAIGN
  | typeof PUT_TERMINATE_CAMPAIGN;

export type CampaignStatusSuccess =
  | typeof PUT_PUBLISH_CAMPAIGN_SUCCESS
  | typeof PUT_PAUSE_CAMPAIGN_SUCCESS
  | typeof PUT_RESUME_CAMPAIGN_SUCCESS
  | typeof PUT_TERMINATE_CAMPAIGN_SUCCESS;

export type CampaignStatusFail =
  | typeof PUT_PUBLISH_CAMPAIGN_FAIL
  | typeof PUT_PAUSE_CAMPAIGN_FAIL
  | typeof PUT_RESUME_CAMPAIGN_FAIL
  | typeof PUT_TERMINATE_CAMPAIGN_FAIL;

export interface IGetAllCampaigns {
  type: typeof GET_ALL_CAMPAIGNS;
  payload: string;
}

export interface IGetAllCampaignsSuccess {
  type: typeof GET_ALL_CAMPAIGNS_SUCCESS;
  payload: ICampaign[];
}

export interface IGetAllCampaignsFail {
  type: typeof GET_ALL_CAMPAIGNS_FAIL;
  payload: string;
}

export interface IGetCampaignById {
  type: typeof GET_CAMPAIGN_BY_ID;
  payload: string;
}

export interface IGetCampaignByIdSuccess {
  type: typeof GET_CAMPAIGN_BY_ID_SUCCESS;
  payload: ICampaign;
}

export interface IGetCampaignByIdFail {
  type: typeof GET_CAMPAIGN_BY_ID_FAIL;
  payload: string;
}

export interface IGetPBXCampaignInfo {
  type: typeof GET_PBX_CAMPAIGN_INFO;
}

export interface IGetPBXCampaignInfoSuccess {
  type: typeof GET_PBX_CAMPAIGN_INFO_SUCCESS;
  payload: IPBXCampaignInfo;
}

export interface IGetPBXCampaignInfoFail {
  type: typeof GET_PBX_CAMPAIGN_INFO_fAIL;
  payload: string;
}

export interface IPostCreateCampaign {
  type: typeof POST_CREATE_CAMPAIGN;
  payload: ICreateCampaign;
}

export interface IPostCreateCampaignSuccess {
  type: typeof POST_CREATE_CAMPAIGN_SUCCESS;
  payload: string;
}

export interface IPostCreateCampaignFail {
  type: typeof POST_CREATE_CAMPAIGN_FAIL;
  payload: string;
}

export interface IPutUpdateCampaign {
  type: typeof PUT_UPDATE_CAMPAIGN;
  payload: {
    id: string;
    index: number;
    editedCampaign: ICreateCampaign;
  };
}

export interface IPutUpdateCampaignSuccess {
  type: typeof PUT_UPDATE_CAMPAIGN_SUCCESS;
  payload: { index: number; campaign: ICampaign };
}

export interface IPutUpdateCampaignFail {
  type: typeof PUT_UPDATE_CAMPAIGN_FAIL;
  payload: string;
}

export interface IPutUpdateCampaignStatus {
  type: CampaignStatusActions;
  payload: {
    id: string;
    index: number;
    newStatus: campaignAction;
  };
}
export interface IPutUpdateCampaignStatusSuccess {
  type: CampaignStatusSuccess;
  payload: { index: number; campaign: ICampaign };
}

export interface IPutUpdateCampaignStatusFail {
  type: CampaignStatusFail;
  payload: string;
}

export interface IDeleteCampaign {
  type: typeof DELETE_CAMPAIGN;
  payload: { id: string; index: number };
}
export interface IDeleteCampaignSuccess {
  type: typeof DELETE_CAMPAIGN_SUCCESS;
  payload: { index: number };
}
export interface IDeleteCampaignFail {
  type: typeof DELETE_CAMPAIGN_FAIL;
  payload: string;
}

export interface IRefreshCampaign {
  type: typeof REFRESH_CAMPAIGN;
  payload: { id: string; index: number };
}
export interface IRefreshCampaignSuccess {
  type: typeof REFRESH_CAMPAIGN_SUCCESS;
  payload: { index: number; campaign: ICampaign };
}
export interface IRefreshCampaignFail {
  type: typeof REFRESH_CAMPAIGN_FAIL;
  payload: string;
}

export interface IGetCampaignTagsSummarized {
  type: typeof GET_CAMPAIGN_TAGS_SUMMARIZED;
  payload: { campaignIndex: number; campaignId: string };
}
export interface IGetCampaignTagsSummarizedSuccess {
  type: typeof GET_CAMPAIGN_TAGS_SUMMARIZED_SUCCESS;
  payload: {
    campaignIndex: number;
    tagsSummarized: ITagSummarized[];
  };
}
export interface IGetCampaignTagsSummarizedFail {
  type: typeof GET_CAMPAIGN_TAGS_SUMMARIZED_FAIL;
  payload: string;
}

export interface IPostContactGroupsAction {
  type: typeof POST_CONTACT_GROUP,
  payload: { totalGroups: number; }
}
export interface IPostContactGroupSuccess {
  type: typeof POST_CONTACT_GROUP_SUCCESS,
  payload: { sendingGroup: number; }
}
export interface IPostContactGroupFail {
  type: typeof POST_CONTACT_GROUP_FAIL,
  payload: string
}