import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Divider from '../../Divider';
import * as DataInfo from '../../DataInfo';
import ViewAudioLibrary from '../common/AudioLibrary/ViewAudioLibrary';

View.propTypes = {
  data: PropTypes.object.isRequired,
};

function View({ data }) {
  return (
    <div>
      <Row>
        <Col lg={12}>
          <DataInfo.Label>Function</DataInfo.Label>
          <DataInfo.Content>{data?.function || '-'}</DataInfo.Content>
        </Col>
      </Row>

      <DataInfo.Switch label="" value={true} divider={false}>
        <Divider />
        <ViewAudioLibrary name="audioLibrary" />
      </DataInfo.Switch>
    </div>
  );
}

export default View;
