export const conditions = [
  { id: '0', name: 'Equal To', value: '==' },
  { id: '1', name: 'Not Equal To', value: '!=' },
  { id: '2', name: 'Less Than', value: '<' },
  { id: '3', name: 'LessEqual Than', value: '<=' },
  { id: '4', name: 'Greater Than', value: '>' },
  { id: '5', name: 'GreaterEqual Than', value: '>=' },
  { id: '6', name: 'Matches Any Of', value: 'MatchesAnyOf' },
  { id: '7', name: 'Do Not Match Any Of', value: 'DoesNotMatchAnyOf' },
  { id: '8', name: 'Contains', value: 'Contains' },
  { id: '9', name: 'Does Not Contain', value: 'DoesNotContain' },
  { id: '11', name: 'Starts With', value: 'StartsWith' },
  { id: '12', name: 'Does Not Start With', value: 'DoesNotStartWith' },
  { id: '13', name: 'Is Blank', value: 'IsBlank' },
  { id: '14', name: 'Is Not Blank', value: 'IsNotBlank' },
];
