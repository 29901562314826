import { Completed, Draft, Pause, Phone, Whatsapp } from 'icons';
import { blueGraph, darkBorder, primary } from 'library/colors';
import { IWaCampaign } from 'library/interfaces/whatsappCampaignInterfaces';
import React, { FC } from 'react';
import styled from 'styled-components';
import StatusAndButtons from '../molecules/statusAndButtons';
import WACounter from '../molecules/waCounter';
import { dateIsoFormated } from 'library/services/dateServices';
// import LoadingBarChart from '../atoms/loadingBarChart';
// import SMSCounter from '../molecules/smsCounter';
// import StatusAndButtons from '../molecules/statusAndButtons';

interface IWACampaignProps {
  campaign: IWaCampaign;
}
const WACampaign: FC<IWACampaignProps> = ({ campaign }) => {
  return (
    <Cont>
      <FlexCont>
        <LeftCont>
          {campaign.status === 'created' && (
            <IconCont>
              <Draft width={25} color={darkBorder} />
            </IconCont>
          )}
          {campaign.status === 'stopped' && (
            <IconCont left="-47px">
              <Pause width={40} color={'orange'} />
            </IconCont>
          )}
          {campaign.status === 'running' && (
            <IconCont left="-45px">
              <Whatsapp width={30} color={primary} />
            </IconCont>
          )}
          {campaign.status === 'completed' && (
            <IconCont left="-45px">
              <Completed width={30} color={blueGraph} />
            </IconCont>
          )}
          <Title>{campaign.name}</Title>
          <div>Creada el: {dateIsoFormated(campaign.created_at)}</div>
          <TotalContacts>Contactos: {campaign.contacts_count}</TotalContacts>
        </LeftCont>
        <RightCont>
          <StatusAndButtons waCampaign={campaign} />
        </RightCont>
      </FlexCont>
      <WACounter
        dispatched_count={campaign.dispatched_count}
        dispatched_delivered={campaign.dispatched_delivered}
        dispatched_enqueued={campaign.dispatched_enqueued}
        dispatched_error={campaign.dispatched_error}
        dispatched_failed={campaign.dispatched_failed}
        dispatched_read={campaign.dispatched_read}
        dispatched_sent={campaign.dispatched_sent}
        dispatched_replied={campaign.dispatched_replied}
      />
    </Cont>
  );
};
const Cont = styled.div`
  border-radius: 10px;
  padding: 20px 50px 20px 90px;
  color: black;
  max-width: 1100px;
  margin: 0 auto 20px;
  border: 1px solid ${darkBorder};
`;
const FlexCont = styled.div`
  display: flex;
`;
const LeftCont = styled.div`
  flex-grow: 1;
  /* border: 1px solid red; */
  max-width: 50%;
  position: relative;
`;
const RightCont = styled.div`
  flex-grow: 1;
  max-width: 50%;
  display: flex;
  /* border: 1px solid; */
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
`;
const TotalContacts = styled.div`
  margin-bottom: 25px;
  font-size: 0.75rem;
`;
const IconCont = styled.div<IIconCont>`
  position: absolute;
  left: ${props => (props.left ? props.left : '-40px')};
  top: 5px;
`;
interface IIconCont {
  left?: string;
}
export default WACampaign;
