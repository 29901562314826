import * as React from 'react';

export const SceneAll = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M73.546 45.833H33.68V5.966h39.866zM95.546 70.027l28.191 28.19-28.19 28.191M53.622 98.194v-52.38M117.783 98.194H10.227"
      fill="none"
      stroke="#7c9904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={11.8598}
    />
  </svg>
);
