import * as React from 'react';

export const HangUpCall = props => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 204.46 186.63"
        {...props}
        >
        {/* <title>Recurso 13</title> */}
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_6" data-name="Capa 6">
                <line fill='currentColor' x1="156.14" y1="33.65" x2="17.81" y2="171.98"/>
                <rect fill='currentColor' x="-10.84" y="94.81" width="195.62" height="16" transform="translate(-47.23 91.61) rotate(-45)"/>
                <path fill='currentColor' d="M51.56,70.5,71.24,48a14.07,14.07,0,0,0-1.33-19.84L41.68,3.48A14,14,0,0,0,21.84,4.81L8.42,20.14l-1.5,1.72A31.47,31.47,0,0,0,3,28.51C.29,34.77-1.56,44,1.86,55.29c.65,2.18,1.45,4.44,2.31,6.81l.07.19c.22.61.45,1.22.68,1.84,5.48,14.43,14.7,32.24,32.53,52.14l28-28A224.44,224.44,0,0,1,51.56,70.5Z"/>
                <path fill='currentColor' d="M199.65,141.58,171.42,116.9a14.06,14.06,0,0,0-19.84,1.33L133,139.49a237.16,237.16,0,0,1-29.41-17.21L75.32,150.53q6.74,5,13.19,9.09c3.11,2,6.18,3.83,9.18,5.54l2.43,1.36,1.54.84c1.79,1,3.55,1.88,5.29,2.75,1,.5,2,1,2.94,1.45,1.58.76,3.13,1.48,4.67,2.17l2.3,1,2.39,1c2.33,1,4.61,1.87,6.84,2.7l1.33.49a201.7,201.7,0,0,0,23.89,7,30.39,30.39,0,0,0,6.73.69c9.54,0,23.4-2.86,27.26-7.27.46-.53,11.79-13,11.79-13l3.89-4.9A14,14,0,0,0,199.65,141.58Z"/>
            </g>
        </g>
    </svg>
);