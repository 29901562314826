import AgentCallsTemplate from 'components/LiveDashboard/templates/agentCallsTemplate';
import React from 'react';
import MetaTags from 'react-meta-tags';

const PagesStarter = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Agent Calls | SmartBot</title>
        </MetaTags>
        <AgentCallsTemplate />
      </div>
    </React.Fragment>
  );
};
export default PagesStarter;
