import { getAws, postAws } from 'helpers/api_aws_helper';
import { IReportData } from 'store/reports/reducer';
import * as XLSX from 'xlsx';

/**
 * Solicita el reporte de campañas
 * @param reportType
 * @param campaignId
 * @param owner
 * @returns
 */
export const requestReport = (
  reportType: string | number,
  campaignId: string,
  owner: string,
): Promise<IReportData> => {
  return postAws(
    `/report/v2/type/${reportType}/owner/${owner.toLocaleLowerCase()}?campaign=${campaignId}&force=true`,
  );
};

/**
 * Recibe la respuesta al solicitar el reporte con el post.
 * Si el estado es complete y existe la uri retorna la uri del reporte.
 *
 * Si el estado es in-progress hace un get cada dos segundos hasta que cambie el in-progress.
 *
 * Si el estado llegara a ser failed, retorna un string vacío
 *
 * @param requestReportResponse la respuesta al solicitar el reporte con el post
 * @param owner
 * @returns la uri del deporte
 */
export const getInProgressReport = async (
  requestReportResponse: IReportData,
  owner: string,
) => {
  let uri = '';

  if (
    requestReportResponse.status === 'completed' &&
    requestReportResponse.content_data &&
    requestReportResponse.content_data.uri
  ) {
    uri = requestReportResponse.content_data.uri;
    return uri;
  }

  try {
    while (requestReportResponse.status === 'in-progress') {
      await new Promise(resolve => setTimeout(resolve, 2000)); // Esperar 2 segundos
      const getReport: IReportData = await getAws(
        `/report/${
          requestReportResponse.id
        }/owner/${owner.toLocaleLowerCase()}`,
      );

      requestReportResponse.status = getReport.status;

      if (getReport.content_data && getReport.content_data.uri) {
        uri = getReport.content_data.uri;
      }
    }
    return uri;
  } catch (error) {
    console.log(error);
    return '';
  }
};

/**
 * Hace el fetch del reporte excel y lo retonar como un array buffet
 * @param url url del reporte
 * @returns
 */
export const getExcelAsBuffer = async (url: string) => {
  try {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return buffer;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 *
 * @param buffer buffer del reporte solicitado
 * @returns
 */
export const excelBufferToJson = async (buffer: ArrayBuffer) => {
  const workbook = XLSX.read(buffer, { type: 'buffer' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];

  const excelData: Record<string, string | number>[] =
    XLSX.utils.sheet_to_json(worksheet);
  const columns = excelData.length > 0 ? Object.keys(excelData[0]) : [];

  return { excelData, columns, worksheet };
};
/**
 * optiene el reporte csv y lo parsea a string
 * @param url url del reporte csv
 * @returns el reporte csv parseado como un string
 */
export const getCsvAsString = async (url: string): Promise<string | null> => {
  try {
    const response = await fetch(url);

    const buffer = await response.arrayBuffer();
    const csvDecoded = new TextDecoder('utf-8').decode(buffer);

    return csvDecoded;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * convierte el csv en un objeto json y crea el objeto con el indice que se utilizara
 * @param csvString
 * @param idName
 * @param separator
 * @returns
 */
export const csvStringParsed = (
  csvString: string,
  idName?: string,
  separator = ';',
) => {
  const csvDoubleSplited = csvString
    .split('\n')
    .map(row => row.split(separator));

  // suponemos la primera fila del csv son el nombre de las columnas
  const columns = csvDoubleSplited.shift() || [];

  const csvData: Record<string, string | number>[] = [];
  const idIndex: Record<string, number> | null = idName ? {} : null;

  for (let i = 0; i < csvDoubleSplited.length - 1; i++) {
    const currentRow = csvDoubleSplited[i];

    const obj = columns.reduce((acc, cur, j) => {
      if (idIndex && idName === cur) {
        idIndex[currentRow[j]] = i;
      }
      acc[cur] = currentRow[j];
      return acc;
    }, {} as Record<string, string | number>);
    csvData.push(obj);
  }
  return { csvData, idIndex };
};

/**
 * recibe los json de ambos reportes y los une utilizando el indice
 * y la columna expecificada como clave
 *
 * @param csvData json del csv
 * @param excelData json del excel
 * @param idIndex objeto indice
 * @param columnKey nombre de la columna con el valor que se buscará en el indice
 * @returns
 */

export const consolidateData = (
  csvData: Record<string, string | number>[],
  excelData: Record<string, string | number>[],
  idIndex: Record<string, number>,
  columnKey: string | number,
) => {
  const consolidatedData = structuredClone(csvData);

  excelData.forEach(data => {
    if (idIndex[data[columnKey]] || idIndex[data[columnKey]] === 0) {
      consolidatedData[idIndex[data[columnKey]]] = {
        ...consolidatedData[idIndex[data[columnKey]]],
        ...data,
      };
    }
  });

  return consolidatedData;
};
/**
 * Crea el reporte excel
 * @param fileName
 * @param sheets
 */
export const createExcellReport = (
  fileName: string,
  sheets: {
    sheetName: string;
    data: Record<string, string | number>[];
  }[],
) => {
  const date = new Date(Date.now()).toISOString().substring(0, 10);
  const workbook = XLSX.utils.book_new();
  sheets.forEach(sheet => {
    const worksheet = XLSX.utils.json_to_sheet(sheet.data);
    XLSX.utils.book_append_sheet(workbook, worksheet, `${sheet.sheetName}`);
  });
  XLSX.writeFile(workbook, `${fileName} ${date}.xlsx`);
};
