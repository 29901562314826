import {
  darkText,
  lightBackground,
  lightBackgroundBlue,
  primary,
} from 'library/colors';
import { ICreateCampaignMainInfoState } from 'library/interfaces/campaignInterfaces';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IAgentToSelect } from '../atoms/selectableAgent';

interface ICreateCampaignResumeProps {
  totalContacts: number;
  agentsToSelect: IAgentToSelect[];
  trunksName: string;
  mainInfo: ICreateCampaignMainInfoState
  campaignNumbers: {callerId: string; did: string; }
}
const CreateCampaignResume: FC<ICreateCampaignResumeProps> = ({
  mainInfo,
  trunksName,
  totalContacts,
  agentsToSelect,
  campaignNumbers
}) => {
  const {t, i18n } = useTranslation('outbound')
  const currentLang = i18n.language.split('_').join('-')
  const recording = mainInfo.recording ? t('campaigns.createCampaignForm.resume.yes') : t('campaigns.createCampaignForm.resume.no');
  const executionDays = mainInfo.execution_day.filter(day=>day.execution_time.length > 0)
  const selectedAgents = agentsToSelect.filter(
    agent => agent.isSelected === true,
    );
  
  return (
    <Cont>
      <H1>
        <Span>{mainInfo.name}</Span>
      </H1>
      <FlexCont margin='15px 0 0'>
        <Section>

          <DataCont noMarginBottom>{t('campaigns.createCampaignForm.resume.configuration')}</DataCont>
          <SectionWhiteBG margin='0 0 10px'>
            <FlexCont>
              <DataCont width='35%'>
                {t('campaigns.createCampaignForm.resume.channels')}: <b> {mainInfo.channels}</b>
              </DataCont>

              <DataCont width='65%'>
                {t('campaigns.createCampaignForm.resume.callerId')}: <b> {campaignNumbers.callerId}</b>
              </DataCont>
              
              
            </FlexCont>

            <FlexCont>

              <DataCont width='35%'>
                {t('campaigns.createCampaignForm.resume.attemps')}: <b> {mainInfo.attempts}</b>
              </DataCont>

              

              <DataCont width='65%'>
                {t('campaigns.createCampaignForm.resume.did')}: <b> {campaignNumbers.did}</b>
              </DataCont>
              
              
            </FlexCont>
            
            <FlexCont>
              


              <DataCont width='35%'>
                {t('campaigns.createCampaignForm.resume.recording')}: <b> {recording}</b>
              </DataCont>

              <DataCont width='65%'>
               {t('campaigns.createCampaignForm.resume.contacts')}: <b> {totalContacts ? totalContacts : ""}</b>
            </DataCont>

            </FlexCont>

            <DataCont>
              {t('campaigns.createCampaignForm.resume.trunk')}: <b> {trunksName}</b>
              </DataCont>
          </SectionWhiteBG>

          

              <DataCont width='35%'>
                {t('campaigns.createCampaignForm.resume.schedule')}:
              </DataCont>



          <SectionWhiteBG>

            <DataCont noMarginBottom>
            {t('campaigns.createCampaignForm.resume.from')}: <b>{mainInfo.date_start}</b> 
            {t('campaigns.createCampaignForm.resume.to')}: <b>{mainInfo.date_end}</b>
            </DataCont>

            <ExcludeDays>
              {executionDays.map((day, index) => {
                return (
                  <div key={index}>
                    {t(`campaigns.createCampaignForm.resume.${day.execution_day}`)}:{' '} &nbsp;
                    {day.execution_time.map((time, index)=> {
                      const exeTime = time.start_time + '-' + time.end_time 
                      const punctuation: string = (index + 1 === day.execution_time.length) ? '.' : ', '
                      return (
                        <label key={index}>{exeTime + punctuation}&nbsp;</label>
                      )
                    })} 
                  </div>
                )
              })}

            </ExcludeDays>

            
          </SectionWhiteBG>
        </Section>









        <Section marginLeft>
          
          <DataCont>
          {t('campaigns.createCampaignForm.resume.selectedAgents')}: <b> {selectedAgents.length}</b>
            <AgentList>
              {selectedAgents.map((agent, index) => {
                return <div key={index}>{agent.name}</div>;
              })}
            </AgentList>
          </DataCont>
          <DataCont>
          {t('campaigns.createCampaignForm.resume.daysExcluded')}: <b> {mainInfo.exclude_day.length}</b>
              <ExcludeDays>
                <ul>
                  {mainInfo.exclude_day.map((day, index) => {
                    const date = new Date(day.day + 'T00:00:00').toLocaleDateString(currentLang, { year:"numeric", month:"long", day:"numeric", weekday:"long" }) 
                    return <li key={index}>{date}</li>;
                  })}
                </ul>
              </ExcludeDays>
            </DataCont>
        </Section>
      </FlexCont>
    </Cont>
  );
};
const Cont = styled.div`
  height: 420px;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
const H1 = styled.h1`
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  margin: 0px auto 10px;
  color: ${darkText};
`;
const Span = styled.span`
  padding: 4px 25px;
  border-radius: 30px;
  text-transform: uppercase;
  background-color: ${lightBackground};
`;
const FlexCont = styled.div<IFlexCont>`
  display: flex;
  justify-content: space-between;
  margin: ${props=>props.margin && props.margin};
`;
const Section = styled.section<ISection>`
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 345px;
  margin-left: ${props=>props.marginLeft && '20px'};
  
`;
const SectionWhiteBG = styled.div<SectionWhiteBG>`
  border-radius: 10px;
  flex-grow: 1;
  padding: 10px 20px;
  margin: ${props=>props.margin};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
`;
const DataCont = styled.div<IDataCont>`
  font-size: 0.9rem;
  font-weight: 300;
  width: ${props=>props.width && props.width};
  margin-bottom: ${props=>!props.noMarginBottom && '5px'};
  & > b {
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0 5px;
  }
  & > span {
    font-size: 1rem;
    font-weight: 500;
  }
`;
const AgentList = styled.div`
  max-height: 200px;
  padding: 5px 20px;
  border-radius: 10px;
  //margin-bottom: 10px;
  background-color: white;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const ExcludeDays = styled.div`
  background-color: white;
  border-radius: 10px;
  height: 90px;
  padding: 5px 10px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
interface IFlexCont {
  margin?: string;
}
interface SectionWhiteBG {
  margin?: string;
}
interface ISection {
  marginLeft?: React.ReactNode
}
interface IDataCont {
  width?: string;
  noMarginBottom?: React.ReactNode
}
export default CreateCampaignResume;
