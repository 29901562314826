import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../FormContainer';
import { Form, Formik } from 'formik';
import { Col, Label, Row } from 'reactstrap';
import * as Fields from '../../Fields';
import * as Yup from 'yup';
import SynthLanguage from '../common/SynthLanguage';
import Divider from '../../Divider';
import AudioLibrary from '../common/AudioLibrary';
import Replays from '../common/Replays';

const validationSchema = Yup.object({
  outputType: Yup.string(),
});

EditOutputSettings.propTypes = {
  data: PropTypes.object.isRequired,
  toggleInEdit: PropTypes.func.isRequired,
};

function EditOutputSettings({ data, toggleInEdit }) {
  const onSubmit = values => {
    data.outputType = values.outputType;
    data.synthLanguage = values.synthLanguage;
    data.voice = values.voice;
    data.text = values.text;
    data.failTexts = values.failTexts;
    data.audioLib1 = values.audioLib1;
    data.replays = values.replays;
    data.audioLib2 = values.audioLib2;

    toggleInEdit();
  };

  return (
    <FormContainer title="Output Settings">
      <Formik
        initialValues={{
          outputType: data?.outputType || 'voiceSyntesis',
          synthLanguage: data?.synthLanguage,
          voice: data?.voice,
          text: data?.text,
          failTexts: data?.failTexts,
          audioLib1: data?.audioLib1,
          replays: data?.replays,
          audioLib2: data?.audioLib2,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <Form>
            <Row>
              <Col lg={12}>
                <Fields.Radio
                  inline={true}
                  name="outputType"
                  label="Voice synthesis"
                  value="voiceSyntesis"
                />
                <span style={{ marginRight: 16 }}>or</span>
                <Fields.Radio
                  inline={true}
                  name="outputType"
                  label="Audiorecord"
                  value="audiorecord"
                />
              </Col>
            </Row>

            {formik?.values.outputType === 'voiceSyntesis' ? (
              <>
                <Divider />
                <SynthLanguage />

                <Row>
                  <Col lg={12}>
                    <Label>
                      Text (
                      <a
                        href="https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        SSML tags
                      </a>
                      )
                    </Label>
                    <Fields.TextArea name="text" placeholder="Text ..." />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Replays name="replays" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Label>
                      Text (
                      <a
                        href="https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        SSML tags
                      </a>
                      )
                    </Label>
                    <Fields.TextArea name="failTexts" placeholder="Text ..." />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <AudioLibrary name="audioLib1" options={[]} />
                <Row>
                  <Col lg={12}>
                    <Replays name="replays" />
                  </Col>
                </Row>
                <AudioLibrary name="audioLib2" options={[]} />
              </>
            )}

            <div className="mt-3">
              <button type="submit" className="btn btn-primary btn-sm">
                Save
              </button>
              <button className="btn btn-default btn-sm" onClick={toggleInEdit}>
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

export default EditOutputSettings;
