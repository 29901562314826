import React, { FC } from 'react';
import styled from 'styled-components';

import Container from '../atoms/container';
import SubTitle from '../atoms/subTitle';
import TimeCounterAndMinMax from '../atoms/timeCountMinMax';
import ChangePrev from '../atoms/changePrev';
import { useTranslation } from 'react-i18next';

interface IAtentionTimeProps {
  service_average: string;
  service_min_time: string;
  service_max_time: string;
  service_change: string;
}
const AtentionTime: FC<IAtentionTimeProps> = ({
  service_average,
  service_min_time,
  service_max_time,
  service_change,
}) => {
  const {t} = useTranslation('summarize')
  return (
    <Container padding="20px">
      <SubTitle sub={t('last24hs')}>{t('averageAttentionTime')}</SubTitle>

      <TimeCounterAndMinMax time_average={service_average} time_min_time={service_min_time} time_max_time={service_max_time}/>

      <ChangePrev change={service_change} prev={0} />
    </Container>
  );
};
export default AtentionTime;
