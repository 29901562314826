import React, { FC } from 'react';
import styled from 'styled-components';

interface IIndicatorProps {
  label: string;
  total?: number;
}
const Indicator: FC<IIndicatorProps> = ({ label, total = 0 }) => {
  return (
    <Cont>
      <Total>{total}</Total>
      <Label>{label}</Label>
    </Cont>
  );
};
const Cont = styled.div`
  //border: 1px solid;
  text-align: center;
  min-width: 60px;
`;
const Total = styled.div`
  text-align: center;
  font-size: 1.5rem;
`;
const Label = styled.div`
  font-size: 0.7rem;
  border-radius: 50px;
  padding: 0 5px;
  background-color: aliceblue;
`;
export default Indicator;
