import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface ISearchProps {
    filter: string;
    setfilter: (filter: string) => void;
}
const Search = ({ setfilter, filter }: ISearchProps) => {
    const { t } = useTranslation('workspace')
    return (
        <Input type="text" placeholder={t('search')} value={filter} onChange={e => setfilter(e.target.value)} />
    )
}
const Input = styled.input`
    border: none;
    outline: none;
    width: 100%;
    min-height: 35px;
    font-size: .9rem;
    padding-left: 20px;
    margin: 0px auto;
    border-radius: 50px;
`
export default Search