import { darkBorder } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IItemProps {
  label: string
  children?: React.ReactElement;
}
const Item: FC<IItemProps> = ({ label, children }) => {
  return (
    <Cont>
      {label}
      {children}
    </Cont>
  );
};
const Cont = styled.div`
font-weight: 500;
position: relative;
padding: 5px 10px;
margin-bottom: 5px;
border-radius: 5px;
border: 1px solid ${darkBorder};
cursor: pointer;

:after {
  content: '';
  position: absolute;
  top: 50%; 
  right: 10px; 
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent; 
  border-left: 10px solid ${darkBorder}; 
  transform: translateY(-50%); 
}

&>div{
  display: none;
}

  
  &:hover {
    color: #fff;
    background-color: ${darkBorder};

    &>div{
      display: block;
    }

    ::after{
      border-left-color: #000;
    }
  }
`;
export default Item;