import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { darkBorder, primary } from 'library/colors';
import RightBarCustomerInfo from 'components/WorkSpace/molecules/rightBarCustomerInfo';
import RightBarIcons from '../molecules/rightBarIcons';

interface IRightBarProps {
  children: React.ReactNode;
  hide?: boolean
  setHide?: (hide: boolean) => void
}
const RightBar: FC<IRightBarProps> = ({ children, hide, setHide }) => {
  const [extendedBar, setExtendedBar] = useState(false);

  const handleExtendedBar = () => {
    setExtendedBar(!extendedBar);
  };

  useEffect(() => {
    if (!hide || !setHide) return
    setExtendedBar(false)
    setHide(false)
  }, [hide])
  return (
    <Cont extendedBar={extendedBar}>
      {/* <RightBarCustomerInfo /> */}
      <ChildrenCont>{children}</ChildrenCont>
      <RightBarIcons handleExtendedBar={handleExtendedBar} />
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  min-width: ${props => (props.extendedBar ? '250px' : '50px')};
  max-height: 75vh;
  margin-left: 10px;
  border-radius: 5px;
  color: ${primary};
  font-size: 1rem;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  transition: min-width 1s, box-shadow 1s;
  box-shadow: ${props => props.extendedBar && `-5px 5px 8px ${darkBorder}`};
  //flex-basis: auto;
`;
const ChildrenCont = styled.div`
  width: 200px;
  padding: 20px 10px;
  font-size: 0.8rem;
  transform: translate(0px);
  position: absolute;
  left: 0;
  max-height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;

interface ICont {
  extendedBar: boolean;
}
export default RightBar;
