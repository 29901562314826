import {
  IExecutionDay,
  ISchedule,
  ITagSummarized,
} from 'library/interfaces/campaignInterfaces';
import {
  GET_ALL_CAMPAIGNS_FAIL,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_BY_ID_FAIL,
  GET_CAMPAIGN_BY_ID_SUCCESS,
  GET_PBX_CAMPAIGN_INFO_fAIL,
  GET_PBX_CAMPAIGN_INFO_SUCCESS,
  OutboundActionTypes,
  POST_CREATE_CAMPAIGN_FAIL,
  POST_CREATE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN_SUCCESS,
  PUT_PAUSE_CAMPAIGN_FAIL,
  PUT_PAUSE_CAMPAIGN_SUCCESS,
  PUT_PUBLISH_CAMPAIGN_FAIL,
  PUT_PUBLISH_CAMPAIGN_SUCCESS,
  PUT_RESUME_CAMPAIGN_FAIL,
  PUT_RESUME_CAMPAIGN_SUCCESS,
  PUT_TERMINATE_CAMPAIGN_FAIL,
  PUT_TERMINATE_CAMPAIGN_SUCCESS,
  PUT_UPDATE_CAMPAIGN_FAIL,
  PUT_UPDATE_CAMPAIGN_SUCCESS,
  REFRESH_CAMPAIGN_SUCCESS,
  REFRESH_CAMPAIGN_FAIL,
  GET_CAMPAIGN_TAGS_SUMMARIZED_FAIL,
  GET_CAMPAIGN_TAGS_SUMMARIZED_SUCCESS,
  POST_CREATE_CAMPAIGN,
  POST_CONTACT_GROUP,
  POST_CONTACT_GROUP_SUCCESS,
  POST_CONTACT_GROUP_FAIL,
  PUT_PUBLISH_CAMPAIGN,
  PUT_PAUSE_CAMPAIGN,
  PUT_RESUME_CAMPAIGN,
  PUT_TERMINATE_CAMPAIGN,
} from './actionTypes';

export interface ICampaignSchedule {
  execution_day: IExecutionDay;
  execution_time: {
    start_time: string;
    end_time: string;
  }[];
}
export interface ICampaignAgents {
  name: string;
}
export interface ICampaignContacts {
  contact: string;
  e64_data: string;
}

export type ICampaignStatus =
  | 'published'
  | 'draft'
  | 'running'
  | 'paused'
  | 'completed'
  | 'pausing'
  | 'resuming'
  | 'ending';

export interface ICampaign {
  id: string;
  name: string;
  // timestamp: string;
  updated_at: string;
  date_start: string;
  date_end: string;
  timezone: string;
  callerid: string;
  did: string;
  schedule: ISchedule[];
  exclude_day: { day: string }[];
  trunk_id: string;
  channels: number;
  attempts: number;
  recording: boolean;
  destination: {
    agents: ICampaignAgents[];
  };
  contacts: ICampaignContacts[] | undefined;
  status: ICampaignStatus;
  tagsSummarized: ITagSummarized[] | undefined;
}
export interface ITrunks {
  id: string;
  name: string;
}
export interface IPBXQueues {
  id: string;
  name: string;
  agents: { id: string }[];
}
export interface IPBXCampaignInfo {
  pbx_uri: string;
  account_code: string;
  trunks: ITrunks[];
  queues: IPBXQueues[];
}
export interface IPostContacGroups {
  error: string;
  totalGroups: number;
  sendingGroup: number;
}
export interface IVoiceCampaignsStore {
  error: string;
  newCampaignId: string;
  campaigns: ICampaign[];
  pxbCampaignInfo: IPBXCampaignInfo | null;
  postContactGroups: IPostContacGroups | null;
}

const INIT_STATE: IVoiceCampaignsStore = {
  error: '',
  newCampaignId: '',
  campaigns: [],
  pxbCampaignInfo: null,
  postContactGroups: null,
};

const voiceCampaigns = (
  state = INIT_STATE,
  action: OutboundActionTypes,
): IVoiceCampaignsStore => {
  switch (action.type) {
    case GET_ALL_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        error: '',
        campaigns: action.payload,
      };
    }
    case GET_CAMPAIGN_BY_ID_SUCCESS: {
      return {
        ...state,
        campaigns: [action.payload, ...state.campaigns],
      };
    }
    case GET_PBX_CAMPAIGN_INFO_SUCCESS: {
      return {
        ...state,
        pxbCampaignInfo: action.payload,
      };
    }
    case POST_CREATE_CAMPAIGN: {
      return {
        ...state,
        postContactGroups: {
          error: '',
          totalGroups: 0, // is only to define postContactGroups to render loading component
          sendingGroup: 0,
        },
      };
    }
    case POST_CREATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        newCampaignId: action.payload,
        postContactGroups: null,
      };
    }
    case POST_CONTACT_GROUP: {
      return {
        ...state,
        postContactGroups: {
          error: '',
          totalGroups: action.payload.totalGroups,
          sendingGroup: 0,
        },
      };
    }
    case POST_CONTACT_GROUP_SUCCESS: {
      if (!state.postContactGroups) return state;
      return {
        ...state,
        postContactGroups: {
          ...state.postContactGroups,
          sendingGroup: action.payload.sendingGroup,
        },
      };
    }
    case POST_CONTACT_GROUP_FAIL: {
      if (!state.postContactGroups) return state;
      return {
        ...state,
        postContactGroups: {
          ...state.postContactGroups,
          error: action.payload,
        },
      };
    }
    // update the state of campaign with actions to ensure the refresh execution 
    case PUT_PUBLISH_CAMPAIGN:
    case PUT_PAUSE_CAMPAIGN:
    case PUT_RESUME_CAMPAIGN:
    case PUT_TERMINATE_CAMPAIGN: {
      const { newStatus, index } = action.payload;
      const currentStatus =
        newStatus === 'publish'
          ? 'published'
          : newStatus === 'pause'
          ? 'pausing'
          : newStatus === 'resume'
          ? 'resuming'
          : 'ending';
      const campaigns = structuredClone(state.campaigns)
      campaigns[index].status = currentStatus
      return {
        ...state,
        campaigns: [...campaigns]
      };
    }
    case PUT_UPDATE_CAMPAIGN_SUCCESS:
    case PUT_PUBLISH_CAMPAIGN_SUCCESS:
    case PUT_PAUSE_CAMPAIGN_SUCCESS:
    case PUT_RESUME_CAMPAIGN_SUCCESS:
    case PUT_TERMINATE_CAMPAIGN_SUCCESS: {
      const { index, campaign } = action.payload;
      const campaignsAfterUpdate = structuredClone(state.campaigns);
      campaignsAfterUpdate[index] = campaign;
      return {
        ...state,
        campaigns: [...campaignsAfterUpdate],
      };
    }
    case DELETE_CAMPAIGN_SUCCESS: {
      const { index } = action.payload;
      const campaigns = structuredClone(state.campaigns)
      campaigns.splice(index, 1); // this line remove the campaign from the campaign list
      const campaignsAfterDelete = campaigns;
      return {
        ...state,
        campaigns: [...campaignsAfterDelete],
      };
    }
    case REFRESH_CAMPAIGN_SUCCESS: {
      const { index, campaign } = action.payload;
      const campaigns = structuredClone(state.campaigns);

      campaigns[index] = campaign;
      const campaignsAfterRefresh = campaigns;
      return {
        ...state,
        campaigns: [...campaignsAfterRefresh],
      };
    }
    case GET_CAMPAIGN_TAGS_SUMMARIZED_SUCCESS: {
      const { campaignIndex, tagsSummarized } = action.payload;
      const campaigns = structuredClone(state.campaigns);

      campaigns[campaignIndex].tagsSummarized = tagsSummarized;
      const campaignsAfterAddTagsSummarized = campaigns;
      return {
        ...state,
        campaigns: [...campaignsAfterAddTagsSummarized],
      };
    }
    case GET_ALL_CAMPAIGNS_FAIL:
    case GET_CAMPAIGN_BY_ID_FAIL:
    case GET_PBX_CAMPAIGN_INFO_fAIL:
    case POST_CREATE_CAMPAIGN_FAIL:
    case PUT_UPDATE_CAMPAIGN_FAIL:
    case PUT_PUBLISH_CAMPAIGN_FAIL:
    case PUT_PAUSE_CAMPAIGN_FAIL:
    case PUT_RESUME_CAMPAIGN_FAIL:
    case PUT_TERMINATE_CAMPAIGN_FAIL:
    case DELETE_CAMPAIGN_FAIL:
    case REFRESH_CAMPAIGN_FAIL:
    case GET_CAMPAIGN_TAGS_SUMMARIZED_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default voiceCampaigns;
