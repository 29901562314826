import React, { useEffect } from 'react';
import styled from 'styled-components';
//@ts-ignore
import MetaTags from 'react-meta-tags';
import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { getScenarios } from 'store/scenariosV2/reducer';
import { useTranslation } from 'react-i18next';
import ScenariosTemplate from 'components/scenarios/templates/scenariosTemplate';

const ScenariosPage = () => {
  const { t } = useTranslation('scenarios');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getScenarios());
  }, []);
  return (
    <PageCont className="page-content">
      <MetaTags>
        <title>{t('metaTag')} | SmartBot</title>
      </MetaTags>
      <div>
        <PageTitle>{t('pageTitle')}</PageTitle>
      </div>
      <ScenariosTemplate />
    </PageCont>
  );
};
const PageCont = styled.div`
  background-color: #eff5f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;


export default ScenariosPage;
