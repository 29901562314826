import { IAgentServiceLevel } from 'library/interfaces/crdInterfaces';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SpeedGraph from '../atoms/speedGraph';
import SubTitle from '../atoms/subTitle';

interface IAgentServiceLevelProps {
  data: IAgentServiceLevel;
}
const AgentServiceLevel: FC<IAgentServiceLevelProps> = ({ data }) => {
  const { t } = useTranslation('summarize');
  const PERCENT =
    data.serviceLevel !== -1
      ? parseInt((data.serviceLevel * 100).toFixed(0))
      : 0;

  return (
    <Cont>
      <SubTitle sub={t('userIndicatorsPopUp.today')}>
        {t('userIndicatorsPopUp.serviceLevel')} (%)
      </SubTitle>
      <SpeedGraph white porcent={PERCENT} />
      <FlexCont>
        <Indicator>
          <Number>{data.answered}</Number>
          <div>{t('userIndicatorsPopUp.answered')}</div>
        </Indicator>
        <Indicator>
          <Number>{data.unanswered}</Number>
          <div>{t('userIndicatorsPopUp.unanswered')}</div>
        </Indicator>
      </FlexCont>
      <ConexionTime>
        <Number>{data.availableDuration}</Number>
        <div>Tiempo de conexión</div>
      </ConexionTime>
    </Cont>
  );
};
const Cont = styled.div`
  width: 200px;
  padding: 20px;
  color: white;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 10;
  border-radius: 10px;
  background-color: #2a3042;
  overflow: visible;

  box-shadow: 0 0 15px black;
  h3 {
    color: white;
  }
`;
const FlexCont = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Indicator = styled.div`
  font-size: 0.7rem;
  text-align: center;
  flex-grow: 1;
  width: 50%;

  div {
    padding: 0px;
  }
`;
const Number = styled.div`
  font-size: 1.3rem;
  line-height: 20px;
`;
const ConexionTime = styled(Indicator)`
  width: 100%;
  margin-top: 10px;

  & > :first-child {
    letter-spacing: 2px;
  }
`;
export default AgentServiceLevel;
