import { IAgentsToQueue } from 'library/interfaces/addAgentToQueueInterfaces';
import { IQueue, IUser } from 'library/interfaces/crdInterfaces';
import { IError } from 'store/reports/reducer';
import { IAgentsInQueue } from './reducer';

export const GET_CDR_USERS_AND_QUEUES = 'GET_CDR_USERS_AND_QUEUES';
export const GET_CDR_USERS_AND_QUEUES_SUCCESS = 'GET_CDR_USERS_AND_QUEUES_SUCCESS';
export const GET_CDR_USERS_AND_QUEUES_FAIL = 'GET_CDR_USERS_AND_QUEUES_FAIL';

export const GET_PBX_AGENTS_IN_QUEUE = 'GET_PBX_AGENTS_IN_QUEUE';
export const GET_PBX_AGENTS_IN_QUEUE_SUCCESS = 'GET_PBX_AGENTS_IN_QUEUE_SUCCESS';
export const GET_PBX_AGENTS_IN_QUEUE_FAIL = 'GET_PBX_AGENTS_IN_QUEUE_FAIL';

export const UPDATE_AGENTS_IN_QUEUE = 'UPDATE_AGENTS_IN_QUEUE';
export const UPDATE_AGENTS_IN_QUEUE_SUCCESS = 'UPDATE_AGENTS_IN_QUEUE_SUCCESS';
export const UPDATE_AGENTS_IN_QUEUE_FAIL = 'UPDATE_AGENTS_IN_QUEUE_FAIL';

export type ConfigutationTypes =
  | IGetCDRDataUsersAndQueues
  | IGetCDRDataUsersAndQueuesSuccess
  | IGetCDRDataUsersAndQueuesFail
  | IGetPBXAgentsInQueue
  | IGetPBXAgentsInQueueSuccess
  | IGetPBXAgentsInQueueFail
  | IUpdateAgentsInQueue
  | IUpdateAgentsInQueueSuccess
  | IUpdateAgentsInQueueFail;

export interface IGetCDRDataUsersAndQueues {
  type: typeof GET_CDR_USERS_AND_QUEUES;
}
export interface IGetCDRDataUsersAndQueuesSuccess {
  type: typeof GET_CDR_USERS_AND_QUEUES_SUCCESS;
  payload: { users: IUser[]; queues: IQueue[] };
}
export interface IGetCDRDataUsersAndQueuesFail {
  type: typeof GET_CDR_USERS_AND_QUEUES_FAIL;
  payload: { error: IError };
}

export interface IGetPBXAgentsInQueue {
  type: typeof GET_PBX_AGENTS_IN_QUEUE,
  payload: {queueId: string}
}
export interface IGetPBXAgentsInQueueSuccess {
  type: typeof GET_PBX_AGENTS_IN_QUEUE_SUCCESS,
  payload: {agentsInQueue: IAgentsInQueue}
}
export interface IGetPBXAgentsInQueueFail {
  type: typeof GET_PBX_AGENTS_IN_QUEUE_FAIL,
  payload: { error: IError }
}



export interface IUpdateAgentsInQueue {
  type: typeof UPDATE_AGENTS_IN_QUEUE;
  payload: { queueId: string; agents: IAgentsToQueue };
}

export interface IUpdateAgentsInQueueSuccess {
  type: typeof UPDATE_AGENTS_IN_QUEUE_SUCCESS;
}

export interface IUpdateAgentsInQueueFail {
  type: typeof UPDATE_AGENTS_IN_QUEUE_FAIL;
  payload: { error: IError };
}
