import * as React from 'react';

export const ScenePublished = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M85.477 29.542c18.27 7.45 31.15 25.386 31.15 46.339M16.543 75.886c0-20.702 12.58-38.466 30.511-46.072M92.375 118.726c-7.533 4.545-16.362 7.16-25.804 7.16a49.808 49.808 0 0 1-26.104-7.344"
      fill="none"
      stroke="#7c9904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={11.1272}
    />
    <g
      fill="none"
      stroke="#7c9904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
    >
      <path
        d="M85.588 44.396H47.61V6.42h37.977zM126.758 117.502H88.781V79.525h37.977zM43.583 117.502H5.605V79.525h37.978z"
        strokeWidth={11.1272}
      />
    </g>
  </svg>
);
