import { darkBorder, primary } from 'library/colors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SearchInput from 'components/outbound/atoms/searchInput';


//
// ******* component with i18n translation *******
//
interface ISearchAndButtonProps {
  onCreateWaCampaignButtonClick: Function;
  onShowGalleryButtonClick: (show: boolean) => void
  buttonKeys?: {
    nameSpace: string;
    key: string;
  }
  inputKeys?: {
    nameSpace: string;
    key: string;
  }
  onInputChange?: (value: string) => void;

}
const SearchAndButtonsWa: FC<ISearchAndButtonProps> = ({
  onCreateWaCampaignButtonClick: onButtonClick,
  onShowGalleryButtonClick,
  onInputChange,
  buttonKeys = { nameSpace: 'outbound', key: 'campaigns.createACampaign' },
  inputKeys = { nameSpace: 'outbound', key: 'campaigns.searchInput' }
}) => {
  // i18n translation
  const { t } = useTranslation(buttonKeys.nameSpace);
  const text = t(buttonKeys.key);

  const handleInputChange = (value: string) => {
    onInputChange && onInputChange(value)
  }

  return (
    <Cont>
      <SearchInput
        onInputChange={handleInputChange}
        i18nKeys={{ namespace: inputKeys.nameSpace, key: inputKeys.key }}
      />
      <div>
        <GrayButton onClick={() => onShowGalleryButtonClick(true)}>Galeria de Archivos</GrayButton>
        <Button onClick={() => onButtonClick()}>+ {text}</Button>
      </div>
    </Cont>
  );
};
const Cont = styled.div`
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
`;
const Button = styled.button`
    border: none;
    border-radius: 50px;
    color: white;
    padding: 5px 20px;
    background-color: ${primary};
`;
const GrayButton = styled(Button)`
margin: 0 10px;
    background-color: ${darkBorder};
`;
export default SearchAndButtonsWa;