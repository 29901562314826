import DownloadReportButton from 'components/outbound/atoms/downloadReportButton';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import StateMenuOption from 'components/outbound/atoms/stateMenuOption';
import LoadingBarChart from 'components/outbound/smsCampaigns/atoms/loadingBarChart';
import {
  blueGraph,
  danger,
  darkBorder,
  lightBackgroundBlue,
  primary,
} from 'library/colors';
import {
  IWaCampaign,
  IWaCampaignActions,
} from 'library/interfaces/whatsappCampaignInterfaces';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteWaCampaignAction,
  getWACampaignByIdAction,
  putChangeWACampaignStatusAction,
  putWaCampaignColorAction,
  WAContactGroupsAction,
} from 'store/actions';
import { campaignAction } from 'store/outboundCampaigns/voiceCampaigns/actionTypes';
import styled from 'styled-components';

interface IStatusAndButtonsProps {
  waCampaign: IWaCampaign;
}
const StatusAndButtons: FC<IStatusAndButtonsProps> = ({ waCampaign }) => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const isUpdating = waCampaign.isLoading;
  const isUploadingContacts = waCampaign.contactLoad
    ? waCampaign.contactLoad.isLoading
    : false;
  const percent = waCampaign.contactLoad?.loadPercentage || 0;

  const contactsError =
    (!waCampaign.contactLoad?.isLoading &&
      waCampaign.contactLoad?.postContactsGroupNumberOfError) ||
    [];

  const STATUS_COLOR =
    waCampaign.status === 'running'
      ? primary
      : waCampaign.status === 'stopped'
        ? 'orange'
        : waCampaign.status === 'completed'
          ? blueGraph
          : lightBackgroundBlue;

  const handleUpdateCampaign = () => {
    dispatch(getWACampaignByIdAction(waCampaign.id));
    setShowMenu(false);
  };

  const handleChangeCampaignStatus = (
    action: IWaCampaignActions | campaignAction,
  ) => {
    dispatch(
      putChangeWACampaignStatusAction(
        action as IWaCampaignActions,
        waCampaign.id,
      ),
    );
    setShowMenu(false);
  };

  const handleDeleteWaCampaign = () => {
    dispatch(deleteWaCampaignAction(waCampaign.id))
    setShowMenu(false);
  }

  const handlePutWaCampaignColor = (color: string) => {
    dispatch(putWaCampaignColorAction(waCampaign.id, color))
    setShowMenu(false);
  }
  const handlePostContactsError = () => {
    dispatch(WAContactGroupsAction(waCampaign.id, contactsError));
  };

  return (
    <Cont>
      {isUploadingContacts && <LoadingBarChart percent={percent} />}
      {!isUploadingContacts && contactsError.length === 0 && !isUpdating && (
        <>
          <Status bgColor={STATUS_COLOR}>{waCampaign.status}</Status>
          <DownloadReportButton campaignId={waCampaign.id+'&force=true'} reportType={10}>
            Report
          </DownloadReportButton>
          <MenuButton
            id="showActionsMenu"
            onClick={() => setShowMenu(prev => !prev)}
          >
            ...
          </MenuButton>
          {showMenu && (
            <Menu>
              {waCampaign.status === 'created' && (
                <StateMenuOption
                  label={'Iniciar'}
                  action="run"
                  onActionClick={handleChangeCampaignStatus}
                />
              )}
              {waCampaign.status === 'running' && (
                <StateMenuOption
                  label={'Terminar'}
                  action="stop"
                  onActionClick={handleChangeCampaignStatus}
                />
              )}
              <StateMenuOption
                label="Actualizar"
                action="terminate"
                onActionClick={handleUpdateCampaign}
              />
              <Button>
                <span>Color: </span>
                <input type="color" value={waCampaign.color} onChange={e => handlePutWaCampaignColor(e.target.value)} />
              </Button>
              <hr />
              <StateMenuOption
                label="Eliminar"
                action="terminate"
                onActionClick={handleDeleteWaCampaign}
              />
            </Menu>
          )}
        </>
      )}
      {contactsError.length > 0 && (
        <ErrorButtonCont>
          <div>Ha ocurrido un error cargando los contactos</div>
          <ErrorButton onClick={() => handlePostContactsError()}>
            Reintentar
          </ErrorButton>
        </ErrorButtonCont>
      )}
      {isUpdating && (
        <Updating>
          <div>
            Actualizando <LoadingPoints />
          </div>
        </Updating>
      )}
    </Cont>
  );
};
const Cont = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
  position: relative;
  /* border: 1px solid; */
`;
const Status = styled.div<IStatus>`
  width: 100px;
  height: 30px;
  margin: 0 10px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  cursor: default;
  user-select: none;
  color: ${props => props.bgColor !== lightBackgroundBlue && 'white'};
  background-color: ${props => props.bgColor};
  position: relative;
`;
const Updating = styled.div`
  text-align: center;
  width: 100%;
  height: 90%;
  display: grid;
  place-items: center;
`;
const MenuButton = styled.div`
  width: 40px;
  height: 30px;
  margin-left: 5px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 35%;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;
const Menu = styled.div`
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 35px;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  border-radius: 5px;
  background-color: ${lightBackgroundBlue};
  border: 1px solid ${darkBorder};
  box-shadow: 0 0px 5px ${primary};

  & > hr{
    margin: 0;
    margin-top: 5px;
    margin-bottom: 3px;
  }
`;
const Button = styled.button`
  text-align: left;
  padding: 2px 15px;
  border-color: transparent;
  background-color: transparent;
  /* border: 1px solid; */

  &:hover{
    color: ${primary};
    transform: scale(1.01);
  }

  &>input{
    position: absolute;
    width: 30px;
    height: 20px;
    margin-left: 5px;
    border: none;
    /* transform: translateY(5px); */
  }
`;
const ErrorButtonCont = styled.div`
  /* border: 1px solid; */
  margin: 0 auto;
  width: 100%;
  display: grid;
  place-content: center;
`;
const ErrorButton = styled.button`
  width: 120px;
  height: 30px;
  font-weight: 600;
  color: ${danger};
  border: 2px solid;
  border-radius: 50px;
  margin: 5px auto 20px;
  background-color: transparent;
`;
interface IStatus {
  bgColor: string;
}
export default StatusAndButtons;
