import {
  DELETE_AUTH_USER_DATA,
  IDeleteAuthUserData,
  IUpdateAuthUserData,
  IUpdateAuthUserExten,
  UPDATE_AUTH_USER_DATA,
  UPDATE_AUTH_USER_EXTEN,
} from './actionTypes';
import { IAuthUserData } from './reducer';

export const updateAuthUserDataAction = (
  userData: IAuthUserData,
): IUpdateAuthUserData => ({
  type: UPDATE_AUTH_USER_DATA,
  payload: { userData },
});

export const deleteAuthUserData = (): IDeleteAuthUserData => ({
  type: DELETE_AUTH_USER_DATA,
});

export const updateAuthUserExtenAction = (
  exten: string,
  exten_name: string,
  exten_password: string,
): IUpdateAuthUserExten => ({
  type: UPDATE_AUTH_USER_EXTEN,
  payload: { exten, exten_name, exten_password },
});
