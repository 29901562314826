import { primary } from 'library/colors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SearchInput from '../atoms/searchInput';

//
// ******* component with i18n translation *******
//
interface ISearchAndButtonProps {
  onButtonClick: Function;
  buttonKeys?: {
    nameSpace: string;
    key: string;
  }
  inputKeys?: {
    nameSpace: string;
    key: string;
  }
  onInputChange?: (value: string) => void;
}
const SearchAndButton: FC<ISearchAndButtonProps> = ({
  onButtonClick,
  onInputChange,
  buttonKeys = { nameSpace: 'outbound', key: 'campaigns.createACampaign' },
  inputKeys = { nameSpace: 'outbound', key: 'campaigns.searchInput' }
}) => {
  // i18n translation
  const { t } = useTranslation(buttonKeys.nameSpace);
  const text = t(buttonKeys.key);

  const handleInputChange = (value: string) => {
    onInputChange && onInputChange(value)
  }

  return (
    <Cont>
      <SearchInput
        onInputChange={handleInputChange}
        i18nKeys={{ namespace: inputKeys.nameSpace, key: inputKeys.key }}
      />
      <Button onClick={() => onButtonClick()}>+ {text}</Button>
    </Cont>
  );
};
const Cont = styled.div`
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
`;
const Button = styled.button`
    border: none;
    border-radius: 50px;
    color: white;
    padding: 5px 20px;
    background-color: ${primary};
`;
export default SearchAndButton;
