import LoadingImg from 'components/Configuration/atoms/loading';
import ChatMessage from 'components/WorkSpace/atoms/chatMessage';
import ListMessage from 'components/WorkSpace/atoms/messages/list';
import { darkBorder, popUpBackground, primary } from 'library/colors';
import { IConversationChannelType } from 'library/enableSpecificFeatures/conversationWorkspace';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { isJSON } from 'library/services/functions';
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { clearEmailSessionstore } from 'store/actions';
import styled from 'styled-components';

interface IChatPopupProps {
  setShowChat: (value: boolean) => void
}
const ChatPopup: FC<IChatPopupProps> = ({ setShowChat }) => {

  const { isLoading, messages } = useAppSelector(store => store.liveConversations.sessionMessages)

  const contRef = useRef<HTMLDivElement>(null);

  const handleExitClick = () => {
    setShowChat(false)
  }

  useLayoutEffect(() => {
    if (!contRef.current) return;
    contRef.current.scrollIntoView();
  }, [messages]);
  return (
    <PopUpContainer>
      <Cont >
        <Exit id="exitButton" onClick={() => handleExitClick()}>X</Exit>
        <Title>Mensajes</Title>
        <LoadingCont>
          {isLoading && <LoadingImg />}
          <ChatCont>
            <ChatMessagesCont>
              {messages.length > 0 &&
                messages.map((message, index) => {
                  if (!message.content) return

                  const isAgent =
                    message.source_type === 'agent' || message.source_type === 'system';

                  let imgUrl = ''
                  let stickerUrl = ''
                  let audioUrl = ''
                  let fileUrl = ''
                  let text: React.ReactNode = ''

                  try {

                    if (message.content_type === 'image') {
                      const json = JSON.parse(message.content.toString())
                      imgUrl = json.url
                    } else if (message.content_type === 'sticker') {
                      stickerUrl = JSON.parse(message.content.toString()).url
                    } else if (message.content_type === 'audio') {
                      audioUrl = JSON.parse(message.content.toString()).url
                    } else if (isJSON(message.content.toString())) {
                      const json = JSON.parse(message.content.toString())
                      if (json.type === 'image') imgUrl = json.originalUrl
                      if (json.type === 'sticker') stickerUrl = json.url || json.originalUrl
                      if (json.type === 'file') fileUrl = json.url
                      if (json.type === 'audio') audioUrl = json.url

                      if (json.Text && Array.isArray(json.Text)) {

                        json.Text.forEach((item: string) => {
                          if (isJSON(item)) {
                            const obj = JSON.parse(item)
                            if (obj.type && obj.type === 'list') {
                              text = <ListMessage list={obj} />
                            }
                          } else {
                            text = text + item
                          }
                        });
                      }
                      //case message.content_type === text
                    } else {
                      text = message.content.toString()
                    }
                  } catch (e) {
                    null
                  }

                  const source: IConversationChannelType | 'call' = message.channel_id === 'facebook' ? 'facebook' : message.channel_id === 'instagram' ? 'instagram' : 'whatsapp'

                  const msg = {
                    source,
                    sender:
                      message.source_name?.toString() ||
                      message.source_type?.toString() ||
                      '',
                    message: text,
                    imgUrl,
                    stickerUrl,
                    audioUrl,
                    fileUrl,
                    timestamp: message.created_at?.toString() || '',
                  };
                  if (isAgent) {
                    return (
                      <ChatMessage
                        chatWidth={100}
                        message={msg}
                        key={index}
                      />
                    );
                  }
                  return (
                    <ChatMessage
                      chatWidth={100}
                      right
                      message={msg}
                      key={index}
                    />
                  );
                })}
              <div ref={contRef}></div>
            </ChatMessagesCont>

          </ChatCont>
        </LoadingCont>
        <Button onClick={() => handleExitClick()}>Salir</Button>
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1000;
  background-color: ${popUpBackground};
`;
const Cont = styled.div`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 800px;
  height: 550px;
  color: black;
  padding: 20px 40px 30px;
  display: grid;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;
`;
const LoadingCont = styled.div`
  position: relative;
`;
const ChatMessagesCont = styled.div`
  flex-grow: 1;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const ChatCont = styled.div`
height: 400px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${darkBorder};
  position: relative;
  
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const Button = styled.button`
  border-radius: 50px;
  margin: 0;
  display: inline-block;
  min-width: 30px;
  font-size: 13px;
  padding: 8px 16px;
  color: white;
  justify-self: end;
  border: 2px solid ${darkBorder};
  background-color: ${darkBorder};
  transform: translateY(10px);
`;
export default ChatPopup;