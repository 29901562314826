import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { lightBackground, primary, secondary } from 'library/colors';

import { CallToQueue } from 'icons';

interface ILargeButton {
  title: string;
  description: string;
  border?: React.ReactNode;
  onClick?: Function;
  showMenu?: boolean;
}

const LargeButton: FC<ILargeButton> = ({
  title,
  description,
  border,
  onClick,
  showMenu,
}) => {
  return (
    <Button
      border={border}
      showMenu={showMenu}
      onClick={() => onClick && onClick(!showMenu)}
    >
      <Icon>
        <CallToQueue width={'100%'} />
      </Icon>
      <TextContainer>
        <Text>{title}</Text>
        <Span>{description}</Span>
      </TextContainer>
    </Button>
  );
};
const Button = styled.button<IButton>`
  width: 100%;
  height: 60px;
  padding: 0 16px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: ${props => (props.border ? `2px solid ${secondary}` : 'none')};
  background-color: ${lightBackground};

  &:after {
    content: '';
    top: 50%;
    right: 20px;
    cursor: pointer;
    position: absolute;
    border-top: 7px solid black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    display: ${props => !props.border && 'none'};
    transform: rotateX(${props => (props.showMenu ? '180deg' : '0')});
    transition: transform 0.2s;
  }
`;
const Icon = styled.div`
  width: 27px;
  color: ${primary};
`;
const TextContainer = styled.div`
  padding-left: 12px;
  padding-top: 7px;
`;
const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  line-height: 13px;
`;
const Span = styled.span`
font-size: 11px;
font-weight: 100;
`;
interface IButton {
  showMenu?: boolean;
  border?: React.ReactNode;
}
export default LargeButton;
