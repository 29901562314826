import * as React from 'react';

export const EditPencil = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M110.887 60.296v56.848a10.262 10.262 0 0 1-10.265 10.265H13.37c-5.665 0-10.265-4.593-10.265-10.265V29.892c0-5.671 4.6-10.265 10.265-10.265h60.486"
      fill="none"
      stroke="#7c9904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.4156}
    />
    <path
      d="M107.037 5.121c9.528 2.355 19.164 11.991 21.518 21.518L64.002 91.193H42.484V69.675zM117.944 37.2 96.426 15.68"
      fill="none"
      stroke="#7c9904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.4156}
    />
  </svg>
);
