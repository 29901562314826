import React, { FC } from 'react';
import styled from 'styled-components';

import Container from '../atoms/container';
import AtentionTime from '../molecules/atentionTime';
import CallsAnswered from '../molecules/callAnswered';

interface ICallsAnsweredAndAtentionTime {
  answered_calls: number;
  gridColumn?: string;
  service_average: string;
  service_min_time: string;
  service_max_time: string;
  service_change: string;
}
const CallsAnsweredAndAtentionTime: FC<ICallsAnsweredAndAtentionTime> = ({
  gridColumn,
  answered_calls,
  service_average,
  service_min_time,
  service_max_time,
  service_change,
}) => {
  return (
    <Container gridColumn={gridColumn}>
      <GridCont>
        <CallsAnswered answered={answered_calls} />
        <AtentionTime
          service_average={service_average}
          service_min_time={service_min_time}
          service_max_time={service_max_time}
          service_change={service_change}
        />
      </GridCont>
    </Container>
  );
};
const GridCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
export default CallsAnsweredAndAtentionTime;
