import React, { FC } from 'react';
import styled from 'styled-components';
import { CustomNodeTypes, INodeData } from '../utils';
import { Node } from 'reactflow';

interface IModalTitleProps {
  Icon: (props: any) => JSX.Element
  selectedNode: Node<INodeData, CustomNodeTypes>;
  handleTitleChange: (event: React.ChangeEvent<HTMLInputElement>, node: Node<INodeData, CustomNodeTypes>) => void
  handleBackgroundColorChange: (event: React.ChangeEvent<HTMLInputElement>, node: Node<INodeData, CustomNodeTypes>) => void
}
const ModalTitle: FC<IModalTitleProps> = ({ Icon, selectedNode, handleTitleChange, handleBackgroundColorChange }) => {

  return (
    <ContTitle backgroundColor={selectedNode.data.backgroundColor}>
      <Icon />
      <TitleInputCont>
        <TitleInput
          type="text"
          id='nodeTitle'
          value={selectedNode.data.title}
          onChange={e => handleTitleChange(e, selectedNode)}
        />
        <IconsCont htmlFor='nodeTitle' >
          <i className='bx bxs-pencil' ></i>
        </IconsCont>
      </TitleInputCont>
      <InputColor
        type="color"
        value={selectedNode.data.backgroundColor}
        onChange={e => handleBackgroundColorChange(e, selectedNode)}
      />

    </ContTitle>
  );
};
const ContTitle = styled.div<IBackground>`
  color: #fff;
  font-size: 1rem;
  background-color: ${props => props.backgroundColor};
  display: flex;
  align-items: center;
  padding: 5px 30px 5px 20px;
  
  & > svg {
    width: 22px;
    height: 22px;
  }
  
  @media (min-width: 1920px) {
    padding: 10px 25px;
    & > svg {
    width: 35px;
    height: 35px;
  }
  }
`;
const TitleInputCont = styled.div`
  margin-right: 10px;
  flex-grow: 1;
  position: relative;
`;
const TitleInput = styled.input`
  color: #fff;
  width: 100%;
  border: none;
  padding: 0;
  padding: 0 25px 0 10px;
  background-color: transparent;

  @media (min-width: 1920px) {
    font-size: 1.3rem;
    margin-top: 2px;
  padding: 0 30px 0 15px;
  }

  &:focus{
    color: #000;
    background-color: white;
  }
`;
const IconsCont = styled.label`
  color: #fff;
  position: absolute;
  top: 1px;
  right: 0;
  font-size: 1rem;
  cursor: pointer;

  @media (min-width: 1920px) {
    font-size: 1.3rem;
    top: 4px;
  }

`;
const InputColor = styled.input`
  cursor: pointer;
`;
interface IBackground {
  backgroundColor: string;
}
export default ModalTitle;