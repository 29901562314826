import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteFile,
  getAllFiles,
  getFileById,
  uploadfile,
} from 'helpers/fakebackend_helper';
import { ISysFile } from './reducer';

export const getAllFilesAction = createAsyncThunk<{
  rows: ISysFile[];
  lastKey: string;
}>('files/getAll', async () => {
  return await getAllFiles();
});

export const deleteFileAction = createAsyncThunk<{ fileId: string }, string>(
  'files/deleteFile',
  async (fileId: string) => {
    const res = await deleteFile(fileId);
    if (res !== 'OK') throw new Error('No se ha eliminado el archivo');
    return { fileId };
  },
);

export const uploadFileAction = createAsyncThunk<ISysFile, FormData>(
  'files/uploadFile',
  async (formData: FormData) => {
    const res: { [key: string]: string } = await uploadfile(formData);
    if (!res?.id) throw new Error('No se ha guardado el archivo');
    return await getFileById(`${res.id}`);
  },
);
