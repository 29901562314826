import {
  IQueueCallEventToApp,
  IQueuedCall,
} from 'library/interfaces/liveQueuedCallsInterfaces';
import { sysNotification } from 'library/notifications/notifications';
import { FC, useEffect } from 'react';

interface IQueuedCallsNotificationsProps {
  call: IQueuedCall<IQueueCallEventToApp>;
}
const QueuedCallsNotifications: FC<IQueuedCallsNotificationsProps> = ({
  call,
}) => {
  const events = call.events;
  const dateEnd = new Date(events.slice(-1)[0]?.endTime).getTime();

  useEffect(() => {
    if (
      events[events.length - 1] &&
      events[events.length - 1].state !== 'noanswer'
    )
      return;
    sysNotification(
      events[events.length - 1].name,
      `No atendió la llamada de ${call.srcCallerid}`,
    );
  }, [dateEnd]);
  return null;
};

export default QueuedCallsNotifications;
