import React from 'react';
import PropTypes from 'prop-types';

Index.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

function Index({ text, type = 'button', variant = 'default', onClick }) {
  return (
    <button
      type={type}
      className={`btn btn-${variant} btn-sm`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default Index;
