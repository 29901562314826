import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { darkBorder, primary, } from 'library/colors';
import { LiveBoard, Incoming, Reports } from 'icons';
import { useHistory } from 'react-router-dom';

const RightBar = () => {
  const history = useHistory();

  const [buttons, setButtons] = useState([
    {
      path: '/live-board',
      label: 'Live Board',
      isSelected: false,
      icon: LiveBoard,
    },
    {
      path: '/live-board/queued-calls',
      label: 'Queued Calls',
      isSelected: false,
      icon: Incoming,
    },
    {
      path: '/live-board/agent-calls',
      label: 'Agent Calls',
      isSelected: false,
      icon: Reports,
    },
  ]);

  const handleButtonClick = (pathToRedirect: string) => {
    history.push(pathToRedirect);
  };

  useEffect(() => {
    const path = history.location.pathname;
    const newButtons = buttons.map(button => {
      if (path === button.path) {
        return { ...button, isSelected: true };
      }
      return { ...button, isSelected: false };
    });
    setButtons([...newButtons]);
  }, []);

  return (
    <Cont>
      <Bar>
        {buttons.map((button, index) => {
          return (
            <IconCont
              key={index}
              label={button.label}
              isSelected={button.isSelected}
              onClick={() => handleButtonClick(button.path)}
            >
              <button.icon
                width={24}
                height={24}
                color={!button.isSelected ? 'white' : ''}
              />
            </IconCont>
          );
        })}
      </Bar>
    </Cont>
  );
};
const Cont = styled.div`
  flex-grow: 1;
  min-width: 50px;
  margin-left: 10px;
`;
const Bar = styled.div`
  width: 50px;
  min-height: 75vh;
  border-radius: 5px;
  position: fixed;
  z-index: 1000;
  background-color: #2a3042;
`;
const IconCont = styled.div<IIconCont>`
  display: grid;
  place-items: center;
  padding: 10px 0px;
  position: relative;
  cursor: pointer;
  color: ${props => (props.isSelected ? '#92d900' : 'white')};

  &:hover:before {
    opacity: 1;
    transform: translateY(0px);
  }

  &:before {
    content: '${props => props.label}';
    position: absolute;
    white-space: nowrap;
    right: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid;
    color: white;
    background-color: #2a3042;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    pointer-events: none;
    transform: translateY(5px);
  }
`;
interface IIconCont {
  label: string;
  isSelected: boolean;
}
export default RightBar;
