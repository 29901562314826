import React, { FC } from 'react';
import { IError } from 'store/reports/reducer';
import styled from 'styled-components';
import CircularGraph from '../atoms/circularGraph';
import VerticalIndicatorsLinked from '../molecules/verticalIndicators';

interface IVerticalIndicatorsWidthCircularGraphProps {
  indicators: {
    label: string;
    value?: number;
    labelBackColor?: string;
  }[];
  graphData: {
    value: number;
    color: string;
    label?: string;
  }[];
  isLoading?: boolean;
  error?: IError | null;
  handleRefresh?: Function;
  
}
const VerticalIndicatorsWidthCircularGraph: FC<
  IVerticalIndicatorsWidthCircularGraphProps
> = ({ indicators, graphData, isLoading, error, handleRefresh }) => {
  return (
    <Cont>
      <VerticalIndicatorsLinked indicators={indicators} />
      <GraphCont>
        <CircularGraph graphData={graphData} isLoading={isLoading} error={error} handleRefresh={handleRefresh}/>
      </GraphCont>
    </Cont>
  );
};
const Cont = styled.div`
display: flex;
justify-content: center;
grid-column: 1 / 3;
`;
const GraphCont = styled.div`
  padding: 50px;
  flex-grow: 1;
  max-width: 50vh;
  border-radius: 10px;
  background-color: white;
  display: grid;
  align-items: center;
`;
export default VerticalIndicatorsWidthCircularGraph;
