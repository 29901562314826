import React, { memo } from 'react';

import NodeLayout from '../common/NodeLayout';
import RightHandle from '../common/RightHandle';
import LeftHandle from '../common/LeftHandle';
import Modal from './Modal';
import PropTypes from 'prop-types';

const color = 'blue';

// eslint-disable-next-line react/display-name
export const AnswerRecognition = memo(({ data, isConnectable }) => {
  return (
    <>
      <NodeLayout
        icon="AnswerRecognition"
        data={data}
        color={color}
        Modal={Modal}
      >
        <LeftHandle
          isConnectable={isConnectable}
          onConnect={() => {}}
          id="route_in"
        />

        <RightHandle
          id="route_no_input"
          isConnectable={isConnectable}
          type="source"
          text="NO INPUT"
          bg="red"
        />
        <RightHandle
          id="route_voice"
          isConnectable={isConnectable}
          type="source"
          text="Voice"
          bg="blue"
        />
        <RightHandle
          id="route_keyboard"
          isConnectable={isConnectable}
          type="source"
          text="Keyboard"
          bg="blue"
        />
      </NodeLayout>
    </>
  );
});

AnswerRecognition.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
