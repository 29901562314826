import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { post } from 'helpers/api_helper';
import { primary } from 'library/colors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Transcription from './transcription';

const BodyTable = ({ recording, showTranscription }) => {
  const {
    transcription,
    uuid,
    source,
    destination,
    audioUrl,
    agent,
    direction,
    startDate,
    endDate,
    duration,
    state,
    timestamp,
    disposition,
  } = recording;
  const { t } = useTranslation('recordings');

  const { queues } = useSelector(state => state.cdr.reportData);

  const [presignedUri, setPresignedUri] = useState({
    isLoading: false,
    error: null,
    uri: null,
  });

  const callState = disposition ?? state;

  const uuidOutput = () => {
    const res = uuid.slice(uuid.lastIndexOf('-') + 1);
    return res;
  };

  const destinationOutput = () => {
    const isAQueue = queues.filter(item => item.id === destination);
    if (isAQueue.length > 0) return isAQueue[0].name;
    else return destination;
  };

  const dateFormat = date => {
    return date.replace(/([T.])/g, ' ').substr(0, 20);
  };

  const handleVerDetalleClick = () => {
    showTranscription(transcription);
  };

  const getPresignedUri = async () => {
    if (!audioUrl) return;
    setPresignedUri(prev => ({
      ...prev,
      isLoading: true,
      error: null,
    }));
    const audioPath = audioUrl.startsWith('/') ? audioUrl.substr(1) : audioUrl;

    try {
      const uri = await post('/bucket/audio/signedurl', {
        key: audioPath,
      });
      setPresignedUri(prev => ({
        ...prev,
        isLoading: false,
        error: null,
        uri: uri,
      }));
    } catch (error) {
      setPresignedUri(prev => ({
        ...prev,
        isLoading: false,
        error: 'Algo ha ido mal',
        uri: null,
      }));
    }
  };

  useEffect(() => {
    if (presignedUri.uri === null) return;
    setPresignedUri(prev => ({
      ...prev,
      isLoading: false,
      error: null,
      uri: null,
    }));
  }, [audioUrl]);
  return (
    <tbody>
      <tr>
        <td className="text-primary pe-auto">
          <span role={'button'} onClick={() => handleVerDetalleClick()}>
            {t('details')}
          </span>
        </td>
        <td>{uuidOutput()}</td>
        <td>{source}</td>
        <td>{destinationOutput()}</td>
        <td>
          {audioUrl &&
            !presignedUri.isLoading &&
            !presignedUri.uri &&
            !presignedUri.error && (
              <PlayButton onClick={getPresignedUri}>
                <span>&#9654;</span>
              </PlayButton>
            )}

          {presignedUri.isLoading && (
            <span>
              Cargando audio &nbsp;
              <LoadingPoints />
            </span>
          )}
          {presignedUri.error && (
            <span>
              {presignedUri.error}{' '}
              <Refresh onClick={getPresignedUri}>&nbsp; Refrescar</Refresh>
            </span>
          )}

          {presignedUri.uri && (
            <audio
              controls
              className="audio"
              preload="auto"
              src={presignedUri.uri}
              type="audio/wav"
            />
          )}
        </td>
        <td>{agent}</td>
        <td className="d-flex justify-content-between align-items-center">
          <i
            style={{ transform: 'scale(1.5)' }}
            className={`bx me-2  
                ${
                  direction === 'inbound'
                    ? 'bx-down-arrow text-primary'
                    : direction === 'outbound'
                    ? 'bxs-up-arrow-circle text-danger'
                    : direction.startsWith('inbound-')
                    ? 'bx-down-arrow text-warning'
                    : direction.startsWith('outbound-')
                    ? 'bxs-up-arrow-circle text-warning'
                    : 'bxs-circle text-secondary'
                }
              `}
          ></i>
          <span style={{ flexGrow: 1 }}>{direction}</span>
        </td>
        <td>{dateFormat(startDate)}</td>
        <td>{dateFormat(endDate)}</td>
        <td>{duration}</td>
        <td
          className={`text-white 
              ${
                callState === 'ANSWERED'
                  ? 'bg-primary'
                  : callState === 'ANSWER'
                  ? 'bg-primary'
                  : callState === 'ABANDON'
                  ? 'bg-danger'
                  : callState === 'NO ANSWER'
                  ? 'bg-warning'
                  : callState === 'CANCEL'
                  ? 'bg-warning'
                  : callState === 'BUSY'
                  ? 'bg-secondary'
                  : callState === 'FAILED'
                  ? 'bg-info'
                  : 'bg-dark'
              }
            `}
        >
          {t(`callStates.${callState}`)}
        </td>
        <td>{dateFormat(timestamp)}</td>
      </tr>
    </tbody>
  );
};
const PlayButton = styled.button`
  border: none;
  width: 40px;
  height: 20px;
  padding-left: 10px;
  border-radius: 50px;

  & > span {
    display: block;
    line-height: 0;
    color: ${primary};
  }
`;
const Refresh = styled.span`
  color: ${primary};
  cursor: pointer;
`;
export default BodyTable;
