import { IQueue } from 'library/interfaces/crdInterfaces';
import {
  IAgentsStatus,
  ILiveBoardQueue,
  ILiveDataQueue,
  IModelLiveBoardData,
} from 'library/interfaces/liveboardInterface';

export const liveBoardAppSyncService = (
  queues: IQueue[],
  liveData: ILiveDataQueue[],
): IModelLiveBoardData[] => {
  const data = queues.map(queue => {
    const newQueue: ILiveBoardQueue = {
      ...queue,
      uid: '',
    };
    let occupation_range = 0;
    let agents_state = {
      total: 0,
      incall: 0,
      ringing: 0,
      onhold: 0,
      available: 0,
      unavailable: 0,
      connected: 0,
    };
    let calls = {
      queued: 0,
      answered: 0,
      abandoned: 0,
      online: 0,
      abandonedCount: 0,
      answeredCount: 0,
    };

    const liveQueue = liveData.find(item => item.id === queue.id);

    if (liveQueue) {
      newQueue.uid = liveQueue.uid;
      const counter = agentsStatusCounter(liveQueue.agentsStatus);
      const total = liveQueue.agentsStatus.length;
      const connected =
        liveQueue.agentsStatus.length - counter.unavailable - counter.onhold;
      const busy = counter.incall + counter.ringing;

      occupation_range = occupationRange(busy, connected);

      agents_state = {
        ...agents_state,
        ...counter,
        total,
        connected,
      };
      calls = {
        ...liveQueue.callsStatus,
      };
    }

    return {
      queue: newQueue,
      agents_state,
      occupation_range,
      calls,
    };
  });
  return data;
};

export const agentsStatusCounter = (agentsStatus: IAgentsStatus[]) => {
  const counter = agentsStatus.reduce(
    (acc, cur) => {
      if (cur.state in acc) {
        acc[cur.state]++;
      }
      return acc;
    },
    {
      incall: 0,
      ringing: 0,
      onhold: 0,
      available: 0,
      unavailable: 0,
    },
  );
  return counter;
};

export const occupationRange = (
  agentsInCall: number,
  agentsAvailable: number,
) => {
  const res = Math.round((agentsInCall / agentsAvailable) * 100);
  return isNaN(res) ? 0 : res;
};
