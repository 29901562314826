import { darkBorder, lightBackground, lightBackgroundBlue, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import LiveConversationSession from '../molecules/liveConversationSession';
import { useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';
import { IUser } from 'library/interfaces/crdInterfaces';
import ChatPopup from './chatPopup';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import {
  deleteLiveConversationSessionAction,
  getAgentsConnectedToWaAction,
  getLiveConversationSessionMessagesAction,
  getLiveConversationsSessionLogsAction,
  getReportData,
  getSessionFromMonitorAction,
  putLiveConversationTranferSessionAction,
  putRestoreWaSessionAction
} from 'store/actions';
import LogsPopup from './logsPopup';
import ReassignPopup from './reassignPopup';

interface IMainContProps { }
const MainCont: FC<IMainContProps> = ({ }) => {
  const dispatch = useAppDispatch()
  const { sessions } = useAppSelector(store => store.liveConversations.sessions)
  const campaigns = useAppSelector(store => store.liveConversations.campaign)
  const users = useSelector<IStore, IUser[]>(store => store.cdr.reportData.users)
  const waCampaigns = useAppSelector(store => store.waCampaigns.campaigns)
  const templates = useAppSelector(store => store.waCampaigns.templates.templates)

  const [selectedWaCampaign, setSelectedWaCampaign] = useState('')
  const [selectedAgent, setSelectedAgent] = useState('')
  const [buttonsState, setButtonsState] = useState(BUTTONS_STATE)

  const [showChat, setShowChat] = useState(false)
  const [showLogs, setShowLogs] = useState(false)
  const [showUsers, setShowUsers] = useState(false)
  const [currentSessionId, setCurrentSessionId] = useState('')

  const handleShowChat = (channel: string, source: string, sessionId: string) => {
    setShowChat(!showChat)
    dispatch(getLiveConversationSessionMessagesAction(channel, source, sessionId, templates))
  }

  const handleShowLogs = (sessionId: string) => {
    setShowLogs(!showLogs)
    dispatch(getLiveConversationsSessionLogsAction(sessionId))
  }

  const handleShowReasignUsers = (agentId: string) => {
    if (!currentSessionId) return
    setShowUsers(!showUsers)
    dispatch(putLiveConversationTranferSessionAction(currentSessionId, agentId))
  }

  const handleUpdateButtonsState = (index: number) => {
    setButtonsState(prev =>
      prev.map((but, i) => {
        if (index === i) return { ...but, isSelected: true }
        return { ...but, isSelected: false }
      })
    )
  }

  const handleDeleteSession = (sessionId: string) => {
    dispatch(deleteLiveConversationSessionAction(sessionId))
  }

  const handleRestoreSession = (sessionId: string) => {
    dispatch(putRestoreWaSessionAction(sessionId))
  }

  useEffect(() => {
    let isActive: boolean | undefined = true
    const buttonSelected = buttonsState.find(but => but.isSelected === true)

    if (buttonSelected?.label === 'Activas') isActive = true
    else if (buttonSelected?.label === 'Cerradas') isActive = false
    else if (buttonSelected?.label === 'Todas') isActive = undefined


    if (selectedWaCampaign === '' && selectedAgent === '') return
    dispatch(getSessionFromMonitorAction(null, isActive, selectedWaCampaign, selectedAgent))
  }, [selectedWaCampaign, selectedAgent, buttonsState])


  useEffect(() => {
    if (users.length > 0) return
    dispatch(getReportData())
  }, [])


  return (
    <Cont>
      <CountersHeaderCont>
        {campaigns &&
          <CounterCont>
            <Flex>
              <div>
                <Counter>{campaigns.dispatched_delivered}</Counter>
                <Label>Entregados</Label>
              </div>
              <div>
                <SmallCounterCont>
                  <SmallCounter>{campaigns.dispatched_read}</SmallCounter>
                  <div>Leídos</div>
                </SmallCounterCont>
                <SmallCounterCont>
                  <SmallCounter>{campaigns.totalUnreadConversations}</SmallCounter>
                  <div>Sin respuesta</div>
                </SmallCounterCont>

              </div>
            </Flex>
          </CounterCont>}

        {/* {campaigns && <CounterCont>
          <Counter>{campaigns.dispatched_read}</Counter>
          <Label>Leídos</Label>
        </CounterCont>}

        {campaigns && <CounterCont>
          <Counter>{campaigns.totalUnreadConversations}</Counter>
          <Label>Sin respuesta</Label>
        </CounterCont>} */}

        <CounterCont>
          <Flex>
            <div>
              <Counter>{campaigns?.totalReadConversations || sessions.length}</Counter>
              <Label>Conversaciones</Label>
            </div>
            {campaigns && <div>
              <SmallCounterCont>
                <SmallCounter>{campaigns.totalAssignedConversations}</SmallCounter>
                <div>Asignadas</div>
              </SmallCounterCont>
              <SmallCounterCont>
                <SmallCounter>{campaigns.totalUnassignedConversations}</SmallCounter>
                <div>Sin asignar</div>
              </SmallCounterCont>

            </div>}
          </Flex>
        </CounterCont>
        {/* 
        {campaigns && <CounterCont>
          <Counter>{campaigns.totalAssignedConversations}</Counter>
          <Label>Asignadas</Label>
        </CounterCont>}

        {campaigns && <CounterCont>
          <Counter>{campaigns.totalUnassignedConversations}</Counter>
          <Label>sin asignar</Label>
        </CounterCont>} */}

        <CounterCont>
          <Counter>{sessions.filter(ses => ses.active).length}</Counter>
          <Label>Abiertas</Label>
        </CounterCont>
        <CounterCont>
          <Counter>{sessions.filter(ses => !ses.active).length}</Counter>
          <Label>Cerradas</Label>
        </CounterCont>
      </CountersHeaderCont>
      <BlueCont>
        <Header>
          <SelectCont>
            <label htmlFor="campaigns">Selecciona una campaña</label>
            <select name="campaigns" id="campaigns" value={selectedWaCampaign} onChange={e => setSelectedWaCampaign(e.target.value)}>
              <option value="">Selecciona una campaña</option>
              {waCampaigns.map((camp, index) => {
                if (camp.active === false) return
                return <option key={index} value={camp.id}>{camp.name}</option>
              })}
            </select>
          </SelectCont>
          <SelectCont>
            <label htmlFor="users">Selecciona un Agente</label>
            <select name="users" id="users" value={selectedAgent} onChange={e => setSelectedAgent(e.target.value)}>
              <option value="">Todos los agentes</option>
              {users.map((user, index) => {
                return <option key={index} value={user.auth0_id}>{user.name}</option>
              })}
            </select>
          </SelectCont>
          <ButtonsCont>
            <label>Filtra por estado de sesion</label>
            <div>
              {buttonsState.map((but, index) => {
                return (
                  <ButtonState key={index} isSelected={but.isSelected} onClick={() => handleUpdateButtonsState(index)}>{but.label}</ButtonState>
                )
              })}
            </div>
          </ButtonsCont>
        </Header>
        {sessions.map((session, index) => {
          return <LiveConversationSession
            key={index}
            session={session}
            users={users}
            waCampaigns={waCampaigns}
            showChat={handleShowChat}
            showLogs={handleShowLogs}
            showUsers={setShowUsers}
            setCurrentSessionId={setCurrentSessionId}
            deleteSession={handleDeleteSession}
            restoreSession={handleRestoreSession}
          />
        })}
      </BlueCont>

      {showChat && <ChatPopup setShowChat={setShowChat} />}
      {showLogs && <LogsPopup setShowLogs={setShowLogs} />}
      {showUsers && <ReassignPopup setShowReassign={setShowUsers} users={users} handleShowReasignUsers={handleShowReasignUsers} />}

    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${lightBackground};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const CountersHeaderCont = styled.div`
  /* border: 1px solid; */
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
`;
const CounterCont = styled.div`
/* min-width: 50px; */
padding: 0 10px;
height: 80px;
border-radius: 10px;
  background-color: ${darkBorder};
`;
const Flex = styled.div`
  display: flex;
`;
const Counter = styled.div`
  /* border: 1px solid; */
  font-size: 2.5rem;
  text-align: center;
  padding-top: 15px;
  line-height: 40px;
`;
const Label = styled.div`
  text-align: center;
  white-space: pre;
`;
const SmallCounterCont = styled.div`
  text-align: center;
  font-size: .7rem;
  margin-left:10px;
  padding-top: 10px;
`;
const SmallCounter = styled.div`
  font-size: 1rem;
  line-height: 10px;
`;
const BlueCont = styled.div`
  border-radius: 10px;
  flex-grow: 1;
  padding: 10px;
  background-color: ${lightBackgroundBlue};
`;
const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
`;
const ButtonsCont = styled.div`
  font-size: 0.7rem;
  
  &>div{
    height: 40px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;
const ButtonState = styled.span<IButtonState>`
padding: 5px 10px;
border-radius: 50px;
cursor: pointer;
color: ${props => props.isSelected ? '#fff' : '#000'};
  background-color: ${props => props.isSelected ? primary : darkBorder};
`;
interface IButtonState {
  isSelected: boolean
}
const SelectCont = styled.div`
  margin: 0 20px 0 0;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &>label{
    font-size: .7rem;
  }

  & > select {
    margin: 0 0 5px;
    height: 44px;
    font-size: 1rem;
    border: none;
    padding: 10px 10px;
    flex-grow: 1;
    max-width: 230px;
    background-color: white;
  }
`;
export default MainCont;

const BUTTONS_STATE: { label: 'Activas' | 'Cerradas' | 'Todas', isSelected: boolean }[] = [
  { label: 'Activas', isSelected: true },
  { label: 'Cerradas', isSelected: false },
  { label: 'Todas', isSelected: false },
]