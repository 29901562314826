import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Paper from '../../../foundation/Paper';
import SidebarItem from './SidebarItem';

import { items } from './sidebarFlowItems';
import './Sidebar.css';
import PropTypes from "prop-types";

const Sidebar = ({onPublish}) => {
  return (
    <div>
      <Paper style={{ height: '50%', padding: '2.5rem .5rem' }}>
        <PerfectScrollbar style={{ height: '100%', paddingRight: 16 }}>
          {items.map(({ text, Icon, type }) => (
            <SidebarItem key={type} text={text} Icon={Icon} type={type} />
          ))}
        </PerfectScrollbar>
      </Paper>

      <div className="d-flex justify-content-end mt-3 px-2">
        <button type="button" className="btn btn-primary btn-rounded" onClick={onPublish}>
          <i className="bx bx-play font-size-20 align-middle me-2"></i>
          Publish
        </button>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  onPublish: PropTypes.func,
}

export default Sidebar;
