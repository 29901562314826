import { IChannel } from 'library/interfaces/channelsInterfaces';
import { IError } from 'store/reports/reducer';
import {
  ChannelActionTypes,
  GET_CHANNELS,
  GET_CHANNELS_FAIL,
  GET_CHANNELS_SUCCESS,
  UPDATE_CHANNEL_SCENERY,
  UPDATE_CHANNEL_SCENERY_FAIL,
  UPDATE_CHANNEL_SCENERY_SUCCESS,
} from './actionTypes';

export interface IChannelStore {
  isLoading: boolean;
  error: IError | null;
  channels: IChannel[];
}
const INIT_STATE: IChannelStore = {
  isLoading: false,
  error: null,
  channels: [],
};
const channels = (
  state = INIT_STATE,
  action: ChannelActionTypes,
): IChannelStore => {
  switch (action.type) {
    //
    // waChannels
    //
    case GET_CHANNELS:
    case UPDATE_CHANNEL_SCENERY: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_CHANNELS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        channels: action.payload.channels,
      };
    }
    case GET_CHANNELS_FAIL:
    case UPDATE_CHANNEL_SCENERY_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case UPDATE_CHANNEL_SCENERY_SUCCESS: {
      const { channelId, sceneryId } = action.payload;
      const newChannels = state.channels.map(chan => {
        if (chan.id === channelId) {
          return { ...chan, scenario_id: sceneryId };
        }
        return chan;
      });
      return {
        ...state,
        isLoading: false,
        channels: newChannels,
      };
    }
    default:
      return state;
  }
};
export default channels;
