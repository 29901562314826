import { createSlice } from '@reduxjs/toolkit';
import {
  deleteFileAction,
  getAllFilesAction,
  uploadFileAction,
} from './asyncActions';

export interface ISysFile {
  id: string;
  filename: string;
  url: string;
  timestamp: string;
}
interface IFilesState {
  isLoading: boolean;
  files: ISysFile[];
  lastKey: string | null;
  error: string | null;
}
const initialState: IFilesState = {
  isLoading: false,
  files: [],
  lastKey: null,
  error: null,
};
const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllFilesAction.pending, state => {
        state.isLoading = true;
        state.error = null;
        state.files = [];
      })
      .addCase(getAllFilesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.lastKey = action.payload.lastKey;
        state.files = action.payload.rows;
      })
      .addCase(getAllFilesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(deleteFileAction.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteFileAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.files = state.files.filter(
          fil => fil.id !== action.payload.fileId,
        );
      })
      .addCase(deleteFileAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(uploadFileAction.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadFileAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.files = [action.payload, ...state.files];
      })
      .addCase(uploadFileAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export default filesSlice.reducer;
