import { call, put, takeEvery } from 'redux-saga/effects';
import {
  deleteCampaign,
  getAllCampaigns,
  getCampaignById,
  getCampaignTagsSummarized,
  getPBXCampaignInfo,
  postContactsToCampaign,
  postCreateCampaign,
  putUpdateCampaign,
  putUpdateCampaignStatus,
} from 'helpers/fakebackend_helper';
import {
  getAllCampaignsFail,
  getAllCampaignsSuccess,
  getCampaignByIdFail,
  getCampaignByIdSuccess,
  getPBXCampaifnInfoFail,
  getPBXCampaifnInfoSuccess,
  postCreateCampaignFail,
  postCreateCampaignSuccess,
  putUpdateCampaignStatusFail,
  putUpdateCampaignStatusSuccess,
  putUpdateCampaignFail,
  putUpdateCampaignSuccess,
  deleteCampaignSuccess,
  deleteCampaignFail,
  refreshCampaignSuccess,
  refreshCampaignFail,
  getCampaignTagsSummarizedFail,
  getCampaignTagsSummarizedSuccess,
  postContactGroupsSuccess,
  postContactGroupsAction,
  postContactGroupFail,
} from './actions';
import {
  GET_ALL_CAMPAIGNS,
  GET_CAMPAIGN_BY_ID,
  GET_PBX_CAMPAIGN_INFO,
  IGetAllCampaigns,
  IGetCampaignById,
  IPostCreateCampaign,
  IPutUpdateCampaign,
  IPutUpdateCampaignStatus,
  POST_CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  PUT_PAUSE_CAMPAIGN,
  PUT_PAUSE_CAMPAIGN_FAIL,
  PUT_PAUSE_CAMPAIGN_SUCCESS,
  PUT_PUBLISH_CAMPAIGN,
  PUT_PUBLISH_CAMPAIGN_FAIL,
  PUT_PUBLISH_CAMPAIGN_SUCCESS,
  PUT_RESUME_CAMPAIGN,
  PUT_RESUME_CAMPAIGN_FAIL,
  PUT_RESUME_CAMPAIGN_SUCCESS,
  PUT_TERMINATE_CAMPAIGN,
  PUT_TERMINATE_CAMPAIGN_FAIL,
  PUT_TERMINATE_CAMPAIGN_SUCCESS,
  PUT_UPDATE_CAMPAIGN,
  IDeleteCampaign,
  IRefreshCampaign,
  REFRESH_CAMPAIGN,
  IGetCampaignTagsSummarized,
  GET_CAMPAIGN_TAGS_SUMMARIZED,
} from './actionTypes';
import { ICampaign, IPBXCampaignInfo, ICampaignStatus } from './reducer';
import {
  IContactsToCampaign,
  ITagSummarized,
} from 'library/interfaces/campaignInterfaces';

function* onGetAllCampaigns({ payload: owner }: IGetAllCampaigns) {
  try {
    if (!owner) throw new Error('Owner is required');
    const response: ICampaign[] = yield call(getAllCampaigns, owner);
    yield put(getAllCampaignsSuccess(response));
  } catch (error) {
    yield put(getAllCampaignsFail(`Something was wrong! ${error}`));
  }
}

function* onGetCampaignById({ payload: id }: IGetCampaignById) {
  try {
    if (!id) throw new Error('Campaign ID is required');
    const response: ICampaign = yield call(getCampaignById, id);
    yield put(getCampaignByIdSuccess(response));
  } catch (error) {
    yield put(getCampaignByIdFail(`Something was wrong! ${error}`));
  }
}

function* onGetPBXCampaignInfo() {
  try {
    const response: IPBXCampaignInfo = yield call(getPBXCampaignInfo);
    if (!response.account_code) throw new Error("PBX info doesn't exist");
    yield put(getPBXCampaifnInfoSuccess(response));
  } catch (error) {
    yield put(getPBXCampaifnInfoFail(`Something was wrong! ${error}`));
  }
}

function* onPostCreateCampaign({ payload: campaign }: IPostCreateCampaign) {
  try {
    if (!campaign) throw new Error('Campaign data is required');

    const { contacts, ...rest } = campaign;
    // post create campaign receives all the campaign without the contacts
    // and return the id of new campaign

    const response: string = yield call(postCreateCampaign, rest);

    // after receives the campaign id into response
    // insert into store postContactGroups the quantity of groups
    yield put(postContactGroupsAction(contacts.length));

    // with the campaign id, post the contacts
    yield call(onPostContactsToCampaign, response, contacts);

    yield put(postCreateCampaignSuccess(response));
  } catch (error) {
    yield put(postCreateCampaignFail(`Something was wrong! ${error}`));
  }
}

function* onPostContactsToCampaign(
  campaignId: string,
  contacts: IContactsToCampaign[][],
) {
  let index = 1;
  for (const contactArr20 of contacts) {
    try {
      yield call(postContactsToCampaign, campaignId, contactArr20);
      yield put(postContactGroupsSuccess(index));
    } catch (error) {
      yield put(
        postContactGroupFail(`Something was wrong loading contacts! ${error}`),
      );
    }
    index++;
  }
}

function* onPutUpdateCampaign({
  payload: { id, index, editedCampaign },
}: IPutUpdateCampaign) {
  try {
    if (!id || !isFinite(index) || !editedCampaign)
      throw new Error('Data is required');

    // first edit the campaign
    yield call(putUpdateCampaign, id, editedCampaign);

    // - We dont receive a response in any case,
    //   that is why we request the campaign information again from the server.
    // - The response replaces the old campaign data with new data
    const newEditedCampaign: ICampaign = yield call(getCampaignById, id);
    yield put(putUpdateCampaignSuccess(index, newEditedCampaign));
  } catch (error) {
    yield put(putUpdateCampaignFail(`Something was wrong! ${error}`));
  }
}

function* onPutUpdateStatus({
  payload: { id, index, newStatus },
}: IPutUpdateCampaignStatus) {
  if (newStatus === 'delete') return;
  const ACTION_SUCCESS =
    newStatus === 'publish'
      ? PUT_PUBLISH_CAMPAIGN_SUCCESS
      : newStatus === 'pause'
      ? PUT_PAUSE_CAMPAIGN_SUCCESS
      : newStatus === 'resume'
      ? PUT_RESUME_CAMPAIGN_SUCCESS
      : PUT_TERMINATE_CAMPAIGN_SUCCESS;

  const ACTION_FAIL =
    newStatus === 'publish'
      ? PUT_PUBLISH_CAMPAIGN_FAIL
      : newStatus === 'pause'
      ? PUT_PAUSE_CAMPAIGN_FAIL
      : newStatus === 'resume'
      ? PUT_RESUME_CAMPAIGN_FAIL
      : PUT_TERMINATE_CAMPAIGN_FAIL;

  // state is nop updated in back with the putUpdateCampaignStatus.
  //
  // the response of putUpdateCampaignStatus comes with the old status
  // then, we control the campaign status with reducer to ensure the refresh of campaign status
  // and saga line newEditedCampaign.status = status
  //
  const status: ICampaignStatus =
    newStatus === 'publish'
      ? 'published'
      : newStatus === 'pause'
      ? 'pausing'
      : newStatus === 'resume'
      ? 'resuming'
      : 'ending';
  try {
    // first update the campaign status according to each case
    // is the same strategy that ***onPutUpdateCampaign*** above saga
    yield call(putUpdateCampaignStatus, id, newStatus);
    const newEditedCampaign: ICampaign = yield call(getCampaignById, id);
    newEditedCampaign.status = status;
    yield put(
      putUpdateCampaignStatusSuccess(index, newEditedCampaign, ACTION_SUCCESS),
    );
  } catch (error) {
    const message = `Something was wrong with ${newStatus} action! ${error}`;

    yield put(putUpdateCampaignStatusFail(message, ACTION_FAIL));
  }
}

function* onDeleteCampaign({ payload: { id, index } }: IDeleteCampaign) {
  try {
    if (!id || !isFinite(index)) throw new Error('Data is required');

    // first delete the campaign
    yield call(deleteCampaign, id);

    // - We dont receive a response in any case,
    //   that is why we request the campaign information again from the server.
    // - If the campaign exists, there was an error
    //const Campaign: ICampaign = yield call(getCampaignById, id);
    //if(Campaign) throw new Error('The campaign was not deleted')
    // - If the campaign does not exist, we can delete the campaign from store
    yield put(deleteCampaignSuccess(index));
  } catch (error) {
    yield put(deleteCampaignFail(`Something was wrong! ${error}`));
  }
}

function* onRefreshCampaign({ payload: { id, index } }: IRefreshCampaign) {
  try {
    if (!id || !isFinite(index)) throw new Error('Data is required');
    const campaign: ICampaign = yield call(getCampaignById, id);
    yield put(refreshCampaignSuccess(index, campaign));
  } catch (error) {
    yield put(refreshCampaignFail(`Something was wrong! ${error}`));
  }
}

function* onGetCampaignTagsSummarized({
  payload: { campaignIndex, campaignId },
}: IGetCampaignTagsSummarized) {
  try {
    const tagsSummarized: ITagSummarized[] = yield call(
      getCampaignTagsSummarized,
      campaignId,
    );
    yield put(getCampaignTagsSummarizedSuccess(campaignIndex, tagsSummarized));
  } catch (error) {
    yield put(getCampaignTagsSummarizedFail(`Something was wrong! ${error}`));
  }
}

function* voiceCampaignsSaga() {
  yield takeEvery(GET_ALL_CAMPAIGNS, onGetAllCampaigns);
  yield takeEvery(GET_CAMPAIGN_BY_ID, onGetCampaignById);
  yield takeEvery(GET_PBX_CAMPAIGN_INFO, onGetPBXCampaignInfo);
  yield takeEvery(POST_CREATE_CAMPAIGN, onPostCreateCampaign);
  yield takeEvery(PUT_UPDATE_CAMPAIGN, onPutUpdateCampaign);
  yield takeEvery(PUT_PUBLISH_CAMPAIGN, onPutUpdateStatus);
  yield takeEvery(PUT_PAUSE_CAMPAIGN, onPutUpdateStatus);
  yield takeEvery(PUT_RESUME_CAMPAIGN, onPutUpdateStatus);
  yield takeEvery(PUT_TERMINATE_CAMPAIGN, onPutUpdateStatus);
  yield takeEvery(DELETE_CAMPAIGN, onDeleteCampaign);
  yield takeEvery(REFRESH_CAMPAIGN, onRefreshCampaign);
  yield takeEvery(GET_CAMPAIGN_TAGS_SUMMARIZED, onGetCampaignTagsSummarized);
}
export default voiceCampaignsSaga;
