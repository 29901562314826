import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Divider from '../../Divider';
import * as DataInfo from '../../DataInfo';

View.propTypes = {
  data: PropTypes.object.isRequired,
};

function View({ data }) {
  return (
    <div>
      <Row>
        <Col lg={12}>
          <DataInfo.Label>From</DataInfo.Label>
          <DataInfo.Content>{data?.from || '-'}</DataInfo.Content>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <DataInfo.Label>To</DataInfo.Label>
          <DataInfo.Content>{data?.to || '-'}</DataInfo.Content>
        </Col>
      </Row>

      <Divider />

      <Row>
        <DataInfo.Label>Message Text</DataInfo.Label>
        <DataInfo.Content>{data?.messageText}</DataInfo.Content>
      </Row>
    </div>
  );
}

export default View;
