import DownloadReportButton from 'components/outbound/atoms/downloadReportButton';
import { lightBackgroundBlue } from 'library/colors';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BotsExt } from './byBotExtension';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import {
  getToday_yyyymmdd,
  isDateAfterToday,
  isFirstDateAfterToSecondDate,
  localDateToUTC_formatISO
} from 'library/services/dateServices';

interface ICustomEventsReportType11Props {
  reportTypeId: string;
}
const CustomEventsReportType11: FC<ICustomEventsReportType11Props> = ({ reportTypeId }) => {
  const { t } = useTranslation('reports')
  const { owner } = useAppSelector(store => store.authUserData);

  const [disable, setDisable] = useState(true)
  const [selectedExtension, setSelectedExtension] = useState('----')
  const [dates, setDates] = useState({
    start: getToday_yyyymmdd(),
    end: getToday_yyyymmdd()
  })

  let START_DATE = localDateToUTC_formatISO(dates.start + 'T00:00:00.000Z');
  let END_DATE = localDateToUTC_formatISO(dates.end + 'T23:59:59.999Z');


  const handleSelectExtensionChange = (value: string) => {
    setSelectedExtension(value)
  }
  const handleInputDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (isDateAfterToday(value)) return

    if (name === 'end' && isFirstDateAfterToSecondDate(dates.start, value)) return

    setDates(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (!isFirstDateAfterToSecondDate(dates.start, dates.end)) {
      setDisable(false)
      return
    }
    setDisable(true)
  }, [dates])
  return (
    <Cont>
      <Title>Reporte de Eventos Personalizados</Title>
      <BlueCont>

        <SpaceBetween>
          <Flex>
            <SelectCont>
              <label htmlFor='filter'>Selecciona la extensión</label>
              <select id="filter" value={selectedExtension} onChange={e => handleSelectExtensionChange(e.target.value)}>
                {BotsExt[owner] && BotsExt[owner].map((item, index) => {
                  return <option key={index} value={item.value}>{t(`botExtension1234Report.selectExtOpt.${item.label}`)}</option>
                })}

              </select>
            </SelectCont>
            <SelectCont>
              <label htmlFor="">Desde</label>
              <input type='date' name='start' value={dates.start} onChange={e => handleInputDateChange(e)} />
            </SelectCont>
            <SelectCont>
              <label htmlFor="">Hasta</label>
              <input type='date' name='end' value={dates.end} onChange={e => handleInputDateChange(e)} />
            </SelectCont>
          </Flex>
          <ButtonCont>
            <DownloadReportButton
              reportType={reportTypeId}
              disable={selectedExtension === '----' || disable}
              params={`start=${START_DATE}&end=${END_DATE}&botExtension=${selectedExtension}`}
            >
              Reporte
            </DownloadReportButton>
          </ButtonCont>
        </SpaceBetween>
      </BlueCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled.div`
  border-radius: 10px;
  flex-grow: 1;
  padding: 0 20px;
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  `;
const SpaceBetween = styled.div`
display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SelectCont = styled.div`
  margin: 5px 20px 5px 0;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &>label{
    font-size: .7rem;
  }

  & > select,
  & > input {
    margin: 0 0 5px;
    height: 44px;
    font-size: 1rem;
    border: none;
    padding: 10px 10px;
    flex-grow: 1;
    max-width: 255px;
    background-color: white;
  }
`;
const ButtonCont = styled.div`
  transform: translateY(10px);
`;
export default CustomEventsReportType11;