import { Phone } from "library/phone/phone";
import React, { FC } from "react";
import styled from "styled-components";

import LeftBlock from "../organisms/leftBlock";
import RightBlock from "../organisms/rightBlock";
import RightBar from "../organisms/workspaceRightBar";
import ChatButton from "../smartbotChat/chatButton";
import { useAppSelector } from "library/hooks/useAppSelector";

interface IWorkSpaceTemplate {
    phone: Phone
}

const WorkSpaceTemplate: FC<IWorkSpaceTemplate> = ({ phone }) => {
    const { owner } = useAppSelector(store => store.authUserData)
    return (
        <>
            <TemplateCont>
                <LeftBlock phone={phone} />
                <RightBlock phone={phone} />
                <RightBar />
            </TemplateCont>
            {owner === 'METROPISTAS' && <ChatButton />}
        </>
    )
}
const TemplateCont = styled.div`
    display: flex;
    height: 90%;
`;
export default WorkSpaceTemplate;