import axios from 'axios';
import { subscribeWebChat } from 'helpers/appSyncWebChat';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { FC, useEffect, useRef, useState } from 'react';
import { getChannelsAction, updateChannelSceneryAction } from 'store/actions';
import styled from 'styled-components';
import bg from './fondo-large.png'
import { IChannel } from 'library/interfaces/channelsInterfaces';
import { IWaMessage } from 'library/interfaces/conversationInterfaces';
import WaTextMessage from './waMessages/waTextMessage';
import { isJSON } from 'library/services/functions';
import WaQuickReplyMessage from './waMessages/waQuickReplyMessage';
import WaListMessageView from '../modals/visualEditor/waVisualEditorComponents/waListMessageView';
import WaListMessage from './waMessages/waListMessage';

interface IChatTesterProps {
  sceneryId: string
}
const ChatTester: FC<IChatTesterProps> = ({ sceneryId }) => {
  const dispatch = useAppDispatch()
  const { id, name } = useAppSelector(store => store.scenariosV2.scenario) || { id: '', name: '' }
  const channels = useAppSelector(store => store.channels.channels)
  const { display_name, auth0_id } = useAppSelector(store => store.authUserData)

  const InputDivRef = useRef<HTMLDivElement>(null)
  const ChatContRef = useRef<HTMLDivElement>(null)
  const divScrollRef = useRef<HTMLDivElement>(null)

  const [message, setMessage] = useState('')

  const [webChatChannel, setWebChatChannel] = useState<IChannel | null>(null)
  const [webChatSession, setWebChatSession] = useState('')

  const [webChatMessages, setWebChatMessages] = useState<IWaMessage[]>([])


  useEffect(() => {
    if (channels.length > 0) return
    dispatch(getChannelsAction())
  }, [])

  useEffect(() => {
    if (channels.length === 0 || webChatChannel) return
    const webChan = channels.find(chan => chan.source === 'webchat')
    if (!webChan) return
    setWebChatChannel(webChan)
  }, [channels])

  useEffect(() => {
    if (sceneryId === 'new' || !webChatChannel || webChatChannel.scenario_id === sceneryId) return
    dispatch(updateChannelSceneryAction(webChatChannel.id, sceneryId))
  }, [sceneryId, webChatChannel])

  useEffect(() => {
    if (!webChatChannel || webChatSession) return

    const source = axios.CancelToken.source()
    const user = {
      sourceId: auth0_id,
      sourceName: display_name
    }
    const getSessionUrl = `${process.env.REACT_APP_WEBCHAT_URL}/channel/${webChatChannel.id}/session`
    axios.post(getSessionUrl, user, {
      cancelToken: source.token
    })
      .then(res => setWebChatSession(res.data))
      .catch(e => console.log(e))

    return () => source.cancel()
  }, [webChatChannel])

  useEffect(() => {
    if (!webChatSession) return
    const subscription = subscribeWebChat(webChatSession, (data: { eventType: 'new-message' | 'end-session'; payload: string }[]) => {
      data.forEach(event => {
        if (event.eventType === 'new-message') {
          setWebChatMessages(prev => [...prev, JSON.parse(event.payload)])
        } else if (event.eventType === 'end-session') {
          console.log(event.payload)

        }
      })
    })

    return () => subscription.unsubscribe()
  }, [webChatSession])

  const handleKeyDown = (value: string, e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        handleSendMessage(value);
      }
    }
  };

  const handleSendMessage = async (value: string) => {
    if (!webChatChannel || !webChatSession || !value) return
    const messageToSend = {
      msg: value
    }
    const msg: IWaMessage = {
      id: crypto.randomUUID(),
      idx: '',
      content_type: 'text',
      content: messageToSend.msg,
      source_id: 'client',
      source_name: 'client',
      source_type: 'webchat',
      source_timestamp: 0,
      channel_id: '',
      timestamp: 0,
      created_at: ''
    }
    setWebChatMessages(prev => [...prev, msg])
    setMessage('')
    if (InputDivRef.current) InputDivRef.current.innerHTML = ''
    try {

      await fetch(`${process.env.REACT_APP_WEBCHAT_URL}/channel/${webChatChannel.id}/session/${webChatSession}/message`, {
        method: 'POST',
        body: JSON.stringify(messageToSend)
      })
    } catch (error) {
      console.log(error)
      setWebChatMessages(prev => prev.filter(m => m.id !== msg.id))
    }

  }
  const handleJson = (value: string) => {
    try {
      const json = JSON.parse(value)
      return json
    } catch (error) {
      return value
    }
  }

  useEffect(() => {
    if (!divScrollRef.current) return
    divScrollRef.current?.scrollIntoView();
  }, [webChatMessages])



  return (
    <Cont bgImage={bg}>
      <Header>{name}</Header>
      <ChatMessageCont ref={ChatContRef} >
        <MessageCont>
          {webChatMessages.map((message, i) => {

            const jsonMessage = handleJson(message.content)
            if (isJSON(jsonMessage)) {
              const msg = JSON.parse(jsonMessage)
              const type = msg.type
              if (type === 'quick_reply') {
                return <WaQuickReplyMessage
                  key={i}
                  text={msg.content.text}
                  header={msg.content.header}
                  caption={msg.content.caption}
                  buttons={msg.options}
                  right={message.source_name !== 'system'}
                  handleSendMessage={handleSendMessage}
                />
              } else if (type === 'list') {
                return <WaListMessage
                  key={i}
                  content={{ title: msg.title, body: msg.body, footer: msg.footer }}
                  globalButton={msg.globalButtons[0].title}
                  items={msg.items}
                  handleSendMessage={handleSendMessage}

                />
              }
            }
            return (
              <WaTextMessage key={i} text={message.content} right={message.source_name !== 'system'} />
            )
          })}
          <div ref={divScrollRef}></div>
        </MessageCont>
      </ChatMessageCont>
      <ChatForm>
        <Input
          contentEditable
          ref={InputDivRef}
          onKeyDown={e => handleKeyDown(message, e)}
          onInput={e => setMessage(e.currentTarget.innerText)} />
        <Button onClick={() => handleSendMessage(message)}>
          <i className='mdi mdi-send' />
        </Button>
      </ChatForm>
    </Cont>
  );
};
const Cont = styled.div<ICont>`
height: 100vh;
width: 100%;
background-image: url(${props => props.bgImage});
display: flex;
flex-direction: column;
overflow: hidden;
`;
const Header = styled.div`
padding: 12px 20px;
  color: #e9edef;
font-size: 1rem;
  background-color: #202c33;
`;
const ChatMessageCont = styled.div`
  flex-grow: 1;
  display: grid;
  align-items: end;
  padding: 0 20px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(76, 89, 96, .4);
    border-radius: 10px;
  }
`;
const MessageCont = styled.div``;

const ChatForm = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  padding-left: 20px;
  background-color: #202c33;
`;
const Input = styled.div`
  border: none;
  color: #e9edef;
  flex-grow: 1;
  padding: 9px 12px;
  border-radius: 10px;
  font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    max-height: 98px;
    overflow-y: auto;
  background-color: #2a3942;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(76, 89, 96);
    border-radius: 10px;
  }
`;
const Button = styled.div`
  font-size: 2rem;
  cursor: pointer;
`;
interface ICont {
  bgImage: string
}


export default ChatTester;