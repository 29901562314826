import React, { FC } from 'react';
import styled from 'styled-components';
import smartbot from '../../../icons/SmartBot_BotonChatBot.svg'

const RightBarErrorFallback = (error?: string) => {
  return (
    <Cont>
      <ImgCont>
        <img src={smartbot} alt="" />
      </ImgCont>
      <h3>Algo ha ido mal! ☹️</h3>
      <h5>Haz un print de pantalla y envíala al equipo de desarrollo.</h5>
      <h5>{error}</h5>
    </Cont>
  );
};
const Cont = styled.div`
  height: 70vh;
  display: grid;
  place-content: center;
  text-align: center;
  grid-gap: 20px;

  &>h3,h5{
    padding: 0 5px;
  }
`;
const ImgCont = styled.div`
  width: 100px;
  margin: 0 auto;
`;
export default RightBarErrorFallback;