import { darkBorder, popUpBackground, primary } from 'library/colors';
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface IEmailPopupProps {
  email: string
  clearSelectedEmail: (value: '') => void
}
const EmailPopup: FC<IEmailPopupProps> = ({ email, clearSelectedEmail }) => {
  const ref = useRef<HTMLDivElement>(null)



  useEffect(() => {
    if (!ref.current) return
    ref.current.innerHTML = email
  }, [])
  return (
    <PopUpContainer>
      <Cont >
        <Exit id="exitButton" onClick={() => clearSelectedEmail('')}>X</Exit>
        <Title>Contenido</Title>
        <EmailCont ref={ref}></EmailCont>
        <Button onClick={() => clearSelectedEmail('')}>Salir</Button>
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1000;
  background-color: ${popUpBackground};
`;
const Cont = styled.div`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 800px;
  height: 550px;
  color: black;
  padding: 20px 40px 30px;
  display: grid;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;
`;
const EmailCont = styled.div`
height: 400px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${darkBorder};
  
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const Button = styled.button`
  border-radius: 50px;
  margin: 0;
  display: inline-block;
  min-width: 30px;
  font-size: 13px;
  padding: 8px 16px;
  color: white;
  justify-self: end;
  border: 2px solid ${darkBorder};
  background-color: ${darkBorder};
  transform: translateY(10px);
`;
export default EmailPopup;