import React from 'react';
import PropTypes from 'prop-types';

import './IternalSidetbar.css';
import InternalSidebarItem from './InternalSidebarItem';
import { Nav } from 'reactstrap';

function InternalSidebar({ items = [] }) {
  return (
    <Nav tabs className="nav-tabs-custom" vertical role="tablist">
      {items.map(({ Icon, text, onClick, divider = false }) => (
        <InternalSidebarItem
          key={text}
          Icon={Icon}
          text={text}
          onClick={onClick}
          divider={divider}
        />
      ))}
    </Nav>
  );
}

InternalSidebar.propTypes = {
  items: PropTypes.array,
};

export default InternalSidebar;
