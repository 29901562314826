import * as React from "react"

export const CallTagging = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M14.62 32.54a4.32 4.32 0 0 1-3.07-1.27l-7.82-7.82a4.35 4.35 0 0 1 0-6.14L17.31 3.73a4.31 4.31 0 0 1 2.79-1.25.55.55 0 0 1 .18 0h9.31a3 3 0 0 1 2.95 3v9.31a1.11 1.11 0 0 1 0 .19 4.33 4.33 0 0 1-1.25 2.78l-13.6 13.51a4.32 4.32 0 0 1-3.07 1.27ZM20.41 4.3h-.1a2.49 2.49 0 0 0-1.7.72L5 18.61a2.5 2.5 0 0 0 0 3.54L12.85 30a2.5 2.5 0 0 0 3.54 0L30 16.39a2.48 2.48 0 0 0 .72-1.69s0-.08 0-.12V5.41a1.11 1.11 0 0 0-1.13-1.11Zm11.21 10.42Z"
    />
    <circle fill="currentColor" cx={23.25} cy={11.47} r={1.82} />
  </svg>
)

