import React from 'react';
import PropTypes from 'prop-types';
import * as Fields from '../../../Fields';

Replays.propTypes = {
  name: PropTypes.string.isRequired,
};

function Replays({ name }) {
  return (
    <>
      <label>Replays</label>
      <div>
        <Fields.Radio inline={true} name={name} label="off" value="off" />
        <Fields.Radio inline={true} name={name} label="1" value="1" />
        <Fields.Radio inline={true} name={name} label="2" value="2" />
        <Fields.Radio inline={true} name={name} label="3" value="3" />
      </div>
    </>
  );
}

export default Replays;
