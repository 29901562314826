import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import PageTitle from 'components/outbound/atoms/pageTitle';
import { lightBackground } from 'library/colors';
import OutboundMenu from 'components/outbound/molecules/outboundMenu';
import CampaignsTemplate from 'components/outbound/templates/outboundCampaignTemplate';
import CreateCampaignTemplate from 'components/outbound/templates/outboundCreateCampaignTemplate';
import { useTranslation } from 'react-i18next';
import SMSCampaignTemplate from 'components/outbound/templates/outboundSMSCampaignTemplate';
import SMSCreateCampaignTemplate from 'components/outbound/templates/outboundSMSCreateCampaignTemplate';
import WaCampaignsTemplate from 'components/outbound/waCampaigns/templates/outboundWaCampaignTemplate';
import useLocalStorage from 'library/hooks/useLocalStorage';
import WACreateCampaignTemplate from 'components/outbound/waCampaigns/templates/outboundWACreateCampaignTemplate';
import Gallery from 'components/outbound/waCampaigns/templates/gallery';

const PagesStarter = () => {
  const { t } = useTranslation('outbound');

  const [initialTab, setInitialTab] = useLocalStorage('outboundLastTab', 0);
  const [buttons, setButtons] = useState(() => {
    return HEADER_BUTTONS_INIT_STATE.map((button, index) => {
      if (index === initialTab) return { ...button, isSelected: true };
      return { ...button, isSelected: false };
    });
  });
  const [showGallery, setShowGallery] = useState(false);

  const [showCampaignForm, setShowCampaignForm] = useState({
    show: false,
    campaignToEdit: undefined,
    campaignIndexToUpdate: undefined,
  });
  const [showSMSCampaignForm, setShowSMSCampaignForm] = useState({
    show: false,
    campaignToEdit: undefined,
    campaignIndexToUpdate: undefined,
  });
  const [showWACampaignForm, setShowWACampaignForm] = useState(false);

  const handleShowCampaignForm = (
    show,
    campaignToEdit = undefined,
    campaignIndexToUpdate = undefined,
  ) => {
    setShowCampaignForm({ show, campaignToEdit, campaignIndexToUpdate });
  };
  const handleShowSMSCampaignForm = (
    show,
    campaignToEdit = undefined,
    campaignIndexToUpdate = undefined,
  ) => {
    setShowSMSCampaignForm({ show, campaignToEdit, campaignIndexToUpdate });
  };
  const handleShowWACampaignForm = show => {
    setShowWACampaignForm(show);
  };

  const handleMenuButtonClick = index => {
    const newButtonsState = buttons.map((item, i) => {
      if (index === i) return { ...item, isSelected: true };
      return { ...item, isSelected: false };
    });
    setInitialTab(index);
    setButtons(newButtonsState);
  };
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          backgroundColor: '#eff5f8',
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        <MetaTags>
          <title>{t('metaTag')} | SmartBot</title>
        </MetaTags>
        <Container fluid>
          <PageTitle i18nKeys={{ namespace: 'outbound', key: 'pageTitle' }} />

          <Cont>
            <OutboundMenu
              buttons={buttons}
              onButtonClick={handleMenuButtonClick}
            />

            {buttons[0].isSelected && (
              <CampaignsTemplate setShowCampaignForm={handleShowCampaignForm} />
            )}

            {buttons[1].isSelected && (
              <SMSCampaignTemplate
                setShowSMSCampaignForm={handleShowSMSCampaignForm}
              />
            )}

            {buttons[2].isSelected && !showGallery && (
              <WaCampaignsTemplate
                setShowWACampaignForm={handleShowWACampaignForm}
                setShowGallery={setShowGallery}
              />
            )}
            {buttons[2].isSelected && showGallery && (
              <Gallery setShowGallery={setShowGallery} />
            )}
          </Cont>

          {showCampaignForm.show && (
            <CreateCampaignTemplate
              campaignToEdit={showCampaignForm.campaignToEdit}
              campaignIndexToUpdate={showCampaignForm.campaignIndexToUpdate}
              setShowCampaignForm={handleShowCampaignForm}
            />
          )}
          {showSMSCampaignForm.show && (
            <SMSCreateCampaignTemplate
              setShowSMSCampaignForm={handleShowSMSCampaignForm}
            />
          )}
          {showWACampaignForm && (
            <WACreateCampaignTemplate
              setShowWACampaignForm={handleShowWACampaignForm}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
const Cont = styled.div`
  min-height: 75vh;
  border-radius: 10px;
  background-color: ${lightBackground};
`;

const HEADER_BUTTONS_INIT_STATE = [
  {
    isSelected: true,
    i18nKeys: { namespace: 'outbound', key: 'campaigns.tabTitle' },
  },
  {
    isSelected: false,
    i18nKeys: { namespace: 'outbound', key: 'SMScampaigns.tabTitle' },
  },
  {
    isSelected: false,
    i18nKeys: { namespace: 'outbound', key: 'waCampaign.tabTitle' },
  },
  // {
  //   isSelected: false,
  //   i18nKeys: { namespace: 'outbound', key: 'outboundCallHistory.tabTitle' },
  // },
];
export default PagesStarter;
