import React, { FC } from 'react';
import styled from 'styled-components';
import InfoCont from '../atoms/infoCont';
import Title from '../atoms/title';
import Text from '../atoms/text';
import Span from '../atoms/span';

interface ISugerenciasOQuejasProps { }
const SugerenciasOQuejas: FC<ISugerenciasOQuejasProps> = ({ }) => {
  return (
    <InfoCont>
      <Title>Sugerencias o quejas</Title>
      <Text>
        Veo que quiere presentar una sugerencia o queja
        <Span danger> (indicar solo la que corresponda)</Span>
        . Voy a abrir un proceso para que su mensaje sea atendido con prontitud.
      </Text>
      <Text danger>
        Entra a la plataforma A1 Tracker, abre un ticket para queja o reclamación y hazle las preguntas básicas que te indica la plataforma.
      </Text>
      <Text>
        Gracias por compartir esta información con nosotros. Le enviaremos un correo electrónico cuando la situación sea atendida para que conozca el resultado. Apreciamos su interés para que sigamos mejorando nuestra gestión.
      </Text>
    </InfoCont>
  );
};
export default SugerenciasOQuejas;