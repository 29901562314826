import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import OnLineAgents from '../molecules/onLineAgents';
import Container from '../atoms/container';
import Title from '../atoms/title';
import LostCalls from '../molecules/lostCalls';
import CallsAnswered from '../molecules/callAnswered';
import OccupationRange from '../molecules/occupationRange';

import { IModelLiveBoardData } from 'library/interfaces/liveboardInterface';
import { useHistory } from 'react-router-dom';
interface IQueueResumenProps {
  data: IModelLiveBoardData;
}
const QueueResume: FC<IQueueResumenProps> = ({ data }) => {
  const { queue, calls, occupation_range, agents_state } = data;

  const history = useHistory();

  const handleClick = () => {
    history.push(`/live-board/summarized/${queue.id}`);
  };
  return (
    <ResumenCont>
      <Container
        margin="0 auto 20px"
        padding="0 0 0 10px"
        handleClick={handleClick}
        cursorPointer
      >
        <Title padding="20px 15px 5px">{queue.name}</Title>
        <Div>
          <LostCalls
            lostCalls={calls.abandonedCount}
            totalCalls={calls.answeredCount + calls.abandonedCount}
          />
          <CallsAnswered
            answered={calls.answeredCount}
            inqueue={calls.queued}
            center
            big
          />
          <OccupationRange center porcent={occupation_range} />
          <OnLineAgents data={agents_state} />
        </Div>
      </Container>
    </ResumenCont>
  );
};
const ResumenCont = styled.div`
  border: 1px;
  max-width: 1050px;
  margin: 0 auto;

  @media (min-width: 1500px) {
    transform: scale(1.1);
    margin: 20px auto 50px;
  }
  @media (min-width: 1600px) {
    transform: scale(1.17);
    margin: 30px auto 60px;
  }
  @media (min-width: 1600px) {
    transform: scale(1.17);
    margin: 30px auto 60px;
  }
  @media (min-width: 1750px) {
    transform: scale(1.3);
    margin: 50px auto 90px;
  }
  @media (min-width: 1900px) {
    transform: scale(1.45);
    margin: 70px auto 130px;
  }
`;
const Div = styled.div`
  display: flex;
  padding: 0 15px 15px;
`;
export default QueueResume;
