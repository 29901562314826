import { IQueue } from 'library/interfaces/crdInterfaces';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';

export const workspaceLiveDataService = (
  extenName: string,
  queues: IQueue[],
  data: ILiveDataQueue[],
) => {
  const res = queues.reduce((acc, cur)=>{
    data.forEach(dat=>{
        if(dat.id === cur.id){
            acc.callsInQueue += dat.callsStatus.queued

            const agentdata = dat.agentsStatus.find(agnt=>agnt.id===extenName)
            if(agentdata){
                acc.answered += agentdata.answeredCount
                acc.unanswered += agentdata.unansweredCount
            }
        }
    })
    return acc
  }, {
    callsInQueue: 0,
    answered: 0,
    unanswered: 0,
    serviceLevel: 0
  } as {
    callsInQueue: number,
    answered: number,
    unanswered: number,
    serviceLevel: number
  })
  return res
};
