import * as React from "react"

export const Pause = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M17.5 31.39A13.89 13.89 0 1 1 31.39 17.5 13.9 13.9 0 0 1 17.5 31.39Zm0-25.94A12.05 12.05 0 1 0 29.55 17.5 12.06 12.06 0 0 0 17.5 5.45Z"
    />
    <path
      fill="currentColor"
      d="M14.52 23.8a.92.92 0 0 1-.92-.92V12.12a.92.92 0 0 1 1.84 0v10.76a.92.92 0 0 1-.92.92ZM20.48 23.8a.92.92 0 0 1-.92-.92V12.12a.92.92 0 0 1 1.84 0v10.76a.92.92 0 0 1-.92.92Z"
    />
  </svg>
)

