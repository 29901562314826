import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../FormContainer';
import ViewAudioLibrary from '../common/AudioLibrary/ViewAudioLibrary';
import { Col, Row } from 'reactstrap';
import Divider from '../../Divider';

ViewOutputSettings.propTypes = {
  data: PropTypes.object.isRequired,
};

function ViewOutputSettings({ data }) {
  return (
    <FormContainer title="Output Settings">
      <>
        {data?.outputType === 'voiceSyntesis' ? (
          <>
            <span className="badge bg-primary">Voice Syntesis</span>
            <Row>
              <Col lg={6}>
                <div className="data-info-label">Synth language</div>
                <div className="data-info-content">
                  {data?.synthLanguage?.label || '-'}
                </div>
              </Col>
              <Col lg={6}>
                <div className="data-info-label">Voice</div>
                <div className="data-info-content">
                  {data?.voice?.label || '-'}
                </div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col lg={12}>
                <div className="data-info-label">Text</div>
                <div className="data-info-content">{data?.text}</div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col lg={12}>
                <div className="data-info-label">Replays</div>
                <div className="data-info-content">{data?.replays}</div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col lg={12}>
                <div className="data-info-label">Text</div>
                <div className="data-info-content">{data?.failTexts}</div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <span className="badge bg-primary">Audiorecord</span>
            <ViewAudioLibrary name={data?.audioLib1} />
            <Divider />
            <Row>
              <Col lg={12}>
                <div>Replays</div>
                <div>{data?.replays}</div>
              </Col>
            </Row>
            <Divider />
            <ViewAudioLibrary name={data?.audioLib2} />
          </>
        )}
      </>
    </FormContainer>
  );
}

export default ViewOutputSettings;
