import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import QueueSelectList from '../molecules/queueSelectList';

import { primary } from 'library/colors';

import { getLiveData, getReportData, getSummarizeData } from 'store/actions';

import { IQueue } from 'library/interfaces/crdInterfaces';
import TimeRangeButtons from '../molecules/timeRangeButtons';
import { useTranslation } from 'react-i18next';

interface IQueueSelectLiveBoardProps {
  queues: IQueue[];
  mainPath: string;
  redirectPath: string;
  live?: React.ReactNode
}
const QueueSelectLiveBoard: FC<IQueueSelectLiveBoardProps> = ({queues, mainPath, redirectPath, live}) => {
  const {t} = useTranslation('liveBoard')

  return (
    <Container>
      <QueueSelectList mainPath={mainPath} redirectPath={redirectPath} queues={queues} />
      {live ? <Div> {t('live').toUpperCase()} </Div> : <TimeRangeButtons />}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 20;

  @media (min-width: 1600px) {
    transform: scale(1.1);
    margin: 10px 60px;
  }
  @media (min-width: 1700px) {
    transform: scale(1.2);
    margin: 15px 120px;
  }
  /* @media (min-width: 1800px) {
    transform: scale(1.3);
    margin: 130px auto 130px;
  } */
  @media (min-width: 1900px) {
    margin: 15px 135px;
  } 
`;
const Div = styled.div`
  flex-grow: 1;
  text-align: center;
  color: ${primary};
  font-size: 2rem;
  font-weight: 700;
`
export default QueueSelectLiveBoard;