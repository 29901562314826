import { darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { EmailFiltersTypes, NotificationTypes } from 'library/interfaces/reportsInterfaces';
import { isDateAfterToday } from 'library/services/dateServices';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clearEmailsByFilterStore, getEmailsByFilterAction } from 'store/actions';
import styled from 'styled-components';

interface IEmailFormProps {
  order: string;
  setOrder: (order: string) => void;
  date: { start: string, end: string }
  setDate: (date: { start: string, end: string }) => void;
  selectedFilter: EmailFiltersTypes,
  setSelectedFilter: (value: EmailFiltersTypes) => void
  filterValue: { cedula: string, notification_type: NotificationTypes }
  setFilterValue: (value: { cedula: string, notification_type: NotificationTypes }) => void
}
const EmailsForm = ({
  order,
  setOrder,
  date,
  setDate,
  filterValue,
  selectedFilter,
  setFilterValue,
  setSelectedFilter }: IEmailFormProps) => {

  const { t } = useTranslation('reports')
  const dispatch = useAppDispatch()

  const { lastKey, isLoading } = useAppSelector(store => store.reports.emails)

  const handleSelectedFilterChange = (value: EmailFiltersTypes) => {
    dispatch(clearEmailsByFilterStore())
    setSelectedFilter(value)
  }

  const handleInputCedulaChange = (value: string) => {
    const regEx = /\D/
    if (regEx.test(value)) return
    setFilterValue({ ...filterValue, cedula: value })
  }

  const handleInputDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (isDateAfterToday(value)) return
    setDate({ ...date, [name]: value })
  }

  const handleButtonClick = () => {
    dispatch(clearEmailsByFilterStore())
    if (selectedFilter === '') {
      dispatch(getEmailsByFilterAction(null, order, date))
      return
    }
    dispatch(getEmailsByFilterAction(
      null, order, date,
      { filter: selectedFilter, filterValue: filterValue[selectedFilter] }
    ))
  }
  return (
    <BlueCont>
      <SpaceBetween>
        <Flex>
          <SelectCont>
            <label htmlFor='filter'>Selecciona el filtro</label>
            <select id="filter" onChange={e => handleSelectedFilterChange(e.target.value as EmailFiltersTypes)}>
              {FILTERS.map((item, index) => {
                return <option key={index} value={item}>{t(`emails.filters.${item}`)}</option>
              })}

            </select>
          </SelectCont>

          <SelectCont>
            <label htmlFor='order'>Orden</label>
            <select id="order" value={order} onChange={e => setOrder(e.target.value)}>
              <option value="des">des</option>
              <option value="asc">asc</option>

            </select>
          </SelectCont>

          <SelectCont>
            <label htmlFor="">Desde</label>
            <input type='date' name='start' value={date.start} onChange={e => handleInputDateChange(e)} />
          </SelectCont>
          <SelectCont>
            <label htmlFor="">Hasta</label>
            <input type='date' name='end' value={date.end} onChange={e => handleInputDateChange(e)} />
          </SelectCont>
          {selectedFilter === 'notification_type' &&
            <SelectCont2>
              <label htmlFor='filter'>Selecciona el estado</label>
              <select id="filter"
                onChange={e => setFilterValue(
                  { ...filterValue, notification_type: e.target.value as NotificationTypes }
                )}
              >
                {NOTIFICATION_TYPES.map((item, index) => {
                  return <option key={index} value={item}>{t(`emails.notificationTypes.${item}`)}</option>
                })}

              </select>
            </SelectCont2>}

          {selectedFilter === 'cedula' &&
            <SelectCont2>
              <label htmlFor="">Ingresa la cédula</label>
              <input value={filterValue.cedula} onChange={e => handleInputCedulaChange(e.target.value)} />
            </SelectCont2>}
        </Flex>

        <Button disabled={isLoading} onClick={() => handleButtonClick()}>{t('emails.search')}</Button>
      </SpaceBetween>

    </BlueCont >
  );
};
const BlueCont = styled.div`
  border-radius: 10px;
  flex-grow: 1;
  padding: 0 20px;
  background-color: ${lightBackgroundBlue};
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  `;
const SpaceBetween = styled.div`
display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SelectCont = styled.div`
  margin: 5px 20px 5px 0;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  justify-content: space-between;
  /* border: 1px solid; */

  &>label{
    font-size: .7rem;
  }

  & > select,
  & > input {
    margin: 0 0 5px;
    height: 44px;
    font-size: 1rem;
    border: none;
    padding: 10px 10px;
    flex-grow: 1;
    max-width: 255px;
    background-color: white;
  }
`;
const SelectCont2 = styled(SelectCont)`
  max-width: 235px;
`;
const Button = styled.button`
  border-radius: 50px;
  margin: 0;
  display: block;
  min-width: 30px;
  font-size: 13px;
  padding: 8px 16px;
  color: white;
  border: 2px solid ${props => props.disabled ? darkBorder : primary};
  background-color: ${props => props.disabled ? darkBorder : primary};
  transform: translateY(10px);
`;
//
//filters
//
const FILTERS: EmailFiltersTypes[] = ['', 'cedula', 'notification_type']
const NOTIFICATION_TYPES: NotificationTypes[] = ['Delivery', 'Bounce', 'Complaint', 'Pending']

export default EmailsForm;