import { primary } from 'library/colors';
import React from 'react';
import styled from 'styled-components';

const Approval = () => {
  return (
    <Cont></Cont>
  );
};
const Cont = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;

  position: absolute;
  right: 50%;
  bottom: 90px;
  transform: translateX(50%);
  background-color: ${primary};

  &:before{
    content: '';
    position: absolute;
    top: 7px;
    right: 20px;
    width: 20px;
    height: 35px;
    border: solid white;
    border-width: 0 5px 5px 0;
    transform: rotate(45deg) ;
  }
  
`;
export default Approval;