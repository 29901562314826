import {
  DataVerification,
  DataModification,
  Extension,
  ScenarioEnd,
  SpeechToText,
  SuccessfulCall,
  TextToSpeech,
  InteractiveMenu,
  SendSMS,
  CallToQueue,
  FunctionRequest,
  ContinueScenario,
  CallRecordings,
  CallForwarding,
  HttpRequest,
  Player,
  Pause,
  SendingEmail,
  CallProperties,
  AnswerRecognition,
  CallTagging,
} from '../../../../icons';

export const items = [
  {
    text: 'Data Verification',
    Icon: DataVerification,
    type: 'dataVerification',
  },
  {
    text: 'Data Modification',
    Icon: DataModification,
    type: 'dataModification',
  },
  {
    text: 'Speech to Text',
    Icon: SpeechToText,
    type: 'speechToText',
  },
  {
    text: 'Text To Speech',
    Icon: TextToSpeech,
    type: 'textToSpeech',
  },
  { text: 'Scenery End', Icon: ScenarioEnd, type: 'sceneryEnd' },
  { text: 'Interactive Menu', Icon: InteractiveMenu, type: 'interactiveMenu' },
  { text: 'Successful Call', Icon: SuccessfulCall, type: 'successfulCall' },
  { text: 'Extension', Icon: Extension, type: 'extension' },
  { text: 'Send SMS', Icon: SendSMS, type: 'sendSMS' },
  { text: 'Call to Queue', Icon: CallToQueue, type: 'callToQueue' },
  { text: 'Function Request', Icon: FunctionRequest, type: 'functionRequest' },
  {
    text: 'Continue Scenario',
    Icon: ContinueScenario,
    type: 'continueScenario',
  },
  { text: 'Call Recordings', Icon: CallRecordings, type: 'callRecordings' },
  { text: 'Call Forwarding', Icon: CallForwarding, type: 'callForwarding' },
  { text: 'Http Request', Icon: HttpRequest, type: 'httpRequest' },
  { text: 'Player', Icon: Player, type: 'player' },
  { text: 'Pause', Icon: Pause, type: 'pause' },
  { text: 'Sending Email', Icon: SendingEmail, type: 'sendingEmail' },
  { text: 'Call Properties', Icon: CallProperties, type: 'callProperties' },
  {
    text: 'Answer Recognition',
    Icon: AnswerRecognition,
    type: 'answerRecognition',
  },
  { text: 'Call Tagging', Icon: CallTagging, type: 'callTagging' },
];
