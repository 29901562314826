import {
  getSMSCampaignByID,
  getSMSCampaigns,
  postSMSCampaign,
  postSMSCampaignContacs,
  putChangeSMSCampaignStatus,
} from 'helpers/fakebackend_helper';
import { ISMSCampaign } from 'library/interfaces/smsCampaignInterface';
import { groupContactsInArr } from 'library/services/campaignFormServices';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { IError } from 'store/reports/reducer';
import {
  getSMSCampaignByIdFail,
  getSMSCampaignByIdSuccess,
  getSMSCampaignsFail,
  getSMSCampaignsSuccess,
  postSMSCampaignFail,
  postSMSCampaignSuccess,
  postSMSContactsFinished,
  putChangeSMSCampaignStatusFail,
  putChangeSMSCampaignStatusSuccess,
  SMSContactGroupsAction,
  SMSContactGroupsFail,
  SMSContactGroupsSuccess,
} from './actions';
import {
  GET_SMS_CAMPAIGNS,
  GET_SMS_CAMPAIGN_BY_ID,
  IGetSMSCampaignById,
  IPostSMSCampaign,
  IPostSMSContactGroups,
  IPutChangeSMSCampaignStatus,
  POST_SMS_CAMPAIGN,
  POST_SMS_CONTACT_GROUP,
  PUT_RUN_SMS_CAMPAIGN,
  PUT_STOP_SMS_CAMPAIGN,
} from './actionTypes';

function* onGetSMSCampaigns() {
  try {
    const SMSCampaigns: ISMSCampaign[] = yield call(getSMSCampaigns);
    yield put(getSMSCampaignsSuccess(SMSCampaigns.reverse()));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the campaigns',
      details: `${e}`,
    };
    yield put(getSMSCampaignsFail(error));
  }
}

function* onGetSMSCampaignById({
  payload: { SMSCampaignId },
}: IGetSMSCampaignById) {
  try {
    const SMSCampaign: ISMSCampaign = yield call(
      getSMSCampaignByID,
      SMSCampaignId,
    );
    yield put(getSMSCampaignByIdSuccess(SMSCampaignId, SMSCampaign));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the campaign',
      details: `${e}`,
    };
    yield put(getSMSCampaignByIdFail(SMSCampaignId, error));
  }
}

function* onPostSMSCampaign({ payload: { SMSCampaign } }: IPostSMSCampaign) {
  try {
    const { name, contacts } = SMSCampaign;
    const SMSCampaignId: string = yield call(postSMSCampaign, { name });
    const newSMSCampaign: ISMSCampaign = yield call(
      getSMSCampaignByID,
      SMSCampaignId,
    );

    // contactLoad doesn't exist on campaign, is undefined
    // then, below initialize contactLoad to handle the percent of contact load
    newSMSCampaign.contactLoad = {
      isLoading: false,
      loadPercentage: 0,
      postContactsGroupNumberOfError: [],
    };
    // first contacts_count is 0, then set the value = contacts.length
    newSMSCampaign.contacts_count = contacts.length;
    // insert the new campaign on store to render it
    yield put(postSMSCampaignSuccess(newSMSCampaign));

    // split contacts in array groups of n contacts inside the array
    const contactGroups = groupContactsInArr(contacts, 20);

    yield put(SMSContactGroupsAction(SMSCampaignId, contactGroups));

    // yield call(onPostContactsToSMSCampaign, SMSCampaignId, contactGroups);
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong creating the campaigns',
      details: `${e}`,
    };
    yield put(postSMSCampaignFail(error));
  }
}

function* onPostContactsToSMSCampaign({
  payload: { SMSCampaignId, contacts },
}: IPostSMSContactGroups) {
  let index = 1;
  const total = contacts.length;
  for (const contactArr of contacts) {
    try {
      yield call(postSMSCampaignContacs, SMSCampaignId, {
        contacts: contactArr,
      });

      const percent: number = Math.round((index / total) * 100);
      yield put(SMSContactGroupsSuccess(SMSCampaignId, percent));
    } catch (e) {
      const percent: number = Math.round((index / total) * 100);
      yield put(SMSContactGroupsFail(SMSCampaignId, percent, contactArr));
    }
    index++;
  }
  yield delay(1000);
  yield put(postSMSContactsFinished(SMSCampaignId));
}

function* onPutChangeSMSCampaignStatus({
  payload: { action, SMSCampaignId, actionType },
}: IPutChangeSMSCampaignStatus) {
  try {
    yield call(putChangeSMSCampaignStatus, SMSCampaignId, action);

    const smsCampaignUpdated: ISMSCampaign = yield call(
      getSMSCampaignByID,
      SMSCampaignId,
    );

    yield put(
      putChangeSMSCampaignStatusSuccess(
        SMSCampaignId,
        smsCampaignUpdated,
        `${actionType}_SUCCESS`,
      ),
    );
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong updating the campaign status',
      details: `${e}`,
    };
    yield put(
      putChangeSMSCampaignStatusFail(
        SMSCampaignId,
        error,
        `${actionType}_FAIL`,
      ),
    );
  }
}

function* smsCampaignSaga() {
  yield takeEvery(GET_SMS_CAMPAIGNS, onGetSMSCampaigns);
  yield takeEvery(GET_SMS_CAMPAIGN_BY_ID, onGetSMSCampaignById);
  yield takeEvery(POST_SMS_CAMPAIGN, onPostSMSCampaign);
  yield takeEvery(POST_SMS_CONTACT_GROUP, onPostContactsToSMSCampaign);
  yield takeEvery(
    [PUT_RUN_SMS_CAMPAIGN, PUT_STOP_SMS_CAMPAIGN],
    onPutChangeSMSCampaignStatus,
  );
}
export default smsCampaignSaga;
