import React, { FC } from 'react';
import styled from 'styled-components';

import Container from '../atoms/container';
import SubTitle from '../atoms/subTitle';
import SpeedGraph from '../atoms/speedGraph';
import { useTranslation } from 'react-i18next';

interface IServiceLevelProps {
  porcent: number;
}
const ServiceLevel: FC<IServiceLevelProps> = ({porcent}) => {
  const {t} = useTranslation('summarize')
  return (
    <Container padding="20px">
      <SubTitle sub={t('last24hs')}>{t('serviceLevel')} (%)</SubTitle>
      <GraphCont>
        <SpeedGraph porcent={porcent} />
      </GraphCont>
      <FlexCont>
        <Span color="#d78480">{t('less')} 40%</Span>
        <Span color="#ff9904">40% - 70%</Span>
        <Span color="#85ce5f">{t('more')} 70%</Span>
      </FlexCont>
    </Container>
  );
};
const GraphCont = styled.div`
    padding: 0 20px;
`;
const FlexCont = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Span = styled.span<ISpan>`
  font-size: 0.62rem;
  color: ${props => props.color};
`;
interface ISpan {
  color: string;
}
export default ServiceLevel;
