import React, { useState } from 'react';
import Controls from '../foundation/Controls';

import './OrderBy.css';

function OrderBy() {
  const [orderBy, setOrderBy] = useState('fecha');

  return (
    <div className="incoming-order-by">
      <label>Ordena por:</label>
      <Controls.Select
        onChange={e => {
          setOrderBy(e.target.value);
        }} 
        value={orderBy}
        options={[{ value: 'fecha', text: 'Fecha' }]}
      />
    </div>
  );
}

export default OrderBy;
