import {
  AGENT_ACTION_FAIL,
  GET_AGENTS,
  GET_AGENTS_SUCCESS,
  GET_USER,
  GET_AGENT_SUCCESS,
  ADD_NEW_AGENT,
  ADD_AGENT_SUCCESS,
  UPDATE_AGENT,
  UPDATE_AGENT_SUCCESS,
  DELETE_AGENT,
  DELETE_AGENT_SUCCESS,
} from './actionTypes';

export const agentActionFail = error => ({
  type: AGENT_ACTION_FAIL,
  payload: error,
});

export const getAgents = groups => ({
  type: GET_AGENTS,
  payload: groups,
});

export const getAgentsSuccess = agents => ({
  type: GET_AGENTS_SUCCESS,
  payload: agents,
});

export const getUser = picture => ({
  type: GET_USER,
  payload: { picture },
});

export const getAgentSuccess = agent => ({
  type: GET_AGENT_SUCCESS,
  payload: agent,
});

export const addNewAgent = (id, agent) => ({
  type: ADD_NEW_AGENT,
  payload: { id, agent },
});

export const addAgentSuccess = (id, agent) => ({
  type: ADD_AGENT_SUCCESS,
  payload: { id, agent },
});

export const updateAgent = (id, agent) => ({
  type: UPDATE_AGENT,
  payload: { id, agent },
});

export const updateAgentSuccess = (id, agent) => ({
  type: UPDATE_AGENT_SUCCESS,
  payload: { id, agent },
});

export const deleteAgent = id => ({
  type: DELETE_AGENT,
  payload: id,
});

export const deleteAgentSuccess = id => ({
  type: DELETE_AGENT_SUCCESS,
  payload: id,
});
