import React, { FC } from 'react';
import styled from 'styled-components';
import { darkText } from 'library/colors';

interface ITitleProps {
  children: string;
  padding?: string;
}
const Title: FC<ITitleProps> = ({ children, padding }) => {
  return <H2 padding={padding}>{children}</H2>;
};
const H2 = styled.h2<IH2>`
  font-weight: 600;
  font-size: 19px;
  margin: 0px auto;
  color: ${darkText};
  padding: ${props => props.padding && props.padding};
`;
interface IH2 {
  padding?: string;
}
export default Title;
