import DownloadReportButton from 'components/outbound/atoms/downloadReportButton';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import StateMenuOption from 'components/outbound/atoms/stateMenuOption';
import {
  blueGraph,
  danger,
  darkBorder,
  lightBackgroundBlue,
  primary,
} from 'library/colors';
import { ISMSCampaign, ISMSCampaignActions } from 'library/interfaces/smsCampaignInterface';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getSMSCampaignByIdAction,
  putChangeSMSCampaignStatusAction,
  SMSContactGroupsAction,
} from 'store/actions';
import { campaignAction } from 'store/outboundCampaigns/voiceCampaigns/actionTypes';
import styled from 'styled-components';
import LoadingBarChart from '../atoms/loadingBarChart';

interface IStatusAndButtonsProps {
  smsCampaign: ISMSCampaign;
}
const StatusAndButtons: FC<IStatusAndButtonsProps> = ({ smsCampaign }) => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const isUpdating = smsCampaign.isLoading;
  const isUploadingContacts = smsCampaign.contactLoad
    ? smsCampaign.contactLoad.isLoading
    : false;
  const percent = smsCampaign.contactLoad?.loadPercentage || 0;

  const contactsError =
    (!smsCampaign.contactLoad?.isLoading &&
      smsCampaign.contactLoad?.postContactsGroupNumberOfError) ||
    [];

  const STATUS_COLOR =
    smsCampaign.status === 'running'
      ? primary
      : smsCampaign.status === 'stopped'
      ? 'orange'
      : smsCampaign.status === 'completed'
      ? blueGraph
      : lightBackgroundBlue;

  const handleUpdateCampaign = () => {
    dispatch(getSMSCampaignByIdAction(smsCampaign.id));
    setShowMenu(false)
  };

  const handleChangeSMSCampaignStatus = (action: ISMSCampaignActions | campaignAction) => {
    dispatch(putChangeSMSCampaignStatusAction(action as ISMSCampaignActions, smsCampaign.id))
    setShowMenu(false)
  }

  const handlePostContactsError = () => {
    dispatch(SMSContactGroupsAction(smsCampaign.id, contactsError));
  };

  return (
    <Cont>
      {isUploadingContacts && <LoadingBarChart percent={percent} />}
      {!isUploadingContacts && contactsError.length === 0 && !isUpdating && (
        <>
          <Status bgColor={STATUS_COLOR}>{smsCampaign.status}</Status>
          <DownloadReportButton campaignId={smsCampaign.id} reportType={5}>
            Report
          </DownloadReportButton>
          <MenuButton
            id="showActionsMenu"
            onClick={() => setShowMenu(prev => !prev)}
          >
            ...
          </MenuButton>
          {showMenu && (
            <Menu>
              {smsCampaign.status === 'created' && <StateMenuOption
                label={'Iniciar'}
                action="run"
                onActionClick={handleChangeSMSCampaignStatus}
              />}
              {smsCampaign.status === 'running' && <StateMenuOption
                label={'Terminar'}
                action="stop"
                onActionClick={handleChangeSMSCampaignStatus}
              />}
              <StateMenuOption
                label="Actualizar"
                action="terminate"
                onActionClick={handleUpdateCampaign}
              />
            </Menu>
          )}
        </>
      )}
      {contactsError.length > 0 && (
        <ErrorButtonCont>
          <div>Ha ocurrido un error cargando los contactos</div>
          <ErrorButton onClick={() => handlePostContactsError()}>
            Reintentar
          </ErrorButton>
        </ErrorButtonCont>
      )}
      {isUpdating && (
        <Updating>
          <div>
            Actualizando <LoadingPoints />
          </div>
        </Updating>
      )}
    </Cont>
  );
};
const Cont = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
  position: relative;
  /* border: 1px solid; */
`;
const Status = styled.div<IStatus>`
  width: 100px;
  height: 30px;
  margin: 0 10px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  cursor: default;
  user-select: none;
  color: ${props => props.bgColor !== lightBackgroundBlue && 'white'};
  background-color: ${props => props.bgColor};
  position: relative;
`;
const Updating = styled.div`
  text-align: center;
  width: 100%;
  height: 90%;
  display: grid;
  place-items: center;
`;
const MenuButton = styled.div`
  width: 40px;
  height: 30px;
  margin-left: 5px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 35%;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;
const Menu = styled.div`
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 35px;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  border-radius: 5px;
  background-color: ${lightBackgroundBlue};
  border: 1px solid ${darkBorder};
  box-shadow: 0 0px 5px ${primary};
`;
const ErrorButtonCont = styled.div`
  /* border: 1px solid; */
  margin: 0 auto;
  width: 100%;
  display: grid;
  place-content: center;
`;
const ErrorButton = styled.button`
  width: 120px;
  height: 30px;
  font-weight: 600;
  color: ${danger};
  border: 2px solid;
  border-radius: 50px;
  margin: 5px auto 20px;
  background-color: transparent;
`;
interface IStatus {
  bgColor: string;
}
export default StatusAndButtons;
