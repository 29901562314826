import React from 'react';
import PropTypes from 'prop-types';
// import { Col, Row } from 'reactstrap';
import * as DataInfo from '../../DataInfo';
import FormCard from '../../FormCard';
import ViewRequest from '../../composible/ViewRequest';

View.propTypes = {
  data: PropTypes.object.isRequired,
};

function View({ data }) {
  return (
    <FormCard>
      <DataInfo.Switch label="Change Caller ID" value={false}>
        <DataInfo.Label>Variable</DataInfo.Label>
        <DataInfo.Content>
          {data?.changeCallerId?.label || '-'}
        </DataInfo.Content>
      </DataInfo.Switch>

      <DataInfo.Switch label="Use voice mail detection" value={false} />

      <DataInfo.Switch label="Make an HTTP request" value={true}>
        <ViewRequest data={data} />
      </DataInfo.Switch>
    </FormCard>
  );
}

export default View;
