import {
  AuthUserDataTypes,
  DELETE_AUTH_USER_DATA,
  UPDATE_AUTH_USER_DATA,
  UPDATE_AUTH_USER_EXTEN,
} from './actionTypes';

export interface IExtenListItem {
  exten: string;
  exten_name: string;
  exten_password: string;
}

export interface IUserDataRoles {
  id: string;
  name: 'agent' | 'supervisor' | 'poc';
  description: string;
}
export interface IAuthUserData {
  auth0_id: string;
  display_name: string;
  email: string;
  exten: string;
  exten_name: string;
  exten_password: string;
  options: IUserOption[];
  owner: string;
  pbx_port: string;
  pbx_url: string;
  picture: string;
  roles: IUserDataRoles[];
  user_id: string;
  exten_list: IExtenListItem[];
}
export interface IUserOption {
  id: string;
  name: string;
  order: string;
  title: string;
}
export type IAuthUserDataStore = IAuthUserData;
const INIT_STATE: IAuthUserDataStore = {
  auth0_id: '',
  display_name: '',
  email: '',
  exten: '',
  exten_name: '',
  exten_password: '',
  options: [],
  owner: '',
  pbx_port: '',
  pbx_url: '',
  picture: '',
  roles: [],
  user_id: '',
  exten_list: [],
};

const authUserData = (
  state = INIT_STATE,
  action: AuthUserDataTypes,
): IAuthUserDataStore => {
  switch (action.type) {
    case UPDATE_AUTH_USER_DATA: {
      const exten_list =
        action.payload.userData.exten_list.length === 0
          ? []
          : [
              {
                exten: action.payload.userData.exten,
                exten_name: action.payload.userData.exten_name,
                exten_password: action.payload.userData.exten_password,
              },
              ...action.payload.userData.exten_list,
            ];
      return {
        ...action.payload.userData,
        exten_list,
      };
    }
    case DELETE_AUTH_USER_DATA: {
      return {
        ...INIT_STATE,
      };
    }
    case UPDATE_AUTH_USER_EXTEN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default authUserData;
