import { IConversationEnabledChannel } from 'library/enableSpecificFeatures/conversationWorkspace';
import {
  ISessionAssigned,
  ISendHSM,
  ISendMessage,
  ISession,
} from 'library/interfaces/conversationInterfaces';
import { IGupshupAppTemplate } from 'library/interfaces/whatsappCampaignInterfaces';
import { IError } from 'store/reports/reducer';

export const GET_CONVERSATION_SESSIONS = 'GET_CONVERSATION_SESSIONS';
export const GET_CONVERSATION_SESSIONS_SUCCESS =
  'GET_CONVERSATION_SESSIONS_SUCCESS';
export const GET_CONVERSATION_SESSIONS_FAIL = 'GET_CONVERSATION_SESSIONS_FAIL';

export const GET_CLIENT_MESSAGES = 'GET_CLIENT_MESSAGES';
export const GET_CLIENT_MESSAGES_SUCCESS = 'GET_CLIENT_MESSAGES_SUCCESS';
export const GET_CLIENT_MESSAGES_FAIL = 'GET_CLIENT_MESSAGES_FAIL';

export const POST_SEND_MESSAGE = 'POST_SEND_MESSAGE';
export const POST_SEND_MESSAGE_SUCCESS = 'POST_SEND_MESSAGE_SUCCESS';
export const POST_SEND_MESSAGE_FAIL = 'POST_SEND_MESSAGE_FAIL';

export const POST_SEND_HSM = 'POST_SEND_HSM';
export const POST_SEND_HSM_SUCCESS = 'POST_SEND_HSM_SUCCESS';
export const POST_SEND_HSM_FAIL = 'POST_SEND_HSM_FAIL';

export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';

export const ADD_NEW_SESSION = 'ADD_NEW_SESSION';

export const DEL_CLOSE_SESSION = 'DEL_CLOSE_SESSION';

// only change the selectedSession on store
export const SET_SELECTED_SESSION = 'SET_SELECTED_SESSION';

export const PUT_ASSING_SESSION_TO_AGENT = 'PUT_ASSING_SESSION_TO_AGENT';
export const PUT_ASSING_SESSION_TO_AGENT_SUCCESS =
  'PUT_ASSING_SESSION_TO_AGENT_SUCCESS';
export const PUT_ASSING_SESSION_TO_AGENT_FAIL =
  'PUT_ASSING_SESSION_TO_AGENT_FAIL';

// client data inicia como objeto vacío {}
// con esta action si viene data del cliente actualiza este objeto con la data del cliente
// recibe la id de sesión y la data para actualizar el store
export const UPDATE_WA_SESION_CLIENT_DATA = 'UPDATE_WA_SESION_CLIENT_DATA';

export type ConversationActionTypes =
  | IGetConversationSessions
  | IGetConversationSessionsSuccess
  | IGetConversationSessionsFail
  | IGetClientMessages
  | IGetClientMessagesSuccess
  | IGetClientMessagesFail
  | IDelCloseSession
  | ISetSelectedSession
  | IPostSendMessage
  | IPostSendMessageSuccess
  | IPostSendMessageFail
  | IAddNewMessage
  | IAddNewSession
  | IPostSendHSM
  | IPostSendHSMSuccess
  | IPostSendHSMFail
  | IPutAssingSessionToAgent
  | IPutAssingSessionToAgentSuccess
  | IPutAssingSessionToAgentFail
  | IUpdateWaSesionClientData;

export interface IGetConversationSessions {
  type: typeof GET_CONVERSATION_SESSIONS;
  payload: { channels: IConversationEnabledChannel[] };
}

export interface IGetConversationSessionsSuccess {
  type: typeof GET_CONVERSATION_SESSIONS_SUCCESS;
  payload: {
    sessionsStore: { waLastKey: string | null; sessions: ISession[] };
    sessionsAssignedStore: {
      lastKey: string | null;
      sessions: ISessionAssigned[];
    };
  };
}

export interface IGetConversationSessionsFail {
  type: typeof GET_CONVERSATION_SESSIONS_FAIL;
  payload: { error: IError };
}

export interface IGetClientMessages {
  type: typeof GET_CLIENT_MESSAGES;
  payload: {
    channel: string;
    source: string;
    sessionId: string;
    templates: IGupshupAppTemplate[];
  };
}

export interface IGetClientMessagesSuccess {
  type: typeof GET_CLIENT_MESSAGES_SUCCESS;
  payload: { message: { [key: string]: string | number }[]; sessionId: string };
}

export interface IGetClientMessagesFail {
  type: typeof GET_CLIENT_MESSAGES_FAIL;
  payload: { error: IError; sessionId: string };
}

export interface IPostSendMessage {
  type: typeof POST_SEND_MESSAGE;
  payload: { sessionId: string; message: ISendMessage };
}

export interface IPostSendMessageSuccess {
  type: typeof POST_SEND_MESSAGE_SUCCESS;
  payload: { sessionId: string; message: ISendMessage };
}

export interface IPostSendMessageFail {
  type: typeof POST_SEND_MESSAGE_FAIL;
  payload: { sessionId: string; error: IError };
}

export interface IPostSendHSM {
  type: typeof POST_SEND_HSM;
  payload: { campaignId: string; hsm: ISendHSM };
}

export interface IPostSendHSMSuccess {
  type: typeof POST_SEND_HSM_SUCCESS;
  payload: { messageId: string };
}

export interface IPostSendHSMFail {
  type: typeof POST_SEND_HSM_FAIL;
  payload: { error: IError };
}

export interface IAddNewMessage {
  type: typeof ADD_NEW_MESSAGE;
  payload: { newMessage: { [key: string]: string | number } };
}

export interface IAddNewSession {
  type: typeof ADD_NEW_SESSION;
  payload: { session: ISession };
}

export interface IDelCloseSession {
  type: typeof DEL_CLOSE_SESSION;
  payload: { sessionId: string };
}

export interface ISetSelectedSession {
  type: typeof SET_SELECTED_SESSION;
  payload: { sessionId: string };
}

export interface IPutAssingSessionToAgent {
  type: typeof PUT_ASSING_SESSION_TO_AGENT;
  payload: { session: ISession };
}

export interface IPutAssingSessionToAgentSuccess {
  type: typeof PUT_ASSING_SESSION_TO_AGENT_SUCCESS;
  payload: { sessionAssigned: ISessionAssigned };
}

export interface IPutAssingSessionToAgentFail {
  type: typeof PUT_ASSING_SESSION_TO_AGENT_FAIL;
  payload: { error: IError };
}

export interface IUpdateWaSesionClientData {
  type: typeof UPDATE_WA_SESION_CLIENT_DATA;
  payload: {
    sessionId: string;
    clientData: { [key: string]: string | number };
  };
}
