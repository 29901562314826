import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { contactGreen, darkBorder, popUpBackground, primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ILoadingBarChartProps {
  percent?: number;
}
const LoadingBarChart: FC<ILoadingBarChartProps> = ({ percent = 0 }) => {
  return (
    <Cont>
      <BarCont>
        <Bar percent={percent}></Bar>
        <Text>Cargando Contactos <LoadingPoints /></Text>
      </BarCont>
    </Cont>
  );
};
const Cont = styled.div`
  /* border: 1px solid; */
  flex-grow: 1;
  margin: 0 auto;
  max-width: 200px;
  height: 100%;
  min-height: 30px;
  display: grid;
  place-items: center;
`;
const BarCont = styled.div`
  width: 100%;
  height: 15px;
  border-radius: 20px;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  background-color: ${darkBorder};
`;
const Bar = styled(BarCont)<IBar>`
  background-color: ${primary};
  position: relative;
  right: 100%;
  transform: translate(${props=>props.percent}%);
  transition: transform .5s;
`;
const Text = styled.div`
  position: absolute;
  font-size: .7rem;
  font-weight: 500;
  color: white;
`;
interface IBar {
  percent: number;
}
export default LoadingBarChart;