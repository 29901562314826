import React, { FC } from "react";
import styled from "styled-components";

import Container from "../atoms/container";
import LostCalls from "../molecules/lostCalls";
import SubTitle from "../atoms/subTitle";
import ProgressBarGraph from "../atoms/progressBarGraph";
import HorizontalBarGraph from "../atoms/horizontalBarGraph";
import { useTranslation } from "react-i18next";

interface ILostCallsWithGraph{
    abandoned_calls: number;
    incoming_calls: number;
    gridColumn?: string
}
const LostCallsWithGraph: FC<ILostCallsWithGraph> = ({gridColumn, abandoned_calls, incoming_calls}) =>{
    const {t} = useTranslation('summarize')
    const porcent = Math.round((abandoned_calls / incoming_calls)*100)
    return(
        <Container padding="20px" gridColumn={gridColumn}>
            <SubTitle sub={t('last24hs')} >{t('abandonedCalls')}</SubTitle>
            <Graph>
                <div>
                <ProgressBarGraph dangerBg porcent={porcent ? porcent : 0} maxWidth={135}/>
                </div>                
                <HorizontalBarGraph lostCall={abandoned_calls} totalCalls={incoming_calls} padding='0px'/>
            </Graph>
        </Container>
    )
}
const Graph = styled.div`
    display: grid;
    align-items: end;
    grid-template-columns: 2fr 3fr;
`

export default LostCallsWithGraph