import { lightBackgroundBlue, lightGreen, primary } from 'library/colors';
import { IDataSources } from 'library/interfaces/smsCampaignInterface';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DropZone from '../atoms/dropzone';
import FormButtons from '../molecules/formButtons';
import { IFile } from '../molecules/FormLoadingFiles';

interface ICreateSmsCampaignFirstStageProps {
  inputRef: React.RefObject<HTMLInputElement>;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  files: IFile[];
  setFiles: Function;
  sources: IDataSources;
  setSources: ({
    sheet,
    numbersColumn,
    columnsToMessageVariables,
  }: IDataSources) => void;
}
const CreateSmsCampaignFirstStage: FC<ICreateSmsCampaignFirstStageProps> = ({
  inputRef,
  textAreaRef,
  files,
  setFiles,
  sources,
  setSources,
}) => {
  const { t } = useTranslation('outbound');

  const [fileHasMessage, setFileHasMessage] = useState<boolean | null>(null);

  let sheetData =
    files.length > 0
      ? files[0].sheets.filter(item => {
          if (item.name === sources.sheet) return item;
        })
      : [];

  const handleSelectSheetChange = (value: string) => {
    setSources({
      ...sources,
      sheet: value,
      numbersColumn: '',
    });
  };

  const handleSelectColumnChange = (value: string) => {
    setSources({
      ...sources,
      numbersColumn: value,
    });
  };

  const handleFileHasMessageRadioSelect = (bool: boolean) => {
    setFileHasMessage(bool);
    if(bool) return 
    setSources({
      ...sources,
      columnWithMessage: '',
    });
  };

  const handleSetColumnWithMessage = (columnName: string, isChecked: boolean) => {
    const column = isChecked ? columnName : ''
    setSources({
      ...sources,
      columnWithMessage: column,
    });
  };

  const handleSelectMessageVariables = (variableName: string) => {
    const variables = sources.columnsToMessageVariables;
    const index = sources.columnsToMessageVariables.findIndex(
      name => name === variableName,
    );
    if (index !== -1) {
      variables.splice(index, 1);
    } else {
      variables.push(variableName);
    }

    setSources({
      ...sources,
      columnsToMessageVariables: variables,
    });
  };

  return (
    <Cont>
      <FlexCont>
        <InputGroup>
          <label>{t('SMScampaigns.SMSCreateCampaignForm.campaignName')}</label>
          <input type="text" name="name" ref={inputRef} />
        </InputGroup>

        <DropZone files={files} setFiles={setFiles} />

        <FilesButtonsCont>
          {files.map((file, index) => {
            return (
              <FileButton
                key={index}
                onClick={
                  e =>
                    e.preventDefault() /* handleFileButtonClick(index, files, e) */
                }
              >
                {file.fileName}
                <i className="bx bx-trash"></i>
              </FileButton>
            );
          })}
        </FilesButtonsCont>
      </FlexCont>

      <RightBlock>
        {files.length > 0 && <label>Fuente de contactos</label>}
        <Flex>
          {files.length > 0 && (
            <SheetSelect
              name="sheetsName"
              value={sources.sheet}
              onChange={e => handleSelectSheetChange(e.target.value)}
            >
              <option value="">
                {t('campaigns.createCampaignForm.contactSources.selectSheet')}
              </option>
              {files[0].sheetsName.map((sheetName, index) => {
                return (
                  <option key={index} value={sheetName}>
                    {sheetName}
                  </option>
                );
              })}
            </SheetSelect>
          )}
          {sources.sheet !== '' && (
            <ColumnSelect
              name="column"
              value={sources.numbersColumn}
              onChange={e => handleSelectColumnChange(e.target.value)}
            >
              <option value="">
                {t('campaigns.createCampaignForm.contactSources.column')}
              </option>
              {sheetData[0] &&
                sheetData[0].columnsToSelect.map((column, index) => {
                  return (
                    <option key={index} value={column.name}>
                      {column.name}
                    </option>
                  );
                })}
            </ColumnSelect>
          )}
        </Flex>

        {sources.numbersColumn !== '' && fileHasMessage !== false && (
          <FileHasMessageCont>
            <div>El archivo contiene los mensajes a enviar?</div>
            <RadioFormCont>
              <RadioCont>
                <input
                  type="radio"
                  name="message"
                  id="yes"
                  onClick={() => handleFileHasMessageRadioSelect(true)}
                />
                <label htmlFor="yes">Si</label>
              </RadioCont>
              <RadioCont>
                <input
                  type="radio"
                  name="message"
                  id="no"
                  onClick={() => handleFileHasMessageRadioSelect(false)}
                />
                <label htmlFor="no">No</label>
              </RadioCont>
            </RadioFormCont>
          </FileHasMessageCont>
        )}

        {fileHasMessage && (
          <>
            <Indication>
              Selecciona la columna que contiene los mensajes
            </Indication>
            <ColumnsToSelectCont>
              {sheetData[0].columnsToSelect.map((column, columnIndex) => {
                if (column.name === sources.numbersColumn) return;
                return (
                  <SelectableColumn key={columnIndex}>
                    <input
                      type="checkbox"
                      id={column.name}
                      onChange={e => handleSetColumnWithMessage(column.name, e.target.checked)}
                    />
                    <label htmlFor={column.name}>{column.name}</label>
                  </SelectableColumn>
                );
              })}
            </ColumnsToSelectCont>
          </>
        )}

        {fileHasMessage === false && (
          <>
            <div>
              {sources.numbersColumn !== '' && (
                <>
                  <Indication>
                    Para incluir los datos de alguna columna dentro del mensaje,
                    debes seleccionar la columna en la lista y coloca el nombre
                    exacto de la columna en el mensaje, entre <b>{'${ }'}</b> y
                    sin espacios.
                  </Indication>

                  <ColumnsToSelectCont>
                    {sheetData[0].columnsToSelect.map((column, columnIndex) => {
                      if (column.name === sources.numbersColumn) return;
                      return (
                        <SelectableColumn key={columnIndex}>
                          <input
                            type="checkbox"
                            id={column.name}
                            onChange={e =>
                              handleSelectMessageVariables(column.name)
                            }
                          />
                          <label htmlFor={column.name}>{column.name}</label>
                        </SelectableColumn>
                      );
                    })}
                  </ColumnsToSelectCont>
                </>
              )}
            </div>
            {sources.numbersColumn !== '' && (
              <>
                <TextArea ref={textAreaRef}></TextArea>
              </>
            )}
          </>
        )}
      </RightBlock>
    </Cont>
  );
};
const Cont = styled.div`
  height: 420px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  background-color: ${lightBackgroundBlue};

  & > :last-child {
    flex-grow: 1;
    /* border: 1px solid; */
  }
`;
const InputGroup = styled.div`
  margin-bottom: 20px;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;

  flex-direction: column;

  & > input {
    width: 100%;
    font-size: 1rem;
    padding: 10px 0;
    border: none;
  }
`;
const FlexCont = styled.div`
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 50%;

  & > :nth-child(2) {
    /* border: 1px solid; */
    transform: scale(0.9) translate(-25px);
  }
`;
const FilesButtonsCont = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const FileButton = styled.button`
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid ${lightGreen};

  &:hover {
    color: red;
    border: 1px solid red;
    text-decoration: line-through;
  }

  & > i {
    line-height: 0px;
    font-size: 1.2rem;
    font-weight: 00;
    display: inline-block;
    margin-left: 5px;
    transform: translateY(3px);
  }
`;
const RightBlock = styled.div`
  padding-left: 20px;

  & > label {
    font-size: 0.7rem;
    font-weight: 500;
  }
`;
const Flex = styled.div`
  display: flex;
  margin: 8px 0;
`;
const SheetSelect = styled.select`
  width: 50%;
  margin-right: 20px;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const ColumnSelect = styled.select`
  flex-grow: 1;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;

const FileHasMessageCont = styled.div`
  margin: 40px 0 35px;
  text-align: center;
`;
const RadioFormCont = styled.div`
  display: flex;
  justify-content: center;
`;
const RadioCont = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;

  & > label {
    margin: 0 5px;
  }
`;
const ColumnsToSelectCont = styled.div`
  height: 100px;
  margin: 0 0 20px;
  padding: 0px 0 0 40px;
  border-radius: 10px;
  background-color: white;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const SelectableColumn = styled.div`
  display: flex;
  margin: 5px 0 10px;

  & > label {
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 10px;
  }
`;

const Indication = styled.div`
  margin: 10px 0 10px;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 110px;
  resize: none;
  font-size: 1rem;
  border-radius: 10px;
  border: 2px solid ${primary};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
export default CreateSmsCampaignFirstStage;
