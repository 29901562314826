import { Node } from 'reactflow';
import { CustomNodeTypes, IHandleData, INodeData } from './nodeTypes';

/**
 * this function get the color of the node source
 * to set the value of input color
 *
 * @param sources the array of node sources
 * @param sourceId the ID of source to get color
 */
export const getColorFromSource = (
  sources: IHandleData[] = [],
  sourceId: string,
): string => {
  return sources.find(src => src.id === sourceId)?.color || 'black';
};

/**
 * update the color of edge, source and label of nodes
 *
 * @param sourceId the ID of source to get color
 * @param color the new color
 * @param selectedNode the current node selecte on modal
 * @param setSelectedNode react set state function
 * @param handleUpdateEdgeColor function on flow index to update edge colors
 * @returns void
 */

export const updateSourceAndEdgeColor = (
  sourceId: string,
  color: string,
  selectedNode: Node<INodeData, CustomNodeTypes>,
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void,
  handleUpdateEdgeColor: (
    nodeId: string,
    sourceId: string,
    color: string,
  ) => void,
) => {
  const { sources } = selectedNode.data;
  if (!sources) return;

  const newSources = sources.map(source => {
    if (source.id === sourceId) {
      return { ...source, color: color };
    }
    return source;
  });
  const editedNode: Node<INodeData, CustomNodeTypes> = {
    ...selectedNode,
    data: {
      ...selectedNode.data,
      sources: newSources,
    },
  };
  setSelectedNode(editedNode);
  handleUpdateEdgeColor(selectedNode.id, sourceId, color);
};
