import axios from 'axios';
import toast from 'react-hot-toast';

const { NODE_ENV, REACT_APP_BACK_SMARTBOT, REACT_APP_DEV_BACK_SMARTBOT } =
  process.env;

//apply base url for axios
export const API_URL =
  NODE_ENV === 'development'
    ? REACT_APP_DEV_BACK_SMARTBOT
    : REACT_APP_BACK_SMARTBOT;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  response => response,
  function (error) {
    if (error.response.status === 401) {
      if (error.response.data.includes('UnauthorizedError: jwt expired')) {
        toast.error(
          'Su sesión ha expirado.\nPuede que reciba data incompleta.\nCierre sesión e inicie nuevamente.',
          { duration: 30000 },
        );
      } else {
        toast.error('No autorizado.\nCierre sesión e inicie nuevamente.', {
          duration: 30000,
        });
      }
    }
    return Promise.reject(error);
  },
);

export async function get(url, config = {}) {
  const token = localStorage.getItem('auth0_token');
  config.headers = {
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
  };
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  const token = localStorage.getItem('auth0_token');
  config.headers = {
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
  };
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function postNoDestructuring(url, data, config = {}) {
  const token = localStorage.getItem('auth0_token');
  config.headers = {
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
  };
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  const token = localStorage.getItem('auth0_token');
  config.headers = {
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
  };
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  const token = localStorage.getItem('auth0_token');
  config.headers = {
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
  };
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
