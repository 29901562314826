import React from 'react';
import { Card, CardBody, CardTitle, Col } from 'reactstrap';
import PropTypes from 'prop-types';

function FormContainer({ title, children }) {
  return (
    <Card outline color="primary">
      <CardBody>
        <CardTitle>{title}</CardTitle>
        {children}
      </CardBody>
    </Card>
  );
}

FormContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
};

export default FormContainer;
