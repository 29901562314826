import { darkBorder, primary } from 'library/colors';
import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface IInfoContProps {
  children: React.ReactNode
  translateY?: string
}
const InfoCont: FC<IInfoContProps> = ({ children, translateY = '0%' }) => {
  return (
    <Cont translateY={translateY}>{children}</Cont>
  );
};
const enterAnimation = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;
const Cont = styled.div<ICont>`
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 500px;
  max-height: 600px;
  overflow-y: scroll;
  padding: 10px 20px;
  border-radius: 10px;
  color: black;
  background-color:#fff;
  transform: translateY(${props => props.translateY});
  box-shadow: 0px 0px 5px  ${darkBorder};
  animation: ${enterAnimation} .2s;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
interface ICont {
  translateY: string
}
export default InfoCont;