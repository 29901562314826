import * as React from "react"

export const Extension = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M22 31.25c-3.62 0-8.07-2.08-11.87-5.88C4.27 19.54 2.48 12.19 6 8.64l1.32-1.28a3.21 3.21 0 0 1 4.55 0l2.43 2.43a3.2 3.2 0 0 1 .94 2.28 3.16 3.16 0 0 1-.94 2.27l-1.93 1.93a21.6 21.6 0 0 0 3 3.79 21.09 21.09 0 0 0 3.79 3l1.93-1.92a3.2 3.2 0 0 1 4.54 0l2.44 2.43a3.22 3.22 0 0 1 0 4.55l-1.28 1.28A6.66 6.66 0 0 1 22 31.25Zm-12.41-23a1.37 1.37 0 0 0-1 .41L7.34 9.94c-2.73 2.73-.87 9.2 4.06 14.13a18.77 18.77 0 0 0 8.14 5c2.58.68 4.71.35 6-.93l1.28-1.28a1.35 1.35 0 0 0 .4-1 1.38 1.38 0 0 0-.4-1l-2.44-2.44a1.39 1.39 0 0 0-1.95 0L20 24.9a.91.91 0 0 1-1.11.15 23 23 0 0 1-4.77-3.69 23.31 23.31 0 0 1-3.69-4.77.91.91 0 0 1 .15-1.11L13 13a1.35 1.35 0 0 0 .4-1 1.38 1.38 0 0 0-.4-1l-2.43-2.34a1.4 1.4 0 0 0-.98-.41ZM22.42 17h-.12a.92.92 0 0 1-.8-1L23 3.85a.92.92 0 0 1 1.83.24l-1.5 12.09a.92.92 0 0 1-.91.82ZM27.66 17h-.11a.91.91 0 0 1-.8-1l1.54-12.1a.92.92 0 1 1 1.82.24l-1.54 12.04a.92.92 0 0 1-.91.82Z"
    />
    <path
      fill="currentColor"
      d="M32.25 8.26H20a.92.92 0 1 1 0-1.84h12.25a.92.92 0 1 1 0 1.84ZM31.68 13.66H19.46a.92.92 0 1 1 0-1.84h12.22a.92.92 0 1 1 0 1.84Z"
    />
  </svg>
)

