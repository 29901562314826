import React, { FC } from 'react';
import styled from 'styled-components';
import Container from '../atoms/container';
import SpeedGraph from '../atoms/speedGraph';
import { ISummarizeAgent } from 'library/interfaces/summarizeInterface';
import { useTranslation } from 'react-i18next';
import { secondary } from 'library/colors';

interface IAgentResumeProps {
  agent: ISummarizeAgent
}
const AgentResume: FC<IAgentResumeProps> = ({ agent }) => {
  const { t } = useTranslation('agentsLive')

  const colors: { [key: string]: string } = {
    "incall": "#85ce5f",
    "ringing": "#4cb3ed",
    "onhold": "#c09405",
    "available": "#053855",
    "unavailable": secondary
  }
  return (
    <Container
      margin="0 auto 20px"
      padding="5px 10px"
    >
      <GridCont>
        <ImgCont>
          <img src={agent.picture} alt={agent.name} />
        </ImgCont>
        <div >{agent.name}</div>
        <div >{agent.exten}</div>
        <StateCont color={colors[agent.state]} >{t(`${agent.state}`)}</StateCont>
        <Center >
          <div>{t(`answered`)}: {agent.serviceLevel.answered}</div>
          <div>{t(`unanswered`)}: <Span isRed={agent.serviceLevel.unanswered > 0}>{agent.serviceLevel.unanswered}</Span></div>
        </Center>
        <Center >{t(`conectedTime`)}: {agent.serviceLevel.availableDuration}</Center>
        <Center >{t(`serviceLevel`)}: {Math.round(agent.serviceLevel.serviceLevel * 100)}%</Center>
        <GraphCont>
          <SpeedGraph porcent={Math.round(agent.serviceLevel.serviceLevel * 100)} />
        </GraphCont>
      </GridCont>
    </Container>
  );
};
const GridCont = styled.div`
    display: grid;
    grid-template-columns: 45px 200px 80px 150px  repeat(3, 1fr);
    align-items: center;

    @media(min-width: 1600px){
      grid-template-columns: 60px 250px 130px 200px  repeat(3, 1fr) 70px;
    }
  @media(min-width: 1900px) {
    padding: 0px 20px;
    font-size: 1rem;
    
  }
  `;
const ImgCont = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;

  & > img {
    width: 100%;
  }
`;
const StateCont = styled.div<IStateCont>`
  background-color: ${props => props.color};
  text-align: center;
  margin-right: 20px;
  border-radius: 5px;
  padding: 10px 0;
  color: #fff;
  font-weight: 700;
`;
const Center = styled.div`
  text-align: center;
`;
const Span = styled.span<ISpan>`
  color: ${props=>props.isRed && 'red'};
  font-weight: ${props=>props.isRed && 500};
`;
const GraphCont = styled.div`
  padding: 0 20px;
  flex-grow: 1;
  display: none;

  @media(min-width: 1600px) {
    display: block;
    width: 140px;
    height: 10px;
    transform: scale(.7) translate(-50px, -35px);
  }
`;

interface IStateCont {
  color: string
}
interface ISpan {
  isRed: boolean
}
export default AgentResume;