import IStore from 'library/interfaces/storeInterface';
import { IWaCampaign } from 'library/interfaces/whatsappCampaignInterfaces';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import WACampaign from './waCampaign';

const WaCampaignsLoadingContactsCont = () => {
  const postContactsOf = useSelector<IStore, IWaCampaign[] | undefined>(
    store => store.waCampaigns.postContactsOf,
  );
  return (
    <Cont
      display={postContactsOf && postContactsOf.length > 0 ? 'block' : 'none'}
    >
      {postContactsOf &&
        postContactsOf.map((campaign, index) => {
          return <WACampaign key={index} campaign={campaign} />;
        })}
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  /* border: 1px solid; */
  display: ${props => props.display};
`;
interface ICont {
  display: 'block' | 'none';
}
export default WaCampaignsLoadingContactsCont;
