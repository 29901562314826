import React, { FC } from 'react';
import styled from 'styled-components';

import { secondary } from 'library/colors';

interface IDonutGraph {
  data: {
    total: number;
    incall: number;
    ringing: number;
    onhold: number;
    available: number;
    unavailable: number;
  };
}
const DonutGraph: FC<IDonutGraph> = ({ data }) => {
  const { total, incall, ringing, onhold, available, unavailable } = data;

  const incallDeg = Math.round((incall / total) * 360);
  const ringingDeg = incallDeg + Math.round((ringing / total) * 360);
  const onholdDeg = ringingDeg + Math.round((onhold / total) * 360);
  const availableDeg = onholdDeg + Math.round((available / total) * 360);
  const unavailableDeg = availableDeg + Math.round((unavailable / total) * 360);
  return (
    <Container>
      <Donut
        incall={incallDeg}
        ringing={ringingDeg}
        onhold={onholdDeg}
        available={availableDeg}
        unavailable={unavailableDeg}
      />
    </Container>
  );
};
const Container = styled.div`
  width: 110px;
  height: 110px;
  position: relative;
`;
const Donut = styled.div<IDonut>`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  transition: background-image 1s;
  background-image: conic-gradient(#85ce5f 0 ${props=>props.incall}deg,#4cb3ed 0 ${props=>props.ringing}deg, #c09405 0 ${props=>props.onhold}deg, #053855 0 ${props=>props.available}deg, ${secondary} 0 ${props=>props.unavailable}deg, ${secondary} 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    transparent calc(100% - 15px),
    red 42px
  );
  mask: radial-gradient(farthest-side, transparent calc(100% - 15px), red 42px);
`;
interface IDonut {
  incall: number;
  ringing: number;
  onhold: number;
  available: number;
  unavailable: number;
}
export default DonutGraph;
