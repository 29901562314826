import {
  CustomNodeTypes,
  IHandleData,
  INodeData,
  conditions,
} from 'components/flowV2/utils';
import { lightBackgroundBlue, primary } from 'library/colors';
import React, { FC } from 'react';
import { Node, Position } from 'reactflow';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import Condition from './consition';
import { AddButtonModalSC, BlueContModalSC, SectionTitleModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { getColorFromSource, updateSourceAndEdgeColor } from 'components/flowV2/utils/colorFunctions';

interface IDataVerificationModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  handleDeleteEdgeOnDeleteSource: (sourceId: string) => void
  handleUpdateEdgeColor: (nodeId: string, sourceId: string, color: string) => void;
}
const DataVerificationModal: FC<IDataVerificationModalProps> = ({
  selectedNode,
  setSelectedNode,
  handleDeleteEdgeOnDeleteSource,
  handleUpdateEdgeColor
}) => {
  const { data } = selectedNode;

  const handleAddCondition = () => {
    const newVariable = {
      id: uuid(),
      port: `Port ${data.conditionalRoutes?.length}`,
      variable: '',
      condition: '==',
      value: '',
    };
    const newSource: IHandleData & { type: 'source' } = {
      id: newVariable.id,
      color: '#7c9904',
      type: 'source',
      position: Position.Right,
      label: newVariable.port,
    };
    const sources = data.sources || [];
    const conditionalRoutes = data.conditionalRoutes || [];
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        sources: [...sources, newSource],
        conditionalRoutes: [...conditionalRoutes, newVariable],
      },
    };
    setSelectedNode(editedNode);
  };

  const handleDeleteCondition = (conditionId: string) => {
    if (!data.conditionalRoutes || !data.sources) return
    const conditionalRoutesToEdit = structuredClone(data.conditionalRoutes)
    const sourcesToEdit = structuredClone(data.sources)
    const conditionIndex = conditionalRoutesToEdit.findIndex(condition => condition.id === conditionId)
    const sourceIndex = sourcesToEdit.findIndex(source => source.id === conditionId)

    if (conditionIndex === -1 || sourceIndex === -1) return
    conditionalRoutesToEdit.splice(conditionIndex, 1)
    sourcesToEdit.splice(sourceIndex, 1)
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        conditionalRoutes: [...conditionalRoutesToEdit],
        sources: [...sourcesToEdit],
      },
    };
    handleDeleteEdgeOnDeleteSource(conditionId)
    setSelectedNode(editedNode);
  }

  const handleConditionChange = (
    index: number,
    name: 'port' | 'variable' | 'condition' | 'value',
    value: string,
  ) => {
    const conditions = structuredClone(data.conditionalRoutes) || [];
    const sources = structuredClone(data.sources) || [];

    conditions[index][name] = value;

    if (name === 'port') {
      sources.forEach(source => {
        if (source.id === conditions[index].id) {
          source.label = value;
        }
      });
    }

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        conditionalRoutes: [...conditions],
        sources: sources,
      },
    };
    setSelectedNode(editedNode);
  };

  const handleUpdateSourceColor = (sourceId: string, color: string) => {
    updateSourceAndEdgeColor(
      sourceId,
      color,
      selectedNode,
      setSelectedNode,
      handleUpdateEdgeColor
    )
  }
  return (
    <BlueContModalSC>
      <SectionTitleModalSC>
        Response routes
      </SectionTitleModalSC>
      {data.conditionalRoutes?.map((condition, index) => {
        return (
          <Condition
            key={index}
            index={index}
            condition={condition}
            sourceColor={getColorFromSource(data.sources, condition.id)}
            handleUpdateSourceColor={handleUpdateSourceColor}
            handleConditionChange={handleConditionChange}
            handleDeleteCondition={handleDeleteCondition}
          />
        );
      })}
      <AddButtonModalSC onClick={() => handleAddCondition()}>
        <i className="bx bxs-plus-circle" />
        <span>Add Variable</span>
      </AddButtonModalSC>
    </BlueContModalSC>
  );
};
export default DataVerificationModal;
