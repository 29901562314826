import React, { FC } from 'react';
import styled from 'styled-components';
import smartbot from '../../../icons/SmartBot_BotonChatBot.svg'

const AppErrorFallback = (error?: string) => {
  return (
    <Cont>
      <ImgCont>
        <img src={smartbot} alt="" />
      </ImgCont>
      <h1>Algo ha ido mal! ☹️</h1>
      <h3>Haz un print de pantalla y envíala al equipo de desarrollo.</h3>
      <h3>{error}</h3>
    </Cont>
  );
};
const Cont = styled.div`
  height: 80vh;
  display: grid;
  place-content: center;
  text-align: center;
  grid-gap: 20px;
`;
const ImgCont = styled.div`
  width: 50vw;
  min-width: 100px;
  max-width: 300px;
  margin: 0 auto;
`;
export default AppErrorFallback;