import { IWhatsappUsage, IWhatsappUsageCounterKeys } from 'library/interfaces/reportsInterfaces';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IWhatsappUsageDetailProps {
  usage: IWhatsappUsage
  columns: IWhatsappUsageCounterKeys[]
}
const WhatsappUsageDetail: FC<IWhatsappUsageDetailProps> = ({ usage, columns }) => {
  return (
    <Cont columnsNumber={columns.length + 1}>
      <div >{usage.date}</div>
      {columns.map((item, index) => {
        return (
          <div key={index}>{usage[item]}</div>
        )
      })}
    </Cont>
  );
};
const Cont = styled.div<ICont>`
grid-column: 1 / -1;
display: grid;
place-items: center;
grid-template-columns: repeat(${props => props.columnsNumber}, 1fr) ;
`;
interface ICont {
  columnsNumber: number
}
export default WhatsappUsageDetail;