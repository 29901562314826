import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  DEL_CLOSE_SESSION,
  GET_CONVERSATION_SESSIONS,
  GET_CLIENT_MESSAGES,
  IDelCloseSession,
  IGetConversationSessions,
  IGetClientMessages,
  IPostSendMessage,
  POST_SEND_HSM,
  POST_SEND_MESSAGE,
  IPutAssingSessionToAgent,
  PUT_ASSING_SESSION_TO_AGENT,
} from './actionTypes';
import {
  assingSessionToAgent,
  delCloseSession,
  getAsignedSessions,
  getClientMessages,
  getSessionMessages,
  getUnassignedSessions,
  getUnassignedSystemSessions,
  postSendHSM,
  postSendMessage,
} from 'helpers/fakebackend_helper';
import {
  ISessionAssigned,
  ISendMessageResponse,
  ISession,
  ISessionMessageResponse_campaign,
  ISessionMessageResponse_HSM,
  ISessionMessageResponse_contact,
} from 'library/interfaces/conversationInterfaces';
import {
  getConversationSessionsFail,
  getConversationSessionsSuccess,
  getCLientMessagesFail,
  getCLientMessagesSuccess,
  postSendHSMASuccess,
  postSendMessageSuccess,
  putAssingSessionToAgentSuccess,
  putAssingSessionToAgentFail,
  updateWaSessionClientDataAction,
} from './actions';
import { IError } from 'store/reports/reducer';
import { IPostSendHSM } from './actionTypes';
import { postSendHSMAFail } from './actions';
import { isArrayJSON } from 'library/services/functions';
import { updatePhoneE64DataAction } from 'store/actions';

function* onGetConversationSessions({
  payload: { channels },
}: IGetConversationSessions) {
  try {
    if (!channels || channels.length === 0) return;

    const noAsigned: { lastKey: string | null; sessions: ISession[] }[] =
      yield all(
        channels.map(chan => {
          if (chan.channelType === 'whatsapp') {
            return call(getUnassignedSessions, chan.value);
          } else {
            return call(getUnassignedSystemSessions, chan.value);
          }
        }),
      );
    const asigned: { lastKey: string | null; sessions: ISessionAssigned[] }[] =
      yield all(
        channels.map(chan => {
          return call(getAsignedSessions, chan.value);
        }),
      );

    const newAsignedSessions = asigned
      .map(s => s.sessions)
      .flat()
      .map(item => {
        return {
          ...item,
          isLoading: false,
          error: null,
          messages: [],
          clientData: null,
        };
      });

    const unassignedSessions = {
      waLastKey: null,
      sessions: noAsigned.map(ses => ses.sessions).flat(),
    };
    const asignedSessions = {
      lastKey: null,
      sessions: newAsignedSessions,
    };

    const sortByDate = (a: ISession, b: ISession) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();

    unassignedSessions.sessions.sort(sortByDate);

    yield put(
      getConversationSessionsSuccess(unassignedSessions, asignedSessions),
    );
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the active sessions',
      details: `${e}`,
    };
    yield put(getConversationSessionsFail(error));
  }
}

function* onGetClientMessages({
  payload: { channel, source, sessionId, templates },
}: IGetClientMessages) {
  try {
    // el historico de mensajes solo trae los mensajes de el cliente
    // y los enviados  desde el chat de smartbot
    // no trae los mensajes de hsm ni los de campaña
    const res: {
      lastKey: string | null;
      messages: { [key: string]: string | number }[];
    } = yield call(getClientMessages, channel, source);

    const previousMessages = res.messages;

    /**
     *  todos reciben lastKey
     *
     *  casos:
     *
     *  1)  cliente que no ha recibido mensaje de camapaña ni hsm individual:
     *
     *        => solo recibe los mensajes.
     *        => no recibe hsm, ni campaign, ni contact.
     *
     *  2)  cliente que ha recibido un hsm individual desde workspace
     *      y no está registrado como contacto en la campaña:
     *
     *        => recibe mensajes y campaign (campaign contiene el template_id).
     *        => no recibe hsm ni contacts.
     *
     *  3)  cliente que está como contacto dentro de la campaña
     *
     *        => recibe mensaje, campaign, hsm y contact
     *            - hsm: tiene el template_id y los parametros
     *            - contact:  {
     *                          data: {} // contiene los datos del clientes guardados al crear la campaña
     *                          message: // tiene el adjunto del mensaje que se envió, revisar luego.
     *                        }
     */
    const sessionMessages: {
      lastKey: string | null;
      messages: { [key: string]: string | number }[];
      hsm?: ISessionMessageResponse_HSM;
      campaign?: ISessionMessageResponse_campaign;
      contact?: ISessionMessageResponse_contact;
    } = yield call(getSessionMessages, sessionId);

    const { messages, hsm, campaign, contact } = sessionMessages;

    /**
     * Caso 1: solo recibe message.
     *
     * Campaign viene en los casos 2 y 3 por lo que si campaign no existe
     * insertamos directamente en el store el historico de mensajes (previousMessages).
     *
     * Retornamos para no ejecutar el resto del código.
     */
    if (!campaign) {
      yield put(getCLientMessagesSuccess(previousMessages, sessionId));
      return;
    }

    /**
     * resto de casos:
     *
     * buscamos el template utilizado en la campaña,
     * si no existe el template, insertamos directamente los mensajes previos.
     *
     * Retornamos para no ejecutar el resto del código.
     */
    let template = templates.find(temp => temp.id === campaign.template_id);

    if (!template) {
      yield put(getCLientMessagesSuccess(previousMessages, sessionId));
      return;
    }

    /**
     * creamos la variable msg que contendra los mensajes de campaña y hsm cuando existe el template
     * seteamos source_name = campaign.name para que el nombre de la campaña aparezca como el remitente del mensaje
     * seteamos content = template.data
     */
    let msg = {
      id: 'id',
      idx: 'idx',
      content_type: 'text',
      content: template.data,
      source_id: 'system',
      source_name: campaign.name,
      source_type: 'system',
      source_timestamp: '',
      channel_id: channel,
      timestamp: '',
      created_at: '-',
    };

    /**
     * caso 2: recibe menssages y campaign, pero no hsm ni contact
     *
     * evaluamos que no exista el hsm, si no existe el hsm inserta msg en previousMessages
     * en el indice previousMessages.length - messages.length para que quede al inicio de los mensajes de sesión.
     *
     * Retornamos para no ejecutar el resto del código.
     */

    if (!hsm) {
      previousMessages.splice(
        previousMessages.length - messages.length,
        0,
        msg,
      );
      yield put(getCLientMessagesSuccess(previousMessages, sessionId));
      return;
    }

    /**
     * caso 3: recibe messages, campaign, hsm y contact
     *
     *  si existe contact y contact.data =! de null
     *  insertamos la data en la sesion y en phone data para mostrarla en workspace Right bar
     *
     * comprobamos que hsm.params sea un array de string stringify y lo parseamos para guardar en la constante params
     */

    if (contact?.data) {
      yield put(updateWaSessionClientDataAction(sessionId, contact.data));
      yield put(updatePhoneE64DataAction([contact.data]));
    }

    if (contact?.message?.type === 'image') {
      const msg2 = structuredClone(msg);
      msg2.content_type = 'image';
      msg2.content = JSON.stringify({ url: contact.message.image.link });
      previousMessages.splice(
        previousMessages.length - messages.length,
        0,
        msg2,
      );
    } else if (contact?.message?.type === 'document') {
      const msg2 = structuredClone(msg);
      msg2.content_type = 'text';
      msg2.content = JSON.stringify({
        type: 'file',
        url: contact.message.document.link,
      });
      previousMessages.splice(
        previousMessages.length - messages.length,
        0,
        msg2,
      );
    }
    const params: string[] = isArrayJSON(hsm.params)
      ? JSON.parse(hsm.params)
      : [];

    /**
     * si la longitud de params es cero,
     * insertamos el mensaje que ya viene con los datos de campaign
     * sin tratar las variables del content
     *
     * Y retornamos para que no se ejecute el resto del código
     */

    if (params.length === 0) {
      previousMessages.splice(
        previousMessages.length - messages.length,
        0,
        msg,
      );
      yield put(getCLientMessagesSuccess(previousMessages, sessionId));
      return;
    }

    /**
     * se crea la expresion regular que haga el match con las variables dentro del template.data.
     * en la variable splitedMessage se utiliza la expresión regular para separar el template en string y variables.
     * se inicializa un contador en 0.
     *
     * se realiza un forEach a splitedMessage y se evalua cada segmento
     */
    const regEx = /({{+\w+}})/g;
    const splitedMessage = template.data.split(regEx);
    let currentParamIndex = 0;

    /**
     * se recorre el array splited message
     * y se evalua con la expresión regular si cada segmento de texto es un string de texto o una variable
     *
     * si es una variable se sustituye
     */
    splitedMessage.forEach((item, index) => {
      if (regEx.test(item)) {
        splitedMessage.splice(index, 1, params[currentParamIndex]);
        currentParamIndex++;
      }
    });

    msg.content = campaign.source + ': ' + splitedMessage.join('');

    previousMessages.splice(previousMessages.length - messages.length, 0, msg);

    yield put(getCLientMessagesSuccess(previousMessages, sessionId));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the messages',
      details: `${e}`,
    };
    yield put(getCLientMessagesFail(error, sessionId));
  }
}

function* onPostSendMessage({
  payload: { message, sessionId },
}: IPostSendMessage) {
  try {
    const response: ISendMessageResponse = yield call(
      postSendMessage,
      sessionId,
      { message: message.content },
    );
    if (response.status === 'submitted') {
      message.id = response.messageId;
      yield put(postSendMessageSuccess(sessionId, message));
    }
  } catch (e) {
    console.log(e);
  }
}

function* onPostSendHSM({ payload: { campaignId, hsm } }: IPostSendHSM) {
  try {
    const response: ISendMessageResponse = yield call(
      postSendHSM,
      campaignId,
      hsm,
    );
    if (response.status !== 'submitted')
      throw new Error(JSON.stringify(response));
    yield put(postSendHSMASuccess(response.messageId));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong sending the HSM',
      details: `${e}`,
    };
    yield put(postSendHSMAFail(error));
  }
}

function* onDelCloseSession({ payload: { sessionId } }: IDelCloseSession) {
  try {
    yield call(delCloseSession, sessionId);
  } catch (error) {
    console.log(error);
  }
}

function* onAssignSessionToAgent({
  payload: { session },
}: IPutAssingSessionToAgent) {
  try {
    const res: string = yield call(assingSessionToAgent, session.id);

    if (res) throw new Error(res);

    const sessionAssigned = {
      ...session,
      isLoading: false,
      error: null,
      messages: [],
      hasNewMessage: false,
      clientData: null,
    };
    yield put(putAssingSessionToAgentSuccess(sessionAssigned));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong assigning the session',
      details: `${e}`,
    };
    yield put(putAssingSessionToAgentFail(error));
  }
}

function* conversationsSaga() {
  yield takeEvery(GET_CONVERSATION_SESSIONS, onGetConversationSessions);
  yield takeEvery(GET_CLIENT_MESSAGES, onGetClientMessages);
  yield takeEvery(POST_SEND_MESSAGE, onPostSendMessage);
  yield takeEvery(POST_SEND_HSM, onPostSendHSM);
  yield takeEvery(DEL_CLOSE_SESSION, onDelCloseSession);
  yield takeEvery(PUT_ASSING_SESSION_TO_AGENT, onAssignSessionToAgent);
}
export default conversationsSaga;
