import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ToggleButton from './toggleButton';

import { primary } from 'library/colors';
import flowIcons from '../utils/flowIcons';
import { CustomNodeTypes } from '../utils';
import SaveScenaryButton from './saveButton';
import useLocalStorage from 'library/hooks/useLocalStorage';
import GoBackButton from './goBackButton';

interface IFlowSideBarProps {
  handleSaveNode: () => void;
  handleGoBackButtonClick: () => void;
}

const FlowSideBar = ({ handleSaveNode, handleGoBackButtonClick }: IFlowSideBarProps) => {
  const {
    sceneryEnd: SceneryEnd,
    interactiveMenu: InteractiveMenu,
    message: TextToSpeech,
    conditional: DataVerification,
    function: FunctionRequest,
    sessionVariables: SessionVariables,
    transfer: Transfer,
    database: Database,
    event: Event,
    GPT
  } = flowIcons;
  const [isExtended, setIsExtended] = useState(true);
  const [storedValue, setValue] = useLocalStorage(
    'flowEditorSidebarExtended',
    true,
  );

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: CustomNodeTypes,
  ) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };
  const onButtonClick = () => {
    setValue(!isExtended);
    setIsExtended(prev => !prev);
  };

  useEffect(() => {
    setIsExtended(storedValue);
  }, []);

  return (
    <>
      <GoBackButton isExtended={isExtended} handleGoBackButtonClick={handleGoBackButtonClick} />
      <Aside isExtended={isExtended}>


        <ToggleButton toggle={isExtended} onClick={onButtonClick} />

        <Cont>
          <Draggable
            onDragStart={event => onDragStart(event, 'message')}
            draggable
          >
            <div>
              <TextToSpeech />
              Mensaje
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'interactiveMenu')}
            draggable
          >
            <div>
              <InteractiveMenu />
              Menú Interactivo
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'conditional')}
            draggable
          >
            <div>
              <DataVerification />
              Condicional
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'function')}
            draggable
          >
            <div>
              <FunctionRequest />
              Funciones
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'transfer')}
            draggable
          >
            <div>
              <Transfer />
              Transferencia
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'database')}
            draggable
          >
            <div>
              <Database />
              Bases de datos
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'event')}
            draggable
          >
            <div>
              <Event />
              Evento
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'GPT')}
            draggable
          >
            <div>
              <GPT />
              GPT
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'sessionVariables')}
            draggable
          >
            <div>
              <SessionVariables />
              Variables de Sessión
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
          <Draggable
            onDragStart={event => onDragStart(event, 'sceneryEnd')}
            draggable
          >
            <div>
              <SceneryEnd />
              Fin de escenario
            </div>
            <i className="bx bx-grid-vertical"></i>
          </Draggable>
        </Cont>
        <SaveScenaryButton
          isExtended={isExtended}
          handleSaveNode={handleSaveNode}
        />
      </Aside>
    </>
  );
};

const Aside = styled.aside<IAside>`
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  color: black;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-60%);
  z-index: 1000;

  @media screen and (min-width: 768px) {
    width: ${props => (props.isExtended ? '250px' : '42px')};
    transition: width 1s;
  }
`;
const Cont = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
`;
const Draggable = styled.div`
  color: #444;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & > div {
    margin-right: 10px;
    white-space: nowrap;
  }
  svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    color: ${primary};
  }
  i {
    color: #adc0c9ff;
  }
`;
interface IAside {
  isExtended: boolean;
}
export default FlowSideBar;
