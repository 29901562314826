import { ACTION_SCENARIOS_FAIL, DELETE_SCENARIO_SUCCESS, GET_SCENARIOS_SUCCESS, GET_SCENARIO_SUCCESS, POST_ADD_SCENARIO_SUCCESS, PUT_RENAME_SCENARIO_SUCCESS, PUT_PUBLISH_SCENARIO_SUCCESS, PUT_SAVE_AS_DRAFT_SCENARIO_SUCCESS, PUT_UPDATE_SCENARIO_SUCCESS, CLEAR_SCENARIO_STORE, GET_FUNCTIONS_SUCCESS, GET_INTENTS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  scenarios: [],
  scenario: {},
  newScenario:undefined,
  functions: [],
  intents: [],
  error: {},
}

const scenarios = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_SCENARIOS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SCENARIOS_SUCCESS:
      return {
        ...state,
        scenarios: action.payload,
      }
    case GET_SCENARIO_SUCCESS:
      return {
        ...state,
        scenario: action.payload,
      }
    case POST_ADD_SCENARIO_SUCCESS:
      return {
        ...state,
        newScenario:action.payload,
      }
    case PUT_RENAME_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: state.scenarios.map(s => { 
            if(s.id.toString() === action.payload.id.toString()) {
              s.name = action.payload.newName
            } 
            return s
          }
        ),
      }
    case DELETE_SCENARIO_SUCCESS:
      console.log('DELETE_SCENARIO_SUCCESS: ', action.payload.id.toString())
      return {
        ...state,
        scenarios: state.scenarios.filter(
          s => s.id.toString() !== action.payload.id.toString()
        ),
      }
    case PUT_PUBLISH_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: state.scenarios.map(s => { 
            if(s.id.toString() === action.payload.toString()) {
              s.published = true
            } 
            return s
          }
        ),
      }
    case PUT_SAVE_AS_DRAFT_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: state.scenarios.map(s => { 
            if(s.id.toString() === action.payload.toString()) {
              s.published = false
            } 
            return s
          }
        ),
      }
    case PUT_UPDATE_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: state.scenarios.map(s => { 
            if(s.id.toString() === action.payload.id.toString()) {
              //s.name = action.payload.newName
            } 
            return s
          }
        ),
      }
    case CLEAR_SCENARIO_STORE: {
      return{
        ...INIT_STATE
      }
    }
    case GET_FUNCTIONS_SUCCESS: {
      return {
        ...state,
        functions: action.payload
      }
    }
    case GET_INTENTS_SUCCESS: {
      return {
        ...state,
        intents: action.payload
      }
    }
    default:
      return state
  }
}

export default scenarios