import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Loading from '../../components/Loading';
import { useAuth0 } from '@auth0/auth0-react';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getUser as onGetUser } from 'store/actions';

const Authorize = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: auth0User, getAccessTokenSilently } = useAuth0();

  const { agent } = useSelector(state => ({
    agent: state.agents.agent,
  }));

  useEffect(async () => {
    if (auth0User) {
      const token = await getAccessTokenSilently();
      localStorage.setItem('auth0_token', token);
      dispatch(onGetUser(auth0User.picture));
    }
  }, [auth0User]);

  useEffect(async () => {
    if (agent) {
      localStorage.setItem('authUser', JSON.stringify(agent));
      history.push(`/${agent.options[0].id}`);
    }
  }, [agent]);

  return <Loading />;
};

export default withRouter(Authorize);
