import {
  IEmail,
  IEventFromBotData,
  ITotalCallsByOrigin,
  IWhatsappUsage,
  IWhatsappUsageCounter,
} from 'library/interfaces/reportsInterfaces';
import {
  GET_REPORT_CALLS_DETAIL_EXCEL_FAIL,
  GET_REPORT_CALLS_DETAIL_EXCEL_SUCCESS,
  GET_REPORT_CALLS_DETAIL_EXCEL_V2,
  GET_REPORT_CALLS_DETAIL_EXCEL_V2_DOWNLOAD,
  GET_REPORT_CALLS_DETAIL_EXCEL_V2_FAIL,
  GET_REPORT_CALLS_DETAIL_EXCEL_V2_SUCCESS,
  GET_REPORT_CALLS_SUMMARIZE_FAIL,
  GET_REPORT_CALLS_SUMMARIZE_SUCCESS,
  GET_REPORT_CALLS_SUMMARIZE_V2,
  GET_REPORT_CALLS_SUMMARIZE_V2_DOWNLOAD,
  GET_REPORT_CALLS_SUMMARIZE_V2_FAIL,
  GET_REPORT_CALLS_SUMMARIZE_V2_SUCCESS,
  GET_REPORT_EVENTS_FROM_BOT_1234_FAIL,
  GET_REPORT_EVENTS_FROM_BOT_1234_SUCCESS,
  GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2,
  GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_DOWNLOAD,
  GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_FAIL,
  GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_SUCCESS,
  GET_REPORT_TOTAL_CALLS_BY_ORIGIN,
  GET_REPORT_TOTAL_CALLS_BY_ORIGIN_FAIL,
  GET_REPORT_TOTAL_CALLS_BY_ORIGIN_SUCCESS,
  GET_REPORT_TOTAL_CALLS_BY_STATUS_FAIL,
  GET_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS,
  GET_REPORT_TYPES,
  GET_REPORT_TYPES_FAIL,
  GET_REPORT_TYPES_SUCCESS,
  ReportTypes,
  REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL,
  REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234,
  REQUEST_REPORT_CALLS_DETAIL_EXCEL,
  REQUEST_REPORT_CALLS_DETAIL_EXCEL_FAIL,
  REQUEST_REPORT_CALLS_DETAIL_EXCEL_SUCCESS,
  REQUEST_REPORT_EVENTS_FROM_BOT_1234,
  REQUEST_REPORT_EVENTS_FROM_BOT_1234_FAIL,
  REQUEST_REPORT_EVENTS_FROM_BOT_1234_SUCCESS,
  REQUEST_REPORT_TOTAL_CALLS_BY_STATUS,
  REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_FAIL,
  REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS,
  GET_EVENTS_FROM_BOT_DATA,
  GET_EVENTS_FROM_BOT_DATA_SUCCESS,
  GET_EVENTS_FROM_BOT_DATA_FAIL,
  UPDATE_EVENTS_FROM_BOT_DATA_COUNTER,
  CLEAR_EVENTS_FROM_BOT_DATA_STORE,
  GET_EMAILS_BY_FILTER,
  GET_EMAILS_BY_FILTER_SUCCESS,
  GET_EMAILS_BY_FILTER_FAIL,
  CLEAR_EMAIL_BY_FILTER_STORE,
  GET_EMAIL_SESSION,
  GET_EMAIL_SESSION_SUCCESS,
  GET_EMAIL_SESSION_FAIL,
  CLEAR_EMAIL_SESSION_STORE,
  GET_WHATSAPP_USAGE,
  GET_WHATSAPP_USAGE_SUCCESS,
  GET_WHATSAPP_USAGE_FAIL,
  CLEAR_WHATSAPP_USAGE_STORE,
} from './actionTypes';
import { error } from 'console';

export interface IError {
  message: string;
  details: string;
}
export interface IReportType {
  owner: string;
  id: string;
  name: string;
  filters: {
    start: Date;
    end: Date;
  };
  format: string;
}
export interface IReportTypeStore {
  error: IError | null;
  isLoading: boolean;
  types: IReportType[];
}

export interface ICallStatus {
  answered: number;
  notAnswered: number;
  abandom: number;
  busy: number;
  failed: number;
  total: number;
}
export interface IContentData {
  callStatus: ICallStatus | undefined;
  uri: string | undefined;
}
export interface IReportData {
  owner: string;
  id: string;
  type: string;
  status: 'in-progress' | 'completed' | 'failed';
  created_at: string;
  updated_at: string;
  format: 'json' | 'excel';
  content_data: IContentData | '';
}
export interface IReport {
  count: number;
  error: IError | null;
  isLoading: boolean;
  reportId: string;
  reportData: IReportData | null;
}
export interface IRestReport {
  error: IError | null;
  isLoading: boolean;
  reportData: ITotalCallsByOrigin | null;
}

export interface IEventsFromBotDataStore {
  error: IError | null;
  isLoading: boolean;
  events: IEventFromBotData[] | null;
  solvedRate: number | null;
  counter: { [key: string]: number };
  colors: { [key: string]: string };
  callMinuteTranscript: { [key: string]: number };
  totalMinuteTranscript: number | null;
}

export interface IEmailsByFilterStore {
  error: IError | null;
  isLoading: boolean;
  lastKey: string | null;
  rows: IEmail[];
  emailSession: {
    error: IError | null;
    isLoading: boolean;
    rows: IEmail[];
  };
}

export interface IWhatsappUsageStore {
  error: IError | null;
  isLoading: boolean;
  partnerAppUsageList: IWhatsappUsage[];
  counter: IWhatsappUsageCounter | null;
}

export interface IReportsStore {
  reportTypes: IReportTypeStore;
  whatsappUsage: IWhatsappUsageStore;
  emails: IEmailsByFilterStore;
  eventsFromBotData: IEventsFromBotDataStore;
  totalCallByOrigin: IRestReport;
  totalCallsByStatus: IReport;
  callsDetailExcel: IReport;
  eventsFromBot1234: IReport;
  callsSummarize: IReport;
}

const INITE_REPORT_STORE = {
  count: 0,
  error: null,
  isLoading: false,
  reportId: '',
  reportData: null,
};
const INIT_REST_REPORT = {
  error: null,
  isLoading: false,
  reportData: null,
};

const INIT_STATE: IReportsStore = {
  reportTypes: {
    error: null,
    isLoading: false,
    types: [],
  },
  whatsappUsage: {
    error: null,
    isLoading: false,
    partnerAppUsageList: [],
    counter: null,
  },
  emails: {
    error: null,
    isLoading: false,
    lastKey: null,
    rows: [],
    emailSession: {
      error: null,
      isLoading: false,
      rows: [],
    },
  },
  eventsFromBotData: {
    error: null,
    isLoading: false,
    events: null,
    solvedRate: null,
    counter: {},
    colors: {},
    callMinuteTranscript: {},
    totalMinuteTranscript: null,
  },
  totalCallByOrigin: INIT_REST_REPORT,
  totalCallsByStatus: INITE_REPORT_STORE,
  callsDetailExcel: INITE_REPORT_STORE,
  eventsFromBot1234: INITE_REPORT_STORE,
  callsSummarize: INITE_REPORT_STORE,
};

const reports = (state = INIT_STATE, action: ReportTypes): IReportsStore => {
  switch (action.type) {
    case GET_REPORT_TYPES: {
      return {
        ...state,
        reportTypes: {
          ...state.reportTypes,
          error: null,
          isLoading: true,
        },
      };
    }
    case GET_REPORT_TYPES_SUCCESS: {
      return {
        ...state,
        reportTypes: {
          error: null,
          isLoading: false,
          types: action.payload.reportTypes,
        },
      };
    }
    case GET_REPORT_TYPES_FAIL: {
      return {
        ...state,
        reportTypes: {
          ...state.reportTypes,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case GET_REPORT_TOTAL_CALLS_BY_ORIGIN: {
      return {
        ...state,
        totalCallByOrigin: {
          ...state.totalCallByOrigin,
          error: null,
          isLoading: true,
        },
      };
    }
    case GET_REPORT_TOTAL_CALLS_BY_ORIGIN_SUCCESS: {
      return {
        ...state,
        totalCallByOrigin: {
          ...state.totalCallByOrigin,
          error: null,
          isLoading: false,
          reportData: action.payload.counters,
        },
      };
    }
    case GET_REPORT_TOTAL_CALLS_BY_ORIGIN_FAIL: {
      return {
        ...state,
        totalCallByOrigin: {
          ...state.totalCallByOrigin,
          isLoading: false,
          reportData: null,
          error: action.payload.error,
        },
      };
    }
    case REQUEST_REPORT_TOTAL_CALLS_BY_STATUS: {
      return {
        ...state,
        totalCallsByStatus: {
          ...state.totalCallsByStatus,
          error: null,
          isLoading: true,
        },
      };
    }
    case REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS: {
      return {
        ...state,
        totalCallsByStatus: {
          ...state.totalCallsByStatus,
          reportId: action.payload.requestedReportId,
        },
      };
    }
    case REQUEST_REPORT_TOTAL_CALLS_BY_STATUS_FAIL: {
      return {
        ...state,
        totalCallsByStatus: {
          ...state.totalCallsByStatus,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case GET_REPORT_TOTAL_CALLS_BY_STATUS_SUCCESS: {
      const newCount =
        action.payload.data.status === 'completed'
          ? 0
          : state.totalCallsByStatus.count + 1;
      const isLoading =
        action.payload.data.status === 'completed' ? false : true;
      const reportId = isLoading ? state.totalCallsByStatus.reportId : '';
      return {
        ...state,
        totalCallsByStatus: {
          count: newCount,
          error: null,
          isLoading: isLoading,
          reportId: reportId,
          reportData: action.payload.data,
        },
      };
    }
    case GET_REPORT_TOTAL_CALLS_BY_STATUS_FAIL: {
      return {
        ...state,
        totalCallsByStatus: {
          ...state.totalCallsByStatus,
          count: 0,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case REQUEST_REPORT_CALLS_DETAIL_EXCEL:
    case GET_REPORT_CALLS_DETAIL_EXCEL_V2: {
      return {
        ...state,
        callsDetailExcel: {
          ...state.callsDetailExcel,
          error: null,
          isLoading: true,
        },
      };
    }
    case REQUEST_REPORT_CALLS_DETAIL_EXCEL_SUCCESS: {
      return {
        ...state,
        callsDetailExcel: {
          ...state.callsDetailExcel,
          reportId: action.payload.requestedReportId,
        },
      };
    }
    case REQUEST_REPORT_CALLS_DETAIL_EXCEL_FAIL: {
      return {
        ...state,
        callsDetailExcel: {
          ...state.callsDetailExcel,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case GET_REPORT_CALLS_DETAIL_EXCEL_SUCCESS:
    case GET_REPORT_CALLS_DETAIL_EXCEL_V2_SUCCESS: {
      const newCount =
        action.payload.data.status === 'completed'
          ? 0
          : state.callsDetailExcel.count + 1;
      const isLoading =
        action.payload.data.status === 'completed' ? false : true;
      const reportId = action.payload.data.id;
      return {
        ...state,
        callsDetailExcel: {
          count: newCount,
          error: null,
          isLoading: isLoading,
          reportId: reportId,
          reportData: action.payload.data,
        },
      };
    }
    case GET_REPORT_CALLS_DETAIL_EXCEL_FAIL:
    case GET_REPORT_CALLS_DETAIL_EXCEL_V2_FAIL: {
      return {
        ...state,
        callsDetailExcel: {
          ...state.callsDetailExcel,
          count: 0,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case REPORT_DOWNLOADED_CALLS_DETAIL_EXCEL:
    case GET_REPORT_CALLS_DETAIL_EXCEL_V2_DOWNLOAD: {
      return {
        ...state,
        callsDetailExcel: INITE_REPORT_STORE,
      };
    }

    case REQUEST_REPORT_EVENTS_FROM_BOT_1234:
    case GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2: {
      return {
        ...state,
        eventsFromBot1234: {
          ...state.eventsFromBot1234,
          error: null,
          isLoading: true,
        },
      };
    }
    case REQUEST_REPORT_EVENTS_FROM_BOT_1234_SUCCESS: {
      return {
        ...state,
        eventsFromBot1234: {
          ...state.eventsFromBot1234,
          reportId: action.payload.requestedReportId,
        },
      };
    }
    case REQUEST_REPORT_EVENTS_FROM_BOT_1234_FAIL: {
      return {
        ...state,
        eventsFromBot1234: {
          ...state.eventsFromBot1234,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case GET_REPORT_EVENTS_FROM_BOT_1234_SUCCESS:
    case GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_SUCCESS: {
      const newCount =
        action.payload.data.status === 'completed'
          ? 0
          : state.eventsFromBot1234.count + 1;
      const isLoading =
        action.payload.data.status === 'completed' ? false : true;
      const reportId = action.payload.data.id;
      return {
        ...state,
        eventsFromBot1234: {
          count: newCount,
          error: null,
          isLoading: isLoading,
          reportId: reportId,
          reportData: action.payload.data,
        },
      };
    }
    case GET_REPORT_EVENTS_FROM_BOT_1234_FAIL:
    case GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_FAIL: {
      return {
        ...state,
        eventsFromBot1234: {
          ...state.eventsFromBot1234,
          count: 0,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case REPORT_DOWNLOADED_EVENTS_FROM_BOT_1234:
    case GET_REPORT_EVENTS_FROM_BOT_BY_EXTENSION_V2_DOWNLOAD: {
      return {
        ...state,
        eventsFromBot1234: INITE_REPORT_STORE,
      };
    }
    case GET_REPORT_CALLS_SUMMARIZE_V2: {
      return {
        ...state,
        callsSummarize: {
          ...state.callsSummarize,
          error: null,
          isLoading: true,
        },
      };
    }
    case GET_REPORT_CALLS_SUMMARIZE_SUCCESS:
    case GET_REPORT_CALLS_SUMMARIZE_V2_SUCCESS: {
      const newCount =
        action.payload.data.status === 'completed'
          ? 0
          : state.callsSummarize.count + 1;
      const isLoading =
        action.payload.data.status === 'completed' ? false : true;
      const reportId = action.payload.data.id;
      return {
        ...state,
        callsSummarize: {
          count: newCount,
          error: null,
          isLoading: isLoading,
          reportId: reportId,
          reportData: action.payload.data,
        },
      };
    }
    case GET_REPORT_CALLS_SUMMARIZE_FAIL:
    case GET_REPORT_CALLS_SUMMARIZE_V2_FAIL: {
      return {
        ...state,
        callsSummarize: {
          ...state.callsSummarize,
          count: 0,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case GET_REPORT_CALLS_SUMMARIZE_V2_DOWNLOAD: {
      return {
        ...state,
        callsSummarize: INITE_REPORT_STORE,
      };
    }
    case GET_EVENTS_FROM_BOT_DATA: {
      return {
        ...state,
        eventsFromBotData: {
          ...state.eventsFromBotData,
          isLoading: true,
          error: null,
          events: null,
          solvedRate: null,
          counter: {},
          colors: {},
          callMinuteTranscript: {},
          totalMinuteTranscript: null,
        },
      };
    }
    case GET_EVENTS_FROM_BOT_DATA_SUCCESS: {
      const totalMinuteTranscript =
        action.payload.totalMinuteTranscript > 0
          ? action.payload.totalMinuteTranscript
          : null;
      return {
        ...state,
        eventsFromBotData: {
          ...state.eventsFromBotData,
          isLoading: false,
          error: null,
          events: action.payload.events,
          solvedRate: action.payload.solvedRate,
          callMinuteTranscript: action.payload.callMinuteTranscript,
          totalMinuteTranscript: totalMinuteTranscript,
        },
      };
    }
    case GET_EVENTS_FROM_BOT_DATA_FAIL: {
      return {
        ...state,
        eventsFromBotData: {
          ...state.eventsFromBotData,
          isLoading: false,
          error: action.payload.error,
          events: null,
        },
      };
    }
    case UPDATE_EVENTS_FROM_BOT_DATA_COUNTER: {
      return {
        ...state,
        eventsFromBotData: {
          ...state.eventsFromBotData,
          counter: { ...action.payload.counter },
          colors: { ...action.payload.colors },
        },
      };
    }
    case CLEAR_EVENTS_FROM_BOT_DATA_STORE: {
      return {
        ...state,
        eventsFromBotData: {
          ...INIT_STATE.eventsFromBotData,
        },
      };
    }
    case GET_EMAILS_BY_FILTER: {
      return {
        ...state,
        emails: {
          ...state.emails,
          isLoading: true,
          error: null,
        },
      };
    }
    case GET_EMAILS_BY_FILTER_SUCCESS: {
      return {
        ...state,
        emails: {
          ...state.emails,
          error: null,
          isLoading: false,
          lastKey: action.payload.lastKey,
          rows: [...state.emails.rows, ...action.payload.rows],
        },
      };
    }
    case GET_EMAILS_BY_FILTER_FAIL: {
      return {
        ...state,
        emails: {
          ...state.emails,
          isLoading: false,
          error: action.payload.error,
        },
      };
    }
    case CLEAR_EMAIL_BY_FILTER_STORE: {
      return {
        ...state,
        emails: {
          ...INIT_STATE.emails,
        },
      };
    }
    case GET_EMAIL_SESSION: {
      return {
        ...state,
        emails: {
          ...state.emails,
          emailSession: {
            ...state.emails.emailSession,
            isLoading: true,
            rows: [],
          },
        },
      };
    }
    case GET_EMAIL_SESSION_SUCCESS: {
      return {
        ...state,
        emails: {
          ...state.emails,
          emailSession: {
            ...state.emails.emailSession,
            isLoading: false,
            rows: action.payload.rows,
          },
        },
      };
    }
    case GET_EMAIL_SESSION_FAIL: {
      return {
        ...state,
        emails: {
          ...state.emails,
          emailSession: {
            ...state.emails.emailSession,
            isLoading: false,
            error: action.payload.error,
          },
        },
      };
    }
    case CLEAR_EMAIL_SESSION_STORE: {
      return {
        ...state,
        emails: {
          ...state.emails,
          emailSession: {
            ...INIT_STATE.emails.emailSession,
          },
        },
      };
    }
    case GET_WHATSAPP_USAGE: {
      return {
        ...state,
        whatsappUsage: {
          isLoading: true,
          error: null,
          counter: null,
          partnerAppUsageList: [],
        },
      };
    }
    case GET_WHATSAPP_USAGE_SUCCESS: {
      return {
        ...state,
        whatsappUsage: {
          isLoading: false,
          error: null,
          counter: action.payload.counter,
          partnerAppUsageList: action.payload.partnerAppUsageList,
        },
      };
    }
    case GET_WHATSAPP_USAGE_FAIL: {
      return {
        ...state,
        whatsappUsage: {
          isLoading: false,
          error: action.payload.error,
          counter: null,
          partnerAppUsageList: [],
        },
      };
    }
    case CLEAR_WHATSAPP_USAGE_STORE: {
      return {
        ...state,
        whatsappUsage: {
          ...INIT_STATE.whatsappUsage,
        },
      };
    }
    default: {
      return state;
    }
  }
};
export default reports;
