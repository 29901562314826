import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useField } from 'formik';

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export function TextInput({ name, ...props }) {
  const [field] = useField(name);

  return (
    <>
      <div className="col-md-12">
        <input className="form-control" type="text" {...field} {...props} />
      </div>
      <ErrorMessage name={name} component="span" />
    </>
  );
}
