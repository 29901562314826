import CounterWithLabel from 'components/outbound/atoms/counterWithLabel';
import { blueGraph, danger, darkBorder } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IWACounterProps {
  dispatched_count: number | string;
  dispatched_delivered: number | string;
  dispatched_enqueued: number | string;
  dispatched_error: number | string;
  dispatched_failed: number | string;
  dispatched_read: number | string;
  dispatched_sent: number | string;
  dispatched_replied?: number | string;
}
const WACounter: FC<IWACounterProps> = ({
  dispatched_count,
  dispatched_delivered,
  dispatched_enqueued,
  dispatched_error,
  dispatched_failed,
  dispatched_read,
  dispatched_sent,
  dispatched_replied = 0
}) => {
  return (
    <Cont>
      <CounterWithLabel
        labelWidth="80px"
        label="Despachados"
        count={dispatched_count}
        backgroundColor={darkBorder}
        toolTip='Mensajes totales.'
      />
      <CounterWithLabel
        labelWidth="80px"
        label="En cola"
        count={dispatched_enqueued}
        backgroundColor={darkBorder}
        toolTip='Mensajes con números válidos enviados al servicio WhatsApp.'
      />
      <CounterWithLabel
        labelWidth="80px"
        label="Enviados"
        count={dispatched_sent}
        // backgroundColor="#a7d6f1"
        backgroundColor="#4cb2ed6e"
        toolTip='Mensajes enviados a números con cuenta de WhatsApp.'
      />
      <CounterWithLabel
        labelWidth="80px"
        label="Entregados"
        count={dispatched_delivered}
        // backgroundColor="#7bc6f2"
        backgroundColor="#4cb2edbf"
      />
      <CounterWithLabel
        labelWidth="80px"
        label="Leído"
        count={dispatched_read}
        backgroundColor={blueGraph}
      />
      <CounterWithLabel
        labelWidth="80px"
        label="Contestadas"
        count={dispatched_replied}
      />
      <CounterWithLabel
        labelWidth="80px"
        label="Error"
        count={dispatched_error}
        backgroundColor={'orange'}
        toolTip='Mensajes con error en la entrega en el servicio de WhatsApp.'
      />
      <CounterWithLabel
        labelWidth="80px"
        label="Fallidos"
        count={dispatched_failed}
        backgroundColor={danger}
        toolTip='Mensajes que arrojan error en el servicio.'
      />

    </Cont>
  );
};
const Cont = styled.div`
  flex-grow: 1;
  max-width: 740px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
export default WACounter;
