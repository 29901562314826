import * as React from "react"

export const SpeechToText = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M11.93 23.27a4.74 4.74 0 0 1-4.74-4.74v-9.7a4.74 4.74 0 0 1 9.48 0v9.7a4.74 4.74 0 0 1-4.74 4.74Zm0-17.35A2.91 2.91 0 0 0 9 8.83v9.7a2.9 2.9 0 1 0 5.8 0v-9.7a2.91 2.91 0 0 0-2.87-2.91Z"
    />
    <path
      fill="currentColor"
      d="M11.93 28.23A10.84 10.84 0 0 1 1.1 17.4a.92.92 0 0 1 1.84 0 9 9 0 0 0 18 0 .92.92 0 1 1 1.83 0 10.84 10.84 0 0 1-10.84 10.83Z"
    />
    <path
      fill="currentColor"
      d="M11.93 33.39a.92.92 0 0 1-.92-.92v-5.16a.92.92 0 0 1 1.84 0v5.16a.92.92 0 0 1-.92.92ZM17.3 1.61h9.71v1.84H17.3z"
    />
    <path
      fill="currentColor"
      d="M21.24 2.53h1.84v11.15h-1.84zM27.23 6.15h5.75v1.84h-5.75z"
    />
    <path fill="currentColor" d="M29.19 7.07h1.84v6.61h-1.84z" />
  </svg>
)

