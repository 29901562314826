import * as React from 'react';

export const Reports = props => (
  <svg
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 127.493V83.906M65.917 127.493V65.706M112.257 127.493V47.43M17.206 51.1l49.628-30.893v17.358l46.876-30.97"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={9.558625}
    />
    <path
      d="M97.345 4.76h17.741v15.447"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={9.558625}
    />
  </svg>
);
