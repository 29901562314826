import { DelIconModalSC, InputModalSC, InputVerticalGroupModalSC, LabelModalSC, WhiteContModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import React, { FC } from 'react';

interface IVariableProps {
  index: number;
  data: {
    key: string;
    value: string;
  };
  handleVariableChange: (
    index: number,
    name: 'key' | 'value',
    value: string,
  ) => void;
  handleDeleteVariable: (index: number) => void
}
const Variable: FC<IVariableProps> = ({
  data,
  index,
  handleVariableChange,
  handleDeleteVariable
}) => {
  return (
    <WhiteContModalSC>
      <DelIconModalSC onClick={() => handleDeleteVariable(index)}>
        <i className='bx bxs-trash'></i>
      </DelIconModalSC>
      <InputVerticalGroupModalSC >
        <LabelModalSC htmlFor="key">Variable Name</LabelModalSC>
        <InputModalSC
          type="text"
          id="key"
          name="key"
          value={data.key}
          onChange={e => handleVariableChange(index, 'key', e.target.value)}
        />
      </InputVerticalGroupModalSC>
      <InputVerticalGroupModalSC margin='0'>
        <LabelModalSC htmlFor="Value">Value</LabelModalSC>
        <InputModalSC
          type="text"
          id="Value"
          name="Value"
          value={data.value}
          onChange={e => handleVariableChange(index, 'value', e.target.value)}
        />
      </InputVerticalGroupModalSC>
    </WhiteContModalSC>
  );
};

export default Variable;
