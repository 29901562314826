import * as React from "react"

export const CallForwarding = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M21.16 31.64a11.24 11.24 0 0 1-2.94-.41 20.5 20.5 0 0 1-9-5.46C3.42 19.94 1.63 12.59 5.18 9l1.28-1.24a3.21 3.21 0 0 1 4.55 0l2.44 2.43a3.23 3.23 0 0 1 0 4.55l-1.93 1.93a22 22 0 0 0 3 3.79 21.39 21.39 0 0 0 3.78 3l1.93-1.93a3.31 3.31 0 0 1 4.55 0L27.26 24a3.23 3.23 0 0 1 0 4.55L26 29.83a6.56 6.56 0 0 1-4.84 1.81ZM8.74 8.65a1.38 1.38 0 0 0-1 .41l-1.26 1.28c-2.73 2.73-.87 9.2 4.06 14.13a18.82 18.82 0 0 0 8.14 5c2.59.68 4.72.35 6-.93L26 27.25a1.39 1.39 0 0 0 0-1.95l-2.44-2.44a1.39 1.39 0 0 0-1.95 0l-2.48 2.44a.92.92 0 0 1-1.1.15 23 23 0 0 1-4.77-3.69A23.31 23.31 0 0 1 9.57 17a.92.92 0 0 1 .14-1.11l2.44-2.44a1.38 1.38 0 0 0 0-1.95L9.71 9.06a1.37 1.37 0 0 0-.97-.41ZM30.28 11.6l-10.88-.07a.92.92 0 0 1-.92-.92V4.28a.92.92 0 0 1 1.84 0V9.7l10 .07a.92.92 0 1 1 0 1.83Z"
    />
    <path
      fill="currentColor"
      d="M25.78 16.53a.91.91 0 0 1-.65-.27.92.92 0 0 1 0-1.3l4.28-4.28-4.28-4.27a.92.92 0 0 1 1.3-1.3L31.36 10a.92.92 0 0 1 0 1.3l-4.93 4.93a.92.92 0 0 1-.65.3Z"
    />
  </svg>
)

