import IStore from 'library/interfaces/storeInterface';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getReportTypesAction } from 'store/actions';
import { IReportType } from 'store/reports/reducer';
import styled from 'styled-components';
import RightBar from '../organisms/rightBar';
import { emailsReportIsAvailableFor, queuesReportIsAvailableFor, whatsappUsageCountersToShow } from 'library/enableSpecificFeatures/reports';

interface IReportRightBarTemplateProps { }
const ReportRightBarTemplate: FC<IReportRightBarTemplateProps> = ({ }) => {
  const { t } = useTranslation('reports');
  const history = useHistory();
  const dispatch = useDispatch();
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const reportTypes = useSelector<IStore, IReportType[]>(
    store => store.reports.reportTypes.types,
  );

  const [hideRightBar, setHideRightBar] = useState(false)

  const handleReportTypeClick = (reportTypeId: string) => {
    if (reportTypeId === 'emailsReport') {
      setHideRightBar(true)
    }
    history.push(`/reports/${reportTypeId}`);
  };

  useEffect(() => {
    if (reportTypes.length > 0 || owner === '') return;
    dispatch(getReportTypesAction(owner));
  }, [owner]);

  return (
    <RightBar hide={hideRightBar} setHide={setHideRightBar}>
      <Button onClick={() => handleReportTypeClick('totalCallsByOrigin')}>
        {t(`rightBar.reportName.totalCallsByOrigin`)}
      </Button>
      {queuesReportIsAvailableFor[owner] && (
        <Button onClick={() => handleReportTypeClick('queuesReport')}>
          {t(`rightBar.reportTypesNameById.queuesReport`)}
        </Button>
      )}
      {emailsReportIsAvailableFor[owner] &&
        <Button onClick={() => handleReportTypeClick('emailsReport')}>
          {t(`rightBar.reportTypesNameById.emailsReport`)}
        </Button>
      }
      {whatsappUsageCountersToShow[owner] &&
        <Button onClick={() => handleReportTypeClick('whatsappUsage')}>
          {t(`rightBar.reportTypesNameById.whatsappUsage`)}
        </Button>
      }

      {reportTypes.map((type, index) => {
        if (
          type.id === '08B6C23E07984D059D8BF79F10270088' ||
          type.id === 'A2E0D406E04143E7BB8934A844A27F2A' ||
          type.id === '86160FA6ECE64BEA96C87EB0451AA67C' ||
          type.id === '4' ||
          type.id === '5' ||
          type.id === '6' ||
          type.id === '10'
        )
          return;
        if (type.id === '1') {
          return (
            <div key={index}>
              <Button key={index} onClick={() => handleReportTypeClick(type.id)}>
                {t(`rightBar.reportTypesNameById.${type.id}`)}
              </Button>
              <Button onClick={() => handleReportTypeClick('eventsByBotDetailed')}>
                {t(`rightBar.reportTypesNameById.eventsByBotDetailed`)}
              </Button>
            </div>
          )
        }
        return (
          <Button key={index} onClick={() => handleReportTypeClick(type.id)}>
            {t(`rightBar.reportTypesNameById.${type.id}`)}
          </Button>
        );
      })}
    </RightBar>
  );
};
const Button = styled.div`
  cursor: pointer;
  flex-grow: 0;
  position: relative;
  word-wrap: break-word;
  margin-bottom: 15px;
`;
export default ReportRightBarTemplate;
