import { primary } from 'library/colors';
import ErrorBoundary from 'library/errorBoundary/errorBoundary';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const InfinitePhoneToast = (data: { [key: string]: string }[] | string, closeToast: () => void) => {

  const [dataArray, setDataArray] = useState<{ title: string; value: string }[] | null>(null)
  const [dataString, setDataString] = useState<string | null>(null)



  useEffect(() => {
    if (typeof data === 'string') {
      setDataString(data)
    } else {
      const dataToRender: { title: string; value: string }[] = []

      data.forEach(item => {
        if (!(item instanceof Object)) return;
        for (const key in item) {
          dataToRender.push({ title: key, value: item[key] });
        }
      })
      setDataArray(dataToRender)
    }

  }, [])
  return (
    <ErrorBoundary>
      <Cont>
        <Exit onClick={() => closeToast()}>X</Exit>

        {dataArray && dataArray.map((item, i) => {
          return (
            <div key={i}>
              <h5>{item.title}</h5>
              <p>{item.value}</p>
            </div>
          )
        })}
        {dataString && <p>{dataString}</p>}


      </Cont>
    </ErrorBoundary>
  );
};
const Cont = styled.div`
  padding-top: 10px;
`;
const Exit = styled.div`
  top: 5px;
  right: 5px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
export default InfinitePhoneToast;