import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../FormContainer';
import { Col, Row } from 'reactstrap';
import Divider from '../../Divider';
import TagList from '../../Fields/TagsInput/TagList';

ViewResponses.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
};

function ViewResponses({ index, data }) {
  const { port, possibleResponses, responseKey } = data.responseRoutes[index];

  return (
    <FormContainer title="Response">
      <Row>
        <Col lg={12}>
          <div className="data-info-content">{port}</div>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col lg={12}>
          <div className="data-info-label">Response key</div>
          <div className="data-info-content">{responseKey}</div>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col lg={12}>
          <div className="data-info-label">Possible responses</div>
          <TagList tags={possibleResponses} />
        </Col>
      </Row>
    </FormContainer>
  );
}

export default ViewResponses;
