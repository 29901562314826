import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { darkBorder, primary } from 'library/colors';
import React, { FC } from 'react';
import { IError } from 'store/reports/reducer';
import styled from 'styled-components';

interface IGetReportButtonProps {
  url?: string;
  isLoading: boolean;
  error?: IError | null;
  buttonText: {
    Label: string;
    errorMessage: string;
    loadingMessage: string;
    downloadMessage: string;
    refresh: string;
  };
  onGetReport: Function;
  onDownloadReport: Function;
  onRefreshReport: Function;
}
const GetReportButton: FC<IGetReportButtonProps> = ({
  onGetReport,
  onDownloadReport,
  onRefreshReport,
  isLoading,
  buttonText,
  error,
  url,
}) => {
  const { Label, downloadMessage, loadingMessage, errorMessage, refresh } = buttonText;

  const handleRefresh = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onRefreshReport()
  };
  const handleDownload = () => {
    setTimeout(() => {
      onDownloadReport();
    }, 500);
  };
  const isGray = Label.includes('CSV')

  return (
    <>
      {!isLoading && !url && !error && (
        <Button isGray={isGray} onClick={() => onGetReport()}>{Label}</Button>
      )}
      {isLoading && (
        <P>
          {loadingMessage} <LoadingPoints />
        </P>
      )}
      {url && !isLoading && !error && (
        <A
          href={url}
          target={'_blank'}
          rel="noreferrer"
          onClick={e => handleDownload()}
        >
          <Button isGray={isGray}>{downloadMessage}</Button>
        </A>
      )}
      {!isLoading && !url && error && (
        <P>
          {errorMessage}
          <Refresh onClick={e => handleRefresh(e)}> {refresh}.</Refresh>
        </P>
      )}
    </>
  );
};
const Button = styled.div<IButton>`
  width: 100%;
  margin: 0 auto;
  padding: 8px 16px;
  border-radius: 50px;
  color: white;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  background-color: ${props => props.isGray ? darkBorder : primary};
`;
const P = styled.div`
  padding: 8px 16px;
  text-align: center;
`;
const A = styled.a`
  display: flex;
`;
const Refresh = styled.a``;
interface IButton {
  isGray: boolean
}
export default GetReportButton;
