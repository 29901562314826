import {
  delCloseSession,
  getClientMessages,
  getSessionLogs,
  getSessionMessages,
  getSessionsFromMonitor,
  getWaSession,
  restoreSession,
  transferSession,
} from 'helpers/fakebackend_helper';
import {
  DELETE_LIVE_CONVERSATION_SESSION,
  GET_AGENTS_CONNECTED_TO_WA,
  GET_LIVE_CONVERSATION_SESSION_LOGS,
  GET_LIVE_CONVERSATION_SESSION_MESSAGES,
  GET_SESSIONS_FROM_MONITOR,
  IGetAgentsConnectedToWa,
  IGetLiveConversationSessionLogs,
  IGetLiveConversationSessionMessages,
  IGetSessionsFromMonitor,
  IPutLiveConversationTransferSession,
  IPutRestoreWaSession,
  IdeleteLiveConversationSession,
  PUT_LIVE_CONVERSATION_TRANSFER_SESSION,
  PUT_RESTORE_WA_SESSION,
} from './actionTypes';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ISession,
  ISessionMessageResponse_HSM,
  ISessionMessageResponse_campaign,
  ISessionMessageResponse_contact,
} from 'library/interfaces/conversationInterfaces';
import {
  deleteLiveConversationSessionFail,
  deleteLiveConversationSessionSuccess,
  getAgentsConnectedToWaFail,
  getAgentsConnectedToWaSuccess,
  getLiveConversationSessionLogsFail,
  getLiveConversationSessionMessagesFail,
  getLiveConversationSessionMessagesSuccess,
  getLiveConversationsSessionLogsSuccess,
  getSessionFromMonitorFail,
  getSessionFromMonitorSuccess,
  putLiveConversationTranferSessionSuccess,
  putLiveConversationTransferSessionFail,
  putRestoreWaSessionFail,
  putRestoreWaSessionSuccess,
} from './actions';
import { isArrayJSON } from 'library/services/functions';
import { IError } from 'store/reports/reducer';
import {
  ILiveConversationActiveAgents,
  ILiveConversationAgents,
  ILiveConversationSession,
  ILiveConversationSessionLogs,
  ILiveConversationsCampaign,
} from './reducer';
import toast from 'react-hot-toast';

// from function* onGetClientMessages
function* onGetLiveConversationSessionMessage({
  payload: { channel, source, sessionId, templates },
}: IGetLiveConversationSessionMessages) {
  try {
    // el historico de mensajes solo trae los mensajes de el cliente
    // y los enviados  desde el chat de smartbot
    // no trae los mensajes de hsm ni los de campaña
    const res: {
      lastKey: string | null;
      messages: { [key: string]: string | number }[];
    } = yield call(getClientMessages, channel, source);

    const previousMessages = res.messages;

    /**
     *  todos reciben lastKey
     *
     *  casos:
     *
     *  1)  cliente que no ha recibido mensaje de camapaña ni hsm individual:
     *
     *        => solo recibe los mensajes.
     *        => no recibe hsm, ni campaign, ni contact.
     *
     *  2)  cliente que ha recibido un hsm individual desde workspace
     *      y no está registrado como contacto en la campaña:
     *
     *        => recibe mensajes y campaign (campaign contiene el template_id).
     *        => no recibe hsm ni contacts.
     *
     *  3)  cliente que está como contacto dentro de la campaña
     *
     *        => recibe mensaje, campaign, hsm y contact
     *            - hsm: tiene el template_id y los parametros
     *            - contact:  {
     *                          data: {} // contiene los datos del clientes guardados al crear la campaña
     *                          message: // tiene el adjunto del mensaje que se envió, revisar luego.
     *                        }
     */
    const sessionMessages: {
      lastKey: string | null;
      messages: { [key: string]: string | number }[];
      hsm?: ISessionMessageResponse_HSM;
      campaign?: ISessionMessageResponse_campaign;
      contact?: ISessionMessageResponse_contact;
    } = yield call(getSessionMessages, sessionId);

    const { messages, hsm, campaign, contact } = sessionMessages;

    /**
     * Caso 1: solo recibe message.
     *
     * Campaign viene en los casos 2 y 3 por lo que si campaign no existe
     * insertamos directamente en el store el historico de mensajes (previousMessages).
     *
     * Retornamos para no ejecutar el resto del código.
     */
    if (!campaign) {
      yield put(getLiveConversationSessionMessagesSuccess(previousMessages));
      return;
    }

    /**
     * resto de casos:
     *
     * buscamos el template utilizado en la campaña,
     * si no existe el template, insertamos directamente los mensajes previos.
     *
     * Retornamos para no ejecutar el resto del código.
     */
    let template = templates.find(temp => temp.id === campaign.template_id);

    if (!template) {
      yield put(getLiveConversationSessionMessagesSuccess(previousMessages));
      return;
    }

    /**
     * creamos la variable msg que contendra los mensajes de campaña y hsm cuando existe el template
     * seteamos source_name = campaign.name para que el nombre de la campaña aparezca como el remitente del mensaje
     * seteamos content = template.data
     */
    let msg = {
      id: 'id',
      idx: 'idx',
      content_type: 'text',
      content: template.data,
      source_id: 'system',
      source_name: campaign.name,
      source_type: 'system',
      source_timestamp: '',
      channel_id: channel,
      timestamp: '',
      created_at: '-',
    };

    /**
     * caso 2: recibe menssages y campaign, pero no hsm ni contact
     *
     * evaluamos que no exista el hsm, si no existe el hsm inserta msg en previousMessages
     * en el indice previousMessages.length - messages.length para que quede al inicio de los mensajes de sesión.
     *
     * Retornamos para no ejecutar el resto del código.
     */

    if (!hsm) {
      previousMessages.splice(
        previousMessages.length - messages.length,
        0,
        msg,
      );
      yield put(getLiveConversationSessionMessagesSuccess(previousMessages));
      return;
    }

    /**
     * caso 3: recibe messages, campaign, hsm y contact
     *
     *  si existe contact y contact.data =! de null
     *  insertamos la data en la sesion y en phone data para mostrarla en workspace Right bar
     *
     * comprobamos que hsm.params sea un array de string stringify y lo parseamos para guardar en la constante params
     */

    // if (contact?.data) {
    //   yield put(updateWaSessionClientDataAction(sessionId, contact.data));
    //   yield put(updatePhoneE64DataAction([contact.data]));
    // }

    if (contact?.message?.type === 'image') {
      const msg2 = structuredClone(msg);
      msg2.content_type = 'image';
      msg2.content = JSON.stringify({ url: contact.message.image.link });
      previousMessages.splice(
        previousMessages.length - messages.length,
        0,
        msg2,
      );
    } else if (contact?.message?.type === 'document') {
      const msg2 = structuredClone(msg);
      msg2.content_type = 'text';
      msg2.content = JSON.stringify({
        type: 'file',
        url: contact.message.document.link,
      });
      previousMessages.splice(
        previousMessages.length - messages.length,
        0,
        msg2,
      );
    }
    const params: string[] = isArrayJSON(hsm.params)
      ? JSON.parse(hsm.params)
      : [];

    /**
     * si la longitud de params es cero,
     * insertamos el mensaje que ya viene con los datos de campaign
     * sin tratar las variables del content
     *
     * Y retornamos para que no se ejecute el resto del código
     */

    if (params.length === 0) {
      previousMessages.splice(
        previousMessages.length - messages.length,
        0,
        msg,
      );
      yield put(getLiveConversationSessionMessagesSuccess(previousMessages));
      return;
    }

    /**
     * se crea la expresion regular que haga el match con las variables dentro del template.data.
     * en la variable splitedMessage se utiliza la expresión regular para separar el template en string y variables.
     * se inicializa un contador en 0.
     *
     * se realiza un forEach a splitedMessage y se evalua cada segmento
     */
    const regEx = /({{+\w+}})/g;
    const splitedMessage = template.data.split(regEx);
    let currentParamIndex = 0;

    /**
     * se recorre el array splited message
     * y se evalua con la expresión regular si cada segmento de texto es un string de texto o una variable
     *
     * si es una variable se sustituye
     */
    splitedMessage.forEach((item, index) => {
      if (regEx.test(item)) {
        splitedMessage.splice(index, 1, params[currentParamIndex]);
        currentParamIndex++;
      }
    });

    msg.content = campaign.source + ': ' + splitedMessage.join('');

    previousMessages.splice(previousMessages.length - messages.length, 0, msg);

    yield put(getLiveConversationSessionMessagesSuccess(previousMessages));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the messages',
      details: `${e}`,
    };
    yield put(getLiveConversationSessionMessagesFail(error));
  }
}

function* onGetLiveConversationSessionLogs({
  payload: { sessionId },
}: IGetLiveConversationSessionLogs) {
  try {
    const res: ILiveConversationSessionLogs[] = yield call(
      getSessionLogs,
      sessionId,
    );

    yield put(getLiveConversationsSessionLogsSuccess(res));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting the logs',
      details: `${e}`,
    };
    yield put(getLiveConversationSessionLogsFail(error));
  }
}

function* onLiveConversationTransferSession({
  payload: { agentId, sessionId },
}: IPutLiveConversationTransferSession) {
  try {
    yield call(transferSession, sessionId, agentId);

    const session: ILiveConversationSession = yield call(
      getWaSession,
      sessionId,
    );

    yield put(putLiveConversationTranferSessionSuccess(session));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong on transfer session',
      details: `${e}`,
    };
    toast.error('No se pudo transferir la sesión');
    yield put(putLiveConversationTransferSessionFail(error, sessionId));
  }
}

function* onGetLiveConversationSessions({
  payload: { isActive, campaign, assignedTo },
}: IGetSessionsFromMonitor) {
  try {
    let lastKey: string | null = '';
    let sessions: ILiveConversationSession[] = [];
    let camp: ILiveConversationsCampaign | undefined = undefined;

    do {
      const res: {
        agent: ILiveConversationAgents;
        sessions: ILiveConversationSession[];
        lastKey: string | null;
        campaign?: ILiveConversationsCampaign;
      } = yield call(
        getSessionsFromMonitor,
        isActive,
        lastKey,
        campaign,
        assignedTo,
      );

      if (res.agent && res.agent.activeAgents) {
        yield put(getAgentsConnectedToWaSuccess(res.agent));
      }

      if (res.campaign && !camp) camp = res.campaign;

      sessions.push(...res.sessions);
      lastKey = res.lastKey;
    } while (lastKey !== null);

    const sortByDate = (a: ISession, b: ISession) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();

    sessions.sort(sortByDate);

    yield put(getSessionFromMonitorSuccess(sessions, lastKey, camp));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting sessions',
      details: `${e}`,
    };
    toast.error('No se pudieron traer las sessiones');
    yield put(getSessionFromMonitorFail(error));
  }
}

function* onDeleteLiveConversationSession({
  payload: { sessionId },
}: IdeleteLiveConversationSession) {
  try {
    yield call(delCloseSession, sessionId);
    yield put(deleteLiveConversationSessionSuccess(sessionId));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong',
      details: `${e}`,
    };
    toast.error('No se pudo eliminar la sesión');
    yield put(deleteLiveConversationSessionFail(error));
  }
}

function* onGetAgentsConnectedToWa({
  payload: { campaignId },
}: IGetAgentsConnectedToWa) {
  try {
    const res: { agent: ILiveConversationAgents } = yield call(
      getSessionsFromMonitor,
      undefined,
      undefined,
      campaignId,
    );
    yield put(getAgentsConnectedToWaSuccess(res.agent));
  } catch (e) {
    console.log(e);
    yield put(getAgentsConnectedToWaFail());
  }
}

function* onPutRestoreWaSession({
  payload: { sessionId },
}: IPutRestoreWaSession) {
  try {
    yield call(restoreSession, sessionId);
    yield put(putRestoreWaSessionSuccess(sessionId));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong',
      details: `${e}`,
    };
    toast.error('No se pudo restaurar la sesión');
    yield put(putRestoreWaSessionFail(error));
  }
}

function* liveConversationsSaga() {
  yield takeEvery(
    GET_LIVE_CONVERSATION_SESSION_MESSAGES,
    onGetLiveConversationSessionMessage,
  );
  yield takeEvery(
    GET_LIVE_CONVERSATION_SESSION_LOGS,
    onGetLiveConversationSessionLogs,
  );
  yield takeEvery(
    PUT_LIVE_CONVERSATION_TRANSFER_SESSION,
    onLiveConversationTransferSession,
  );
  yield takeEvery(GET_SESSIONS_FROM_MONITOR, onGetLiveConversationSessions);
  yield takeEvery(
    DELETE_LIVE_CONVERSATION_SESSION,
    onDeleteLiveConversationSession,
  );
  yield takeEvery(GET_AGENTS_CONNECTED_TO_WA, onGetAgentsConnectedToWa);
  yield takeEvery(PUT_RESTORE_WA_SESSION, onPutRestoreWaSession);
}
export default liveConversationsSaga;
