import React, { memo } from 'react';
import RightHandle from '../common/RightHandle';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
export const SceneryStart = memo(({ data, isConnectable }) => {
  return (
    <>
      <div className="node-circle-start">
        <RightHandle
          id="route_start"
          isConnectable={isConnectable}
          type="source"
          bg="green2"
        />
      </div>
    </>
  );
});

SceneryStart.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
