import React from "react";
import styled from "styled-components";

import CounterWithLabel from "../atoms/counterWithLabel";

import { blueGraph, danger } from "library/colors";
import { useTranslation } from "react-i18next";

const CallCounter = () => {
  const {t} = useTranslation('outbound')
  return(
    <Cont>
      <CounterWithLabel label={t('campaigns.campaign.success')}/>
      <CounterWithLabel label={t('campaigns.campaign.left')} backgroundColor={'orange'}/>
      <CounterWithLabel label={t('campaigns.campaign.fail')} backgroundColor={danger}/>
    </Cont>
  )
}
const Cont = styled.div`
  flex-grow: 1;
  max-width: 280px;
  display: flex;
  justify-content: space-between;
`;
export default CallCounter