import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../FormContainer';
import { Col, Row } from 'reactstrap';
import Divider from '../../Divider';

ViewInputSettings.propTypes = {
  data: PropTypes.object,
};

function ViewInputSettings({ data }) {
  return (
    <>
      <FormContainer title="Input Settings">
        <Row>
          <Col lg={6}>
            <div className="data-info-label">Input type</div>
            <span className="badge bg-primary">{data?.inputType}</span>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col lg={12}>
            <div className="data-info-label">Recognition language</div>
            <div className="data-info-content">
              {data?.recognitionLanguage?.label || '-'}
            </div>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col lg={6}>
            <div className="data-info-label">Start Recognition</div>
            <div className="data-info-content">
              {data?.startRecognition?.label || '-'}
            </div>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col lg={12}>
            <div className="data-info-label">Recognition language</div>
            <div className="data-info-content">
              {data?.DTMFType?.label || '-'}
            </div>
          </Col>
        </Row>
      </FormContainer>
    </>
  );
}

export default ViewInputSettings;
