import { IExecutionDayButton } from 'library/interfaces/campaignInterfaces';
import React, { FC } from 'react';
import styled from 'styled-components';
import ExcludeDayButton from '../atoms/excludeDayButton';

interface IExecutionHoursProps {
  executionDays: IExecutionDayButton[];
  onDeleteHours: (dayIndex: number, timeIndex: number) => void;
}
const ExecutionHours: FC<IExecutionHoursProps> = ({
  executionDays,
  onDeleteHours,
}) => {
  return (
    <Cont>
      {executionDays.map((day, dayIndex) => {
        return (
          <HoursCont key={dayIndex}>
            {day.execution_time.map((time, timeIndex) => {
              return (
                <ExcludeDayButton
                  key={timeIndex}
                  index={timeIndex}
                  margin={'0 2px 0 0'}
                  padding={'2px 2px 2px 4px'}
                  onClick={() => onDeleteHours(dayIndex, timeIndex)}
                >
                  {time.start_time + ' - ' + time.end_time}
                </ExcludeDayButton>
              );
            })}
          </HoursCont>
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  margin: 0 auto;
  max-width: 210px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const HoursCont = styled.div`
  background-color: white;
  display: flex;
  min-height: 25.5px;
`;
export default ExecutionHours;
