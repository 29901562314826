import React from 'react';
import PropTypes from 'prop-types';

Content.propTypes = {
  children: PropTypes.any,
};

export function Content({ children }) {
  return <div className="data-info-content">{children}</div>;
}
