import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getScenariosV2,
  getSceneryByIdV2,
  putUpdateSceneryV2,
  getSceneryFunctions,
  getSceneryIntents,
  postSceneryV2,
} from 'helpers/fakebackend_helper';
import {
  ICreateScenario,
  IFunction,
  IIntent,
  IScenario,
  IScenarioGeneralData,
} from 'library/interfaces/scenariosInterfaces';

export const getScenarios = createAsyncThunk<IScenarioGeneralData[]>(
  'scenariosV2/getScenarios',
  async () => {
    return await getScenariosV2();
  },
);

export const getScenarioById = createAsyncThunk<IScenario, string>(
  'scenariosV2/getScenarioById',
  async (id: string) => {
    return await getSceneryByIdV2(id);
  },
);

export const addScenario = createAsyncThunk<string, ICreateScenario>(
  'scenariosV2/addScenario',
  async (scenario: ICreateScenario) => {
    return await postSceneryV2(scenario);
  },
);

export const updateScenario = createAsyncThunk(
  'scenariosV2/updateScenario',
  async ({
    scenarioId,
    scenario,
  }: {
    scenarioId: string;
    scenario: ICreateScenario;
  }) => {
    return await putUpdateSceneryV2(scenarioId, scenario);
  },
);

export const getFunctions = createAsyncThunk<IFunction[]>(
  'scenariosV2/getFunctions',
  async () => {
    return await getSceneryFunctions();
  },
);
export const getIntents = createAsyncThunk<IIntent[]>(
  'scenariosV2/getIntents',
  async () => {
    return await getSceneryIntents();
  },
);

interface IScenariosState {
  isLoading: boolean;
  isEdited: boolean;
  scenarios: IScenarioGeneralData[];
  scenario: IScenario | null;
  newScenarioId: string | null;
  error: string | null;
  functions: IFunction[];
  intents: IIntent[];
}

const initialState: IScenariosState = {
  isLoading: false,
  isEdited: false,
  scenarios: [],
  scenario: null,
  newScenarioId: null,
  error: null,
  functions: [],
  intents: [],
};
const scenariosV2Slice = createSlice({
  name: 'scenariosV2',
  initialState,
  reducers: {
    updateIsEdited: (state, action: PayloadAction<boolean>) => {
      state.isEdited = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // get all
      .addCase(getScenarios.pending, state => {
        state.isLoading = true;
        state.error = null;
        state.scenario = null;
      })
      .addCase(getScenarios.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scenarios = action.payload;
      })
      .addCase(getScenarios.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || '';
      })
      // get by id
      .addCase(getScenarioById.pending, state => {
        state.isLoading = true;
        state.error = null;
        state.scenarios = [];
        state.scenario = null;
      })
      .addCase(getScenarioById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scenario = action.payload;
      })
      .addCase(getScenarioById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || '';
      })
      // add scenario
      .addCase(addScenario.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addScenario.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newScenarioId = action.payload;
      })
      .addCase(addScenario.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || '';
      })
      //update Scenario
      .addCase(updateScenario.pending, state => {
        state.isLoading = true;
        state.error = null;
        state.scenario = null;
      })
      .addCase(updateScenario.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(updateScenario.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || '';
      })
      // functions
      .addCase(getFunctions.fulfilled, (state, action) => {
        state.functions = action.payload;
      })
      .addCase(getFunctions.rejected, (state, action) => {
        state.error = action.error.message || '';
      })
      // intents
      .addCase(getIntents.fulfilled, (state, action) => {
        state.intents = action.payload;
      })
      .addCase(getIntents.rejected, (state, action) => {
        state.error = action.error.message || '';
      });
  },
});

export const { updateIsEdited } = scenariosV2Slice.actions;

export default scenariosV2Slice.reducer;
