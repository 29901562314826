import React from 'react';
import { Handle } from 'react-flow-renderer';
import PropTypes from 'prop-types';

import { COLORS } from './constants';

function RightHandle({ id, text = '', type = 'source', isConnectable, bg }) {
  return (
    <div className="node-right-handle">
      <span
        className="node-handle-text"
        style={{
          backgroundColor: text ? COLORS[bg] : 'transparent',
        }}
      >
        {text}
      </span>
      <Handle
        type={type}
        position="right"
        id={id}
        style={{ backgroundColor: COLORS[bg] }}
        isConnectable={isConnectable}
      />
    </div>
  );
}

RightHandle.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.string,
  isConnectable: PropTypes.bool,
  bg: PropTypes.string,
};

export default RightHandle;
