
import * as React from 'react';

export const AnswerCall = props => (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 245.49 228.09"
    {...props}
>
    {/* <title>Answer Call</title> */}
    <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_8" data-name="Capa 8">
            <path fill='none' d="M168.73,131.05h20.53c0-42.62-37.37-77.29-83.32-77.29v18C140.56,71.75,168.73,98.35,168.73,131.05Z"/>
            <path fill='none' d="M207.65,131.05H227.1c0-62.12-54.35-112.66-121.16-112.66v17C162,35.37,207.65,78.29,207.65,131.05Z"/>
            <path fill='currentColor' d="M105.94,71.75V90.14c24.48,0,44.4,18.35,44.4,40.91h18.39C168.73,98.35,140.56,71.75,105.94,71.75Z"/>
            <path fill='currentColor' d="M105.94,35.37V53.76c45.95,0,83.32,34.67,83.32,77.29h18.39C207.65,78.29,162,35.37,105.94,35.37Z"/>
            <path fill='currentColor' d="M105.94,0V18.39c66.81,0,121.16,50.54,121.16,112.66h18.39C245.49,58.79,182.89,0,105.94,0Z"/>
            <path fill='currentColor' d="M75.32,192S64.65,183.91,56,176.05s-18.51-18.32-18.51-18.32C19.62,137.83,10.4,120,4.92,105.59c-.23-.62-.46-1.23-.68-1.84,0-.07,0-.13-.07-.19-.86-2.37-1.66-4.64-2.31-6.81C-1.56,85.41.29,76.22,3,70a31.47,31.47,0,0,1,3.93-6.65l1.5-1.72L21.84,46.26a14.07,14.07,0,0,1,19.84-1.33L69.91,69.61a14.08,14.08,0,0,1,1.33,19.85L51.56,112c4.38,6.16,8.78,12,13.87,17.8,0,0,13.08,12.9,18.58,17.79s19.6,16.2,19.62,16.22A237.6,237.6,0,0,0,133,181l18.59-21.27a14.07,14.07,0,0,1,19.84-1.33L199.65,183A14,14,0,0,1,201,202.88l-3.89,4.9s-11.33,12.51-11.79,13c-3.86,4.41-17.72,7.27-27.26,7.27a31,31,0,0,1-6.73-.69,201.7,201.7,0,0,1-23.89-7l-1.33-.49q-3.34-1.24-6.84-2.7l-2.39-1-2.3-1c-1.54-.69-3.09-1.42-4.67-2.17-1-.47-2-1-2.94-1.45q-2.61-1.31-5.29-2.75l-1.54-.84-2.43-1.36c-3-1.71-6.07-3.56-9.18-5.54Q82.06,197,75.32,192Z"/>
        </g>
    </g>
</svg>
);