import React, { memo } from 'react';

import NodeLayout from '../common/NodeLayout';
import RightHandle from '../common/RightHandle';
import LeftHandle from '../common/LeftHandle';
import Modal from './Modal';
import PropTypes from 'prop-types';

const color = 'blue';

// eslint-disable-next-line react/display-name
export const CallProperties = memo(({ data, isConnectable }) => {
  return (
    <NodeLayout icon="CallProperties" data={data} Modal={Modal} color={color}>
      <LeftHandle
        isConnectable={isConnectable}
        onConnect={() => {}}
        id="route_in"
      />

      <RightHandle
        id="route_out"
        isConnectable={isConnectable}
        type="source"
        text="Out"
        bg="blue"
      />
    </NodeLayout>
  );
});

CallProperties.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
