import {
  IQueue,
  IQueueUsers,
  IReportData,
  IUser,
} from 'library/interfaces/crdInterfaces';
import {
  ILiveQueuedCalls,
  IModelQueuedCallsData,
  IQueueCallEventFromAppSync,
  IQueueCallEventToApp,
  IQueuedCall,
} from 'library/interfaces/liveQueuedCallsInterfaces';
import {
  IAgensStateCounter,
  ILiveBoardQueue,
} from 'library/interfaces/liveboardInterface';
import { agentsStatusCounter } from './liveBoardService';
import { ISummarizeAgent } from 'library/interfaces/summarizeInterface';

export const queuedCallsService = (
  reportData: IReportData,
  liveData: ILiveQueuedCalls[],
): IModelQueuedCallsData[] => {
  const { queues, users, queueUsers } = reportData;
  const data = queues.map(queue => {
    const newQueue: ILiveBoardQueue = { ...queue, uid: '' };
    let agents_state: IAgensStateCounter = {
      total: 0,
      incall: 0,
      ringing: 0,
      onhold: 0,
      available: 0,
      unavailable: 0,
      connected: 0,
    };
    const liveQueue = liveData.find(item => item.id === queue.id);

    if (!liveQueue) {
      return {
        queue: newQueue,
        agents_state,
        calls: [],
      };
    }

    const counter = agentsStatusCounter(liveQueue.agentsStatus);
    const total = liveQueue.agentsStatus.length;
    const connected =
      liveQueue.agentsStatus.length - counter.unavailable - counter.onhold;

    const calls = addAgentNameToCallEvents(users, queueUsers, liveQueue.calls);

    newQueue.uid = liveQueue.uid;
    agents_state = {
      ...agents_state,
      ...counter,
      total,
      connected,
    };

    return {
      queue: newQueue,
      agents_state,
      calls: sortCallByUniqueId(calls),
    };
  });
  return data;
};

export const addAgentNameToCallEvents = (
  users: IUser[],
  queueUsers: IQueueUsers,
  calls: IQueuedCall<IQueueCallEventFromAppSync>[],
): IQueuedCall<IQueueCallEventToApp>[] => {
  const newCalls = calls.map(call => {
    const eventsWithName = call.events.map(event => {
      const agent = users.find(user => user.exten === event.agent);
      const name = agent ? agent.name : '';
      return {
        ...event,
        agent: queueUsers[event.agent]?.exten || event.agent,
        name: queueUsers[event.agent]?.name || name,
      };
    });
    return {
      ...call,
      events: eventsWithName,
    };
  });
  return newCalls;
};

export const queuesChangeIdValueToUidValue = (
  queues: IQueue[],
  liveData: ILiveQueuedCalls[],
): IQueue[] => {
  const newQueues = queues.map(queue => {
    const liveQueue = liveData.find(item => item.id === queue.id);
    if (!liveQueue) return queue;
    return { ...queue, id: liveQueue.uid };
  });
  return newQueues;
};

export const agentsDataQueuedCallsDetailed = (
  LiveData: ILiveQueuedCalls[],
  reportData: IReportData,
  uid: string,
): ISummarizeAgent[] => {
  const live = LiveData.find(item => item.uid === uid);

  const connectedAgents = live
    ? live.agentsStatus.filter(item => item.state !== 'unavailable')
    : [];

  const agents = connectedAgents.map(item => {
    const agentInfo = reportData.users.filter(
      user => user.exten_name === item.id,
    )[0];

    return {
      ...agentInfo,
      agent: item.id,
      state: item.state,
      serviceLevel: {
        answered: item.answeredCount,
        unanswered: item.unansweredCount,
        serviceLevel: item.serviceLevel === -1 ? 0 : item.serviceLevel,
        availableDuration: item.availableDuration
      },
    };
  });
  return agents;
};

export const sortCallByUniqueId = (
  calls: IQueuedCall<IQueueCallEventToApp>[],
): IQueuedCall<IQueueCallEventToApp>[] => {
  const comparefn = (
    a: IQueuedCall<IQueueCallEventToApp>,
    b: IQueuedCall<IQueueCallEventToApp>,
  ): 0 | 1 | -1 => {
    const first = parseFloat(a.uniqueid.slice(a.uniqueid.lastIndexOf('-') + 1));
    const second = parseFloat(
      b.uniqueid.slice(b.uniqueid.lastIndexOf('-') + 1),
    );
    if (first < second) {
      return -1;
    }
    if (first > second) {
      return 1;
    }
    return 0;
  };
  return calls.sort(comparefn);
};
