import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';
import { lightBackgroundBlue, primary } from 'library/colors';
import React, { FC } from 'react';
import { Node } from 'reactflow';
import styled from 'styled-components';
import Variable from './variable';
import { AddButtonModalSC, BlueContModalSC, SectionTitleModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';

interface ISessionVariablesModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
}
const SessionVariablesModal: FC<ISessionVariablesModalProps> = ({
  selectedNode,
  setSelectedNode,
}) => {
  const { data } = selectedNode;

  const handleAddVariable = () => {
    const newVariable = { key: '', value: '' };
    const sessionVariables = data.sessionVariables || [];

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        sessionVariables: [...sessionVariables, newVariable],
      },
    };
    setSelectedNode(editedNode);
  };

  const handleDeleteVariable = (index: number) => {
    const variables = structuredClone(selectedNode.data.sessionVariables) || [];
    variables.splice(index, 1)

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        sessionVariables: [...variables],
      },
    };
    setSelectedNode(editedNode);
  }

  const handleVariableChange = (
    index: number,
    name: 'key' | 'value',
    value: string,
  ) => {
    const variables = structuredClone(selectedNode.data.sessionVariables) || [];

    variables[index][name] = value;
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        sessionVariables: [...variables],
      },
    };
    setSelectedNode(editedNode);
  };
  return (
    <BlueContModalSC>
      <SectionTitleModalSC>Session Variables</SectionTitleModalSC>
      {data.sessionVariables?.map((variable, index) => {
        return (
          <Variable
            key={index}
            index={index}
            data={variable}
            handleVariableChange={handleVariableChange}
            handleDeleteVariable={handleDeleteVariable}
          />
        );
      })}
      <AddButtonModalSC onClick={() => handleAddVariable()}>
        <i className="bx bxs-plus-circle" /> <span>Add Variable</span>
      </AddButtonModalSC>
    </BlueContModalSC>
  );
};
export default SessionVariablesModal;
