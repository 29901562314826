import { danger, darkBorder, lightBackgroundBlue, lightGrayBackground } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import React, { FC } from 'react';
import { deleteFileAction } from 'store/files/asyncActions';
import styled from 'styled-components';

interface IGalleryItemProps {
  filename: string
  index: number
  url: string
  fileId: string
}
const GalleryItem: FC<IGalleryItemProps> = ({ filename, index, url, fileId }) => {
  const dispatch = useAppDispatch()

  const isPDF = filename.endsWith('.pdf')

  const handleDeleteFile = () => {
    dispatch(deleteFileAction(fileId))
  }
  return (
    <Cont>

      <ImgCont>
        {
          isPDF ?
            <Embed src={url} type="application/pdf" />
            :
            <Img alt={index + filename} src={url} />

        }
      </ImgCont>
      <FileName isPDF={filename.endsWith('.pdf')}>
        {filename.substring(0, 24)}
        <DelIcon onClick={() => handleDeleteFile()}>
          <i className='bx bxs-trash'></i>
        </DelIcon>
      </FileName>
    </Cont>
  );
};
const Cont = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;
const ImgCont = styled.div`
  aspect-ratio: 16/9;
  overflow: hidden;
  
`;
const Img = styled.img`
  width: 100%;
`;
const Embed = styled.embed`
  width: 100%;
  overflow: hidden;
  
`;
const FileName = styled.div<IFileName>`
height: 30px;
  color: black;
  text-align: center;
  word-wrap: break-word;
  display: grid;
  place-items: center;
  background-color: ${props => props.isPDF ? lightBackgroundBlue : lightBackgroundBlue};
  position: relative;
`;
const DelIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 4px;
  font-size: 1rem;
  cursor: pointer;

  &:hover{
    color: ${danger}
  }
`;
interface IFileName {
  isPDF: boolean
}

export default GalleryItem;