import React, { memo } from 'react';

import NodeLayout from '../common/NodeLayout';
import RightHandle from '../common/RightHandle';
import LeftHandle from '../common/LeftHandle';
import Modal from './Modal';
import PropTypes from 'prop-types';

const color = 'blue';

// eslint-disable-next-line react/display-name
export const CallForwarding = memo(({ data, isConnectable }) => {
  return (
    <NodeLayout icon="CallForwarding" data={data} Modal={Modal} color={color}>
      <LeftHandle
        isConnectable={isConnectable}
        onConnect={() => {}}
        id="route_in"
      />

      <RightHandle
        id="route_fail"
        isConnectable={isConnectable}
        type="source"
        text="fail"
        bg="red"
      />
      <RightHandle
        id="route_success"
        isConnectable={isConnectable}
        type="source"
        text="success"
        bg="green2"
      />
    </NodeLayout>
  );
});

CallForwarding.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
