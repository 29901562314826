import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { darkBorder } from 'library/colors';

import { AnswerCall } from 'icons';
import { useTranslation } from 'react-i18next';
import CallCounter from '../molecules/callCounter';
import CampaignInfo from '../atoms/campaignInfo';
import StateAndButton from '../molecules/stateAndButton';
import Indicators from '../molecules/indicators';
import { ICampaign } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import CampaignDetails from './campaignDetails';
import { useDispatch } from 'react-redux';
import { getCampaignByIdAction, getCampaignTagsSummarizedAction, refreshCampaignAction } from 'store/actions';
import CampaignTagsCount from './campaignTagsCount';

interface ICampaignProps {
  campaign: ICampaign;
  campaignIndex: number;
  setShowCampaignForm: (
    show: boolean,
    campaignToEdit?: null | ICampaign,
  ) => void;
}
const Campaign: FC<ICampaignProps> = ({
  campaign,
  campaignIndex,
  setShowCampaignForm,
}) => {
  // i18n translation
  //const { namespace, key } = i18nKeys;
  const { t } = useTranslation('outbound');
  const textI18N = {
    scenario: t('campaigns.campaign.scenario'),
    from: t('campaigns.campaign.from'),
    to: t('campaigns.campaign.to'),
    callerId: 'Caller Id',
    did: 'Did'
  };

  const [showCampaignDetails, setShowCampaignDetails] = useState(false);
  const [showCampaignTagsCount, setShowCampaignTagsCount] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0);

  const dispatch = useDispatch()

  const handleViewReportClick = () => {
    dispatch(getCampaignTagsSummarizedAction(campaignIndex, campaign.id))
    setShowCampaignTagsCount(true)
  }

  useEffect(() => {
    const { status } = campaign;
    if(refreshCount > 5) return
    if (!status.endsWith('ing') && status !== 'published' || status === 'running') return
    
    const t = setTimeout(()=>{
      dispatch(refreshCampaignAction(campaign.id, campaignIndex))
      setRefreshCount(refreshCount + 1)
    }, 5 * 1000)
    return () => clearTimeout(t)
  }, [refreshCount, campaign.status]);

  return (
    <Cont>
      {showCampaignDetails && (
        <CampaignDetails
          campaign={campaign}
          setShowCampaignDetails={setShowCampaignDetails}
        />
      )}
      {showCampaignTagsCount && (
        <CampaignTagsCount
        campaignName={campaign.name}
        tagsSummarized={campaign.tagsSummarized}
        setShowCampaignTagsCount={setShowCampaignTagsCount}
        />
      )}
      <FlexCont justifyContent={'space-between'} marginBottom={'40px'}>
        <CampaignInfo
          textI18N={textI18N}
          title={campaign.name}
          from={campaign.date_start}
          to={campaign.date_end}
          status={campaign.status}
          callerId = {campaign.callerid}
          did={campaign.did}
        />
        <StateAndButton
          campaign={campaign}
          campaignIndex={campaignIndex}
          showCampaignForm={setShowCampaignForm}
          setShowCampaignDetails={setShowCampaignDetails}
          onViewReportClick={handleViewReportClick}
        />
      </FlexCont>
      <FlexCont>
        <CallCounter />
        {/* <Indicators /> */}
      </FlexCont>
    </Cont>
  );
};
const Cont = styled.div`
  border-radius: 10px;
  padding: 20px 50px 20px 90px;
  color: black;
  max-width: 1100px;
  margin: 0 auto 20px;
  border: 1px solid ${darkBorder};
`;
const FlexCont = styled.div<FlexCont>`
  display: flex;
  margin-bottom: ${props => props.marginBottom && props.marginBottom};
  justify-content: ${props => props.justifyContent && props.justifyContent};
`;
interface FlexCont {
  marginBottom?: string;
  justifyContent?: string;
}
export default Campaign;
