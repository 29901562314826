import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  darkBorder,
  lightBackground,
  lightBackgroundBlue,
  primary,
} from 'library/colors';
import PhoneHorizontalButtons from '../molecules/phoneHorizontalButtons';
import { Phone } from 'library/phone/phone';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';
import { IPhoneStore } from 'store/phone/reducer';
import TimeCounter from '../atoms/timeCounter';
import SelectedChat from '../molecules/selectedChat';
import { useTranslation } from 'react-i18next';
import SelectedConversation from '../molecules/selectedConversation';
import {
  addNewMEssageAction,
  addNewSessionAction,
  postSendMessageAction,
} from 'store/actions';
import {
  ISendMessage,
  ISession,
} from 'library/interfaces/conversationInterfaces';
import { subscribeWaChannel } from 'helpers/appSyncConversations';
import { genIdx } from 'library/services/functions';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { conversationInWorkspaceIsEnabled, whatsappIsEnabled } from 'library/enableSpecificFeatures/conversationWorkspace';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import ImagePicker from '../molecules/imagesPicker';
import AudioRecording from '../molecules/audioRecording';
import { sysNotification } from 'library/notifications/notifications';
import { deleteUserConnection, postUserConnection } from 'helpers/fakebackend_helper';

interface IRightBlock {
  phone: Phone;
}
const RightBlock: FC<IRightBlock> = ({ phone }) => {
  const { t } = useTranslation('workspace');
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const phoneStore = useSelector<IStore>(state => state.phone) as IPhoneStore;
  const { owner, auth0_id, exten, display_name } = useAppSelector(store => store.authUserData);
  const selectedSession = useSelector<IStore, string>(
    store => store.conversations.selectedSession,
  );
  const { areAllowed, showNewWaMessage, showNewWaSession } = useAppSelector(store => store.global.sysNotifications)

  const [idxs, setIdxs] = useState<string[]>([])

  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showImagesPicker, setShowImagesPicker] = useState(false)
  const [showAudioRecording, setShowAudioRecording] = useState(false)

  const handleInputChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(e.target instanceof HTMLInputElement)) return;
    if (showEmojiPicker) setShowEmojiPicker(false)
    if (e.key === 'Enter' && selectedSession) handleSendMessage();
  };

  const handleEmojiClick = (event: EmojiClickData) => {
    if (!inputRef.current) return;

    inputRef.current.value = inputRef.current.value + event.emoji
    console.log(event.emoji)
  }

  const handleIconPickerClick = (picker: 'emoji' | 'images' | 'audio') => {
    if (picker === 'emoji') {
      setShowEmojiPicker(prev => !prev)
      setShowImagesPicker(false)
      setShowAudioRecording(false)
      return
    } else if (picker === 'audio') {
      if (!selectedSession) return
      setShowImagesPicker(false)
      setShowEmojiPicker(false)
      setShowAudioRecording(prev => !prev)
      return
    }
    setShowImagesPicker(prev => !prev)
    setShowEmojiPicker(false)
    setShowAudioRecording(false)
  }

  const handleSendMessage = () => {
    if (!inputRef.current) return;

    const text = inputRef.current.value;

    if (!text || !selectedSession) return;
    const message: ISendMessage = {
      content: text,
      created_at: new Date().toISOString(),
      source_name: display_name,
      source_type: 'agent',
    };
    dispatch(postSendMessageAction(selectedSession, message));

    inputRef.current.value = '';
  };


  useEffect(() => {
    if (exten || !conversationInWorkspaceIsEnabled[owner]) return;

    conversationInWorkspaceIsEnabled[owner].forEach(channel => {
      genIdx(channel.value, auth0_id, (val: string) => {
        setIdxs(prev => [...prev, val])
      })
      genIdx(channel.value, 'empty', (val: string) => {
        setIdxs(prev => [...prev, val])
      })
    })

  }, [auth0_id])
  //
  // maneja las nuevas sesiones
  //
  useEffect(() => {
    if (exten || !conversationInWorkspaceIsEnabled[owner] || idxs.length !== conversationInWorkspaceIsEnabled[owner].length * 2) return

    const subs = idxs.map((idx, index) => {
      return subscribeWaChannel(idx,
        (data: { eventType: string; payload: string }[]) => {
          data.map(item => {
            if (index === 0 && item.eventType === 'new-message') {
              const newMessage = JSON.parse(item.payload)
              dispatch(addNewMEssageAction(newMessage));
              if (areAllowed && showNewWaMessage) {
                sysNotification('Tienes un nuevo mensaje!!!', `Nombre: ${newMessage['source_name']} \n\nNumero: ${newMessage['source_id']}`)
              }
            }
            else if (index === 1 && item.eventType === 'new-session') {
              const newSession: ISession = JSON.parse(item.payload);
              dispatch(addNewSessionAction(newSession));
              if (areAllowed && showNewWaSession) {
                sysNotification('Tienes una nueva conversación en espera!!! ', `Nombre: ${newSession['source_name']} \n\nNumero: ${newSession['source_id']}`)
              }
            }
          });
        },
      );
    })
    return () => subs.forEach(sub => sub.unsubscribe())
  }, [owner, showNewWaSession, showNewWaMessage, idxs]);

  // registra las sessiones de los usuarios de whatsapp
  useEffect(() => {
    if (!whatsappIsEnabled[owner]) return
    postUserConnection()

    return () => {
      deleteUserConnection()
    }
  }, [owner])

  return (
    <RightBlockCont>
      <RightBlockHeader>
        {phoneStore.isCallAnswered === true && (
          <>
            <PhoneDisplay>
              {phone.getRemoteId()}
              <TimeCounter />
            </PhoneDisplay>
            <PhoneHorizontalButtons phone={phone}></PhoneHorizontalButtons>
          </>
        )}
      </RightBlockHeader>
      <ChatContiner>
        {owner !== 'POC' && (
          <SelectedChat
            isCallAnswered={phoneStore.isCallAnswered}
            phone={phone}
          />
        )}
        <SelectedConversation />
      </ChatContiner>
      <InputAndButtonsCont>
        <InputCont>
          <Input
            type="text"
            ref={inputRef}
            onKeyDown={e => handleInputChange(e)}
            placeholder={`${t('writeMessage')} . . .`}
          />
          <IconCont>
            <div>
              <i className="bx bx-happy" onClick={() => handleIconPickerClick('emoji')} />
            </div>
            <div>
              <i className="bx bx-paperclip" onClick={() => handleIconPickerClick('images')} />
            </div>
          </IconCont>
          {showEmojiPicker &&
            <EmojiCont >
              <EmojiPicker onEmojiClick={e => handleEmojiClick(e)} />
            </EmojiCont>}
          {showImagesPicker &&
            <ImagePicker setShowImagesPicker={setShowImagesPicker} />
          }
          {showAudioRecording &&
            <AudioRecording setShowAudioRecording={setShowAudioRecording} />
          }
        </InputCont>

        <Microphone onClick={() => handleIconPickerClick('audio')}>
          <i className='bx bxs-microphone'></i>
        </Microphone>

        <Button onClick={() => handleSendMessage()}>
          <span>{t('send')}</span>
          <i className="mdi mdi-send"></i>
        </Button>
      </InputAndButtonsCont>
    </RightBlockCont>
  );
};
const RightBlockCont = styled.div`
  flex-grow: 1;
  border-radius: 10px;
  background-color: ${lightBackground};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ChatContiner = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const RightBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  min-height: 82px;
  border-bottom: 2px solid ${darkBorder};
`;
const PhoneDisplay = styled.div`
  color: black;
  font-size: 1rem;
`;
const InputAndButtonsCont = styled.div`
  padding: 20px;
  height: 82px;
  border-top: 2px solid ${darkBorder};
  display: flex;
  align-items: center;

  & > div {
    flex-grow: 1;
    display: flex;
    position: relative;
  } 
`;
const InputCont = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  align-items: center;
`;
const IconCont = styled.div`
  position: absolute;
  right: 10px;
  font-size: 1.7rem;
  opacity: .5;
  display: flex;
  gap: 5px;

   div {
    cursor: pointer;
    transition: transform .2s;
  } 
  & :last-child > i {
    transform: rotate(-90deg);
  }
   div:hover{
    transform: scale(1.1);
  }
`;
const EmojiCont = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  right: -150px;
`;
const Input = styled.input`
  flex-grow: 1;
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 50px;
  background-color: ${lightBackgroundBlue};
  padding: 0 70px 0 30px;
  font-size: 1rem;
`;
const Microphone = styled.div`
  flex-grow: 0;
  max-width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 1.7rem;
  opacity: .5;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${darkBorder};
  
  & > i{
    transition: transform .2s;
  }
  &:hover > i{
    transform: scale(1.1);
  }
`;
const Button = styled.button`
  min-width: 128px;
  height: 40px;
  padding: 0 20px 0 30px;
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50px;
  background-color: ${primary};

  & > i {
    margin-left: 10px;
    font-size: 1rem;
    display: inline-block;
    transform: scale(1.3);
  }
`;
export default RightBlock;
