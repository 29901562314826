import {useState} from "react";

export const useNodeModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  return {
    modalOpen,
    toggleModal
  }
}