import * as React from "react"

export const InteractiveMenu = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M8.58 29.93a4.69 4.69 0 1 1 4.69-4.69 4.7 4.7 0 0 1-4.69 4.69Zm0-7.54a2.85 2.85 0 1 0 2.85 2.85 2.86 2.86 0 0 0-2.85-2.85Z"
    />
    <path
      fill="currentColor"
      d="M8.58 22.5a.92.92 0 0 1-.92-.92V6.91a.92.92 0 1 1 1.84 0v14.67a.92.92 0 0 1-.92.92ZM26.42 29.93a4.69 4.69 0 1 1 4.69-4.69 4.7 4.7 0 0 1-4.69 4.69Zm0-7.54a2.85 2.85 0 1 0 2.85 2.85 2.86 2.86 0 0 0-2.85-2.85Z"
    />
    <path
      fill="currentColor"
      d="M26.42 22.5a.92.92 0 0 1-.92-.92V6.91a.92.92 0 1 1 1.84 0v14.67a.92.92 0 0 1-.92.92ZM17.5 14.45a4.69 4.69 0 1 1 4.69-4.69 4.69 4.69 0 0 1-4.69 4.69Zm0-7.54a2.85 2.85 0 1 0 2.85 2.85 2.86 2.86 0 0 0-2.85-2.85Z"
    />
    <path
      fill="currentColor"
      d="M17.5 29a.92.92 0 0 1-.92-.92V13.42a.92.92 0 1 1 1.84 0v14.67a.92.92 0 0 1-.92.91Z"
    />
  </svg>
)

