import { popUpBackground, primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IPopUpContainerProps {
  children: React.ReactNode;
  onExitClick: Function;
}
const PopUpContainer: FC<IPopUpContainerProps> = ({ children, onExitClick }) => {

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>{
    if(!(event.target instanceof HTMLDivElement)) return
    const {id} = event.target
    onExitClick(id)
  }
  return (
    <Cont id='popUpContainer' onClick={e=>handleClick(e)}>
      
      { children }
    </Cont>
  );
};
const Cont = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1000;
  background-color: ${popUpBackground};
`;
export default PopUpContainer;