import { darkBorder } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';
import Item from './item';
import AsistenciaEnLaCarretera from './itemsInformation/asistenciaEnLaCarretera';
import AutoridadDeCarreteras from './itemsInformation/autoridadDeCarreteras';
import PoliciaAutopistas from './itemsInformation/policiaAutopistas';
import ReclamacionDeDaños from './itemsInformation/reclamacionDeDaños';
import SugerenciasOQuejas from './itemsInformation/sugerenciasOQuejas';
import ProblemasConAutoExpreso from './itemsInformation/problemasConAutoexpreso';
import OficinasAdministrativas from './itemsInformation/oficinasAdministrativas';
import CierreDeTodaLlamada from './itemsInformation/cierreDeTodaLlamada';
import Span from './atoms/span';

interface IMetropistasChatInfoProps { }
const MetropistasChatInfo: FC<IMetropistasChatInfoProps> = ({ }) => {
  return (
    <Cont>
      <Text>
        Gracias por llamar a metropistas. Soy <Span danger>[Tu Nombre]</Span>, y estoy aquí para ayudarle. ¿En qué puedo asistirle hoy?
      </Text>
      {itemsList.map((item, index) => {
        return <Item key={index} label={item.label}>{item.children}</Item>
      })}
    </Cont>
  );
};
const Cont = styled.div`
  padding: 10px 20px;
`;
const Text = styled.p`
`;
const itemsList = [
  { label: 'Asistencia en la Carretera', children: <AsistenciaEnLaCarretera /> },
  { label: 'Autoridad de carreteras', children: <AutoridadDeCarreteras /> },
  { label: 'Policía Autopistas', children: <PoliciaAutopistas /> },
  { label: 'Reclamación de daños', children: <ReclamacionDeDaños /> },
  { label: 'Sugerencias o quejas', children: <SugerenciasOQuejas /> },
  { label: 'Problemas con Autoexpreso', children: <ProblemasConAutoExpreso /> },
  { label: 'Oficinas Administrativas', children: <OficinasAdministrativas /> },
  { label: 'Cierre de llamada', children: <CierreDeTodaLlamada /> },
];
export default MetropistasChatInfo;