import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';

import './SceneryList.css';

import { ScenePublished, SceneEraser } from '../../icons';
import { Link } from 'react-router-dom';

function SceneryList({ items, onDeleteItem, version }) {
  const pathToRedirect = version === 'v1' ? '/editor/' : '/v2/incoming/flow/';
  const getIcon = type =>
    type === 'Publicado' ? (
      <ScenePublished width={24} height={24} style={{ stroke: 'black' }} />
    ) : (
      <SceneEraser width={24} height={24} />
    );
  return (
    <table className="table mt-3">
      <tbody>
        {items.map(({ id, type, text, updatedAt }) => (
          <tr key={id}>
            <td>
              <div>{getIcon(type)}</div>
            </td>
            <td>
              <Link to={pathToRedirect + id}>{text}</Link>
              <br />
              {
                <span
                  className={
                    type === 'Publicado' ? ' text-primary' : ' text-danger'
                  }
                  style={{ padding: 0 }}
                >
                  {type}
                </span>
              }{' '}
              | Modificado, {updatedAt}
            </td>
            <td>
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="btn nav-btn" tag="i">
                  <i className="bx bx-dots-horizontal-rounded font-size-24" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => onDeleteItem(id)}>
                    Borrar
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

SceneryList.propTypes = {
  items: PropTypes.array.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

export default SceneryList;
