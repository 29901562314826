import { Completed } from 'icons';
import { blueGraph, darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import { IScenarioGeneralData } from 'library/interfaces/scenariosInterfaces';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface IScenarioProps {
  scenario: IScenarioGeneralData
}
const Scenario: FC<IScenarioProps> = ({ scenario }) => {

  const history = useHistory()

  const dateFormat = (date: string) => {
    return date.replace(/([T.])/g, ' ').substr(0, 20);
  };

  const handleScenarioClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const clickedElement = event.target as HTMLDivElement;
    const closestElememt = clickedElement.closest('[id]');
    const parentId = closestElememt?.id;
    if (parentId !== 'SceneryContainer') return
    history.push(`/incoming-v2/${scenario.id}`)
  }
  return (
    <Containter id='SceneryContainer' onClick={e => handleScenarioClick(e)}>
      <Cont>
        <IconCont left="-45px">
          <Completed width={30} color={blueGraph} />
        </IconCont>
        <Title>{scenario.name}</Title>
        <Date>Fecha de creación: <span>{dateFormat(scenario.createdAt)}</span></Date>
        <Date>Última actualización: <span>{dateFormat(scenario.updatedAt)}</span></Date>
      </Cont>
      <StateAndButtonCont>
        <State>Published</State>
        <MenuButton id='menu'>...</MenuButton>
      </StateAndButtonCont>

    </Containter>
  );
};
const Containter = styled.div`
  border-radius: 10px;
  padding: 20px 50px 20px 90px;
  color: black;
  max-width: 1100px;
  margin: 0 auto 20px;
  border: 1px solid ${darkBorder};
  display: flex;
  cursor: pointer;
`;
const Cont = styled.div`
  position: relative;
  flex-grow: 1;
`;
const IconCont = styled.div<IIconCont>`
  position: absolute;
  left: ${props => (props.left ? props.left : '-40px')};
  top: 5px;
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
`;
const Date = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  margin-bottom: 5px;
  color: ${primary};

  & > span{
    color: black;
    white-space: pre;
  }
`;
const StateAndButtonCont = styled.div`
  display: flex;
`;
const State = styled.div`
  width: 100px;
  height: 30px;
  margin: 0 10px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  cursor: default;
  user-select: none;
  background-color: ${lightBackgroundBlue};
`;
const MenuButton = styled.div`
  width: 40px;
  height: 30px;
  margin-left: 5px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 35%;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;
interface IIconCont {
  left?: string;
}
export default Scenario;