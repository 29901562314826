import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

TagList.propTypes = {
  tags: PropTypes.array,
  removeTag: PropTypes.func,
};

function TagList({ tags = [], removeTag }) {
  return (
    <div className="input-tag-container">
      {tags.map((tag, index) => (
        <Tag
          key={index}
          text={tag}
          onRemove={removeTag ? () => removeTag(index) : null}
        />
      ))}
    </div>
  );
}

export default TagList;
