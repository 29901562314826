import { API, graphqlOperation } from 'aws-amplify';

const config = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_WA_URL, // La URL de la API de AppSync
  aws_appsync_region: 'us-east-1', // La región de AWS donde has creado tu API de AppSync
  aws_appsync_authenticationType: 'API_KEY', // El tipo de autenticación que estás utilizando en tu API de AppSync
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_WA_KEY, // La clave de API si estás utilizando autenticación basada en API key en tu API de AppSync
};

const waChannelQuey = `
subscription MySubscription($idx: String!) {
  subcribeChannelEvents(idx: $idx) {
    agentId
    channelId
    events {
      eventType
      payload
    }
    idx
    timestamp
  }
}
`;
export function subscribeWaChannel(idx, next, error) {
  API.configure(config);

  return API.graphql(graphqlOperation(waChannelQuey, { idx })).subscribe({
    next: ({ provider, value }) => {
      next(value.data.subcribeChannelEvents.events);
    },
    error: error || console.log,
  });
}
