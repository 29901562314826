import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { useEffect } from 'react';

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// actions
import { loginUser } from '../../store/actions';
import { useAuth0 } from '@auth0/auth0-react';

// import images
import logo from 'assets/images/logo.svg';

//Import config
import topHeaderImage from '../../assets/images/login-top-header.png';
import logoDark from '../../assets/images/logo-dark.png';

const Entry = props => {
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      organization: props.match.params.organization,
    },
    validationSchema: Yup.object({
      organization: Yup.string().required(
        'Por favor introduzca el nombre de su organiación',
      ),
      password: Yup.string().required(
        'Por favor, introduzca el nombre de su organización',
      ),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history));
    },
  });

  const { error, auth } = useSelector(state => ({
    error: state.Login.error,
    auth: state.Login.auth,
  }));

  useEffect(() => {
    if (auth) {
      loginWithRedirect({
        redirectUri: window.location.origin.toString() + '/authorize',
      });
    }
  }, [auth]);

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(loginUser(values, props.history));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Ingreso | SmartBot</title>
      </MetaTags>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 32,
            }}
          >
            <img src={logoDark} alt="SmartBot logo" width={200} />
          </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div
                  className="bg-primary bg-soft"
                  style={{
                    backgroundImage: `url(${topHeaderImage})`,
                    height: '100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="text-primary p-4">
                        <h5 className="text-light">Bienvenido</h5>
                        <p className="text-light">
                          Ingrese el nombre de su organización para continuar
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        //console.log('SUBMIT')
                        handleValidSubmit(e.target[0].value);

                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Organización</Label>
                        <Input
                          name="organization"
                          className="form-control"
                          placeholder="Ingrese el nombre de la organización"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.organization || ''}
                          invalid={
                            !!(
                              validation.touched.organization &&
                              validation.errors.organization
                            )
                          }
                        />
                        {validation.touched.organization &&
                        validation.errors.organization ? (
                          <FormFeedback type="invalid">
                            {validation.errors.organization}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          /* onClick={() => loginWithRedirect()} */
                        >
                          Continuar
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Entry);

Entry.propTypes = {
  history: PropTypes.object,
};
