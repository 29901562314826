import React, { memo } from 'react';

import RightHandle from '../common/RightHandle';
import NodeLayout from '../common/NodeLayout';
import LeftHandle from '../common/LeftHandle';
import Modal from './Modal';
import PropTypes from 'prop-types';

const color = 'blue';

// eslint-disable-next-line react/display-name
export const Extension = memo(({ data, isConnectable }) => (
  <NodeLayout icon="Extension" data={data} Modal={Modal} color={color}>
    <LeftHandle id="route_in" isConnectable={isConnectable} />

    <RightHandle
      id="route_fail"
      isConnectable={isConnectable}
      type="source"
      text="Fail"
      bg="red"
    />
    <RightHandle
      id="route_success"
      isConnectable={isConnectable}
      type="source"
      text="Success"
      bg="green2"
    />
  </NodeLayout>
));

Extension.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
