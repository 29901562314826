import React, { FC, useState } from "react";
import styled from "styled-components";
import {
  darkGreen,
  lightBackgroundBlue,
  lightGreen,
  primary,
} from "library/colors";

interface ISelectableRoundButton {
  isSelected : boolean;
  onSelect?: Function;
  onDeselect?: Function;
  children?: React.ReactNode;
}
const SelectableRoundButton: FC<ISelectableRoundButton> = ({
  isSelected,
  onSelect,
  onDeselect,
  children,
  
}) => {

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onSelect && !isSelected) return onSelect();
    onDeselect && onDeselect();
  };
  return (
    <Button isSelected={isSelected} onClick={(e) => handleClick(e)}>
      <Span>
        {children}
      </Span>
    </Button>
  );
};
const Button = styled.button<IButtonProps>`
  width: 40px;
  aspect-ratio: 1;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  border: 1px solid ${lightGreen};
  transition: color 0.25s, background-color 0.25s;
  color: ${(props) => props.isSelected && "#fff"};
  background-color: ${(props) =>
    props.isSelected ? primary : lightBackgroundBlue};

  &:hover {
    background-color: ${(props) => props.isSelected && darkGreen};
  }
`;
const Span = styled.span`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
interface IButtonProps {
  isSelected: boolean;
}
export default SelectableRoundButton;
