import { Completed, Draft, Megaphone, Pause, Phone } from 'icons';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import LeftMenuButton from '../atoms/leftMenuButton';

interface ILeftMenuOutboundProps {
  setCampaignFilter: Function;
  filterButtons: {
    isSelected: boolean;
    icon: JSX.Element;
    value: string;
    i18nKeys: {
        namespace: string;
        key: string;
    };
}[]
}
const LeftMenuOutbound: FC<ILeftMenuOutboundProps> = ({setCampaignFilter, filterButtons}) => {
  const [buttons, setButtons] = useState(filterButtons);
  const handleLeftMenuButtonClick = (index: number) =>{
    const filter = buttons[index].value
    const newButtonsState = buttons.map((item, i) => {
        if (index === i) return { ...item, isSelected: true };
        return { ...item, isSelected: false };
      });
      setButtons(newButtonsState);
      setCampaignFilter(filter)
  }
  return (
    <Cont>
      {buttons.map((item, index) => {
        return (
          <LeftMenuButton
            key={index}
            index={index}
            icon={item.icon}
            isSelected={item.isSelected}
            i18nKeys={item.i18nKeys}
            onButtonClick={handleLeftMenuButtonClick}
          />
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  margin-right: 10px;
  flex-basis: 150px;
  display: flex;
  flex-direction: column;
`;
export default LeftMenuOutbound;
