import { darkBorder, popUpBackground, primary } from 'library/colors';
import React, { useState } from 'react';
import { Node } from 'reactflow';
import styled from 'styled-components';
import { CustomNodeTypes, INodeData } from '../utils/nodeTypes';
import flowIcons from '../utils/flowIcons';
import TextToSpeechModal from './textToSpeech/textToSpeechModal';
import InteractiveMenuModal from './interactiveMenu/interactiveMenuModal';
import DataVerificationModal from './dataVerification/dataVerificationModal';
import FunctionRequestModal from './functionRequest/functionRequestModal';
import SceneryStartModal from './sceneryStart/sceneryStartModal';
import { ISceneryGlobals } from 'library/interfaces/scenariosInterfaces';
import SessionVariablesModal from './sessionVariables/sessionVariableModal';
import TransferModal from './transfer/transferModal';
import DatabasesModal from './databases/databasesModal';
import ModalTitle from './modalTitle';
import { DelIconModalSC } from '../styledComponents/modalsStyledComponents';
import EventModal from './event/eventModal';
import GPTModal from './gpt/gptModal';
import InteractiveMenuVisualEditor from './interactiveMenu/interactiveMenuVisualEditor';

interface IModalProps {
  handleExit: Function;
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  handleUpdateNode: () => void;
  sceneryGlobals: ISceneryGlobals;
  setSceneryGlobals: (sceneryGlobals: ISceneryGlobals) => void;
  handleDeleteEdgeOnDeleteSource: (sourceId: string) => void
  handleUpdateEdgeColor: (nodeId: string, sourceId: string, color: string) => void;
}
const Modal = ({
  handleExit,
  selectedNode,
  setSelectedNode,
  handleUpdateNode,
  sceneryGlobals,
  setSceneryGlobals,
  handleDeleteEdgeOnDeleteSource,
  handleUpdateEdgeColor
}: IModalProps) => {

  const [isVisualEditor, setIsVisualEditor] = useState(false)

  const type: CustomNodeTypes = selectedNode?.type || 'sceneryStart';
  const Icon = flowIcons[type];
  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    node: Node<INodeData, CustomNodeTypes>,
  ) => {
    const value = event.target.value;
    node.data.title = value;
    const newNode: Node<INodeData, CustomNodeTypes> = {
      ...node,
      data: { ...node.data, title: value },
    };
    setSelectedNode(newNode);
  };

  const handleBackgroundColorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    node: Node<INodeData, CustomNodeTypes>,
  ) => {
    const backgroundColor = event.target.value;
    const newNode: Node<INodeData, CustomNodeTypes> = {
      ...node,
      data: { ...node.data, backgroundColor: backgroundColor },
    };
    setSelectedNode(newNode);
  };

  const handleShowVisualEditor = (show: boolean) => {
    setIsVisualEditor(show)
  }

  const handleSaveButtonClick = () => {
    handleUpdateNode();
  };
  return (
    <PopUpContainer>
      <Cont>
        <CloseButton onClick={e => handleExit(e)}>
          <i className='bx bx-x'></i>
        </CloseButton>
        <ModalTitle
          Icon={Icon}
          selectedNode={selectedNode}
          handleTitleChange={handleTitleChange}
          handleBackgroundColorChange={handleBackgroundColorChange}
        />
        {selectedNode.type === 'sceneryStart' && (
          <SceneryStartModal
            sceneryGlobals={sceneryGlobals}
            setSceneryGlobals={setSceneryGlobals}
          />
        )}


        {!isVisualEditor &&
          selectedNode.type === 'message' && (
            <TextToSpeechModal
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              handleShowVisualEditor={handleShowVisualEditor}
            />
          )}
        {!isVisualEditor &&
          selectedNode.type === 'interactiveMenu' && (
            <InteractiveMenuModal
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              handleDeleteEdgeOnDeleteSource={handleDeleteEdgeOnDeleteSource}
              handleUpdateEdgeColor={handleUpdateEdgeColor}
              handleShowVisualEditor={handleShowVisualEditor}
            />
          )}
        {isVisualEditor &&
          // selectedNode.type === 'interactiveMenu' &&
          <InteractiveMenuVisualEditor
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            handleShowVisualEditor={handleShowVisualEditor}
            handleDeleteEdgeOnDeleteSource={handleDeleteEdgeOnDeleteSource}

          />
        }
        {selectedNode.type === 'conditional' && (
          <DataVerificationModal
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            handleDeleteEdgeOnDeleteSource={handleDeleteEdgeOnDeleteSource}
            handleUpdateEdgeColor={handleUpdateEdgeColor}
          />
        )}
        {selectedNode.type === 'function' && (
          <FunctionRequestModal
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        )}
        {selectedNode.type === 'sessionVariables' && (
          <SessionVariablesModal
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        )}
        {selectedNode.type === 'transfer' && (
          <TransferModal
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        )}
        {selectedNode.type === 'database' && (
          <DatabasesModal
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        )}
        {selectedNode.type === 'event' && (
          <EventModal
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        )}
        {selectedNode.type === 'GPT' && (
          <GPTModal
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            handleDeleteEdgeOnDeleteSource={handleDeleteEdgeOnDeleteSource}
            handleUpdateEdgeColor={handleUpdateEdgeColor}
          />
        )}
        {!isVisualEditor && <ButtonCont>
          <CancelButton onClick={e => handleExit(e)}>Cancelar</CancelButton>
          <SaveButton onClick={handleSaveButtonClick}>Guardar</SaveButton>
        </ButtonCont>}
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1000;
  background-color: ${popUpBackground};
`;
const Cont = styled.div`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 500px;
  color: black;
  overflow: hidden;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);

  @media (min-width: 1920px) {
    width: 650px;
  }
`;
const CloseButton = styled(DelIconModalSC)`
  color: white;
  font-size: 1.2rem;
`;
const ButtonCont = styled.div`
  display: flex;
  justify-content: end;
  margin: 10px 0 20px;
  padding-right: 50px;
`;
const SaveButton = styled.button`
  align-self: right;
  border: none;
  color: white;
  padding: 5px 20px;
  border-radius: 50px;
  background-color: ${primary};

  @media (min-width: 1920px) {
    font-size: 1.1rem;
  }
`;
const CancelButton = styled(SaveButton)`
  margin-right: 20px;
  background-color: ${darkBorder};
`;
export default Modal;
