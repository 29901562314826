import { primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ISelectableButtonProps {
  label: string | number;
  isSelected: boolean;
  onClick: Function;
  index?:number;
}
const SelectableButton: FC<ISelectableButtonProps> = ({
  label,
  isSelected,
  onClick,
  index=0
}) => {
  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onClick(index);
  };
  return <Button isSelected={isSelected} onClick={e => handleButtonClick(e)}>{label}</Button>;
};
const Button = styled.button<IButton>`
  border-radius: 50px;
  display: inline-block;
  min-width: 30px;
  width: 100%;
  border: 2px solid ${primary};
  color: ${props=>props.isSelected && 'white'};
  background-color: ${props=>props.isSelected ? primary : 'transparent'};
`;
interface IButton {
  isSelected: boolean;
}
export default SelectableButton;
