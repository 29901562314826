
import { ITextV2 } from 'components/flowV2/utils';
import { darkBorder, primary } from 'library/colors';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface ITextTabsProps {
  textV2?: ITextV2
  handleTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}
const TextTabs: FC<ITextTabsProps> = ({ textV2 = { text: '', ssml: '', xml: '' }, handleTextChange }) => {
  const [activeTab, setActiveTab] = useState(0);

  const text = Object.entries(textV2).map(([key, value]) => { return { key, value } })

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };
  return (
    <Cont>
      <HeaderTab>
        {text.map((item, index) => (
          <TabButton
            key={index}
            isSelected={index === activeTab}
            onClick={() => handleTabClick(index)}
          >
            {item.key.toUpperCase()}
          </TabButton>
        ))}
      </HeaderTab>
      <TabContent >
        {text.map((item, index) => (
          <TextAreaCont
            key={index}
            isSelected={index === activeTab}
          >
            <TextArea
              name={item.key}
              value={item.value}
              onChange={(e) => {
                handleTextChange(e)
              }}
            ></TextArea>
          </TextAreaCont>
        ))}
      </TabContent>
    </Cont>
  );
};
const Cont = styled.div`
  margin-top: 0px;
`;
const HeaderTab = styled.div`
  display: flex;
  border-bottom: 1px solid ${darkBorder};
`;
const TabButton = styled.div<ITab>`
  cursor: pointer;
  padding: 0px 25px;
  border-bottom: ${props => props.isSelected && `2px solid ${primary}`};
  font-weight: ${props => props.isSelected ? 'bold' : ''};
`;
const TabContent = styled.div`
margin-top: 10px;
`;
const TextAreaCont = styled.div<ITab>`
  display: ${props => !props.isSelected && 'none'};
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  border-radius: 5px;
  border: 1px solid ${primary};
`;
interface ITab {
  isSelected: boolean
}
export default TextTabs;