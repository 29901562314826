import { IAgentToSelect } from 'components/outbound/atoms/selectableAgent';
import { IReportData, IUser } from 'library/interfaces/crdInterfaces';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';
import { ISippeer } from 'store/configuration/reducer';

export const agentsfromQueueToSelectableAgents = (
  reportData: IReportData,
  liveData: ILiveDataQueue[],
  queueId: string,
): IAgentToSelect[] => {
  const agentsFromQueue =
    liveData.find(queue => queue.id === queueId)?.agentsStatus || [];

  const allUsers = reportData.users;

  const selectableAgentsFromQueue = agentsFromQueue.map(agent => {
    const userData = allUsers.find(user => user.exten_name === agent.id);
    return {
      exten: userData?.exten ?? '0000',
      name: userData?.name ?? 'Sin usuario asignado',
      isSelected: false,
    };
  });
  return selectableAgentsFromQueue ? selectableAgentsFromQueue : [];
};

export const freeAgentsToSelectableAgents = (
  reportData: IReportData,
  liveData: ILiveDataQueue[],
  queueId: string,
): IAgentToSelect[] => {
  const allUsers = reportData.users;
  const agentsFromQueue =
    liveData.find(queue => queue.id === queueId)?.agentsStatus || [];

  const selectableFreeAgents: IAgentToSelect[] = [];
  allUsers.map(user => {
    if (agentsFromQueue.some(agent => agent.id === user.exten_name)) return;
    selectableFreeAgents.push({
      exten: user.exten ?? '0000',
      name: user.name,
      isSelected: false,
    });
  });
  return selectableFreeAgents;
};

export const agentsInQueueToSelectableAgents = (
  agentsInQueue: ISippeer[],
  users: IUser[],
): {
  inQueue: IAgentToSelect[];
  free: IAgentToSelect[];
} => {
  const inQueue: IAgentToSelect[] = [];
  const free: IAgentToSelect[] = [];

  users.forEach(user => {
    const agentInQueue = agentsInQueue.find(
      agent => agent.sippeer_id === user.exten,
    );
    if (!agentInQueue) {
      return free.push({
        name: user.name,
        exten: user.exten,
        isSelected: false,
      });
    }
    inQueue.push({ name: user.name, exten: user.exten, isSelected: false });
  });
  return { free, inQueue };
};

export const filterAgents = (
  agents: IAgentToSelect[],
  filter: string,
): IAgentToSelect[] => {
  const regEx = new RegExp(filter, 'i');
  const agentsFiltered: IAgentToSelect[] = [];
  agents.forEach(agent => {
    if (regEx.test(agent.exten + ' ' + agent.name)) {
      return agentsFiltered.push(agent);
    }
  });
  return agentsFiltered;
};
