import React from 'react';
// import PropTypes from 'prop-types';
import { Col, /* Label, */ Row } from 'reactstrap';
import Language from './Language';
import Voice from './Voice';

SynthLanguage.propTypes = {};

function SynthLanguage() {
  return (
    <Row>
      <Col lg={6}>
        <Language name="synthLanguage" label="Synth language" />
      </Col>

      <Col lg={6}>
        <Voice name="voice" label="Voice" />
      </Col>
    </Row>
  );
}

export default SynthLanguage;
