import * as React from "react"

// fill="#7c9904"

export const SmartBot = (props) => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor">
      <path d="M92.361 90.387c-10.396 0-18.82-8.428-18.82-18.82s8.424-18.82 18.82-18.82c10.392 0 18.82 8.428 18.82 18.82s-8.428 18.82-18.82 18.82m-54.75 0c-10.392 0-18.82-8.428-18.82-18.82s8.428-18.82 18.82-18.82c10.396 0 18.82 8.428 18.82 18.82s-8.424 18.82-18.82 18.82m92.303-18.549c0-20.196-16.476-36.564-36.802-36.564H36.86C16.535 35.274.062 51.642.062 71.838s16.473 36.564 36.798 36.564h27.953v13.187l41.61-15.614-.196-.008c13.837-5.25 23.687-18.532 23.687-34.129M132.354 21.829c0 6.201-5.026 11.226-11.227 11.226s-11.23-5.025-11.23-11.226 5.03-11.226 11.23-11.226 11.227 5.025 11.227 11.226" />
    </g>
  </svg>
)

