import { darkBorder, lightBackground, lightBackgroundBlue, primary } from 'library/colors';
import { conversationInWorkspaceIsEnabled } from 'library/enableSpecificFeatures/conversationWorkspace';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { FC, useEffect, useState } from 'react';
import { deleteLiveConversationSessionAction, getConversationSessionsAction, getLiveConversationSessionMessagesAction, getLiveConversationsSessionLogsAction, getReportData, putLiveConversationTranferSessionAction } from 'store/actions';
import styled from 'styled-components';
import ConversationByChannel from '../molecules/conversationByChannel';
import ChatPopup from '../organisms/chatPopup';
import { IUser } from 'library/interfaces/crdInterfaces';
// import { getSessionsFromMonitor } from 'helpers/fakebackend_helper';
import { ILiveConversationSession } from 'store/liveConversations/reducer';
import { ISession } from 'library/interfaces/conversationInterfaces';
import { sortByDate } from 'library/services/dateServices';
import ReassignPopup from '../organisms/reassignPopup';

interface IConversationsByChannelTemplateProps { }
const ConversationsByChannelTemplate: FC<IConversationsByChannelTemplateProps> = ({ }) => {

  const dispatch = useAppDispatch()
  const owner = useAppSelector(store => store.authUserData.owner)

  const sessions = useAppSelector(store => store.conversations.activeSessions.sessionsStore.sessions)
  const templates = useAppSelector(store => store.waCampaigns.templates.templates)
  const users = useAppSelector(store => store.cdr.reportData.users) as IUser[]

  const [showChat, setShowChat] = useState(false)
  const [showLogs, setShowLogs] = useState(false)
  const [showUsers, setShowUsers] = useState(false)
  const [currentSessionId, setCurrentSessionId] = useState('')

  const [liveConversationSessions, setLiveConversationSessions] = useState<ILiveConversationSession[]>([])
  const [loadingLiveConversationSessions, setLoadingLiveConversationSessions] = useState(false)
  const [liveConversationSessionsWasAdded, setLiveConversationSessionsWasAdded] = useState(false)

  const [allSessions, setAllSessions] = useState<(ISession | ILiveConversationSession)[]>([])

  const handleShowChat = (channel: string, source: string, sessionId: string) => {
    setShowChat(!showChat)
    dispatch(getLiveConversationSessionMessagesAction(channel, source, sessionId, templates))
  }

  const handleShowLogs = (sessionId: string) => {
    setShowLogs(!showLogs)
    dispatch(getLiveConversationsSessionLogsAction(sessionId))
  }

  const handleRequestAgentSessions = async (users: IUser[]) => {
    // setLoadingLiveConversationSessions(true)
    // try {
    //   const response: {
    //     agent: { name: string, email: string },
    //     sessions: ILiveConversationSession[],
    //     lastKey: string | null;
    //   }[] = await Promise.all(users.map(usr => getSessionsFromMonitor(true, undefined, undefined, usr.auth0_id)))

    //   const sessionsFromMonitor = response.map(res => res.sessions.map(ses => { return { ...ses, active_assigned_to: res.agent.name } })).flat()

    //   setLiveConversationSessions(sessionsFromMonitor)
    //   setLoadingLiveConversationSessions(false)
    // } catch (error) {
    //   console.log(error)
    // }
  }

  const handleShowReasignUsers = (agentId: string) => {
    if (!currentSessionId) return
    setShowUsers(!showUsers)
    dispatch(putLiveConversationTranferSessionAction(currentSessionId, agentId))
    setAllSessions(prev => prev.map(ses => {
      if (ses.id === currentSessionId) {
        const agent = users.find(usr => usr.auth0_id === agentId)
        if (!agent) return ses
        return { ...ses, active_assigned_to: agent.name } as ILiveConversationSession
      }
      return ses
    }))
  }

  const handleDeleteSession = (sessionId: string) => {
    dispatch(deleteLiveConversationSessionAction(sessionId))
    setAllSessions(prev => prev.filter(sess => sess.id !== sessionId))
    setLiveConversationSessions(prev => prev.filter(ses => ses.id !== sessionId))
  }

  useEffect(() => {
    if (!conversationInWorkspaceIsEnabled[owner] || sessions.length > 0) return
    dispatch(getConversationSessionsAction(conversationInWorkspaceIsEnabled[owner]))
  }, [owner])

  useEffect(() => {
    if (users.length > 0) return
    dispatch(getReportData())
  }, [])

  useEffect(() => {
    if (users.length === 0 || liveConversationSessions.length > 0 || loadingLiveConversationSessions) return
    handleRequestAgentSessions(users)
  }, [users, liveConversationSessions])

  useEffect(() => {
    if (sessions.length === 0) return
    setAllSessions(prev => [...prev, ...sessions].sort(sortByDate))
  }, [sessions])

  useEffect(() => {
    if (liveConversationSessions.length === 0 || liveConversationSessionsWasAdded) return
    setAllSessions(prev => [...prev, ...liveConversationSessions].sort(sortByDate))
    setLiveConversationSessionsWasAdded(true)
  }, [liveConversationSessions])
  return (
    <Cont>
      {/* <CountersHeaderCont>
        <CounterCont>
          <Counter>{allSessions.length - liveConversationSessions.length}</Counter>
          <Label>Sin asignar</Label>
        </CounterCont>
        <CounterCont>
          <Counter>{liveConversationSessions.length}</Counter>
          <Label>Asignadas</Label>
        </CounterCont>
        <CounterCont>
          <Counter>{allSessions.length}</Counter>
          <Label>Total</Label>
        </CounterCont>


      </CountersHeaderCont> */}
      <BlueCont>
        {allSessions.map((session, index) => {
          if (index > 100) return
          return (
            <ConversationByChannel
              key={index}
              session={session}
              showChat={handleShowChat}
              showLogs={handleShowLogs}
              showUsers={setShowUsers}
              setCurrentSessionId={setCurrentSessionId}
              deleteSession={handleDeleteSession}
            />
          )
        })}
      </BlueCont>
      {showChat && <ChatPopup setShowChat={setShowChat} />}
      {showUsers && <ReassignPopup setShowReassign={setShowUsers} users={users} handleShowReasignUsers={handleShowReasignUsers} />}
    </Cont>
  );
};
const Cont = styled.div`
  /* height: 75vh; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${lightBackground};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const BlueCont = styled.div`
  border-radius: 10px;
  flex-grow: 1;
  padding: 10px;
  background-color: ${lightBackgroundBlue};
`;
const CountersHeaderCont = styled.div`
  /* border: 1px solid; */
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
`;
const CounterCont = styled.div`
padding: 0 10px;
height: 80px;
border-radius: 10px;
  background-color: ${darkBorder};
`;
const Counter = styled.div`
  font-size: 2.5rem;
  text-align: center;
  padding-top: 15px;
  line-height: 40px;
`;
const Label = styled.div`
  text-align: center;
  white-space: pre;
`;

export default ConversationsByChannelTemplate;