import {
  SmartBot,
  ScenarioEnd,
  InteractiveMenu,
  TextToSpeech,
  DataVerification,
  SendSMS,
  FunctionRequest,
  Configuration,
  TransferCall,
  DataModification,
  Checkmark,
  ChatGPT,
} from 'icons';
import { CustomNodeObject } from './nodeTypes';

const flowIcons: CustomNodeObject<(props: any) => JSX.Element> = {
  sceneryStart: SmartBot,
  sceneryEnd: ScenarioEnd,
  interactiveMenu: InteractiveMenu,
  message: TextToSpeech,
  conditional: DataVerification,
  function: FunctionRequest,
  sessionVariables: Configuration,
  transfer: TransferCall,
  sendSMS: SendSMS,
  database: DataModification,
  event: Checkmark,
  GPT: ChatGPT,
};
export default flowIcons;
