import React, { FC } from "react";
import styled from "styled-components";

import Container from "../atoms/container";
import SubTitle from "../atoms/subTitle";
import TimeCounterAndMinMax from "../atoms/timeCountMinMax";
import ChangePrev from "../atoms/changePrev";
import { useTranslation } from "react-i18next";

interface IAnswerTime{
    calltime_average: string;
    calltime_min_time: string;
    calltime_max_time: string;
    calltime_change: string;
}
const AnswerTime: FC<IAnswerTime> = ({calltime_average, calltime_min_time, calltime_max_time, calltime_change}) =>{
  const {t} = useTranslation('summarize')
  return(
        <Container padding="20px">
            <SubTitle sub={t('last24hs')}>{t('averageResponseTime')}</SubTitle>
            <TimeCounterAndMinMax time_average={calltime_average} time_min_time={calltime_min_time} time_max_time={calltime_max_time}/>

            <ChangePrev change={calltime_change} prev={0} />
        </Container>
    )
}
export default AnswerTime