import { call, put, takeEvery } from 'redux-saga/effects';

import {
  GET_AGENTS,
  GET_USER,
  ADD_NEW_AGENT,
  UPDATE_AGENT,
  DELETE_AGENT,
} from './actionTypes';

import {
  agentActionFail,
  getAgentsSuccess,
  getAgentSuccess,
  addAgentSuccess,
  updateAgentSuccess,
  deleteAgentSuccess,
} from './actions';

import {
  getAgents,
  getFetch,
  addNewAgent,
  updateAgent,
  deleteAgent,
} from '../../helpers/fakebackend_helper';

//import { getFirebaseBackend, getFirestore } from "../../helpers/firebase_helper"
//const fireBaseBackend = getFirebaseBackend()
//const firestore = getFirestore()

function* fetchAgents({ payload: groups }) {
  try {
    const response = yield call(getAgents, groups);

    yield put(getAgentsSuccess(response));
  } catch (error) {
    yield put(
      agentActionFail({
        error: 'Error al obtener los agentes',
        errorDetails: error?.message,
      }),
    );
  }
}

function* fetchUser({ payload: { picture } }) {
  try {
    const response = yield call(getFetch);
    response.picture = picture;
    yield put(getAgentSuccess(response));
  } catch (error) {
    console.log('fetchUser:', error);
    yield put(
      agentActionFail({
        error: 'Error al obtener el agente',
        errorDetails: error?.message,
      }),
    );
  }
}

function* onUpdateAgent({ payload: { id, agent } }) {
  try {
    yield call(updateAgent, id, agent);
    yield put(updateAgentSuccess(id, agent));
  } catch (error) {
    yield put(
      agentActionFail({
        error: 'Error al actualizar el agente: ' + JSON.stringify(error),
        errorDetails: error?.message,
      }),
    );
  }
}

function* onDeleteAgent({ payload: id }) {
  try {
    yield call(deleteAgent, id);
    yield put(deleteAgentSuccess(id));
  } catch (error) {
    yield put(
      agentActionFail({
        error: 'Error al eliminar el agente: ' + JSON.stringify(error),
        errorDetails: error?.message,
      }),
    );
  }
}

function* onAddNewAgent({ payload: { id, agent } }) {
  try {
    const newId = yield call(addNewAgent, id, agent);

    yield put(addAgentSuccess(newId, agent));
  } catch (error) {
    yield put(
      agentActionFail({
        error: 'Error al crear el agente',
        errorDetails: error?.message,
      }),
    );
  }
}

function* agentsSaga() {
  yield takeEvery(GET_AGENTS, fetchAgents);
  yield takeEvery(GET_USER, fetchUser);
  yield takeEvery(ADD_NEW_AGENT, onAddNewAgent);
  yield takeEvery(UPDATE_AGENT, onUpdateAgent);
  yield takeEvery(DELETE_AGENT, onDeleteAgent);
}

export default agentsSaga;
