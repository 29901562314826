import React, { FC } from 'react';
import styled from 'styled-components';

import Container from '../atoms/container';
import SubTitle from '../atoms/subTitle';

import { primary } from 'library/colors';
import { useTranslation } from 'react-i18next';

interface ILiveCalls {
  inqueue: number;
  answered: number;
  online: number;
  gridColumn?: string;
  gridRow?: string;
}

const LiveCalls: FC<ILiveCalls> = ({
  gridColumn,
  gridRow,
  inqueue,
  answered,
  online,
}) => {
  const {t} = useTranslation('summarize')
  const total = inqueue + answered + online
  return (
    <Container gridColumn={gridColumn} gridRow={gridRow} padding="20px">
      <SubTitle circleColor={primary}>{t('liveCalls')}</SubTitle>
      <FlexCont>
        <div>
          <div>
            <Span>{inqueue}</Span>/{total}
          </div>
          {t('queuedCalls')}
        </div>
        <div>
          <div>
            <Span>{answered}</Span>/{total}
          </div>
          {t('answeredCalls')}
        </div>
        <div>
          <div>
            <Span>{online}</Span>/{total}
          </div>
          {t('ongoingCalls')}
        </div>
      </FlexCont>
    </Container>
  );
};
const FlexCont = styled.div`
  font-size: 0.72rem;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
`;
const Span = styled.span`
  font-size: 2.3rem;
  font-weight: 600;
`;
export default LiveCalls;
