import React from 'react';
import PropTypes from 'prop-types';

import * as Fields from '../../../../Fields';
import { Col } from 'reactstrap';

PossibleResponses.propTypes = {
  name: PropTypes.string.isRequired,
  lg: PropTypes.number,
};

export function PossibleResponses({ name, lg }) {
  return (
    <Col lg={lg}>
      <label>Possible responses</label>
      <Fields.TagsInput name={name} />
    </Col>
  );
}
