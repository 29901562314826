import {
  GET_QUEUES_FAIL,
  GET_QUEUES_SUCCESS,
  GET_RECORDINGS_FAIL,
  GET_RECORDINGS_SUCCESS,
  GET_PAGINATION_FAIL,
  GET_PAGINATION_SUCCESS,
  /* GET_RECORDINGS_BY_DATE_FAIL,
  GET_RECORDINGS_BY_DATE_SUCCESS,*/
  GET_RECORDINGS_BY_FILTER_FAIL,
  GET_RECORDINGS_BY_FILTER_SUCCESS,
  GET_RECORDINGS_BY_QUEUE_FAIL,
  GET_RECORDINGS_BY_QUEUE_SUCCESS,
  GET_RECORDINGS_BY_ALL_FILTERS_FAIL,
  GET_RECORDINGS_BY_ALL_FILTERS_SUCCESS,
  UPDATE_LIMIT,
  UPDATE_RECORDINGS_ORDER,
  UPDATE_RECORDINGS_PAGINATION,
  FILTER_ADD_QUEUE,
  FILTER_REMOVE_QUEUE,
  FILTER_VALUE_UPDATE,
  POST_FILTERS_FAIL,
  POST_FILTERS_SUCCESS,
  GET_RECORDINGS_FROM_REPORT_MODULE_SUCCESS,
  GET_RECORDINGS_FROM_REPORT_MODULE,
  GET_RECORDINGS_FROM_REPORT_MODULE_FAIL,
} from './actionTypes';

const INIT_STATE = {
  isLoading: false,
  Count: 10,
  LastKey: '',
  Items: [],

  limit: 10,
  order: 'des',

  page: 1,

  selectedQueue: '',
  filters: {
    startDate: '',
    endDate: '',
    startTime: '00:00',
    endTime: '23:59',
    agent: '',
    source: '',
    destination: '',
  },
  reportFilters: {
    queues: [],
    startDate: '',
    endDate: '',
  },
  error: {},
};

const recordings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUEUES_SUCCESS:
      return {
        ...state,
        queues: action.payload,
      };
    case GET_QUEUES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_RECORDINGS_SUCCESS: {
      const { Count, LastKey, Items } = action.payload;
      return {
        ...state,
        Count: Count,
        LastKey: LastKey,
        Items: Items,
        page: 1,
      };
    }
    case GET_RECORDINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PAGINATION_SUCCESS:
      return {
        ...state,
        Count: action.payload.Count,
        LastKey: action.payload.LastKey,
        Items: action.payload.Items,
      };
    case GET_PAGINATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    /* case GET_RECORDINGS_BY_DATE_SUCCESS:
      return {
        ...state,
        Count: action.payload.Count,
        //LastKey: action.payload.LastKey,
        Items: action.payload.Items,
        page: 1,
      };
    case GET_RECORDINGS_BY_DATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }; */
    case GET_RECORDINGS_BY_FILTER_SUCCESS:
      return {
        ...state,
        Count: action.payload.Count,
        //LastKey: action.payload.LastKey,
        Items: action.payload.Items,
        page: 1,
      };
    case GET_RECORDINGS_BY_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_RECORDINGS_BY_QUEUE_SUCCESS:
      return {
        ...state,
        Count: action.payload.Count,
        //LastKey: action.payload.LastKey,
        Items: action.payload.Items,
        page: 1,
      };
    case GET_RECORDINGS_BY_QUEUE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_RECORDINGS_BY_ALL_FILTERS_SUCCESS:
      return {
        ...state,
        Count: action.payload.Count,
        //LastKey: action.payload.LastKey,
        Items: action.payload.Items,
        page: 1,
      };
    case GET_RECORDINGS_BY_ALL_FILTERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_LIMIT:
      return {
        ...state,
        limit: action.payload,
      };
    case UPDATE_RECORDINGS_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case UPDATE_RECORDINGS_PAGINATION:
      return {
        ...state,
        page: action.payload,
      };
    case FILTER_ADD_QUEUE:
      return {
        ...state,
        selectedQueue: action.payload,
      };
    case FILTER_REMOVE_QUEUE:
      return {
        ...state,
        selectedQueue: '',
      };
    case FILTER_VALUE_UPDATE:
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    case POST_FILTERS_SUCCESS:
      return {
        ...state,
        reportFilters: state.filters,
        recordings: action.payload,
      };
    case POST_FILTERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_RECORDINGS_FROM_REPORT_MODULE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_RECORDINGS_FROM_REPORT_MODULE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        Count: action.payload.Count,
        LastKey: action.payload.LastKey,
        Items: action.payload.Items,
      };
    }
    case GET_RECORDINGS_FROM_REPORT_MODULE_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default recordings;
