import React, { FC } from 'react';
import styled from 'styled-components';

interface IWaTextMessageProps {
  text: string;
  right: boolean
}
const WaTextMessage: FC<IWaTextMessageProps> = ({ text, right }) => {
  return (
    <Cont isRight={right}>
      <TextCont isRight={right}>
        <TailCont isRight={right}>
          <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px" enableBackground="new 0 0 8 13"><title>tail-in</title><path opacity="0.13" fill="#0000000" d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"></path><path fill="currentColor" d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"></path></svg>
        </TailCont>
        {text}
      </TextCont>
    </Cont>
  );
};
const Cont = styled.div<ICont>`
color: #e9edef;
font-size: 14px;
margin-bottom: 10px;
display: flex;
justify-content: ${props => props.isRight && 'end'};
`;
const TextCont = styled.div<ICont>`
max-width: 90%;
min-height: 40px;
padding: 6px 7px 8px 9px;
  background-color: ${props => !props.isRight ? '#202c33' : '#005c4b'};
  position: relative;

  border-radius: 7.5px;
  border-top-left-radius: ${props => !props.isRight && 0};
  border-top-right-radius: ${props => props.isRight && 0};
`;

const TailCont = styled.span<ICont>`
  position: absolute;
  top: 0px;
  left: ${props => !props.isRight ? 0 : 'calc(100% + 8px)'};

  display: flex;
  transform: translate(-100%) rotateY(${props => !props.isRight ? '0deg' : '180deg'});
  color: ${props => !props.isRight ? '#202c33' : '#005c4b'};
`;
interface ICont {
  isRight: boolean
}
export default WaTextMessage;