import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { darkBorder, lightBackground, primary } from 'library/colors';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'library/hooks/useAppSelector';

interface ITabButtons {
  setTab: Function
}
const TabButtons: FC<ITabButtons> = ({ setTab }) => {
  const { t } = useTranslation('workspace')
  const unassignedSessions = useAppSelector(store => store.conversations.activeSessions.sessionsStore.sessions)
  const [buttons, setButtons] = useState([
    { content: t('conversations'), isSelected: true },
    { content: t('waiting'), isSelected: false },
  ]);
  const handleTabClick = (tab: number) => {
    const newButtons = buttons.map((item, index) => {
      if (index === tab) return { ...item, isSelected: true };
      return { ...item, isSelected: false }
    })
    setButtons(newButtons)
    setTab(tab)
  };
  return (
    <ButtonsCont>
      {buttons.map((item, index) => {
        return (
          <Button key={index} isSelected={item.isSelected} onClick={() => handleTabClick(index)}>
            {item.content}
            {index === 1 && unassignedSessions.length > 0 &&
              <Counter isSelected={item.isSelected}>{unassignedSessions.length}</Counter>
            }
          </Button>
        );
      })}
    </ButtonsCont>
  );
};
const ButtonsCont = styled.div`
display: flex;
  border-radius: 10px;
  background-color: ${lightBackground};
`;
const Button = styled.button<IButton>`
  font-size: .9rem;
  padding: 8px 0px;
  border-radius: 10px;
  border: none;
  flex-grow: 1;
  color: ${props => props.isSelected ? 'white' : 'black'};
  background-color: ${props => props.isSelected ? primary : 'transparent'};
`;
const Counter = styled.span<IButton>`
  /* padding: 3px 5px; */
  width: 30px;
  height: 20px;
  display: inline-block;
  color: ${props => props.isSelected ? 'white' : 'black'};
  font-weight: 500;
  margin-left: 5px;
  border-radius: 50%;
  background-color: ${darkBorder};
`;
interface IButton {
  isSelected: boolean
}
export default TabButtons;
