/**
 * this function is only to know if is production o development enviroment
 * @returns boolean
 */
export const isProd = (): boolean => {
  return document.URL.startsWith('https://app.smartbot.biz/');
};

/**
 * Esta funcion es para generar el IDX que se utiliza para traer las sesiones de whatsapp de appsync
 */

export const toHash = async (...values: string[]): Promise<string> => {
  try {
    const text = values.join('-');
    const encoder = new TextEncoder();
    const data = encoder.encode(text);

    const buffer = await crypto.subtle.digest('SHA-1', data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hashHex = hashArray
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');

    return hashHex;
  } catch (error) {
    console.error('Error generating hash:', error);
    throw error;
  }
};

export const genIdx = async (
  channel: string,
  userId: string,
  callback: (val: string) => void,
) => {
  try {
    const res = await toHash(channel, userId);

    callback(res);
  } catch (error) {
    console.error('Error generating Idx:', error);
  }
};

/**
 * Esta funcion es para evaluar si la cadena de texto es un string o un JSON
 * @param value
 */
export const isJSON = (value: string) => {
  try {
    const parsedValue = JSON.parse(value);
    return (
      typeof parsedValue === 'object' &&
      !Array.isArray(parsedValue) &&
      parsedValue !== null
    );
  } catch (error) {
    return false;
  }
};
/**
 * Evalua si la cadena de texto es un string o un array JSON
 * @param value
 */
export const isArrayJSON = (value: string) => {
  try {
    const parsedValue = JSON.parse(value);
    return (
      typeof parsedValue === 'object' &&
      Array.isArray(parsedValue) &&
      parsedValue !== null
    );
  } catch (error) {
    return false;
  }
};

const generarLetra = () => {
  const letter = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
  ];
  const number = parseInt((Math.random() * 15).toFixed(0));
  return letter[number];
};
export const colorHEX = () => {
  let color = '';
  for (let i = 0; i < 6; i++) {
    color = color + generarLetra();
  }
  return '#' + color;
};
