import React, { FC } from 'react';
import styled from 'styled-components';
import InfoCont from '../atoms/infoCont';
import Title from '../atoms/title';
import Text from '../atoms/text';

interface IOficinasAdministrativasProps { }
const OficinasAdministrativas: FC<IOficinasAdministrativasProps> = ({ }) => {
  return (
    <InfoCont>
      <Title>Oficinas Administrativas</Title>
      <Text>Veo que necesita hablar con alguien en nuestras oficinas administrativas, espere un momento y le conectaré con nuestras oficinas llamando al 787-474-5999.</Text>
    </InfoCont>
  );
};
export default OficinasAdministrativas;