import { toast } from 'react-hot-toast';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

const actionsObj: { [key: string]: (action: AnyAction) => void } = {
  'scenariosV2/addScenario/fulfilled': (action: AnyAction) =>
    toast.success('Escenario creado exitosamente'),
  'scenariosV2/addScenario/rejected': (action: AnyAction) =>
    toast.error(
      `No se ha podido guardar el nuevo escenario\n\n${action?.error?.message}`,
    ),
  'scenariosV2/updateScenario/fulfilled': (action: AnyAction) =>
    toast.success('Escenario actualizado exitosamente'),
  'scenariosV2/updateScenario/rejected': (action: AnyAction) =>
    toast.error(
      `El escenario no ha podido ser actualizado\n\n${action?.error?.message}`,
    ),
};
export const notificationMiddleware =
  (store: MiddlewareAPI<Dispatch<AnyAction>>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (actionsObj[action.type]) {
      actionsObj[action.type](action);
    }
    return next(action);
  };
