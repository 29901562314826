import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteDb,
  deleteDbRows,
  getAllDatabases,
  getRowsByDatabaseId as getRowsByDbId,
  getRowById,
  postCreateDb,
  postRowsDb,
  putUpdateDb,
} from 'helpers/fakebackend_helper';
import {
  ICreateDatabase,
  IDatabase,
  IDatabaseRow,
  IUpdateDatabase,
} from 'library/interfaces/databasesInterfaces';
import { groupContactsInArr } from 'library/services/campaignFormServices';
import { updatePencent } from './reducer';

export const getDatabases = createAsyncThunk<IDatabase[]>(
  'databases/getDatabases',
  async () => {
    return await getAllDatabases();
  },
);

export const getRowsByDatabaseId = createAsyncThunk<
  { lastKey: string | null; rows: IDatabaseRow[] },
  { dbId: string; dbIndex: string; lastKey: string | null }
>('databases/getDatabaseRows', async ({ dbId, dbIndex, lastKey }) => {
  return await getRowsByDbId({ dbId, limit: 500, dbIndex, lastKey });
});

export const getRowByRowId = createAsyncThunk<
  IDatabaseRow,
  { dbId: string; rowId: string }
>('databases/getRowByRowId', async ({ dbId, rowId }) => {
  return await getRowById(dbId, rowId);
});

export const postCreateDatabase = createAsyncThunk(
  'databases/postCreateDatabase',
  async (database: ICreateDatabase, { dispatch }) => {
    const create = async (database: ICreateDatabase) => {
      try {
        await postCreateDb(database);
        dispatch(getDatabases());
      } catch (error) {
        console.log(error);
      }
    };
    await create(database);
  },
);

export const putUpdateDatabase = createAsyncThunk<
  void,
  { dbId: string; database: IUpdateDatabase }
>('databases/putUpdateDatabase', async ({ dbId, database }, { dispatch }) => {
  const update = async (databaseId: string, database: IUpdateDatabase) => {
    try {
      await putUpdateDb(databaseId, database);
      dispatch(getDatabases());
    } catch (error) {
      console.log(error);
    }
  };
  await update(dbId, database);
});

export const postRows = createAsyncThunk<
  void,
  { dbId: string; allRows: any[]; dbIndex: string }
>('databases/postRows', async ({ dbId, allRows, dbIndex }, { dispatch }) => {
  const arrRows = groupContactsInArr(allRows, 200);
  const total = arrRows.length;

  const postGroupRows = async (rows: any[], index: number) => {
    try {
      await postRowsDb(dbId, rows);
      const percent = Math.round(((index + 1) / total) * 100);
      dispatch(updatePencent(percent));
    } catch (error) {
      const percent = Math.round(((index + 1) / total) * 100);
      dispatch(updatePencent(percent));
      console.log(error);
    }
    if (index < total - 1) {
      await postGroupRows(arrRows[index + 1], index + 1);
    }
  };
  await postGroupRows(arrRows[0], 0);
  dispatch(getRowsByDatabaseId({ dbId, dbIndex, lastKey: null }));
});

export const updateRow = createAsyncThunk<
  { row: IDatabaseRow; index: number },
  { dbId: string; row: IDatabaseRow; index: number }
>('databases/updateRow', async ({ dbId, row, index }) => {
  await postRowsDb(dbId, [row]);
  return { index, row };
});

export const deleteRows = createAsyncThunk<
  { id: string | number }[],
  { dbId: string; ids: { id: string | number }[] }
>('databases/deleteRows', async ({ dbId, ids }) => {
  await deleteDbRows(dbId, ids);
  return ids;
});

export const deleteDatabase = createAsyncThunk<string, string>(
  'databases/deleteDatabase',
  async dbId => {
    await deleteDb(dbId);
    return dbId;
  },
);
