import { ICampaign, IPBXCampaignInfo } from './reducer';
import {
  ICreateCampaign,
  ITagSummarized,
} from 'library/interfaces/campaignInterfaces';
import {
  GET_ALL_CAMPAIGNS,
  GET_ALL_CAMPAIGNS_FAIL,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_BY_ID,
  GET_CAMPAIGN_BY_ID_FAIL,
  GET_CAMPAIGN_BY_ID_SUCCESS,
  GET_PBX_CAMPAIGN_INFO,
  GET_PBX_CAMPAIGN_INFO_fAIL,
  GET_PBX_CAMPAIGN_INFO_SUCCESS,
  IGetAllCampaigns,
  IGetAllCampaignsFail,
  IGetAllCampaignsSuccess,
  IGetCampaignById,
  IGetCampaignByIdFail,
  IGetCampaignByIdSuccess,
  IGetPBXCampaignInfo,
  IGetPBXCampaignInfoSuccess,
  IGetPBXCampaignInfoFail,
  IPostCreateCampaign,
  IPostCreateCampaignFail,
  IPostCreateCampaignSuccess,
  IPutUpdateCampaign,
  IPutUpdateCampaignFail,
  IPutUpdateCampaignSuccess,
  IPutUpdateCampaignStatus,
  IPutUpdateCampaignStatusSuccess,
  IPutUpdateCampaignStatusFail,
  POST_CREATE_CAMPAIGN,
  POST_CREATE_CAMPAIGN_FAIL,
  POST_CREATE_CAMPAIGN_SUCCESS,
  PUT_UPDATE_CAMPAIGN,
  PUT_UPDATE_CAMPAIGN_FAIL,
  PUT_UPDATE_CAMPAIGN_SUCCESS,
  CampaignStatusActions,
  CampaignStatusSuccess,
  CampaignStatusFail,
  campaignAction,
  IDeleteCampaign,
  DELETE_CAMPAIGN,
  IDeleteCampaignSuccess,
  DELETE_CAMPAIGN_SUCCESS,
  IDeleteCampaignFail,
  DELETE_CAMPAIGN_FAIL,
  IRefreshCampaign,
  REFRESH_CAMPAIGN,
  IRefreshCampaignSuccess,
  REFRESH_CAMPAIGN_SUCCESS,
  IRefreshCampaignFail,
  REFRESH_CAMPAIGN_FAIL,
  IGetCampaignTagsSummarized,
  GET_CAMPAIGN_TAGS_SUMMARIZED,
  IGetCampaignTagsSummarizedSuccess,
  GET_CAMPAIGN_TAGS_SUMMARIZED_SUCCESS,
  IGetCampaignTagsSummarizedFail,
  GET_CAMPAIGN_TAGS_SUMMARIZED_FAIL,
  IPostContactGroupsAction,
  POST_CONTACT_GROUP,
  POST_CONTACT_GROUP_SUCCESS,
  IPostContactGroupSuccess,
  IPostContactGroupFail,
  POST_CONTACT_GROUP_FAIL,
} from './actionTypes';

export const getAllCampaignsAction = (owner: string): IGetAllCampaigns => ({
  type: GET_ALL_CAMPAIGNS,
  payload: owner,
});

export const getAllCampaignsSuccess = (
  campaigns: ICampaign[],
): IGetAllCampaignsSuccess => ({
  type: GET_ALL_CAMPAIGNS_SUCCESS,
  payload: campaigns,
});

export const getAllCampaignsFail = (error: string): IGetAllCampaignsFail => ({
  type: GET_ALL_CAMPAIGNS_FAIL,
  payload: error,
});

export const getCampaignByIdAction = (id: string): IGetCampaignById => ({
  type: GET_CAMPAIGN_BY_ID,
  payload: id,
});

export const getCampaignByIdSuccess = (
  campaign: ICampaign,
): IGetCampaignByIdSuccess => ({
  type: GET_CAMPAIGN_BY_ID_SUCCESS,
  payload: campaign,
});

export const getCampaignByIdFail = (error: string): IGetCampaignByIdFail => ({
  type: GET_CAMPAIGN_BY_ID_FAIL,
  payload: error,
});

export const getPBXCampaignInfoAction = (): IGetPBXCampaignInfo => ({
  type: GET_PBX_CAMPAIGN_INFO,
});

export const getPBXCampaifnInfoSuccess = (
  pbxCampaignInfo: IPBXCampaignInfo,
): IGetPBXCampaignInfoSuccess => ({
  type: GET_PBX_CAMPAIGN_INFO_SUCCESS,
  payload: pbxCampaignInfo,
});

export const getPBXCampaifnInfoFail = (
  error: string,
): IGetPBXCampaignInfoFail => ({
  type: GET_PBX_CAMPAIGN_INFO_fAIL,
  payload: error,
});

export const postCreateCampaignAction = (
  campaign: ICreateCampaign,
): IPostCreateCampaign => ({
  type: POST_CREATE_CAMPAIGN,
  payload: campaign,
});

export const postCreateCampaignSuccess = (
  id: string,
): IPostCreateCampaignSuccess => ({
  type: POST_CREATE_CAMPAIGN_SUCCESS,
  payload: id,
});

export const postCreateCampaignFail = (
  error: string,
): IPostCreateCampaignFail => ({
  type: POST_CREATE_CAMPAIGN_FAIL,
  payload: error,
});

export const putUpdateCampaignAction = (
  id: string,
  index: number,
  editedCampaign: ICreateCampaign,
): IPutUpdateCampaign => ({
  type: PUT_UPDATE_CAMPAIGN,
  payload: { id, index, editedCampaign },
});

export const putUpdateCampaignSuccess = (
  index: number,
  campaign: ICampaign,
): IPutUpdateCampaignSuccess => ({
  type: PUT_UPDATE_CAMPAIGN_SUCCESS,
  payload: { index, campaign },
});

export const putUpdateCampaignFail = (
  error: string,
): IPutUpdateCampaignFail => ({
  type: PUT_UPDATE_CAMPAIGN_FAIL,
  payload: error,
});

export const putUpdateCampaignStatusAction = (
  id: string,
  index: number,
  newStatus: campaignAction,
  action: CampaignStatusActions,
): IPutUpdateCampaignStatus => ({
  type: action,
  payload: { id, index, newStatus },
});

export const putUpdateCampaignStatusSuccess = (
  index: number,
  campaign: ICampaign,
  action: CampaignStatusSuccess,
): IPutUpdateCampaignStatusSuccess => ({
  type: action,
  payload: { index, campaign },
});

export const putUpdateCampaignStatusFail = (
  error: string,
  action: CampaignStatusFail,
): IPutUpdateCampaignStatusFail => ({
  type: action,
  payload: error,
});

export const deleteCampaignAction = (
  id: string,
  index: number,
): IDeleteCampaign => ({
  type: DELETE_CAMPAIGN,
  payload: { id, index },
});

export const deleteCampaignSuccess = (
  index: number,
): IDeleteCampaignSuccess => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: { index },
});

export const deleteCampaignFail = (error: string): IDeleteCampaignFail => ({
  type: DELETE_CAMPAIGN_FAIL,
  payload: error,
});

export const refreshCampaignAction = (
  id: string,
  index: number,
): IRefreshCampaign => ({
  type: REFRESH_CAMPAIGN,
  payload: { id, index },
});

export const refreshCampaignSuccess = (
  index: number,
  campaign: ICampaign,
): IRefreshCampaignSuccess => ({
  type: REFRESH_CAMPAIGN_SUCCESS,
  payload: { index, campaign },
});

export const refreshCampaignFail = (error: string): IRefreshCampaignFail => ({
  type: REFRESH_CAMPAIGN_FAIL,
  payload: error,
});

export const getCampaignTagsSummarizedAction = (
  campaignIndex: number,
  campaignId: string,
): IGetCampaignTagsSummarized => ({
  type: GET_CAMPAIGN_TAGS_SUMMARIZED,
  payload: { campaignIndex, campaignId },
});

export const getCampaignTagsSummarizedSuccess = (
  campaignIndex: number,
  tagsSummarized: ITagSummarized[],
): IGetCampaignTagsSummarizedSuccess => ({
  type: GET_CAMPAIGN_TAGS_SUMMARIZED_SUCCESS,
  payload: { campaignIndex, tagsSummarized },
});

export const getCampaignTagsSummarizedFail = (
  error: string,
): IGetCampaignTagsSummarizedFail => ({
  type: GET_CAMPAIGN_TAGS_SUMMARIZED_FAIL,
  payload: error,
});

export const postContactGroupsAction = (
  totalGroups: number,
): IPostContactGroupsAction => ({
  type: POST_CONTACT_GROUP,
  payload: { totalGroups },
});

export const postContactGroupsSuccess = (
  sendingGroupNumber: number,
): IPostContactGroupSuccess => ({
  type: POST_CONTACT_GROUP_SUCCESS,
  payload: { sendingGroup: sendingGroupNumber },
});

export const postContactGroupFail = (error: string): IPostContactGroupFail => ({
  type: POST_CONTACT_GROUP_FAIL,
  payload: error,
});
