import SearchAndButton from 'components/outbound/molecules/searchAndButton';
import { lightBackground, lightBackgroundBlue, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import DatabaseTable from '../organisms/databaseTable';
import { IDatabase, IDatabaseRow } from 'library/interfaces/databasesInterfaces';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { deleteRows, getRowByRowId, getRowsByDatabaseId } from 'store/databases/asyncActions';
import { ISelectedDatabaseState } from 'store/databases/reducer';
import LoadingImg from 'components/Configuration/atoms/loading';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import InputsAndButtons from '../organisms/inputsAndButtons';

interface IDatabaseDataProps {
  currentDb?: IDatabase
  selectedDatabase: ISelectedDatabaseState
  setShowUploadDataForm: (show: boolean) => void
  setShowModifyColumnsForm: (show: boolean) => void
  setSelectedRow: (row: IDatabaseRow | null, index: number) => void
}
const DatabaseData: FC<IDatabaseDataProps> = ({
  currentDb,
  selectedDatabase,
  setShowUploadDataForm,
  setShowModifyColumnsForm,
  setSelectedRow
}) => {

  const dispatch = useAppDispatch()

  const [deleteIds, setDeleteIds] = useState<{ id: string | number }[]>([])
  const [filter, setFilter] = useState('')

  const handleGetMoreDataClick = () => {
    if (!currentDb || !selectedDatabase.lastKey) return
    dispatch(getRowsByDatabaseId({
      dbId: currentDb.id,
      dbIndex: currentDb.indexList[0]?.key || '',
      lastKey: selectedDatabase.lastKey,
    }))
  }
  const handleDeleteCheckboxChange = (id: string | number, checked: boolean) => {
    if (checked) {
      return setDeleteIds(prev => [...prev, { id }])
    }
    setDeleteIds(prev => prev.filter(item => item.id !== id))
  }

  const handleDeleteRowsClick = () => {
    if (!currentDb || deleteIds.length === 0) return
    dispatch(deleteRows({ dbId: currentDb.id, ids: deleteIds }))
    setDeleteIds([])
  }

  const handleSearchClick = () => {
    if (!currentDb) return
    if (filter === '') {
      dispatch(getRowsByDatabaseId({
        dbId: currentDb.id,
        dbIndex: currentDb.indexList[0]?.key || '',
        lastKey: null,
      }))
      return
    }
    dispatch(getRowByRowId({ dbId: currentDb.id, rowId: filter }))
  }

  return (
    <Container>
      <InputsAndButtons
        setFilter={setFilter}
        setShowUploadDataForm={() => setShowUploadDataForm(true)}
        setShowModifyColumnsForm={() => setShowModifyColumnsForm(true)}
        handleDeleteRowsClick={handleDeleteRowsClick}
        handleSearchClick={handleSearchClick}
      />
      <TableCont>
        {currentDb && <DatabaseTable deleteIds={deleteIds} currentDb={currentDb} setSelectedRow={setSelectedRow} handleDeleteCheckboxChange={handleDeleteCheckboxChange} />}
        {selectedDatabase.lastKey && <MoreRows>
          {!selectedDatabase.isLoading && <span onClick={() => handleGetMoreDataClick()}>Cargar más datos</span>}
          {selectedDatabase.isLoading && <div >Cargando <LoadingPoints /></div>}
        </MoreRows>}
      </TableCont>
      {selectedDatabase.isLoading && selectedDatabase.percent === null && <LoadingImg blur={2} />}
      {selectedDatabase.isLoading && selectedDatabase.percent !== null && <LoadingImg blur={2} porcent={selectedDatabase.percent} />}
    </Container>
  );
};
const Container = styled.div`
  flex-grow: 1;
  padding: 0 20px 20px;
  border-radius: 10px;
  background-color: ${lightBackground};
`;
const TableCont = styled.div`
  max-height: calc(100vh - 280px);
  overflow-y: scroll;
  padding: 20px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};


  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const MoreRows = styled.div`
  padding: 10px 0;
  text-align: center;

  span {
    cursor: pointer;
    color: #0000EE ;

    &:hover{
      color: ${primary};
    }
  }
`;
export default DatabaseData;