import React, { FC } from 'react';
import styled from 'styled-components';

import { lightBackgroundBlue, popUpBackground, primary } from 'library/colors';
import CreateCampaignResume from './createCampaignResume';
import { ICampaign, IPBXCampaignInfo } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import {
  mainInfoService,
  selectedAgentsService,
  selectedTrunkIdService,
} from 'library/services/campaignFormServices';
import { useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';
import { IReportData } from 'library/interfaces/crdInterfaces';
import { IAgentToSelect } from '../atoms/selectableAgent';

interface ICampaignDetailsProps {
  campaign: ICampaign;
  setShowCampaignDetails: (show: boolean) => void
}
const CampaignDetails: FC<ICampaignDetailsProps> = ({ campaign, setShowCampaignDetails }) => {
  const { users, queues } = useSelector<IStore, IReportData>(
    store => store.cdr.reportData,
  );
  const pbxCampaignInfo = useSelector<IStore, IPBXCampaignInfo | null>(
    store => store.voiceCampaigns.pxbCampaignInfo,
  );

  const newAgentsToSelect: IAgentToSelect[] = [];

  users.map(user => {
    if (!user.exten) return;
    newAgentsToSelect.push({
      exten: user.exten,
      name: user.name,
      isSelected: false,
    });
  });

  const mainInfo = mainInfoService(campaign, 1);
  const agentsToSelect = selectedAgentsService(campaign, newAgentsToSelect);
  const totalContacts = campaign.contacts ? campaign.contacts.length : 0;
  const trunksName = pbxCampaignInfo
    ? selectedTrunkIdService(campaign, pbxCampaignInfo).name
    : '';

    const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!(event.target instanceof Element)) return;
  
      if (
        event.target.id === 'popupContainer' ||
        event.target.id === 'exitButton'
      ) {
        return setShowCampaignDetails(false);
      }
    };
  return (
    <PopUpContainer id='popupContainer' onClick={e=>handleExit(e)}>
      <Cont>
        <Exit id='exitButton'>X</Exit>
          <CreateCampaignResume 
            mainInfo={mainInfo} 
            agentsToSelect={agentsToSelect} 
            totalContacts={totalContacts} 
            trunksName={trunksName} 
            campaignNumbers={{callerId: campaign.callerid, did: campaign.did}}
          />
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const Cont = styled.div`
  position: fixed;
  top: 120px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 20px 10px;
  border-radius: 20px;
  background-color: ${lightBackgroundBlue};
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
export default CampaignDetails;
