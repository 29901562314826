import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Divider from '../../Divider';
import * as DataInfo from '../../DataInfo';

View.propTypes = {
  data: PropTypes.object.isRequired,
};

function View({ data }) {
  return (
    <div>
      <Row>
        <Col lg={6}>
          <DataInfo.Label>Synth language</DataInfo.Label>
          <DataInfo.Content>
            {data?.synthLanguage?.label || '-'}
          </DataInfo.Content>
        </Col>
        <Col lg={6}>
          <DataInfo.Label>Voice</DataInfo.Label>
          <DataInfo.Content>{data?.voice?.label || '-'}</DataInfo.Content>
        </Col>
      </Row>

      <Divider />

      <Row>
        <DataInfo.Label>
          Text (
          <a
            href="https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html"
            target="_blank"
            rel="noreferrer"
          >
            SSML tags
          </a>
          )
        </DataInfo.Label>
        <DataInfo.Content>
          {data?.text || 'Hello! This is an automated information system'}
        </DataInfo.Content>
      </Row>
    </div>
  );
}

export default View;
