import { 
  CDR_ACTION_FAIL, 
  GET_AGENTS_SERVICE_LEVEL, 
  GET_AGENTS_SERVICE_LEVEL_SUCCESS, 
  GET_LIVE_DATA, 
  GET_LIVE_DATA_SUCCESS, 
  GET_REPORT_DATA, 
  GET_REPORT_DATA_SUCCESS, 
  GET_SUMMARIZE_DATA, 
  GET_SUMMARIZE_DATA_SUCCESS 
} from "./actionTypes"

export const cdrActionFail = error => ({
  type: CDR_ACTION_FAIL,
  payload: error,
})

export const getLiveData = (owner, queues) => ({
  type: GET_LIVE_DATA,
  payload: {owner, queues},
})

export const getLiveDataSuccess = (data) => ({
  type: GET_LIVE_DATA_SUCCESS,
  payload: data,
})

export const getSummarizeData = (owner, period, timezone, queues) => ({
  type: GET_SUMMARIZE_DATA,
  payload: {owner, period, timezone, queues},
})

export const getSummarizeDataSuccess = (data) => ({
  type: GET_SUMMARIZE_DATA_SUCCESS,
  payload: data,
})

export const getReportData = () => ({
  type: GET_REPORT_DATA
})

export const getReportDataSuccess = (data) => ({
  type: GET_REPORT_DATA_SUCCESS,
  payload: data,
})

export const getAgentServiceLevelAction = (owner, startDate, endDate) => ({
  type: GET_AGENTS_SERVICE_LEVEL,
  payload: {owner, startDate, endDate}
})

export const getAgentServiceLevelSuccess = (data) =>({
  type: GET_AGENTS_SERVICE_LEVEL_SUCCESS,
  payload: data
})