import React, { FC, useState } from 'react';
import styled from 'styled-components';

import {
  lightBackground,
  lightBackgroundBlue,
  lightText,
  popUpBackground,
  primary,
  secondary,
} from 'library/colors';
import SelectableAgent, {
  IAgentToSelect,
} from 'components/outbound/atoms/selectableAgent';
import SelectableUserList from '../molecules/selectableUserList';
import { IQueue } from 'library/interfaces/crdInterfaces';
import { ISipperId } from 'library/interfaces/addAgentToQueueInterfaces';
import { useDispatch } from 'react-redux';
import { updateAgentsInQueueAction } from 'store/actions';
import ArrowsButtons from '../molecules/userListArrows';
import { filterAgents } from 'library/services/addAgentToQueueServices';
import { useTranslation } from 'react-i18next';

interface IAddAgentToQueue {
  setShow: Function;
  queue: IQueue;
  selectableAgents: {
    inQueue: IAgentToSelect[];
    free: IAgentToSelect[];
  };
  setSelectableAgents: Function;
}

const AddAgentToQueue: FC<IAddAgentToQueue> = ({
  setShow,
  queue,
  selectableAgents,
  setSelectableAgents,
}) => {
  const {t} = useTranslation('summarize')
  const dispatch = useDispatch();

  const [filter, setFilter] = useState('');
  const freeAgents = filterAgents(selectableAgents.free, filter);
  const inQueueAgents = filterAgents(selectableAgents.inQueue, filter);

  const handleSelectAgent = (
    index: number,
    isSelected: boolean,
    source: 'inQueue' | 'free',
  ) => {
    const newSelectableAgentsState = selectableAgents[source];
    newSelectableAgentsState[index].isSelected = isSelected;
    setSelectableAgents({
      ...selectableAgents,
      [source]: newSelectableAgentsState,
    });
  };
  const handleSelectAllAgents = (
    selectAll: boolean,
    source: 'inQueue' | 'free',
  ) => {
    const newSelectableAgentsState = selectableAgents[source].map(agent => {
      return { ...agent, isSelected: selectAll };
    });
    setSelectableAgents({
      ...selectableAgents,
      [source]: newSelectableAgentsState,
    });
  };

  const addAgents = () => {
    const selected: IAgentToSelect[] = [];
    const noSelected: IAgentToSelect[] = [];
    selectableAgents.free.forEach(agent => {
      if (agent.isSelected === true)
        return selected.push({ ...agent, isSelected: false });
      noSelected.push(agent);
    });
    setSelectableAgents({
      free: noSelected,
      inQueue: [...selected, ...selectableAgents.inQueue],
    });
  };
  const removeAgents = () => {
    const selected: IAgentToSelect[] = [];
    const noSelected: IAgentToSelect[] = [];
    selectableAgents.inQueue.forEach(agent => {
      if (agent.isSelected === true)
        return selected.push({ ...agent, isSelected: false });
      noSelected.push(agent);
    });
    setSelectableAgents({
      free: [...selected, ...selectableAgents.free],
      inQueue: noSelected,
    });
  };

  const updateAgentsInQueue = () => {
    const agents: ISipperId[] = [];
    selectableAgents.inQueue.forEach(agent => {
      agents.push({ sippeer_id: agent.exten });
    });
    dispatch(updateAgentsInQueueAction(queue.id, { agents }));
  };
  const handleExitClick = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
  ) => {
    if (
      !(event.target instanceof HTMLDivElement) &&
      !(event.target instanceof HTMLButtonElement)
    )
      return;
    const { id } = event.target;
    if (id === 'popupBackground' || id === 'exitButton') {
      setShow(false);
    }
  };
  return (
    <PopUpContainer id="popupBackground" onClick={e => handleExitClick(e)}>
      <Div id="Div">
        <Exit id="exitButton">x</Exit>
        <BlueCont>
          <Title>{t('addAgentsForm.addAgentsTo')} {queue.name}</Title>
        </BlueCont>
        <BlueCont>
          <Filters>
            <Input
              type="text"
              placeholder={t('addAgentsForm.searchByUserOrExtension')}
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          </Filters>
          <FlexCont>
            <SelectableUserList
              height="35vh"
              width="320px"
              source="free"
              selectableAgents={freeAgents}
              handleSelectAgent={handleSelectAgent}
              handleSelectAllAgents={handleSelectAllAgents}
            />
            <ArrowsButtons moveToLeft={removeAgents} moveToRight={addAgents} />
            <SelectableUserList
              height="35vh"
              width="320px"
              source="inQueue"
              selectableAgents={inQueueAgents}
              handleSelectAgent={handleSelectAgent}
              handleSelectAllAgents={handleSelectAllAgents}
            />
          </FlexCont>
        </BlueCont>
        <BlueCont>
          <FlexCont>
            <span></span>
            <div>
              <CancelButton id="exitButton">{t('addAgentsForm.cancel')}</CancelButton>
              <Button onClick={e => updateAgentsInQueue()}>{t('addAgentsForm.confirm')}</Button>
            </div>
          </FlexCont>
        </BlueCont>
      </Div>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  inset: 0;
  z-index: 200;
  background-color: ${popUpBackground};
`;
const Div = styled.div`
  position: fixed;
  top: 120px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const BlueCont = styled.div`
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.h2`
  font-size: 1.3rem;
  text-align: center;
  margin: 0px;
  padding: 0px;
`;
const Filters = styled.div`
  font-size: 0.7rem;
  display: flex;
  margin-bottom: 20px;
  border-radius: 30px;
  border: 1px solid ${secondary};
  background-color: ${lightBackground};
`;
const Input = styled.input`
  border: none;
  width: 100%;
  padding: 7px 20px;
  background-color: transparent;
`;
const FlexCont = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CancelButton = styled.button`
  font-size: 0.8rem;
  border: none;
  background: transparent;
`;
const Button = styled(CancelButton)`
  border-radius: 30px;
  padding: 5px 20px;
  font-weight: 700;
  margin-left: 30px;
  color: ${lightText};
  background-color: ${primary};

  &:hover {
    background-color: #698203;
  }
`;
export default AddAgentToQueue;
