import { call, put, takeEvery } from 'redux-saga/effects';
import { getChatBot, updateCallStatus } from 'helpers/fakebackend_helper';
import {
  GET_CHATBOT_BY_CALL,
  IGetChatBotByCall,
  IUpdateCallStatus,
  UPDATE_ASESP_CALL_STATUS,
} from './actionTypes';
import { IChatBotStore } from './reducer';
import {
  getChatBotByCallFail,
  getChatBotByCallSuccess,
  updateAsespCallStatusFail,
  updateAsespCallStatusSuccess,
} from './actions';

function* onGetChatBotByCall({
  payload: { owner, uniqueId },
}: IGetChatBotByCall) {
  try {
    if (!owner || !uniqueId) throw new Error('Incomplete Data');

    const response: IChatBotStore = yield call(
      getChatBot,
      owner.toLowerCase(),
      uniqueId,
    );

    yield put(getChatBotByCallSuccess(response));
  } catch (e) {
    yield put(getChatBotByCallFail(`Something was wrong! ${e}`));
  }
}

function* onUpdateAsespCallStatus({
  payload: { callStatus },
}: IUpdateCallStatus) {
  try {
    console.log('saga')
    const response: unknown = yield call(updateCallStatus, callStatus);
    if (response && typeof response === 'object' && 'error' in response)
      throw new Error('no se actualizó el estado de la llamada');
    yield put(updateAsespCallStatusSuccess());
  } catch (e) {
    console.log(e);
    yield put(updateAsespCallStatusFail());
  }
}

function* ChatBotSaga() {
  yield takeEvery(GET_CHATBOT_BY_CALL, onGetChatBotByCall);
  yield takeEvery(UPDATE_ASESP_CALL_STATUS, onUpdateAsespCallStatus);
}
export default ChatBotSaga;
