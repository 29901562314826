import { darkBorder, lightGreen, primary } from 'library/colors';
import { Phone } from 'library/phone/phone';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, } from 'react-redux';
import {
  getConversationSessionsAction,
  getAllCampaignsAction,
  updateDialedNumberAction,
  updateIsHeldAction,
  updateIsToMakeCallAction,
  updateBlindTransferExt,
  getChannelsAction,
  getGupshupAppTemplatesAction,
  updateAuthUserExtenAction,
} from 'store/actions';
import styled from 'styled-components';
import Search from '../atoms/searchInput';
import TabButtons from '../atoms/tabButtons';
import CurrentCall from '../molecules/currentCall';
import PhoneCall from '../molecules/phoneCall';
import PhoneKeyboard from '../molecules/phoneKeyboard';
import PhoneTransferCall from '../molecules/phoneTransferCall';
import TagsPopup from './tagsPopup';
import ConversationSessions from '../molecules/activeSessions';
import { conversationInWorkspaceIsEnabled } from 'library/enableSpecificFeatures/conversationWorkspace';
import { useAppSelector } from 'library/hooks/useAppSelector';
import TransferNumbers from '../molecules/transferNumbers';

interface ILeftBlock {
  phone: Phone;
}

const LeftBlock: FC<ILeftBlock> = ({ phone }) => {
  const [tab, setTab] = useState(0);
  const [showTags, setShowTags] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [filter, setFilter] = useState('')
  const [filterByCampaignId, setFilterByCampaignId] = useState('')

  const channels = useAppSelector(store => store.channels.channels)
  const waCampaigns = useAppSelector(store => store.waCampaigns.campaigns)

  const phoneStore = useAppSelector(state => state.phone);
  const { owner, exten, pbx_url, exten_list } = useAppSelector(
    store => store.authUserData,
  );
  const { t } = useTranslation('workspace');
  const dispatch = useDispatch();

  const waCampaignsToRender = useMemo(() => {
    return waCampaigns.filter(camp => camp.active !== false)
  }, [waCampaigns])

  const remoteDisplayName =
    phone && phone.getRemoteDisplayName() !== ''
      ? phone.getRemoteDisplayName()
      : '';
  const numberToShow = phoneStore.isIncommingCall
    ? phone.getRemoteId()
    : phoneStore.dialedNumber;

  const handleAcceptButtonClick = () => {
    phone.answer();
  };

  const handleHangUpButtonClick = () => {
    phone.hangup();
  };
  const showNumericKeyboard = () => {
    if (!phone || phoneStore.isCallAnswered) return;
    if (phoneStore.isToMakeACall !== undefined) {
      return dispatch(updateIsToMakeCallAction(undefined));
    }
    return dispatch(updateIsToMakeCallAction(true));
  };
  const updateDialedNumber = (number: string) => {
    dispatch(updateDialedNumberAction(number));
  };
  const handlePhoneKeyboardCallClick = () => {
    if (phoneStore.dialedNumber === '') return;
    if (phoneStore.isToMakeACall === true) {
      phone.call(phoneStore.dialedNumber, pbx_url);
    } else {
      phone.transferCall(phoneStore.dialedNumber, pbx_url);
    }
    dispatch(updateIsToMakeCallAction(undefined));
  };

  const handleHangUpTransferCall = () => {
    phone.hangupTransferCall();
  };
  const handleConfirmTransfer = () => {
    phone.confirmTransfer();
  };
  const handleBlindTransfer = () => {
    if (phoneStore.dialedNumber === '') return;
    phone.blindTransfer(phoneStore.dialedNumber, pbx_url);
    dispatch(updateIsToMakeCallAction(undefined));
  };
  const handleExitKeyboard = () => {
    if (phone.isHeld()) {
      phone.unhold();
      dispatch(updateIsHeldAction(false));
    }
    dispatch(updateIsToMakeCallAction(undefined));
  };

  const handleShowTagsCont = () => {
    setShowTags(!showTags);
  };

  const handleReturnToQueueClick = () => {
    if (!phoneStore.blindTransferExt) return
    phone.blindTransfer(phoneStore.blindTransferExt, pbx_url)

    // blindTransferExt vuelve a ser undefined
    dispatch(updateBlindTransferExt())
  }

  const handleChangeExten = (value: string) => {
    if (value === exten) return

    const extenItem = exten_list.find(item => item.exten === value)

    if (!extenItem) return
    if (phone) {
      phone.unregister()
    }
    dispatch(updateAuthUserExtenAction(extenItem.exten, extenItem.exten_name, extenItem.exten_password))
  }

  useEffect(() => {
    if (!owner) return;
    dispatch(getAllCampaignsAction(owner));


    if (exten || !conversationInWorkspaceIsEnabled[owner]) return;
    dispatch(getConversationSessionsAction(conversationInWorkspaceIsEnabled[owner]));

    dispatch(getChannelsAction())
  }, [owner]);

  useEffect(() => {
    if (channels.length === 0 || !conversationInWorkspaceIsEnabled[owner]) return

    const channel = channels.find(chan => chan.number === conversationInWorkspaceIsEnabled[owner].find(val => val.channelType === 'whatsapp')?.value)
    if (!channel) return
    dispatch(getGupshupAppTemplatesAction(channel.appId))
  }, [channels])

  return (
    <LeftBlockCont>
      <HeaderCont>
        <Header>
          {phoneStore.isRegistered && <Span>{t('active')}</Span>}

          {owner === 'CORPORACION' && (
            <TagsButton onClick={() => handleShowTagsCont()}>Tags</TagsButton>
          )}
          {/* {phoneStore.blindTransferExt &&
            <TagsButton onClick={() => handleReturnToQueueClick()}>
              {t('returnToBot')}
            </TagsButton>
          } */}
          {phoneStore.blindTransferExtList && phoneStore.isIncommingCall &&
            <TagsButton onClick={() => setShowTransfer(true)}>
              Tranferir
            </TagsButton>}

          {phoneStore.blindTransferExtList && showTransfer &&
            <TransferNumbers
              phone={phone}
              setShow={setShowTransfer}
              extList={phoneStore.blindTransferExtList}
            />
          }

          <PhoneIcon onClick={() => showNumericKeyboard()}>
            <i className="bx bx-phone-call text-primary" />
          </PhoneIcon>
        </Header>
        {phoneStore.isIncommingCall !== undefined &&
          !phoneStore.isCallAnswered && (
            <PhoneCall
              isIncoming={phoneStore.isIncommingCall}
              dialedNumber={numberToShow}
              onHangUp={handleHangUpButtonClick}
              acceptCall={handleAcceptButtonClick}
              remoteDisplayName={remoteDisplayName}
            />
          )}

        {phoneStore.isTransferCall !== undefined && (
          <PhoneTransferCall
            isTransferCall={phoneStore.isTransferCall}
            dialedNumber={
              phone && phone.getTransferId() ? phone.getTransferId() : ''
            }
            onHangUp={handleHangUpTransferCall}
            transfer={handleConfirmTransfer}
          />
        )}

        {phoneStore.isToMakeACall !== undefined && (
          <PhoneKeyboard
            isCall={phoneStore.isToMakeACall ? phoneStore.isToMakeACall : false}
            dialedNumber={phoneStore.dialedNumber}
            setDialedNumber={updateDialedNumber}
            onExitClick={handleExitKeyboard}
            onCallButtonClick={handlePhoneKeyboardCallClick}
            onBlindTranferButtonClick={handleBlindTransfer}
          />
        )}
      </HeaderCont>
      {phoneStore.isCallAnswered && <CurrentCall phone={phone} />}

      {exten_list.length > 0 &&
        <Select value={exten} onChange={e => handleChangeExten(e.target.value)}>
          {exten_list.map((ext, index) => {
            return (
              <option key={index} value={ext.exten}>{ext.exten_name}</option>
            )
          })}
        </Select>}

      <Search filter={filter} setfilter={setFilter} />

      {showTags && (
        <TagsPopup
          owner={owner}
          number={phone ? phone.getRemoteId() : ''}
          handleExit={handleShowTagsCont}
          phone={phone}
        />
      )}
      {waCampaignsToRender.length > 0 &&
        <Select value={filterByCampaignId} onChange={e => setFilterByCampaignId(e.target.value)}>
          <option value="">Selecciona una campaña</option>
          {waCampaignsToRender.map(camp => {
            return (
              <option key={camp.id} value={camp.id}>{camp.name}</option>
            )
          })}
        </Select>}
      <TabButtons setTab={setTab} />
      <ConversationSessions filter={filter} tab={tab} filterByCampaign={filterByCampaignId} />
    </LeftBlockCont>
  );
};
const LeftBlockCont = styled.div`
  width: 300px;
  min-width: 300px;
  margin-right: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
`;
const HeaderCont = styled.div`
  min-height: 82px;
  border-bottom: 2px solid ${darkBorder};
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Span = styled.span`
  color: black;
  font-size: 1rem;
  position: relative;
  margin-left: 25px;
  cursor: default;
  display: grid;
  place-items: center;

  &:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    top: 5px;
    left: -20px;
    border-radius: 50%;
    background-color: ${lightGreen};
  }
`;
const Select = styled.select`
  width: 100%;
  min-height: 35px;
    font-size: .9rem;
    padding-left: 15px;
    border-radius: 20px;
    border: none;
    background-color: #fff;
    color: #606060;
    margin: 10px 0;
`;
const TagsButton = styled.div`
  border: 1px solid;
  padding: 1px 10px 0;
  font-size: 0.8rem;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${primary};
`;
const PhoneIcon = styled.div`
  text-align: right;
  margin-right: 20px;
  display: grid;
  place-items: center;
  & > i {
    font-size: 1.5rem;
    display: inline-block;
    cursor: pointer;
  }
`;
export default LeftBlock;
