import React, { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
} from 'react-flow-renderer';

import './Editor.css';
import CustomEdge from './common/CustomEdge';
import { nodeTypes, nodelabels } from './noteTypes';
import { useDispatch } from 'react-redux';

import { updateFlowScenario as onPublishScenario } from 'store/actions';

const edgeTypes = {
  default: CustomEdge,
};

function MindNode({ scenario, editorCMD }) {
  const dispatch = useDispatch();
  const reactFlowWrapper = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  // const [node, setNode] = useState();
  // const [data, setData] = useState('');
  // const [nodeName, setNodeName] = useState('');

  const onConnect = params => setEdges(eds => addEdge(params, eds));

  useEffect(() => {
    if (scenario?.flow) {
      if (scenario.flow?.nodes?.length > 0) {
        setNodes(scenario?.flow.nodes);
      } else {
        //setNodes(initialNodes)
      }
      setEdges(scenario?.flow.edges);
    }
  }, [scenario]);

  useEffect(() => {
    if (editorCMD) {
      if (editorCMD === 'on-publish')
        dispatch(onPublishScenario(scenario.id, reactFlowInstance.toObject()));
      console.log('CMD: ', editorCMD, scenario.id);
    }
  }, [editorCMD]);

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: uuidv4(),
        type,
        position,
        data: { label: nodelabels[type] },
      };

      setNodes(nds => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes],
  );

  // const onPublishClick = () => {
  //   if(scenario) {
  //     dispatch(onUpdateFlowScenario(scenario.id, reactFlowInstance.toObject()))
  //   }
  // }

  // const onDataChange = (e) => {
  //   setData(e.target.value);
  // }

  // const onSetClick = (e) => {
  //   setNodes(nodes.map(e => {
  //       if (e?.id === node?.id) {
  //         e.data = JSON.parse(data)
  //         console.log("Change: ", data)
  //       }
  //       return e;
  //     })
  //   );
  // }

  // const onNodeDoubleClick= (event, element) => {
  //   setNode(element)
  //   setData(JSON.stringify(element?.data))
  // };

  // const onAddNodeClick = (e) => {
  //   if(nodes?.length > 0) {
  //   setNodes(old => [
  //     ...old, {
  //       id: (old.length + 1).toString(),
  //       type: nodeName,
  //       data: {label: `${nodeName} - ${(old.length + 1).toString()}`},
  //       position: {
  //         x: Math.random() * window.innerWidth,
  //         y: Math.random() * window.innerHeight,
  //       }
  //     }])
  //   }
  // }

  return (
    <div ref={reactFlowWrapper} className="reactflow-wrapper">
      <ReactFlow
        // fitView
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onInit={setReactFlowInstance}
        style={{ width: '100%', height: '90vh' }}
        onConnect={onConnect}
        connectionLineStyle={{ stroke: '#ddd', strokeWidth: 2 }}
        connectionLineType="bezier"
        snapToGrid={true}
        snapGrid={[16, 16]}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <Background color="#888" gap={16} />
        {/*  <MiniMap
          nodeColor={n => {
            if (n.type === 'input') return 'blue';
            return '#ffcc00';
          }}
        />
        <Controls /> */}
      </ReactFlow>
    </div>
  );
}

MindNode.propTypes = {
  scenario: PropTypes.object.isRequired,
  editorCMD: PropTypes.string.isRequired,
};

export default MindNode;
