import React, { useMemo, useState } from 'react';
import { Node } from 'reactflow';
import styled from 'styled-components';
import { CustomNodeTypes, INodeData } from '../utils';
import { primary } from 'library/colors';
import flowIcons from '../utils/flowIcons';

interface ISearchNodeProps {
  nodes: Node<INodeData>[];
  centerSearchedNode: (nodeId: string) => void;
}
const SearchNode = ({ nodes, centerSearchedNode }: ISearchNodeProps) => {
  const [filter, setFilter] = useState('')
  const nodesToRender = useMemo(() => {
    const regEx = new RegExp(filter, 'gi')
    return nodes.filter(node => regEx.test(node.data.title))
  }, [nodes, filter])
  return (
    <>
      <Cont id='MenuNodos'>
        <Input type="text" id='filterText' value={filter} onChange={e => setFilter(e.target.value)} />
        {nodesToRender.map((node, index) => {
          const Icon = flowIcons[node.type as CustomNodeTypes]
          return (
            <Label key={index} onClick={() => centerSearchedNode(node.id)}>
              <IconCont>
                <Icon />
              </IconCont>
              {node.data.title}
            </Label>
          );
        })}
      </Cont>
    </>
  );
};
const Cont = styled.div`
  position: absolute;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  right: 0;
  top: 35px;
  background-color: #fff;
  max-height: 300px;
  min-width: 200px;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const Label = styled.div`
  padding: 5px 10px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(128, 128, 128, 0.201);
  }
`;
const Input = styled.input`
  width: calc(100% - 20px);
  margin: 5px 10px;
  border: 1px solid ${primary};
  border-radius: 50px;
  padding: 0 10px;
`;
const IconCont = styled.span`
 margin-right: 10px;

  & > svg {
    width: 20px;
    height: 20px;
    color: ${primary};
  }
`;
export default SearchNode;
