import React, { FC, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import PhoneRoundButton from '../atoms/phoneRoundButton';

import { contactGreen, lightGreen, primary } from 'library/colors';
import { useTranslation } from 'react-i18next';

interface IPhoneCall {
  isIncoming: boolean;
  dialedNumber: string;
  onHangUp: Function;
  acceptCall: Function;
  remoteDisplayName: string;
}
const PhoneCall: FC<IPhoneCall> = ({
  isIncoming,
  onHangUp,
  dialedNumber,
  acceptCall,
  remoteDisplayName,
}) => {
  const {t} = useTranslation('workspace')
  //props
  const client = {
    name: remoteDisplayName,
    number: dialedNumber,
  };
  const direction = isIncoming ? t('phoneCall.incomingCall') : t('phoneCall.outgoingCall');

  //state
  const [isAnswered, setIsAnswered] = useState(false);

  const acceptCallClick = () => {
    acceptCall();
    setIsAnswered(true);
  };

  const rejectCall = () => {
    onHangUp();
    setIsAnswered(false);
  };
  return (
    <PhoneCont>
      <ContactCont>
        <ContactIMG isAnswered={isAnswered} isIncoming={isIncoming}>
          {isIncoming && client.name.slice(0, 1) || dialedNumber.slice(0, 1)}
        </ContactIMG>
        <ContactInfo>
          {isIncoming && <Name>{client.name}</Name>}
          <Num>{isIncoming ? client.number : dialedNumber}</Num>
          <div>{direction}</div>
        </ContactInfo>
      </ContactCont>
      <ButtonsCont>
        {!isAnswered && isIncoming && (
          <IncomingButtons>
            <div>
              <PhoneRoundButton red onButtonClick={rejectCall} />
              {t('phoneCall.deny')}
            </div>
            <div>
              <PhoneRoundButton onButtonClick={acceptCallClick} />
              {t('phoneCall.answer')}
            </div>
          </IncomingButtons>
        )}
        {isAnswered && (
          <IncomingButtons>
            <div>
              <PhoneRoundButton red onButtonClick={rejectCall} />
              {t('phoneCall.hangUp')}
            </div>
          </IncomingButtons>
        )}
        {!isAnswered && !isIncoming && (
          <IncomingButtons>
            <div>
              <PhoneRoundButton red onButtonClick={rejectCall} />
              {t('phoneCall.hangUp')}
            </div>
          </IncomingButtons>
        )}
      </ButtonsCont>
    </PhoneCont>
  );
};
const PhoneCont = styled.div`
  border-radius: 8px;
  max-width: 300px;
  border: 2px solid ${lightGreen};
  background-color: #fff;
  padding: 20px 20px 10px;
`;
const ContactCont = styled.div`
  display: flex;

  /* div{
        border: 1px solid;
    } */
`;
const IncomingCallAnimation = keyframes`
    0%{ outline: none; }
    29%{ outline: none; }
    30%{ outline: 2px solid ${lightGreen}; }
    100%{ outline: 2px solid ${lightGreen}; }
`;
const BeforeAnimation = keyframes`
    0%{ opacity: 0; }
    49%{ opacity: 0; }
    50%{ opacity: 1; }
    100%{ opacity: 1; }
`;
const AfterAnimation = keyframes`
    0%{ opacity: 0; }
    74%{ opacity: 0; }
    75%{ opacity: 1; }
    100%{ opacity: 1; }
`;
const ContactIMG = styled.div<IContactIMG>`
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  border-radius: 50%;
  background-color: ${contactGreen};
  position: relative;
  animation: ${props =>
      props.isIncoming && !props.isAnswered && IncomingCallAnimation}
    2s infinite;

  &:before,
  &:after {
    content: '';
    animation: ${BeforeAnimation} 2s infinite;
    display: ${props => (!props.isIncoming || props.isAnswered) && 'none'};
    position: absolute;
    inset: 0;
    border-radius: 50%;
    transform: scale(1.3);
    background: conic-gradient(
      ${lightGreen} 0deg 45deg,
      #0000 45deg 135deg,
      ${lightGreen} 135deg 225deg,
      #0000 225deg 315deg,
      ${lightGreen} 0
    );
    -webkit-mask: radial-gradient(farthest-side, transparent 93%, red 98%);
    mask: radial-gradient(farthest-side, transparent 93%, red 98%);
  }
  &:after {
    animation: ${AfterAnimation} 2s infinite;
    transform: scale(1.5);
    -webkit-mask: radial-gradient(farthest-side, transparent 93%, red 98%);
    mask: radial-gradient(farthest-side, transparent 93%, red 98%);
  }
`;
const ContactInfo = styled.div`
  margin-left: 20px;

  > * {
    &:last-child {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${primary};
    }
  }
`;
const Name = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
`;
const Num = styled.div`
  color: black;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 25px;
`;
const ButtonsCont = styled.div`
  margin-top: 10px;
`;
const IncomingButtons = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 0.9rem;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

interface IContactIMG {
  isIncoming: boolean;
  isAnswered: boolean;
}
export default PhoneCall;
