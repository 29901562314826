import { darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IFormHeaderProps {
  stages: {
    label: string;
    isSelected: boolean;
  }[];
}
const FormHeader: FC<IFormHeaderProps> = ({ stages }) => {
  return (
    <Cont>
      {stages.map((stage, index) => {
        return (
          <HeaderLabel key={index} isSelected={stage.isSelected}>
            <IconCont isSelected={stage.isSelected}>&#10004;</IconCont>
            <span>{stage.label}</span>
          </HeaderLabel>
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background-color: ${lightBackgroundBlue};
`;
const HeaderLabel = styled.div<IHeaderLabel>`
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: ${props => !props.isSelected && darkBorder};
`;
const IconCont = styled.span<IIconCont>`
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 17px;
  font-size: 1rem;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: ${props=>props.isSelected && 'white'};
  border: 2px solid ${props=> props.isSelected && primary};
  background-color: ${props=>props.isSelected && primary};
  
`;
interface IHeaderLabel {
  isSelected: boolean;
}
interface IIconCont {
  isSelected: boolean;
}
export default FormHeader;
