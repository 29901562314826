export const ACTION_SCENARIOS_FAIL = "ACTION_SCENARIOS_FAIL"

export const GET_SCENARIOS = "GET_SCENARIOS"
export const GET_SCENARIOS_SUCCESS = "GET_SCENARIOS_SUCCESS"

export const GET_SCENARIO = "GET_SCENARIO"
export const GET_SCENARIO_SUCCESS = "GET_SCENARIO_SUCCESS"

export const POST_ADD_SCENARIO = "POST_ADD_SCENARIO"
export const POST_ADD_SCENARIO_SUCCESS = "POST_ADD_SCENARIO_SUCCESS"

export const PUT_RENAME_SCENARIO = "PUT_RENAME_SCENARIO"
export const PUT_RENAME_SCENARIO_SUCCESS = "PUT_RENAME_SCENARIO_SUCCESS"

export const DELETE_SCENARIO = "DELETE_SCENARIO"
export const DELETE_SCENARIO_SUCCESS = "DELETE_SCENARIO_SUCCESS"

export const PUT_PUBLISH_SCENARIO = "PUT_PUBLISH_SCENARIO"
export const PUT_PUBLISH_SCENARIO_SUCCESS = "PUT_PUBLISH_SCENARIO_SUCCESS"

export const PUT_SAVE_AS_DRAFT_SCENARIO = "PUT_SAVE_AS_DRAFT_SCENARIO"
export const PUT_SAVE_AS_DRAFT_SCENARIO_SUCCESS = "PUT_SAVE_AS_DRAFT_SCENARIO_SUCCESS"

export const PUT_UPDATE_SCENARIO = "PUT_UPDATE_SCENARIO"
export const PUT_UPDATE_SCENARIO_SUCCESS = "PUT_UPDATE_SCENARIO_SUCCESS"

export const PUT_UPDATE_FLOW_SCENARIO = "PUT_UPDATE_FLOW_SCENARIO"
export const PUT_UPDATE_FLOW_SCENARIO_SUCCESS = "PUT_UPDATE_FLOW_SCENARIO_SUCCESS"

export const CLEAR_SCENARIO_STORE = 'CLEAR_SCENARIO_STORE'

export const GET_FUNCTIONS = 'GET_FUNCTIONS'
export const GET_FUNCTIONS_SUCCESS = 'GET_FUNCTIONS_SUCCESS'

export const GET_INTENTS = 'GET_INTENTS'
export const GET_INTENTS_SUCCESS = 'GET_INTENTS_SUCCESS'