import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

function NodeModal({ Icon, data, toggle, isOpen, children }) {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader className="node-modal-header">
            <div className="node-modal-title-content">
              {Icon && <Icon width={16} height={16} />} {data?.label}
            </div>
            <button
              type="button"
              className="node-modal-btn-close"
              aria-label="Close"
              onClick={() => toggle()}
            >
              <span aria-hidden="true">×</span>
            </button>
          </ModalHeader>
          <PerfectScrollbar
            style={{ maxHeight: '70vh', minHeight: '15vh', paddingRight: 8 }}
          >
            <ModalBody>{children}</ModalBody>
          </PerfectScrollbar>
        </div>
      </Modal>
    </React.Fragment>
  );
}

NodeModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  Icon: PropTypes.any,
  children: PropTypes.any,
};

export default NodeModal;
