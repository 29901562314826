import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
//@ts-ignore
import MetaTags from 'react-meta-tags';
import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import FlowV2 from 'components/flowV2';
import DatabasesTemplate from 'components/databases/template/databasesTemplate';
import { useTranslation } from 'react-i18next';
import CreateDatabaseTemplate from 'components/databases/template/createDatabaseTemplate';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { getDatabases, getRowsByDatabaseId } from 'store/databases/asyncActions';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'library/hooks/useAppSelector';
import DatabaseData from 'components/databases/template/databaseDataTemplate';
import UploadDataTemplate from 'components/databases/template/databaseUploadData';
import ModifyColumnsForm from 'components/databases/template/modifyColumns';
import ModifyRowForm from 'components/databases/template/modifyRow';
import { IDatabaseRow } from 'library/interfaces/databasesInterfaces';
import { clearRows } from 'store/databases/reducer';

const DatabaseDataPage = () => {
  const { databaseId } = useParams<{ databaseId: string }>();

  const { t } = useTranslation('databases')
  const dispatch = useAppDispatch()
  const [showUploadDataForm, setShowUploadDataForm] = useState(false)
  const [showModifyColumnsForm, setShowModifyColumnsForm] = useState(false)
  const [selectedRow, setSelectedRow] = useState<IDatabaseRow | null>(null)
  const [rowIndex, setRowIndex] = useState(0)
  const { databases, selectedDatabase } = useAppSelector(store => store.databases)

  const currentDb = useMemo(() => {
    return databases.find(db => db.id === databaseId)
  }, [databases, databaseId])

  const handleSelectRow = (row: IDatabaseRow | null, index: number) => {
    setSelectedRow(row)
    setRowIndex(index)
  }

  useEffect(() => {
    if (databases.length === 0) {
      dispatch(getDatabases())
    }
  }, [])

  useEffect(() => {
    if (!currentDb) return
    dispatch(getRowsByDatabaseId({
      dbId: currentDb.id,
      dbIndex: currentDb.indexList[0]?.key || '',
      lastKey: selectedDatabase.lastKey,
    }))
  }, [currentDb])

  useEffect(() => {
    return () => {
      dispatch(clearRows())
    }
  }, [])
  return (
    <Cont className="page-content">
      <MetaTags>
        <title>{t('databaseData.metaTag')} | SmartBot</title>
      </MetaTags>
      <div>
        <PageTitle>{t('databaseData.pageTitle')}&nbsp;&nbsp;{currentDb?.name}</PageTitle>
      </div>
      <DatabaseData
        currentDb={currentDb}
        setSelectedRow={handleSelectRow}
        selectedDatabase={selectedDatabase}
        setShowUploadDataForm={setShowUploadDataForm}
        setShowModifyColumnsForm={setShowModifyColumnsForm}
      />
      {showUploadDataForm && <UploadDataTemplate currentDb={currentDb} setShowUploadDataForm={setShowUploadDataForm} />}
      {showModifyColumnsForm && <ModifyColumnsForm currentDb={currentDb} setShowModifyColumnsForm={setShowModifyColumnsForm} />}
      {selectedRow && <ModifyRowForm rowIndex={rowIndex} row={selectedRow} currentDb={currentDb} setSelectedRow={setSelectedRow} />}
    </Cont>
  );
};
const Cont = styled.div`
  background-color: #eff5f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export default DatabaseDataPage;