import React from 'react';
import NodeModal from "../common/NodeModal";
import PropTypes from "prop-types";

function Modal({Icon, data, toggle, isOpen}) {
  return (
    <NodeModal Icon={Icon} data={data} isOpen={isOpen} toggle={toggle}>
      {data.label}
      <h5>DATA</h5>
      <pre>
      {JSON.stringify(data, null, 2)}
      </pre>
    </NodeModal>
  );
}

Modal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  Icon: PropTypes.any,
};

export default Modal;