import {
  getAgentsInQueue,
  getReportData,
  updateAgentsInQueue,
} from 'helpers/fakebackend_helper';
import { IReportData } from 'library/interfaces/crdInterfaces';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IError } from 'store/reports/reducer';
import {
  getCDRDataUsersAndQueuesSuccess,
  getPBXAgentsInQueueSuccess,
  updateAgentsInQueueFail,
  updateAgentsInQueueSuccess,
} from './actions';
import {
  GET_CDR_USERS_AND_QUEUES,
  GET_PBX_AGENTS_IN_QUEUE,
  IGetPBXAgentsInQueue,
  IUpdateAgentsInQueue,
  UPDATE_AGENTS_IN_QUEUE,
} from './actionTypes';
import { IAgentsInQueue } from './reducer';

function* onGetCDRDataUsersAndQueues() {
  try {
    const { users, queues }: IReportData = yield call(getReportData);
    // reverse queues to show the most recent first
    const reverseQueues = queues.reverse();
    yield put(getCDRDataUsersAndQueuesSuccess(users, reverseQueues));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting users and queues from CDR!',
      details: `${e}`,
    };
    yield put(updateAgentsInQueueFail(error));
  }
}

function* onGetPBXAgentsInQueue({
  payload: { queueId },
}: IGetPBXAgentsInQueue) {
  try {
    if (queueId === '') throw new Error('A queue should be selected');
    const response: IAgentsInQueue = yield call(getAgentsInQueue, queueId);
    yield put(getPBXAgentsInQueueSuccess(response));
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong getting agents in queue!',
      details: `${e}`,
    };
    yield put(updateAgentsInQueueFail(error));
  }
}

function* onUpdateAgentsInQueue({
  payload: { queueId, agents },
}: IUpdateAgentsInQueue) {
  try {
    if (queueId === '') throw new Error('A queue should be selected');

    yield call(updateAgentsInQueue, queueId, agents);
    yield put(updateAgentsInQueueSuccess());
  } catch (e) {
    const error: IError = {
      message: 'Something was wrong updating agents in queue!',
      details: `${e}`,
    };
    yield put(updateAgentsInQueueFail(error));
  }
}

function* ConfigurationSaga() {
  yield takeEvery(GET_CDR_USERS_AND_QUEUES, onGetCDRDataUsersAndQueues);
  yield takeEvery(GET_PBX_AGENTS_IN_QUEUE, onGetPBXAgentsInQueue);
  yield takeEvery(UPDATE_AGENTS_IN_QUEUE, onUpdateAgentsInQueue);
}
export default ConfigurationSaga;
