import { ICdr, IQueue, IReportData } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Container from '../atoms/container';
import PageTitle from '../atoms/pageTitle';
import QueueSelectLiveBoard from '../organisms/queueSelectLiveBoard';
import RightBar from './rightBarTemplate';
import { getReportData } from 'store/actions';
import {
  subscribeQueuedCallsData,
} from 'helpers/appSyncLiveBoard';
import { ILiveQueuedCalls } from 'library/interfaces/liveQueuedCallsInterfaces';
import { queuedCallsService, queuesChangeIdValueToUidValue } from 'library/services/liveQueuedCallsServices';
import QueuedCallsResume from '../organisms/queuedCallsResume';

interface IQueuedCallsTemplateProps {}
const QueuedCallsTemplate: FC<IQueuedCallsTemplateProps> = () => {
  const dispatch = useDispatch();
  const [live, setLive] = useState<ILiveQueuedCalls[]>([]);
  
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const reportData = useSelector<IStore, IReportData>(
    state => state.cdr.reportData,
  );

  const dataToRender = queuedCallsService(reportData, live);

  const queuedCallsQueues = queuesChangeIdValueToUidValue(reportData.queues, live)

  useEffect(() => {
    if (reportData.queues.length === 0) dispatch(getReportData());
  }, []);

  useEffect(() => {
    if (!owner) return;
    const subscription = subscribeQueuedCallsData(
      owner.toLowerCase(),
      (data: ILiveQueuedCalls[]) => setLive(data),
    );
    return () => subscription.unsubscribe();
  }, [owner]);
  return (
    <>
      <PageTitle>Queued Calls</PageTitle>

      <FlexCont>
        <Container padding="20px">
          <QueueSelectLiveBoard
            mainPath="/live-board/queued-calls"
            redirectPath="/live-board/queued-calls/detailed"
            live
            queues={queuedCallsQueues}
          />
          <Container padding="30px 30px 10px" mediumBackground>
            {dataToRender.map((queue, index) => {
              return <QueuedCallsResume key={index} data={queue} />;
            })}
          </Container>
        </Container>
        <RightBar />
      </FlexCont>
    </>
  );
};
const FlexCont = styled.div`
  display: flex;
  min-height: 75vh;
`;
export default QueuedCallsTemplate;
