import DownloadReportButton from 'components/outbound/atoms/downloadReportButton';
import { lightBackgroundBlue, primary } from 'library/colors';
import { MONTH, YEARS, localDate_formatISO } from 'library/services/dateServices';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface ISurveyReportProps { }
const SurveyReport: FC<ISurveyReportProps> = ({ }) => {
  const [date, setDate] = useState({
    year: localDate_formatISO().substring(0, 4),
    month: localDate_formatISO().substring(5, 7),
  })
  return (
    <Cont>
      <BlueCont>
        <Label2 margin='15px 20px 0 0'>Reporte de encuesta: </Label2>
        <SCont>
          <SelectCont maxWidth='120px'>
            <Label margin="0">Mes del reporte: </Label>
            <Select
              value={date.month}
              onChange={e => setDate(prev => ({ ...prev, month: e.target.value }))}
            >
              {MONTH.map((month, index) => {
                return (
                  <option key={index} value={month.value}>
                    {month.label}
                  </option>
                );
              })}
            </Select>
          </SelectCont>
          <SelectCont maxWidth='70px'>
            <Label margin="0">Año: </Label>
            <Select
              value={date.year}
              onChange={e => setDate(prev => ({ ...prev, year: e.target.value }))}
            >
              {YEARS.map((year, index) => {
                return (
                  <option key={index} value={year}>
                    {year}
                  </option>
                );
              })}
            </Select>
          </SelectCont>
        </SCont>
        <DownloadReportButton
          reportType={15}
          params={`start=${date.year}-${date.month}&end=${date.year}-${date.month}`}
        >
          Reporte
        </DownloadReportButton>
      </BlueCont>
    </Cont >
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  border-radius: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${lightBackgroundBlue};

`;
const SCont = styled.div`
  flex-grow: 1;
  display: flex;
`;
const SelectCont = styled.div<ISelectCont>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-width: ${props => props.maxWidth ? props.maxWidth : '200px'};
`;
const Label = styled.label<ILabel>`
  font-size: .7rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
const Select = styled.select`
  flex-grow: 1;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const Label2 = styled.label<ILabel>`
display: block;
font-size: 1rem;
font-weight: 500;
margin: ${props => props.margin};
`;
interface ISelectCont {
  maxWidth?: string
}
interface ILabel {
  margin?: string;
}
export default SurveyReport;