import React, { FC } from 'react';
import styled from 'styled-components';

export interface IAgentToSelect {
    exten: string;
    name: string;
    isSelected: boolean;
}
interface ISelectableAgentProps {
  agent: IAgentToSelect
  index: number
  handleSetAgents: Function;
}
const SelectableAgent: FC<ISelectableAgentProps> = ({ agent, index, handleSetAgents }) => {

  const handleSelect = (index: number, isSelected: boolean) =>{
    
    handleSetAgents(index, isSelected)
  }
  return (
    <AgentCont>
      <input type="checkbox" id={agent.exten} checked={agent.isSelected} onChange={e=>handleSelect(index, !agent.isSelected)}/>
      <Exten htmlFor={agent.exten} >{agent.exten}</Exten>
      <Name htmlFor={agent.exten}>{agent.name.substring(0, 28)}</Name>
    </AgentCont>
  );
};

const AgentCont = styled.div`
  padding: 5px 0px 5px 10px;
  display: flex;
  align-items: center;
`;
const Exten = styled.label`
  font-weight: 300;
  width: 60px;
  text-align: center;
  margin: 0;
`;
const Name = styled.label`
  font-weight: 500;
  margin: 0;
  font-size: 0.9rem;
`;
export default SelectableAgent;
