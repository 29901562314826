import React, { FC } from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { ISelectedChatCustomer } from 'store/chatSelected/reducer';
import IStore from 'library/interfaces/storeInterface';

import { darkBorder, primary } from 'library/colors';
import { useTranslation } from 'react-i18next';

const RightBarInfo = () => {
  const { t } = useTranslation('workspace');
  const e64_data = useSelector<IStore, Object[]>(store => store.phone.e64_data);

  const dataToRender: { column: string; value: string }[] = [];
  e64_data.forEach(data => {
    if (!(data instanceof Object)) return;
    for (const key in data) {
      /* @ts-ignore */
      dataToRender.push({ column: key, value: data[key] });
    }
  });

  return (
    <Cont>
      {dataToRender.map((data, index) => {
        return (
          <div key={index}>
            <Label>{data.column}:</Label>
            <Data>{data.value}</Data>
          </div>
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  height: 100%;
  width: 200px;
  padding: 20px 10px;
  font-size: 0.8rem;
  transform: translate(0px);
  position: absolute;
  left: 0;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const Label = styled.div``;
const Data = styled.div`
  color: black;
  font-size: 1rem;
  min-height: 25px;
  margin-bottom: 20px;
`;
export default RightBarInfo;
