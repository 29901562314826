import React, { FC } from 'react';
import styled from 'styled-components';

import Container from '../atoms/container';
import SubTitle from '../atoms/subTitle';
import TimeCounterAndMinMax from '../atoms/timeCountMinMax';
import ChangePrev from '../atoms/changePrev';
import { useTranslation } from 'react-i18next';

interface IQueueTime {
  waiting_average: string;
  waiting_min_time: string; 
  waiting_max_time: string; 
  waiting_change: string;
}
const QueueTime: FC<IQueueTime> = ({ waiting_average, waiting_min_time, waiting_max_time, waiting_change }) => {
  const {t} = useTranslation('summarize')
  return (
    <Container padding="20px">
      <SubTitle sub={t('last24hs')}>{t('averageQueueTime')}</SubTitle>
      <TimeCounterAndMinMax time_average={waiting_average} time_min_time={waiting_min_time} time_max_time={waiting_max_time}/>
      <ChangePrev change={waiting_change} prev={0} />
    </Container>
  );
};
export default QueueTime;
