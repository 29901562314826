import React, { FC } from 'react';
import styled from 'styled-components';

import {
  darkText,
  lightBackground,
  lightBackgroundBlue,
  popUpBackground,
  primary,
} from 'library/colors';
import { ITagSummarized } from 'library/interfaces/campaignInterfaces';

interface ICampaignTagsCountProps {
  campaignName: string;
  tagsSummarized: ITagSummarized[] | undefined;
  setShowCampaignTagsCount: (show: boolean) => void;
}
const CampaignTagsCount: FC<ICampaignTagsCountProps> = ({
  tagsSummarized,
  campaignName,
  setShowCampaignTagsCount,
}) => {
  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton'
    ) {
      return setShowCampaignTagsCount(false);
    }
  };
  console.log(tagsSummarized);
  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <Cont>
        <Exit id="exitButton">X</Exit>
        <H1>
          <Span>{campaignName}</Span>
        </H1>

        <Label>Etiquetas: </Label>
        <TagsCont>
          {tagsSummarized &&
            tagsSummarized.map((tag, index) => {
              return (
                <Tag key={index}>
                  {tag.tag}:  <b>{tag.count}</b>
                </Tag>
              );
            })}
        </TagsCont>
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const Cont = styled.div`
  position: fixed;
  top: 120px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 20px 10px;
  border-radius: 20px;
  background-color: ${lightBackgroundBlue};
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const H1 = styled.h1`
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  margin: 0px auto 10px;
  color: ${darkText};
`;
const Span = styled.span`
  padding: 4px 25px;
  border-radius: 30px;
  text-transform: uppercase;
  background-color: ${lightBackground};
`;
const Label = styled.div`
  margin: 20px auto 10px;
  padding: 0 40px;
  width: 90%;
`;
const TagsCont = styled.div`
  margin: 0 auto 10px;
  width: 90%;
  min-height: 200px;
  max-height: 400px;
  padding: 10px 40px;
  border-radius: 10px;
  background-color: ${lightBackground};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
const Tag = styled.div`
  margin-bottom: 10px;

  & > b {
    margin-left: 10px;
  }
`;
export default CampaignTagsCount;
