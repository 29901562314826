import React from 'react';
import { Handle, HandleProps, Position } from 'reactflow';
import styled from 'styled-components';

import { CustomNodeTypes, IHandleData, INodeData } from '../utils/nodeTypes';
import flowIcons from '../utils/flowIcons';

interface IStartScenaryProps {
  type: CustomNodeTypes;
  data: INodeData;
}
const CircleNode = ({ data, type }: IStartScenaryProps) => {
  const Icon = flowIcons[type];
  const { backgroundColor, sources, target } = data;
  return (
    <Cont>
      <PermanentBorder backgroundcolor={backgroundColor} />
      <InternalCircle>
        <InternalGreenCircle backgroundcolor={backgroundColor}>
          <Icon />
        </InternalGreenCircle>
        <Label backgroundcolor={backgroundColor}>{data.title}</Label>
      </InternalCircle>
      {sources?.map((source, index) => {
        return (
          <CustomSouce
            key={index}
            color={source.color}
            type={source.type}
            id={source.id}
            position={source.position}
          />
        );
      })}
      {target && (
        <CustomTarget
          type={target.type}
          id={target.id}
          position={target.position}
          backgroundcolor={backgroundColor}
        >
          <div></div>
        </CustomTarget>
      )}
    </Cont>
  );
};
const Cont = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: relative;
  color: black;
  padding: 2px;
  background-color: white;
`;
const Label = styled.div<IBackground>`
  position: absolute;
  width: 80%;
  top: 55%;
  left: 50%;
  transform: translate(-50%);
  color: ${props => props.backgroundcolor};

  font-size: 0.7rem;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
`;
const InternalCircle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 2px;
  background-image: linear-gradient(90deg, white 50%, #dadada);
`;
const InternalGreenCircle = styled.div<IBackground>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 8px;
  background-image: linear-gradient(
    ${props => props.backgroundcolor} 45%,
    transparent 45%
  );

  & > svg {
    display: block;
    margin: 0 auto;
    width: 25px;
    height: 25px;
    color: #fff;
  }
`;

const PermanentBorder = styled.div<IBackground>`
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -1;
  border-radius: 50%;
  background-image: linear-gradient(
    ${props => props.backgroundcolor} 46%,
    transparent 46% 75%,
    ${props => props.backgroundcolor} 75%
  );
`;

const CustomSouce = styled(Handle)<ICustomHandle>`
  border: none;
  width: 8px;
  height: 8px;
  background-color: ${props => props.color};
  transform: translate(-2px, 8px);
`;
const CustomTarget = styled(Handle)<IBackground>`
  border: none;
  width: 12px;
  height: 12px;
  background: transparent;
  /* background-image: radial-gradient(blue 6px, transparent 6px); */
  transform: translate(1px, 6px);

  & > div {
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-54%, -55%);
    border-radius: 50%;
    background-color: #7c909c;
  }
`;
interface ICustomHandle {
  color?: string;
}

interface IBackground {
  backgroundcolor: string;
}
export default CircleNode;
