import { uploadfile } from 'helpers/fakebackend_helper';
import { darkBorder, primary } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { ISendMessage } from 'library/interfaces/conversationInterfaces';
import React, { FC, useEffect, useRef, useState } from 'react';
import { postSendMessageAction } from 'store/actions';
import styled from 'styled-components';

interface IAudioRecordingProps {
  setShowAudioRecording: (show: boolean) => void
}
const AudioRecording: FC<IAudioRecordingProps> = ({ setShowAudioRecording }) => {
  const dispatch = useAppDispatch()
  const { display_name } = useAppSelector(store => store.authUserData)
  const selectedSession = useAppSelector(store => store.conversations.selectedSession)

  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);

  const [url, setUrl] = useState('')

  const startRecording = async () => {
    if (!selectedSession) return
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);

      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          setAudioChunks([...audioChunks, e.data]);
        }
      };
      recorder.start();
      setMediaRecorder(recorder);
    } catch (error) {
      console.error('Error al acceder al micrófono:', error);
    }
  };
  let audioGraphAnimation: number;

  const stopRecording = () => {

    if (mediaRecorder) {
      mediaRecorder.stop();
      cancelAnimationFrame(audioGraphAnimation)
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    }
  };

  const sendAudio = async (audioChunksArray: Blob[]) => {
    if (audioChunksArray.length === 0 || !selectedSession) return
    const audioBlob = new Blob(audioChunksArray, { type: 'audio/mp3' });

    const formData = new FormData();
    formData.append('file', audioBlob, 'recording.mp3');

    const res = await uploadfile(formData)

    if (!res?.url) return
    const audioMsg = {
      type: "audio",
      url: res.url
    }

    const message: ISendMessage = {
      content: JSON.stringify(audioMsg),
      created_at: new Date().toISOString(),
      source_name: display_name,
      source_type: 'agent'
    }
    dispatch(postSendMessageAction(selectedSession, message));
    setShowAudioRecording(false)
  }

  const handleSendAudioButtonClick = async () => {
    if (!selectedSession) return
    let audioBlob: Blob[] = audioChunks

    if (mediaRecorder) {
      if (mediaRecorder.state === 'inactive') {
        sendAudio(audioBlob)
      }
      mediaRecorder.ondataavailable = async (e) => {
        if (e.data.size > 0) {

          audioBlob = await [...audioBlob, e.data];
        }
        sendAudio(audioBlob)
      };
    }
    stopRecording()
  }

  useEffect(() => {
    if (audioChunks.length === 0) return
    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(audioBlob);
    setUrl(audioUrl)
  }, [audioChunks])

  useEffect(() => {
    if (!selectedSession) return
    startRecording()
    return () => stopRecording()
  }, [])

  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [audioStream]);


  // codigo del grafico
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const audioCtx = new AudioContext();

  const localAnalizer = audioCtx.createAnalyser()
  localAnalizer.minDecibels = -90;
  localAnalizer.maxDecibels = -10;
  localAnalizer.smoothingTimeConstant = 0.5;

  let bars = 100
  const localAudioBars = new Array(bars).fill(0);

  useEffect(() => {
    if (!canvasRef.current || !audioStream || mediaRecorder?.state === 'inactive') return;
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const localSource = audioCtx.createMediaStreamSource(audioStream)

    localSource.connect(localAnalizer)
    if (!canvasCtx) return
    localAnalizer.fftSize = 2048;
    let localBufferLengthAlt = localAnalizer.frequencyBinCount;
    let localDataArrayAlt = new Uint8Array(localBufferLengthAlt);
    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    const visualize = () => {
      drawRemote();
      audioGraphAnimation = requestAnimationFrame(visualize);
    }

    const drawRemote = () => {
      localAnalizer.getByteFrequencyData(localDataArrayAlt)
      canvasCtx.fillStyle = 'rgb(255, 255, 255)';
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      let barWidth = 3;
      let localBarHeight;
      let x = 0;

      if (localAudioBars.length < bars) {
        localAudioBars.push(localDataArrayAlt[0])
      } else {
        localAudioBars.shift();
        localAudioBars.push(localDataArrayAlt[0]);
      }
      for (let i = 0; i < bars; i++) {
        localBarHeight = localAudioBars[i] / 2;

        if (i % 2 === 0) {
          canvasCtx.fillStyle = 'rgb(' + (localBarHeight + 10) + ',201,57)';
          canvasCtx.fillRect(
            x,
            (HEIGHT - (localBarHeight + 2)) / 2,
            barWidth,
            localBarHeight + 2,
          );
        } else {
          canvasCtx.fillStyle = `transparent`;
          canvasCtx.fillRect(
            x,
            (HEIGHT - (localBarHeight + 2)) / 2,
            barWidth,
            localBarHeight + 2,
          );
        }
        x += barWidth;
      }
    };

    visualize();

    return () => {
      cancelAnimationFrame(audioGraphAnimation);
    }
  }, [audioStream, mediaRecorder])

  return (
    <Cont>
      <Flex>
        <Button onClick={() => setShowAudioRecording(false)}>
          <i className='bx bxs-trash-alt'></i>
        </Button>
        <CanvasCont>
          {mediaRecorder?.state === 'recording' &&
            <Canvas ref={canvasRef} width={217} height={70}></Canvas>
          }
          {mediaRecorder?.state === 'inactive' && <Audio controls src={url} ></Audio>}
        </CanvasCont>
        <PauseButton onClick={() => mediaRecorder?.state === 'recording' ? stopRecording() : startRecording()}>
          <i className={mediaRecorder?.state === 'recording' ? 'bx bx-pause' : 'bx bx-play'} ></i>
        </PauseButton>
        <SendButton onClick={() => handleSendAudioButtonClick()}>
          <i className='mdi mdi-send'></i>
        </SendButton>
      </Flex>
    </Cont>
  );
};
const Cont = styled.div`
   position: absolute;
  bottom: calc(100% + 10px);
  right: -150px;
  width: 350px;
  height: 70px;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  background-color: #FFF;
  overflow: hidden;
`;
const Flex = styled.div`
  display: Flex;
  align-items: center;
  height: 100%;
`;
const Button = styled.button`
  border: none;
  font-size: 1.3rem;
  margin-left: 5px;
  border-radius: 50px;
  padding: 0;
  line-height: 0;
  width: 35px;
  height: 35px;
`;
const SendButton = styled(Button)`
  color: #fff;
  padding-top: 2px;
  padding-left: 3px;
  background-color: ${primary};
`;
const PauseButton = styled(Button)`
  font-size: 2rem;
`;
const CanvasCont = styled.div`
  margin-left: 5px;
  width: 217px;
`;
const Canvas = styled.canvas`
  background-color: transparent;
  display: block;
`;
const Audio = styled.audio`
  width: 217px;
  margin: 0;

`;
export default AudioRecording;