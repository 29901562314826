import React from 'react';
import { Handle } from 'react-flow-renderer';
import PropTypes from 'prop-types';

import { COLORS } from './constants';

function LeftHandle({
  id,
  text = '',
  isConnectable,
  bg = 'blue',
  onConnect = () => {},
}) {
  return (
    <div className="node-left-handle">
      <Handle
        id={id}
        type="target"
        position="left"
        style={{ backgroundColor: COLORS[bg] }}
        onConnect={onConnect}
        isConnectable={isConnectable}
      />
      <span
        className="node-handle-text"
        style={{ backgroundColor: text ? COLORS[bg] : 'transparent' }}
      >
        {text}
      </span>
    </div>
  );
}

LeftHandle.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  isConnectable: PropTypes.bool,
  bg: PropTypes.string,
  onConnect: PropTypes.func,
};

export default LeftHandle;
