import React, { FC } from 'react';
import styled from 'styled-components';
import bg from './fondo.png'

interface IWaQuickReplyMessageEditorProps {
  content: {
    header: string
    text: string
    caption: string
    url: string
    filename: string
  }
  buttons: { title: string, postbackText: string }[]
}
const WaQuickReplyMessageEditor = ({ buttons, content: { header, filename, caption, text, url } }: IWaQuickReplyMessageEditorProps) => {
  return (
    <Cont $bgImg={bg}>
      <TextCont>
        <TailCont>
          <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px" enableBackground="new 0 0 8 13"><title>tail-in</title><path opacity="0.13" fill="#0000000" d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"></path><path fill="currentColor" d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"></path></svg>
        </TailCont>
        <Header >
          {header}
        </Header>
        <Text >
          {text}
        </Text>

        <Footer>
          {caption}
        </Footer>
      </TextCont>

      {buttons.map((item, index) => {
        return (
          <ButtonCont key={index}>
            <Text >
              {item.title}
            </Text>
          </ButtonCont>
        )
      })}

    </Cont>
  );
};
const Cont = styled.div<IContProp>`
  border: 1px solid;
  width: 100%;
  /* height: 350px; */
  color: #fff;
  padding: 20px 20px;
  background-image: url(${props => props.$bgImg});
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
`;
const TextCont = styled.div`
width: 336px;
width: 90%;
min-height: 40px;
padding: 6px 7px 8px 9px;
  background-color: #202c33;
  position: relative;

  border-radius: 7.5px;
  border-top-left-radius: 0;
  font-size: 15px;
`;
const ButtonCont = styled(TextCont)`
  border-radius: 7.5px;
  text-align: center;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  
  >div{
    color: #53bdeb;

  }
`;
const Text = styled.div`
  border: none;
  outline: none;
  /* border: 1px solid; */
  flex-grow: 1;
  margin-bottom: 5px;
  font-size: 14px;
  /* font-weight: 500; */
  color: #e9edef;
  
`;
const Header = styled.div`
  border: none;
  outline: none;
  /* border: 1px solid; */
  flex-grow: 1;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
  color: #e9edef;
`;

const Footer = styled.div`
  border: none;
  outline: none;
  /* border: 1px solid; */
  flex-grow: 1;
  font-size: 13px;
  /* font-weight: 100; */
  color: #667781;
`;
const TailCont = styled.span`
  position: absolute;
  top: 0px;
  left: 0;
  display: flex;
  transform: translate(-100%);
  color: #202c33;
`;
interface IContProp {
  $bgImg: string
}
export default WaQuickReplyMessageEditor;