import React, { FC } from 'react';
import styled from 'styled-components';
import AgentsInQueueConfig from './configs/agentsInQueue';

interface IConfigurationTemplateProps {}
const ConfigurationTemplate: FC<IConfigurationTemplateProps> = ({  }) => {
  return (
    <Cont>
      <AgentsInQueueConfig />
    </Cont>
  );
};
const Cont = styled.div`
  flex-grow: 1;
`;
export default ConfigurationTemplate;