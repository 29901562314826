import * as React from 'react';

export const NotepadEmpty = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M107.948 120.791a11.454 11.454 0 0 1-8.129 3.365H14.304c-6.351 0-11.494-5.143-11.494-11.493V27.147c0-6.338 5.143-11.494 11.494-11.494h14.498M85.176 15.786H99.68c6.351 0 11.494 5.149 11.494 11.487v18.452"
      fill="none"
      stroke="#7c9904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={5.819552}
    />
    <path
      d="m111.743 45.516 4.049 11.589 13.568 38.82c1.664 4.776-2.12 8.64-8.47 8.64H35.374c-6.35 0-12.847-3.864-14.517-8.64L3.24 45.516M28.878 15.786H85.39M28.878 23.377V8.227M85.176 23.377V8.227M111.743 45.516H20.781"
      fill="none"
      stroke="#7c9904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={5.819552}
    />
  </svg>
);
