import React from 'react';
import PropTypes from 'prop-types';

import { CardHeader } from 'reactstrap';

const Transcription = ({ transcription, hideTranscription }) => {
  const handleExitClick = event => {
    if (event.target.id === 'popupBackground' || event.target.id === 'exit') {
      hideTranscription('');
    }
  };
  return (
    <div
      id="popupBackground"
      className="popup-background"
      onClick={e => handleExitClick(e)}
    >
      <div className="popup-container">
        <CardHeader
          tag="h4"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          Transcripción
          <i
            id="exit"
            className="bx bx-x me-1 mt-1"
            style={{ cursor: 'pointer' }}
            onClick={e => handleExitClick(e)}
          ></i>
        </CardHeader>
        <div style={{ padding: '20px 30px' }}>{transcription}</div>
      </div>
    </div>
  );
};
Transcription.propTypes = {
  transcription: PropTypes.string.isRequired,
};
export default Transcription;
