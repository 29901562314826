import { TransferCall } from 'icons';
import { darkBorder, popUpBackground, primary } from 'library/colors';
import { IUser } from 'library/interfaces/crdInterfaces';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IReassignProps {
  users: IUser[]
  setShowReassign: (value: boolean) => void
  handleShowReasignUsers: (agentId: string) => void;
}
const ReassignPopup: FC<IReassignProps> = ({ setShowReassign, users, handleShowReasignUsers }) => {

  const handleExitClick = () => {
    setShowReassign(false)
  }
  return (
    <PopUpContainer>

      <Cont>
        <Exit id="exitButton" onClick={() => handleExitClick()}>X</Exit>
        <Title>Agentes</Title>
        <UsersCont>
          {users.map((user, index) => {
            return (
              <UserC key={index}>
                <div>{user.name}</div>
                <ReAssignButton onClick={() => handleShowReasignUsers(user.auth0_id)}>
                  <div><TransferCall width={20} /></div>
                  Asignar sesión
                </ReAssignButton>
              </UserC>
            )
          })}

        </UsersCont>
        <Button onClick={() => handleExitClick()}>Salir</Button>
      </Cont>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1000;
  background-color: ${popUpBackground};
`;
const Cont = styled.div`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 500px;
  height: 550px;
  color: black;
  padding: 20px 40px 30px;
  display: grid;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;
`;
const UsersCont = styled.div`
height: 400px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${darkBorder};
  
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const UserC = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
border-radius: 10px;
padding: 10px;
margin-bottom: 10px;
  border: 1px solid ${darkBorder};

`;
const ReAssignButton = styled.div`
  display: flex;
  padding: 5px 15px;
  border-radius: 50px;
  color: #fff;
  background-color: ${darkBorder};
  cursor: pointer;
  > div {
    margin-right: 5px;
  }

  &:hover{
  background-color: ${primary};
  }
`;
const Button = styled.button`
  border-radius: 50px;
  margin: 0;
  display: inline-block;
  min-width: 30px;
  font-size: 13px;
  padding: 8px 16px;
  color: white;
  justify-self: end;
  border: 2px solid ${darkBorder};
  background-color: ${darkBorder};
  transform: translateY(10px);
`;
export default ReassignPopup;