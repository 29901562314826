import PhoneKeyboard from 'components/WorkSpace/molecules/phoneKeyboard';
import { darkBorder } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IPhoneKeyboard {
  dialedNumber: string;
  setDialedNumber: Function;
  isCall: boolean;
  onExitClick: Function;
  onCallButtonClick: Function;
  onBlindTranferButtonClick: Function;
}
const NumericFloatKeyboard: FC<IPhoneKeyboard> = ({
  isCall,
  dialedNumber,
  setDialedNumber,
  onExitClick,
  onCallButtonClick,
  onBlindTranferButtonClick,
}) => {
  return (
    <Cont>
      <PhoneKeyboard
        isCall={isCall}
        dialedNumber={dialedNumber}
        setDialedNumber={setDialedNumber}
        onExitClick={onExitClick}
        onCallButtonClick={onCallButtonClick}
        onBlindTranferButtonClick={onBlindTranferButtonClick}
      />
    </Cont>
  );
};
const Cont = styled.div`
  position: absolute;
  width: 300px;
  top: 80%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 10px;
  box-shadow: 0 3px 5px ${darkBorder}, 0 3px 10px ${darkBorder},
    0 3px 10px ${darkBorder}, 0 3px 15px ${darkBorder};
`;
export default NumericFloatKeyboard;
