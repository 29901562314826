import React from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import { CustomNodeTypes, IHandleData, INodeData } from '../utils/nodeTypes';
import { InteractiveMenu as InteractiveMenuIcon } from 'icons';
import flowIcons from '../utils/flowIcons';

interface IInteractiveMenuProps {
  type: CustomNodeTypes;
  data: INodeData;
}
const InteractiveMenu = ({ data, type }: IInteractiveMenuProps) => {
  const Icon = flowIcons[type];
  const { backgroundColor, target, sources } = data;
  return (
    <Cont backgroundcolor={backgroundColor}>
      <InternalBg>
        <Header backgroundcolor={backgroundColor}>
          <Icon />
          {data.title}
        </Header>
        <HandleCont>
          {target && (
            //this elements have position absolute
            // always render on left center
            <CustomTarget
              type={target.type}
              id={target.id}
              position={target.position}
              backgroundcolor={backgroundColor}
            >
              <div></div>
            </CustomTarget>
          )}
          <SourcesCont>
            {sources?.map((source, index) => {
              return (
                <SourceCont key={index}>
                  <SourceLabel backgroundcolor={source.color}>
                    {source.label}
                  </SourceLabel>
                  <CustomHandle
                    id={source.id}
                    position={source.position}
                    type={source.type}
                    backgroundcolor={source.color}
                  />
                </SourceCont>
              );
            })}
          </SourcesCont>
        </HandleCont>
      </InternalBg>
    </Cont>
  );
};
const Cont = styled.div<IBackground>`
  min-width: 170px;
  min-height: 70px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  padding: 2px;
  display: flex;
  flex-direction: column;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: -1;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 14px 14px 13px 13px;
    background-image: linear-gradient(
      ${props => props.backgroundcolor} 38px,
      transparent 38px calc(100% - 27px),
      #eff6f9 calc(100% - 27px)
    );
  }
  &:after {
    top: auto;
    bottom: -8px;
    left: -8px;
    width: calc(100% + 16px);
    height: 30px;
    z-index: -2;
    border-radius: 0 0 16px 16px;
    background-image: none;
    background-color: ${props => props.backgroundcolor};
  }
`;
const InternalBg = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 2px 2px 20px;
  background-image: linear-gradient(90deg, white 50%, #dadada);
`;
const Header = styled.div<IBackground>`
  width: 100%;
  height: 30px;
  padding: 0 20px 0 10px;
  border-radius: 8px 8px 0 0;
  background-color: ${props => props.backgroundcolor};
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  place-items: center;

  svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
`;
const HandleCont = styled.div`
  display: flex;
  justify-content: end;
`;
const SourcesCont = styled.div``;
const CustomTarget = styled(Handle)<IBackground>`
  border: none;
  width: 12px;
  height: 12px;
  background: transparent;
  transform: translate(-2px, 0px);

  & > div {
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -55%);
    border-radius: 50%;
    background-color: ${props => props.backgroundcolor};
  }
`;
const SourceCont = styled.div`
  position: relative;
  margin: 5px 0;
  line-height: 12px;
`;
const SourceLabel = styled.div<IBackground>`
  text-align: end;
  color: #fff;
  font-size: 0.6rem;
  margin: 0 3px 0 20px;
  padding: 0 10px;
  border-radius: 30px;
  background-color: ${props => props.backgroundcolor};
`;
//
//
//
//
const CustomHandle = styled(Handle)<IBackground>`
  border: none;
  width: 8px;
  height: 8px;
  background-color: ${props => props.backgroundcolor};
  transform: translate(2px, -50%);
`;
const CustomHandle2 = styled(CustomHandle)`
  background-color: #85ce5f;
  transform: translate(-2px, 5px);
`;
const CustomHandle3 = styled(CustomHandle)`
  background-color: #4cb3ed;
  transform: translate(-2px, 25px);
`;

const Modal = styled.div`
  background-color: #d78480;
  position: absolute;
  font-size: 0.6rem;
  width: 100px;
  text-align: right;
  padding-right: 10px;
  border-radius: 50px;
  font-weight: 700;
  left: -105px;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
`;
const Modal2 = styled(Modal)`
  background-color: #85ce5f;
`;
const Modal3 = styled(Modal)`
  background-color: #4cb3ed;
`;
interface IBackground {
  backgroundcolor: string;
}

export default InteractiveMenu;
