import { IAgentsToQueue } from 'library/interfaces/addAgentToQueueInterfaces';
import { IQueue, IUser } from 'library/interfaces/crdInterfaces';
import { IError } from 'store/reports/reducer';
import {
  GET_CDR_USERS_AND_QUEUES,
  GET_CDR_USERS_AND_QUEUES_FAIL,
  GET_CDR_USERS_AND_QUEUES_SUCCESS,
  GET_PBX_AGENTS_IN_QUEUE,
  GET_PBX_AGENTS_IN_QUEUE_FAIL,
  GET_PBX_AGENTS_IN_QUEUE_SUCCESS,
  IGetCDRDataUsersAndQueues,
  IGetCDRDataUsersAndQueuesFail,
  IGetCDRDataUsersAndQueuesSuccess,
  IGetPBXAgentsInQueue,
  IGetPBXAgentsInQueueFail,
  IGetPBXAgentsInQueueSuccess,
  IUpdateAgentsInQueue,
  IUpdateAgentsInQueueFail,
  IUpdateAgentsInQueueSuccess,
  UPDATE_AGENTS_IN_QUEUE,
  UPDATE_AGENTS_IN_QUEUE_FAIL,
  UPDATE_AGENTS_IN_QUEUE_SUCCESS,
} from './actionTypes';
import { IAgentsInQueue } from './reducer';

export const getCDRDataUsersAndQueuesAction =
  (): IGetCDRDataUsersAndQueues => ({
    type: GET_CDR_USERS_AND_QUEUES,
  });

export const getCDRDataUsersAndQueuesSuccess = (
  users: IUser[],
  queues: IQueue[],
): IGetCDRDataUsersAndQueuesSuccess => ({
  type: GET_CDR_USERS_AND_QUEUES_SUCCESS,
  payload: { users, queues },
});

export const getCDRDataUsersAndQueuesFail = (
  error: IError,
): IGetCDRDataUsersAndQueuesFail => ({
  type: GET_CDR_USERS_AND_QUEUES_FAIL,
  payload: { error },
});

export const getPBXAgentsInQueueAction = (
  queueId: string,
): IGetPBXAgentsInQueue => ({
  type: GET_PBX_AGENTS_IN_QUEUE,
  payload: { queueId },
});
export const getPBXAgentsInQueueSuccess = (
  agentsInQueue: IAgentsInQueue,
): IGetPBXAgentsInQueueSuccess => ({
  type: GET_PBX_AGENTS_IN_QUEUE_SUCCESS,
  payload: { agentsInQueue },
});
export const getPBXAgentsInQueueFail = (
  error: IError,
): IGetPBXAgentsInQueueFail => ({
  type: GET_PBX_AGENTS_IN_QUEUE_FAIL,
  payload: { error },
});

export const updateAgentsInQueueAction = (
  queueId: string,
  agents: IAgentsToQueue,
): IUpdateAgentsInQueue => ({
  type: UPDATE_AGENTS_IN_QUEUE,
  payload: { queueId, agents },
});

export const updateAgentsInQueueSuccess = (): IUpdateAgentsInQueueSuccess => ({
  type: UPDATE_AGENTS_IN_QUEUE_SUCCESS,
});

export const updateAgentsInQueueFail = (
  error: IError,
): IUpdateAgentsInQueueFail => ({
  type: UPDATE_AGENTS_IN_QUEUE_FAIL,
  payload: { error },
});
