import { primary } from 'library/colors';
import React, { FC } from 'react';
import {
  IContentData,
  IError,
  IReport,
  IReportData,
} from 'store/reports/reducer';
import styled from 'styled-components';
import Indicators from '../molecules/indicators';

interface IGraphAndDataProps {
  data: IContentData | '' | undefined;
  isLoading: boolean;
  error: IError | null;
  handleRefresh: Function;
}
const GraphAndData: FC<IGraphAndDataProps> = ({
  data,
  isLoading,
  error,
  handleRefresh,
}) => {
  let callStatus = {
    answered: 0,
    notAnswered: 0,
    abandom: 0,
    busy: 0,
    failed: 0,
    total: 0,
  };

  if (data && data.callStatus)
    callStatus = { ...callStatus, ...data.callStatus };

  const handleRefreshButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    handleRefresh();
  };
  return (
    <Cont>
      <Indicators callStatus={callStatus} />
      <Graph>
        {!isLoading && !error && <div></div>}
        {isLoading && !error && <label>Loading . . .</label>}
        {error && (
          <label>
            {error.message} &nbsp;{' '}
            <Refresh onClick={e => handleRefreshButtonClick(e)}>
              {' '}
              Refresh.
            </Refresh>
          </label>
        )}
      </Graph>
    </Cont>
  );
};
const Cont = styled.div`
  flex-grow: 1;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
`;
const Graph = styled.div`
  //border: 1px solid;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background-color: ${primary};
  }
`;
const Refresh = styled.a``;
export default GraphAndData;
