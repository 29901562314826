import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import ConfigRightBarTemplate from 'components/Configuration/templates/configRightBarTemplate';
import ConfigurationTemplate from 'components/Configuration/templates/configurationTemplate';
import { useTranslation } from 'react-i18next';

const PagesStarter = () => {
  const { t } = useTranslation('configuration');
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t('pageTitle')} | SmartBot</title>
        </MetaTags>
        <Container fluid>
          <PageTitle>{t('pageTitle')}</PageTitle>
          <FlexCont>
            <ConfigurationTemplate />
            <ConfigRightBarTemplate />
          </FlexCont>
        </Container>
      </div>
    </React.Fragment>
  );
};
const FlexCont = styled.div`
  display: flex;
  min-height: 75vh;
`;
export default PagesStarter;
