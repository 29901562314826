import React, { memo } from 'react';

import NodeLayout from '../common/NodeLayout';
import RightHandle from '../common/RightHandle';
import LeftHandle from '../common/LeftHandle';
import Modal from './Modal';
import PropTypes from 'prop-types';

const color = 'blue';

// eslint-disable-next-line react/display-name
export const InteractiveMenu = memo(({ data, isConnectable }) => (
  <NodeLayout icon="InteractiveMenu" data={data} Modal={Modal} color={color}>
    <LeftHandle
      isConnectable={isConnectable}
      onConnect={() => {}}
      id="route_in"
    />

    <RightHandle
      id="route_fail"
      isConnectable={isConnectable}
      type="source"
      text="Fail"
      bg="red"
    />

    {console.log('InteractiveMenu: ', data)}

    {data?.responseRoutes?.map(({ id, port, bg }) => {
      return (
        <RightHandle
          key={id}
          id={id}
          isConnectable={true}
          type="source"
          text={port}
          bg={bg || color}
        />
      );
    })}
  </NodeLayout>
));

InteractiveMenu.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
