import React, { FC } from 'react';
import styled from 'styled-components';
import bg from './fondo.png'

interface IWaTextMessageViewerProps {
  text: string
}
const WaTextMessageViewer = ({ text }: IWaTextMessageViewerProps) => {
  return (
    <Cont $bgImg={bg}>
      <TextCont>
        <TailCont>
          <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px" enableBackground="new 0 0 8 13"><title>tail-in</title><path opacity="0.13" fill="#0000000" d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"></path><path fill="currentColor" d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"></path></svg>
        </TailCont>
        <Text>
          {text}
        </Text>

      </TextCont>
    </Cont>
  );
};
const Cont = styled.div<IContProp>`
  border: 1px solid;
  width: 100%;
  /* height: 350px; */
  color: #fff;
  padding: 20px 20px;
  background-image: url(${props => props.$bgImg});
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
`;
const TextCont = styled.div`
width: 336px;
width: 90%;
min-height: 40px;
padding: 6px 7px 8px 9px;
  background-color: #202c33;
  position: relative;

  border-radius: 7.5px;
  border-top-left-radius: 0;
  font-size: 15px;
`;
interface IContProp {
  $bgImg: string
}
const TailCont = styled.span`
  position: absolute;
  top: 0px;
  left: 0;
  display: flex;
  transform: translate(-100%);
  color: #202c33;
`;
const Text = styled.div`
  border: none;
  outline: none;
  /* border: 1px solid; */
  flex-grow: 1;
  margin-bottom: 5px;
  font-size: 14px;
  /* font-weight: 500; */
  color: #e9edef;
  
`;
export default WaTextMessageViewer;