import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { border } from 'library/colors';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { IError } from 'store/reports/reducer';

export interface ICircularGraphProps {
  graphData: {
    value: number;
    color: string;
    label?: string;
  }[];
  isLoading?: boolean;
  error?: IError | null;
  handleRefresh?: Function;
}

const CircularGraph: FC<ICircularGraphProps> = ({ graphData, isLoading, error, handleRefresh }) => {
  const TOTAL: number = graphData.reduce((acc, cur) => acc + cur.value, 0);

  const dataToRender = graphData
    .sort((a, b) => a.value - b.value)
    .map((slice, index) => {
      let value = graphData.reduce(
        (acc, cur, i) => (index >= i ? acc + cur.value : acc),
        0,
      );
      return { ...slice, value };
    })
    .reverse();

  const generateClipPath = (value: number): string => {
    const angle = Math.round((value * 360) / TOTAL);

    if (angle <= 90) {
      let percent = Math.round((angle * 100) / 90);
      return `50% 50%, 0% 0%, ${percent}% 0%, ${percent}% 0%, ${percent}% 0%, ${percent}% 0%`;
    } else if (angle <= 180) {
      let percent = Math.round(((angle - 90) * 100) / 90);
      return `50% 50%, 0% 0%, 100% 0%, 100% 0%, 100% 0%, 100% ${percent}%`;
    } else if (angle <= 270) {
      let percent = 100 - Math.round(((angle - 180) * 100) / 90);
      return `50% 50%, 0% 0%, 100% 0%, 100% 100%, 100% 100%, ${percent}% 100%`;
    }
    let percent = 100 - Math.round(((angle - 270) * 100) / 90);
    return `50% 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% ${percent}%`;
  };

  return (
    <Cont>
      <Pie>
        {(!isLoading && !error) && dataToRender.map((slice, index) => {
          return (
            <Slice
              key={index}
              bgColor={isLoading ? border : slice.color}
              poligon={generateClipPath(slice.value)}
            ></Slice>
          );
        })}
        {(isLoading && !error) && (
          <Loading>
            Loading &nbsp;
            <LoadingPoints />
          </Loading>
        )}
        {error && (
          <Error>
            {error?.message}
            <Refresh onClick={e =>handleRefresh && handleRefresh(e)}>
              &nbsp;
              Refresh.
            </Refresh>
          </Error>
        )}
      </Pie>
    </Cont>
  );
};
const Cont = styled.div`
  width: 100%;
  aspect-ratio: 1;
  position: relative;
`;
const Pie = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${border};
`;
const Slice = styled.div<ISlice>`
  position: absolute;
  inset: 0;
  background-color: ${props => props.bgColor};
  transform: rotate(45deg);
  clip-path: polygon(${props => props.poligon});

  transition: clip-path 1s, transform 1s;
`;
const Loading = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(3%, -60%);
  text-align: center;
`;
const Error = styled(Loading)`
  transform: translate(0%, -60%);
`;
const Refresh = styled.a``;
interface ISlice {
  poligon: string;
  bgColor: string;
}
export default CircularGraph;
