import React, { useState } from 'react';
import styled from 'styled-components';

import { darkBorder, primary } from 'library/colors';
import RightBarIcons from '../molecules/rightBarIcons';
import RightBarCustomerInfo from '../molecules/rightBarCustomerInfo';
import RightBarInfo from '../molecules/rightBarInfo';
import SendWaTemplate from '../molecules/rightBarSendWaTemplateForm';
import ReportsInRightBar from '../molecules/rightBarReports';
import ErrorBoundary from 'library/errorBoundary/errorBoundary';
import RightBarErrorFallback from 'library/errorBoundary/fallbacks/rightBarErrorFallback';

const RightBar = () => {
  const [extendedBar, setExtendedBar] = useState(false)
  const [selectedInfo, setSelectedInfo] = useState(1)

  const handleExtendedBar = (index: number) => {
    setSelectedInfo(index)
    if (!extendedBar) return setExtendedBar(true)
    if (index === selectedInfo) setExtendedBar(false)
  }
  return (
    <Cont extendedBar={extendedBar}>
      <ErrorBoundary fallback={RightBarErrorFallback}>
        {selectedInfo === 1 && <RightBarCustomerInfo />}
        {selectedInfo === 2 && <RightBarInfo />}
        {selectedInfo === 3 && <SendWaTemplate />}
        {selectedInfo === 4 && <ReportsInRightBar />}
      </ErrorBoundary>
      <RightBarIcons handleExtendedBar={handleExtendedBar} />
    </Cont>
  );
};
const Cont = styled.div<ICont>`
  width: ${props => props.extendedBar ? '250px' : '50px'};
  margin-left: 10px;
  border-radius: 5px;
  color: ${primary};
  font-size: 1rem;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  transition: width 1s, box-shadow 1s;
  box-shadow:  ${props => props.extendedBar && `-5px 5px 8px ${darkBorder}`};
`;

interface ICont {
  extendedBar: boolean;
}
export default RightBar;
