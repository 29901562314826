import * as React from "react"

export const DialogFlowConnector = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#7c9904}"}</style>
    </defs>
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M32.34 20.58a.92.92 0 0 1-.92-.92v-9.19a7.91 7.91 0 0 0-7.91-7.9H10.2a.92.92 0 0 1 0-1.84h13.31a9.76 9.76 0 0 1 9.75 9.74v9.19a.92.92 0 0 1-.92.92Z"
    />
    <path
      fill="currentColor"
      d="M23.14 34.27a.88.88 0 0 1-.62-.25l-7-6.6H8.77a7 7 0 0 1-7-7v-8.97a7 7 0 0 1 7-7h13.05a7 7 0 0 1 7 7v8.94a7.07 7.07 0 0 1-4.79 6.66v6.3a.92.92 0 0 1-.55.85.94.94 0 0 1-.34.07ZM8.77 6.26a5.19 5.19 0 0 0-5.19 5.19v8.94a5.2 5.2 0 0 0 5.19 5.19h7.09a.91.91 0 0 1 .63.25l5.74 5.4v-4.88a.91.91 0 0 1 .72-.89A5.22 5.22 0 0 0 27 20.39v-8.94a5.19 5.19 0 0 0-5.19-5.19Z"
    />
    <path
      fill="currentColor"
      d="M15 16.84H7.7a.92.92 0 1 1 0-1.84H15a.92.92 0 0 1 0 1.84ZM22.89 16.84h-4.5a.92.92 0 0 1 0-1.84h4.5a.92.92 0 0 1 0 1.84Z"
    />
  </svg>
)

