import PropTypes from 'prop-types';
import React from 'react';
import SpeedButton from '../foundation/buttons/SpeedButton';

const Viewer = ({ toggleInEdit, children, onRemove }) => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 4 }}>
        {onRemove && (
          <SpeedButton name="trash" variant="danger" onClick={onRemove} />
        )}

        <SpeedButton name="edit" variant="primary" onClick={toggleInEdit} />
      </div>

      {children}
    </>
  );
};

Viewer.propTypes = {
  toggleInEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  children: PropTypes.any,
};

export default Viewer;
