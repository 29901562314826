import React from 'react';
import MetaTags from 'react-meta-tags';

import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import LiveDashboardTemplate from 'components/LiveDashboard/templates/liveDashboardTemplate';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import RightBar from 'components/LiveDashboard/templates/rightBarTemplate';

const PagesStarter = () => {
  const { t } = useTranslation('liveBoard');
  const pageTitle = t('pageTitle');
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{pageTitle} | SmartBot</title>
        </MetaTags>

        <PageTitle>{pageTitle}</PageTitle>
        <FlexCont>
          <LiveDashboardTemplate />
          <RightBar />
        </FlexCont>
      </div>
    </React.Fragment>
  );
};
const FlexCont = styled.div`
  display: flex;
  min-height: 75vh;
`;
export default PagesStarter;
