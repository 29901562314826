import React, { Component } from 'react';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import PageHeader from '../../components/Common/PageHeader';

class PagesStarter extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Integraciones | SmartBot</title>
          </MetaTags>
          <Container fluid>
            <PageHeader title="INTEGRACIONES" />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PagesStarter;
