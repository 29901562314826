import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Buffer } from 'buffer';

import { popUpBackground, primary } from 'library/colors';
import FormHeader from '../molecules/formHeader';
import FormButtons from '../molecules/formButtons';
import CreateCampaignMainInfo from '../organisms/createCampaignMainInfo';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';
import { IFile } from '../molecules/FormLoadingFiles';
import CreateCampaignLoadFIles from '../organisms/createCampaignLoadFiles';
import CreateCampaignConfigContactSources from '../organisms/createCampaignConfigContactSources';
import {
  getPBXCampaignInfoAction,
  getReportData,
  postCreateCampaignAction,
  putUpdateCampaignAction,
} from 'store/actions';
import { IReportData, IUser } from 'library/interfaces/crdInterfaces';
import { IAgentToSelect } from '../atoms/selectableAgent';
import CreateCampaignResume from '../organisms/createCampaignResume';
import {
  IContactsToCampaign,
  ICreateCampaign,
  ICreateCampaignFileContacts,
  ICreateCampaignMainInfoState,
  IExecutionDay,
  IExecutionDayButton,
  ISchedule,
} from 'library/interfaces/campaignInterfaces';
import { ICampaign, IPBXCampaignInfo, ITrunks } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import { ISelectableQueue } from '../atoms/selectableQueue';
import {
  campaignNumbersService,
  EXECUTION_DAYS,
  fileContactsService,
  filesService,
  groupContactsInArr,
  mainInfoService,
  selectedAgentsService,
  selectedTrunkIdService,
} from 'library/services/campaignFormServices';
import { getTimezoneOffset } from 'library/services/dateServices';
import { useTranslation } from 'react-i18next';

interface IAgentExten {
  name: string;
}
interface IContact {
  id: string;
  contact: string;
  e64_data: string;
}

interface ICreateCampaignTemplateProps {
  campaignToEdit?: ICampaign;
  campaignIndexToUpdate?: number;
  setShowCampaignForm: (show: boolean) => void;
}
const CreateCampaignTemplate: FC<ICreateCampaignTemplateProps> = ({
  campaignToEdit,
  campaignIndexToUpdate,
  setShowCampaignForm,
}) => { 
  const {t} = useTranslation('outbound')
  const dispatch = useDispatch();
  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const { users, queues } = useSelector<IStore, IReportData>(
    store => store.cdr.reportData,
  );
  const pbxCampaignInfo = useSelector<IStore, IPBXCampaignInfo | null>(
    store => store.voiceCampaigns.pxbCampaignInfo,
  );

  // handle if the current stage is valid to enable or disable the next button
  const [stageIsValid, setStageIsValid] = useState(false);
  // handle the header state
  const [headerStage, setHeaderStage] = useState(HEADER_STAGE_INIT_STATE(t));

  // save excel files
  const [files, setFiles] = useState<IFile[]>([]);
  // save contacts from excel files after selecting souce
  // in a string array per file
  const [fileContacts, setFileContacts] = useState<ICreateCampaignFileContacts[][]>([]);

  // selectable agents and queues
  const [agentsToSelect, setAgentsToSelect] = useState<IAgentToSelect[]>([]);
  const [queuesToSelect, setQueuesToSelect] = useState<ISelectableQueue[]>([]);

  // data to create a new campaign
  const [mainInfoState, setMainInfoState] =
    useState<ICreateCampaignMainInfoState>(MAIN_INFO_INIT_STATE());
  const [trunk, setTrunk] = useState<ITrunks>({ id: '', name: 'Select Trunk' });
  const [campaignNumbers, setCampaignNumbers] = useState({callerId: '', did: ''})
  const [agentsAndContacts, setAngentsAndContacts] = useState<{
    agents: IAgentExten[];
    contacts: IContact[];
  }>({
    agents: [],
    contacts: [],
  });

  const currentHeaderStageIndex = headerStage.findIndex(
    stage => stage.current === true,
  );

  const handleHeaderStage = (index: number): void => {
    if (index > headerStage.length - 1) return;
    const newHeaderStage = headerStage.map((stage, i) => {
      if (index === i) return { ...stage, current: true, isSelected: true };
      if (index < i) return { ...stage, current: false, isSelected: false };
      return { ...stage, current: false };
    });
    setStageIsValid(false);
    setHeaderStage(newHeaderStage);
  };

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton'
    ) {
      return setShowCampaignForm(false);
    }
  };
  
  const handleCreateCampaign = () => {
    if (!pbxCampaignInfo) return;
    const { account_code, pbx_uri } = pbxCampaignInfo;
    const timezone = getTimezoneOffset()
    const attempts = parseInt(mainInfoState.attempts);
    const channels = parseInt(mainInfoState.channels);

    const schedule: ISchedule[] = 
      mainInfoState.execution_day.filter(day=>{
        return day.execution_time.length > 0
      })
      .map(day=>{
        return {
          execution_day: day.execution_day, 
          execution_time: day.execution_time
        }
      })

    const contacts: IContactsToCampaign[][] = groupContactsInArr(agentsAndContacts.contacts, 20)

    const newCampaign: ICreateCampaign = {
      version: '2',
      pbx_uri: pbx_uri,
      account_code: account_code,
      name: mainInfoState.name,
      owner: owner.toLowerCase(),
      date_start: mainInfoState.date_start,
      date_end: mainInfoState.date_end,
      timezone: "-0300", //timezone,
      attempts: attempts,
      recording: mainInfoState.recording,
      callerid: campaignNumbers.callerId,
      did: campaignNumbers.did,
      schedule: schedule,
      exclude_day: mainInfoState.exclude_day,
      trunk_id: trunk.id,
      channels: channels,
      destination: {
        agents: agentsAndContacts.agents,
      },
      contacts: contacts
    };
    if (campaignIndexToUpdate === undefined) {
      dispatch(postCreateCampaignAction(newCampaign));
    } else if (campaignToEdit) {
      dispatch(
        putUpdateCampaignAction(
          campaignToEdit.id,
          campaignIndexToUpdate,
          newCampaign,
        ),
      );
    }
    setShowCampaignForm(false);
  };

  // get the pbx campaign info
  //
  // and if is edit or clone
  // transforms the campaign data and inserts it into the corresponding state
  useEffect(() => {
    if (!pbxCampaignInfo) {
      dispatch(getPBXCampaignInfoAction());
      return;
    }
    if (!campaignToEdit) return;
    const newMainInfoState = mainInfoService(
      campaignToEdit,
      campaignIndexToUpdate,
    );
    // const newFilesState = filesService(campaignToEdit);
    // const newFileContacts = fileContactsService(campaignToEdit);0
    const newCampaignNumbers = campaignNumbersService(campaignToEdit)
    const newSelectedTrunkID = selectedTrunkIdService(
      campaignToEdit,
      pbxCampaignInfo,
    );
    setMainInfoState(newMainInfoState);
    // setFiles([newFilesState]);
    // setFileContacts([newFileContacts]); 
    setCampaignNumbers(newCampaignNumbers)
    setTrunk(newSelectedTrunkID);
  }, [pbxCampaignInfo]);

  // transforms users and queues into selectable objects and inserts them into states
  useEffect(() => {
    if (users.length === 0) dispatch(getReportData());
    const newAgentsToSelect: IAgentToSelect[] = [];
    users.map(user => {
      if (!user.exten) return;
      newAgentsToSelect.push({
        exten: user.exten,
        name: user.name,
        isSelected: false,
      });
    });

    const newQueueToSelect: ISelectableQueue[] = [];
    queues.map(queue => {
      newQueueToSelect.push({
        id: queue.id,
        name: queue.name,
        isSelected: false,
      });
    });

    setQueuesToSelect([...newQueueToSelect]);

    // - If is not to edit or clone a campaign, insert the newAgentsToSelect to state.
    // - If is a edit or clone campaign, selected agents at the original campaign
    //   are inserted as {...agent, isSelected: true}[]
    if (!campaignToEdit) return setAgentsToSelect([...newAgentsToSelect]);
    const newAgentsToSelectEdit = selectedAgentsService(
      campaignToEdit,
      newAgentsToSelect,
    );
    setAgentsToSelect([...newAgentsToSelectEdit]);
  }, [users]);

  //
  // VALIDATIONS
  //

  // this check if main info is correct
  useEffect(() => {
    if (!headerStage[0].current) return;
    let isValid = true;
    isValid = mainInfoState.execution_day.some(
      day=>day.execution_time.length > 0
    );
    Object.entries(mainInfoState).forEach(([key, value]) => {
      if (key === 'exclude_day' || key === 'execution_day') return;
      if (value === '') isValid = false;
    });
    setStageIsValid(isValid);
  }, [mainInfoState, headerStage]);

  // check if files have been uploaded
  useEffect(() => {
    if (!headerStage[1].current) return;
    let isValid = files.length > 0 ? true : false;
    setStageIsValid(isValid);
  }, [files, headerStage]);

  // check if trunk, contacts and agents have been selected
  useEffect(() => {
    if (!headerStage[2].current) return;
    let isValid = true;
    const selectedAgents = agentsToSelect.filter(
      agent => agent.isSelected === true,
      );
    // if(campaignNumbers.callerId === '' && campaignNumbers.did === '' ) isValid = false
    if (fileContacts.length === 0 /* || selectedAgents.length === 0 */) {
      isValid = false;
    }
    if (trunk.id === '') isValid = false;
    setStageIsValid(isValid);
  }, [campaignNumbers, trunk, fileContacts, agentsToSelect, headerStage]);

  // this tranform the data to send when the form is in the last stage
  useEffect(() => {
    if (!headerStage[headerStage.length - 1].current) return;

    let newAgents: IAgentExten[] = [];

    agentsToSelect.map(agent => {
      if (!agent.isSelected) return;
      newAgents.push({
        name: agent.exten,
      });
    });

    const fileContactsFiltered = fileContacts.filter(item => item !== null);
    let newContacts = fileContactsFiltered.flat().map((item, index) => {
      const contactData = [ {Campaign: mainInfoState.name},...item.e64_data]
      const buf = Buffer.from(JSON.stringify(contactData))
      const e64_data = buf.toString('base64')
      return {
        id: `${index + 1}`,
        contact: `${item.contact}`,
        e64_data: e64_data,
      };
    });

    setAngentsAndContacts({
      agents: newAgents,
      contacts: newContacts,
    });
  }, [headerStage]);

  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton" onClick={e => handleExit(e)}>
          x
        </Exit>
        <FormHeader stages={headerStage} />
        <StageCont>
          {headerStage[0].current && (
            <CreateCampaignMainInfo
              mainInfo={mainInfoState}
              setMainInfoValues={setMainInfoState}
            />
          )}
          {headerStage[1].current && (
            <CreateCampaignLoadFIles
              files={files}
              setFiles={setFiles}
              fileContacts={fileContacts}
              setFileContacts={setFileContacts}
            />
          )}
          {headerStage[2].current && (
            <CreateCampaignConfigContactSources
              files={files}
              setFiles={setFiles}
              setTrunk={setTrunk}
              selectedTrunk={trunk}
              fileContact={fileContacts}
              setFileContacts={setFileContacts}
              agentsToSelect={agentsToSelect}
              setAgentsToSelect={setAgentsToSelect}
              queuesToSelect={queuesToSelect}
              setQueuesToSelect={setQueuesToSelect}
              campaignNumbers={campaignNumbers}
              setCampaignNumbers={setCampaignNumbers}
            />
          )}
          {headerStage[3].current && (
            <CreateCampaignResume
              trunksName={trunk.name}
              mainInfo={mainInfoState}
              campaignNumbers={campaignNumbers}
              agentsToSelect={agentsToSelect}
              totalContacts={agentsAndContacts.contacts.length}
            />
          )}
        </StageCont>
        <FormButtons
          stageIsValid={stageIsValid}
          stageIndex={currentHeaderStageIndex}
          stageLength={headerStage.length}
          handleHeaderStage={handleHeaderStage}
          saveCampaign={handleCreateCampaign}
        />
      </FormContainer>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.form`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const StageCont = styled.div`
  min-height: 420px;
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;

//
// constants to initialize react states
//
const MAIN_INFO_INIT_STATE = (): ICreateCampaignMainInfoState => ({
  name: '',
  date_start: '',
  date_end: '',
  execution_day: EXECUTION_DAYS(),
  channels: '1',
  attempts: '1',
  recording: true,
  exclude_day: [],
});
const HEADER_STAGE_INIT_STATE = (t: (key: string)=> string) => [
  { current: true, isSelected: true, label: t('campaigns.createCampaignForm.formHeader.mainInfo') },
  { current: false, isSelected: false, label: t('campaigns.createCampaignForm.formHeader.loadingContactsFiles') },
  { current: false, isSelected: false, label: t('campaigns.createCampaignForm.formHeader.configureContactSource') },
  { current: false, isSelected: false, label: t('campaigns.createCampaignForm.formHeader.resume') },
];
export default CreateCampaignTemplate;
