export const owners = {
  NISSEI: 'NISSEI',
  ASESP: 'ASESP',
  POC: 'POC',
  CORPORACION: 'CORPORACION',
  BSE: 'BSE',
  METROPISTAS: 'METROPISTAS',
  CONSULADOUY: 'CONSULADOUY',
  CASMU: 'CASMU',
  VARLIX: 'VARLIX',
  varlix: 'varlix'
};
