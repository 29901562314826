import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { updateLimitAction } from 'store/actions';
import Button from './button';
import { useTranslation } from 'react-i18next';

const LimitButtons = () => {
  const {t} = useTranslation('recordings')
  const [buttons, setButtons] = useState([
    { text: 10, isActive: true },
    { text: 25, isActive: false },
    { text: 50, isActive: false }
  ]);
  const dispatch = useDispatch()

  const handleButtonClick = (index) => {
    const newButtonState = buttons.map((button, i)=>{
      if(i===index) {
        return {text: button.text, isActive: true}
      }
      return {text: button.text, isActive: false}
    })
    dispatch(updateLimitAction(buttons[index].text))
    setButtons(newButtonState)
  };
  return (
    <Container>
      <Text>{t('limit')}:</Text>
      <ButtonsContainer>
        {buttons.map((item, index) => {
          return (
            <Button
              key={index}
              index={index}
              button={item}
              onClick={handleButtonClick}
            />
          );
        })}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
`;
const Text = styled.div`
font-size: 14px;
font-weight: 500;
padding: 0 20px 0 0;
`;
const ButtonsContainer = styled.div` 
  text-align: center;
`;
export default LimitButtons;