import SearchAndButton from 'components/outbound/molecules/searchAndButton';
import { lightBackground } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Database from '../organisms/database';
import LoadingImg from 'components/Configuration/atoms/loading';

interface IDatabasesPops {
  setShowCreateDatabaseForm: (show: boolean) => void
}
const DatabasesTemplate = ({ setShowCreateDatabaseForm }: IDatabasesPops) => {
  const { databases, isLoading } = useAppSelector(store => store.databases)
  const [filter, setFilter] = useState('')

  const databasesToRender = useMemo(() => {
    if (!filter) return databases
    const regEx = new RegExp(filter, 'i')
    return databases.filter(database => regEx.test(database.name))
  }, [filter, databases])
  return (
    <Container>
      <Cont>
        <SearchAndButton
          onButtonClick={() => setShowCreateDatabaseForm(true)}
          onInputChange={setFilter}
          buttonKeys={{ key: 'createDatabase', nameSpace: 'databases' }}
          inputKeys={{ key: 'searchInput', nameSpace: 'databases' }}
        />
        {databasesToRender.map((db, index) => {
          return <Database key={index} database={db} />
        })}
      </Cont>
      {isLoading && <LoadingImg blur={3} />}

    </Container>
  );
};
const Container = styled.div`
flex-grow: 1;
  border-radius: 10px;
  background-color: ${lightBackground};
`;
const Cont = styled.div`
  margin: 0 20px;
  position: relative;
`;
export default DatabasesTemplate;