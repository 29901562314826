import { darkBorder } from 'library/colors';
import { IEmail } from 'library/interfaces/reportsInterfaces';
import { dateUTCISO_localDateFormatISO } from 'library/services/dateServices';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IEmailTableRowProps {
  row: IEmail
  setSelectedEmail: (email: string) => void
  setSelectedSession: (sessionId: string) => void
  reference?: (node: any) => void
}
const EmailTableRow: FC<IEmailTableRowProps> = ({ row, setSelectedEmail, reference, setSelectedSession }) => {

  const dateFormat = (date: string) => {
    return dateUTCISO_localDateFormatISO(date).replace(/([T.I])/g, ' ').substr(0, 20);
  };

  return (
    <>
      <div>{dateFormat(row.timestamp)}</div>
      <div ref={reference}>{row.phone}</div>
      <ShowEmail>
        <i className='bx bxs-show' onClick={() => setSelectedSession(row.session_id)} />
      </ShowEmail>
      <div>{row.name}</div>
      <div>{row.cedula}</div>
      <div>{row.destintion}</div>
      <ShowEmail>
        <i className='bx bxs-show' onClick={() => setSelectedEmail(row.body)} />
      </ShowEmail>
      <div>{row.notification_type}</div>
      <Center>{row.option}</Center>
    </>
  );
};
const ShowEmail = styled.div`
  font-size: 1.7rem;
  display: grid;
  justify-content: center;
  position: relative;

  & > i {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -20%);
    cursor: pointer;
    color: ${darkBorder};

    &:hover {
      color: black;
    }
  }
`;
const Center = styled.div`
  text-align: center;
`;
export default EmailTableRow;