import { BlueContModalSC, CheckBoxButtonContModalSC, FlexContSBModalSC, InputModalSC, InputVerticalGroupModalSC, LabelModalSC, SelectModalSC, WhiteContModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';
import { lightBackgroundBlue, primary } from 'library/colors';
import {
  ISceneryGlobals,
  ScenarioSourceTypes,
} from 'library/interfaces/scenariosInterfaces';
import React, { FC } from 'react';
import { Node } from 'reactflow';
import styled from 'styled-components';

interface ISceneryStartModalProps {
  // selectedNode: Node<INodeData, CustomNodeTypes>;
  // setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  sceneryGlobals: ISceneryGlobals;
  setSceneryGlobals: (sceneryGlobals: ISceneryGlobals) => void;
}
const SceneryStartModal: FC<ISceneryStartModalProps> = ({
  sceneryGlobals,
  setSceneryGlobals,
}) => {

  const handleNameChange = (value: string) => {
    setSceneryGlobals({ ...sceneryGlobals, name: value });
  };

  const handleSourcesChange = (value: ScenarioSourceTypes) => {
    const sources = [...sceneryGlobals.sources];
    const index = sources.indexOf(value);
    if (index === -1) sources.push(value);
    else sources.splice(index, 1);
    setSceneryGlobals({ ...sceneryGlobals, sources: sources });
  };
  return (
    <BlueContModalSC>
      <InputVerticalGroupModalSC>
        <LabelModalSC htmlFor="port">Scenery Name</LabelModalSC>
        <InputModalSC
          type="text"
          id="name"
          name="name"
          placeholder='Nombre del nuevo escenario'
          value={sceneryGlobals.name}
          onChange={e => handleNameChange(e.target.value)}
        />
      </InputVerticalGroupModalSC>

      <WhiteContModalSC>

        <FlexContSBModalSC>
          <InputVerticalGroupModalSC maxWidth='47%'>
            <LabelModalSC>Sources:</LabelModalSC>
            <FlexContSBModalSC>
              <CheckBoxButtonContModalSC htmlFor='chat' isChecked={sceneryGlobals.sources.includes('chat')}>
                Chat
                <input
                  type="checkbox"
                  name="chat"
                  id="chat"
                  checked={sceneryGlobals.sources.includes('chat')}
                  onChange={() => handleSourcesChange('chat')}
                />
              </CheckBoxButtonContModalSC>
              <CheckBoxButtonContModalSC htmlFor='voice' isChecked={sceneryGlobals.sources.includes('voice')}>
                Voice
                <input
                  type="checkbox"
                  name="voice"
                  id="voice"
                  checked={sceneryGlobals.sources.includes('voice')}
                  onChange={() => handleSourcesChange('voice')}
                />
              </CheckBoxButtonContModalSC>
            </FlexContSBModalSC>



          </InputVerticalGroupModalSC>

          <InputVerticalGroupModalSC maxWidth='47%'>
            <LabelModalSC>Global Languaje:</LabelModalSC>
            <SelectModalSC>
              <option value="Standard_A">es-ES</option>
            </SelectModalSC>
          </InputVerticalGroupModalSC>

        </FlexContSBModalSC>

        <FlexContSBModalSC>
          <InputVerticalGroupModalSC maxWidth='47%' margin='0'>
            <LabelModalSC>Speech To Text Engine</LabelModalSC>
            <SelectModalSC>
              <option value="Standard_A">microsoft</option>
            </SelectModalSC>
          </InputVerticalGroupModalSC>
          <InputVerticalGroupModalSC maxWidth='47%' margin='0'>
            <LabelModalSC>Speech To Text Language</LabelModalSC>
            <SelectModalSC>
              <option value="Standard_A">es-ES</option>
            </SelectModalSC>
          </InputVerticalGroupModalSC>
        </FlexContSBModalSC>
      </WhiteContModalSC>

      <WhiteContModalSC>
        <FlexContSBModalSC>
          <InputVerticalGroupModalSC maxWidth='47%'>
            <LabelModalSC >Text To Speech Engine</LabelModalSC>
            <SelectModalSC>
              <option value="Standard_A">microsoft</option>
            </SelectModalSC>
          </InputVerticalGroupModalSC>
          <InputVerticalGroupModalSC maxWidth='47%'>
            <LabelModalSC >Text To Speech Language</LabelModalSC>
            <SelectModalSC>
              <option value="Standard_A">es-UY</option>
            </SelectModalSC>
          </InputVerticalGroupModalSC>
        </FlexContSBModalSC>

        <FlexContSBModalSC>
          <InputVerticalGroupModalSC maxWidth='47%' margin='0'>
            <LabelModalSC>Text To Speech Gender</LabelModalSC>
            <SelectModalSC>
              <option value="Standard_A">Female</option>
            </SelectModalSC>
          </InputVerticalGroupModalSC>
          <InputVerticalGroupModalSC maxWidth='47%' margin='0'>
            <LabelModalSC>Text To Speech Voice</LabelModalSC>
            <SelectModalSC>
              <option value="Standard_A">es-UY-ValentinaNeural</option>
            </SelectModalSC>
          </InputVerticalGroupModalSC>
        </FlexContSBModalSC>
      </WhiteContModalSC>
    </BlueContModalSC>
  );
};
export default SceneryStartModal;
