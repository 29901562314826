import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../Divider';
import { Row, Col } from 'reactstrap';

Switch.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  divider: PropTypes.bool,
  children: PropTypes.any,
};

export function Switch({ label, value = true, divider = true, children }) {
  return (
    <Row>
      <Col md={12}>
        {label && (
          <div className={`data-info-label${value ? '-md' : '-disabled'}`}>
            {!value && 'off'} {label}
          </div>
        )}

        {value && (
          <>
            {children}
            {divider && <Divider />}
          </>
        )}
      </Col>
    </Row>
  );
}
