import { primary } from 'library/colors';
import IStore from 'library/interfaces/storeInterface';
import { Phone } from 'library/phone/phone';
import { chatBotToSelectedChatService } from 'library/services/chatBotToSelectedChatService';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IChatBotStore } from 'store/chatBot/reducer';
import { updateSelectedChatAction } from 'store/chatSelected/actions';
import { ISelectedChatStore } from 'store/chatSelected/reducer';
import styled from 'styled-components';
import ChatMessage from '../atoms/chatMessage';

interface ISelectedChatProps {
  isCallAnswered: boolean;
  phone: Phone;
}
const SelectedChat: FC<ISelectedChatProps> = ({ isCallAnswered, phone }) => {
  const contRef = useRef(null);
  const [chatInnerWidth, setChatInnerWidth] = useState(100)
  const dispatch = useDispatch();
  const chatBotStore = useSelector<IStore>(
    store => store.ChatBot,
  ) as IChatBotStore;
  const selectedChatStore = useSelector<IStore>(
    store => store.selectedChat,
  ) as ISelectedChatStore;
  const owner = useSelector<IStore>(
    store => store.authUserData.owner
  ) as string

  const handleDivResize = () =>{
    // @ts-expect-error
    setChatInnerWidth(contRef.current?.offsetWidth)
  }
  
  const resizeObserver = new ResizeObserver(handleDivResize)
  
  useEffect(() => {
    const result = chatBotToSelectedChatService(chatBotStore, owner);
    const remoteIdentity = phone && phone.getRemoteId() ? phone.getRemoteId() : '';
    result.customer.phoneNumber = remoteIdentity
    dispatch(updateSelectedChatAction(result.customer, result.messages));
  }, [chatBotStore]);

  useEffect(() => {
    // @ts-expect-error
    contRef.current?.scrollIntoView();
    // @ts-expect-error
    resizeObserver.observe(contRef.current)

    return () =>{
      resizeObserver.disconnect()
    }

  }, [selectedChatStore]);

  return (
    <ChatCont id="ChatContainerScroll">
      {selectedChatStore.messages.map((item, index) => {
        if (item.sender.includes(owner)) {
          return <ChatMessage chatWidth={chatInnerWidth} right message={item} key={index} />;
        }
        return <ChatMessage chatWidth={chatInnerWidth} message={item} key={index} />;
      })}
      <div ref={contRef}></div>
    </ChatCont>
  );
};
const ChatCont = styled.div`
  flex-grow: 1;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
export default SelectedChat;
