import React, { memo } from 'react';

import NodeLayout from '../common/NodeLayout';
import RightHandle from '../common/RightHandle';
import LeftHandle from '../common/LeftHandle';
import Modal from './Modal';
import PropTypes from 'prop-types';

const color = 'blue';

// eslint-disable-next-line react/display-name
export const HttpRequest = memo(({ data, isConnectable }) => (
  <NodeLayout icon="HttpRequest" data={data} Modal={Modal} color={color}>
    <LeftHandle
      isConnectable={isConnectable}
      onConnect={() => {}}
      id="route_in"
    />

    <RightHandle
      id="route_success"
      isConnectable={isConnectable}
      type="source"
      text="Success"
      bg="green2"
    />
  </NodeLayout>
));

HttpRequest.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
