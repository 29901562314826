import { darkBorder, primary } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { ISession } from 'library/interfaces/conversationInterfaces';
import { dateIsoFormated } from 'library/services/dateServices';
import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  delCloseSessionAction,
  getCLientMessagesAction,
  getWACampaignsAction,
  putAssingSessionToAgent,
  setSelectedSessionAction,
  updatePhoneE64DataAction
} from 'store/actions';

interface IConversationSessionsProps {
  filter: string;
  tab: number;
  filterByCampaign: string;
}
const ConversationSessions: FC<IConversationSessionsProps> = ({ tab, filter, filterByCampaign }) => {
  const dispatch = useAppDispatch();
  const waCampaigns = useAppSelector(store => store.waCampaigns.campaigns)
  const sessions = useAppSelector(store => store.conversations.activeSessions.sessionsStore.sessions)
  const sessionsAssigned = useAppSelector(store => store.conversations.activeSessions.sessionsAssignedStore.sessions)
  const { templates, isLoading } = useAppSelector(store => store.waCampaigns.templates)

  const sessionAsignedFilterByCampaign = useMemo(() => {
    if (filterByCampaign === '') return sessionsAssigned
    return sessionsAssigned.filter(session => session.campaign_id === filterByCampaign)
  }, [filterByCampaign, sessionsAssigned])

  const sessionsAssignedToRender = useMemo(() => {
    if (filter === '') return sessionAsignedFilterByCampaign
    const regEx = new RegExp(filter, 'i')
    return sessionAsignedFilterByCampaign.filter(session => regEx.test(session.source_id) || regEx.test(session.source_name))
  }, [filter, sessionAsignedFilterByCampaign])

  const sessionFilterByCampaign = useMemo(() => {
    if (filterByCampaign === '') return sessions
    return sessions.filter(session => session.campaign_id === filterByCampaign)
  }, [filterByCampaign, sessions])

  const sessionsToRender = useMemo(() => {
    if (filter === '') return sessionFilterByCampaign
    const regEx = new RegExp(filter, 'i')
    return sessionFilterByCampaign.filter(session => regEx.test(session.source_id) || regEx.test(session.source_name))
  }, [filter, sessionFilterByCampaign])

  const handleSessionContClick = (
    channelId: string,
    source: string,
    sessionId: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // if are fetching the templates, return
    if (!(e.target instanceof HTMLDivElement) || isLoading) return;

    const { id } = e.target;

    if (id === 'exitButton') return dispatch(delCloseSessionAction(sessionId));

    const session = sessionsAssigned.filter(
      sess => sess.id === sessionId,
    );

    if (session.length > 0 && session[0].messages.length > 1) {
      if (session[0].clientData) {
        dispatch(updatePhoneE64DataAction([session[0].clientData]))
      } else {
        dispatch(updatePhoneE64DataAction([]))
      }
      dispatch(setSelectedSessionAction(sessionId))
      return
    }

    dispatch(updatePhoneE64DataAction([]))
    dispatch(getCLientMessagesAction(channelId, source, sessionId, templates));
  };

  const handleUnassignedSessionClick = (session: ISession) => {
    // if are fetching the templates, return
    if (isLoading) return
    // if (session.source_name !== 'instagram' && session.source_name !== 'facebook') 
    dispatch(putAssingSessionToAgent(session))
    dispatch(getCLientMessagesAction(session.channel_id, session.source_id, session.id, templates));
  }

  const getCampaignColor = (waCampaignId: string): string => {
    if (waCampaigns.length === 0) return darkBorder

    const campaign = waCampaigns.find(camp => camp.id === waCampaignId)

    if (!campaign) return darkBorder

    return campaign?.color || darkBorder
  }

  useEffect(() => {
    dispatch(getWACampaignsAction());
  }, [])
  return (
    <Cont>
      {tab === 0 && sessionsAssignedToRender.map((session, index) => {
        return (
          <SessionCont
            id="sessionCont"
            hasNewMessage={session.hasNewMessage}
            key={index}
            onClick={e =>
              handleSessionContClick(
                session.channel_id,
                session.source_id,
                session.id,
                e,
              )
            }
          >
            <Exit id="exitButton">X</Exit>
            <ImgCont color={getCampaignColor(session.campaign_id)}>
              <span>{session.source_name.slice(0, 1)}</span>
            </ImgCont>
            <Info>
              <Name>{session.source_name}</Name>
              <Div>{session.source_id}</Div>
              <Div>{dateIsoFormated(session.updated_at)}</Div>
            </Info>
          </SessionCont>
        );
      })}

      {tab === 1 && sessionsToRender.map((session, index) => {
        return (
          <SessionCont
            key={index}
            onClick={() => handleUnassignedSessionClick(session)}
          >
            <ImgCont color={getCampaignColor(session.campaign_id)}>
              <span>{session.source_name.slice(0, 1)}</span>
            </ImgCont>
            <Info>
              <Name>{session.source_name}</Name>
              <Div>{session.source_id}</Div>
              <Div>{dateIsoFormated(session.updated_at)}</Div>
            </Info>
          </SessionCont>
        )
      })}
    </Cont>
  );
};
const Cont = styled.div`
  flex-grow: 1;
  /* border: 1px solid; */
  margin-top: 10px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const SessionCont = styled.div<ISessionCont>`
  height: 65px;
  margin: 0 3px 10px;
  /* border: 1px solid ${darkBorder}; */
  display: flex;
  align-items: center;
  padding: 0 10px 5px;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 3px 5px ${darkBorder};
  background-color: ${props => props.hasNewMessage ? primary : '#fff'};
`;
const Exit = styled.div`
  top: 3px;
  right: 5px;
  position: absolute;
  font-size: 0.8rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const ImgCont = styled.div<IImgCont>`
  width: 45px;
  height: 45px;
  font-size: 2rem;
  border-radius: 50%;
  color: #fff;
  background-color: ${props => props.color};
  transform: translateY(2.5px);
  text-align: center;
  line-height: 45px;
`;
const Info = styled.div`
  padding-left: 10px;
`;
const Div = styled.div`
  line-height: 15px;
`;
const Name = styled.div`
  color: black;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  line-height: 25px;
  padding-top: 5px;
`;
interface ISessionCont {
  hasNewMessage?: boolean;
}
interface IImgCont {
  color: string
}
export default ConversationSessions;
