import {
  GET_QUEUES,
  GET_QUEUES_FAIL,
  GET_QUEUES_SUCCESS,
  GET_RECORDINGS,
  GET_RECORDINGS_FAIL,
  GET_RECORDINGS_SUCCESS,
  GET_PAGINATION_NEXT,
  GET_PAGINATION_FAIL,
  GET_PAGINATION_SUCCESS,
  /* GET_RECORDINGS_BY_DATE,
  GET_RECORDINGS_BY_DATE_FAIL,
  GET_RECORDINGS_BY_DATE_SUCCESS, */
  GET_RECORDINGS_BY_FILTER,
  GET_RECORDINGS_BY_FILTER_FAIL,
  GET_RECORDINGS_BY_FILTER_SUCCESS,
  GET_RECORDINGS_BY_QUEUE,
  GET_RECORDINGS_BY_QUEUE_FAIL,
  GET_RECORDINGS_BY_QUEUE_SUCCESS,
  GET_RECORDINGS_BY_ALL_FILTERS,
  GET_RECORDINGS_BY_ALL_FILTERS_FAIL,
  GET_RECORDINGS_BY_ALL_FILTERS_SUCCESS,
  FILTER_ADD_QUEUE,
  FILTER_REMOVE_QUEUE,
  FILTER_VALUE_UPDATE,
  POST_FILTERS,
  POST_FILTERS_FAIL,
  POST_FILTERS_SUCCESS,
  UPDATE_LIMIT,
  UPDATE_RECORDINGS_ORDER,
  UPDATE_RECORDINGS_PAGINATION,
  GET_RECORDINGS_FROM_REPORT_MODULE,
  GET_RECORDINGS_FROM_REPORT_MODULE_SUCCESS,
  GET_RECORDINGS_FROM_REPORT_MODULE_FAIL,
} from './actionTypes';

//
//QUEUES
//
export const getQueues = () => ({
  type: GET_QUEUES,
});

export const getQueuesSuccess = queues => ({
  type: GET_QUEUES_SUCCESS,
  payload: queues,
});

export const getQueuesFail = error => ({
  type: GET_QUEUES_FAIL,
  payload: error,
});

//
//RECORDINGS
//
export const getRecordings = owner => ({
  type: GET_RECORDINGS,
  payload: owner,
});

export const getRecordingsSuccess = recordings => ({
  type: GET_RECORDINGS_SUCCESS,
  payload: recordings,
});

export const getRecordingsFail = error => ({
  type: GET_RECORDINGS_FAIL,
  payload: error,
});

//
//PAGINATION
//
export const getPaginationNextAction = (owner, LastKey, limit) => ({
  type: GET_PAGINATION_NEXT,
  payload: { owner, LastKey, limit },
});
export const getPaginationSuccess = recordings => ({
  type: GET_PAGINATION_SUCCESS,
  payload: recordings,
});
export const getPaginationFail = error => ({
  type: GET_PAGINATION_FAIL,
  payload: error,
});

//
//FILTERS DATE    DELETE
//
/* export const getRecordingsFilterByDateActions = (owner, start, end, startTime, endTime, limit, order) =>({
  type: GET_RECORDINGS_BY_DATE,
  payload: {owner, start, end, startTime, endTime, limit, order}
})
export const getRecordingsFilterByDateSuccess = recordings => ({
  type: GET_RECORDINGS_BY_DATE_SUCCESS,
  payload: recordings,
});

export const getRecordingsFilterByDateFail = error => ({
  type: GET_RECORDINGS_BY_DATE_FAIL,
  payload: error,
}); */
//
//FILTERS
//
export const getRecordingsByFilterActions = (
  owner,
  startDate,
  endDate,
  filter,
  value,
  limit,
  order,
) => ({
  type: GET_RECORDINGS_BY_FILTER,
  payload: { owner, startDate, endDate, filter, value, limit, order },
});
export const getRecordingsByFilterSuccess = recordings => ({
  type: GET_RECORDINGS_BY_FILTER_SUCCESS,
  payload: recordings,
});

export const getRecordingsByFilterFail = error => ({
  type: GET_RECORDINGS_BY_FILTER_FAIL,
  payload: error,
});
//
//FILTERS BY QUEUE
//
export const getRecordingsByQueueActions = (owner, queue, limit, order) => ({
  type: GET_RECORDINGS_BY_QUEUE,
  payload: { owner, queue, limit, order },
});
export const getRecordingsByQueueSuccess = recordings => ({
  type: GET_RECORDINGS_BY_QUEUE_SUCCESS,
  payload: recordings,
});

export const getRecordingsByQueueFail = error => ({
  type: GET_RECORDINGS_BY_QUEUE_FAIL,
  payload: error,
});
//
//FILTERS BY ALL FILTERS
//
export const getRecordingsByAllFiltersActions = (
  owner,
  queue,
  startDate,
  endDate,
  filter,
  value,
  limit,
  order,
) => ({
  type: GET_RECORDINGS_BY_ALL_FILTERS,
  payload: { owner, queue, startDate, endDate, filter, value, limit, order },
});
export const getRecordingsByAllFiltersSuccess = recordings => ({
  type: GET_RECORDINGS_BY_ALL_FILTERS_SUCCESS,
  payload: recordings,
});

export const getRecordingsByAllFiltersFail = error => ({
  type: GET_RECORDINGS_BY_ALL_FILTERS_FAIL,
  payload: error,
});

//
//LIMIT , ORDER , PAGE (PAGINATION)
//
export const updateLimitAction = limit => ({
  type: UPDATE_LIMIT,
  payload: limit,
});
export const updateOrderAction = order => ({
  type: UPDATE_RECORDINGS_ORDER,
  payload: order,
});
export const updateRecordingsPaginationAction = page => ({
  type: UPDATE_RECORDINGS_PAGINATION,
  payload: page,
});

//
//FILTERS
//
export const filterAddQueue = queueId => ({
  type: FILTER_ADD_QUEUE,
  payload: queueId,
});
export const filterRemoveQueue = queueId => ({
  type: FILTER_REMOVE_QUEUE,
  payload: queueId,
});
export const filterValueUpdate = filter => ({
  type: FILTER_VALUE_UPDATE,
  payload: filter,
});

// GET recordings from report module
export const getRecordingsFromReportModuleAction = (
  owner,
  limit,
  order,
  start_date,
  end_date,
  filters,
) => ({
  type: GET_RECORDINGS_FROM_REPORT_MODULE,
  payload: { owner, limit, order, start_date, end_date, filters },
});
export const getRecordingsFromReportModuleSuccess = (recordings) => ({
  type: GET_RECORDINGS_FROM_REPORT_MODULE_SUCCESS,
  payload: recordings
})
export const getRecordingsFromReportModuleFail = (error) => ({
  type: GET_RECORDINGS_FROM_REPORT_MODULE_FAIL,
  payload: error
})

/// Revisar para eliminar

export const postFilters = filters => ({
  type: POST_FILTERS,
  payload: filters,
});
export const postFiltersSuccess = recordings => ({
  type: POST_FILTERS_SUCCESS,
  payload: recordings,
});
export const postFiltersFail = error => ({
  type: POST_FILTERS_FAIL,
  payload: error,
});
