import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import PageTitle from '../atoms/pageTitle';
import LiveCalls from '../molecules/liveCalls';
import LiveDashboardAside from '../organisms/liveDashboardAside';
import ServiceLevel from '../molecules/serviceLevel';
import AnswerTime from '../molecules/answerTime';
import QueueTime from '../molecules/queueTime';
import CallsAnsweredAndAtentionTime from '../organisms/callsAnsweredAndAtentionTIme';
import PostCallTime from '../molecules/postCallTime';
import OccupationRange from '../molecules/occupationRange';
import LostCallsWithGraph from '../organisms/lostCallWithGraph';
import Container from '../atoms/container';
import QueueSelectLiveBoard from '../organisms/queueSelectLiveBoard';

import { getReportData, getReportTotalCallsByOriginAction, getSummarizeData } from 'store/actions';

import { ICdr } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';
import SummarizeService from 'library/services/summarizeService';
import AddAgentToQueue from '../organisms/addAgentToQueuePopup';
import {
  agentsfromQueueToSelectableAgents,
  freeAgentsToSelectableAgents,
} from 'library/services/addAgentToQueueServices';
import { IAgentToSelect } from 'components/outbound/atoms/selectableAgent';
import { useTranslation } from 'react-i18next';
import RightBar from './rightBarTemplate';
import { subscribeLiveData } from 'helpers/appSyncLiveBoard';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';
import { IAuthUserData } from 'store/authUserData/reducer';
import { getToday_yyyymmdd } from 'library/services/dateServices';
import { useAppSelector } from 'library/hooks/useAppSelector';

interface ISummarizeTemplateProps {
  queueId: string;
}
const SummarizeTemplate: FC<ISummarizeTemplateProps> = ({ queueId }) => {
  const { t } = useTranslation('summarize')
  const dispatch = useDispatch();
  const { summarizeData, reportData } = useSelector<IStore>(
    state => state.cdr,
  ) as ICdr;

  const [live, setLive] = useState<ILiveDataQueue[]>([])

  const { owner, exten } = useSelector<IStore, IAuthUserData>(store => store.authUserData);
  const totalCallsByOrigin = useAppSelector(store => store.reports.totalCallByOrigin)

  const [showAddAgentToQueue, setShowAddAgentToQueue] = useState(false);
  const [selectableAgents, setSelectableAgents] = useState<{
    inQueue: IAgentToSelect[];
    free: IAgentToSelect[];
  }>({ inQueue: [], free: [] });


  const data = useMemo(() => {
    return SummarizeService(reportData, live, summarizeData, queueId);
  }, [reportData, live, summarizeData, queueId])

  useEffect(() => {
    if (reportData.queues.length > 0) return
    dispatch(getReportData());
  }, []);

  useEffect(() => {
    if (!owner || reportData.queues.length === 0) return
    const queuesId = reportData.queues.map(item => item.id);
    dispatch(getSummarizeData(owner.toLowerCase(), 'last24h', -3, queuesId));
  }, [owner, reportData.queues])

  // add selectable agents
  useEffect(() => {
    if (
      selectableAgents.free.length !== 0 ||
      selectableAgents.inQueue.length !== 0 ||
      live.length === 0
    ) {
      return;
    }

    const agentsInQueue = agentsfromQueueToSelectableAgents(
      reportData,
      live,
      queueId,
    );
    const freeAgents = freeAgentsToSelectableAgents(
      reportData,
      live,
      queueId,
    );

    setSelectableAgents({
      inQueue: agentsInQueue,
      free: freeAgents,
    });
  }, [live]);

  useEffect(() => {
    if (!owner) return
    const subscription = subscribeLiveData(
      owner.toLowerCase(),
      (data: ILiveDataQueue[]) => setLive(data),
    );
    return () => subscription.unsubscribe();
  }, [owner])

  useEffect(() => {
    if (!owner) return;
    const OWNER = owner.toLocaleLowerCase();

    const START_DATE = getToday_yyyymmdd() + 'T00:00:00.000Z';
    const END_DATE = getToday_yyyymmdd() + 'T23:59:59.999Z';

    dispatch(getReportTotalCallsByOriginAction(OWNER, START_DATE, END_DATE, exten));
  }, [owner]);

  return (
    <>
      <PageTitle>{t('pageTitle')} &middot; {data.queue.name}</PageTitle>

      <FlexCont>
        <Container padding="20px">
          <QueueSelectLiveBoard mainPath='/live-board' redirectPath='/live-board/summarized' queues={reportData.queues} />

          <Container padding="30px" mediumBackground>
            <GridContainer>
              <LiveDashboardAside
                gridColumn="-2 / -1"
                gridRow="1 / 5 "
                data={data.agents_state}
                agents={data.agents}
                showAddAgentToQueue={setShowAddAgentToQueue}
              />
              <LiveCalls
                gridColumn="1 / -2"
                gridRow="1"
                inqueue={data.calls.inqueue}
                answered={data.calls.answered}
                online={data.calls.online}
              />
              <ServiceLevel porcent={data.service_level} />
              <AnswerTime
                calltime_average={data.calltime_average}
                calltime_min_time={data.calltime_min_time}
                calltime_max_time={data.calltime_max_time}
                calltime_change={data.calltime_change}
              />
              <QueueTime
                waiting_average={data.waiting_average}
                waiting_min_time={data.waiting_min_time}
                waiting_max_time={data.waiting_max_time}
                waiting_change={data.waiting_change}
              />
              <CallsAnsweredAndAtentionTime
                gridColumn="1 / 3"
                answered_calls={data.calls.answeredCount}
                service_average={data.service_average}
                service_min_time={data.service_min_time}
                service_max_time={data.service_max_time}
                service_change={data.service_change}
              />
              {/* <PostCallTime /> */}
              <OccupationRange porcent={data.occupation_range} />
              <LostCallsWithGraph
                gridColumn="1 / 3"
                abandoned_calls={data.calls.abandonedCount}
                incoming_calls={data.calls.answeredCount + data.calls.abandonedCount}
              />
            </GridContainer>
          </Container>
        </Container>
        <RightBar />
      </FlexCont>
      {showAddAgentToQueue && (
        <AddAgentToQueue
          queue={data.queue}
          selectableAgents={selectableAgents}
          setSelectableAgents={setSelectableAgents}
          setShow={setShowAddAgentToQueue}
        />
      )}
    </>
  );
};
const FlexCont = styled.div`
  display: flex;
  min-height: 75vh;
`;
const GridContainer = styled.div`
  margin: 20px auto 0;
  color: black;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 320px;
  grid-template-rows: 140px 185px 185px 170px;
  /* position: relative; */
  /* z-index: 0; */
  max-width: 1030px;

  @media (min-width: 1600px) {
    transform: scale(1.1);
    margin: 50px auto 50px;
  }
  @media (min-width: 1700px) {
    transform: scale(1.2);
    margin: 100px auto 100px;
  }
  @media (min-width: 1800px) {
    transform: scale(1.3);
    margin: 130px auto 130px;
  }
  @media (min-width: 1900px) {
    transform: scale(1.45);
    margin: 180px auto 160px;
  }
`;
export default SummarizeTemplate;
