import ExcludeDay from 'components/outbound/molecules/excludeDay';
import ExecutionDaysButtons from 'components/outbound/molecules/executionDaysButtons';
import ExecutionHours from 'components/outbound/molecules/executionHours';
import ExecutionHoursForm from 'components/outbound/molecules/executionHoursForm';
import { lightBackgroundBlue } from 'library/colors';
import {
  ICreateCampaignMainInfoState,
  IExecutionDayButton,
} from 'library/interfaces/campaignInterfaces';
import { EXECUTION_DAYS } from 'library/services/campaignFormServices';
import {
  isDateBeforeToday,
  isDateBetweenTheTwoDates,
  isFirstDateAfterToSecondDate,
} from 'library/services/dateServices';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface ICreateCampaignHorasProps {}
const CreateCampaignHoras: FC<ICreateCampaignHorasProps> = ({}) => {
  const [mainInfoState, setMainInfoState] =
    useState<ICreateCampaignMainInfoState>(MAIN_INFO_INIT_STATE());
  const [hoursForm, setHoursForm] = useState({
    isValid: false,
    start_time: '',
    end_time: '',
  });

  const handleExecutionDayButtonClick = (index: number) => {
    const newDayButtonsState = mainInfoState.execution_day;
    newDayButtonsState[index].isSelected =
      !mainInfoState.execution_day[index].isSelected;

    const hoursFormIsValid =
      hoursForm.start_time !== '' &&
      hoursForm.end_time !== '' &&
      newDayButtonsState.some(button => button.isSelected);

    setMainInfoState({
      ...mainInfoState,
      execution_day: newDayButtonsState,
    });
    setHoursForm({ ...hoursForm, isValid: hoursFormIsValid });
  };

  const addWorkingHours = () => {
    if (!hoursForm.isValid) return;
    const { start_time, end_time } = hoursForm;
    const newExecutionDay: IExecutionDayButton[] =
      mainInfoState.execution_day.map(day => {
        if (!day.isSelected || day.execution_time.length === 2) return day;
        day.execution_time.push({ start_time, end_time });
        return day;
      });
    setMainInfoState({
      ...mainInfoState,
      execution_day: newExecutionDay,
    });
  };
  const deleteWorkingHours = (dayIndex: number, timeIndex: number) => {
    const newExecutionDays = mainInfoState.execution_day;
    newExecutionDays[dayIndex].execution_time.splice(timeIndex, 1);

    setMainInfoState({
      ...mainInfoState,
      execution_day: newExecutionDays,
    });
  };
  const handleInputTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;

    if (name === 'end_time' && hoursForm.start_time === '') return;
    else if (
      name === 'end_time' &&
      isFirstDateAfterToSecondDate(
        `2022-1-1 ${hoursForm.start_time}`,
        `2022-1-1 ${value}`,
      )
    )
      return;
    else if (
      name === 'start_time' &&
      hoursForm.end_time !== '' &&
      isFirstDateAfterToSecondDate(
        `2022-1-1 ${value}`,
        `2022-1-1 ${hoursForm.end_time}`,
      )
    )
      return;
    const formIsValid =
      (name === 'end_time' ||
        (name === 'start_time' && hoursForm.end_time !== '')) &&
      mainInfoState.execution_day.some(button => button.isSelected);

    setHoursForm({
      ...hoursForm,
      isValid: formIsValid,
      [name]: value,
    });
  };
  const deleteExcludeDay = (index: number, days: { day: string }[]) => {
    days.splice(index, 1);
    setMainInfoState({
      ...mainInfoState,
      exclude_day: days,
    });
  };
  const handleInputDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    date_start?: string,
  ) => {
    const { name, value } = event.target;

    if (name === 'date_start' && isDateBeforeToday(value)) {
      return;
    } else if (
      name === 'date_end' &&
      (date_start === '' ||
        !isFirstDateAfterToSecondDate(value, mainInfoState.date_start))
    ) {
      return;
    } else if (
      name === 'exclude_day' &&
      (mainInfoState.date_start === '' ||
        mainInfoState.date_end === '' ||
        !isDateBetweenTheTwoDates(
          mainInfoState.date_start,
          mainInfoState.date_end,
          value,
        ) ||
        mainInfoState.exclude_day.some(day => day.day === value))
    ) {
      return;
    } else if (name === 'exclude_day') {
      const excludeDay = mainInfoState.exclude_day;

      excludeDay.unshift({
        day: value,
      });

      setMainInfoState({
        ...mainInfoState,
        exclude_day: [...excludeDay],
      });
      return;
    }

    setMainInfoState({
      ...mainInfoState,
      [name]: value,
    });
  };
  return (
    <Cont>
      <FormGroupTitle>Horarios</FormGroupTitle>
      <FlexCont>
        <Cont2>
          <InputDateGroup>
            <label>Fecha Inicio</label>
            <input
              type="date"
              name="date_start"
              value={mainInfoState.date_start}
              onChange={e => handleInputDateChange(e)}
            />
          </InputDateGroup>
          <InputDateGroup>
            <label>Fecha final</label>
            <input
              type="date"
              name="date_end"
              value={mainInfoState.date_end}
              onChange={e => handleInputDateChange(e, mainInfoState.date_start)}
            />
          </InputDateGroup>
          <InputDateGroup margin="10px 0 10px">
            <label>Excluir días</label>
            <input
              type="date"
              name="exclude_day"
              value={
                mainInfoState.exclude_day[0]
                  ? mainInfoState.exclude_day[0].day
                  : ''
              }
              onChange={e => handleInputDateChange(e)}
            />
          </InputDateGroup>
          <InputDateGroup>
            <ExcludeDay
              exclusion_day={mainInfoState.exclude_day}
              deleteExcludeDay={deleteExcludeDay}
            />
          </InputDateGroup>
        </Cont2>
        <Flex>
          <ExecutionDaysButtons
            buttons={mainInfoState.execution_day}
            handleSelectButton={handleExecutionDayButtonClick}
          />
          <ExecutionHours
            executionDays={mainInfoState.execution_day}
            onDeleteHours={deleteWorkingHours}
          />
          <ExecutionHoursForm
            hoursForm={hoursForm}
            handleInputTimeChange={handleInputTimeChange}
            onAddClick={addWorkingHours}
          />
        </Flex>
      </FlexCont>
      <InputGroup>
        <label>Numero de mensajes a enviar por día</label>
        <div>
        <input
          type="number"
          name="name"
          // value={firstStageData.campaignName}
          // onChange={e => handleNameChange(e)}
        />
        <Indication>Si no se especifica, el total se dividirá entre el número de días.</Indication>
        </div>
      </InputGroup>
    </Cont>
  );
};
const Cont = styled.div`
  height: 420px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${lightBackgroundBlue};
`;
const FlexCont = styled.div`
  display: flex;
  align-items: center;
`;
const Cont2 = styled.div`
  flex-grow: 1;
  max-width: 50%;
`;
const FormGroupTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;
const Flex = styled.div`
  display: flex;
  height: 240px;
  flex-grow: 1;
  justify-content: space-between;
`;
const InputDateGroup = styled.div<IInputDateGroup>`
  margin: ${props => (props.margin ? props.margin : '10px 0 20px')};
  font-size: 0.7rem;
  font-weight: 500;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label {
    margin: 0;
  }
  & > input {
    margin-left: 10px;
    font-size: 1rem;
    text-align: center;
    border: none;
    padding: 10px 0;
    max-width: 200px;
    flex-grow: 1;
  }
`;
const MAIN_INFO_INIT_STATE = (): ICreateCampaignMainInfoState => ({
  name: '',
  date_start: '',
  date_end: '',
  execution_day: EXECUTION_DAYS(),
  channels: '1',
  attempts: '1',
  recording: true,
  exclude_day: [],
});
const InputGroup = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & input {
    width: 100%;
    font-size: 1rem;
    padding: 10px 0;
    border: none;
    max-width: 300px;
  }
`;
const Indication = styled.span`
  display: inline-block;
`;
interface IInputDateGroup {
  margin?: string;
}
export default CreateCampaignHoras;
