import CircularGraph from 'components/Report/atoms/circularGraph';
import Indicator from 'components/Report/molecules/indicator';
import EventDetailed from 'components/Report/organisms/eventDetailed';
import EventsByBotDetailedForm from 'components/Report/organisms/eventsByBotDetailedForm';
import { lightBackgroundBlue, primary } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { getToday_yyyymmdd } from 'library/services/dateServices';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { clearEventsFromBotDataStoreAction, getEventFromBotDataAction } from 'store/actions';
import styled from 'styled-components';

interface IEventsByBotExtensionDetailedProps { }
const EventsByBotExtensionDetailed: FC<IEventsByBotExtensionDetailedProps> = ({ }) => {
  const dispatch = useAppDispatch()
  const owner = useAppSelector(store => store.authUserData.owner)

  const observer = useRef<IntersectionObserver>()
  const [numberOfElements, setNumberOfElements] = useState(10)

  const {
    counter,
    colors,
    events,
    isLoading,
    solvedRate,
    callMinuteTranscript,
    totalMinuteTranscript
  } = useAppSelector(store => store.reports.eventsFromBotData)

  const [showDetails, setShowDetails] = useState(false)
  const [form, setForm] = useState(() => ({
    start_date: getToday_yyyymmdd(),
    end_date: getToday_yyyymmdd(),
    exten: '----'
  }));

  const [eventsFilter, setEventsFilter] = useState('')
  const [numberFilter, setNumberFilter] = useState('')

  const [numberInput, setNumberInput] = useState('')

  const eventsType = useMemo(() => {
    return Object.keys(counter)
  }, [events])

  const labelsToRender = useMemo(() => {
    const keys = Object.keys(counter)
    const data = keys.map(key => {
      return {
        value: counter[key],
        color: colors[key],
        label: key,
      }
    })
    return data
  }, [counter])

  const dataToRender = useMemo(() => {
    return labelsToRender.filter(data => data.label !== 'INCOMING_CALL')
  }, [labelsToRender])

  const eventsFiltered = useMemo(() => {
    if (!events) return []

    if (eventsFilter && numberFilter) {
      return events.filter(event => event.event === eventsFilter && event.callerid.includes(numberFilter))
    } else if (eventsFilter) {
      return events.filter(event => event.event === eventsFilter)
    } else if (numberFilter) {
      return events.filter(event => event.callerid.includes(numberFilter))
    }
    return events
  }, [eventsFilter, numberFilter, events])

  const eventsToRender = useMemo(() => {
    const sliceEnd = numberOfElements
    return eventsFiltered.slice(0, sliceEnd)
  }, [eventsFiltered, numberOfElements])

  const lastElementRef = useCallback((node: any) => {

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setNumberOfElements(prev => prev + 10)
      }
    })
    if (node) observer.current.observe(node)
  }, [])

  const handleSelectChange = (value: string) => {
    setEventsFilter(value)
  }

  const handleFormButtonClick = () => {
    const { exten, end_date, start_date } = form
    if (exten === '----' || isLoading) return
    setShowDetails(false)
    setNumberOfElements(10)
    setEventsFilter('')
    setNumberFilter('')
    setNumberInput('')
    dispatch(getEventFromBotDataAction(exten, start_date + 'T00:00:00:000Z', end_date + 'T23:59:59:999Z'))
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      setNumberFilter(numberInput)
    }, 1000)

    return () => clearTimeout(interval)
  }, [numberInput])

  useEffect(() => {
    return () => {
      dispatch(clearEventsFromBotDataStoreAction())
    }
  }, [])

  return (
    <Cont>
      <EventsByBotDetailedForm isDisable={isLoading} formValues={form} setFormValues={setForm} handleFormButtonClick={handleFormButtonClick} />
      <BlueCont>
        <Title>
          Resumen
          {totalMinuteTranscript && (
            <span>{`: ${totalMinuteTranscript.toFixed(2)} min`}</span>
          )}
        </Title>
        <WhiteCont>
          <GraphCont>

            <CircularGraph graphData={dataToRender} />

          </GraphCont>

        </WhiteCont>
        <WhiteCont grid='3 / 5'>
          {labelsToRender.map((data, index) => {
            return (
              <Indicator key={index} data={data} />
            )
          })}
          {solvedRate &&
            <SolvedRate>Efectividad del Bot: {solvedRate}%</SolvedRate>
          }
        </WhiteCont>
      </BlueCont>
      <BlueCont>
        <EventDetailedHeader show={showDetails}>
          {showDetails &&
            <>
              <div>
                <AppSelect onChange={e => handleSelectChange(e.target.value)}>
                  <option value="">Filtrar por evento</option>
                  {eventsType.map((event, index) => {
                    return (
                      <option key={index} value={event}>{event}</option>
                    )
                  })}
                </AppSelect>
              </div>
              <div>
                <Input type="text" placeholder='Filtrar por número' onChange={e => setNumberInput(e.target.value)} />
              </div>
            </>
          }
          <AddResponseButton onClick={() => setShowDetails(!showDetails)}>
            <span>Mostrar eventos detallados</span>
            <i className="bx bxs-plus-circle" />
          </AddResponseButton>
        </EventDetailedHeader>

        {showDetails && eventsToRender.map((event, index) => {
          if (eventsToRender.length === index + 1) {
            return (
              <LastElement key={index} ref={lastElementRef}>
                <EventDetailed event={event} owner={owner} callMinuteTranscript={callMinuteTranscript[event.uniqueid]} />
              </LastElement>
            )
          }
          return (
            <EventDetailed key={index} event={event} owner={owner} callMinuteTranscript={callMinuteTranscript[event.uniqueid]} />
          )
        })}

      </BlueCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${lightBackgroundBlue};

`;
const Title = styled.h2`
  grid-column: 1 / -1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;

const WhiteCont = styled.div<IWhiteCont>`
  height: 100%;
  grid-column: ${props => props.grid ? props.grid : '1 / 3'};
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;

const GraphCont = styled.div`
  padding: 10px 10% 0;
`;
const SolvedRate = styled.div`
  margin: 20px 10px 0;
  font-weight: 500;
`;

const EventDetailedHeader = styled.div<IEventsCont>`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: ${props => props.show ? 'space-between' : 'flex-end'};

  &>div {
    margin-right: 20px;
  }
`;
const AppSelect = styled.select`
  width: 262px;
  margin: 8px 0;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const Input = styled.input`
  margin: 8px 0;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const AddResponseButton = styled.div`
margin: 11px 0;
  color: ${primary};
  display: flex;
  align-items: center;
  cursor: pointer;

  & > span {
    margin-right: 5px;
  }
  & > i {
    font-size: 1rem;
  }
`;
const LastElement = styled.div`
  grid-column: 1 / -1;
`;
interface IWhiteCont {
  grid?: string
}
interface IEventsCont {
  show: boolean
}
export default EventsByBotExtensionDetailed;