import React from 'react';
import styled from 'styled-components';

interface IToggleButton {
  toggle?: boolean;
  onClick: () => void;
}
const ToggleButton = ({ toggle = false, onClick }: IToggleButton) => {
  return (
    <Cont onClick={() => onClick()}>
      <Span toggle={toggle}>{`<`}</Span>
      <Span toggle={!toggle}>{`<`}</Span>
    </Cont>
  );
};
const Cont = styled.div`
  width: 30px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  top: -30px;
`;
const Span = styled.span<ISpan>`
  display: inline-block;
  transform: rotateY(${props => props.toggle && 180}deg);
  transition: transform 1s;
`;
interface ISpan {
  toggle: boolean;
}
export default ToggleButton;
