import PropTypes from 'prop-types';
import React from 'react';

const Editor = ({ children }) => {
  return <>{children}</>;
};

Editor.propTypes = {
  children: PropTypes.any,
};

export default Editor;
