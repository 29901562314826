//QUEUES
export const GET_QUEUES = 'GET_QUEUES';
export const GET_QUEUES_SUCCESS = 'GET_QUEUES_SUCCESS';
export const GET_QUEUES_FAIL = 'GET_QUEUES_FAIL';

//RECORDINGS
export const GET_RECORDINGS = 'GET_RECORDINGS';
export const GET_RECORDINGS_SUCCESS = 'GET_RECORDINGS_SUCCESS';
export const GET_RECORDINGS_FAIL = 'GET_RECORDINGS_FAIL';

// PAGINATION
export const GET_PAGINATION_NEXT = "GET_PAGINATION_NEXT"
export const GET_PAGINATION_SUCCESS = "GET_PAGINATION_SUCCESS"
export const GET_PAGINATION_FAIL = "GET_PAGINATIO_FAIL"

//Filter date
/* export const GET_RECORDINGS_BY_DATE = 'GET_RECORDINGS_BY_DATE';
export const GET_RECORDINGS_BY_DATE_SUCCESS = 'GET_RECORDINGS_BY_DATE_SUCCESS';
export const GET_RECORDINGS_BY_DATE_FAIL = 'GET_RECORDINGS_BY_DATE_FAIL'; */

//Filter 
export const GET_RECORDINGS_BY_FILTER = 'GET_RECORDINGS_BY_FILTER';
export const GET_RECORDINGS_BY_FILTER_SUCCESS = 'GET_RECORDINGS_BY_FILTER_SUCCESS';
export const GET_RECORDINGS_BY_FILTER_FAIL = 'GET_RECORDINGS_BY_FILTER_FAIL';

//Filters QUEUE
export const GET_RECORDINGS_BY_QUEUE = 'GET_RECORDINGS_BY_QUEUE';
export const GET_RECORDINGS_BY_QUEUE_SUCCESS = 'GET_RECORDINGS_BY_QUEUE_SUCCESS';
export const GET_RECORDINGS_BY_QUEUE_FAIL = 'GET_RECORDINGS_BY_QUEUE_FAIL';

// ALL Filters 
export const GET_RECORDINGS_BY_ALL_FILTERS = 'GET_RECORDINGS_BY_ALL_FILTERS';
export const GET_RECORDINGS_BY_ALL_FILTERS_SUCCESS = 'GET_RECAORDINGS_BY_ALL_FILTERS_SUCCESS';
export const GET_RECORDINGS_BY_ALL_FILTERS_FAIL = 'GET_RECORDINGS_BY_ALL_FILTERS_FAIL';

// LIMIT AND ORDER FILTERS

export const UPDATE_LIMIT = 'UPDATE_LIMIT' 
export const UPDATE_RECORDINGS_ORDER = 'UPDATE_RECORDINGS_ORDER' 
export const UPDATE_RECORDINGS_PAGINATION = 'UPDATE_RECORDINGS_PAGINATION'


export const FILTER_ADD_QUEUE = 'FILTER_ADD_QUEUE';
export const FILTER_REMOVE_QUEUE = 'FILTER_REMOVE_QUEUE';
export const FILTER_VALUE_UPDATE = 'FILTER_VALUE_UPDATE';

// GET recordings from report module

export const GET_RECORDINGS_FROM_REPORT_MODULE = 'GET_RECORDINGS_FROM_REPORT_MODULE'
export const GET_RECORDINGS_FROM_REPORT_MODULE_SUCCESS = 'GET_RECORDINGS_FROM_REPORT_MODULE_SUCCESS'
export const GET_RECORDINGS_FROM_REPORT_MODULE_FAIL = 'GET_RECORDINGS_FROM_REPORT_MODULE_FAIL'



// Eliminar luego de depurar el codigo

//
export const POST_FILTERS = 'POST_FILTERS';
export const POST_FILTERS_SUCCESS = 'POST_FILTERS_SUCCESS';
export const POST_FILTERS_FAIL = 'POST_FILTERS_FAIL';
