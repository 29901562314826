import { darkBorder } from 'library/colors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const AudioGraph = ({ phone, height = 0, width = 0, bars = 0 }) => {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

  const remoteAnalyser = audioCtx.createAnalyser();
  remoteAnalyser.minDecibels = -90;
  remoteAnalyser.maxDecibels = -10;
  remoteAnalyser.smoothingTimeConstant = 0.5;

  const localAnalizer = audioCtx.createAnalyser()
  localAnalizer.minDecibels = -90;
  localAnalizer.maxDecibels = -10;
  localAnalizer.smoothingTimeConstant = 0.5;

  let audioGraphAnimation;

  const remoteAudioBars = [];
  const localAudioBars = [];
  
  for (let i = 0; i < bars; i++) {
    remoteAudioBars.push(0);
    localAudioBars.push(0)
  }

  useEffect(() => {
    const canvas = document.querySelector('.visualizer');
    const canvasCtx = canvas.getContext('2d');

    const remoteStream = phone.remoteStream();
    const localStream = phone.localStream();

    const remoteSource = audioCtx.createMediaStreamSource(remoteStream);
    const localSource = audioCtx.createMediaStreamSource(localStream)

    remoteSource.connect(remoteAnalyser);
    localSource.connect(localAnalizer)

    function visualize() {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;

      remoteAnalyser.fftSize = 2048;
      localAnalizer.fftSize = 2048;

      let remoteBufferLengthAlt = remoteAnalyser.frequencyBinCount;
      let localBufferLengthAlt = localAnalizer.frequencyBinCount;
      
      let remoteDataArrayAlt = new Uint8Array(remoteBufferLengthAlt);
      let localDataArrayAlt = new Uint8Array(localBufferLengthAlt);

      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

      let drawRemote = function () {
        audioGraphAnimation = requestAnimationFrame(drawRemote);
         
        remoteAnalyser.getByteFrequencyData(remoteDataArrayAlt);
        localAnalizer.getByteFrequencyData(localDataArrayAlt)

        canvasCtx.fillStyle = 'rgb(255, 255, 255)';
        canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

        let barWidth = 3;
        let remoteBarHeight;
        let localBarHeight;
        let x = 0;
        
        if (remoteAudioBars.length < bars) {
          remoteAudioBars.push(remoteDataArrayAlt[0]);
          localAudioBars.push(localDataArrayAlt[0])
        } else {
          remoteAudioBars.shift();
          localAudioBars.shift();
          remoteAudioBars.push(remoteDataArrayAlt[0]);
          localAudioBars.push(localDataArrayAlt[0]);
        }

        for (let i = 0; i < bars; i++) {
          remoteBarHeight = remoteAudioBars[i] / 2;
          localBarHeight = localAudioBars[i] / 2;

          if (i % 2 === 0) {
            canvasCtx.fillStyle = 'rgb(' + (remoteBarHeight + 10) + ',201,57)';
            canvasCtx.fillRect(
              x,
              (HEIGHT - (remoteBarHeight + 2)) / 2,
              barWidth,
              remoteBarHeight + 2,
            );
          } else {
            canvasCtx.fillStyle = `${darkBorder}`;
            canvasCtx.fillRect(
              x,
              (HEIGHT - (localBarHeight + 2)) / 2,
              barWidth,
              localBarHeight + 2,
            );
          }
          x += barWidth ;
        }
      };
      drawRemote();
    }
    visualize();

    return () => {
      cancelAnimationFrame(audioGraphAnimation);
    }
  }, []);

  return (
      <Canvas className="visualizer" width={width} height={height}></Canvas>
  );
};
const Canvas = styled.canvas`
  background-color: blue;
  display: block;
`;
export default AudioGraph;
