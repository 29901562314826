import * as React from "react"

export const CallProperties = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M18.49 34.29h-4.16a.9.9 0 0 1-.89-.71L12.59 30a13.13 13.13 0 0 1-1.73-.72l-3.16 2a.91.91 0 0 1-1.13-.13l-3-3A.92.92 0 0 1 3.48 27l2-3.16a13.13 13.13 0 0 1-.72-1.73l-3.66-.87a.9.9 0 0 1-.71-.89v-4.16a.92.92 0 0 1 .71-.9l3.62-.85a13.59 13.59 0 0 1 .72-1.72l-2-3.16a.94.94 0 0 1 .13-1.14l3-2.95a.92.92 0 0 1 1.3 0 .94.94 0 0 1 0 1.3L5.42 9.21l1.86 3a.94.94 0 0 1 0 .93 10.15 10.15 0 0 0-1 2.33.91.91 0 0 1-.67.64l-3.44.81v2.7l3.44.81a.91.91 0 0 1 .67.64 10.15 10.15 0 0 0 1 2.33.94.94 0 0 1 0 .93l-1.86 3 1.93 1.93 3-1.86a.94.94 0 0 1 .93 0 10.15 10.15 0 0 0 2.33 1 .91.91 0 0 1 .64.67l.81 3.44h2.7l.81-3.51a.91.91 0 0 1 .64-.67 10.15 10.15 0 0 0 2.33-1 .94.94 0 0 1 .93 0l3 1.86 2.44-2.44a.92.92 0 0 1 1.3 0 .91.91 0 0 1 0 1.29l-2.95 3a.92.92 0 0 1-1.14.13l-3.16-2a13.59 13.59 0 0 1-1.72.72l-.85 3.62a.92.92 0 0 1-.9.78Zm-2.06-8.95a7.07 7.07 0 0 1-5-12.07.92.92 0 1 1 1.3 1.29A5.24 5.24 0 1 0 20.14 22a.91.91 0 0 1 1.29 0 .92.92 0 0 1 0 1.3 7 7 0 0 1-5 2.04Z"
    />
    <path
      fill="currentColor"
      d="M29.25 25.53a3.22 3.22 0 0 1-2.25-.94l-2.44-2.44a3.16 3.16 0 0 1-.94-2.27 3.2 3.2 0 0 1 .94-2.28l1.93-1.93a21.91 21.91 0 0 0-3-3.78 21.91 21.91 0 0 0-3.78-3l-1.93 1.93a3.23 3.23 0 0 1-4.55 0l-2.5-2.48a3.22 3.22 0 0 1 0-4.54L12 2.52C13.77.76 16.53.26 19.77 1.11a20.53 20.53 0 0 1 9 5.47c5.83 5.83 7.62 13.17 4.06 16.73l-1.28 1.28a3.22 3.22 0 0 1-2.3.94ZM19.5 6.78a.93.93 0 0 1 .5.12 23 23 0 0 1 4.77 3.69 23 23 0 0 1 3.69 4.77.92.92 0 0 1-.14 1.11l-2.48 2.43a1.38 1.38 0 0 0 0 2l2.44 2.44a1.38 1.38 0 0 0 1.95 0L31.51 22c1.27-1.28 1.6-3.41.92-6a18.67 18.67 0 0 0-5-8.15 18.73 18.73 0 0 0-8.15-5c-2.58-.68-4.71-.35-6 .92L12 5.09a1.38 1.38 0 0 0 0 2l2.44 2.44a1.38 1.38 0 0 0 2 0L18.85 7a1 1 0 0 1 .65-.22Z"
    />
  </svg>
)
