import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';

FileUpload.propTypes = {};

function FileUpload() {
  return (
    <div className="mt-4">
      <div>
        <div className="input-group">
          <Input type="file" className="form-control" id="inputGroupFile02" />
          <Label className="input-group-text" htmlFor="inputGroupFile02">
            Upload
          </Label>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
