import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PaginationMui from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';

import { getPaginationNextAction, updateRecordingsPaginationAction } from 'store/actions';
import LimitButtons from './limitButtons';
import OrderButtons from './order';

const Pagination = ({ owner }) => {
  const [count, setCount] = useState({
    currentCount: 20,
    lastPage: 1,
    keys: [],
  });

  const {LastKey, limit, page }= useSelector(state => state.recordings);
  const dispatch = useDispatch();

  const handlePaginationChange = (event, value) => {
    
    //si la pagina clicada es la misma se retorna
    if(page===value)return 
    
    //verifico si la pagina selecionada ya fue visitada y esta registrada
    const currentSelectionWasVisited = count.keys.filter(
      item => item.page === value,
    );

    //verifico si la pagina anterior ya esta registrada
    const lastPageWasVisited = count.keys.filter(
      item => item.page === count.lastPage,
    );

    //si la pagina selecionada fue visitada, envio la peticion con la key que esta almacenada
    if (currentSelectionWasVisited.length > 0) {
      dispatch(
        getPaginationNextAction(owner, currentSelectionWasVisited[0].key, limit),
      );
    } else {
      // si la pagina selecionada no fue visitada se envia la peticion con el last key
      dispatch(getPaginationNextAction(owner, LastKey, limit));
    }

    // si la pagina anterior ya fue registrada no se registra nuevamente en keys
    // pero se actualiza el lastPage con el valor actual
    if (lastPageWasVisited.length > 0) {
      setCount({ ...count, lastPage: value });
      return dispatch(updateRecordingsPaginationAction(value))
    } else {
      //si la pagina anterior no fue visitada se registra en keys
      //esta vez se actualiza completo
      const newCount = {
        currentCount: count.currentCount + 1,
        lastPage: value,
        keys: [...count.keys, { page: count.lastPage, key: LastKey }],
      };
      setCount(newCount);
      return dispatch(updateRecordingsPaginationAction(value))
    }
  };
  return (
    <Div>
      <ButtonsContainer>
      <LimitButtons />
      <OrderButtons />
      </ButtonsContainer>
      <PaginationMui
        shape="rounded"
        page={page}
        siblingCount={2}
        boundaryCount={2}
        count={count.currentCount}
        onChange={handlePaginationChange}
      ></PaginationMui>
    </Div>
  );
};
const Div = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;

`;
const ButtonsContainer = styled.div`
  display: flex;
`;
export default Pagination;
