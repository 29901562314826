import { IExecutionDayButton } from 'library/interfaces/campaignInterfaces';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SelectableButton from '../atoms/selectableButton';

interface IExecutionDaysButtonsProps {
  buttons: IExecutionDayButton[];
  handleSelectButton: Function;
}
const ExecutionDaysButtons: FC<IExecutionDaysButtonsProps> = ({
  buttons,
  handleSelectButton,
}) => {
  const {t} = useTranslation('outbound')
  return (
    <Cont>
      
      {buttons.map((button, index) => {
        return (
          <SelectableButton
            key={index}
            index={index}
            label={t(`campaigns.createCampaignForm.mainInfo.${button.execution_day}`)}
            isSelected={button.isSelected}
            onClick={handleSelectButton}
          />
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 50px;
`;
export default ExecutionDaysButtons;
