import { IChannel } from 'library/interfaces/channelsInterfaces';
import { IError } from 'store/reports/reducer';

export const GET_CHANNELS = 'GET_CHANNELS';
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';
export const GET_CHANNELS_FAIL = 'GET_CHANNELS_FAIL';

export const UPDATE_CHANNEL_SCENERY = 'UPDATE_CHANNEL_SCENERY';
export const UPDATE_CHANNEL_SCENERY_SUCCESS = 'UPDATE_CHANNEL_SCENERY_SUCCESS';
export const UPDATE_CHANNEL_SCENERY_FAIL = 'UPDATE_CHANNEL_SCENERY_FAIL';

export type ChannelActionTypes =
  | IGetChannels
  | IGetChannelsSuccess
  | IGetChannelsFail
  | IUpdateChannelScenery
  | IUpdateChannelScenerySuccess
  | IUpdateChannelSceneryFail;

export interface IGetChannels {
  type: typeof GET_CHANNELS;
}

export interface IGetChannelsSuccess {
  type: typeof GET_CHANNELS_SUCCESS;
  payload: { channels: IChannel[] };
}

export interface IGetChannelsFail {
  type: typeof GET_CHANNELS_FAIL;
  payload: { error: IError };
}

export interface IUpdateChannelScenery {
  type: typeof UPDATE_CHANNEL_SCENERY;
  payload: { channelId: string; sceneryId: string };
}

export interface IUpdateChannelScenerySuccess {
  type: typeof UPDATE_CHANNEL_SCENERY_SUCCESS;
  payload: { channelId: string; sceneryId: string };
}

export interface IUpdateChannelSceneryFail {
  type: typeof UPDATE_CHANNEL_SCENERY_FAIL;
  payload: { error: IError };
}
