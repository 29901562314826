import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

import NumericKeyboard from '../atoms/numericKeyboard';

import { darkBorder, lightGreen } from 'library/colors';
import Screen from '../atoms/numericKeyboardScreen';

import PhoneRoundButton from '../atoms/phoneRoundButton';
import { AnswerCall } from 'icons';
import { useTranslation } from 'react-i18next';

interface IPhoneKeyboard {
  dialedNumber: string;
  setDialedNumber: Function;
  isCall: boolean;
  onExitClick: Function;
  onCallButtonClick: Function;
  onBlindTranferButtonClick: Function;
}
const PhoneKeyboard: FC<IPhoneKeyboard> = ({
  isCall,
  dialedNumber,
  setDialedNumber,
  onExitClick,
  onCallButtonClick,
  onBlindTranferButtonClick
}) => {
  const {t} = useTranslation('workspace')
  const reference = useRef<HTMLInputElement>(null);

  const clickedNumber = (number: string) => {
    if (dialedNumber.length > 14) return;
    setDialedNumber(dialedNumber + number);
  };
  const deleteNumber = () => {
    const numbers = dialedNumber.slice(0, -1);
    setDialedNumber(numbers);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDialedNumber(value);
  };

  useEffect(() => {
    reference.current?.focus();
  }, []);
  return (
    <PhoneKeyboardCont>
      <Action>
        <span>{isCall ? t('phoneKeyboard.call') : t('phoneKeyboard.transfer')}</span>
        <Exit onClick={() => onExitClick()}>x</Exit>
      </Action>
      <Input
        type="text"
        ref={reference}
        value={dialedNumber}
        onChange={e => handleInputChange(e)}
      />
      <Screen dialedNumber={dialedNumber} deleteNumber={deleteNumber} />
      <NumericKeyboard onNumberClicked={clickedNumber} />
      {isCall && (
        <Button isCall={isCall} onClick={() => onCallButtonClick()}>
          <AnswerCall width={35}/>
        </Button>
      )}
      {!isCall && (
        <ButtonsCont>
          <PhoneRoundButton
            transfer
            onButtonClick={() => onBlindTranferButtonClick()}
          />
          <PhoneRoundButton onButtonClick={() => onCallButtonClick()} />
        </ButtonsCont>
      )}
    </PhoneKeyboardCont>
  );
};
const PhoneKeyboardCont = styled.div`
  max-width: 400px;
  margin: 0;
  color: black;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid ${lightGreen};
`;
const Action = styled.div`
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 20px;
  position: relative;
`;
const Exit = styled.span`
  padding: 0 15px;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 35px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;
const Input = styled.input`
  position: absolute;
  height: 35px;
  background-color: transparent;
  outline: none;
  border: none;
  color: transparent;
`;
const Button = styled.div<IButton>`
  max-width: 200px;
  height: 40px;
  margin: 0 auto 20px;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  color: #fff;
  background-color: ${props => (props.isCall ? lightGreen : darkBorder)};
`;
const ButtonsCont = styled.div`
  width: 150px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: space-between;
`;
interface IButton {
  isCall: boolean;
}
export default PhoneKeyboard;
