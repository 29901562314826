import { lightGreen } from 'library/colors';
import { Phone } from 'library/phone/phone';
import React, { FC } from 'react';
import styled from 'styled-components';

import PhoneRoundButton from '../atoms/phoneRoundButton';
import SelectableRoundButton from '../atoms/selectableRoundButton';

import { useDispatch, useSelector } from 'react-redux';
import {
  updateIsHeldAction,
  updateIsMutedAction,
  updateIsToMakeCallAction,
} from 'store/actions';
import IStore from 'library/interfaces/storeInterface';
import { IPhoneStore } from 'store/phone/reducer';
import { HoldCall, Message, MuteCall, Paper, TransferCall } from 'icons';

interface IPhoneHorizontalButtons {
  phone: Phone;
}
const PhoneHorizontalButtons: FC<IPhoneHorizontalButtons> = ({ phone }) => {
  const dispatch = useDispatch();
  const phoneStore = useSelector<IStore>(state => state.phone) as IPhoneStore;
  const isHeld = phone.isHeld()
  const hangUp = () => {
    phone.hangup();
  };
  const hold = () => {
    phone.hold();
    dispatch(updateIsHeldAction(true));
  };
  const unHold = () => {
    phone.unhold();
    dispatch(updateIsHeldAction(false));
  };
  const mute = () => {
    phone.mute();
    dispatch(updateIsMutedAction(true));
  };
  const unMute = () => {
    phone.unmute();
    dispatch(updateIsMutedAction(false));
  };
  const showTransferKeyboard = () => {
    if(!isHeld){
      hold()
    }
    dispatch(updateIsToMakeCallAction(false));
  };
  const hideTransferKeyboard = () => {
    if(isHeld){
      unHold()
    }
    dispatch(updateIsToMakeCallAction(undefined));
  };
  return (
    <Cont>
      <SelectableRoundButton
        onSelect={showTransferKeyboard}
        onDeselect={hideTransferKeyboard}
        isSelected={phoneStore.isToMakeACall === false ? true : false}
      >
        <TransferCall width={'60%'}/>
      </SelectableRoundButton>

      <SelectableRoundButton
        onSelect={mute}
        onDeselect={unMute}
        isSelected={phoneStore.isMuted}
      >
        <MuteCall width={'45%'}/>
      </SelectableRoundButton>

      {/* <SelectableRoundButton
        isSelected={false}
      >
        <Paper width={'45%'}/>
      </SelectableRoundButton> */}

      <SelectableRoundButton
        onSelect={hold}
        onDeselect={unHold}
        isSelected={phoneStore.isHeld}
      >
        <HoldCall width={'35%'}/>
      </SelectableRoundButton>

      {/* <SelectableRoundButton
        isSelected={false}
      >
        <Message width={'50%'}/>
      </SelectableRoundButton> */}

      <PhoneRoundButton red onButtonClick={hangUp} />
    </Cont>
  );
};
const Cont = styled.div`
  border-left: 1px solid ${lightGreen};
  max-width: 230px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
`;
export default PhoneHorizontalButtons;
