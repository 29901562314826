import InputDate from 'components/Recording/reports/atoms/InputDate';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { get } from 'helpers/api_helper';
import { darkBorder, lightBackgroundBlue, primary } from 'library/colors';
import { IQueue, IReportData } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';
import { report } from 'process';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportData } from 'store/actions';
import styled from 'styled-components';

interface IAsespQueueReportProps { }
const QueueReport: FC<IAsespQueueReportProps> = ({ }) => {
  const dispatch = useDispatch();
  const [selectedQueue, setSelectedQueue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [getReport, setGetReport] = useState<{
    isLoading: boolean;
    error: null | string;
    report: string;
  }>({
    isLoading: false,
    error: null,
    report: '',
  });
  const queues = useSelector<IStore, IQueue[]>(
    store => store.cdr.reportData.queues,
  );

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value)
  }
  const handleRequestReport = async () => {
    if (!selectedQueue || !startDate) return;
    setGetReport(prev => ({ ...prev, isLoading: true }));
    const requestReport: string = await get(
      `/asesp/queue/${selectedQueue}/report?from=${startDate}`,
    );

    if (!requestReport)
      return setGetReport(prev => ({ ...prev, isLoading: false }));

    setGetReport(prev => ({
      ...prev,
      isLoading: false,
      report: requestReport,
    }));
  };

  useEffect(() => {
    dispatch(getReportData());
  }, []);
  return (
    <Cont>
      <Title>Reporte de colas</Title>
      <BlueCont>
        <SelectCont>
          <Label margin="0px 10px 0 0">Selecciona la Cola: </Label>
          <Select
            onChange={e => setSelectedQueue(e.target.value)}
          >
            <option value="">
              Colas
            </option>
            {queues.map((queue, index) => {
              return (
                <option key={index} value={queue.id}>
                  {queue.name}
                </option>
              );
            })}
          </Select>
          <Label margin="0px 10px 0 0">Selecciona la fecha: </Label>
          <div>
            <InputDate
              type={'date'}
              name={'startDate'}
              value={startDate}
              handleChange={handleDateChange}
            />
          </div>

          {!getReport.isLoading && getReport.report ? (
            <a href={getReport.report} target="_blank" rel="noreferrer">
              <DownloadButton
                onClick={() => setGetReport(prev => ({ ...prev, report: '' }))}
              >
                Descargar
              </DownloadButton>
            </a>
          ) : (
            <Button
              disabled={getReport.isLoading}
              onClick={() => handleRequestReport()}
            >
              {getReport.isLoading ? (
                <>
                  Generando <LoadingPoints />
                </>
              ) : (
                'Reporte'
              )}
            </Button>
          )}
        </SelectCont>
      </BlueCont>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;
const BlueCont = styled(Cont)`
  margin-top: 20px;
  background-color: ${lightBackgroundBlue};
`;
const Title = styled.h2`
  grid-column: 1/-1;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;

  & > b {
    color: black;
    display: inline-block;
    width: 140px;
  }
`;
const SelectCont = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.label<ILabel>`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
interface ILabel {
  margin?: string;
}
const Select = styled.select`
  flex-grow: 1;
  max-width: 100px;
  margin-right: 20px;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const Button = styled.button`
  width: 120px;
  height: 30px;
  font-weight: 600;
  margin-left: 20px;
  color: ${props => (props.disabled ? 'white' : primary)};
  border: 2px solid ${props => (props.disabled ? darkBorder : primary)};
  border-radius: 50px;
  background-color: ${props => (props.disabled ? darkBorder : 'transparent')};
`;
const DownloadButton = styled(Button)`
  color: white;
  background-color: ${primary};
`;
export default QueueReport;
