import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ITextProps {
  children: ReactNode
  danger?: ReactNode
}
const Text: FC<ITextProps> = ({ children, danger }) => {
  return (
    <Cont danger={danger}>{children}</Cont>
  );
};
const Cont = styled.p<ICont>`
  font-weight: 300;
  color: ${props => props.danger && 'red'};
`;
interface ICont {
  danger: ReactNode
}
export default Text;