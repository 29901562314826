import {
  ICreateWACampaign,
  ICreateWACampaignContact,
  IGupshupApp,
  IGupshupAppTemplate,
  IWaCampaign,
  IWaCampaignActions,
} from 'library/interfaces/whatsappCampaignInterfaces';
import { IError } from 'store/reports/reducer';
import {
  changeWACampaignStatusActions,
  changeWACampaignStatusFail,
  changeWACampaignStatusSuccess,
  GET_WA_CAMPAIGNS,
  GET_WA_CAMPAIGNS_FAIL,
  GET_WA_CAMPAIGNS_SUCCESS,
  GET_WA_CAMPAIGN_BY_ID,
  GET_WA_CAMPAIGN_BY_ID_FAIL,
  GET_WA_CAMPAIGN_BY_ID_SUCCESS,
  IGetWACampaignById,
  IGetWACampaignByIdFail,
  IGetWACampaignByIdSuccess,
  IGetWACampaigns,
  IGetWACampaignsFail,
  IGetWACampaignsSuccess,
  IPostWACampaign,
  IPostWACampaignFail,
  IPostWACampaignSuccess,
  IPostWAContactGroups,
  IPostWAContactGroupsFail,
  IPostWAContactGroupsSuccess,
  IPostWAContactsFinish,
  IPutChangeWACampaignStatus,
  IPutChangeWACampaignStatusFail,
  IPutChangeWACampaignStatusSuccess,
  POST_WA_CAMPAIGN,
  POST_WA_CAMPAIGN_FAIL,
  POST_WA_CAMPAIGN_SUCCESS,
  POST_WA_CONTACTS_FINISHED,
  POST_WA_CONTACT_GROUP,
  POST_WA_CONTACT_GROUP_FAIL,
  POST_WA_CONTACT_GROUP_SUCCESS,
  PUT_RUN_WA_CAMPAIGN,
  PUT_STOP_WA_CAMPAIGN,
  IGetGupshupAppList,
  GET_GUPSHUP_APP_LIST,
  IGetGupshupAppListSuccess,
  GET_GUPSHUP_APP_LIST_SUCCESS,
  IGetGupshupAppListFail,
  GET_GUPSHUP_APP_LIST_FAIL,
  IGetGupshupAppTemplates,
  GET_GUPSHUP_APP_TEMPLATES,
  IGetGupshupAppTemplatesSuccess,
  GET_GUPSHUP_APP_TEMPLATES_SUCCESS,
  IGetGupshupAppTemplatesFail,
  GET_GUPSHUP_APP_TEMPLATES_FAIL,
  IDeleteWaCampaign,
  DELETE_WA_CAMPAIGN,
  IDeleteWaCampaignFail,
  DELETE_WA_CAMPAIGN_FAIL,
  IDeleteWaCampaignSuccess,
  DELETE_WA_CAMPAIGN_SUCCESS,
  IPutWaCampaignColor,
  PUT_WA_CAMPAIGN_COLOR,
  IPutWaCampaignColorSuccess,
  PUT_WA_CAMPAIGN_COLOR_SUCCESS,
  IPutWaCampaignColorFail,
  PUT_WA_CAMPAIGN_COLOR_FAIL,
} from './actionTypes';

export const getWACampaignsAction = (): IGetWACampaigns => ({
  type: GET_WA_CAMPAIGNS,
});

export const getWACampaignsSuccess = (
  waCampaigns: IWaCampaign[],
): IGetWACampaignsSuccess => ({
  type: GET_WA_CAMPAIGNS_SUCCESS,
  payload: { waCampaigns },
});

export const getWACampaignsFail = (error: IError): IGetWACampaignsFail => ({
  type: GET_WA_CAMPAIGNS_FAIL,
  payload: { error },
});

export const getWACampaignByIdAction = (
  waCampaignId: string,
): IGetWACampaignById => ({
  type: GET_WA_CAMPAIGN_BY_ID,
  payload: { waCampaignId },
});

export const getWACampaignByIdSuccess = (
  waCampaignId: string,
  waCampaign: IWaCampaign,
): IGetWACampaignByIdSuccess => ({
  type: GET_WA_CAMPAIGN_BY_ID_SUCCESS,
  payload: { waCampaignId, waCampaign },
});

export const getWACampaignByIdFail = (
  waCampaignId: string,
  error: IError,
): IGetWACampaignByIdFail => ({
  type: GET_WA_CAMPAIGN_BY_ID_FAIL,
  payload: { waCampaignId, error },
});

export const postWACampaignAction = (
  waCampaign: ICreateWACampaign,
): IPostWACampaign => ({
  type: POST_WA_CAMPAIGN,
  payload: { waCampaign },
});

export const postWACampaignSuccess = (
  waCampaign: IWaCampaign,
): IPostWACampaignSuccess => ({
  type: POST_WA_CAMPAIGN_SUCCESS,
  payload: { waCampaign },
});

export const postWACampaignFail = (error: IError): IPostWACampaignFail => ({
  type: POST_WA_CAMPAIGN_FAIL,
  payload: { error },
});

export const WAContactGroupsAction = (
  waCampaignId: string,
  contacts: ICreateWACampaignContact[][],
): IPostWAContactGroups => ({
  type: POST_WA_CONTACT_GROUP,
  payload: { waCampaignId, contacts },
});

export const WAContactGroupsSuccess = (
  waCampaignId: string,
  loadPercentage: number,
): IPostWAContactGroupsSuccess => ({
  type: POST_WA_CONTACT_GROUP_SUCCESS,
  payload: { waCampaignId, loadPercentage },
});

export const WAContactGroupsFail = (
  waCampaignId: string,
  loadPercentage: number,
  contactGroupOfError: ICreateWACampaignContact[],
): IPostWAContactGroupsFail => ({
  type: POST_WA_CONTACT_GROUP_FAIL,
  payload: { waCampaignId, loadPercentage, contactGroupOfError },
});

export const postWAContactsFinished = (
  waCampaignId: string,
): IPostWAContactsFinish => ({
  type: POST_WA_CONTACTS_FINISHED,
  payload: { waCampaignId },
});

export const putChangeWACampaignStatusAction = (
  action: IWaCampaignActions,
  waCampaignId: string,
): IPutChangeWACampaignStatus => {
  const actionType: changeWACampaignStatusActions =
    action === 'run' ? PUT_RUN_WA_CAMPAIGN : PUT_STOP_WA_CAMPAIGN;
  return {
    type: actionType,
    payload: { action, waCampaignId, actionType },
  };
};

export const putChangeWACampaignStatusSuccess = (
  waCampaignId: string,
  waCampaign: IWaCampaign,
  actionType: changeWACampaignStatusSuccess,
): IPutChangeWACampaignStatusSuccess => ({
  type: actionType,
  payload: { waCampaignId, waCampaign },
});

export const putChangeWACampaignStatusFail = (
  waCampaignId: string,
  error: IError,
  actionType: changeWACampaignStatusFail,
): IPutChangeWACampaignStatusFail => ({
  type: actionType,
  payload: { waCampaignId, error },
});

export const deleteWaCampaignAction = (
  waCampaignId: string,
): IDeleteWaCampaign => ({
  type: DELETE_WA_CAMPAIGN,
  payload: { waCampaignId },
});

export const deleteWaCampaignSuccess = (
  waCampaignId: string,
  waCampaign: IWaCampaign,
): IDeleteWaCampaignSuccess => ({
  type: DELETE_WA_CAMPAIGN_SUCCESS,
  payload: { waCampaignId, waCampaign },
});

export const deleteWaCampaignFail = (
  waCampaignId: string,
  error: IError,
): IDeleteWaCampaignFail => ({
  type: DELETE_WA_CAMPAIGN_FAIL,
  payload: { waCampaignId, error },
});

export const putWaCampaignColorAction = (
  waCampaignId: string,
  color: string,
): IPutWaCampaignColor => ({
  type: PUT_WA_CAMPAIGN_COLOR,
  payload: { waCampaignId, color },
});

export const putWaCampaignColorSuccess = (
  waCampaignId: string,
  waCampaign: IWaCampaign,
): IPutWaCampaignColorSuccess => ({
  type: PUT_WA_CAMPAIGN_COLOR_SUCCESS,
  payload: { waCampaignId, waCampaign },
});

export const putWaCampaignColorFail = (
  waCampaignId: string,
  error: IError,
): IPutWaCampaignColorFail => ({
  type: PUT_WA_CAMPAIGN_COLOR_FAIL,
  payload: { waCampaignId, error },
});

export const getGupshupAppListAction = (): IGetGupshupAppList => ({
  type: GET_GUPSHUP_APP_LIST,
});

export const getGupshupAppListSuccess = (
  appList: IGupshupApp[],
): IGetGupshupAppListSuccess => ({
  type: GET_GUPSHUP_APP_LIST_SUCCESS,
  payload: { appList },
});

export const getGupshupAppListFail = (
  error: IError,
): IGetGupshupAppListFail => ({
  type: GET_GUPSHUP_APP_LIST_FAIL,
  payload: { error },
});

export const getGupshupAppTemplatesAction = (
  appId: string,
): IGetGupshupAppTemplates => ({
  type: GET_GUPSHUP_APP_TEMPLATES,
  payload: { appId },
});

export const getGupshupAppTemplatesSuccess = (
  templates: IGupshupAppTemplate[],
): IGetGupshupAppTemplatesSuccess => ({
  type: GET_GUPSHUP_APP_TEMPLATES_SUCCESS,
  payload: { templates },
});

export const getGupshupAppTemplatesFail = (
  error: IError,
): IGetGupshupAppTemplatesFail => ({
  type: GET_GUPSHUP_APP_TEMPLATES_FAIL,
  payload: { error },
});
