import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SelectableButton from '../atoms/selectableButton';

interface ISelectableButtonFilterProps {
  SelectableButtons: {
    isSelected: boolean;
    label: string;
  }[];
  handleSelectableButtonClick: Function;
}
const SelectableButtonFilter: FC<ISelectableButtonFilterProps> = ({
  SelectableButtons,
  handleSelectableButtonClick
}) => {
  const {t} = useTranslation('reports')
  return (
    <Cont>
      <Label >{t('defaultDates')}</Label>
      <FlexCont>
      {SelectableButtons.map((button, index) => {
        return (
          <SelectableButton
            key={index}
            index={index}
            isSelected={button.isSelected}
            onClick={handleSelectableButtonClick}
          >
            {t(`${button.label}`)}
          </SelectableButton>
        );
      })}
      </FlexCont>
    </Cont>
  );
};
const Cont = styled.div`
 
`;
const Label = styled.div`
    font-size: 0.7rem;
    font-weight: 500;
    text-align: center;
    transform: translateY(-9px);
`;
const FlexCont = styled.div`
  display: flex;
  align-items: center;
`;
export default SelectableButtonFilter;
