import { Completed, Draft, Megaphone, Pause, Phone } from 'icons';
import { ISMSCampaign } from 'library/interfaces/smsCampaignInterface';
import IStore from 'library/interfaces/storeInterface';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSMSCampaignsAction } from 'store/actions';
import { ISMSCampaignStore } from 'store/outboundCampaigns/smsCampaigns/reducer';
import { ICampaign } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import styled from 'styled-components';
import LeftMenuOutbound from '../molecules/leftMenu';
import SearchAndButton from '../molecules/searchAndButton';
import SMSCampaign from '../smsCampaigns/organisms/smsCampaign';
import SMSCampaignsLoadingContactsCont from '../smsCampaigns/organisms/smsCampaignsLoadingContactsCont';

interface ISMSCampaignTemplateProps {
  setShowSMSCampaignForm: (
    show: boolean,
    // TODO: change ICampaign to ISMSCampaign
    campaignToEdit?: null | ICampaign,
    campaignIndexToUpdate?: null | ICampaign,
  ) => void;
}
const SMSCampaignTemplate: FC<ISMSCampaignTemplateProps> = ({setShowSMSCampaignForm}) => {
  const dispatch = useDispatch()

  const campaigns = useSelector<IStore, ISMSCampaign[]>(store=>store.smsCampaigns.campaigns)

  const [smsCampaignFilter, setSMSCampaignFilter] = useState('');

  const smsCampaignsToRender = campaigns.filter(campaign =>
    smsCampaignFilter ? campaign.status === smsCampaignFilter : campaign,
  );


  const handleCreateSMSCamgpaignButtonClick = () => {
    setShowSMSCampaignForm(true);
  };
  useEffect(()=>{
    if(campaigns.length > 0) return
    dispatch(getSMSCampaignsAction())
  }, [])
  return (
    <Cont>
      <SearchAndButton onButtonClick={handleCreateSMSCamgpaignButtonClick} />
      <FlexCont>
        <LeftMenuOutbound filterButtons={buttons} setCampaignFilter={setSMSCampaignFilter}/>
        <CampaignsCont>
          <SMSCampaignsLoadingContactsCont />
          {smsCampaignsToRender.map((campaign, index)=>{
            return (
              <SMSCampaign 
              key={index}
              smsCampaign={campaign}
              />
            )
          })}
        </CampaignsCont>
      </FlexCont>
    </Cont>
  );
};
const Cont = styled.div`
  margin: 0 20px;
`;
const FlexCont = styled.div`
  display: flex;
`;
const CampaignsCont = styled.div`
  flex-grow: 1;
  /* border: 1px solid red; */
`;
//
// filter buttons 
//
const buttons = [
  {
    isSelected: true,
    icon: < Megaphone />,
    value: '',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.all' },
  },
  {
    isSelected: false,
    icon: < Draft width={15}/>,
    value: 'created',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.draft' },
  },
  {
    isSelected: false,
    icon: < Phone width={17}/>,
    value: 'running',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.ongoing' },
  },
  {
    isSelected: false,
    icon: < Pause width={25} style={{transform: 'translateX(-5px)'}}/>,
    value: 'stopped',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.pause' },
  },
  {
    isSelected: false,
    icon: < Completed width={18}/>,
    value: 'completed',
    i18nKeys: { namespace: 'outbound', key: 'campaigns.leftMenu.completed' },
  },
]
export default SMSCampaignTemplate;
