/* GENERAL */
export const AGENT_ACTION_FAIL = "AGENT_ACTION_FAIL"

/* AGENTS */
export const GET_AGENTS = "GET_AGENTS"
export const GET_AGENTS_SUCCESS = "GET_AGENTS_SUCCESS"
export const GET_USER = "GET_USER"
export const GET_AGENT_SUCCESS = "GET_AGENT_SUCCESS"
export const ADD_NEW_AGENT = "ADD_NEW_AGENT"
export const ADD_AGENT_SUCCESS = "ADD_AGENT_SUCCESS"
export const UPDATE_AGENT = "UPDATE_AGENT"
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS"
export const DELETE_AGENT = "DELETE_AGENT"
export const DELETE_AGENT_SUCCESS = "DELETE_AGENT_SUCCESS"