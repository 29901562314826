import * as React from 'react';

export const SceneCheck = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M110.484 11.929h3.336c7.41 0 13.418 6.005 13.418 13.415v76.948c0 7.407-6.008 13.415-13.418 13.415H36.875c-7.41 0-13.415-6.008-13.415-13.415V25.344c0-7.41 6.005-13.415 13.415-13.415h4.702M51.889 11.929h47.675"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit={10}
      strokeWidth={4.8448}
    />
    <path
      d="M47.904 20.046h-2.12a4.096 4.096 0 0 1-4.096-4.096V6.515a4.094 4.094 0 0 1 4.096-4.097h2.12A4.094 4.094 0 0 1 52 6.515v9.435a4.096 4.096 0 0 1-4.096 4.096zM106.112 20.046h-2.12a4.096 4.096 0 0 1-4.096-4.096V6.515a4.094 4.094 0 0 1 4.096-4.097h2.12a4.094 4.094 0 0 1 4.096 4.097v9.435a4.096 4.096 0 0 1-4.096 4.096zM23.726 34.016h103.446M49.435 66.946h-9.837a4.228 4.228 0 0 1-4.23-4.227v-9.84a4.228 4.228 0 0 1 4.23-4.227h9.837a4.226 4.226 0 0 1 4.227 4.227v9.84a4.226 4.226 0 0 1-4.227 4.227zM80.367 66.946h-9.838a4.228 4.228 0 0 1-4.23-4.227v-9.84a4.228 4.228 0 0 1 4.23-4.227h9.838a4.226 4.226 0 0 1 4.227 4.227v9.84a4.226 4.226 0 0 1-4.227 4.227zM80.367 101.539h-9.838a4.228 4.228 0 0 1-4.23-4.227v-9.84a4.228 4.228 0 0 1 4.23-4.227h9.838a4.226 4.226 0 0 1 4.227 4.227v9.84a4.226 4.226 0 0 1-4.227 4.227zM111.3 101.539h-9.837a4.228 4.228 0 0 1-4.23-4.227v-9.84a4.228 4.228 0 0 1 4.23-4.227h9.838a4.226 4.226 0 0 1 4.227 4.227v9.84a4.226 4.226 0 0 1-4.227 4.227z"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit={10}
      strokeWidth={4.8448}
    />
    <path
      d="m98.898 55.304 5.988 8.316 12.306-12.972"
      fill="none"
      stroke="#231f20"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={4.8448}
    />
    <path
      d="M29.92 77.709c15.072 0 27.288 12.219 27.288 27.288 0 15.073-12.216 27.291-27.289 27.291S2.631 120.07 2.631 104.998c0-15.07 12.216-27.289 27.288-27.289"
      fill="#7c9904"
    />
    <path
      d="m17.641 101.95 7.783 10.811 16-16.865"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={4.8448}
    />
  </svg>
);
