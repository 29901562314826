import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_BACK_AMAZONAWS;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

// TODO: remove the data argument from this function
// data is wrong here and headers are not being sending by that
export async function getAws(url, data, config = {}) { 
  const token = localStorage.getItem("auth0_token")
  config.headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With"
  }
  return await axiosApi.get(url, { ...data }, { ...config }).then(response => response.data);
}

export async function postAws(url, data, config = {}) {
  const token = localStorage.getItem("auth0_token")
  config.headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With"
  }
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function postAwsWithoutDataDestructuring(url, data, config = {}) {
  const token = localStorage.getItem("auth0_token")
  config.headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With"
  }
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data);
}

export async function putAws(url, data, config = {}) {
  const token = localStorage.getItem("auth0_token")
  config.headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With"
  }
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function delAws(url, config = {}) {
  const token = localStorage.getItem("auth0_token")
  config.headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With"
  }
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
