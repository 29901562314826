import * as React from 'react';

export const Search = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M53.348 20.57c-18.277 0-33.144 14.868-33.144 33.145a3.727 3.727 0 1 0 7.455 0c0-14.166 11.523-25.69 25.69-25.69a3.727 3.727 0 0 0 0-7.455" />
    <path d="M12.383 53.729c0-22.595 18.384-40.98 40.98-40.98s40.979 18.385 40.979 40.98-18.384 40.98-40.98 40.98-40.979-18.385-40.979-40.98m116.578 58.676L98.61 82.054c5.156-8.222 8.157-17.926 8.157-28.325C106.767 24.28 82.811.324 53.362.324S-.042 24.28-.042 53.73s23.955 53.405 53.404 53.405a53.087 53.087 0 0 0 28.934-8.551l30.244 30.244c2.191 2.191 5.077 3.287 7.956 3.287s5.758-1.096 7.95-3.287l.515-.516c4.383-4.383 4.383-11.523 0-15.906" />
  </svg>
);
