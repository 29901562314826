import { call, put, takeEvery } from 'redux-saga/effects';

import {
  GET_QUEUES,
  GET_RECORDINGS,
  GET_PAGINATION_NEXT,
  POST_FILTERS,
  GET_RECORDINGS_BY_FILTER,
  GET_RECORDINGS_BY_QUEUE,
  GET_RECORDINGS_BY_ALL_FILTERS,
  GET_RECORDINGS_FROM_REPORT_MODULE,
} from './actionTypes';

import {
  getPaginationFail,
  getPaginationSuccess,
  getQueuesFail,
  getQueuesSuccess,
  getRecordingsByAllFiltersFail,
  getRecordingsByAllFiltersSuccess,
  getRecordingsByFilterFail,
  getRecordingsByFilterSuccess,
  getRecordingsByQueueFail,
  getRecordingsByQueueSuccess,
  getRecordingsFail,
  getRecordingsFromReportModuleFail,
  getRecordingsFromReportModuleSuccess,
  getRecordingsSuccess,
  postFiltersFail,
  postFiltersSuccess,
} from './actions';

import {
  getPaginationNext,
  getQueues,
  getRecordings,
  getRecordingsByAllFilters,
  getRecordingsByFilter,
  getRecordingsByQueue,
  getRecordingsFromReportModule,
  //getRecordingsFilterDate,
  postFilters,
} from 'helpers/fakebackend_helper';

function* onGetQueues() {
  try {
    const response = yield call(getQueues);
    yield put(getQueuesSuccess(response));
  } catch (error) {
    yield put(getQueuesFail(error));
  }
}

function* onGetRecordings({ payload: owner }) {
  let limit;
  try {
    if (owner === 'corporacion' || owner === 'CORPORACION') {
      owner = 'fcb7592c-04a0-494e-89f1-2a1bcf7d993f';
    } else if (owner === 'asesp' || owner === 'ASESP') {
      limit = 100;
    }
    const response = yield call(getRecordings, owner, limit);
    yield put(getRecordingsSuccess(response));
  } catch (error) {
    yield put(getRecordingsFail(error));
  }
}
function* onGetPaginationNext({ payload: reqData }) {
  try {
    let limit = reqData.limit;
    if (reqData.owner === 'corporacion') {
      reqData.owner = 'fcb7592c-04a0-494e-89f1-2a1bcf7d993f';
    } else if (reqData.owner === 'asesp' || reqData.owner === 'ASESP') {
      limit = 100;
    }
    const response = yield call(
      getPaginationNext,
      reqData.owner,
      reqData.LastKey,
      limit,
    );
    yield put(getPaginationSuccess(response));
  } catch (error) {
    yield put(getPaginationFail(error));
  }
}

/* function* onGetRecordingFilterByDate({ payload: reqData }) {
  try {
    const response = yield call(
      getRecordingsFilterDate,
      reqData.owner,
      reqData.start,
      reqData.end,
      reqData.startTime,
      reqData.endTime,
      reqData.limit,
      reqData.order,
    );
    yield put(getRecordingsFilterByDateSuccess(response));
  } catch (error) {
    yield put(getRecordingsFilterByDateFail(error));
  }
} */

function* onGetRecordingByFilter({ payload: reqData }) {
  try {
    let limit = reqData.limit;
    if (reqData.owner === 'corporacion') {
      reqData.owner = 'fcb7592c-04a0-494e-89f1-2a1bcf7d993f';
    } else if (reqData.owner === 'asesp' || reqData.owner === 'ASESP') {
      limit = 100;
    }
    const response = yield call(
      getRecordingsByFilter,
      reqData.owner,
      reqData.startDate,
      reqData.endDate,
      reqData.filter,
      reqData.value,
      limit,
      reqData.order,
    );
    yield put(getRecordingsByFilterSuccess(response));
  } catch (error) {
    yield put(getRecordingsByFilterFail(error));
  }
}
function* onGetRecordingByQueue({ payload: reqData }) {
  try {
    let limit = reqData.limit;
    if (reqData.owner === 'corporacion') {
      reqData.owner = 'fcb7592c-04a0-494e-89f1-2a1bcf7d993f';
    } else if (reqData.owner === 'asesp' || reqData.owner === 'ASESP') {
      limit = 100;
    }
    const response = yield call(
      getRecordingsByQueue,
      reqData.owner,
      reqData.queue,
      limit,
      reqData.order,
    );
    yield put(getRecordingsByQueueSuccess(response));
  } catch (error) {
    yield put(getRecordingsByQueueFail(error));
  }
}
function* onGetRecordingsByAllFilters({ payload: reqData }) {
  try {
    let limit = reqData.limit;
    if (reqData.owner === 'corporacion') {
      reqData.owner = 'fcb7592c-04a0-494e-89f1-2a1bcf7d993f';
    } else if (reqData.owner === 'asesp' || reqData.owner === 'ASESP') {
      limit = 100;
    }
    const response = yield call(
      getRecordingsByAllFilters,
      reqData.owner,
      reqData.queue,
      reqData.startDate,
      reqData.endDate,
      reqData.filter,
      reqData.value,
      limit,
      reqData.order,
    );
    yield put(getRecordingsByAllFiltersSuccess(response));
  } catch (error) {
    yield put(getRecordingsByAllFiltersFail(error));
  }
}

function* onGetRecordingsFromReportModule({ payload: reqData }) {
  try {
    let limit = reqData.limit;
    if (reqData.owner === 'corporacion') {
      reqData.owner = 'fcb7592c-04a0-494e-89f1-2a1bcf7d993f';
    } else if (reqData.owner === 'asesp' || reqData.owner === 'ASESP') {
      limit = 100;
    }
    const response = yield call(
      getRecordingsFromReportModule,
      reqData.owner.toLowerCase(),
      limit,
      reqData.order.toUpperCase(),
      reqData.start_date,
      reqData.end_date,
      reqData.filters,
    );
    yield put(getRecordingsFromReportModuleSuccess(response));
  } catch (error) {
    yield put(getRecordingsFromReportModuleFail(error));
  }
}

//// Revisar luego de depurar
function* onPostFilters({ payload: filters }) {
  try {
    const response = yield call(postFilters, filters);
    yield put(postFiltersSuccess(response));
  } catch (error) {
    yield put(postFiltersFail(error));
  }
}

function* recordingsSaga() {
  yield takeEvery(GET_QUEUES, onGetQueues);
  yield takeEvery(GET_RECORDINGS, onGetRecordings);
  yield takeEvery(GET_PAGINATION_NEXT, onGetPaginationNext);
  //yield takeEvery(GET_RECORDINGS_BY_DATE, onGetRecordingFilterByDate);
  yield takeEvery(GET_RECORDINGS_BY_FILTER, onGetRecordingByFilter);
  yield takeEvery(GET_RECORDINGS_BY_QUEUE, onGetRecordingByQueue);
  yield takeEvery(GET_RECORDINGS_BY_ALL_FILTERS, onGetRecordingsByAllFilters);
  yield takeEvery(
    GET_RECORDINGS_FROM_REPORT_MODULE,
    onGetRecordingsFromReportModule,
  );
  yield takeEvery(POST_FILTERS, onPostFilters);
}

export default recordingsSaga;
