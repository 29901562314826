import { call, put, takeEvery } from "redux-saga/effects"

import { cdrActionFail, getAgentServiceLevelSuccess, getLiveDataSuccess, getReportDataSuccess, getSummarizeDataSuccess } from "./actions"
import { GET_AGENTS_SERVICE_LEVEL, GET_LIVE_DATA, GET_REPORT_DATA, GET_SUMMARIZE_DATA } from "./actionTypes"

import { 
  getReportData,
  getSummarizeData,
  getLiveData,
  getAgentsServiceLevel,
} from "../../helpers/fakebackend_helper"

function* onGetSummarizeData({ payload: {owner, period, timezone, queues} }) {
  try {
    //console.log('onGetSummarizeData: ', {owner, period, timezone, queues})

    const data = yield call(getSummarizeData, owner, period, timezone, queues);    
    yield put(getSummarizeDataSuccess(data))
  } catch (error) {
    yield put(cdrActionFail(
      {
        "error": "Error al crear obtener los datos",
        "errorDetails": error?.message
      })
    )
  }
}

function* onGetLiveData({ payload: {owner, queues} }) {
  try {
    //console.log('onGetLiveData: ', {owner, queues})
    const data = yield call(getLiveData, owner, queues);    
    yield put(getLiveDataSuccess(data))
  } catch (error) {
    yield put(cdrActionFail(
      {
        "error": "Error al crear obtener los datos",
        "errorDetails": error?.message
      })
    )
  }
}

function* onGetReportData() {
  try {
    const data = yield call(getReportData);
    yield put(getReportDataSuccess(data))
  } catch (error) {
    yield put(cdrActionFail(
      {
        "error": "Error al crear obtener los datos",
        "errorDetails": error?.message
      })
    )
  }
}

function* onGetAgentsServiceLevel({payload: { owner, startDate, endDate }}) {
  try {
    const data = yield call(getAgentsServiceLevel, owner.toLowerCase(), startDate, endDate)
    yield put(getAgentServiceLevelSuccess(data))
  } catch (error) {
    yield put(cdrActionFail(
      {
        "error": "Error al crear obtener el nivel de servicio de los agentes",
        "errorDetails": error?.message
      })
    )
  }
}

function* cdrSaga() {
  yield takeEvery(GET_SUMMARIZE_DATA, onGetSummarizeData)
  yield takeEvery(GET_LIVE_DATA, onGetLiveData)
  yield takeEvery(GET_REPORT_DATA, onGetReportData)
  yield takeEvery(GET_AGENTS_SERVICE_LEVEL, onGetAgentsServiceLevel)
}

export default cdrSaga;