import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  inline: PropTypes.bool.isRequired,
};

export function Radio({ name, label, inline, ...props }) {
  const [field] = useField(name);

  return (
    <>
      <div className={`form-check ${inline && 'form-check-inline'} mb-3`}>
        <input
          className="form-check-input"
          type="radio"
          name={name}
          id={props.value}
          checked={field.value === props.value}
          {...field}
          {...props}
        />
        <label className="form-check-label" htmlFor={props.value}>
          {label}
        </label>
      </div>
      <ErrorMessage name={name} component="span" />
    </>
  );
}
