import { BlueContModalSC, InputModalSC, InputVerticalGroupModalSC, LabelModalSC, SectionTitleModalSC, SelectModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';
import { lightBackgroundBlue, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { FC } from 'react';
import { Node } from 'reactflow';
import styled from 'styled-components';

interface IDatabasesModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
}
const DatabasesModal: FC<IDatabasesModalProps> = ({ selectedNode, setSelectedNode }) => {

  const databases = useAppSelector(store => store.databases.databases)

  const { data } = selectedNode;

  const handleKeyChange = (value: string) => {
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        database: {
          tableId: selectedNode.data.database?.tableId || '',
          key: value
        }
      },
    };
    setSelectedNode(editedNode);
  }

  const handleSelectDatabase = (value: string) => {

    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        database: {
          key: selectedNode.data.database?.key || '',
          tableId: value,
        }
      },
    };
    setSelectedNode(editedNode);
  }

  return (
    <BlueContModalSC>
      <SectionTitleModalSC>
        Base de Datos
      </SectionTitleModalSC>
      <InputVerticalGroupModalSC>
        <LabelModalSC htmlFor="key">Key</LabelModalSC>
        <InputModalSC
          type="text"
          id="key"
          name="key"
          value={data?.database?.key}
          placeholder='Asigna un nombre único para la base de datos'
          onChange={e => handleKeyChange(e.target.value)}
        />
      </InputVerticalGroupModalSC>
      <SelectModalSC value={data.database?.tableId} onChange={e => handleSelectDatabase(e.target.value)}>
        <option value="">Select a database</option>
        {databases.map((dat, index) => {
          return (
            <option key={index} value={dat.id}>{dat.name}</option>
          )
        })}
      </SelectModalSC>
    </BlueContModalSC>
  );
};

export default DatabasesModal;