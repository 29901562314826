import {
  blueGraph,
  border,
  darkBorder,
  lightBackgroundBlue,
  primary,
} from 'library/colors';
import { ISMSCampaignActions } from 'library/interfaces/smsCampaignInterface';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  deleteCampaignAction,
  putUpdateCampaignStatusAction,
} from 'store/actions';
import {
  campaignAction,
  PUT_PAUSE_CAMPAIGN,
  PUT_PUBLISH_CAMPAIGN,
  PUT_RESUME_CAMPAIGN,
  PUT_TERMINATE_CAMPAIGN,
} from 'store/outboundCampaigns/voiceCampaigns/actionTypes';
import { ICampaign, ICampaignStatus } from 'store/outboundCampaigns/voiceCampaigns/reducer';
import styled from 'styled-components';
import DownloadReportButton from '../atoms/downloadReportButton';
import LoadingPoints from '../atoms/loadingPoints';
import StateMenuOption from '../atoms/stateMenuOption';

interface IStateAndButtonProps {
  campaign: ICampaign;
  campaignIndex: number;
  showCampaignForm: (
    showForm: boolean,
    campaignToEdit?: ICampaign,
    campaignIndexToUpdate?: number,
  ) => void;
  setShowCampaignDetails: (show: boolean) => void;
  onViewReportClick: () => void;
}
const StateAndButton: FC<IStateAndButtonProps> = ({
  campaign,
  campaignIndex,
  showCampaignForm,
  setShowCampaignDetails,
  onViewReportClick,
}) => {
  const { t } = useTranslation('outbound');
  const dispatch = useDispatch();
  const [showActionsMenu, setShowActionsMenu] = useState(false);

  const IS_AN_INTERMEDIATE_STATE =
    campaign.status.endsWith('ing') && campaign.status !== 'running';

  const STATUS_COLOR =
    campaign.status === 'running'
      ? primary
      : campaign.status === 'paused'
      ? 'orange'
      : campaign.status === 'completed'
      ? blueGraph
      : lightBackgroundBlue;

  const handleShowActionsMenu = () => {
    setShowActionsMenu(!showActionsMenu);
  };

  // this show the tags count report
  const handleViewReportClic = () => {
    onViewReportClick();
  };

  const handleUpdateStatus = (newStatus: string): void => {
    const allowedActions: campaignAction[] = ['publish', 'pause', 'resume', 'terminate', 'delete'];
    if (!allowedActions.includes(newStatus as campaignAction)) return
    setShowActionsMenu(false);

    const ACTION =
      newStatus === 'publish'
        ? PUT_PUBLISH_CAMPAIGN
        : newStatus === 'pause'
        ? PUT_PAUSE_CAMPAIGN
        : newStatus === 'resume'
        ? PUT_RESUME_CAMPAIGN
        : PUT_TERMINATE_CAMPAIGN;

    dispatch(
      putUpdateCampaignStatusAction(
        campaign.id,
        campaignIndex,
        newStatus as campaignAction,
        ACTION,
      ),
    );
  };

  const handleDeleteCampaign = (action: string) => {
    if (action !== 'delete') return;
    dispatch(deleteCampaignAction(campaign.id, campaignIndex));
    setShowActionsMenu(false);
  };
  const handleCloneCampaign = () => {
    showCampaignForm(true, campaign);
    setShowActionsMenu(false);
  };

  const handleUpdateCampaign = () => {
    showCampaignForm(true, campaign, campaignIndex);
    setShowActionsMenu(false);
  };

  const handleShowDetails = () => {
    setShowCampaignDetails(true);
    setShowActionsMenu(false);
  };

  return (
    <Cont>
      <State bgColor={STATUS_COLOR} showMessage={IS_AN_INTERMEDIATE_STATE}>
        {t(`campaigns.campaign.${campaign.status}`)}{' '}
        {IS_AN_INTERMEDIATE_STATE && <LoadingPoints />}
        <WaitingMessage>
          {t('campaigns.campaign.waitingMessage.0')}
          <br />
          {t('campaigns.campaign.waitingMessage.1')}
        </WaitingMessage>
      </State>
      <DownloadReportButton campaignId={campaign.id} reportType={4}>
        {t('campaigns.campaign.report')}
      </DownloadReportButton>
      <MenuButton id="showActionsMenu" onClick={() => handleShowActionsMenu()}>
        ...
      </MenuButton>
      {showActionsMenu && (
        <Menu>
          <StateMenuOption
            label={t('campaigns.campaign.menu.showDetails')}
            action="publish"
            onActionClick={handleShowDetails}
          />
          <StateMenuOption
            label={t('campaigns.campaign.menu.clone')}
            action="publish"
            onActionClick={handleCloneCampaign}
          />
          {campaign.status === 'draft' && (
            <>
              {/* <StateMenuOption
                label={t('campaigns.campaign.menu.edit')}
                action="publish"
                onActionClick={handleUpdateCampaign}
              /> */}

              <StateMenuOption
                label={t('campaigns.campaign.menu.publish')}
                action="publish"
                onActionClick={handleUpdateStatus}
              />
              <StateMenuOption
                label={t('campaigns.campaign.menu.delete')}
                action="delete"
                onActionClick={handleDeleteCampaign}
              />
            </>
          )}
          {campaign.status === 'running' && (
            <StateMenuOption
              label={t('campaigns.campaign.menu.pause')}
              action="pause"
              onActionClick={handleUpdateStatus}
            />
          )}
          {campaign.status === 'paused' && (
            <StateMenuOption
              label={t('campaigns.campaign.menu.resume')}
              action="resume"
              onActionClick={handleUpdateStatus}
            />
          )}
          {(campaign.status === 'paused' || campaign.status === 'running') && (
            <StateMenuOption
              label={t('campaigns.campaign.menu.terminate')}
              action="terminate"
              onActionClick={handleUpdateStatus}
            />
          )}
        </Menu>
      )}
    </Cont>
  );
};
const Cont = styled.div`
  margin-bottom: 20px;
  display: flex;
  position: relative;
`;
const State = styled.div<IState>`
  width: 100px;
  height: 30px;
  margin: 0 10px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  cursor: default;
  user-select: none;
  color: ${props => props.bgColor !== lightBackgroundBlue && 'white'};
  background-color: ${props => props.bgColor};
  position: relative;

  &:hover > :last-child {
    display: ${props => props.showMessage && 'block'};
    opacity: ${props => props.showMessage && 1};
  }
`;
const WaitingMessage = styled.div`
  position: absolute;
  border: 1px solid;
  width: 280px;
  padding: 5px;
  font-size: 0.7rem;
  line-height: 15px;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: ${lightBackgroundBlue};
  border: 1px solid ${darkBorder};
  box-shadow: 0 0px 5px ${primary};

  opacity: 0;
  transition: opacity 0.5s;
`;

const MenuButton = styled.div`
  width: 40px;
  height: 30px;
  margin-left: 5px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 35%;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;
const Menu = styled.div`
  position: absolute;
  right: 0;
  top: 35px;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  border-radius: 5px;
  background-color: ${lightBackgroundBlue};
  border: 1px solid ${darkBorder};
  box-shadow: 0 0px 5px ${primary};
`;
interface IState {
  bgColor: string;
  showMessage: boolean;
}
export default StateAndButton;
