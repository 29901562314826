import {
  ICreateWACampaign,
  ICreateWACampaignContact,
  IGupshupApp,
  IGupshupAppTemplate,
  IWaCampaign,
  IWaCampaignActions,
} from 'library/interfaces/whatsappCampaignInterfaces';
import { IError } from 'store/reports/reducer';

export const GET_WA_CAMPAIGNS = 'GET_WA_CAMPAIGNS';
export const GET_WA_CAMPAIGNS_SUCCESS = 'GET_WA_CAMPAIGNS_SUCCESS';
export const GET_WA_CAMPAIGNS_FAIL = 'GET_WA_CAMPAIGNS_FAIL';

export const GET_WA_CAMPAIGN_BY_ID = 'GET_WA_CAMPAIGN_BY_ID';
export const GET_WA_CAMPAIGN_BY_ID_SUCCESS = 'GET_WA_CAMPAIGN_BY_ID_SUCCESS';
export const GET_WA_CAMPAIGN_BY_ID_FAIL = 'GET_WA_CAMPAIGN_BY_ID_FAIL';

export const POST_WA_CAMPAIGN = 'POST_WA_CAMPAIGN';
export const POST_WA_CAMPAIGN_SUCCESS = 'POST_WA_CAMPAIGN_SUCCESS';
export const POST_WA_CAMPAIGN_FAIL = 'POST_WA_CAMPAIGN_FAIL';

export const POST_WA_CONTACT_GROUP = 'POST_WA_CONTACT_GROUP';
export const POST_WA_CONTACT_GROUP_SUCCESS = 'POST_WA_CONTACT_GROUP_SUCCESS';
export const POST_WA_CONTACT_GROUP_FAIL = 'POST_WA_CONTACT_GROUP_FAIL';
export const POST_WA_CONTACTS_FINISHED = 'POST_WA_CONTACTS_FINISHED';

export const PUT_RUN_WA_CAMPAIGN = 'PUT_RUN_WA_CAMPAIGN';
export const PUT_RUN_WA_CAMPAIGN_SUCCESS = 'PUT_RUN_WA_CAMPAIGN_SUCCESS';
export const PUT_RUN_WA_CAMPAIGN_FAIL = 'PUT_RUN_WA_CAMPAIGN_FAIL';

export const PUT_STOP_WA_CAMPAIGN = 'PUT_STOP_WA_CAMPAIGN';
export const PUT_STOP_WA_CAMPAIGN_SUCCESS = 'PUT_STOP_WA_CAMPAIGN_SUCCESS';
export const PUT_STOP_WA_CAMPAIGN_FAIL = 'PUT_STOP_WA_CAMPAIGN_FAIL';

export const DELETE_WA_CAMPAIGN = 'DELETE_WA_CAMPAIGN';
export const DELETE_WA_CAMPAIGN_SUCCESS = 'DELETE_WA_CAMPAIGN_SUCCESS';
export const DELETE_WA_CAMPAIGN_FAIL = 'DELETE_WA_CAMPAIGN_FAIL';

export const PUT_WA_CAMPAIGN_COLOR = 'PUT_WA_CAMPAIGN_COLOR';
export const PUT_WA_CAMPAIGN_COLOR_SUCCESS = 'PUT_WA_CAMPAIGN_COLOR_SUCCESS';
export const PUT_WA_CAMPAIGN_COLOR_FAIL = 'PUT_WA_CAMPAIGN_COLOR_FAIL';

export const GET_GUPSHUP_APP_LIST = 'GET_GUPSHUP_APP_LIST';
export const GET_GUPSHUP_APP_LIST_SUCCESS = 'GET_GUPSHUP_APP_LIST_SUCCESS';
export const GET_GUPSHUP_APP_LIST_FAIL = 'GET_GUPSHUP_APP_LIST_FAIL';

export const GET_GUPSHUP_APP_TEMPLATES = 'GET_GUPSHUP_APP_TEMPLATES';
export const GET_GUPSHUP_APP_TEMPLATES_SUCCESS =
  'GET_GUPSHUP_APP_TEMPLATES_SUCCESS';
export const GET_GUPSHUP_APP_TEMPLATES_FAIL = 'GET_GUPSHUP_APP_TEMPLATES_FAIL';

export type WACampaignsActionTypes =
  | IGetWACampaigns
  | IGetWACampaignsSuccess
  | IGetWACampaignsFail
  | IGetWACampaignById
  | IGetWACampaignByIdSuccess
  | IGetWACampaignByIdFail
  | IPostWACampaign
  | IPostWACampaignSuccess
  | IPostWACampaignFail
  | IPostWAContactGroups
  | IPostWAContactGroupsSuccess
  | IPostWAContactGroupsFail
  | IPostWAContactsFinish
  | IPutChangeWACampaignStatus
  | IPutChangeWACampaignStatusSuccess
  | IPutChangeWACampaignStatusFail
  | IDeleteWaCampaign
  | IDeleteWaCampaignSuccess
  | IDeleteWaCampaignFail
  | IPutWaCampaignColor
  | IPutWaCampaignColorSuccess
  | IPutWaCampaignColorFail
  | IGetGupshupAppList
  | IGetGupshupAppListSuccess
  | IGetGupshupAppListFail
  | IGetGupshupAppTemplates
  | IGetGupshupAppTemplatesSuccess
  | IGetGupshupAppTemplatesFail;

export type changeWACampaignStatusActions =
  | typeof PUT_RUN_WA_CAMPAIGN
  | typeof PUT_STOP_WA_CAMPAIGN;

export type changeWACampaignStatusSuccess =
  | typeof PUT_RUN_WA_CAMPAIGN_SUCCESS
  | typeof PUT_STOP_WA_CAMPAIGN_SUCCESS;

export type changeWACampaignStatusFail =
  | typeof PUT_RUN_WA_CAMPAIGN_FAIL
  | typeof PUT_STOP_WA_CAMPAIGN_FAIL;

export interface IGetWACampaigns {
  type: typeof GET_WA_CAMPAIGNS;
}

export interface IGetWACampaignsSuccess {
  type: typeof GET_WA_CAMPAIGNS_SUCCESS;
  payload: { waCampaigns: IWaCampaign[] };
}

export interface IGetWACampaignsFail {
  type: typeof GET_WA_CAMPAIGNS_FAIL;
  payload: { error: IError };
}

export interface IGetWACampaignById {
  type: typeof GET_WA_CAMPAIGN_BY_ID;
  payload: { waCampaignId: string };
}

export interface IGetWACampaignByIdSuccess {
  type: typeof GET_WA_CAMPAIGN_BY_ID_SUCCESS;
  payload: { waCampaignId: string; waCampaign: IWaCampaign };
}

export interface IGetWACampaignByIdFail {
  type: typeof GET_WA_CAMPAIGN_BY_ID_FAIL;
  payload: { waCampaignId: string; error: IError };
}

export interface IPostWACampaign {
  type: typeof POST_WA_CAMPAIGN;
  payload: { waCampaign: ICreateWACampaign };
}

export interface IPostWACampaignSuccess {
  type: typeof POST_WA_CAMPAIGN_SUCCESS;
  payload: { waCampaign: IWaCampaign };
}

export interface IPostWACampaignFail {
  type: typeof POST_WA_CAMPAIGN_FAIL;
  payload: { error: IError };
}

export interface IPostWAContactGroups {
  type: typeof POST_WA_CONTACT_GROUP;
  payload: { waCampaignId: string; contacts: ICreateWACampaignContact[][] };
}

export interface IPostWAContactGroupsSuccess {
  type: typeof POST_WA_CONTACT_GROUP_SUCCESS;
  payload: { waCampaignId: string; loadPercentage: number };
}

export interface IPostWAContactGroupsFail {
  type: typeof POST_WA_CONTACT_GROUP_FAIL;
  payload: {
    waCampaignId: string;
    loadPercentage: number;
    contactGroupOfError: ICreateWACampaignContact[];
  };
}

export interface IPostWAContactsFinish {
  type: typeof POST_WA_CONTACTS_FINISHED;
  payload: { waCampaignId: string };
}

export interface IPutChangeWACampaignStatus {
  type: changeWACampaignStatusActions;
  payload: {
    action: IWaCampaignActions;
    waCampaignId: string;
    actionType: changeWACampaignStatusActions;
  };
}

export interface IPutChangeWACampaignStatusSuccess {
  type: changeWACampaignStatusSuccess;
  payload: { waCampaignId: string; waCampaign: IWaCampaign };
}

export interface IPutChangeWACampaignStatusFail {
  type: changeWACampaignStatusFail;
  payload: { waCampaignId: string; error: IError };
}

export interface IDeleteWaCampaign {
  type: typeof DELETE_WA_CAMPAIGN;
  payload: { waCampaignId: string };
}

export interface IDeleteWaCampaignSuccess {
  type: typeof DELETE_WA_CAMPAIGN_SUCCESS;
  payload: { waCampaignId: string; waCampaign: IWaCampaign };
}

export interface IDeleteWaCampaignFail {
  type: typeof DELETE_WA_CAMPAIGN_FAIL;
  payload: { waCampaignId: string; error: IError };
}

export interface IPutWaCampaignColor {
  type: typeof PUT_WA_CAMPAIGN_COLOR;
  payload: { waCampaignId: string; color: string };
}

export interface IPutWaCampaignColorSuccess {
  type: typeof PUT_WA_CAMPAIGN_COLOR_SUCCESS;
  payload: { waCampaignId: string; waCampaign: IWaCampaign };
}
export interface IPutWaCampaignColorFail {
  type: typeof PUT_WA_CAMPAIGN_COLOR_FAIL;
  payload: { waCampaignId: string; error: IError };
}

export interface IGetGupshupAppList {
  type: typeof GET_GUPSHUP_APP_LIST;
}

export interface IGetGupshupAppListSuccess {
  type: typeof GET_GUPSHUP_APP_LIST_SUCCESS;
  payload: { appList: IGupshupApp[] };
}

export interface IGetGupshupAppListFail {
  type: typeof GET_GUPSHUP_APP_LIST_FAIL;
  payload: { error: IError };
}

export interface IGetGupshupAppTemplates {
  type: typeof GET_GUPSHUP_APP_TEMPLATES;
  payload: { appId: string };
}

export interface IGetGupshupAppTemplatesSuccess {
  type: typeof GET_GUPSHUP_APP_TEMPLATES_SUCCESS;
  payload: { templates: IGupshupAppTemplate[] };
}

export interface IGetGupshupAppTemplatesFail {
  type: typeof GET_GUPSHUP_APP_TEMPLATES_FAIL;
  payload: { error: IError };
}
