import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchFile from '../organisms/searchFile';
import GalleryItem from '../organisms/galerryItem';
import { post } from 'helpers/api_helper';
import GalleryUploadFilePopUp from './galleryUploadFile';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { getAllFilesAction } from 'store/files/asyncActions';
import { useAppSelector } from 'library/hooks/useAppSelector';

interface IGalleryProps {
  setShowGallery: (show: boolean) => void;

}
const Gallery: FC<IGalleryProps> = ({ setShowGallery }) => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useAppDispatch()
  const { files } = useAppSelector(store => store.files)

  useEffect(() => {
    dispatch(getAllFilesAction())
  }, [])

  return (
    <>
      <Cont>
        <SearchFile
          setShow={setShowForm}
          onButtonClick={() => setShowGallery(false)}
          buttonKeys={{ nameSpace: 'outbound', key: 'waCampaign.backToCampaigns' }}
          inputKeys={{ nameSpace: 'outbound', key: 'waCampaign.searchFile' }} />
        <FilesCont
        >
          {files.map((item, index) => {
            if (item.filename.endsWith('.mp3')) return
            return (
              <GalleryItem key={index} index={index} fileId={item.id} filename={item.filename} url={item.url} />
            )
          })}
        </FilesCont>
      </Cont>
      {showForm && <GalleryUploadFilePopUp setShow={setShowForm} />}
    </>
  );
};
const Cont = styled.div`
  margin: 0 20px;
  padding: 0 ;
`;
const FilesCont = styled.div`
  /* border: 1px solid; */
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
`;
const Input = styled.input`
  position: absolute;
  display: block;
  width: 100vh;
  height: 100%;
`;
export default Gallery;

const DATA = [
  {
    "id": "casmu-uploadpng-DxTSQ86d5",
    "filename": "upload.png",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-uploadpng-DxTSQ86d5",
    "timestamp": "2023-08-28T21:13:05.260Z"
  },
  {
    "id": "casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "filename": "SMARTBOT_Software_2022_INBOUND_9 (1).pdf",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "timestamp": "2023-08-28T22:24:19.517Z"
  },
  {
    "id": "casmu-uploadpng-DxTSQ86d5",
    "filename": "upload.png",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-uploadpng-DxTSQ86d5",
    "timestamp": "2023-08-28T21:13:05.260Z"
  },
  {
    "id": "casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "filename": "SMARTBOT_Software_2022_INBOUND_9 (1).pdf",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "timestamp": "2023-08-28T22:24:19.517Z"
  },
  {
    "id": "casmu-uploadpng-DxTSQ86d5",
    "filename": "upload.png",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-uploadpng-DxTSQ86d5",
    "timestamp": "2023-08-28T21:13:05.260Z"
  },
  {
    "id": "casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "filename": "SMARTBOT_Software_2022_INBOUND_9 (1).pdf",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "timestamp": "2023-08-28T22:24:19.517Z"
  },
  {
    "id": "casmu-uploadpng-DxTSQ86d5",
    "filename": "upload.png",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-uploadpng-DxTSQ86d5",
    "timestamp": "2023-08-28T21:13:05.260Z"
  },
  {
    "id": "casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "filename": "SMARTBOT_Software_2022_INBOUND_9 (1).pdf",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "timestamp": "2023-08-28T22:24:19.517Z"
  },
  {
    "id": "casmu-uploadpng-DxTSQ86d5",
    "filename": "upload.png",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-uploadpng-DxTSQ86d5",
    "timestamp": "2023-08-28T21:13:05.260Z"
  },
  {
    "id": "casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "filename": "SMARTBOT_Software_2022_INBOUND_9 (1).pdf",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "timestamp": "2023-08-28T22:24:19.517Z"
  },
  {
    "id": "casmu-uploadpng-DxTSQ86d5",
    "filename": "upload.png",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-uploadpng-DxTSQ86d5",
    "timestamp": "2023-08-28T21:13:05.260Z"
  },
  {
    "id": "casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "filename": "SMARTBOT_Software_2022_INBOUND_9 (1).pdf",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "timestamp": "2023-08-28T22:24:19.517Z"
  },
  {
    "id": "casmu-uploadpng-DxTSQ86d5",
    "filename": "upload.png",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-uploadpng-DxTSQ86d5",
    "timestamp": "2023-08-28T21:13:05.260Z"
  },
  {
    "id": "casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "filename": "SMARTBOT_Software_2022_INBOUND_9 (1).pdf",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "timestamp": "2023-08-28T22:24:19.517Z"
  },
  {
    "id": "casmu-uploadpng-DxTSQ86d5",
    "filename": "upload.png",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-uploadpng-DxTSQ86d5",
    "timestamp": "2023-08-28T21:13:05.260Z"
  },
  {
    "id": "casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "filename": "SMARTBOT_Software_2022_INBOUND_9 (1).pdf",
    "url": "https://smartbot-public-files.s3.amazonaws.com/casmu-smartbotsoftware2022inbound91pdf-VALiqZDFB",
    "timestamp": "2023-08-28T22:24:19.517Z"
  },
]