import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import NodeModal from '../common/NodeModal';

import EditInputSettings from './EditInputSettings';
import EditOutputSettings from './EditOutputSettings';
import EditResponses from './EditResponses';
import ViewInputSettings from './ViewInputSettings';
import ViewOutputSettings from './ViewOutputSettings';
import ViewResponses from './ViewResponses';
import ViewEditContainer from '../../ViewEditContainer';
import ViewEditArrayContainer from '../../ViewEditArrayContainer';

const initialValues = {
  port: '',
  responseKey: '',
  possibleResponses: [],
};

function Modal({ Icon, data, toggle, isOpen }) {
  const [value, setValue] = useState(data);

  const handleNewResponse = () => {
    console.log('handleNewResponse', data);
    setValue(old => {
      const responseRoutes = old.responseRoutes ? old.responseRoutes : [];
      const newArray = [...responseRoutes, { id: uuidv4(), ...initialValues }];
      data.responseRoutes = newArray;

      return data;
    });
  };

  const handleRemoveResponse = idx => {
    setValue(old => {
      const updatedArray = old.responseRoutes.filter(
        (_, index) => index !== idx,
      );
      data.responseRoutes = updatedArray;

      return data;
    });
  };

  return (
    <NodeModal Icon={Icon} data={data} isOpen={isOpen} toggle={toggle}>
      <ViewEditContainer
        data={data}
        View={ViewOutputSettings}
        Edit={EditOutputSettings}
      />
      <ViewEditContainer
        data={data}
        View={ViewInputSettings}
        Edit={EditInputSettings}
      />

      <ViewEditArrayContainer
        title="Responses"
        View={ViewResponses}
        Edit={EditResponses}
        addText="Add Response"
        data={value}
        newRow={handleNewResponse}
        removeRow={handleRemoveResponse}
        name="responseRoutes"
      />
    </NodeModal>
  );
}

Modal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  Icon: PropTypes.any,
};

export default Modal;
