import React, { useState } from 'react';
import { Button } from 'reactstrap';

const QueueButton = ({ index, queue, handleIsActive }) => {



  const handleClick = () => {
    handleIsActive(index)
  };
  return (
    <Button
      color={queue.isActive ? 'secondary' : ''}
      className="btn-rounded border me-1"
      onClick={() => handleClick()}
    >
      {queue.isActive && <i className="bx bx-check me-1" />}
      {queue && queue.name}
    </Button>
  );
};
export default QueueButton;
