import { darkBorder } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IGoBackButtonProps {
  isExtended: boolean;
  handleGoBackButtonClick: () => void;

}
const GoBackButton: FC<IGoBackButtonProps> = ({ isExtended, handleGoBackButtonClick }) => {
  return (
    <Cont isExtended={isExtended} onClick={() => handleGoBackButtonClick()}>
      <i className="bx bx-arrow-back"></i>
      Volver
    </Cont>
  );
};
const Cont = styled.div<IButton>`
position: absolute;
/* color: #fff; */
top: 50px;
left: 12px;
width: ${props => (props.isExtended ? '120px' : '40px')};
  height: 40px;
  padding: 4px 15px;
  padding-left: ${props => (props.isExtended ? '15px' : '7px')};
  border-radius: 50px;
  z-index: 1000;

  display: flex;
  align-items: center;
  background-color: ${darkBorder};
  transition: width 1s, padding 1s;
  overflow: hidden;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;

  & > i {
    font-size: 1.5rem;
    margin: 0 6px 0 2px;
  }

`;
interface IButton {
  isExtended: boolean;
}
export default GoBackButton;