import { IChatBotStore } from './reducer';
import {
  GET_CHATBOT_BY_CALL,
  GET_CHATBOT_BY_CALL_FAIL,
  GET_CHATBOT_BY_CALL_SUCCESS,
  IGetChatBotByCall,
  IGetChatBotByCallFail,
  IGetChatBotByCallSuccess,
  IUpdateCallStatus,
  IUpdateCallStatusFail,
  IUpdateCallStatusSuccess,
  UPDATE_ASESP_CALL_STATUS,
  UPDATE_ASESP_CALL_STATUS_FAIL,
  UPDATE_ASESP_CALL_STATUS_SUCCESS,
} from './actionTypes';
import { IUpdateAsespCallStatus } from 'library/interfaces/asespCallStatus';

export const getChatBotByCallAction = (
  owner: string,
  uniqueId: string,
): IGetChatBotByCall => ({
  type: GET_CHATBOT_BY_CALL,
  payload: {
    owner: owner,
    uniqueId: uniqueId,
  },
});

export const getChatBotByCallSuccess = (
  chatBot: IChatBotStore,
): IGetChatBotByCallSuccess => ({
  type: GET_CHATBOT_BY_CALL_SUCCESS,
  payload: chatBot,
});

export const getChatBotByCallFail = (error: string): IGetChatBotByCallFail => ({
  type: GET_CHATBOT_BY_CALL_FAIL,
  payload: error,
});

export const updateAsespCallStatusAction = (
  callStatus: IUpdateAsespCallStatus,
): IUpdateCallStatus => ({
  type: UPDATE_ASESP_CALL_STATUS,
  payload: { callStatus },
});

export const updateAsespCallStatusSuccess = (
): IUpdateCallStatusSuccess => ({
  type: UPDATE_ASESP_CALL_STATUS_SUCCESS,
});
export const updateAsespCallStatusFail = (
): IUpdateCallStatusFail => ({
  type: UPDATE_ASESP_CALL_STATUS_FAIL,
});
