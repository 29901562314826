import { lightBackgroundBlue, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { IDatabase, IDatabaseRow } from 'library/interfaces/databasesInterfaces';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

interface IDatabaseTableProps {
  currentDb: IDatabase
  deleteIds: { id: string | number }[];
  setSelectedRow: (row: IDatabaseRow | null, index: number) => void
  handleDeleteCheckboxChange: (id: string | number, checked: boolean) => void

}
const DatabaseTable: FC<IDatabaseTableProps> = ({ currentDb, deleteIds, setSelectedRow, handleDeleteCheckboxChange }) => {
  const { schema } = currentDb
  const { rows } = useAppSelector(store => store.databases.selectedDatabase)

  const handleRowDoubleClick = (row: IDatabaseRow, index: number) => {
    const selectedRow = structuredClone(row)
    setSelectedRow(selectedRow, index)
  }

  return (
    <Table>
      <thead>
        <tr>
          <Eliminar>Eliminar</Eliminar>
          {schema.map((item, index) => {
            return (
              <th key={index}>{item.name}</th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index} >
            <td>
              <Input type="checkbox"
                checked={deleteIds.some(id => id.id === row['id'])}
                onChange={e => handleDeleteCheckboxChange(row['id'], e.target.checked)}
              />
            </td>
            {schema.map((item, idx) => {
              return (
                <td key={idx} onDoubleClick={() => handleRowDoubleClick(row, index)}>{row[item.key]}</td>
              )
            })}
          </tr>
        ))}
      </tbody>

    </Table>
  );
};

const Table = styled.table`
width: 100%;
  table-layout: auto;

  thead {
    position: sticky;
    top: -21px;
    line-height: 30px;
    background-color: ${lightBackgroundBlue};
  }
  
  td, th {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: left;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
td{
  background-color: white;
}
`;
const Eliminar = styled.th`
  width: 50px;
`;
const Input = styled.input`
display: block;
  margin:  0 auto;
`;
export default DatabaseTable;