import { primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IWaMessageTemplateContProps {
  message: string;
}
const WaMessageTemplateCont: FC<IWaMessageTemplateContProps> = ({
  message,
}) => {
  const regEx = /(\{\{[^}]+\}\})/g;
  const splitedMessage = message.split(regEx);
  return (
    <Cont>
      {splitedMessage.map((msg, index) => {
        if (msg.startsWith('{{') && msg.endsWith('}}')) {
          return <B key={index}>{msg}</B>;
        }
        return <span key={index}>{msg}</span>;
      })}
    </Cont>
  );
};
const Cont = styled.div`
  /* margin: 20px 0; */
  /* max-height: 200px; */
  flex-grow: 1;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid ${primary};
  background-color: white;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const B = styled.b`
  /* font-weight: 500; */
`;
export default WaMessageTemplateCont;
