import SubTitle from 'components/LiveDashboard/atoms/subTitle';
import { lightBackground, secondary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import AgentLiveConversation from '../molecules/agent';
import { getAgentsConnectedToWaAction } from 'store/actions';
import { useAppDispatch } from 'library/hooks/useAppDispatch';

interface IConversationAsideProps { }
const ConversationAside: FC<IConversationAsideProps> = ({ }) => {

  const dispatch = useAppDispatch()

  const { total, totalConnected, totalUnconnected, activeAgents } = useAppSelector(store => store.liveConversations.agents)

  const availableDeg = Math.round((totalConnected / total) * 360);
  const unavailableDeg = availableDeg + Math.round((totalUnconnected / total) * 360);

  useEffect(() => {
    // TODO
    // dispatch(getAgentsConnectedToWaAction('2de2af98-6148-4f3b-b7f8-4ab4e2737dc7'))
  }, [])
  return (
    <AsideCont>
      <SubTitleWithPadding>
        <SubTitle>Total de agentes &middot; {total}</SubTitle>
      </SubTitleWithPadding>

      <FlexCont>
        <Container>
          <Donut available={availableDeg} unavailable={unavailableDeg} />
        </Container>

        <ListCont donutGraph>
          <SubTitle circleColor="#85ce5f">Conectados &middot; {totalConnected}</SubTitle>
          <SubTitle circleColor={secondary}>Desconectados &middot; {totalUnconnected}</SubTitle>
        </ListCont>

      </FlexCont>

      <AgentsCont>
        {activeAgents.map((agent, index) => {
          return <AgentLiveConversation agent={agent} key={index} />
        })}
      </AgentsCont>
    </AsideCont>
  );
};
const AsideCont = styled.div`
   border-radius: 10px;
   padding: 10px 0;
  background-color: ${lightBackground};
`;
const SubTitleWithPadding = styled.div`
  padding: 0 10px;
`;
const FlexCont = styled.div`
  padding: 3px 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${secondary};
`;
const Container = styled.div`
  width: 90px;
  height: 90px;
  position: relative;
`;
const Donut = styled.div<IDonut>`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  transition: background-image 1s;
  background-image: conic-gradient(#85ce5f 0 ${props => props.available}deg, ${secondary} 0 ${props => props.unavailable}deg, ${secondary} 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    transparent calc(100% - 15px),
    red 42px
  );
  mask: radial-gradient(farthest-side, transparent calc(100% - 15px), red 35px);
`;
const ListCont = styled.div<IListCont>`
  margin-left: ${props => props.donutGraph && '10px'};

  > h3 {
    font-size: .7rem;
  }
`;
const AgentsCont = styled.div`
  padding: 10px;
`;
interface IDonut {
  available: number;
  unavailable: number;
}
interface IListCont {
  donutGraph?: React.ReactNode;
}
export default ConversationAside;