import * as React from 'react';

export const Recordings = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.294 69.635v-6.302c0-25.043 20.29-45.388 45.388-45.388 25.043 0 45.387 20.29 45.387 45.388v6.302M107.144 69.635h-1.548v44.724h1.548c12.328 0 22.39-10.062 22.39-22.39s-10.062-22.334-22.39-22.334zM25.16 114.359h1.547V69.635H25.16c-12.328 0-22.39 10.062-22.39 22.39s10.117 22.334 22.39 22.334z"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={5.5283}
    />
    <path
      d="M52.911 96.06V59.352c0-3.095 3.483-4.81 5.97-2.985l24.602 18.354c1.99 1.493 1.99 4.478 0 5.97L58.882 99.047c-2.433 1.824-5.97.055-5.97-2.986"
      fill="#92d900"
    />
  </svg>
);
