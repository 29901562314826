import React from 'react';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import PageHeader from '../../components/Common/PageHeader';
import MainRecording from 'components/Recording/MainRecording';
import { useTranslation } from 'react-i18next';

const PagesStarter = () => {
  const { t } = useTranslation('recordings');
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ position: 'relative', minHeight: '100vh' }}
      >
        <MetaTags>
          <title>{t('pageTitle')} | SmartBot</title>
        </MetaTags>
        <Container fluid>
          <PageHeader title={t('pageTitle')} />
          <MainRecording />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PagesStarter;
