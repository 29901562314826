import React, { FC } from 'react';
import styled from 'styled-components';

import { Hamburguer, LightBulb, Paper, PeopleText } from 'icons';
import { lightGreenICON } from 'library/colors';

interface IRightBarIconsProps {
  handleExtendedBar: Function;
}
const RightBarIcons: FC<IRightBarIconsProps> = ({ handleExtendedBar }) => {
  return (
    <IconsCont>
      {/* <Icons>
        <PeopleText
          width={25}
          cursor={'pointer'}
          color={lightGreenICON}
          onClick={() => handleExtendedBar()}
        />
      </Icons> */}
      <HambCont onClick={() => handleExtendedBar()}>
        <Hamburguer width={20} color={'white'}/>
      </HambCont>
    </IconsCont>
  );
};
const IconsCont = styled.div`

  height: 100%;
  min-width: 50px;
  max-width: 50px;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */
  border-radius: 5px;
  background-color: #2a3042;
  position: relative;
  z-index: 10;
`;
const Icons = styled.div`
  margin-top: 20px;
  border: 1px solid white;
`;
const HambCont = styled.div`
  padding: 10px 15px;
  cursor: pointer;
`;
export default RightBarIcons;
