import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SidebarFlow from '../../components/flow/common/SidebarFlow';
import Editor from '../../components/flow/Editor';
import { ReactFlowProvider } from 'react-flow-renderer';

import './editor.css';
import MetaTags from 'react-meta-tags';
import { Container } from 'reactstrap';
import PageHeader from '../../components/Common/PageHeader';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  addScenario as onAddScenario,
  getScenario as onGetScenario,
} from 'store/actions';

Index.propTypes = {
  scenario: PropTypes.object,
};

function Index(props) {
  //const [nodes] = useState(scenario.nodes ? scenario.nodes : blank.nodes);
  //const [edges] = useState(scenario.edges ? scenario.edges : blank.edges);
  const [editorCMD, setEditorCMD] = useState('');

  const handleUndo = () => {};
  const handleRedo = () => {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [scenarioDisplay, setScenarioDisplay] = useState({});

  useEffect(() => {
    if (props.match.params.id !== 'new') {
      dispatch(onGetScenario(props.match.params.id));
    } else {
      dispatch(onAddScenario());
    }
  }, [dispatch]);

  const { scenario, newScenario } = useSelector(state => ({
    scenario: state.scenarios.scenario,
    newScenario: state.scenarios.newScenario,
  }));

  useEffect(() => {
    if (newScenario) {
      if (props.match.params.id === 'new') {
        history.push(`/editor/${newScenario}`);
        dispatch(onGetScenario(newScenario));
      }
    }
  }, [newScenario]);

  useEffect(() => {
    if (scenario) {
      setScenarioDisplay(scenario);
    }
  }, [scenario]);
  const onPublish = () => {
    setEditorCMD('on-publish');
    setTimeout(function () {
      setEditorCMD('');
    }, 1000);
  };

  return (
    <ReactFlowProvider>
      <div className="page-content">
        <MetaTags>
          <title>Outbound Scenario | SmartBot</title>
        </MetaTags>

        <Container fluid>
          <PageHeader title="OUTBOUND SCENARIO" />

          <span
            style={{
              padding: 8,
            }}
          >
            <button className="btn btn-default" onClick={handleUndo}>
              <i className="bx bx-undo font-size-24 text-primary" />
            </button>
            <button className="btn btn-default" onClick={handleRedo} disabled>
              <i className="bx bx-redo font-size-24 text-primary" />
            </button>
          </span>

          <span className="float-end">
            <button type="button" className="btn btn-primary btn-rounded">
              <i className="bx bx-play font-size-20 align-middle me-2"></i>
              Test
            </button>
            <button className="btn btn-default" onClick={handleRedo}>
              <i className="bx bx-slider font-size-24 text-primary" />
            </button>
            <button className="btn btn-default" onClick={handleRedo}>
              <i className="bx bx-search font-size-24 text-primary" />
            </button>
          </span>

          <div className="flow-container">
            <SidebarFlow onPublish={onPublish} />

            <div className="flow-editor">
              <Editor scenario={scenarioDisplay} editorCMD={editorCMD} />
            </div>
          </div>
        </Container>
      </div>
    </ReactFlowProvider>
  );
}

export default Index;
