import React, { useCallback } from 'react';
import styled from 'styled-components';
//@ts-ignore
import MetaTags from 'react-meta-tags';
import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import FlowV2 from 'components/flowV2';

const ReactFlowPage = () => {
  return (
    <Cont>
      <MetaTags>
        <title>SmartBot</title>
      </MetaTags>
      {/* <div>
        <PageTitle>Entrantes</PageTitle>
      </div> */}
      <FlowV2 />
    </Cont>
  );
};
const Cont = styled.div`
  background-color: #eff5f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export default ReactFlowPage;
