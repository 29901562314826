import React from 'react';

import { Search } from '../../icons';

import './SearchScenery.css';

function SearchScenery() {
  return (
    <div className="incoming-search-scenery">
      <Search width={16} height={16} />
      <input placeholder="Busca escenario..." />
    </div>
  );
}

export default SearchScenery;
