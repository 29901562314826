import { lightBackgroundBlue, popUpBackground, primary } from 'library/colors';
import {
  ICreateSMSCampaignContact,
  IDataSources,
} from 'library/interfaces/smsCampaignInterface';
import IStore from 'library/interfaces/storeInterface';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { postSMSCampaignAction } from 'store/actions';
import styled from 'styled-components';
import { IFile } from '../molecules/FormLoadingFiles';
import CreateSmsCampaignnFirstStage from '../organisms/createSMSCampaignFirstStage';
import SMSFormButtons from '../smsCampaigns/molecules/smsFormButtons';

interface ISMSCreateCampaignTemplateProps {
  setShowSMSCampaignForm: (show: boolean) => void;
}
const SMSCreateCampaignTemplate: FC<ISMSCreateCampaignTemplateProps> = ({
  setShowSMSCampaignForm,
}) => {
  const { t } = useTranslation('outbound');
  const dispatch = useDispatch();

  // this propety is initialized as undefine.
  // whem post a new campaign, the property is true
  // after create campaign success or fail, propety is undefine againt
  // this is used to handle the disable of form button and form hide
  const isCreatingSMSCampaign = useSelector<IStore, boolean | undefined>(
    store => store.smsCampaigns.isCreatingSMSCampaign,
  );

  // save loaded files
  const [files, setFiles] = useState<IFile[]>([]);
  // save the source of contacts and data
  const [sources, setSources] = useState<IDataSources>({
    sheet: '',
    numbersColumn: '',
    columnWithMessage: '',
    columnsToMessageVariables: [],
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleCreateCampaign = () => {
    const campaignName = inputRef.current?.value;
    const message = textAreaRef.current?.value;

    const sheet =
      files.length > 0 &&
      files[0].sheets.filter(item => {
        if (item.name === sources.sheet) return item;
      });
    if (!campaignName || !sheet) return;

    const contacts: ICreateSMSCampaignContact[] = [];

    if (sources.columnWithMessage) {
      sheet[0]?.data.map((excelRow, i) => {
        if (!(excelRow instanceof Object)) return;

        if ((excelRow as any)[sources.numbersColumn]) {
          const number = `${(excelRow as any)[sources.numbersColumn]}`;
          const messageToSend = `${(excelRow as any)[sources.columnWithMessage]
            }`;
          number.split(';').forEach(num => {
            if (!num) return;
            contacts.push({
              number: num.trim(),
              // comenta el limite de los mensaje de 160 caracteres para envíar mensajes más largos
              text: messageToSend, // .substring(0, 159),
            });
          });
        }
      });
      dispatch(
        postSMSCampaignAction({ name: campaignName, contacts: contacts }),
      );
      return;
    }

    if (!message) return;
    // if messageHasVaribles is different of -1,message contains variables
    const messageHasVaribles = message.indexOf('${') !== -1 ? true : false;

    sheet[0]?.data.map((excelRow, i) => {
      if (!(excelRow instanceof Object)) return;
      // initialize the value with each iteration
      let messageToSend = message;

      // if message has variables and the are columns selected
      // replace the values in message
      if (messageHasVaribles && sources.columnsToMessageVariables.length > 0) {
        sources.columnsToMessageVariables.forEach(name => {
          if ((excelRow as any)[name]) {
            messageToSend = messageToSend.replaceAll(
              `$\{${name}}`,
              (excelRow as any)[name],
            );
          }
        });
      }

      if ((excelRow as any)[sources.numbersColumn]) {
        const number = `${(excelRow as any)[sources.numbersColumn]}`;
        number.split(';').forEach(num => {
          if (!num) return;
          contacts.push({
            number: num.trim(),
            // comenta el limite de los mensaje de 160 caracteres para envíar mensajes más largos
            text: messageToSend //.substring(0, 159),
          });
        });
      }
    });

    dispatch(postSMSCampaignAction({ name: campaignName, contacts: contacts }));
  };

  const handleExit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(event.target instanceof Element)) return;

    if (
      event.target.id === 'popupContainer' ||
      event.target.id === 'exitButton'
    ) {
      return setShowSMSCampaignForm(false);
    }
  };
  useEffect(() => {
    if (isCreatingSMSCampaign !== false) return;
    setShowSMSCampaignForm(false);
  }, [isCreatingSMSCampaign]);
  return (
    <PopUpContainer id="popupContainer" onClick={e => handleExit(e)}>
      <FormContainer>
        <Exit id="exitButton" onClick={e => handleExit(e)}>
          x
        </Exit>
        {/* <FormHeader stages={headerStage} /> */}
        <HeaderCont>
          {t('SMScampaigns.SMSCreateCampaignForm.smsCampaignInformation')}
        </HeaderCont>
        <StageCont>
          <CreateSmsCampaignnFirstStage
            files={files}
            setFiles={setFiles}
            sources={sources}
            setSources={setSources}
            inputRef={inputRef}
            textAreaRef={textAreaRef}
          />
        </StageCont>
        <SMSFormButtons
          saveCampaign={handleCreateCampaign}
          isCreatingSMSCampaign={isCreatingSMSCampaign}
        />
      </FormContainer>
    </PopUpContainer>
  );
};
const PopUpContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 2;
  background-color: ${popUpBackground};
`;
const FormContainer = styled.form`
  position: fixed;
  top: 100px;
  z-index: 10;
  width: 800px;
  color: black;
  padding: 30px 20px 0px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid ${primary};
  box-shadow: 0 5px 10px rgba(123, 153, 4, 0.5);
`;
const Exit = styled.div`
  top: 5px;
  right: 10px;
  position: absolute;
  font-size: 1rem;
  text-align: right;
  padding-right: 5px;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }
`;
const HeaderCont = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  padding: 20px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background-color: ${lightBackgroundBlue};
`;
const StageCont = styled.div`
  min-height: 420px;
`;

//
// constants to initialize react states
//
// const HEADER_STAGE_INIT_STATE = (t: (key: string) => string) => [
//   {
//     current: true,
//     isSelected: true,
//     label: t(
//       'SMScampaigns.SMSCreateCampaignForm.formHeader.loadingContactsFiles',
//     ),
//   },
//   {
//     current: false,
//     isSelected: false,
//     label: t('SMScampaigns.SMSCreateCampaignForm.formHeader.mainInfo'),
//   },
//   {
//     current: false,
//     isSelected: false,
//     label: t(
//       'SMScampaigns.SMSCreateCampaignForm.formHeader.configureContactSource',
//     ),
//   },
//   {
//     current: false,
//     isSelected: false,
//     label: t('SMScampaigns.SMSCreateCampaignForm.formHeader.resume'),
//   },
// ];
export default SMSCreateCampaignTemplate;

// const sheetData = [
//   {
//     name: 'Sheet1',
//     columnsToSelect: [
//       {
//         name: 'nombre',
//         isSelected: false,
//       },
//       {
//         name: 'numero',
//         isSelected: false,
//       },
//     ],
//     data: [
//       {
//         nombre: 'uno',
//         numero: 11111,
//       },
//       {
//         nombre: 'dos ',
//         numero: 22222,
//       },
//       {
//         nombre: 'tres',
//         numero: 33333,
//       },
//     ],
//   },
// ];

// const file = [
//   {
//     fileName: 'prueba mensaje.xlsx',
//     sheetsName: ['Sheet1'],
//     sheets: [
//       {
//         name: 'Sheet1',
//         columnsToSelect: [
//           {
//             name: 'nombre',
//             isSelected: false,
//           },
//           {
//             name: 'numero',
//             isSelected: false,
//           },
//         ],
//         data: [
//           {
//             nombre: 'uno',
//             numero: 11111,
//           },
//           {
//             nombre: 'dos ',
//             numero: 22222,
//           },
//           {
//             nombre: 'tres',
//             numero: 33333,
//           },
//         ],
//       },
//     ],
//   },
// ];

const usuarios = [{ name: 'uno' }, { name: 'dos' }, { name: 'tres' }];

const value = 'hola usuario ${name}';

const resultado = ['hola usuario uno', 'hola usuario dos', 'hola usuario tres'];
