import { danger } from 'library/colors';
import { ICreateDatabaseSchema } from 'library/interfaces/databasesInterfaces';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IDatabaseColumnProps {
  index: number
  column: ICreateDatabaseSchema
  handleUpdateColumn: (index: number, column: ICreateDatabaseSchema) => void
  handleDeleteColumn: (index: number) => void
}
const DatabaseColumn: FC<IDatabaseColumnProps> = ({ column, index, handleUpdateColumn, handleDeleteColumn }) => {

  const handeColumnChange = (key: 'key' | 'type', value: string) => {
    const editedColumn = structuredClone(column)
    editedColumn[key] = value
    if (key === 'key') {
      editedColumn.name = value
    }
    handleUpdateColumn(index, editedColumn)
  }
  return (
    <Cont>
      <DelIcon onClick={() => handleDeleteColumn(index)}>
        <i className='bx bxs-trash'></i>
      </DelIcon>
      <InputGroup>
        <label htmlFor="">Nombre de columna</label>
        <input
          type="text"
          value={column.key}
          onChange={e => handeColumnChange('key', e.target.value)}
        />
      </InputGroup>

      <InputGroup>
        <label htmlFor="">Tipo de dato</label>
        <select
          value={column.type}
          onChange={e => handeColumnChange('type', e.target.value)}
        >
          <option value="S">Texto</option>
          <option value="N">Numérico</option>
        </select>
      </InputGroup>
    </Cont>
  );
};
const Cont = styled.div`
margin-bottom: 10px;
padding: 5px 10px;
border-radius: 10px;
  background-color: white;
  display: flex;
  position: relative;
`;
const InputGroup = styled.div`
max-width: 70%;
min-height: 30%;
  font-size: 0.7rem;
  font-weight: 500;
  display: grid;
  margin-bottom: 10px;

  & > input,
  select {
    font-size: 1rem;
    padding: 5px 0;
    border: none;
    background-color: white;
  }
  & > input{
    padding-right: 10px;
  }
`;
const DelIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 1rem;
  cursor: pointer;

  &:hover{
    color: ${danger}
  }
`;
export default DatabaseColumn;