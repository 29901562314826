import React, { FC } from 'react';
import styled from 'styled-components';

interface IInputDateProps {
  name: string;
  type: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const InputDate: FC<IInputDateProps> = ({
  name,
  type,
  value,
  handleChange,
}) => {
  return (
    <Input
      type={type}
      name={name}
      id={name}
      value={value}
      onChange={e => handleChange(e)}
    />
  );
};
const Input = styled.input`
  font-size: 1rem;
  text-align: center;
  border: none;
  padding: 10px 0;
  flex-grow: 1;
`;
export default InputDate;
