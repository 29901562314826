import { File } from 'buffer';
import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import UploadFIleDropZone from 'components/outbound/waCampaigns/organisms/uploadFileDropZone';
import { darkBorder, primary } from 'library/colors';
import { useAppDispatch } from 'library/hooks/useAppDispatch';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { ISendMessage } from 'library/interfaces/conversationInterfaces';
import React, { FC, useEffect } from 'react';
import { postSendMessageAction } from 'store/actions';
import { getAllFilesAction, uploadFileAction } from 'store/files/asyncActions';
import { ISysFile } from 'store/files/reducer';
import styled from 'styled-components';

interface IImagePickerProps {
  setShowImagesPicker: (show: boolean) => void
}
const ImagePicker: FC<IImagePickerProps> = ({ setShowImagesPicker }) => {
  const dispatch = useAppDispatch()
  const { display_name } = useAppSelector(store => store.authUserData)
  const { files, isLoading } = useAppSelector(store => store.files)
  const selectedSession = useAppSelector(store => store.conversations.selectedSession)

  const handleUploadFile = (file: File) => {
    const form = new FormData()
    //@ts-ignore
    form.append('file', file)

    dispatch(uploadFileAction(form))
  }

  const handleSendFile = (file: ISysFile) => {
    if (!selectedSession) return

    let content: { [key: string]: string } = {}

    if (file.filename.endsWith('.jpg') || file.filename.endsWith('.png')) {
      content = {
        type: "image",
        originalUrl: file.url,
        previewUrl: file.url,
      }
    } else if (file.filename.endsWith('.pdf')) {
      content = {
        type: "file",
        url: file.url,
        filename: file.filename
      }
    }
    if (!content?.type) return

    const message: ISendMessage = {
      content: JSON.stringify(content),
      created_at: new Date().toISOString(),
      source_name: display_name,
      source_type: 'agent'
    }
    dispatch(postSendMessageAction(selectedSession, message));
    setShowImagesPicker(false)
  }
  useEffect(() => {
    if (files.length === 0)
      dispatch(getAllFilesAction())
  }, [])
  return (
    <Cont>

      <InputCont >
        <Input type="text" placeholder="Search" />
        <SearchIcon />
      </InputCont>
      <FilesCont>
        {files.map((file) => {
          if (file.filename.endsWith('.mp3')) return
          const isPDF = file.filename.endsWith('.pdf')
          return (
            <FileCont key={file.id}>
              <SendButton onClick={() => handleSendFile(file)}>
                <i className='bx bx-upload'></i>
                Enviar
              </SendButton>
              {isPDF ?
                <Embed src={file.url} type="application/pdf" />
                :
                <Img src={file.url} alt="img" />}
            </FileCont>
          )
        })}
      </FilesCont>
      <UploadFileCont>
        {isLoading ?
          <span>Cargando <LoadingPoints /></span>
          :
          <UploadFIleDropZone file={[]} setFile={handleUploadFile} />
        }
      </UploadFileCont>
    </Cont>
  );
};
const Cont = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  right: -150px;
  width: 350px;
  height: 450px;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  background-color: #FFF;
  overflow: hidden;
`;
const InputCont = styled.div`
  padding: 15px 10px;
  position: relative;
`;
const Input = styled.input`
  border: 1px solid #f6f6f6;
  border-radius: 8px;
  height: 40px;
  color: #858585;
  width: 100%;
  padding: 0 30px;
  background-color: #f6f6f6;
  font-size: .8rem;
  font-family: sans-serif;

  &:focus {
    border-color: #007aeb;
  }
`;
const SearchIcon = styled.div`
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='40' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23868686' d='M12 8.81c0 2.08-1.68 3.76-3.76 3.76s-3.76-1.68-3.76-3.76 1.68-3.76 3.76-3.76S12 6.73 12 8.81zm-.77 3.91c-.83.64-1.87 1.01-2.99 1.01-2.72 0-4.92-2.2-4.92-4.92 0-2.72 2.2-4.92 4.92-4.92 2.72 0 4.92 2.2 4.92 4.92 0 1.13-.38 2.16-1.01 2.99l3.94 3.93c.25.25.25.66 0 .92-.25.25-.66.25-.92 0l-3.94-3.93z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23C0C0BF' d='M12 28.81c0 2.08-1.68 3.76-3.76 3.76s-3.76-1.68-3.76-3.76 1.68-3.76 3.76-3.76S12 26.73 12 28.81zm-.77 3.91c-.83.64-1.87 1.01-2.99 1.01-2.72 0-4.92-2.2-4.92-4.92 0-2.72 2.2-4.92 4.92-4.92 2.72 0 4.92 2.2 4.92 4.92 0 1.13-.38 2.16-1.01 2.99l3.94 3.93c.25.25.25.66 0 .92-.25.25-.66.25-.92 0l-3.94-3.93z'/%3E%3C/svg%3E");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 20px;
    content: "";
    height: 20px;
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
`;
const FilesCont = styled.div`
  /* border: 1px solid black; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 0 20px;
  height: 300px;
  overflow-y: scroll;
  
`;
const Img = styled.img`
  width: 100%;
`;
const Embed = styled.embed`
  width: 100%;
  overflow: hidden;
`;
const FileCont = styled.div`
  /* border: 1px solid black; */
  height: 100px;
  overflow: hidden;
  position: relative;
  display: grid;
  place-items: center;

  &:hover > div {
    opacity: 1;
  }

`;
const SendButton = styled.div`
  position: absolute;
  border: 1px solid;
  padding: 10px 20px 10px 15px;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s;
  background-color: ${primary};

  &>i{
    margin-right: 5px ;
  }
`;
const UploadFileCont = styled.div`
  /* border: 1px solid; */
  padding: 10px;
  height: 78px;
  color: #858585;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    transform: translateY(-15px);
    margin: 3px;
  }

  & > div {
    cursor: pointer;
    /* border-color: #fff; */
    background-color: #f6f6f6;
  }
`;
export default ImagePicker;