import { darkBorder, primary } from 'library/colors';
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { Upload } from 'icons';
import { useTranslation } from 'react-i18next';
import { IFile } from 'components/outbound/molecules/FormLoadingFiles';

interface IUploadFIleDropZoneProps {
  file: any;
  setFile: Function;
}
const UploadFIleDropZone: FC<IUploadFIleDropZoneProps> = ({ file, setFile, }) => {
  const { t } = useTranslation('outbound');

  // Function to load files
  const onDrop = (acceptedFiles: any[], rejectedFiles: any[]) => {
    // if there is not acepted files return
    if (
      acceptedFiles.length <= 0 ||
      acceptedFiles.length > 1
    )
      return;

    setFile(acceptedFiles[0])

    // const newFiles: IFile[] = [];

    // map the acepted files
    // acceptedFiles.map((file, index) => {
    //   // for every file, asign the file name to the property fileName
    //   // the other properties are initialized
    //   const obj: IFile = {
    //     fileName: acceptedFiles[index]?.name,
    //     sheetsName: [],
    //     sheets: [],
    //   };

    //   // create a reader to read the files
    //   const reader = new FileReader();
    //   reader.readAsArrayBuffer(acceptedFiles[index]);

    //   // event dispatch after the reader loads the file
    //   reader.onloadend = e => {
    //     // validate is result exist and the result type of loaded file
    //     // if is not a Array Buffer return
    //     if (!(e.target?.result instanceof ArrayBuffer)) return;

    //     // transform result in Uint8Array
    //     const data = new Uint8Array(e.target.result);

    //     // transform the array into an object
    //     const workbook = XLSX.read(data, { type: 'array' });

    //     // map the SheetNames property of workbook
    //     // to get the data of every sheet
    //     workbook.SheetNames.map(sheetname => {
    //       // get data of every sheet
    //       const sheetData: { [key: string]: string }[] = XLSX.utils.sheet_to_json(
    //         workbook.Sheets[sheetname],
    //       );

    //       // save the columns name
    //       const columns =
    //         sheetData.length > 0
    //           ? Object.keys(sheetData[0] as {})
    //           : Object.keys({});

    //       // trnsform columns name in selectable objects
    //       const columnsToSelect =
    //         (columns.length > 0 &&
    //           columns.map(column => {
    //             return { name: column, isSelected: false };
    //           })) ||
    //         [];

    //       obj.sheetsName.push(sheetname);
    //       obj.sheets.push({
    //         name: sheetname,
    //         columnsToSelect: columnsToSelect,
    //         data: sheetData,
    //       });
    //     });
    //     newFiles.push(obj);
    //     setFiles([...files, ...newFiles]);
    //   };
    // });
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      multiple: false,
      // accept: {
      //   'text/csv': ['.csv'],
      //   'application/vnd.ms-excel': ['.xls'],
      //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      //     '.xlsx',
      //   ],
      // },
    });
  return (
    <Drop {...getRootProps()} accepted={isDragAccept} rejected={isDragReject}>
      <input {...getInputProps()} />

      {!isDragReject && (
        <>
          <Upload width={100} />
          <br />
          <P1>{t('campaigns.createCampaignForm.loadFiles.uploadOrDrop')}</P1>
          <P2>Solo archivos en formato PDF o imágenes</P2>
        </>
      )}

      {isDragReject && (
        <>
          <P3>Formato de Archivo no válido</P3>
        </>
      )}
    </Drop>
  );
};
const Drop = styled.div<IDrop>`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed
    ${props => (props.accepted ? primary : props.rejected ? 'red' : darkBorder)};
`;
const P1 = styled.p`
  font-size: 1rem;
  font-weight: 500;
`;
const P2 = styled(P1)`
  font-size: 0.8rem;
  line-height: 5px;
`;
const P3 = styled(P1)`
  color: red;
`;
interface IDrop {
  accepted: boolean;
  rejected: boolean;
}
export default UploadFIleDropZone;