import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Paper from 'components/foundation/Paper';

import {
  // getQueues as onGetQueues,
  getRecordings as onGetRecordings,
  getReportData as onGetReportData,
} from 'store/actions';

import QueueFilter from './filters';
import RecordingsList from './recordingList';

import './mainRecording.css';
import Pagination from './filters/pagination';

const MainRecording = () => {
  const [owner, setOwner] = useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector(store => store.recordings.isLoading);
  // let authUser = JSON.parse(localStorage.getItem('authUser'));

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const ownerID = authUser.owner.toLowerCase();
    setOwner(ownerID);
    dispatch(onGetReportData());

    if (isLoading) return;
    dispatch(onGetRecordings(ownerID));
  }, []);

  return (
    <>
      <div>
        <Paper className="mt-2">
          <QueueFilter owner={owner} />
        </Paper>
        <Pagination owner={owner} />
        <Paper className="mt-2">
          <RecordingsList />
        </Paper>
      </div>
    </>
  );
};
export default MainRecording;
