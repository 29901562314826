import * as React from 'react';

export const Workspace = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M66.113 33.847c7.09 0 12.84 5.751 12.84 12.84s-5.75 12.84-12.84 12.84-12.84-5.75-12.84-12.84 5.751-12.84 12.84-12.84"
      fill="#92d900"
    />
    <path
      d="M36.063 109.247v-9.971c0-16.596 13.454-30.12 30.119-30.12 16.596 0 30.119 13.455 30.119 30.12v9.971"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.8297}
    />
    <path
      d="M20.969 94.563c-9.698 0-17.552-7.854-17.552-17.552V40.61c0-9.698 7.854-17.553 17.552-17.553h90.357c9.698 0 17.552 7.855 17.552 17.553V77.01c0 9.698-7.854 17.552-17.552 17.552"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.8297}
    />
  </svg>
);
