import React from 'react';
import PropTypes from 'prop-types';

function Select({ options, value, onChange, disabled = false }) {
  return (
    <select
      className="form-select"
      style={{ display: 'inline-block', borderRadius: 35 }}
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      {options?.map(({ value, text }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </select>
  );
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

export default Select;
