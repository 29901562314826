import { lightBackgroundBlue, primary } from 'library/colors';
import { getSecondsDifference } from 'library/services/dateServices';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

interface IStateCounterProps {
  createdAt: string
  setIsAvailable: (isAvailable: boolean) => void
}
const StateCounter: FC<IStateCounterProps> = ({ createdAt, setIsAvailable }) => {
  const [countdown, setCountdown] = useState(() => {
    const regressiveCountStart = getSecondsDifference(createdAt) - 60
    if (regressiveCountStart >= 0) return 0
    return regressiveCountStart
  });

  useEffect(() => {
    if (countdown < 0) return
    setIsAvailable(true)
  }, [countdown])

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prev => {
        if (prev === 0) {
          clearInterval(interval)
          return prev
        } else {
          return prev + 1
        }
      })
    }, 1000)

    return () => clearTimeout(interval)
  }, []);

  return (
    <State isAvailable={!(countdown < 0)}>Disponible{countdown < 0 && ` en ${Math.abs(countdown)} s`}</State>
  );
};
const State = styled.div<IStateProps>`
  width: 150px;
  height: 30px;
  margin: 0 10px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  cursor: default;
  user-select: none;
  color: ${props => props.isAvailable ? 'white' : 'black'};
  background-color: ${props => props.isAvailable ? primary : lightBackgroundBlue};
`;
interface IStateProps {
  isAvailable: boolean
}
export default StateCounter;