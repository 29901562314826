import LoadingPoints from 'components/outbound/atoms/loadingPoints';
import { darkBorder, primary } from 'library/colors';
import { useAppSelector } from 'library/hooks/useAppSelector';
import { consolidateData, createExcellReport, csvStringParsed, getCsvAsString, excelBufferToJson, getExcelAsBuffer, getInProgressReport, requestReport } from 'library/services/reportsServices/consolidatecsvExcellReport';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface IReportsInRightBarProps { }
const ReportsInRightBar: FC<IReportsInRightBarProps> = ({ }) => {

  const campaigns = useAppSelector(store => store.waCampaigns.campaigns)
  const owner = useAppSelector(store => store.authUserData.owner)

  const [campaignId, setCampaignId] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleGetConsolidatedReport = async () => {
    setIsLoading(true)
    try {

      const requestedReports = await Promise.all([
        requestReport(7, campaignId, owner)
          .then(async res => {
            const getExcelRes = await getExcelAsBuffer(await getInProgressReport(res, owner))
            if (!getExcelRes) throw Error('No se pudo obtener el reporte 7')
            return excelBufferToJson(getExcelRes)
          })
          .catch(e => console.log(e)),
        requestReport(10, campaignId, owner)
          .then(async res => {
            const csvRes = await getCsvAsString(await getInProgressReport(res, owner))
            if (!csvRes) throw Error('No se pudo obtener el reporte 10')
            return csvStringParsed(csvRes, 'Número Contacto')

          })
          .catch(e => console.log(e)),
      ])

      const [excel, csv] = requestedReports

      if (!excel || !csv) throw new Error('Error al obtener los reportes')

      const consolidatedData = consolidateData(csv.csvData, excel.excelData, csv.idIndex || {}, 'Celular')

      const sheets = [
        { sheetName: 'Reporte consolidado', data: consolidatedData },
        { sheetName: 'Reporte de escenario .xlsx', data: excel.excelData },
        { sheetName: 'Reporte de campaña .csv', data: csv.csvData },
      ]

      const campaignName = campaigns.find(camp => camp.id === campaignId)?.name || ''

      createExcellReport(`Reporte Consolidado ${campaignName}`, sheets)

    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Cont>
      <Label>Campaña de WhatsApp</Label>
      <Select value={campaignId} onChange={e => setCampaignId(e.target.value)}>
        <option value="">Seleccionar campaña</option>
        {campaigns.map((campaign) => {
          if (campaign.active === false) return
          return <option key={campaign.id} value={campaign.id}>{campaign.name}</option>
        })}
      </Select>
      <Button
        disabled={!campaignId || isLoading}
        onClick={() => handleGetConsolidatedReport()}>
        {isLoading ? <>Generando <LoadingPoints /></> : 'Reporte'}
      </Button>
    </Cont>
  );
};
const Cont = styled.div`
  height: 100%;
  width: 200px;
  padding: 20px 10px;
  font-size: 0.8rem;
  transform: translate(0px);
  position: absolute;
  left: 0;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${primary};
  }
`;
const Label = styled.label<ILabel>`
  font-size: 0.7rem;
  font-weight: 500;
  margin: ${props => props.margin};
`;
const Select = styled.select`
  width: 100%;
  margin: 8px 0;
  padding-left: 5px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid ${primary};
`;
const Button = styled.button`
  width: 120px;
  height: 30px;
  font-weight: 600;
  margin: 10px auto;
  display: block;
  color: ${props => props.disabled ? 'white' : primary};
  border: 2px solid ${props => props.disabled ? darkBorder : primary};
  border-radius: 50px;
  background-color:  ${props => props.disabled ? darkBorder : 'transparent'};
`;
interface ILabel {
  margin?: string;
}
export default ReportsInRightBar;