import * as React from 'react';

export const LiveChat = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.354 94.02V35.142c0-7.034 5.694-12.727 12.727-12.727h100.14c7.033 0 12.727 5.693 12.727 12.727V94.02"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={6.6983}
    />
    <path
      d="M42.406 69.37c0-12.66 10.315-22.975 22.975-22.975S88.356 56.71 88.356 69.37 78.04 92.345 65.38 92.345 42.406 81.963 42.406 69.37m65.308 32.822L90.7 85.178a29.893 29.893 0 0 0 4.555-15.875c0-16.478-13.397-29.941-29.874-29.941S35.439 52.825 35.439 69.303s13.464 29.941 29.942 29.941c5.961 0 11.52-1.741 16.21-4.822l16.946 16.946c1.206 1.206 2.814 1.809 4.488 1.809s3.215-.603 4.488-1.809l.268-.268c2.411-2.411 2.411-6.43-.067-8.908"
      fill="#92d900"
    />
  </svg>
);
