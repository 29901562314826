import * as React from 'react';

export const Integrations = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M52.404 66.138H2.571m24.916 24.917 24.917-24.917-24.917-24.917m45.776 47.933h0c12.69 0 23.016-10.326 23.016-23.016S85.952 43.122 73.263 43.122m-20.859 60.109a42.587 42.587 0 0 0 9.453 3.904l3.648 15.464h15.618l3.648-15.464a42.587 42.587 0 0 0 9.453-3.904l13.511 8.374 10.994-10.994-8.374-13.512a42.587 42.587 0 0 0 3.905-9.453L129.724 74V58.38l-15.464-3.648a42.587 42.587 0 0 0-3.905-9.453l8.374-13.512-10.994-10.994-13.511 8.374a42.587 42.587 0 0 0-9.453-3.904L81.123 9.78H65.505l-3.648 15.464a42.587 42.587 0 0 0-9.453 3.904"
      fill="none"
      stroke="#92d900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={5.1375}
    />
  </svg>
);
