import { danger } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ISelectableColumnProps {
  index: number;
  column: {
    key: string,
    type: string,
    isSelected: boolean
  }
  onSelectColumn: (index: number) => void
  onDeleteColumn: (index: number) => void
}
const SelectableColumn: FC<ISelectableColumnProps> = ({ column, index, onSelectColumn, onDeleteColumn }) => {
  return (
    <ColumnCont>
      <Input type="checkbox" checked={column.isSelected} onChange={() => onSelectColumn(index)} />
      <Type>{column.type}</Type>
      <Key>{column.key}</Key>
      <DelIcon onClick={() => onDeleteColumn(index)}>
        <i className='bx bxs-trash'></i>
      </DelIcon>
    </ColumnCont>
  );
};
const ColumnCont = styled.div`
  padding: 0px 0px 5px 10px;
  display: flex;
  align-items: center;
`;
const Type = styled.label`
  font-weight: 300;
  width: 70px;
  min-width: 70px;
  text-align: center;
  margin: 0;
`;
const Key = styled.label`
  font-weight: 500;
  margin: 0;
  font-size: 0.9rem;
`;
const Input = styled.input`
  cursor: pointer;
`;
const DelIcon = styled.div`
  font-size: .8rem;
  cursor: pointer;
  margin-left: 10px;

  &:hover{
    color: ${danger}
  }
`;
export default SelectableColumn;