import { postCampaignCallTag } from "helpers/fakebackend_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { IError } from "store/reports/reducer";
import { postCallTagFail, postCallTagSuccess } from "./actions";
import { IPostCallTag, POST_CALL_TAG } from "./actionTypes";

function* onPostCallTag({payload: {tag, campaignId}}: IPostCallTag){
  try {
    const response: string = yield call(postCampaignCallTag, tag, campaignId)
    if(response !== tag.callid) throw new Error("Tag was not created");
    yield put(postCallTagSuccess(response))
  } catch (e) {
    const error: IError = {
      message: 'Something went wrong getting report types!',
      details: `${e}`,
    };
    yield put(postCallTagFail(error));
  }
}

function* workspaceSaga() {
  yield takeEvery(POST_CALL_TAG, onPostCallTag)
}
export default workspaceSaga;