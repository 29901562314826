import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { ISelectedChatCustomer } from 'store/chatSelected/reducer';
import IStore from 'library/interfaces/storeInterface';

import { darkBorder } from 'library/colors';
import { useTranslation } from 'react-i18next';
import { IChatBotStore } from 'store/chatBot/reducer';
import { IPhoneStore } from 'store/phone/reducer';
import { IAuthUserData } from 'store/authUserData/reducer';
import { IUpdateAsespCallStatus } from 'library/interfaces/asespCallStatus';
import { updateAsespCallStatusAction } from 'store/actions';

const RightBarCustomerInfo = () => {
  const { t } = useTranslation('workspace');
  const dispatch = useDispatch();
  const chatBot = useSelector<IStore, IChatBotStore>(store => store.ChatBot);
  const user = useSelector<IStore, IAuthUserData>(store => store.authUserData);
  const { isCallAnswered, uniqueId } = useSelector<IStore, IPhoneStore>(
    store => store.phone,
  );
  const customer = useSelector<IStore>(
    store => store.selectedChat.customer,
  ) as ISelectedChatCustomer;

  useEffect(() => {
    if (
      user.owner !== 'ASESP' ||
      chatBot.queue !== '102' ||
      !isCallAnswered ||
      uniqueId.slice(uniqueId.lastIndexOf('-') + 1) !== chatBot.uniqueid
    )
      return;
    const callStatus: IUpdateAsespCallStatus = {
      docNumber: customer.id,
      docType: '68909',
      status: 'Atendida',
      uniqueid: chatBot.uniqueid,
    };
    dispatch(updateAsespCallStatusAction(callStatus));
  }, [chatBot, isCallAnswered]);
  return (
    <Cont>
      <Label>{t('rightBar.identificationNumber')}:</Label>
      <Data>{customer.id}</Data>
      <Label>{t('rightBar.customerName')}:</Label>
      <Data>{customer.name}</Data>
      <Label>{t('rightBar.number')}:</Label>
      <Data>{customer.phoneNumber}</Data>
      <Label>{t('rightBar.type')}:</Label>
      <Data>{customer.type}</Data>
      <Label>{t('rightBar.address')}:</Label>
      <Data>{customer.address}</Data>
    </Cont>
  );
};
const Cont = styled.div`
  width: 200px;
  padding: 20px 10px;
  font-size: 0.8rem;
  transform: translate(0px);
  position: absolute;
  left: 0;
`;
const Label = styled.div``;
const Data = styled.div`
  color: black;
  font-size: 1rem;
  min-height: 25px;
  margin-bottom: 20px;
`;
export default RightBarCustomerInfo;
