/* GENERAL */
export const CDR_ACTION_FAIL = "CDR_ACTION_FAIL"

/* CDR */
export const GET_SUMMARIZE_DATA = "GET_SUMMARIZE_DATA"
export const GET_SUMMARIZE_DATA_SUCCESS = "GET_SUMMARIZE_DATA_SUCCESS"
export const GET_LIVE_DATA = "GET_LIVE_DATA"
export const GET_LIVE_DATA_SUCCESS = "GET_LIVE_DATA_SUCCESS"
export const GET_REPORT_DATA = "GET_REPORT_DATA"
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS"
export const GET_AGENTS_SERVICE_LEVEL = "GET_AGENTS_SERVICE_LEVEL"
export const GET_AGENTS_SERVICE_LEVEL_SUCCESS = "GET_AGENTS_SERVICE_LEVEL_SUCCESS"

