import React from 'react';
import MetaTags from 'react-meta-tags';
import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import ConversationTemplate from 'components/liveConversations/templates/conversationTemplate';

const PagesStarter = props => {
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          backgroundColor: '#eff5f8',
          height: '100vh',
          position: 'relative',
        }}
      >
        <MetaTags>
          <title>Conversaciones en vivo | SmartBot</title>
        </MetaTags>

        <PageTitle>Conversaciones</PageTitle>

        <ConversationTemplate />
      </div>
    </React.Fragment>
  );
};

export default PagesStarter;
