import { IAuthUserData } from './reducer';

export const UPDATE_AUTH_USER_DATA = 'UPDATE_AUTH_USER_DATA';
export const DELETE_AUTH_USER_DATA = 'DELETE_AUTH_USER_DATA';

export const UPDATE_AUTH_USER_EXTEN = 'UPDATE_AUTH_USER_EXTEN';

export type AuthUserDataTypes =
  | IUpdateAuthUserData
  | IDeleteAuthUserData
  | IUpdateAuthUserExten;

export interface IUpdateAuthUserData {
  type: typeof UPDATE_AUTH_USER_DATA;
  payload: { userData: IAuthUserData };
}

export interface IDeleteAuthUserData {
  type: typeof DELETE_AUTH_USER_DATA;
}

export interface IUpdateAuthUserExten {
  type: typeof UPDATE_AUTH_USER_EXTEN;
  payload: { exten: string; exten_name: string; exten_password: string };
}
