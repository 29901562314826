import { darkBorder, primary } from 'library/colors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IExecutionHoursFormProps {
  hoursForm: { isValid: boolean; start_time: string; end_time: string };
  handleInputTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddClick: () => void;
}
const ExecutionHoursForm: FC<IExecutionHoursFormProps> = ({
  hoursForm,
  handleInputTimeChange,
  onAddClick,
}) => {
  const { t } = useTranslation('outbound');

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onAddClick();
  };
  return (
    <Cont>
      <InputTimeGroup>
        <div>
          <label>{t('campaigns.createCampaignForm.mainInfo.from')}</label>
          <Input
            type="time"
            name="start_time"
            value={hoursForm.start_time}
            onChange={e => handleInputTimeChange(e)}
          />
        </div>
        <div>
          <label>{t('campaigns.createCampaignForm.mainInfo.to')}</label>
          <Input
            type="time"
            name="end_time"
            value={hoursForm.end_time}
            onChange={e => handleInputTimeChange(e)}
          />
        </div>
        <AddHoursButton
          onClick={e => handleClick(e)}
          isDisabled={!hoursForm.isValid}
        >
          Add
        </AddHoursButton>
      </InputTimeGroup>
    </Cont>
  );
};
const Cont = styled.div`
  min-width: 50px;
  display: flex;
  align-items: center;
`;
const InputTimeGroup = styled.div`
  margin: 20px 0 20px;
  font-size: 0.7rem;
  font-weight: 500;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
`;
const Input = styled.input`
  text-align: center;
  border: none;
  padding: 10px 0;
  max-width: 80px;
  font-size: 1rem;
`;
const AddHoursButton = styled.button<IAddHoursButton>`
  margin-top: 5px;
  width: 100%;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50px;
  border: 2px solid ${props => (props.isDisabled ? darkBorder : primary)};
  background-color: ${props => (props.isDisabled ? darkBorder : primary)};
`;
interface IAddHoursButton {
  isDisabled: boolean;
}
export default ExecutionHoursForm;
