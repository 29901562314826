import * as React from 'react';

export const HoldCall = props => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 63 91.52"
        {...props}
    >
        {/* <title>Recurso 15</title> */}
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_5" data-name="Capa 5">
                <line 
                    fill='currentColor'
                    stroke='currentColor'
                    strokeMiterlimit={10}
                    strokeWidth={"17px"}
                    x1="8.5" x2="8.5" y2="91.52"
                />
                <line 
                    fill='currentColor' 
                    stroke='currentColor'
                    strokeMiterlimit={10}
                    strokeWidth={"17px"}
                    x1="54.5" x2="54.5" y2="91.52"
                />
            </g>
        </g>
    </svg>
);