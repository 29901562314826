import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderTable = ({directionWidth}) => {
  const {t} = useTranslation('recordings')
  return (
    <thead>
      <tr>
        <th style={{ width: '120px' }}>{t('transcription')}</th>
        <th style={{ width: '150px' }}>Uuid</th>
        <th style={{ width: '100px' }}>{t('source')}</th>
        <th style={{ width: '120px' }}>{t('destination')}</th>
        <th style={{ width: '330px' }}>{t('audio')}</th>
        <th style={{ width: '70px' }}>{t('agent')}</th>
        <th style={{ width: directionWidth + 'px' }}>{t('direction')}</th>
        <th style={{ width: '200px' }}>{t('startDate')}</th>
        <th style={{ width: '200px' }}>{t('endDate')}</th>
        <th style={{ width: '100px' }}>{t('duration')}</th>
        <th style={{ width: '170px' }}>{t('callState')}</th>
        <th style={{ width: '200px' }}>{t('timestamp')}</th>
      </tr>
    </thead>
  );
};
export default HeaderTable;
