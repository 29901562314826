import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Viewer from './Viewer';
import Editor from './Editor';

ViewEditContainer.propTypes = {
  View: PropTypes.any,
  Edit: PropTypes.any,
  data: PropTypes.object,
  index: PropTypes.number,
  onRemove: PropTypes.func,
};

function ViewEditContainer({ index, View, Edit, data, onRemove }) {
  const [inEdit, setInEdit] = useState(false);

  const toggleInEdit = useCallback(() => {
    setInEdit(old => !old);
  }, []);

  return inEdit ? (
    <Editor>
      <Edit index={index} data={data} toggleInEdit={toggleInEdit} />
    </Editor>
  ) : (
    <Viewer toggleInEdit={toggleInEdit} onRemove={onRemove}>
      <View index={index} data={data} />
    </Viewer>
  );
}

export default ViewEditContainer;
