import { IChatBotStore } from 'store/chatBot/reducer';
import {
  ISelectedChatMessage,
  ISelectedChatStore,
} from 'store/chatSelected/reducer';

export const chatBotToSelectedChatService = (
  chatBot: IChatBotStore,
  owner: string,
): ISelectedChatStore => {
  const customerID = chatBot.customer && chatBot.customer.id ? chatBot.customer.id : '';
  const customerName = chatBot.customer && chatBot.customer.name ? chatBot.customer.name : '';
  const customerAddress= chatBot.customer && chatBot.customer.address ? chatBot.customer.address : '';
  const customerType = chatBot.customer && chatBot.customer.type ? chatBot.customer.type : '';
  
  const customer = {
    phoneNumber: '',
    id: customerID,
    name: capitalizeFirstLetterOfEachWord(customerName),
    address: customerAddress,
    type: customerType
  };

  const logsWithOngoingStatus = chatBot.logs?.filter(
    item => item.callStatus === 'ongoing',
  );

  const messages: ISelectedChatMessage[] = [];

  logsWithOngoingStatus?.map(item => {
    const customerMessage: ISelectedChatMessage = {
      source: 'call',
      sender: customer.name || 'Customer',
      timestamp: dateFormat(item.timestamp),
      message: item.textRequest || '',
    };
    const botMessage: ISelectedChatMessage = {
      source: 'call',
      sender: `Bot ${owner}`,
      timestamp: dateFormat(item.timestamp),
      message: item.textResponse || '',
    };
    messages.push(customerMessage, botMessage);
  });

  return { customer, messages };
};

// this function converts the first letter of each word to uppercase
// and the rest of the word to lowercase
export const capitalizeFirstLetterOfEachWord = (string: string) => {
  if (string === '') return '';

  const lowercaseString = string.toLowerCase();
  const stringArray = lowercaseString.split(' ');
  if (stringArray.length > 0) {
    const result = stringArray
      .map(item => item.substring(0, 1).toUpperCase() + item.substring(1))
      .join(' ');

    return result;
  }
  return '';
};

// convert the default timestamp format
// from UTC to local time
export const dateFormat = (date: string) => {
  const localDate = new Date(date);
  return localDate.toLocaleString();
};