import { IModelLiveBoardData } from 'library/interfaces/liveboardInterface';
import React, { FC } from 'react';
import styled from 'styled-components';
import Title from '../atoms/title';
import Container from '../atoms/container';
import { useHistory } from 'react-router-dom';
import HorizontalBarGraph from '../atoms/horizontalBarGraph';
import ServiceLevel from '../molecules/serviceLevel';
import SpeedGraph from '../atoms/speedGraph';
import { useTranslation } from 'react-i18next';

interface ICallsResumeProps {
  data: IModelLiveBoardData;
}
const CallsResume: FC<ICallsResumeProps> = ({ data }) => {
  const history = useHistory();
  const { t } = useTranslation('summarize')

  const { queue, calls } = data;

  const totalCalls = calls.answeredCount + calls.abandonedCount
  const serviceLevel = Math.round(calls.answeredCount / totalCalls * 100) || 0

  const handleClick = () => {
    history.push(`/live-board/agent-calls/detailed/${queue.id}`);
  };
  return (
    <ResumenCont>
      <Container
        margin="0 auto 20px"
        padding="0 0 15px 10px"
        handleClick={handleClick}
        cursorPointer
      >
        <Cont>

          <Title padding="0px 15px 5px">{queue.name}</Title>
          <HorizontalBarGraph lostCall={calls.abandonedCount} totalCalls={calls.answeredCount + calls.abandonedCount} />
          <FlexCont>

            <GraphCont>
              <SpeedGraph porcent={serviceLevel} />
            </GraphCont>

          </FlexCont>
        </Cont>
      </Container>
    </ResumenCont>
  );
};
const ResumenCont = styled.div`
  border: 1px;
  max-width: 1050px;
  margin: 0 auto;

  @media (min-width: 1500px) {
    transform: scale(1.1);
    margin: 20px auto 50px;
  }
  @media (min-width: 1600px) {
    transform: scale(1.17);
    margin: 30px auto 60px;
  }
  @media (min-width: 1600px) {
    transform: scale(1.17);
    margin: 30px auto 60px;
  }
  @media (min-width: 1750px) {
    transform: scale(1.3);
    margin: 50px auto 90px;
  }
  @media (min-width: 1900px) {
    transform: scale(1.45);
    margin: 70px auto 130px;
  }
`;
const Cont = styled.div`
  display: flex;
  gap: 100px;
  grid-template-columns: 1fr 1fr 1fr;

  &>h2{
    margin: 0;
    margin-right: 20px;
    display: grid;
    place-content: center;
    padding-top: 20px;
  }

  &>:nth-child(2) {
    flex-grow: 1;
    position: relative;

    &::after {
      content: 'Recuento de llamadas';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      text-align: center;
      font-size: .8rem;
      font-weight: 500;
      color: dimgray;
      /* border: 1px solid; */
    }
  }
`;
const GraphCont = styled.div`
    padding: 0 20px;
    flex-grow: 1;
    position: relative;

    &::after {
      content: 'Nivel de servicio';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      text-align: center;
      font-size: .8rem;
      font-weight: 500;
      color: dimgray;
      /* border: 1px solid; */
    }
`;
const FlexCont = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 200px;
`;
export default CallsResume;