import {
  ICreateSMSCampaign,
  ICreateSMSCampaignContact,
  ISMSCampaign,
  ISMSCampaignActions,
} from 'library/interfaces/smsCampaignInterface';
import { IError } from 'store/reports/reducer';

export const GET_SMS_CAMPAIGNS = 'GET_SMS_CAMPAIGNS';
export const GET_SMS_CAMPAIGNS_SUCCESS = 'GET_SMS_CAMPAIGNS_SUCCESS';
export const GET_SMS_CAMPAIGNS_FAIL = 'GET_SMS_CAMPAIGNS_FAIL';

export const GET_SMS_CAMPAIGN_BY_ID = 'GET_SMS_CAMPAIGN_BY_ID';
export const GET_SMS_CAMPAIGN_BY_ID_SUCCESS = 'GET_SMS_CAMPAIGN_BY_ID_SUCCESS';
export const GET_SMS_CAMPAIGN_BY_ID_FAIL = 'GET_SMS_CAMPAIGN_BY_ID_FAIL';

export const POST_SMS_CAMPAIGN = 'POST_SMS_CAMPAIGN';
export const POST_SMS_CAMPAIGN_SUCCESS = 'POST_SMS_CAMPAIGN_SUCCESS';
export const POST_SMS_CAMPAIGN_FAIL = 'POST_SMS_CAMPAIGN_FAIL';

export const POST_SMS_CONTACT_GROUP = 'POST_SMS_CONTACT_GROUP';
export const POST_SMS_CONTACT_GROUP_SUCCESS = 'POST_SMS_CONTACT_GROUP_SUCCESS';
export const POST_SMS_CONTACT_GROUP_FAIL = 'POST_SMS_CONTACT_GROUP_FAIL';
export const POST_SMS_CONTACTS_FINISHED = 'POST_SMS_CONTACTS_FINISHED';

export const PUT_RUN_SMS_CAMPAIGN = 'PUT_RUN_SMS_CAMPAIGN';
export const PUT_RUN_SMS_CAMPAIGN_SUCCESS = 'PUT_RUN_SMS_CAMPAIGN_SUCCESS';
export const PUT_RUN_SMS_CAMPAIGN_FAIL = 'PUT_RUN_SMS_CAMPAIGN_FAIL';

export const PUT_STOP_SMS_CAMPAIGN = 'PUT_STOP_SMS_CAMPAIGN';
export const PUT_STOP_SMS_CAMPAIGN_SUCCESS = 'PUT_STOP_SMS_CAMPAIGN_SUCCESS';
export const PUT_STOP_SMS_CAMPAIGN_FAIL = 'PUT_STOP_SMS_CAMPAIGN_FAIL';

export type SMSCampaignsActionTypes =
  | IGetSMSCampaigns
  | IGetSMSCampaignsSuccess
  | IGetSMSCampaignsFail
  | IGetSMSCampaignById
  | IGetSMSCampaignByIdSuccess
  | IGetSMSCampaignByIdFail
  | IPostSMSCampaign
  | IPostSMSCampaignSuccess
  | IPostSMSCampaignFail
  | IPostSMSContactGroups
  | IPostSMSContactGroupsSuccess
  | IPostSMSContactGroupsFail
  | IPostSMSContactsFinish
  | IPutChangeSMSCampaignStatus
  | IPutChangeSMSCampaignStatusSuccess
  | IPutChangeSMSCampaignStatusFail

export type changeSMSCampaignStatusActions =
  | typeof PUT_RUN_SMS_CAMPAIGN
  | typeof PUT_STOP_SMS_CAMPAIGN;

export type changeSMSCampaignStatusSuccess =
  | typeof PUT_RUN_SMS_CAMPAIGN_SUCCESS
  | typeof PUT_STOP_SMS_CAMPAIGN_SUCCESS;

export type changeSMSCampaignStatusFail =
  | typeof PUT_RUN_SMS_CAMPAIGN_FAIL
  | typeof PUT_STOP_SMS_CAMPAIGN_FAIL;

export interface IGetSMSCampaigns {
  type: typeof GET_SMS_CAMPAIGNS;
}

export interface IGetSMSCampaignsSuccess {
  type: typeof GET_SMS_CAMPAIGNS_SUCCESS;
  payload: { SMSCampaigns: ISMSCampaign[] };
}

export interface IGetSMSCampaignsFail {
  type: typeof GET_SMS_CAMPAIGNS_FAIL;
  payload: { error: IError };
}

export interface IGetSMSCampaignById {
  type: typeof GET_SMS_CAMPAIGN_BY_ID;
  payload: { SMSCampaignId: string };
}

export interface IGetSMSCampaignByIdSuccess {
  type: typeof GET_SMS_CAMPAIGN_BY_ID_SUCCESS;
  payload: { SMSCampaignId: string; SMSCampaign: ISMSCampaign };
}

export interface IGetSMSCampaignByIdFail {
  type: typeof GET_SMS_CAMPAIGN_BY_ID_FAIL;
  payload: { SMSCampaignId: string; error: IError };
}

export interface IPostSMSCampaign {
  type: typeof POST_SMS_CAMPAIGN;
  payload: { SMSCampaign: ICreateSMSCampaign };
}

export interface IPostSMSCampaignSuccess {
  type: typeof POST_SMS_CAMPAIGN_SUCCESS;
  payload: { SMSCampaign: ISMSCampaign };
}

export interface IPostSMSCampaignFail {
  type: typeof POST_SMS_CAMPAIGN_FAIL;
  payload: { error: IError };
}

export interface IPostSMSContactGroups {
  type: typeof POST_SMS_CONTACT_GROUP;
  payload: { SMSCampaignId: string; contacts: ICreateSMSCampaignContact[][] };
}

export interface IPostSMSContactGroupsSuccess {
  type: typeof POST_SMS_CONTACT_GROUP_SUCCESS;
  payload: { SMSCampaignId: string; loadPercentage: number };
}

export interface IPostSMSContactGroupsFail {
  type: typeof POST_SMS_CONTACT_GROUP_FAIL;
  payload: {
    SMSCampaignId: string;
    loadPercentage: number;
    contactGroupOfError: ICreateSMSCampaignContact[];
  };
}

export interface IPostSMSContactsFinish {
  type: typeof POST_SMS_CONTACTS_FINISHED;
  payload: { SMSCampaignId: string };
}

export interface IPutChangeSMSCampaignStatus {
  type: changeSMSCampaignStatusActions;
  payload: { action: ISMSCampaignActions; SMSCampaignId: string; actionType: changeSMSCampaignStatusActions}
}

export interface IPutChangeSMSCampaignStatusSuccess {
  type: changeSMSCampaignStatusSuccess;
  payload: { SMSCampaignId: string; SMSCampaign: ISMSCampaign };
}

export interface IPutChangeSMSCampaignStatusFail {
  type: changeSMSCampaignStatusFail;
  payload: { SMSCampaignId: string; error: IError };
}
