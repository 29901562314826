import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';

import store from './store';
import Auth0ProviderWithHistory from 'helpers/api_auth0';
import ErrorBoundary from 'library/errorBoundary/errorBoundary';
import AppErrorFallback from 'library/errorBoundary/fallbacks/appErrorFallback';

const app = (
  <ErrorBoundary fallback={AppErrorFallback}>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
