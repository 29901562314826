import React from 'react';
import PropTypes from 'prop-types';

function CheckBox({ label, value, onChange, onClick, style = undefined }) {
  return (
    <div
      className="form-check form-switch form-switch-md"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <input
        type="checkbox"
        className="form-check-input"
        id="isAvailable"
        onChange={onChange}
        onClick={onClick}
        checked={value}
      />
      <label className="form-check-label" htmlFor="isAvailable">
        {label}
      </label>
    </div>
  );
}

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default CheckBox;
