import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../atoms/container';
import QueueResume from '../organisms/queueResume';
import QueueSelectLiveBoard from '../organisms/queueSelectLiveBoard';

import { getReportData } from 'store/actions';

import { IQueue } from 'library/interfaces/crdInterfaces';
import IStore from 'library/interfaces/storeInterface';

import { liveBoardAppSyncService } from 'library/services/liveBoardService';
import { subscribeLiveData } from 'helpers/appSyncLiveBoard';
import { ILiveDataQueue } from 'library/interfaces/liveboardInterface';

const LiveDashboardTemplate = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ILiveDataQueue[]>([]);

  const owner = useSelector<IStore, string>(store => store.authUserData.owner);
  const queues = useSelector<IStore, IQueue[]>(
    state => state.cdr.reportData.queues,
  );

  const dataToRender = useMemo(() => {
    return liveBoardAppSyncService(queues, data);
  }, [data, queues]);

  useEffect(() => {
    if (queues.length === 0) dispatch(getReportData());

    if (!owner) return;
    const subscription = subscribeLiveData(
      owner.toLowerCase(),
      (data: ILiveDataQueue[]) => setData(data),
    );
    return () => subscription.unsubscribe();
  }, [owner]);

  return (
    <Container padding="20px">
      <QueueSelectLiveBoard mainPath='/live-board' redirectPath='/live-board/summarized' queues={queues} live />

      <Container padding="30px 30px 10px" mediumBackground>
        {dataToRender &&
          dataToRender.map((item, index) => {
            return <QueueResume key={index} data={item}></QueueResume>;
          })}
      </Container>
    </Container>
  );
};
export default LiveDashboardTemplate;
