import * as React from "react"

export const CallRecordings = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    {...props}
  >
    <title>{"SB_Inbound_icon_"}</title>
    <path
      fill="currentColor"
      d="M8.5 24.75a7.25 7.25 0 1 1 7.25-7.25 7.25 7.25 0 0 1-7.25 7.25Zm0-12.66a5.41 5.41 0 1 0 5.41 5.41 5.42 5.42 0 0 0-5.41-5.41ZM26.5 24.75a7.25 7.25 0 1 1 7.24-7.25 7.26 7.26 0 0 1-7.24 7.25Zm0-12.66a5.41 5.41 0 1 0 5.41 5.41 5.41 5.41 0 0 0-5.41-5.41Z"
    />
    <path
      fill="currentColor"
      d="M26.5 24.75h-18a.92.92 0 0 1 0-1.84h18a.92.92 0 0 1 0 1.84Z"
    />
  </svg>
)
