import { darkBorder, primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';
import ExcludeDayButton from '../atoms/excludeDayButton';

interface IExclusionDayProps {
  exclusion_day: { day: string }[];
  deleteExcludeDay: Function;
}
const ExcludeDay: FC<IExclusionDayProps> = ({
  exclusion_day,
  deleteExcludeDay,
}) => {
  const deleteDay = (index: number) => {
    deleteExcludeDay(index, exclusion_day);
  };
  return (
    <Cont>
      {exclusion_day.map((day, index) => {
        return (
          <ExcludeDayButton key={index} index={index} onClick={deleteDay}>
            {day.day}
          </ExcludeDayButton>
        );
      })}
    </Cont>
  );
};
const Cont = styled.div`
  height: 44px;
  overflow-y: scroll;
  flex-grow: 1;
  border-radius: 5px;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);

  background-color: ${darkBorder};
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(123, 153, 4, 0.3);
    border-radius: 10px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${primary};
    }
  }
`;
export default ExcludeDay;
