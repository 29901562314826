import React, { memo } from 'react';

import RightHandle from '../common/RightHandle';
import NodeLayout from '../common/NodeLayout';
import LeftHandle from '../common/LeftHandle';
import Modal from './Modal';
import PropTypes from 'prop-types';

const color = 'green1';

// eslint-disable-next-line react/display-name
export const OutboundCall = memo(({ data, isConnectable }) => (
  <NodeLayout data={data} Modal={Modal} color={color}>
    <LeftHandle id="route_in" isConnectable={isConnectable} bg="blue" />

    <RightHandle
      id="route_fail"
      isConnectable={isConnectable}
      type="source"
      text="fail"
      bg="red"
    />
    <RightHandle
      id="route_success"
      isConnectable={isConnectable}
      type="source"
      text="success"
      bg="green2"
    />
    <RightHandle
      id="route_voice_call"
      isConnectable={isConnectable}
      type="source"
      text="voice call"
      bg="blue"
    />
  </NodeLayout>
));

OutboundCall.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
