import { primary } from 'library/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ISaveScenaryButtonProps {
  isExtended: boolean;
  handleSaveNode: () => void;
}
const SaveScenaryButton: FC<ISaveScenaryButtonProps> = ({
  isExtended,
  handleSaveNode,
}) => {
  return (
    <Cont isExtended={isExtended} onClick={() => handleSaveNode()}>
      <i className="bx bx-memory-card"></i>
      Save
    </Cont>
  );
};
const Cont = styled.div<IButton>`
  position: absolute;
  bottom: -50px;
  right: ${props => (props.isExtended ? '0px' : '0px')};
  width: ${props => (props.isExtended ? '110px' : '40px')};
  height: 40px;
  color: #fff;
  padding: 4px 15px;
  padding-left: ${props => (props.isExtended ? '15px' : '7px')};
  border-radius: 50px;
  display: flex;
  align-items: center;
  background-color: ${primary};
  transition: right 1s, width 1s, padding 1s;
  overflow: hidden;
  cursor: pointer;
  & > i {
    font-size: 1.5rem;
    margin: 0 6px 0 2px;
  }
`;
interface IButton {
  isExtended: boolean;
}
export default SaveScenaryButton;
