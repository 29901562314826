import {
  OutboundCall,
  TextToSpeech,
  SceneryStart,
  SceneryEnd,
  Extension,
  ContinueScenario,
  FunctionRequest,
  DataVerification,
  DataModification,
  SpeechToText,
  InteractiveMenu,
  CallRecordings,
  CallForwarding,
  HttpRequest,
  Player,
  Pause,
  SendingEmail,
  CallProperties,
  CallToQueue,
  SendSMS,
  AnswerRecognition,
  CallTagging,
  SuccessfulCall,
} from './';

export const nodeTypes = {
  outboundCall: OutboundCall,
  textToSpeech: TextToSpeech,
  sceneryStart: SceneryStart,
  sceneryEnd: SceneryEnd,
  extension: Extension,
  continueScenario: ContinueScenario,
  functionRequest: FunctionRequest,
  dataVerification: DataVerification,
  dataModification: DataModification,
  speechToText: SpeechToText,
  interactiveMenu: InteractiveMenu,
  callRecordings: CallRecordings,
  callForwarding: CallForwarding,
  httpRequest: HttpRequest,
  player: Player,
  pause: Pause,
  sendingEmail: SendingEmail,
  callProperties: CallProperties,
  callToQueue: CallToQueue,
  sendSMS: SendSMS,
  answerRecognition: AnswerRecognition,
  callTagging: CallTagging,
  successfulCall: SuccessfulCall,
};

export const nodelabels = {
  outboundCall: 'Outbound Call',
  textToSpeech: 'Text To Speech',
  sceneryStart: 'Scenery Start',
  sceneryEnd: 'Scenery End',
  extension: 'Extension',
  continueScenario: 'Continue Scenario',
  functionRequest: 'Function Request',
  dataVerification: 'Data Verification',
  dataModification: 'Data Modification',
  speechToText: 'Speech to Text',
  interactiveMenu: 'Interactive Menu',
  callRecordings: 'Call Recordings',
  callForwarding: 'Call Forwarding',
  httpRequest: 'Http Request',
  player: 'Player',
  pause: 'Pause',
  sendingEmail: 'Sending Email',
  callProperties: 'Call Properties',
  callToQueue: 'Call to Queue',
  sendSMS: 'Send SMS',
  answerRecognition: 'Answer Recognition',
  callTagging: 'Call Tagging',
  successfulCall: 'Successful Call',
};
