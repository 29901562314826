import DownloadReportButton from 'components/outbound/atoms/downloadReportButton';
import { IScenarioGeneralData } from 'library/interfaces/scenariosInterfaces';
import { IWaCampaign } from 'library/interfaces/whatsappCampaignInterfaces';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

interface IWaCampaignReportItemProps {
  waCampaign: IWaCampaign;
  scenarios: IScenarioGeneralData[]
}
const WaCampaignReportItem: FC<IWaCampaignReportItemProps> = ({ waCampaign, scenarios }) => {

  const scenarioName = useMemo(() => {
    return scenarios.find(scenario => scenario.id === waCampaign.scenario_id)?.name
  }, [scenarios, waCampaign])

  return (
    <GridCont>
      <div>{waCampaign.name}</div>
      <div>{scenarioName}</div>
      <Date>{waCampaign.created_at.substring(0, 10)}</Date>
      <DownloadReportButton reportType={7} params={`campaign=${waCampaign.id}&force=true`}>Reporte</DownloadReportButton>

    </GridCont>
  );
};
const GridCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 200px 200px;
  /* border: 1px solid red; */
  align-items: center;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: default;

  &:hover > :first-child {
    font-weight: 700;
  }
`;
const Date = styled.div`
  text-align: center;
`;
export default WaCampaignReportItem;