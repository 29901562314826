import toast from 'react-hot-toast';

interface IToastFunc<T> {
  message?: string;
  component?: (data: T, closeCallback: () => void) => JSX.Element;
  componentMessage?: T;
}
export const toast20 = <T>({
  message,
  component,
  componentMessage,
}: IToastFunc<T>) => {
  if (message) {
    toast(message, { duration: 20000 });
  } else if (component && componentMessage) {
    return toast(t => component(componentMessage, () => toast.dismiss(t.id)), {
      duration: 20000,
    });
  }
};
