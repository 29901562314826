import React, { FC } from 'react';
import styled from 'styled-components';
import InfoCont from '../atoms/infoCont';
import Title from '../atoms/title';
import Text from '../atoms/text';
import Span from '../atoms/span';

interface ICierreDeTodaLlamadaProps { }
const CierreDeTodaLlamada: FC<ICierreDeTodaLlamadaProps> = ({ }) => {
  return (
    <InfoCont>
      <Title>Cierre de llamada</Title>
      <Text>
        <Span danger>Antes de transferirle: </Span>
        Espero haber sido de ayuda. Agradecemos su llamada. Si necesita más información o ayuda para atender su situación puede volver a comunicarse con nosotros. Con gusto le atenderemos.
      </Text>
      <Text>Trabajamos para que llegues bien.</Text>
      <Text danger>Transferir o cerrar la llamada.</Text>
      <Text>
        <Span danger>Cliente no escucha: </Span>
        Por falta de comunicación procedo al cierre de llamada, por favor contactar nuevamente a nuestro número 787-705 8699.
      </Text>
      <Text>
        <Span danger>Cliente fuera de contexto: </Span>
        Gracias por comunicarse con nosotros, pero por temas de seguridad daré por terminada esta llamada.
      </Text>
      <Text danger>Cerrar la llamada.</Text>
      </InfoCont>
  );
};
export default CierreDeTodaLlamada;