import React, { FC, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import smartbotIcon from '../../../icons/SmartBot_BotonChatBot.svg'
import { darkBorder, primary } from 'library/colors';
import MetropistasChatInfo from './metropistas/metroPistasChatInfo';

interface IChatButtonProps { }
const ChatButton: FC<IChatButtonProps> = ({ }) => {
  const [showChat, setShowChat] = useState(false)

  const hanleShowChat = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    if (e.target instanceof HTMLDivElement && e.target.id === 'exitChat') {
      setShowChat(false)
    }
    if (showChat) return
    setShowChat(true)
  }
  return (
    <Cont onClick={e => hanleShowChat(e)} >
      <img src={smartbotIcon} alt="Smarbot" />
      {showChat && <ChatContainer>
        <Exit id='exitChat'>&times;</Exit>

        <ChatHeader>
          <img src={smartbotIcon} alt="Smarbot" />
          Smartbot AI
        </ChatHeader>
        <MetropistasChatInfo />
        <InputCont>
          <input type="text" />
          <button disabled><i className='mdi mdi-send' ></i></button>
        </InputCont>
      </ChatContainer>}
    </Cont>
  );
};
const Cont = styled.button`
  position: absolute;
  top: calc(100vh - 100px);
  left: 25px;
  width: 75px;
  height: 75px;
  z-index: 10000;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  border: none;
  box-shadow: 0 0 5px #000, 
  0 0 5px #fff;
  
`;
const enterAnimation = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;
const ChatContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 350px;
  height: 520px;
  border-radius: 10px;
  cursor: default;
  text-align: left;
  box-shadow: 2px 2px 5px  ${darkBorder};
  animation: ${enterAnimation} .2s;
`;
const Exit = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 100;
  font-size: 2rem;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  /* border: 1px solid; */
`;
const ChatHeader = styled.div`
  /* border: 1px solid; */
  font-size: 1.5rem;
  font-weight: 500;
  padding: 15px 20px 10px;
  border-bottom: 1px solid ${darkBorder};
  &>img {
    width: 40px;
    margin-right: 10px;
  }
`;
const InputCont = styled.div`
  border-top: 1px solid ${darkBorder};
  flex-grow: 1;
  height: 67px;
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px;

  &>input{
    border: 1px solid ${primary};
    height: 30px;
    flex-grow: 1;
    border-radius: 50px;
    padding: 0 10px;
  }
  &>button{
    border: none;
    width: 30px;
    height: 30px;
    color: #fff;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: ${primary};
  }
`;
export default ChatButton;