import React, { FC } from 'react';
import styled from 'styled-components';

import { Phone } from 'library/phone/phone';
import AudioGraph from '../atoms/audioGraph';
import { AnswerCall } from 'icons';
import { incomingCallIconBackground } from 'library/colors';
import { useSelector } from 'react-redux';
import IStore from 'library/interfaces/storeInterface';
import { ISelectedChatCustomer } from 'store/chatSelected/reducer';

interface ICurrentCall{
    phone: Phone
}
const CurrentCall: FC<ICurrentCall> = ({phone}) => {
    const customerInfo = useSelector<IStore>(store=>store.selectedChat.customer) as ISelectedChatCustomer
    const display = 
      phone.getRemoteDisplayName() !== '' 
        ? phone.getRemoteDisplayName() 
        : customerInfo.name !== '' 
        ? customerInfo.name 
        : phone.getRemoteId()
    const name = display.slice(0, 16)
  return (
    <Cont>
      <ContactCont>
        <ContactImg>{name.slice(0,1)}</ContactImg>
        <ContactInfo>
          <Name>{name}</Name>
          <Icon>
            <AnswerCall width={'80%'}/>
          </Icon>
        </ContactInfo>
      </ContactCont>
      <Graph>
        <AudioGraph phone={phone} width={78} height={50} bars={28}/>
      </Graph>
    </Cont>
  );
};
const Cont = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
`;
const ContactCont = styled.div`
  display: flex;
`;
const ContactImg = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e7a200;
  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
`;
const ContactInfo = styled.div`
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Name = styled.div`
  font-size: 1rem;
  color: black;
  font-weight: 500;
`;
const Icon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${incomingCallIconBackground};
  text-align: center;
  color: #fff;
`;
const Graph = styled.div`
  width: 80px;
`;
export default CurrentCall;
