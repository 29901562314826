import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';

import scenarios from './scenarios/reducer';

import agents from './agents/reducer';

import cdr from './cdr/reducer';

//recordings
import recordings from './recordings/reducer';

//authUserData
import authUserData from './authUserData/reducer';

//phone
import phone from './phone/reducer';

//chatbot
import ChatBot from './chatBot/reducer';

//selectedChat
import selectedChat from './chatSelected/reducer';

//voice campaings
import voiceCampaigns from './outboundCampaigns/voiceCampaigns/reducer';

// SMS campaings
import smsCampaigns from './outboundCampaigns/smsCampaigns/reducer';

// WA campaings
import waCampaigns from './outboundCampaigns/waCampaigns/reducer';

// reports
import reports from './reports/reducer';

// configuration
import configuration from './configuration/reducer';

// workspace
import workspace from './workspace/reducer';

//conversations
import conversations from './conversation/reducer';

//conversations
import global from './global/reducer';

//channels
import channels from './channels/reducer';

// scenarios V2
import scenariosV2 from './scenariosV2/reducer';

// databases
import databases from './databases/reducer';

// files
import files from './files/reducer';

// liveConversations
import liveConversations from './liveConversations/reducer';

const rootReducer = combineReducers({
  // public
  liveConversations,
  files,
  databases,
  scenariosV2,
  global,
  channels,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  scenarios,
  agents,
  cdr,
  recordings,
  authUserData,
  phone,
  ChatBot,
  selectedChat,
  voiceCampaigns,
  smsCampaigns,
  waCampaigns,
  reports,
  configuration,
  workspace,
  conversations,
});

export default rootReducer;
