import React from 'react';
import PropTypes from 'prop-types';

Label.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string,
};

export function Label({ variant = 'sm', children }) {
  return <div className={`data-info-label-${variant}`}>{children}</div>;
}
