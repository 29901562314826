import React from 'react';
import PropTypes from 'prop-types';

Index.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

function Index({ name, variant = 'default', onClick = null }) {
  return (
    <button
      className={`btn btn-default btn-sm text-${variant}`}
      onClick={onClick}
    >
      <i className={`bx bx-${name}`} style={{ fontSize: 20 }} />
    </button>
  );
}

export default Index;
