import React, { FC } from 'react';
import styled from 'styled-components';

interface IWaQuickReplyMessageProps {
  right: boolean
  text: string
  header: string
  caption: string
  buttons: { title: string, postbackText: string }[]
  handleSendMessage: (value: string) => void
}
const WaQuickReplyMessage: FC<IWaQuickReplyMessageProps> = ({ right, text, header, caption, buttons, handleSendMessage }) => {
  return (
    <Cont isRight={right}>
      <TextCont isRight={right}>
        <TailCont isRight={right}>
          <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px" enableBackground="new 0 0 8 13"><title>tail-in</title><path opacity="0.13" fill="#0000000" d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"></path><path fill="currentColor" d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"></path></svg>
        </TailCont>
        <Header >
          {header}
        </Header>
        <Text >
          {text}
        </Text>

        <Footer>
          {caption}
        </Footer>

      </TextCont>
      {buttons.map((item, i) => {
        return (
          <Buttons key={i} onClick={() => handleSendMessage(item.title)}>
            <Text >
              {item.title}
            </Text>
          </Buttons>
        )
      })}
    </Cont>
  );
};
const Cont = styled.div<ICont>`
color: #e9edef;
font-size: 14px;
margin-bottom: 10px;
display: flex;
  gap: 3px;
flex-direction: column;
align-items: ${props => props.isRight && 'end'};

`;
const TextCont = styled.div<ICont>`
max-width: 90%;
min-height: 40px;
padding: 6px 7px 8px 9px;
background-color: #202c33;
position: relative;

border-radius: 7.5px;
border-top-left-radius: ${props => !props.isRight && 0};
border-top-right-radius: ${props => props.isRight && 0};
font-size: 15px;
`;

const TailCont = styled.span<ICont>`
position: absolute;
top: 0px;
left: ${props => !props.isRight ? 0 : 'calc(100% + 8px)'};

display: flex;
transform: translate(-100%) rotateY(${props => !props.isRight ? '0deg' : '180deg'});
color: #202c33;
`;
const Text = styled.div`
  border: none;
  outline: none;
  flex-grow: 1;
  margin-bottom: 5px;
  font-size: 14px;
  color: #e9edef;
  
`;
const Header = styled.div`
  border: none;
  outline: none;
  flex-grow: 1;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
  color: #e9edef;
`;

const Footer = styled.div`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 13px;
  color: #667781;
`;
const Buttons = styled.div`
max-width: 90%;
background-color: #202c33;

  border-radius: 7.5px;
  text-align: center;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  >div{
    color: #53bdeb;
    width: 100%;
    padding-top: 7px;

  }
`;
interface ICont {
  isRight: boolean
}
export default WaQuickReplyMessage;