import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './button';
import { useDispatch } from 'react-redux';
import { updateOrderAction } from 'store/actions';
import { useTranslation } from 'react-i18next';

const OrderButtons = () => {
  const {t} = useTranslation('recordings')
  const [buttons, setButtons] = useState([
    { text: 'des', isActive: true },
    { text: 'asc', isActive: false },
  ]);
  const dispatch = useDispatch()

  const handleButtonClick = (index) => {
    const newButtonState = buttons.map((button, i)=>{
      if(i===index) {
        return {text: button.text, isActive: true}
      }
      return {text: button.text, isActive: false}
    })
    dispatch(updateOrderAction(buttons[index].text))
    setButtons(newButtonState)
  };
  return (
    <Container>
      <Text>{t('order')}:</Text>
      <ButtonsContainer>
        {buttons.map((item, index) => {
          return (
            <Button
              key={index}
              index={index}
              button={item}
              onClick={()=>handleButtonClick(index)}
            />
          );
        })}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  margin-left: 50px;
`;
const Text = styled.div`
font-size: 14px;
font-weight: 500;
padding: 0 20px 0 0px;
`;
const ButtonsContainer = styled.div` 
  text-align: center;
`;
export default OrderButtons;