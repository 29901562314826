import React from 'react';
import { Redirect } from 'react-router-dom';

// Pages Component
import WorkSpace from '../pages/WorkSpace';
import Configuration from '../pages/Configuration';
import History from '../pages/History';
import Incoming from '../pages/Incoming';
import Integrations from '../pages/Integrations';
import LiveBoard from '../pages/LiveBoard';
import LiveBoardSummarize from '../pages/liveBoardSummarize';
import LiveBoardQueuedCalls from '../pages/liveBoardQueuedCalls';
import LiveBoardQueuedCallsDetailed from '../pages/liveBoardQueuedCallsDetailed';
import LiveChat from '../pages/liveConversations';
import Numbers from '../pages/Numbers';
import Outgoing from '../pages/Outgoing';
import Recordings from '../pages/Recordings';
import Reports from '../pages/Reports';
import Editor from '../pages/Editor';
import AgentCalls from '../pages/liveBoardAgentCalls';
import AgentCallsDetailed from '../pages/liveBoardAgentCallsDetailed';

// Authentication related pages
import Entry from '../pages/Authentication/Entry';
import Authorize from '../pages/Authentication/Authorize';
import Profile from '../pages/Authentication/user-profile';
import ReactFlow from 'pages/flowTest';
import ScenariosPage from 'pages/scenarios';
import DatabasesPage from 'pages/databases';
import DatabaseDataPage from 'pages/databaseData';

const authProtectedRoutes = [
  //chat
  { path: '/workspace', component: WorkSpace },
  { path: '/configuration', component: Configuration },
  { path: '/history', component: History },
  { path: '/incoming', component: Incoming },
  { path: '/integrations', component: Integrations },
  {
    path: '/live-board/queued-calls/detailed/:uid',
    component: LiveBoardQueuedCallsDetailed,
  },
  { path: '/live-board/queued-calls', component: LiveBoardQueuedCalls },
  {
    path: '/live-board/agent-calls/detailed/:queueId',
    component: AgentCallsDetailed,
  },
  { path: '/live-board/agent-calls', component: AgentCalls },
  { path: '/live-board/summarized/:queueId', component: LiveBoardSummarize },
  { path: '/live-board', component: LiveBoard },
  { path: '/live-chat', component: LiveChat },
  { path: '/numbers', component: Numbers },
  { path: '/outgoing', component: Outgoing },
  { path: '/recordings', component: Recordings },
  { path: '/reports', component: Reports },
  { path: '/reports/:reportTypeId', component: Reports },
  { path: '/editor/:id', component: Editor },
  { path: '/workspace', component: WorkSpace },
  { path: '/profile', component: Profile },
  { path: '/incoming-v2', component: ScenariosPage },
  { path: '/incoming-v2/:scenarioId', component: ReactFlow },
  { path: '/databases', component: DatabasesPage },
  { path: '/database/:databaseId', component: DatabaseDataPage },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: '/', exact: true, component: () => <Redirect to="/workspace" /> },

  // redirect to workspace in case path doesn't exits
  { path: '*', component: () => <Redirect to="/" /> },
];

const publicRoutes = [
  { path: '/entry/:organization?', component: Entry },
  { path: '/authorize', component: Authorize },

  // { path: '/pages-maintenance', component: PagesMaintenance },
  // { path: '/pages-comingsoon', component: PagesComingsoon },
  // { path: '/pages-404', component: Pages404 },
  // { path: '/pages-500', component: Pages500 },
];

const publicLayoutRoutes = [
  // { path: '/pages-maintenance', component: PagesMaintenance },
  // { path: '/pages-comingsoon', component: PagesComingsoon },
  // { path: '/pages-404', component: Pages404 },
  // { path: '/pages-500', component: Pages500 },
];

export { authProtectedRoutes, publicRoutes, publicLayoutRoutes };
