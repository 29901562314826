import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'reactstrap';

import BodyTable from './bodyTable';
import HeaderTable from './headertable';
import Transcription from './transcription';
import { useAppSelector } from 'library/hooks/useAppSelector';

const RecordingsList = () => {
  const [transcription, setTranscription] = useState('');
  const [directionWidth, setDirectionWidth] = useState(120);
  const owner = useAppSelector(store => store.authUserData.owner);
  const queues = useAppSelector(store => store.cdr.reportData.queues);

  const recordings = useAppSelector(store => store.recordings.Items);

  const recordinstToRender = useMemo(() => {
    if (owner === 'ASESP') {
      const queueObj = queues.reduce((acc, cur) => {
        acc[cur.id] = cur.name;
        return acc;
      }, {});
      console.log(queueObj);
      return recordings.filter(record => queueObj[record.destination]);
    }

    return recordings;
  }, [recordings, queues]);

  useEffect(() => {
    const record = recordings.filter(item => item.direction.length > 8);

    if (record.length === 0 && directionWidth !== 120) {
      setDirectionWidth(120);
    } else if (record[0]) {
      setDirectionWidth(record[0].direction.length * 10);
    }
  });

  return (
    <>
      <Table responsive bordered className="table">
        <HeaderTable directionWidth={directionWidth} />
        {recordinstToRender &&
          recordinstToRender.map((recording, index) => {
            return (
              <BodyTable
                key={index}
                recording={recording}
                showTranscription={setTranscription}
              />
            );
          })}
      </Table>
      {transcription && (
        <Transcription
          transcription={transcription}
          hideTranscription={setTranscription}
        />
      )}
    </>
  );
};
export default RecordingsList;
