import * as React from 'react';

export const Draft = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="svg8"
    version="1.1"
    viewBox="0 0 92.708629 123.00317"
    {...props}
  >
    <defs id="defs2" />
    <g transform="translate(-53.141667,-41.154968)" id="layer1">
      <path
        id="path85"
        fill="currentColor"
        strokeWidth="0.71952301"
        d="m 58.329823,163.09947 c -1.294802,-0.56599 -2.993465,-2.07756 -3.774806,-3.35904 -1.381771,-2.26624 -1.420405,-3.82543 -1.412704,-57.01371 0.006,-41.972895 0.214512,-55.08575 0.896714,-56.413199 0.488839,-0.951196 1.80245,-2.40823 2.919138,-3.237853 1.958491,-1.455028 2.932711,-1.515703 27.529278,-1.714553 l 25.498937,-0.206147 17.64042,17.067856 c 9.70222,9.38732 17.80619,17.736615 18.00882,18.553985 0.20263,0.81737 0.27474,19.13243 0.16026,40.700131 -0.20188,38.03021 -0.25266,39.29003 -1.68242,41.73233 -2.97438,5.08082 -1.97021,4.97035 -44.934971,4.94341 -31.766218,-0.0199 -38.905957,-0.204 -40.848666,-1.05321 z m 79.559007,-6.91219 c 0.64933,-0.64934 0.86342,-10.1053 0.86342,-38.13472 V 80.781269 l -13.13129,-0.003 c -11.74642,-0.002 -13.3738,-0.15055 -15.43065,-1.4047 -4.53275,-2.76379 -4.86974,-3.97739 -5.14438,-18.526271 l -0.2485,-13.16443 H 83.331754 c -18.554884,0 -21.618995,0.153322 -22.596351,1.130679 -1.007304,1.007303 -1.130679,6.88001 -1.130679,53.820323 0,40.02147 0.207593,52.89724 0.863427,53.55307 1.245233,1.24523 76.175439,1.24523 77.420679,0 z m -14.61531,-92.685364 -10.78386,-10.803642 -0.20131,9.898217 c -0.1765,8.677928 -0.0486,10.009838 1.03693,10.803638 0.8507,0.62206 4.28873,0.90543 10.98516,0.90543 h 9.74692 z"
      />
    </g>
  </svg>
);
