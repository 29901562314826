import * as React from 'react';

export const SceneStar = props => (
  <svg
    width={500}
    height={500}
    viewBox="0 0 132.29 132.29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M110.592 11.88h3.336c7.41 0 13.415 6.004 13.415 13.415v76.947c0 7.408-6.005 13.415-13.415 13.415H36.983c-7.41 0-13.418-6.007-13.418-13.415V25.295c0-7.41 6.008-13.416 13.418-13.416h4.702M51.97 11.88h47.675"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit={10}
      strokeWidth={4.8448}
    />
    <path
      d="M47.852 19.994h-2.12a4.096 4.096 0 0 1-4.096-4.096V6.463a4.094 4.094 0 0 1 4.096-4.096h2.12a4.094 4.094 0 0 1 4.096 4.096v9.435a4.096 4.096 0 0 1-4.096 4.096zM106.232 19.994h-2.12a4.096 4.096 0 0 1-4.096-4.096V6.463a4.094 4.094 0 0 1 4.096-4.096h2.12a4.094 4.094 0 0 1 4.096 4.096v9.435a4.096 4.096 0 0 1-4.096 4.096zM23.628 33.972h103.446M49.548 66.892H39.71a4.228 4.228 0 0 1-4.23-4.227v-9.84a4.228 4.228 0 0 1 4.23-4.227h9.838a4.226 4.226 0 0 1 4.227 4.227v9.84a4.226 4.226 0 0 1-4.227 4.227zM80.312 66.892h-9.837a4.228 4.228 0 0 1-4.23-4.227v-9.84a4.228 4.228 0 0 1 4.23-4.227h9.837a4.226 4.226 0 0 1 4.227 4.227v9.84a4.226 4.226 0 0 1-4.227 4.227zM80.312 101.484h-9.837a4.228 4.228 0 0 1-4.23-4.227v-9.84a4.228 4.228 0 0 1 4.23-4.227h9.837a4.226 4.226 0 0 1 4.227 4.227v9.84a4.226 4.226 0 0 1-4.227 4.227zM111.319 101.484h-9.837a4.228 4.228 0 0 1-4.23-4.227v-9.84a4.228 4.228 0 0 1 4.23-4.227h9.837a4.226 4.226 0 0 1 4.227 4.227v9.84a4.226 4.226 0 0 1-4.227 4.227z"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit={10}
      strokeWidth={4.8448}
    />
    <path
      d="M29.926 77.672c15.072 0 27.289 12.218 27.289 27.288 0 15.072-12.217 27.29-27.289 27.29S2.638 120.033 2.638 104.96c0-15.07 12.216-27.288 27.288-27.288"
      fill="#ff9904"
    />
    <path
      d="M111.803 58.365a4.845 4.845 0 1 1-9.69 0 4.845 4.845 0 0 1 9.69 0"
      fill="#231f20"
    />
    <path
      d="M24.84 99.982a1.686 1.686 0 0 0 1.404-.998l3.045-6.734 3.045 6.734c.254.557.797.947 1.405.998l6.717.526-5.532 4.973c-.473.44-.676 1.1-.49 1.727l2.115 7.427-6.33-4.212a1.652 1.652 0 0 0-1.877 0l-6.599 4.399 1.776-7.699a1.687 1.687 0 0 0-.472-1.591l-5.129-5.007zm-4.824 7.31-2.522 10.914a1.69 1.69 0 0 0 1.253 2.03c.458.101.947.017 1.337-.235l9.205-6.143L38.494 120c.778.523 1.827.303 2.352-.475.272-.407.357-.913.22-1.405l-3.095-10.828 7.681-6.904a1.69 1.69 0 0 0 .12-2.386 1.698 1.698 0 0 0-1.118-.56l-9.646-.76-4.178-9.24c-.39-.845-1.388-1.235-2.234-.845a1.705 1.705 0 0 0-.847.846l-4.18 9.239-9.645.76a1.714 1.714 0 0 0-1.555 1.83c.034.404.22.794.506 1.082z"
      fill="#fff"
    />
  </svg>
);
