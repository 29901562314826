import React, { FC } from "react";
import styled from "styled-components";

import { darkBorder } from "library/colors";

interface INumericKeyboard {
  onNumberClicked: Function;
}
const NumericKeyboard: FC<INumericKeyboard> = ({ onNumberClicked }) => {
  return (
    <KeyboardCont>
      <Row>
        <Number onClick={()=>onNumberClicked('1')}>1</Number>
        <Number sideBorder onClick={()=>onNumberClicked('2')}>2</Number>
        <Number onClick={()=>onNumberClicked('3')}>3</Number>
      </Row>
      <Row>
        <Number onClick={()=>onNumberClicked('4')}>4</Number>
        <Number sideBorder onClick={()=>onNumberClicked('5')}>5</Number>
        <Number onClick={()=>onNumberClicked('6')}>6</Number>
      </Row>
      <Row>
        <Number onClick={()=>onNumberClicked('7')}>7</Number>
        <Number sideBorder onClick={()=>onNumberClicked('8')}>8</Number>
        <Number onClick={()=>onNumberClicked('9')}>9</Number>
      </Row>
      <Row noBorder>
        <Number onClick={()=>onNumberClicked('*')}>*</Number>
        <Number sideBorder onClick={()=>onNumberClicked('0')}>0</Number>
        <Number onClick={()=>onNumberClicked('#')}>#</Number>
      </Row>
    </KeyboardCont>
  );
};
const KeyboardCont = styled.div`
  max-width: 250px;
  margin: 20px auto;
`;
const Row = styled.div<IRow>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid ${darkBorder};
  border: ${(props) => props.noBorder && "none"};
`;
const Number = styled.button<INumber>`
  text-align: center;
  font-size: 1.5rem;
  line-height: 33px;
  font-weight: 300;
  border: none;
  cursor: pointer;
  background-color: transparent;
  border-left: ${(props) => props.sideBorder && `1px solid ${darkBorder}`};
  border-right: ${(props) => props.sideBorder && `1px solid ${darkBorder}`};
`;
interface IRow {
  noBorder?: React.ReactNode;
}
interface INumber {
  sideBorder?: React.ReactNode;
}
export default NumericKeyboard;
