import { BlueContModalSC, InputModalSC, SectionTitleModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';
import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';
import React, { FC } from 'react';
import { Node } from 'reactflow';

interface ITransferModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
}
const TransferModal: FC<ITransferModalProps> = ({ selectedNode, setSelectedNode }) => {
  const { data } = selectedNode;

  const handleInputChange = (value: string) => {
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        transfer: value
      },
    };
    setSelectedNode(editedNode);
  }

  return (
    <BlueContModalSC>
      <SectionTitleModalSC>Configuracion de transferencia</SectionTitleModalSC>
      <InputModalSC type="text"
        value={data.transfer}
        onChange={e => handleInputChange(e.target.value)} />
    </BlueContModalSC>
  );
};
export default TransferModal;