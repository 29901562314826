import React from 'react';
import MetaTags from 'react-meta-tags';
import PageTitle from 'components/LiveDashboard/atoms/pageTitle';
import WorkSpaceTemplate from 'components/WorkSpace/templates/workSpaceTemplates';
import { useTranslation } from 'react-i18next';
import PageTitleWithIndicators from 'components/WorkSpace/organisms/titleWithIndicators';

const Index = props => {
  const { phone } = props;
  const { t } = useTranslation('workspace');
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ backgroundColor: '#eff5f8', height: '100vh' }}
      >
        <MetaTags>
          <title>{t('pageTitle')} | SmartBot</title>
        </MetaTags>

        <PageTitleWithIndicators>{t('pageTitle')}</PageTitleWithIndicators>

        <WorkSpaceTemplate phone={phone} />
      </div>
    </React.Fragment>
  );
};
export default Index;
