import { CustomNodeTypes, INodeData } from 'components/flowV2/utils';
import { lightBackgroundBlue, primary } from 'library/colors';
import React, { FC } from 'react';
import { Node } from 'reactflow';
import styled from 'styled-components';
import TextTabs from './textTab';
import { BlueContModalSC, SectionTitleModalSC } from 'components/flowV2/styledComponents/modalsStyledComponents';

interface ITextToSpeechModalProps {
  selectedNode: Node<INodeData, CustomNodeTypes>;
  setSelectedNode: (node: Node<INodeData, CustomNodeTypes>) => void;
  handleShowVisualEditor: (show: boolean) => void;
}
const TextToSpeechModal: FC<ITextToSpeechModalProps> = ({
  selectedNode,
  setSelectedNode,
  handleShowVisualEditor
}) => {
  const { data } = selectedNode;

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!data.textV2) return
    const { name, value } = event.target
    const textData = structuredClone(data.textV2);
    const editedNode: Node<INodeData, CustomNodeTypes> = {
      ...selectedNode,
      data: { ...selectedNode.data, textV2: { ...textData, [name]: value } }
    };
    setSelectedNode(editedNode);
  }
  return (
    <BlueContModalSC>
      <Flex>
        <SectionTitleModalSC>Message</SectionTitleModalSC>
        <VisualButton onClick={() => handleShowVisualEditor(true)}>Visual</VisualButton>
      </Flex>

      <TextTabs textV2={data?.textV2} handleTextChange={handleTextChange} />
    </BlueContModalSC>
  );
};
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const VisualButton = styled(SectionTitleModalSC)`
  background-color: ${primary};
  padding: 3px 10px ;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
`;
export default TextToSpeechModal;
