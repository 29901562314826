import { ICreateTag } from 'library/interfaces/workspaceInterface';
import { IError } from 'store/reports/reducer';

export const POST_CALL_TAG = 'POST_CALL_TAG';
export const POST_CALL_TAG_SUCCESS = 'POST_CALL_TAG_SUCCESS';
export const POST_CALL_TAG_FAIL = 'POST_CALL_TAG_FAIL';
export const UPDATE_SELECTED_CAMPAIGN_ID = 'UPDATE_SELECTED_CAMPAIGN_ID';
export const UPDATE_FROM_NUMBER = 'UPDATE_FROM_NUMBER';

export type WorkspaceActionTypes =
  | IPostCallTag
  | IPostCallTagSuccess
  | IPostCallTagFail
  | IUpdateSelectedCampaignId
  | IUpdateFromNumber;

export interface IPostCallTag {
  type: typeof POST_CALL_TAG;
  payload: { tag: ICreateTag; campaignId: string };
}
export interface IPostCallTagSuccess {
  type: typeof POST_CALL_TAG_SUCCESS;
  payload: { tagId: string };
}

export interface IPostCallTagFail {
  type: typeof POST_CALL_TAG_FAIL;
  payload: { error: IError };
}

export interface IUpdateSelectedCampaignId {
  type: typeof UPDATE_SELECTED_CAMPAIGN_ID;
  payload: { campaignId: string };
}

export interface IUpdateFromNumber {
  type: typeof UPDATE_FROM_NUMBER;
  payload: { fromNumber: string };
}
